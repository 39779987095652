import { Purchase } from '../../../../resource/purchase.resource';
import { Component, OnInit, Input } from '@angular/core';
import { Channel } from '../../../../resource/channel.resource';
import { OfferProgramService } from '../../../../service/offer-program/offer-program.service';
import { PurchaseType } from '../../../../resource/purchaseType.resource';
import { PurchaseTypeService } from '../../../../service/purchase-type/purchase-type.service';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-purchase-list-detail',
  templateUrl: './purchase-list-detail.component.html',
  styleUrls: ['./purchase-list-detail.component.scss']
})
export class PurchaseListDetailComponent implements OnInit {

  @Input() purchase: Purchase;
  purchaseTypes: PurchaseType[];

  purchaseTypeValue: FormControl = new FormControl();
  path: string = Channel.PATH;
  logosChannel: Array<string>;
  loadingChannel: boolean;

  constructor(
    private offerProgramService: OfferProgramService,
    private purchaseTypeService: PurchaseTypeService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.purchaseTypeValue.setValue(this.purchase.type.id);
    this.loadOfferProgramLogoChannel();
    this.purchaseTypeService.getList()
      .subscribe(items => {
        this.purchaseTypes = items;
      });
  }


  loadOfferProgramLogoChannel(): void {
    this.loadingChannel = true;
    let filter = {
         purchase_id: this.purchase.id,
      };

    let offerProgramSource$ = this.offerProgramService.getList(filter, 'level0');

    offerProgramSource$.subscribe(
      offerPrograms => this.logosChannel = offerPrograms.map(
          offerProgram => {
            this.loadingChannel = false;
              return offerProgram && offerProgram.area ? offerProgram.area.channel.image : null;
          }
      ).filter( (elem, index, self) => {
          return index === self.indexOf(elem) && elem != null;
      })
    );
  }

  OnMatCardClickEvent() {
    this.router.navigate(['/purchases/edit/', this.purchase.id]);
  }

  displayStatus(status) {
    if (this.purchaseTypes && status) {
      let result = this.purchaseTypes.find((data) => {
        return status === data.id;
      })
      return result.name;
    } else {
      return null;
    }
  }
}
