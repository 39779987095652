import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { UtilitiesHandler } from '@service/utilities-handlers/utilitiesHandlers';
import { AbstractService } from '@service/abstract.service';
import { SoReach, JsonSoReach } from '../../resource/so-reach.resource';
import { environment } from '../../../environments/environment';

import { Observable, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SoReachService extends AbstractService {
  private route: string = 'soreach';
  private soReaches: Observable<SoReach[]>;

  private soReachesSource = new Subject<any>();
  public soReachesSource$ = this.soReachesSource.asObservable();

  constructor(
    private http: HttpClient,
    private utilitiesHandler: UtilitiesHandler
  ) {
    super();
  }

  /**
   * Get all So Reach
   *
   * @return {Observable<void>>}
   */
  getSoReach(): Observable<Object> {
    const api_base_url = `${environment.api_base_url}/${this.route}`;

    return this.http
      .get(api_base_url)
      .pipe(
        map((response: any) => {
          return response._embedded.so_reach
            .map((jsonSoreach: JsonSoReach) => new SoReach(jsonSoreach))
        }),
        tap((soReaches: Observable<SoReach[]>) => this.soReaches = soReaches)
      )
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Put new ligne So Reach
   *
   * @param soReach
   * @return {Observable<SoReach>}
   */
  putSoReach(soReach: SoReach): Observable<SoReach> {
    const api_base_url = `${environment.api_base_url}/${this.route}/${soReach.id}`;

    return this.http
      .put(api_base_url, this.convertToUnderscore(soReach))
      .pipe(
        map((jsonSoReach: JsonSoReach) => new SoReach(jsonSoReach))
      )
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Save all So Reach
   *
   * @param soReach
   * @return {Observable<SoReach>}
   */
  postSoReach(soReach: SoReach): Observable<SoReach> {
    const api_base_url = `${environment.api_base_url}/${this.route}`;

    return this.http
      .post(api_base_url, this.convertToUnderscore(soReach))
      .pipe(
        map((jsonSoReach: JsonSoReach) => new SoReach(jsonSoReach))
      )
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Delete SoReach
   *
   * @param id
   * @return {Observable<Object>}
   */
  deleteSoReach(id: number): Observable<Object> {
    const api_base_url = `${environment.api_base_url}/${this.route}/${id}`;

    return this.http
      .delete(api_base_url)
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  sendDataSoReach(data): void {
    this.soReachesSource.next(data);
  }
}
