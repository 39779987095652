
export interface JsonCategory {
    id: string;
    name: string;
}

export class Category {
    public id: string;
    public name: string;

    constructor(jsonCategory: JsonCategory) {
        this.id = jsonCategory.id;
        this.name = jsonCategory.name;
    }
}
