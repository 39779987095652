<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<div fxLayout="row" fxLayoutGap="1px" class="container full-width">
  <div fxLayout="column" fxLayoutGap="1px" fxFlex="11%">
    <div fxFlex="30px" fxFlexOffset="10px" class=""></div>
    <div fxLayout="column" fxLayoutGap="1px" fxLayoutAlign="none none" fxFlex>
      <div *ngFor="let channel of NatChannels" fxLayoutAlign="center center" fxFlex="20%"
           class="box-text border-all back-color">
        <img class="logo" [src]="logoChannelsPath + channel.short + '.png'">
      </div>
    </div>
  </div>
  <div fxLayout="column" fxLayoutGap="1px" fxFlex="90%">
    <div fxLayout="row" fxLayoutGap="1px" fxFlexOffset="10px" fxLayoutAlign="none none" fxFlex="30px">
      <div *ngFor="let weekDay of weeksDays" fxLayoutAlign="center center" fxFlex="100/7"
           class="box-text border-all back-color">
        {{ weekDay.long }}
      </div>
    </div>
    <div *ngFor="let channel of NatChannels" fxLayout="row" fxLayoutGap="1px" class="channel-row">
      <div *ngFor="let weekDay of weeksDays" fxLayout="column" fxFlex="100/7" class="box-text border-all scroll" id="{{ channel.short }}-{{ weekDay.short }}">
        <ng-container *ngFor="let soReachProgram of soReachProgramsGrid; index as indexSoReachProgram">
          <fieldset *ngIf="soReachProgram['grid'][channel.short|uppercase][weekDay.short].status === 1 || soReachProgram['grid'][channel.short|uppercase][weekDay.short].status === 5 || soReachProgram['grid'][channel.short|uppercase][weekDay.short].status === 2"
                fxLayout="column" class="global-program-box">
            <legend [matTooltip]="soReachProgram['name']" matTooltipClass="styleTooltip">{{ soReachProgram['name'] }}</legend>
            <div *ngIf="soReachProgram['grid'][channel.short|uppercase][weekDay.short].status === 1 || soReachProgram['grid'][channel.short|uppercase][weekDay.short].status === 2 || soReachProgram['grid'][channel.short|uppercase][weekDay.short].status === 5 || soReachProgram['grid'][channel.short|uppercase][weekDay.short].status === 0"  class="program-box purchase-name" fxLayout="row">
              <div *ngFor="let purchaseSoreach of soReachProgram['grid'][channel.short|uppercase][weekDay.short]['purchases']; index as indexPurchase" class="main-soReach-box" fxFlex>
                <div *ngIf="purchaseSoreach['TYPE_PURCHASE'] === '1' && purchaseSoreach['SOREACH_PURCHASE']" class="soReach-box soReach-box-label">
                  <a class="soReach-name" [routerLink]="['/purchases', 'edit', purchaseSoreach['PURCHASE']]" target="_blank" [matTooltip]="buildTooltip(purchaseSoreach)" matTooltipClass="styleTooltip">{{ purchaseSoreach['PRODUCT'] }}</a>
                </div>
                <div *ngIf="!purchaseSoreach['SOREACH_PURCHASE'] && soReachProgram['offer_program_status'] !== '0'" class="enable-purchase-soReach" (click)="selectSoReachProgram(indexSoReachProgram, channel.short, weekDay.short, indexPurchase)" [ngClass]="{'selected': soReachProgram['grid'][channel.short|uppercase][weekDay.short].status === 5}"></div>
                <div *ngIf="!purchaseSoreach['SOREACH_PURCHASE'] && soReachProgram['offer_program_status'] === '0'" class="disable-case"></div>
              </div>
            </div>
            <div fxLayout="row" class="co-part-group">
              <ng-container *ngFor="let coPart of soReachProgram['grid'][channel.short|uppercase][weekDay.short]['coPart']">
                <ng-container *ngIf="!coPart['SOREACH_PURCHASE'] && coPart['PURCHASE'] && coPart['PRODUCT']">
                  <div class="co-part" [ngClass]="'co-part-' + coPart['TYPE_PURCHASE']" [matTooltip]="buildTooltip(coPart)" matTooltipClass="styleTooltip" fxFlex>
                    <a class="coPart-name" [routerLink]="['/purchases', 'edit', coPart['PURCHASE']]" target="_blank">{{ coPart['PRODUCT'] }}</a>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </fieldset>
        </ng-container>
      </div>
    </div>
  </div>
</div>
