import { Base } from './base.resource';

export interface JsonPurchaseGrp {
  week_count: number;
  estimated_purchase_grp: number;
  estimated_purchase_grp_for_purchase: number;
  estimated_purchase_coverage: number;
  estimated_purchase_coverage_for_purchase: number;
  estimated_purchase_repeat: number;
  estimated_purchase_repeat_for_purchase: number;
}

export class PurchaseGrp extends Base {
  public weekCount: number;
  public estimatedPurchaseGrp: number;
  public estimatedPurchaseGrpForPurchase: number;
  public estimatedPurchaseCoverage: number;
  public estimatedPurchaseCoverageForPurchase: number;
  public estimatedPurchaseRepeat: number;
  public estimatedPurchaseRepeatForPurchase: number;

  constructor(jsonPurchaseGrp: JsonPurchaseGrp) {

    super();
    jsonPurchaseGrp = this.convertToUnderscore(jsonPurchaseGrp);

    this.weekCount = jsonPurchaseGrp.week_count;
    this.estimatedPurchaseGrp = jsonPurchaseGrp.estimated_purchase_grp;
    this.estimatedPurchaseGrpForPurchase = jsonPurchaseGrp.estimated_purchase_grp_for_purchase;
    this.estimatedPurchaseCoverage = jsonPurchaseGrp.estimated_purchase_coverage;
    this.estimatedPurchaseCoverageForPurchase = jsonPurchaseGrp.estimated_purchase_coverage_for_purchase;
    this.estimatedPurchaseRepeat = jsonPurchaseGrp.estimated_purchase_repeat;
    this.estimatedPurchaseRepeatForPurchase = jsonPurchaseGrp.estimated_purchase_repeat_for_purchase;
  }
}
