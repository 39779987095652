import { FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';

import { GrpService } from './grp.service';
import { PurchaseService } from '../purchase/purchase.service';


@Injectable()
export class GrpFormService {

  constructor(
      private purchaseService: PurchaseService,
      private grpService: GrpService
  ) { }

  /**
   * Update Grp and its cost
   *
   * @param {any} grpForm
   * @returns {void}
   *
   * @memberOf GrpFormService
   */
  public update(grpForm: FormGroup, controlName: string): void {
    if (!grpForm && !grpForm.valid) {
      return;
    }

   this.purchaseService.purchase[controlName] = grpForm.get(controlName).value;

    const cgrp = this.computeCgrp(grpForm.get(controlName).value);
    switch (controlName) {
      case 'grp':
          grpForm.get('cgrp').setValue(cgrp);
          this.purchaseService.purchase.cgrp = grpForm.get('cgrp').value;
      break;

      case 'estimatedGrp':
        grpForm.get('estimatedCgrp').setValue(cgrp);
        this.purchaseService.purchase.estimatedCgrp = grpForm.get('estimatedCgrp').value;
      break;
    }

    this.purchaseService.onDataChange();
  }

  /**
   * Compute Grp using new Grp or estimatedGrp
   *
   * @private
   * @param {number} grp
   * @returns {number}
   * @memberOf GrpFormService
   */
  private computeCgrp(grp: number): number {
    return this.grpService.getCost(grp, this.purchaseService.purchase.tvClassicBudgetNet,
      this.purchaseService.purchase.averageDurationRate);
  }
}
