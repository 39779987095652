
export interface JsonDurationRate {
    id: number;
    year: number;
    channelId: string;
    areaId: string;
    rate:  number
}

export class DurationRate {
    id: number;
    year: number;
    channelId: string;
    areaId: string;
    rate:  number

    constructor(json: JsonDurationRate) {
        this.id = json.id;
        this.year = json.year;
        this.channelId = json.channelId;
        this.areaId = json.areaId;
        this.rate = json.rate;
    }
}
