import { Component, OnInit, Input, OnChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';

import { PurchaseBudgetService } from '../../../../../../../service/purchase-budget/purchase-budget.service';
import {
  AddBroadcastPurchasedFilter,
  BroadcastPurchasedService
} from '../../../../../../../service/broadcast-purchased/broadcast-purchased.service';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';
import { FiltersService } from '../../../../../../availability/service/filters.service';
import { PurchaseService } from '../../../../../../../service/purchase/purchase.service';
import { PurchaseGrpService } from '../../../../../../../service/purchase-grp/purchase-grp.service';
import { QueueLogService } from '../../../../../../../service/queue-log/queue-log.service';

import { PurchaseProgram } from '../../../../../../../resource/purchaseProgram.resource';
import { QueueLog } from '../../../../../../../resource/queue-log.resource';
import { Purchase } from '../../../../../../../resource/purchase.resource';
import { Channel } from 'src/app/resource/channel.resource';

import {delay, takeUntil} from 'rxjs/operators';
import { Subject } from 'rxjs';

import * as moment from 'moment';



@Component({
  selector: 'app-broadcast-add',
  templateUrl: './broadcast-add.component.html',
  styleUrls: ['./broadcast-add.component.scss'],
  providers: [FiltersService]
})
export class BroadcastAddComponent implements OnInit, OnChanges, OnDestroy {

  @Input() purchaseProgram: PurchaseProgram;
  @Input() purchase: Purchase;

  @Output() addBroadcastEmitter = new EventEmitter<boolean>();

  public broadcastFrom: FormControl;
  public broadcastTo: FormControl;

  public addFilter: AddBroadcastPurchasedFilter;
  public adding: boolean = false;
  public programIsDeactivated: boolean = false;

  private componentDestroyed: Subject<any> = new Subject();

  constructor(
    private broadcastPurchasedService: BroadcastPurchasedService,
    private purchaseService: PurchaseService,
    private purchaseBudgetService: PurchaseBudgetService,
    private queueLogService: QueueLogService,
    private purchaseGrpService: PurchaseGrpService,
    private customToastrService: CustomToastrService,
  ) { }

  ngOnInit() {
    this.broadcastFrom = new FormControl();
    this.broadcastTo = new FormControl();

    this.queueLogService.queueLogSource$.subscribe(queueLog => {
      if (this.queueLogService.purchaseFrom === QueueLog.PURCHASE_BROADCAST) {
        if (queueLog.length === 0) {
            this.adding = false;
            this.purchaseService.getUpdatedPurchase(); // Update purchase
            this.purchaseGrpService.updatePurchaseGrp(this.purchase.id);
            this.broadcastPurchasedService.getBroadcastsPurchased()
            .subscribe(
              (result) => {
                this.broadcastPurchasedService.updateLoading(false);
                this.addBroadcastEmitter.emit(true);
              }
            );
        } else {
          if (queueLog[0].okCount === queueLog[0].totalCount) {
            this.adding = false;
            this.purchaseService.getUpdatedPurchase(); // Update purchase
            this.purchaseGrpService.updatePurchaseGrp(this.purchase.id);
            this.broadcastPurchasedService.getBroadcastsPurchased().subscribe(
              result => {
                this.broadcastPurchasedService.updateLoading(false);
              }
            );
          }
        }
      }
    });

    this.broadcastFrom.valueChanges.pipe(
      takeUntil(this.componentDestroyed))
      .subscribe((data) => this.addFilter.broadcasted_from = data);

    this.broadcastTo.valueChanges.pipe(
      takeUntil(this.componentDestroyed))
      .subscribe((data) => this.addFilter.broadcasted_to = data);
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  ngOnChanges(changes: any): void {
    if (changes.purchaseProgram) {
      this.initAddFilter();
      this.broadcastPurchasedService.init(this.purchaseProgram.offerProgram, this.purchase, this.purchaseProgram);
      if (this.purchaseProgram.offerProgram && this.purchaseProgram.offerProgram.status === 0) {
        this.programIsDeactivated = true;
      } else {
        this.programIsDeactivated = false;
      }
    }
  }

  addBroadcasts() {
    this.addFilter.broadcasted_from = moment(this.broadcastFrom.value).format('YYYY-MM-DD');
    this.addFilter.broadcasted_to = moment(this.broadcastTo.value).format('YYYY-MM-DD');

    if (this.purchase && this.purchase.soreachPurchase && this.purchaseProgram
      && this.purchaseProgram.area && this.purchaseProgram.area.channel && this.purchaseProgram.area.channel.channelGroup
      && this.purchaseProgram.area.channel.channelGroup !== Channel.THEMA_CHANNEL_GROUP) {
      this.addFilter.soreach = this.purchase.soreachPurchase;
    }

    if (!this.addFilter.broadcasted_from || !this.addFilter.broadcasted_to || this.adding || !this.purchase.id) {
      return null;
    }

    this.adding = true;
    this.broadcastPurchasedService.updateLoading(true);
    this.broadcastPurchasedService.updateEditing(true);
    this.purchaseBudgetService.updateGlobalBudgetLoading(true);
    this.broadcastPurchasedService
      .addBroadcastsPurchased(this.addFilter)
      .pipe(delay(1500))
      .subscribe(() => {
        this.queueLogService.checkQueueProgressOnTimer(QueueLog.QUEUE_NAME_BROADCAST_PURCHASED,
          this.purchase.id,
          QueueLog.PURCHASE_BROADCAST,
          true);
      });
  }

  private initAddFilter() {
    this.addFilter = {
      offer_program_id: this.purchaseProgram.offerProgram.id,
      purchase_id: this.purchase.id,
      broadcasted_from: '',
      broadcasted_to: '',
    };
  }

}
