import { ErrorHandler, Injectable, Injector} from '@angular/core';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

@Injectable()
export class UtilitiesHandler implements ErrorHandler {
  email: string;
  trigramme: string;

  constructor(private injector: Injector) {
    this.feedInformationUser();
  }

  /**
   * HANDLE ERROR => FEATURE LOG ADSPACE
   * /!\ FOR INTECEPTOR ERROR /!\
   *
   * @returns {void}
   * @param {Error} error
   * @memberof ErrorsHandler
   */
  handleError(error: any): void {
    if (typeof error === 'string' && error.includes('403')) {
      this.redirection403();
    }
    console.error(error);
  }

  /**
   * HANDLE ERROR API => FEATURE LOG ASPACE // TODO
   *
   * @param {HttpErrorResponse} error
   * @returns {Observable<never>}
   * @memberof ErrorsHandler
   */
  handleErrorApi(error: HttpErrorResponse): Observable<never> {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
        switch (error.status) {
          case 403:
            // console.error(error.message);
            break;
          case 422:
            // console.error(error.message);
            break;
          default:
            // console.error(error.message);
            break;
        }
    }

    if (error && error.error && error.error) {
      throw error.error;
    } else {
      throw error.message;
    }
  };

  /**
   * Build automatically params for API url
   *
   * @param {(Object)} filters
   * @returns {HttpParams}
   * @memberof ErrorsHandler
   */
  buildParamsApi(filters: Object): HttpParams {
    let params = new HttpParams();

    if (filters && typeof filters === 'object') {
      Object.entries(filters).forEach((item) => {
        params = <HttpParams>params.append(item[0], item[1]);
      });
    }

    return params;
  }

  /**
   * insert information user in email/trigramme variable
   *
   * @private
   * @returns {void}
   * @memberof ErrorsHandler
   */
  private feedInformationUser(): void {
    this.email = localStorage.getItem('email');
    this.trigramme = localStorage.getItem('trigramme');
  }

  /**
   * Redirection 403 if user token
   *
   * @private
   * @memberof UtilitiesHandler
   */
  private redirection403(): void {
    const router = this.injector.get(Router);

    router.navigate(['/']);
    localStorage.removeItem('access_token');
    localStorage.removeItem('email');
    localStorage.removeItem('trigramme');
    localStorage.removeItem('parameter');
  }
}
