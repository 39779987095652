import { tap, map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { OfferProgramBudgetPackage } from '../../resource/offerProgramBudgetPackage.resource';
import { Schedule } from '../../resource/schedule/schedule.resource';
import { OfferProgram } from '../../resource/offerProgram.resource';
import { Purchase } from '../../resource/purchase.resource';
import { Broadcast } from '../../resource/broadcast.resource';
import { Channel } from 'src/app/resource/channel.resource';
import { BroadcastPurchased } from '../../resource/broadcast-purchased.resource';
import { PurchaseProgram } from '../../resource/purchaseProgram.resource';

import { BroadcastService } from '../broadcast/broadcast.service';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';
import { SchedulePurchasedService } from '../schedule/schedule-purchased.service';
import { ScheduleService } from '../schedule/schedule.service';

import { UtilitiesHandler } from '@service/utilities-handlers/utilitiesHandlers';
import { environment } from '../../../environments/environment';

import { Observable, Subject } from 'rxjs';

import * as moment from 'moment';

const MAX_SIZE_POST = 100;

export interface AddBroadcastPurchasedFilter {
  offer_program_id: string;
  broadcasted_from: string;
  broadcasted_to: string;
  purchase_id: number;
  soreach?: number;
}

@Injectable()
export class BroadcastPurchasedService {
  private route: string = 'broadcast_purchased';

  public purchase: Purchase;
  public offerProgram: OfferProgram;
  public purchaseProgram: PurchaseProgram;
  public addFilter: AddBroadcastPurchasedFilter;

  public broadcasts: Broadcast[];
  public filteredBroadcastsPurchased: BroadcastPurchased[] = [];
  public broadcastsPurchased: BroadcastPurchased[];

  public parentBroadcastIds: any[] = [];
  public broadcastsAdded: BroadcastPurchased[] = [];

  private pageCount: number = 0;
  private pageNumber: number = 0;

  private loadingSource = new Subject<boolean>();
  public loadingSource$ = this.loadingSource.asObservable();

  private editingSource = new Subject<boolean>();
  public editingSource$ = this.editingSource.asObservable();

  private deletingSource = new Subject<boolean>();
  public deletingSource$ = this.deletingSource.asObservable();

  private broadcastPurchasedList = new Subject<BroadcastPurchased[]>();
  public broadcastPurchasedList$ = this.broadcastPurchasedList.asObservable();

  private removeScheduleFromList = new Subject<Schedule>();
  public removeScheduleFromList$ = this.removeScheduleFromList.asObservable();

  public schedulesPurchasedByOfferProgram: Schedule[];
  public schedulesByDatesAndOfferProgram: Schedule[];
  public schedulesToClone: Schedule[];
  public offerProgramBudgetPackages: OfferProgramBudgetPackage[];

  constructor(
    private broadcastService: BroadcastService,
    private scheduleService: ScheduleService,
    private schedulePurchasedService: SchedulePurchasedService,
    private http: HttpClient,
    private customToastrService: CustomToastrService,
    private utilitiesHandler: UtilitiesHandler
  ) { }

  /**
   * Get list of Broadcast purchased
   *
   * @param {Object} filters
   * @returns {Observable<BroadcastPurchased[]>}
   * @memberof BroadcastPurchasedService
   */
  public getList(filters: Object): Observable<BroadcastPurchased[]> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params}).pipe(
      map((data: any) =>
        data._embedded.broadcast_purchased
          .map((broadcastPurchased: any) =>
            new BroadcastPurchased(broadcastPurchased)
          )
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get complete list of broadcast purchase
   *
   * @param {Object} filters
   * @returns {Observable<any>}
   * @memberof BroadcastPurchasedService
   */
  public getCompleteList(filters: Object): Observable<any> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return Observable.create(observer => {
      this.initPagination();
      this.http
        .get(api_base_url, {params})
        .pipe(
          tap((x: any) => { this.pageCount = x.page_count}),
          map((data: any) =>
            data._embedded.broadcast_purchased
              .map((broadcast) =>
                new BroadcastPurchased(broadcast)
              )
          ),
          catchError(this.utilitiesHandler.handleErrorApi)
        )
        .subscribe(broadcasts => {
          this.broadcastsPurchased = broadcasts;
          this.paginateResult(filters)
            .subscribe(() => {
              observer.next(this.broadcastsPurchased);
            }, (error) => {
              this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            });
        }, (error) => {
          this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
        })
    });
  }

  /**
   * Paginate result
   *
   * @param {*} filters
   * @returns {Observable<any>}
   * @memberof BroadcastPurchasedService
   */
  public paginateResult(filters): Observable<any> {
    return Observable.create(observer => {
        if (this.pageCount <= 1) {
          observer.next();
        }

        let count = 1;

        while (this.pageNumber < this.pageCount) {
          this.pageNumber++
          filters.page = this.pageNumber;
          this.getList(filters)
            .subscribe(broadcastsPurchased => {
              this.broadcastsPurchased = this.broadcastsPurchased.concat(broadcastsPurchased);
              if (this.checkActionCount(this.pageCount, ++count)) {
                observer.next();
              }
          }, (error) => {
            this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
          })
        }
    })
  }

  /**
   * Post broadcast
   *
   * @param {*} broadcastPurchased
   * @param {boolean} [collection=false]
   * @returns {Observable<Object>}
   * @memberof BroadcastPurchasedService
   */
  public post(broadcastPurchased, collection: boolean = false): Observable<Object> {
    const data = collection ? broadcastPurchased.map(value => this.extract(value)) : this.extract(broadcastPurchased);
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;

    return this.http
      .post(api_base_url, data)
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Post paginated collection
   *
   * @param {BroadcastPurchased[]} broadcastPurchased
   * @returns {Observable<any>}
   * @memberof BroadcastPurchasedService
   */
  public postPaginatedCollection(broadcastPurchased: BroadcastPurchased[]): Observable<any> {
    return Observable.create(observer => {
      return this.post(broadcastPurchased, true)
        .subscribe(result => {
          if (result && result['_embedded']) {
            this.customToastrService.displayToastr('WARNING', result['_embedded'].length +
            ' diffusions n\'ont pas été liés à ce dossier car elles ne sont plus disponibles.');
          }
          observer.next(result);
        })
    })
  }

  /**
   * Get splited post array
   *
   * @param {*} array
   * @returns {Array<any>}
   * @memberof BroadcastPurchasedService
   */
  public getSplitedPostArray(array): Array<any> {
    let result = [];
    while (array.length > 0) {
      result.push(array.splice(0, MAX_SIZE_POST));
    }

    return result;
  }

  /**
   * Delete broadcast with ID
   *
   * @param {BroadcastPurchased} broadcastPurchased
   * @returns {Observable<Object>}
   * @memberof BroadcastPurchasedService
   */
  public delete(broadcastPurchased: BroadcastPurchased): Observable<Object> {
    const api_base_url = `${environment.api_base_url}/${this.route}/${broadcastPurchased.id}`;

    return this.http
      .delete(api_base_url)
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      );
  }

  /**
   * Delete list of broadcast purchased
   *
   * @param {BroadcastPurchased[]} broadcastPurchaseds
   * @param {number} purchaseId
   * @param {number} purchaseProgramId
   * @returns {Observable<Object>}
   * @memberof BroadcastPurchasedService
   */
  public deleteList(broadcastPurchaseds: BroadcastPurchased[], purchaseId: number, purchaseProgramId: number): Observable<Object> {
    const api_base_url = `${environment.api_base_url}/${this.route}`;

    return this.http
      .request('delete', api_base_url,
        {
          body: {
            ids: broadcastPurchaseds.map(broadcastPurchased => broadcastPurchased.id),
            purchase_id: purchaseId,
            purchase_program_id: purchaseProgramId,
          }
        }
      )
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      );
  }

  /**
   * Init
   *
   * @param {OfferProgram} offerProgram
   * @param {Purchase} purchase
   * @param {PurchaseProgram} purchaseProgram
   * @returns {void}
   * @memberof BroadcastPurchasedService
   */
  public init(offerProgram: OfferProgram, purchase: Purchase, purchaseProgram: PurchaseProgram): void {
    this.purchase = purchase;
    this.offerProgram = offerProgram;
    this.purchaseProgram = purchaseProgram;
  }

  /**
   * Reset value
   *
   * @returns {void}
   * @memberof BroadcastPurchasedService
   */
  public reset(): void {
    this.purchase = null;
    this.offerProgram = null;
    this.broadcasts = [];
    this.broadcastsPurchased = [];
    this.schedulesPurchasedByOfferProgram = [];
    this.schedulesByDatesAndOfferProgram = [];
    this.schedulesToClone = [];
    this.parentBroadcastIds = [];
    this.offerProgramBudgetPackages = [];
    this.filteredBroadcastsPurchased = [];
    this.broadcastsAdded = [];
  }

  /**
   * Set add filter, build new Object
   *
   * @return {void}
   * @param {AddBroadcastPurchasedFilter} addFilter
   * @memberof BroadcastPurchasedService
   */
  public setAddFilter(addFilter: AddBroadcastPurchasedFilter): void {
    this.addFilter = {
      broadcasted_from: moment(addFilter.broadcasted_from, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD'),
      broadcasted_to: moment(addFilter.broadcasted_to, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD'),
      offer_program_id: addFilter.offer_program_id,
      purchase_id: addFilter.purchase_id
    };
  }

  /**
   * Update loading
   *
   * @return {void}
   * @param {*} value
   * @memberof BroadcastPurchasedService
   */
  public updateLoading(value): void {
    this.loadingSource.next(value);
  }

  /**
   * Update editing
   *
   * @return {void}
   * @param {boolean} value
   * @memberof BroadcastPurchasedService
   */
  public updateEditing(value: boolean): void {
    this.editingSource.next(value);
  }

  /**
   * Update deleting
   *
   * @return {void}
   * @param {boolean} value
   * @memberof BroadcastPurchasedService
   */
  public updateDeleting(value: boolean): void {
    this.deletingSource.next(value);
  }

  /**
   * Add broadcasts purchased
   *
   * @param {AddBroadcastPurchasedFilter} addFilter
   * @returns {Observable<any>}
   * @memberof BroadcastPurchasedService
   */
  public addBroadcastsPurchased(addFilter: AddBroadcastPurchasedFilter): Observable<any> {
    const filters = {
      broadcasted_from: addFilter.broadcasted_from,
      broadcasted_to: addFilter.broadcasted_to,
      offer_program_id: addFilter.offer_program_id,
      groups: 'purchase'
    };

    if (addFilter.soreach) {
      filters['soreach'] = addFilter.soreach;
    }

    return Observable.create(observer => {
      this.setAddFilter(addFilter);
      this.broadcastService
        .getCompleteList(filters)
        .subscribe((broadcasts: any) => {
          this.broadcasts = broadcasts;
          this.getList(this.addFilter)
            .subscribe(broadcastsPurchased => {
              this.filteredBroadcastsPurchased = broadcastsPurchased;

              this.broadcastsAdded = this.getBroadcastPurchasedToCreate();

              this.manageBroadcastPurchased().subscribe(() => {
                observer.next();
                return null;
              });
            }, (error) => {
              this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            });
        }, (error) => {
          this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
        });
    });
  }

  /**
   * Create roadcast purchased from availibilty
   *
   * @param {Broadcast[]} broadcasts
   * @returns {Observable<any>}
   * @memberof BroadcastPurchasedService
   */
  public createBroadcastPurchasedFromAvailibilty(broadcasts: Broadcast[]): Observable<any> {
    return Observable.create(observer => {
      const filters = {
        offer_program_id: this.offerProgram.id,
        broadcasted_from: this.broadcastService.getTimesFromBroadcasts(broadcasts)['startTime'],
        broadcasted_to:  this.broadcastService.getTimesFromBroadcasts(broadcasts)['endTime'],
        purchase_id: this.purchase.id,
      };

      if (this.purchase.soreachPurchase) {
        filters['soreach'] = this.purchase.soreachPurchase;
      }

      this.schedulesPurchasedByOfferProgram = [];
      this.broadcasts = broadcasts;
      this.broadcastsAdded = this.getBroadcastPurchasedToCreate();

      this.setAddFilter(filters);

      this.manageBroadcastPurchased()
        .subscribe(() => {
          observer.next();
        }, () => {
          this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
        });
    });
  }

  /**
   * Manage broadcast purchased
   *
   * @returns {Observable<any>}
   * @memberof BroadcastPurchasedService
   */
  public manageBroadcastPurchased(): Observable<any> {
    return Observable.create(observer => {
      if (this.broadcastsAdded.length) {
        this.manageSchedulesToClone()
            .subscribe(() => {
              this.postPaginatedCollection(this.broadcastsAdded)
                  .subscribe(() => {
                    this.getBroadcastsPurchased()
                        .subscribe(() => {
                          observer.next();
                        }, () => {
                          this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
                        })
                  }, () => {
                    this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
                  })
            }, () => {
              this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            });
      } else {
        observer.next();
      }
    })
  }

  /**
   * Get broadcasts purchased
   *
   * @returns {Observable<any>}
   * @memberof BroadcastPurchasedService
   */
  public getBroadcastsPurchased(): Observable<any> {
    return Observable.create(observer => {
      this.getCompleteList({
        offer_program_id: this.purchaseProgram.offerProgram.id,
        purchase_id: this.purchase.id,
        page: 1,
        groups: 'purchase'
      }).subscribe(result => {
        if (result && result.length > 0) {
          let purchaseYear = {
            purchaseYear: moment(result[0].startTime).year()
          };
          Object.assign(this.purchase, purchaseYear);
        }
        this.broadcastsPurchased = result;
        this.broadcastPurchasedList.next(this.broadcastsPurchased);
        observer.next();
      }, (error) => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });
    });
  }

  /**
   * Remove broadcast purchased
   *
   * @param {BroadcastPurchased} broadcastPurchased
   * @memberof BroadcastPurchasedService
   */
  public removeBroadcastPurchased(broadcastPurchased: BroadcastPurchased): void {
    let newArray = [];

    this.broadcastsPurchased.forEach(item => {
      if (broadcastPurchased.id !== item.id) {
        newArray.push(item);
      }
    });

    this.broadcastsPurchased = newArray;
    this.manageScheduleToDelete();
    this.broadcastPurchasedList.next(this.broadcastsPurchased);
  }

  /**
   * Get broadcast purchased to create
   *
   * @returns {Array<any>}
   * @memberof BroadcastPurchasedService
   */
  public getBroadcastPurchasedToCreate(): Array<any> {
    let result = [];

    this.broadcasts.forEach(broadcast => {
      if (!broadcast.broadcastType) {

        let alreadyExist = this.filteredBroadcastsPurchased.some(broadcastPurchased =>
          broadcastPurchased.broadcast.id === +broadcast.id
        );

        if (!alreadyExist) {
          result.push(new BroadcastPurchased({
            id : null,
            offer_program: this.offerProgram,
            purchase: this.purchase,
            broadcast: broadcast,
            status: BroadcastPurchased.BOOKED
          }));
        }
      }
    });

    return result;
  }

  /**
   * Check Action count
   *
   * @param {*} totalActions
   * @param {*} actionCount
   * @returns {boolean}
   * @memberof BroadcastPurchasedService
   */
  public checkActionCount(totalActions, actionCount): boolean {
    return totalActions === actionCount;
  }

  /**
   * Build new Object
   *
   * @param {BroadcastPurchased} broadcastPurchased
   * @returns {Object}
   * @memberof BroadcastPurchasedService
   */
  public extract(broadcastPurchased: BroadcastPurchased): Object {
    return {
      id: broadcastPurchased.id,
      broadcast: broadcastPurchased.broadcastId,
      offer_program: broadcastPurchased.offerProgram.id,
      purchase: broadcastPurchased.purchase.id,
      purchase_program: this.purchaseProgram ? this.purchaseProgram.id : null
    }
  }

 /**
  * Get schedules to clone
  *
  * @returns {Observable<any>}
  * @memberof BroadcastPurchasedService
  */
 public getSchedulesToClone(): Observable<any> {
    return Observable.create(observer => {
      this.getSchedulesByDatesAndOfferProgram()
        .subscribe(() => {
          const schedulesToClone = [];
          this.schedulesByDatesAndOfferProgram.forEach(schedule => {
            if (this.checkIfScheduleHasAlreadyBeenCloned(schedule)) {
              schedulesToClone.push(schedule);
            }
          });
          observer.next(schedulesToClone);
        }, () => {
          this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
        });
    })
  }

  /**
   * Get schedules by dates and offer program
   *
   * @returns {Observable<any>}
   * @memberof BroadcastPurchasedService
   */
  public getSchedulesByDatesAndOfferProgram(): Observable<any> {
    return Observable.create(observer => {
      const filters = {
        broadcasted_from: this.addFilter.broadcasted_from,
        broadcasted_to: this.addFilter.broadcasted_to,
        offer_program_id: this.addFilter.offer_program_id,
        is_purchased: 0,
        groups: 'addingBroadcast'
      };

      if (this.purchase.soreachPurchase && this.offerProgram && this.offerProgram.area && this.offerProgram.area.channel
        && this.offerProgram.area.channel && this.offerProgram.area.channel.channelGroup
        && this.offerProgram.area.channel.channelGroup !== Channel.THEMA_CHANNEL_GROUP) {
        filters['soreach'] = this.purchase.soreachPurchase;
      }

      this.scheduleService
        .getList(filters)
        .subscribe(result => {
          this.schedulesByDatesAndOfferProgram = result;
          observer.next();
        }, () => {
          this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
        })
    })
  }

  /**
   * ManageSchedule to delete // Schedule Part REMOVE
   *
   * @return {void}
   * @memberof BroadcastPurchasedService
   */
  public manageScheduleToDelete(): void {
    this.broadcastService.getCompleteList({offer_program_id: this.purchaseProgram.offerProgram.id})
      .subscribe((broadcasts: any) => {
        let schedulesReallyPurchased = {};

        broadcasts.forEach((broadcast: Broadcast) => {
          let isPurchased = this.broadcastsPurchased.filter(item => item.broadcastId === broadcast.id)
          if (isPurchased.length) {
            broadcast.schedules.forEach(schedule => {
              schedulesReallyPurchased[schedule.id] = schedule;
            })
          }
        });

        let scheduleToRemove = this.getScheduleToRemove(schedulesReallyPurchased);

        if (scheduleToRemove.length) {
          this.removeSchedules(scheduleToRemove)
            .subscribe(() => {
              scheduleToRemove.forEach(schedule => {
                this.removeScheduleFromList.next(schedule);
              });
            }, (error) => {
              this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            });
        }
      }, (error) => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      })
  }

  /**
   * Get Schedule to remove
   *
   * @private
   * @param {*} schedulesReallyPurchased
   * @returns {Array<any>}
   * @memberof BroadcastPurchasedService
   */
  private getScheduleToRemove(schedulesReallyPurchased): Array<any> {
    let scheduleToRemove = [];
    let schedulesReallyPurchasedKeys = Object.keys(schedulesReallyPurchased);

    this.schedulesPurchasedByOfferProgram.forEach(schedule => {
        if (schedulesReallyPurchasedKeys.indexOf(schedule.parent.id.toString()) === -1) {
            scheduleToRemove.push(schedule);
        }
    });

    return scheduleToRemove;
  }

  /**
   * Remove schedules
   *
   * @private
   * @param {*} schedules
   * @returns {Observable<any>}
   * @memberof BroadcastPurchasedService
   */
  private removeSchedules(schedules): Observable<any> {
    return Observable.create(observer => {
      let count = 0;
      schedules.forEach(schedule => {
          this.scheduleService.delete(schedule)
            .subscribe(() => {
              if (this.checkActionCount(schedules.length, ++count)) {
                  observer.next();
                }
            }, (error) => {
              this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            });
        })
    })
  }

  /**
   * Manage schedules to clone // Schedule Part ADD
   *
   * @private
   * @returns {Observable<any>}
   * @memberof BroadcastPurchasedService
   */
  private manageSchedulesToClone(): Observable<any> {
    return Observable.create(observer => {
      this.getSchedulesToClone()
        .subscribe(schedulesToClone => {
          if (!schedulesToClone.length) {
            observer.next();
          }

          let count = 0;
          this.schedulesToClone = schedulesToClone;

          this.schedulesToClone.forEach(schedule => {
            this.schedulePurchasedService
              .cloneSchedule(schedule, this.purchase, this.purchaseProgram)
              .subscribe(newScheduleId => {
                // get new schedule
                this.scheduleService.get(newScheduleId)
                  .subscribe(newSchedule => {
                    // update list of schedules
                    this.schedulesPurchasedByOfferProgram.push(newSchedule);
                    if (this.checkActionCount(this.schedulesToClone.length, ++count)) {
                      observer.next();
                    }
                  }, () => {
                    this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
                  });
              }, () => {
                this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
              });
          });
        }, () => {
          this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
        });
    });
  }

  /**
   * Check if scheduke has already been cloned
   *
   * @private
   * @param {*} schedule
   * @returns {boolean}
   * @memberof BroadcastPurchasedService
   */
  private checkIfScheduleHasAlreadyBeenCloned(schedule): boolean {
    let result = true;

    if (!this.schedulesPurchasedByOfferProgram) {
      this.schedulesPurchasedByOfferProgram = [];
      return true;
    }

    const scheduleAlreadyCloned = this.schedulesPurchasedByOfferProgram.filter(item => item.parent.id === schedule.id);

    if (scheduleAlreadyCloned.length) {
      result = false;
    }

    return result;
  }


  /**
   * Get schedule by broadcast purchased
   *
   * @private
   * @param {BroadcastPurchased} broadcastPurchased
   * @returns {Schedule}
   * @memberof BroadcastPurchasedService
   */
  private getScheduleByBroadcastPurchased(broadcastPurchased: BroadcastPurchased): Schedule {
    let result = null;
    this.schedulesPurchasedByOfferProgram.forEach(schedule => {
      broadcastPurchased.broadcast.schedules.forEach(offerSchedule => {
        if (offerSchedule.id === schedule.parent.id) {
          result = schedule;
        }
      })
    })

    return result;
  }

  /**
   * Init pagination
   *
   * @returns {void}
   * @private
   * @memberof BroadcastPurchasedService
   */
  private initPagination(): void {
    this.pageCount = 0;
    this.pageNumber = 1;
    this.broadcastsPurchased = [];
  }
}
