
export interface JsonSecodip {
    id: string;
    name: string;
}

export class Secodip {
    public id: string;
    public name: string;

    constructor(jsonOffer: JsonSecodip) {
        this.id = jsonOffer.id;
        this.name = jsonOffer.name;
    }
}
