import { Abatement } from './abatement.resource';
import { Purchase } from './purchase.resource';
import { Base } from './base.resource';

const TV_BUDGET_UNIT = 'tvBudget';
const CA_REF_UNIT       = 'caRef';
const CA_FACT_UNIT      = 'caFact';

export interface JsonPurchaseAbatement {
    id?: number;
    purchase?: any;
    abatement?: any;
    rate?: number;
    amount?: number;
    base?: number;
    purchase_abatement_order?: number;
    channel_group?: string;
    created?: string;
    updated?: string;
}

export class PurchaseAbatement extends Base {

    public static TV_BUDGET_UNIT = TV_BUDGET_UNIT;
    public static CA_REF_UNIT       = CA_REF_UNIT;
    public static CA_FACT_UNIT      = CA_FACT_UNIT;

    public id: number;
    public purchase: Purchase;
    public abatement: Abatement;
    public rate: number;
    public amount: number;
    public base: number;
    public purchaseAbatementOrder: number;
    public channelGroup: String;
    public created: string;
    public updated: string;

    constructor(json: JsonPurchaseAbatement) {
        super();
        json = this.convertToUnderscore(json);

        this.id = json.id;
        this.abatement = json.abatement;
        this.rate = json.rate;
        this.amount = json.amount;
        this.base = json.base;
        this.purchaseAbatementOrder = json.purchase_abatement_order;
        this.channelGroup = json.channel_group;
        this.created = json.created;
        this.updated = json.updated;

        if (json.purchase) {
          this.purchase = new Purchase(json.purchase);
        }

        if (json.abatement) {
          this.abatement = new Abatement(json.abatement)
        }
    }
}
