
import { take, refCount, publishReplay, map,  catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Abatement } from '../../resource/abatement.resource';
import { UtilitiesHandler } from '@service/utilities-handlers/utilitiesHandlers';

import { of, Observable } from 'rxjs';

@Injectable()
export class AbatementService {
  private dataCache: Observable<Abatement>[] = [];

  constructor(
    private http: HttpClient,
    private utilitiesHandler: UtilitiesHandler) {}

  /**
   * Get the list of Abatements
   *
   * @param {Object} [filters=null]
   * @returns {Observable<Abatement[]>}
   * @memberof AbatementService
   */
  public getList(filters: Object = null): Observable<Abatement[]> {
    const api_base_url: string = `${environment.api_base_url}/abatement`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);
    const query = JSON.stringify(filters);

    if (!this.dataCache[query]) {
      return this.http
        .get(api_base_url, {params})
        .pipe(
          map((data: any) => {
            this.dataCache[query] = data._embedded.abatement
              .map((abatement: any) =>
                new Abatement(abatement)
              )
            return this.dataCache[query];
          }),
          publishReplay(1, 4000),
          refCount(),
          take(1),
          catchError(this.utilitiesHandler.handleErrorApi)
        )
    } else {
      return of(this.dataCache[query]);
    }
  }

  /**
   * Sort Abatement
   *
   * @param {*} abatements
   * @returns {Array<any>}
   * @memberof AbatementService
   */
  public sortAbatements(abatements): Array<any>  {
    abatements.sort((a, b) => a.category - b.category);

    let categories = [];
    abatements.forEach(abatement => {
      if (!categories[abatement.category]) {
        categories[abatement.category] = [];
      }
      categories[abatement.category].push(abatement)
    })

    let sortedAbatements = [];
    categories.forEach(category => {
      category.sort((a, b) => a.abatementOrder - b.abatementOrder);
      category.forEach(abatement => {
        sortedAbatements.push(abatement);
      })
    })

    return sortedAbatements;
  }
}
