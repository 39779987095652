
export interface JsonAdvertisingPackage {
    id: number;
    name?: string;
    game?: boolean;
    type?: string;
}

const PRE_GENERIC = 0;
const POST_GENERIC = 1;
const PRE_GENERIC_WITH_GAME = 2;
const POST_GENERIC_WITH_GAME = 3;
const MINI_GENERIC_CUT = 4;
const MINI_GENERIC_RECOVERY = 5;
const BA = 6;
const REMINDER = 7;
const LOGO_INLAY = 52;

export class AdvertisingPackage {
    public id: number;
    public name: string;
    public game: boolean;
    public type: string;

    public static PRE_GENERIC = PRE_GENERIC;
    public static POST_GENERIC = POST_GENERIC;
    public static PRE_GENERIC_WITH_GAME = PRE_GENERIC_WITH_GAME;
    public static POST_GENERIC_WITH_GAME = POST_GENERIC_WITH_GAME;
    public static MINI_GENERIC_CUT = MINI_GENERIC_CUT;
    public static MINI_GENERIC_RECOVERY = MINI_GENERIC_RECOVERY;
    public static BA = BA;
    public static REMINDER = REMINDER;
    public static LOGO_INLAY = LOGO_INLAY;


    constructor(jsonAdvertisingPackage: JsonAdvertisingPackage) {
        this.id = jsonAdvertisingPackage.id;
        this.name = jsonAdvertisingPackage.name;
        this.game = jsonAdvertisingPackage.game;
        this.type = jsonAdvertisingPackage.type;
    }

    public getDefaultDuration() {
        if (this.game === true) {
            return 20;
        }

        switch (this.id) {
            case PRE_GENERIC:
            case POST_GENERIC:
                return 8;
            case MINI_GENERIC_CUT:
            case MINI_GENERIC_RECOVERY:
                return 4;
            case BA:
                return 6;
            case REMINDER:
                return 5;
            default:
                return 8;
        }
    }
}
