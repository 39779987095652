import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatAutocompleteSelectedEvent, MatSlideToggleChange } from '@angular/material';
import { FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

import { AreaService } from '@service/area/area.service';
import { ChannelService } from '@service/channel/channel.service';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';
import { OfferProgramGridSynthesisService } from '../../../service/offer-program-grid-synthesis.service';

import { Channel } from '../../../../../resource/channel.resource';
import { SynthesisFilter } from '../../../../../resource/synthesis.resource';
import { FilteredItem } from '../../../../../resource/filteredItem.resource';

import { Observable, of, Subject } from 'rxjs';
import { startWith, takeUntil, filter, debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-filters-synthesis',
  templateUrl: './filters-synthesis.component.html',
  styleUrls: ['./filters-synthesis.component.scss']
})
export class FiltersSynthesisComponent implements OnInit, OnDestroy {
  @ViewChild('channelInput') channelInput: ElementRef;
  @ViewChild('areaInput') areaInput: ElementRef;

  @Output() decreaseY: EventEmitter<void> = new EventEmitter();
  @Output() increaseY: EventEmitter<void> = new EventEmitter();

  @Input() filters: any;
  @Input() synthesisFilter: SynthesisFilter;
  @Input() sourceSynthesis;

  public loading = false;

  onlyExclusivesOffers = false;
  selectable: boolean = true;
  removable: boolean = true;
  addOnBlur: boolean = false;
  path: string = Channel.PATH;

  separatorKeysCodes = [ENTER, COMMA];

  areaCtrl = new FormControl();
  channelCtrl = new FormControl();
  categoryCtrl = new FormControl();
  offerNameCtrl = new FormControl();
  offerTypeCtrl = new FormControl();

  filteredAreas: Observable<FilteredItem[]>;
  filteredChannels: Observable<FilteredItem[]>;

  // Selected Offer Type : 0 => 'National', 1 => 'Région', 2 => 'So Reach', 3 => 'Outre Mer'
  selectedOfferType = 0;
  selectedChannels: FilteredItem[] = [];
  selectedAreas: FilteredItem[] = [];

  // All channels of France "over sea"
  overSeaChannels: FilteredItem[]  = [];

  // All channels of France "régie F"
  defaultChannels: FilteredItem[]  = [];

  allF3Areas: FilteredItem[] = [];
  allProgramCategories = [
    {value: 'A', name: 'FILMS-FICTIONS'},
    {value: 'C', name: 'DIVERTISSEMENTS-JEUX-VARIETES'},
    {value: 'D', name: 'MAGAZINES'},
    {value: 'DG', name: 'METEO NATIONALE'},
    {value: 'F', name: 'SPORT'},
    {value: 'G', name: 'JEUNESSE'},
    {value: 'AF', name: 'PROGRAMMES COURTS'},
    {value: 'X', name: 'METEO MULTI-REGIONALE'},
    {value: 'MER', name: 'METEO REGIONALE'},
    {value: 'MAR', name: 'MAGAZINE REGIONALE'},
    {value: 'ISR', name: 'INFO SERVICE REGIONALE'},
    {value: 'H', name: 'HISTOIRE ET SOCIETE'},
  ];

  private componentDestroyed: Subject<void> = new Subject();

  constructor(
    private offerProgramGridSynthesisService: OfferProgramGridSynthesisService,
    private customToastrService: CustomToastrService,
    private areaService: AreaService,
    private channelService: ChannelService) {
      // Default selected categories
      this.categoryCtrl.setValue(this.allProgramCategories.filter((category) => category.value !== 'AF'));
  }

  ngOnInit() {
    this.channelService.getListForFilter()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((channels: FilteredItem[]) => {
        this.overSeaChannels = channels.filter((item: FilteredItem) => item && item.entity && item.entity.channelGroup === 'O');
        this.defaultChannels = channels.filter((item: FilteredItem) => item && item.entity && item.entity.channelGroup === 'F');

        this.filteredChannels = of(this.defaultChannels);
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.areaService.getListForFilter({channel_id: 3})
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((areas: FilteredItem[]) => {
        // Exclude France 3 National area
        this.allF3Areas = areas.filter((item: FilteredItem) => item && item.entity && item.entity.id !== 'NAT');
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    // Filter channels from input
    this.channelCtrl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(1000),
        distinctUntilChanged(),
        filter((value) => !value || typeof value === 'string'),
        takeUntil(this.componentDestroyed)
      )
      .subscribe((channelValue?: string) => {
        this.filteredChannels = of(this.filterChannels(channelValue))
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Erreur de filtrage des chaînes.');
      });

    this.areaCtrl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(1000),
        distinctUntilChanged(),
        filter((value) => !value || typeof value === 'string'),
        takeUntil(this.componentDestroyed)
      )
      .subscribe((areaValue?: string) => {
        this.filteredAreas = of(this.filterAreas(areaValue));
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.categoryCtrl.valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        takeUntil(this.componentDestroyed)
      )
      .subscribe((selectedCategories) => {
        this.synthesisFilter.categories = selectedCategories;
        this.offerProgramGridSynthesisService.filterSynthesisGrid();
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    // listener on offer type selection
    this.offerTypeCtrl.valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((offerType: string) => {
        this.selectedOfferType = +offerType;
        this.synthesisFilter.offerType = +offerType;

        // clear selected channels / areas while navigating through offer selection mode
        if (this.selectedChannels.length > 0) {
          this.selectedChannels.forEach((channel: FilteredItem) => {
            this.remove(channel, false);
          });
        }

        // clearing selected channels and text from channel input
        this.selectedChannels = [];
        this.synthesisFilter.channels = [];
        this.filteredChannels = (this.selectedOfferType === 3) ? of(this.overSeaChannels) : of(this.defaultChannels);
        this.clearChannelInput();

        this.offerProgramGridSynthesisService.filterSynthesisGrid();
    }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
    });

    this.offerNameCtrl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(1000),
        distinctUntilChanged(),
        filter((value) => !value || typeof value === 'string'),
        takeUntil(this.componentDestroyed)
      )
      .subscribe((offerName?: string) => {
        this.synthesisFilter.offerName = offerName;
        this.offerProgramGridSynthesisService.filterSynthesisGrid();
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  /**
   * Remove chips for filter channel
   *
   * @param channel
   * @param {boolean} filterSynthesis
   * @return {void}
   */
  remove(channel: FilteredItem, filterSynthesis = true): void {
    const index = this.selectedChannels.indexOf(channel);

    if (index >= 0) {
      this.selectedChannels.splice(index, 1);
      this.synthesisFilter.channels = [...this.selectedChannels];
    }

    // If we remove France 3 from channel, clear filter area
    if (channel.value === '3') {
      // regional offer mode selected
      if (this.selectedOfferType === 1) {
        this.areaInput.nativeElement.value = '';
      }

      this.selectedAreas = [];
      this.synthesisFilter.areas = [];
    }

    if (filterSynthesis) {
      this.offerProgramGridSynthesisService.filterSynthesisGrid();
    }
  }

  /**
   * Remove area from the program area filter
   *
   * @param {FilteredItem} area
   * @return {void}
   */
  removeArea(area: FilteredItem): void {
    const index = this.selectedAreas.indexOf(area);

    if (index >= 0) {
      this.selectedAreas.splice(index, 1);
      this.synthesisFilter.areas = [...this.selectedAreas];
      this.offerProgramGridSynthesisService.filterSynthesisGrid();
    }
  }

  /**
   * Filter by channel name
   *
   * @param channelName
   */
  filterChannels(channelName?: string): FilteredItem[] {
    let filteredChannels = (this.selectedOfferType === 3) ? [...this.overSeaChannels] : [...this.defaultChannels];

    if (channelName && channelName.length) {
      if (this.selectedOfferType === 3) {
        filteredChannels = this.overSeaChannels.filter((channel: FilteredItem) =>
          channel.display.toUpperCase().includes(channelName.toUpperCase())
        );
      } else {
        filteredChannels = this.defaultChannels.filter((channel: FilteredItem) =>
          channel.display.toUpperCase().includes(channelName.toUpperCase())
        );
      }
    }

    return filteredChannels;
  }

  /**
   * Filter the grid synthesis by area
   *
   * @param areaName
   */
  filterAreas(areaName?: string): FilteredItem[] {
    let filteredAreas = [...this.allF3Areas];

    if (areaName && areaName.length) {
      filteredAreas = this.allF3Areas.filter((area: FilteredItem) => area.display.toUpperCase().includes(areaName.toUpperCase()));
    }

    return filteredAreas
  }

  /**
   * When user selected from list of chips
   *
   * @param event
   * @return {void}
   */
  selectedChannel(event: MatAutocompleteSelectedEvent): void {
    if (this.selectedChannels.findIndex((channel: FilteredItem) => channel.value === event.option.value.value) === -1) {
      this.selectedChannels.push(event.option.value);
      this.synthesisFilter.channels = [...this.selectedChannels];
      this.offerProgramGridSynthesisService.filterSynthesisGrid();
    }

    this.clearChannelInput();
    this.channelCtrl.setValue(null);
  }

  /**
   * Remove text content from channel input
   */
  clearChannelInput(): void {
    if (this.channelInput) {
      this.channelInput.nativeElement.value = '';
    }
  }

  /**
   * When user chooses from list of areas in the filter
   *
   * @param event
   * @return {void}
   */
  selectedArea(event: MatAutocompleteSelectedEvent): void {
    if (this.selectedAreas.findIndex((area: FilteredItem) => area.value === event.option.value.value) === -1) {
      this.selectedAreas.push(event.option.value);
      this.synthesisFilter.areas = [...this.selectedAreas];

      // PUSH FRANCE 3
      if (this.selectedChannels.findIndex((channel: FilteredItem) => channel.value === '3') === -1) {
        this.selectedChannels.push(this.defaultChannels.find((channel: FilteredItem) => channel.value === '3'));
        this.synthesisFilter.channels = [...this.selectedChannels];
      }

      this.offerProgramGridSynthesisService.filterSynthesisGrid();
    }

    // Clear area input and control
    this.areaInput.nativeElement.value = '';
    this.areaCtrl.setValue(null);
  }

  /**
   * Reload all synthesis with API
   */
  searchSynthesis(): void {
    this.offerProgramGridSynthesisService.searchSynthesis();
  }

  /**
   * Order synthesis asc/desc data
   *
   * @param order
   */
  orderSynthesis(order: string): void {
    this.offerProgramGridSynthesisService.orderAscDescSynthesis(order);
  }

  /**
   * Decrease Year and reload data
   */
  decreaseYear(): void {
    this.decreaseY.emit();
    this.offerProgramGridSynthesisService.searchSynthesis();
  }

  /**
   * Increase Year and reload data
   */
  increaseYear(): void {
    this.increaseY.emit();
    this.offerProgramGridSynthesisService.searchSynthesis();
  }

  /**
   * Select all or unselect all programme
   */
  selectAllCategories(): void {
    if (this.categoryCtrl.value.length !== this.allProgramCategories.length) {
      this.categoryCtrl.setValue(this.allProgramCategories);
    } else {
      this.categoryCtrl.setValue([]);
    }
  }

  /**
   * Download synthesis grid of availability
   */
  downloadSynthesis(): void {
    this.loading = true;
    const extraQueryParameters = {};

    // Processing selected channels filter
    if (this.selectedChannels && this.selectedChannels.length) {
      extraQueryParameters['channels'] = this.selectedChannels.map((channel: FilteredItem) => channel.value).join(',');
    }

    // Processing selected programs categories filter
    if (this.categoryCtrl && this.categoryCtrl.value && this.categoryCtrl.value.length) {
      extraQueryParameters['programsCategories'] = this.categoryCtrl.value.map(category => category.value).join(',');
    }

    // Processing selected areas filter
    if (this.selectedAreas && this.selectedAreas.length) {
      extraQueryParameters['areas'] = this.selectedAreas.map((area: FilteredItem) => area.value).join(',');
    }

    // Processing offer name filter
    if (this.offerNameCtrl && this.offerNameCtrl.value && this.offerNameCtrl.value.length) {
      extraQueryParameters['offerName'] = this.offerNameCtrl.value;
    }

    // Set offer type filter
    if (this.selectedOfferType === 0) {
      // National mode
      extraQueryParameters['mode'] = 'national';
    } else if (this.selectedOfferType === 1) {
      // Regional mode
      extraQueryParameters['mode'] = 'regional';
    } else if (this.selectedOfferType === 2) {
      // SoReach mode
      extraQueryParameters['mode'] = 'soreach';
    } else if (this.selectedOfferType === 3) {
      // Overseas mode
      extraQueryParameters['mode'] = 'overseas';
    }

    this.offerProgramGridSynthesisService.download(this.filters.year, extraQueryParameters)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(() => {
        this.customToastrService.displayToastr('SUCCESS', 'Synthèse générée avec succès.');
        this.loading = false;
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
        this.loading = false;
      });
  }

  /**
   * Change view Advertiser / Product
   */
  changeView(data: MatSlideToggleChange): void {
    this.offerProgramGridSynthesisService.changeViewAdvertiserProduct(data.checked);
  }

  /**
   * Event when user click on toggle to get only or not exclusives offers
   * @param event
   */
  switchExclusivesOffers(event: MatSlideToggleChange): void {
    if (this.onlyExclusivesOffers = !!event.checked) {
      this.filters.isExclusive = 1;
    } else {
      this.filters.isExclusive = 0
    }

    this.offerProgramGridSynthesisService.filterSynthesisGrid();
  }
}
