<app-program
   [purchaseProgram]="purchaseProgram"
   [purchase]="purchase"
   (selectedPurchaseProgram)="onChangePurchaseProgram($event)">
</app-program>

<app-broadcast 
  *ngIf="purchaseProgram && purchaseProgram.id > 0"
  [purchaseProgram]="purchaseProgram"
  [purchase]="purchase"
  (refreshEmitter)="refreshBrutEmitter($event)">
</app-broadcast>

<app-advertising-package
  *ngIf="purchaseProgram && purchaseProgram.id > 0"
  [purchaseProgram]="purchaseProgram"
  [purchase]="purchase">
</app-advertising-package>
