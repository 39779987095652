import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, takeUntil, filter, map } from 'rxjs/operators';

import { LotDotationService } from '@service/lot-dotation/lot-dotation.service';
import { ChannelService } from '@service/channel/channel.service';
import { CategoryService } from '@service/category/category.service';
import { AreaService } from '@service/area/area.service';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';
import { AutocompleteService } from '@service/utilities/autocomplete.service';

import { FilteredItem } from '../../../../resource/filteredItem.resource';
import { Channel } from '../../../../resource/channel.resource';

import { CustomValidators } from '../../../../validator/custom-validators';

@Component({
  selector: 'app-new-monitoring-lot-program',
  templateUrl: './new-monitoring-lot-program.component.html',
  styleUrls: ['./new-monitoring-lot-program.component.scss']
})
export class NewMonitoringLotProgramComponent implements OnInit, OnDestroy {
  public loading = false;
  public path: string = Channel.PATH;
  private componentDestroyed$: Subject<void> = new Subject();

  public newMonitoringLotProgramForm: FormGroup;

  public filteredChannelItems: Observable<FilteredItem[]>;
  public filteredCategoryItems: Observable<FilteredItem[]>;
  public filteredAreaItems: Observable<FilteredItem[]>;

  private channelList: FilteredItem[];
  private categoryList: FilteredItem[];
  private areaList: FilteredItem[];

  constructor(
    public dotationService: LotDotationService,
    public autocompleteService: AutocompleteService,
    public dialogRef: MatDialogRef<NewMonitoringLotProgramComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private customToastrService: CustomToastrService,
    private channelService: ChannelService,
    private areaService: AreaService,
    private categoryService: CategoryService
  ) {
    this.getChannelList();
    this.getAreasList();
    this.getProgramCategoryList();
  }

  ngOnInit() {
    this.initForm();
    this.setValueChanges();
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.unsubscribe();
  }

  /**
   * Init new monitoring lot program form
   *
   * @returns {void}
   * @memberof NewMonitoringLotProgramComponent
   */
  public initForm(): void {
    let areaInitValue = this.data.channel.value !== '3' ? {value: 'NAT', display: 'NATIONALE'} : null;
    let areaInitStatus = this.data.channel.value !== '3' ?  true : false;

    this.newMonitoringLotProgramForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(50)]],
      channel: [{value: this.data.channel, disabled: true}, [Validators.required, CustomValidators.inFilteredItemValidator]],
      area: [{value: areaInitValue, disabled: areaInitStatus}, [Validators.required, CustomValidators.inFilteredItemValidator]],
      category: [null, [Validators.required, CustomValidators.inFilteredItemValidator]],
    });
  }

  /**
   * Close NewMonitoringLotProgramComponent dialog
   *
   * @returns {void}
   * @memberof NewMonitoringLotProgramComponent
   */
  cancel(): void  {
    this.dialogRef.close();
  }

  /**
   * Trigger the API request to create a new dotation purchase and its related database objects
   *
   * @returns {void}
   * @memberof NewMonitoringLotProgramComponent
   */
  save(): void {
    if (this.newMonitoringLotProgramForm.valid) {
      this.loading = true;

      // Post params
      const params = {
        name: this.newMonitoringLotProgramForm.get('name').value,
        channel: this.newMonitoringLotProgramForm.get('channel').value.id,
        area: this.newMonitoringLotProgramForm.get('area').value.id,
        category: this.newMonitoringLotProgramForm.get('category').value.id
      };

      this.dotationService.createProgram(params)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((createdProgram: FilteredItem) => {
          this.dialogRef.close(createdProgram);
          this.customToastrService.displayToastr('SUCCESS', 'Creation emission avec succes!');
        }, () => {
          this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue lors de la création de l\'émission!');
        }, () => {
          this.loading = false;
        });
    }
  }

  getProgramCategoryList() {
    this.loading = true;
    this.filteredCategoryItems = of([]);

    this.categoryService.getListForFilter()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((categoryItems: FilteredItem[]) => {
        this.categoryList = categoryItems;
        this.filteredCategoryItems = of(categoryItems);
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Erreur lors de la récupération des catégories');
      }, () => {
        this.loading = false;
      });
  }

  getAreasList() {
    this.loading = true;
    this.filteredAreaItems = of([]);

    this.areaService.getListForFilter({channel_id: 3})
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((areaList: FilteredItem[]) => {
        // Exclude France 3 National area
        // this.areaList = areas.filter((item: FilteredItem) => item && item.entity && item.entity.id !== 'NAT'
        this.areaList = areaList;
        this.filteredAreaItems = of(areaList);
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      }, () => {
        this.loading = false;
      });
  }

  getChannelList() {
    this.loading = true;
    this.filteredChannelItems = of([]);

    this.channelService.getListForFilter()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((channelItems: FilteredItem[]) => {
        // Filter only national channels: 2, 3, 4 and 5 || 11/05/2023 Reactivation of France 4
        this.channelList = channelItems.filter((item) => item && item.entity && (item.entity.channelGroup === 'F'
          && item.entity.id !== 'CBOX' && item.entity.id !== 'O'));
        this.filteredChannelItems = of(this.channelList);
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Erreur lors de la récupération des chaines');
      }, () => {
        this.loading = false;
      });
  }

  /**
   * Set value change listener on newMonitoringLotProgramForm
   *
   * @private
   * @returns {void}
   * @memberof NewMonitoringLotProgramComponent
   */
  private setValueChanges(): void {
    // Channel
    /*this.newMonitoringLotProgramForm.controls['channel'].valueChanges
      .pipe(
        startWith(null),
        distinctUntilChanged(),
        debounceTime(500),
        takeUntil(this.componentDestroyed$),
        filter((value) => (typeof value === 'string'))
      )
      .subscribe((value) => {
        // this.filteredCategoryItems = of([]);
        this.newMonitoringLotProgramForm.controls['channel'].setValue('', {emitEvent: false});
        this.filteredChannelItems = of(this.channelList);

        if (value) {
          // this.filteredCategoryItems = of([]);
          this.filteredChannelItems = of(this.autocompleteService.filterItems(value, this.channelList));
        }
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
        this.loading = false;
      });*/

    // Area
    this.newMonitoringLotProgramForm.controls['area'].valueChanges
      .pipe(
        startWith(null),
        distinctUntilChanged(),
        debounceTime(500),
        takeUntil(this.componentDestroyed$),
        filter((value) => (typeof value === 'string'))
      )
      .subscribe((value) => {
        // this.filteredCategoryItems = of([]);
        this.newMonitoringLotProgramForm.controls['area'].setValue('', {emitEvent: false});
        this.filteredAreaItems = of(this.areaList);

        if (value) {
          // this.filteredCategoryItems = of([]);
          this.filteredAreaItems = of(this.autocompleteService.filterItems(value, this.areaList));
        }
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
        this.loading = false;
      });

    // Category
    this.newMonitoringLotProgramForm.controls['category'].valueChanges
      .pipe(
        startWith(null),
        distinctUntilChanged(),
        debounceTime(500),
        takeUntil(this.componentDestroyed$),
        filter((value) => (typeof value === 'string'))
      )
      .subscribe((value) => {
        // this.filteredCategoryItems = of([]);
        this.newMonitoringLotProgramForm.controls['category'].setValue('', {emitEvent: false});
        this.filteredCategoryItems = of(this.categoryList);

        if (value) {
          // this.filteredCategoryItems = of([]);
          this.filteredCategoryItems = of(this.autocompleteService.filterItems(value, this.categoryList));
        }
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
        this.loading = false;
      });
  }
}
