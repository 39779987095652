import { Component, DoCheck, EventEmitter, Input, Output } from '@angular/core';

import { PropalCartService } from '@service/propal-cart/propal-cart.service';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';

import { Broadcast } from '../../../../resource/broadcast.resource';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-area-program-propal',
  templateUrl: './area-program-propal.component.html',
  styleUrls: ['./area-program-propal.component.scss']
})
export class AreaProgramPropalComponent implements DoCheck {
  @Input() offerProgramPropal;
  @Output() onDeleteProgram = new EventEmitter<number>();

  public min: Broadcast;
  public max: Broadcast;

  public scheduleSummary = [];

  private componentDestroyed$: Subject<any> = new Subject();

  constructor(private propalCartService: PropalCartService,
              private customToastrService: CustomToastrService) { }

  ngDoCheck() {
    this.min = this.offerProgramPropal.broadcasts[0];
    this.max = this.offerProgramPropal.broadcasts[this.offerProgramPropal.broadcasts.length - 1];

    this.propalCartService
      .getScheduleSummary(this.offerProgramPropal.broadcasts)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(scheduleSummary => {
        this.scheduleSummary = scheduleSummary;
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });
  }

  /**
   * Remove the actual program from cart and grid
   */
  removeOfferProgramFromF3AreaCart(): void {
    this.onDeleteProgram.emit(this.offerProgramPropal.offerProgramId);
    this.propalCartService.deleteOfferProgramFromF3AreaCart(this.offerProgramPropal.offerProgramId);
  }
}
