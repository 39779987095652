import { ApplicationRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';

import { AuthService } from '@service/auth.service';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';

import { concat, interval, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public isLoggedIn: boolean;

  private readonly forcedClearCacheDate = moment('02/12/2020', 'DD/MM/YYYY'); // CHANGE DATE IF NEED TO CLEAR ALL USER STORAGE
  private componentDestroyed$: Subject<any> = new Subject();

  constructor(
    public authService: AuthService,
    private appRef: ApplicationRef,
    private swUpdate: SwUpdate,
    private router: Router,
    private customToastrService: CustomToastrService
  ) {
  }

  checkIsLoggedIn() {
    this.isLoggedIn = this.authService.isLoggedIn();
  }

  ngOnInit() {
    this.checkNewVersion();
    this.checkForceClearCache();
    this.checkIsLoggedIn();
    this.authService.eventLogged$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        this.checkIsLoggedIn();
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });
  }

  /**
   * Check if new version is available and ask user to reload
   *
   * @return {void}
   */
  checkNewVersion(): void {
    const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
    const everyFiveMinutes$ = interval(5 * 60 * 1000); // Timer 5 minutes
    const everyFiveMinutesOnceAppIsStable$ = concat(appIsStable$, everyFiveMinutes$);

    everyFiveMinutesOnceAppIsStable$
      .subscribe(() => {
        if (this.swUpdate.isEnabled) {
          this.swUpdate.available.subscribe(() => {
            if (confirm('Une nouvelle version de Parrainage est disponible. Cliquer sur OK pour recharger la page !')) {
              this.swUpdate.activateUpdate().then(() => document.location.reload());
            }
          });
        }
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });
  }

  /**
   * Check if need to clear cache
   */
  checkForceClearCache(): void {
    const dateCache: string = localStorage.getItem('date-cache');

    if (!dateCache && !localStorage.getItem('access_token') && !localStorage.getItem('email') && !localStorage.getItem('trigramme')) {
      localStorage.setItem('date-cache', this.forcedClearCacheDate.format('DD/MM/YYYY'));
    } else if (!dateCache || (dateCache && moment(dateCache, 'DD/MM/YYYY').isBefore(moment(this.forcedClearCacheDate)))) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('email');
      localStorage.removeItem('trigramme');
      localStorage.removeItem('parameter');
      this.customToastrService.displayToastr('WARNING', 'Votre session a expiré, veuillez vous reconnecter.');
      this.authService.sendLoggedStatus(false);
      localStorage.setItem('date-cache', this.forcedClearCacheDate.format('DD/MM/YYYY'));
      this.router.navigate(['/']);
    }
  }
}
