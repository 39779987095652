import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { AdvertiserService } from '@service/advertiser/advertiser.service';
import { AutocompleteService } from '@service/utilities/autocomplete.service';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';
import { ProgramService } from '@service/program/program.service';
import { ProductService } from '@service/product/product.service';
import { PurchaseService } from '@service/purchase/purchase.service';

import { FilteredItem } from '../../../resource/filteredItem.resource';

import { Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, startWith, takeUntil } from 'rxjs/operators';

import * as moment from 'moment';

@Component({
  selector: 'app-lots-purchase',
  templateUrl: './lots-purchase.component.html',
  styleUrls: ['./lots-purchase.component.scss']
})
export class LotsPurchaseComponent implements OnInit {
  displayedColumns = ['advertiserId', 'advertiser', 'productId', 'product', 'purchase', 'lot', 'program', 'netBudget'];
  informationGroup: FormGroup;
  loading = false;
  dataSource = [];

  componentDestroyed$: Subject<boolean> = new Subject<boolean>();
  filteredAdvertiserItems: Observable<FilteredItem[]>;
  filteredProgramItems: Observable<FilteredItem[]>;
  filteredContractNumberItems: Observable<FilteredItem[]>;
  filteredProductItems: Observable<FilteredItem[]>;

  constructor(
    public customToastrService: CustomToastrService,
    public autocompleteService: AutocompleteService,
    private productService: ProductService,
    private advertiserService: AdvertiserService,
    private fb: FormBuilder,
    private programService: ProgramService,
    private purchaseService: PurchaseService,
  ) { }

  ngOnInit() {
    this.initForm();
    this.setValueChange();
  }

  /**
   * Init filters form
   */
  initForm(): void {
    this.informationGroup = this.fb.group({
      program:[null, [Validators.maxLength(100)]],
      advertiser:[null, [Validators.maxLength(100)]],
      contractNumber: [null, [Validators.minLength(3), Validators.maxLength(15)]],
      product:[null, [Validators.maxLength(100)]],
      startDate: [moment().startOf('year'), Validators.required],
      endDate: [null]
    });
  }

  /**
   * Get product list related to the given advertiser
   *
   * @param controlName
   */
  valueSelected(controlName: string): void {
    if (controlName === 'advertiser' && this.informationGroup.controls['advertiser'] != null && this.informationGroup.controls['advertiser'].value !== null &&
      this.informationGroup.controls['advertiser'].value !== '' && this.informationGroup.controls['advertiser'].value.id &&
      typeof this.informationGroup.controls['advertiser'].value.id === 'number') {
      const productParams = { advertiser_id: this.informationGroup.controls['advertiser'].value.id };

      this.productService.getListForFilter(productParams)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((productItems: FilteredItem[]) => {
          if (productItems.length === 1) {
            this.informationGroup.controls['product'].setValue(productItems[0]);
            this.valueSelected('product');
          } else if (productItems.length > 1) {
            this.filteredProductItems = of(productItems);
          }
          this.loading = false;
        }, () => {
          this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
          this.loading = false;
        });
    }
  }

  /**
   * Set the value after change
   */
  setValueChange(): void {
    // Product
    this.informationGroup.controls['product'].valueChanges
      .pipe(
        startWith(null),
        debounceTime(500),
        filter((data) => data && (typeof data === 'string')),
        distinctUntilChanged(),
        takeUntil(this.componentDestroyed$))
      .subscribe((value) => {
        this.filteredProductItems = of([]);
        this.loading = true;
        
        const productParams = Number(value) ? {id: value} : {name: value.toUpperCase()};
        
        this.productService.getListForFilter(productParams)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((productItems: FilteredItem[]) => {
          if (productItems && productItems.length > 0) {
            this.filteredProductItems = of(
              this.autocompleteService.filterItems(value, productItems)
            );
          }
          this.loading = false;
        }, () => {
          this.customToastrService.displayToastr('ERROR', 'Erreur lors de la récupération des produits');
          this.loading = false;
        });
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Erreur lors de la récupération des produits');
        this.loading = false;
      });
      
    // advertiser
    this.informationGroup.get('advertiser').valueChanges
      .pipe(
        startWith(null),
        debounceTime(500),
        filter((data) => data && (typeof data === 'string')),
        distinctUntilChanged(),
        takeUntil(this.componentDestroyed$))
      .subscribe((value) => {
        // empty the value of product and clean the product list if we replace advertiser value
        this.informationGroup.controls['product'].setValue('', {emitEvent: false});
        this.filteredProductItems = of([]);

        this.filteredAdvertiserItems = of([]);
        this.loading = true;

        const filterAdvertiser =
        Number(value) ? {id: value.toUpperCase()} : {name: value.toUpperCase()};

        this.advertiserService.getListForFilter(filterAdvertiser)
          .pipe(
            distinctUntilChanged(),
            takeUntil(this.componentDestroyed$))
          .subscribe((advertiserItems: FilteredItem[]) => {
            if (advertiserItems && advertiserItems.length > 0) {
              this.filteredAdvertiserItems = of(this.autocompleteService.filterItems(value, advertiserItems));
            }
            this.loading = false;
          }, () => {
            //this.loading = false;
            this.customToastrService.displayToastr('ERROR', 'Erreur lors de la récupération des annonceurs');
          });
      }, () => {
        this.loading = false;
        this.customToastrService.displayToastr('ERROR', 'Erreur lors de la récupération des annonceurs');
      });

    // contract number
    this.informationGroup.get('contractNumber').valueChanges
    .pipe(
      startWith(null),
      debounceTime(500),
      filter((data) => data && (typeof data === 'string')),
      distinctUntilChanged(),
      takeUntil(this.componentDestroyed$))
    .subscribe((value) => {
      this.loading = true;

      this.purchaseService.getListDotationPurchaseForFilter({contract_value: value})
        .pipe(
          distinctUntilChanged(),
          takeUntil(this.componentDestroyed$))
        .subscribe((contractsItems: FilteredItem[]) => {
          if (contractsItems && contractsItems.length > 0) {
            this.filteredContractNumberItems = of(this.autocompleteService.filterItems(value, contractsItems));
          }
          this.loading = false;
        }, () => {
          this.loading = false;
          this.customToastrService.displayToastr('ERROR', 'Erreur lors de la récupération des numéros de contrats');
        })
    }, () => {
      this.loading = false;
      this.customToastrService.displayToastr('ERROR', 'Erreur lors de la récupération des numéros de contrats');
    });

    // program
    this.informationGroup.get('program').valueChanges
      .pipe(
        startWith(null),
        debounceTime(500),
        filter((data) => data && (typeof data === 'string')),
        distinctUntilChanged(),
        takeUntil(this.componentDestroyed$))
      .subscribe((value) => {
        this.filteredProgramItems = of([]);
        this.loading = true;

        const filterProgram = {with_monitoring_lot: true, name: value.toUpperCase()};

        this.programService.getListForFilter(filterProgram)
          .pipe(
            distinctUntilChanged(),
            takeUntil(this.componentDestroyed$))
          .subscribe((programItems: FilteredItem[]) => {
            if (programItems && programItems.length > 0) {
              this.filteredProgramItems = of(this.autocompleteService.filterItems(value, programItems));
            }
            this.loading = false;
          }, () => {
            this.loading = false;
            this.customToastrService.displayToastr('ERROR', 'Erreur lors de la récupération des émissions');
          });
      }, () => {
        this.loading = false;
        this.customToastrService.displayToastr('ERROR', 'Erreur lors de la récupération des émissions');
      });
  }

  /**
   * Load purchase dotation list
   */
  loadPurchaseList(): void {
    this.loading = true;

    let filtersPurchase = {}

    if (this.informationGroup.get('advertiser') && this.informationGroup.get('advertiser').value
    && this.informationGroup.get('advertiser').value.id) {
      filtersPurchase['advertiser'] = this.informationGroup.get('advertiser').value.id;
    }
    if (this.informationGroup.get('product') && this.informationGroup.get('product').value
    && this.informationGroup.get('product').value.id) {
      filtersPurchase['product'] = this.informationGroup.get('product').value.id;
    }
    if (this.informationGroup.get('contractNumber') && this.informationGroup.get('contractNumber').value) {
      filtersPurchase['contract_number'] = this.informationGroup.get('contractNumber').value.value;
    }
    if (this.informationGroup.get('program') && this.informationGroup.get('program').value
    && this.informationGroup.get('program').value.id) {
      filtersPurchase['program'] = this.informationGroup.get('program').value.id;
    }
    if (this.informationGroup.get('startDate') && this.informationGroup.get('startDate').value) {
      filtersPurchase['startDate'] = moment(this.informationGroup.get('startDate').value).format('DD/MM/YYYY');
    }
    if (this.informationGroup.get('endDate') && this.informationGroup.get('endDate').value) {
      filtersPurchase['endDate'] = moment(this.informationGroup.get('endDate').value).format('DD/MM/YYYY');
    }

    if (this.informationGroup.valid) {
      this.purchaseService.getListDotationPurchase(filtersPurchase)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((purchaseList) => {
        this.dataSource = purchaseList;

        // formate program and lot values
        this.dataSource.map(element => {
          if (element.programName !== null && element.programName !== '') {
            element.programName = element.programName.replaceAll(', ', ',\n');
          }
          if (element.lot !== null && element.lot !== '') {
            element.lot = element.lot.replaceAll(', ', ',\n');
          }
          return element;
        });
        this.loading = false;
      }, () => {
        this.loading = false;
        this.customToastrService.displayToastr('ERROR', 'Erreur lors de la récupération des dossiers');
      });
    } else {
      this.loading = false;
      this.customToastrService.displayToastr('WARNING', 'Les champs du formulaire ne sont pas valides!');
    }
  }
}
