import { Component, OnInit, Input, OnDestroy } from '@angular/core';

import { ScheduleService } from '../../../../service/schedule/schedule.service';
import { PropalCartService } from '../../../../service/propal-cart/propal-cart.service';
import { CustomToastrService } from '../../../../service/toastr/custom-toastr.service';
import { Broadcast } from '../../../../resource/broadcast.resource';
import { OfferProgramPropalCart } from '../../../../resource/availability/offer-program-propal-cart.resource';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-offer-program-propal',
  templateUrl: './offer-program-propal.component.html',
  styleUrls: ['./offer-program-propal.component.scss']
})
export class OfferProgramPropalComponent implements OnInit, OnDestroy {

  @Input() offerProgramPropal: OfferProgramPropalCart;

  public broadcasts: Broadcast[];
  public path: String;
  public logo: any;

  public min: Broadcast;
  public max: Broadcast;
  public scheduleSummary = [];

  destroyPropalSource$: Subject<boolean> = new Subject<boolean>();
  destroyScheduleSummary$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private propalCartService: PropalCartService,
    private customToastrService: CustomToastrService) { }

  ngOnInit() {
    this.path = 'assets/images/logos/channels/';
    this.logo = this.getProgramLogo();
    this.updateData();

    this.propalCartService.offerProgramPropalSource$
      .pipe(takeUntil(this.destroyPropalSource$))
      .subscribe(
        offerProgramPropalCart => {
          if (offerProgramPropalCart.offerProgram.id === this.offerProgramPropal.offerProgram.id) {
            this.offerProgramPropal = offerProgramPropalCart;
            this.updateData();
          }
      }, error => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });
  }

  ngOnDestroy() {
    this.destroyPropalSource$.next(true);
    this.destroyScheduleSummary$.next(true);
    this.destroyPropalSource$.unsubscribe();
    this.destroyScheduleSummary$.unsubscribe();
  }

  public removeBroadcast(broadcast: Broadcast) {
    this.propalCartService.removeBroadcast(broadcast, this.offerProgramPropal);
  }

  public removeOfferProgramPropal() {
    this.propalCartService.removeOfferProgramPropal(this.offerProgramPropal);
  }

  private updateData() {
    this.broadcasts = this.offerProgramPropal.broadcasts;
    this.getSummary();
    this.propalCartService
      .getScheduleSummary(this.broadcasts)
      .pipe(takeUntil(this.destroyScheduleSummary$))
      .subscribe(scheduleSummary => {
        this.scheduleSummary = scheduleSummary;
      });
  }

  private getProgramLogo(): string {
    this.logo = this.offerProgramPropal.offerProgram.area.channel.image.split('.');
    return this.logo[0] + '-G.' + this.logo[1];
  }

  private getSummary() {
    if (!this.broadcasts) {
      return null;
    }

    this.broadcasts.sort((n1, n2) => moment(n1.startTime, 'YYYY-MM-DD').valueOf() - moment(n2.startTime, 'YYYY-MM-DD').valueOf());
    this.min = this.broadcasts[0];
    this.max = this.broadcasts[this.broadcasts.length - 1];
  }
}
