const TRANSLATIONS = {
  PURCHASE_STATUS_PURCHASED: 'Ce dossier ne peut pas être en statut acheté car certaines diffusions ne sont plus disponibles',
  PURCHASE_NOT_OPTIONABLE: 'Ce dossier n\'est pas optionable',
  INVOICED_BROADCAST_PURCHASE_NOT_CANCELABLE: 'Ce dossier ne peut pas être annulé car certaines diffusions sont déjà facturées',
  PURCHASE_DELETE: 'Ce dossier ne peut pas être supprimé car certaines diffusions sont déjà facturées',
  PURCHASE_LOWER_INVOICED_NATIONAL: 'Le budget (national) net de ce dossier ne peut être inférieur au net déjà facturé',
  PURCHASE_LOWER_INVOICED_REGIONAL: 'Le budget (régions) net de ce dossier ne peut être inférieur au net déjà facturé',
  PURCHASE_LOWER_INVOICED_DOMTOM: 'Le budget (dom-tom) net de ce dossier ne peut être inférieur au net déjà facturé',
  PURCHASE_LOWER_INVOICED_THEMA: 'Le budget (thématique) net de ce dossier ne peut être inférieur au net déjà facturé',
  PURCHASE_LOWER_INVOICED_INTERNATIONAL: 'Le budget (international) net de ce dossier ne peut être inférieur au net déjà facturé',
  PURCHASE_LOWER_INVOICED_F4: 'Le budget (France 4) net de ce dossier ne peut être inférieur au net déjà facturé',
  PURCHASE_NOT_FOUND: 'Dossier non trouvé',
  PURCHASE_CAMPAIGN_PRODUCT_MATCH: 'La campagne ne correspond pas au produit',
  PURCHASE_PROGRAM_DELETE: 'Cette émission ne peut pas être supprimée car certaines diffusions sont déjà facturées',
  OFFER_PROGRAM_DELETE: 'Cette émission ne peut pas être supprimée car certaines diffusions sont déjà achetées',
  OFFER_PROGRAM_NOT_FOUND: 'Emission non trouvée',
  SCHEDULE_DELETE: 'Cette programmation ne peut pas être supprimée car certaines diffusions sont déjà achetées',
  SCHEDULE_NOT_FOUND: 'Programmation non trouvée',
  OFFER_NOT_FOUND: 'Offre non trouvée',
  OFFER_DELETE: 'Cette offre ne peut pas être supprimée car elle contient des diffusions achetées',
  PURCHASE_CAMPAIGN_MISMATCH: 'Les données relatives à la campagne ne correspondent pas à celles saisies sur le dossier',
  CONVENTION_CHECK: 'Veuillez bien renseigner la partie client et sauvgarder'
};

export class ApiError {

  public message: string;

  constructor(apiMessage) {
    this.message = this.getTranslation(apiMessage);
  }

  private getTranslation(apiMessage) {
    let arrayData: any[] = apiMessage.split(' - ');
    let key: string = arrayData[0];
    let originalMessage: string = arrayData[1];

    if (TRANSLATIONS[key]) {
      return TRANSLATIONS[key];
    }

    return originalMessage;
  }

}
