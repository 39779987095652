<div fxLayout="column" fxLayoutGap="5px">
  <form class="filters-form" [formGroup]="informationGroup" fxLayout="row" fxFlex fxLayoutAlign="space-between center">
    <div fxLayout="row" fxFlex fxLayoutGap="10px">
      <mat-form-field fxFlex class="width-input">
        <input matInput appRemoveAccents placeholder="Annonceur"
              [matAutocomplete]="advertiserAutocomplete"
              formControlName="advertiser">
      </mat-form-field>
      <mat-autocomplete #advertiserAutocomplete="matAutocomplete" [displayWith]="autocompleteService.displayAutocomplete.bind(this)" (optionSelected)="valueSelected('advertiser')">
        <mat-option *ngFor="let advertiser of filteredAdvertiserItems | async" [value]="advertiser">
          {{ advertiser.display }} ({{ advertiser.value }})
        </mat-option>
      </mat-autocomplete>

      <mat-form-field fxFlex class="width-input">
        <input matInput placeholder="Produit"
              [matAutocomplete]="productAutocomplete"
              appRemoveAccents
              formControlName="product">
      </mat-form-field>
      <mat-autocomplete #productAutocomplete="matAutocomplete" [displayWith]="autocompleteService.displayAutocomplete.bind(this)" (optionSelected)="valueSelected('product')">
        <mat-option *ngFor="let product of filteredProductItems | async" [value]="product">
          {{ product.display }} ({{ product.value }})
        </mat-option>
      </mat-autocomplete>

      <mat-form-field class="width-input">
        <input matInput appRemoveAccents placeholder="Numéro de contrat"
              [matAutocomplete]="contractNumberAutocomplete"
              formControlName="contractNumber">
      </mat-form-field>
      <mat-autocomplete #contractNumberAutocomplete="matAutocomplete" [displayWith]="autocompleteService.displayAutocomplete.bind(this)">
        <mat-option *ngFor="let contractNumber of filteredContractNumberItems | async" [value]="contractNumber">
          {{ contractNumber.display }}
        </mat-option>
      </mat-autocomplete>

      <mat-form-field fxFlex class="width-input">
        <input matInput appRemoveAccents placeholder="Emission"
              [matAutocomplete]="programAutocomplete"
              formControlName="program">
      </mat-form-field>
      <mat-autocomplete #programAutocomplete="matAutocomplete"
                        [displayWith]="autocompleteService.displayAutocomplete.bind(this)">
        <mat-option *ngFor="let program of filteredProgramItems | async" [value]="program">
          {{ program.display }} ({{ program.value }})
        </mat-option>
      </mat-autocomplete>

      <mat-form-field fxFlex class="date-full-width">
        <input matInput
            [matDatepicker]="startDate"
            placeholder="Du"
            formControlName="startDate"
            [max]="informationGroup.get('endDate').value">
            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
        <mat-datepicker #startDate></mat-datepicker>
      </mat-form-field>

      <mat-form-field fxFlex class="date-full-width">
        <input matInput
            [matDatepicker]="endDate"
            placeholder="Au"
            formControlName="endDate"
            [min]="informationGroup.get('startDate').value">
            <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
        <mat-datepicker #endDate></mat-datepicker>
    </mat-form-field>
    </div>
    <div class="margin-button" fxLayoutGap="50px">
      <button mat-flat-button (click)="loadPurchaseList()" class="black button-flat">
        Rechercher
      </button>
    </div>
  </form>
  <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  <div class="table-purchase-lot-container">
    <table mat-table [dataSource]="dataSource">
      <!--advertiser id-->
      <ng-container matColumnDef="advertiserId">
        <th mat-header-cell *matHeaderCellDef> Code annonceur</th>
        <td mat-cell *matCellDef="let element">{{element.advertiser}}</td>
      </ng-container>
      <!--advertiser name-->
      <ng-container matColumnDef="advertiser">
        <th mat-header-cell *matHeaderCellDef > Annonceur</th>
        <td mat-cell *matCellDef="let element" matTooltip="{{element.advertiserName}}">
          {{element.advertiserName.length > 20 ? ((element.advertiserName | slice:0:20) + '...') : element.advertiserName}}
        </td>
      </ng-container>
      <!--product id-->
      <ng-container matColumnDef="productId">
        <th mat-header-cell *matHeaderCellDef> Code produit</th>
        <td mat-cell *matCellDef="let element">{{element.product}}</td>
      </ng-container>
      <!--product name-->
      <ng-container matColumnDef="product">
        <th mat-header-cell *matHeaderCellDef> Produit</th>
        <td mat-cell *matCellDef="let element" matTooltip="{{element.productName}}">{{element.productName}}</td>
      </ng-container>
      <!--purchase name-->
      <ng-container matColumnDef="purchase">
        <th mat-header-cell *matHeaderCellDef> Nom du dossier</th>
        <td mat-cell *matCellDef="let element" matTooltip="{{element.purchaseName}}">{{element.purchaseName}}</td>
      </ng-container>
      <!--lot-->
      <ng-container matColumnDef="lot">
        <th mat-header-cell *matHeaderCellDef>Lot</th>
        <td class="break-line" mat-cell *matCellDef="let element" matTooltip="{{element.lot}}">{{element.lot}}</td>
      </ng-container>
      <!--program-->
      <ng-container matColumnDef="program">
        <th mat-header-cell *matHeaderCellDef>Emission</th>
        <td class="break-line" mat-cell *matCellDef="let element" matTooltip="{{element.programName}}">{{element.programName}}</td>
      </ng-container>
      <!--budget nagocie-->
      <ng-container matColumnDef="netBudget">
        <th mat-header-cell *matHeaderCellDef>Budget Net</th>
        <td mat-cell *matCellDef="let element">{{element.budgetNet | number:'0.2-2':'fr'}} €</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="height-row"></tr>
    </table>
  </div>
</div>
