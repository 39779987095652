export interface JsonTarget {
    id: string;
    name: string;
}

export class Target {
    public id: string;
    public name: string;

    constructor(json: JsonTarget) {
        this.id = json.id;
        this.name = json.name;
    }
}