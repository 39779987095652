import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';

import { SoReachService } from '@service/so-reach/so-reach.service';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';

import { SoReach } from '../../../resource/so-reach.resource';
import { SoReachPeriodBudget } from 'src/app/resource/soreach-period-budget.resource';

import { SoReachPeriodBudgetComponent } from './so-reach-period-budget/so-reach-period-budget.component';
import { ValidationDialogComponent } from '../validation-dialog/validation-dialog.component';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as moment from 'moment';
import { Moment } from 'moment';

@Component({
  selector: 'app-so-reach-dialog',
  templateUrl: './so-reach-dialog.component.html',
  styleUrls: ['./so-reach-dialog.component.scss']
})
export class SoReachDialogComponent implements OnInit, OnDestroy {

  @ViewChild('SoReachPeriodBudgetComponent') SoReachPeriodBudgetComponent: SoReachPeriodBudgetComponent;
  soReaches: Array<SoReach>;
  allSoReaches: Array<SoReach>;

  public soReach;
  isDeleting = false;
  isSaving = false;
  isLoading = false;
  isCreating = false;
  clickToClose = false;

  // True when adding a new soreach period
  isAdding = false;

  // Contains the original copy of selected soreach before any user modification
  soreachOriginalCopy: SoReach = null;

  soReachForm: FormGroup;
  selectedSoreachIndex: number = null;
  selectedPeriodIndex: number = null;
  year: number;

  private componentDestroyed$: Subject<any> = new Subject();

  constructor(
    public dialogRef: MatDialogRef<SoReachDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog,
    private fb: FormBuilder,
    private soReachService: SoReachService,
    private customToastrService: CustomToastrService
  ) {
    // Init year
    this.year = (new Date()).getFullYear();

    // Init formGroup
    this.soReachForm = this.fb.group({
      id: new FormControl(null),
      year: new FormControl(this.year, [Validators.required, Validators.minLength(4), Validators.maxLength(4)]),
      name: new FormControl(null, [Validators.required, Validators.maxLength(50)]),
      periodsBudgets: new FormArray([])
    });
  }

  ngOnInit() {
    this.getSoReachList();
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.unsubscribe();
  }

  /**
   * Populate periodBudgets form with soReachPeriod data or init the period budgets form
   *
   * @param soReachPeriod
   * @return {void}
   */
  initSoreachPeriodBudget(soReachPeriod?: SoReachPeriodBudget): void {
    (this.soReachForm.get('periodsBudgets') as FormArray).push(this.fb.group({
      startDate: new FormControl(soReachPeriod ? soReachPeriod.startDate : null, Validators.required),
      endDate: new FormControl(soReachPeriod ? soReachPeriod.endDate : null, Validators.required),
      id: new FormControl(soReachPeriod ? soReachPeriod.id : null),
      orderPeriod: new FormControl(this.soReachForm.get('periodsBudgets').value.length),
      budgetTotal: new FormControl(soReachPeriod ? soReachPeriod.budgetTotal : null, [Validators.required, Validators.max(999999999.9999)]),
      f2Budget: new FormControl(soReachPeriod ? soReachPeriod.f2Budget : null, [Validators.required, Validators.max(999999999.9999)]),
      f3Budget: new FormControl(soReachPeriod ? soReachPeriod.f3Budget : null, [Validators.required, Validators.max(999999999.9999)]),
      f4Budget: new FormControl(soReachPeriod ? soReachPeriod.f4Budget : null, [Validators.required, Validators.max(999999999.9999)]),
      f5Budget: new FormControl(soReachPeriod ? soReachPeriod.f5Budget : null, [Validators.required, Validators.max(999999999.9999)]),
      foBudget: new FormControl(soReachPeriod ? soReachPeriod.foBudget : null, [Validators.required, Validators.max(999999999.9999)]),
    }));

    //  Redefine selectedPeriodIndex value if we are adding a new line or not
    if (this.isAdding) {
      this.selectedPeriodIndex = (this.soReachForm.get('periodsBudgets') as FormArray).length - 1;
    } else {
      this.selectedPeriodIndex = 0;
    }
  }

  /**
   * Add a new period line
   *
   * @return {void}
   */
  addPeriod(): void {
    this.isAdding = true;
    this.initSoreachPeriodBudget();
  }

  /**
   * Get all SoReachs
   *
   * @return {void}
   */
  getSoReachList(): void {
    this.isLoading = true;
    this.soReachService.getSoReach()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((soReaches: SoReach[]) => {
        this.allSoReaches = soReaches;
        this.filterSoReachesByYear();
        this.isLoading = false;
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Erreur lors de la récupération des données soreach.');
        this.isLoading = false;
      })
  }

  /**
   * Populate Soreach Form when a soreach object is selected
   *
   * @param index {number} soreach index
   * @param checkSoreach {boolean} indicates if should check if soreach have been modified
   * @return {void}
   */
  selectedSoReach(index: number, checkSoreach: boolean = true): void {
    if (index >= 0 && this.soReaches && this.soReaches[index]) {
      if (!checkSoreach || !this.soreachOriginalCopy || this.isSoreachSimilar(this.soReachForm, this.soreachOriginalCopy)) {
        this.isCreating = false;
        this.selectedSoreachIndex = index;
        const selectedSoreach: SoReach = this.soReaches[index];

        // Set soreach FormGroup data from selected soreach
        this.soReachForm.get('id').setValue(selectedSoreach.id);
        this.soReachForm.get('name').setValue(selectedSoreach.name);
        this.soReachForm.get('year').setValue(selectedSoreach.year);

        // Remove all periods from 'periodsBudgets' ControlForm of soreach FormGroup
        this.soReachForm.setControl('periodsBudgets', new FormArray([]));

        if (selectedSoreach.periodsBudgets.length) {
          // Sort periods by date
          const sortedPeriods = this.sortSoReachPeriods(selectedSoreach.periodsBudgets);

          // Set 'periodsBudgets' ControlForm with periods of selected soreach
          sortedPeriods.forEach((soReachPeriod: SoReachPeriodBudget) => {
            this.initSoreachPeriodBudget(soReachPeriod);

            // Activate channels budget change event in order to keep total budget always updated in selected soreach period
            if (this.SoReachPeriodBudgetComponent) {
              this.SoReachPeriodBudgetComponent.computeBudgetChange();
            }
          });

          this.selectedPeriodIndex = 0;
        }

        // Update soreachOriginalCopy value
        this.soreachOriginalCopy = {...selectedSoreach} as SoReach;
      } else {
        this.openDialogValidation(index);
      }
    }
  }

  /**
   * Check if we have modified current soreach
   *
   * @param soreachForm contains modified value of current soreach
   * @param soreachOriginalCopy original copy of current soreach
   * @return {boolean}
   */
   isSoreachSimilar(soreachForm: FormGroup, soreachOriginalCopy: SoReach): boolean {
     return !(soreachForm.get('name').value !== soreachOriginalCopy.name || soreachForm.get('year').value !== soreachOriginalCopy.year
       || this.isSoReachPeriodsChange(soreachForm, soreachOriginalCopy));
  }

  /**
   * Check if we have modified periods and budgets of current soreach
   *
   * @param soreachModifiedForm contains modified values of current soreach
   * @param soreachOriginalCopy original copy of current soreach
   * @return {boolean}
   */
   isSoReachPeriodsChange(soreachModifiedForm: FormGroup, soreachOriginalCopy: SoReach): boolean {
    let isChanged = false;

    // Check if the number of periods has changed
    if (soreachModifiedForm.value.periodsBudgets.length !== soreachOriginalCopy.periodsBudgets.length) {
      isChanged = true;
    } else {
      soreachOriginalCopy.periodsBudgets.forEach(originalPeriod => {
        // Check only periods with id not null
        const foundPeriod = originalPeriod.id && soreachModifiedForm.value.periodsBudgets
          .find((modifiedPeriod) => modifiedPeriod.id === originalPeriod.id);

        // Check if period data have been modified
        if (foundPeriod && (foundPeriod.budgetTotal !== originalPeriod.budgetTotal || foundPeriod.f2Budget !== originalPeriod.f2Budget
          || foundPeriod.f3Budget !== originalPeriod.f3Budget || foundPeriod.f4Budget !== originalPeriod.f4Budget
          || foundPeriod.f5Budget !== originalPeriod.f5Budget || foundPeriod.foBudget !== originalPeriod.foBudget
          || foundPeriod.startDate !== originalPeriod.startDate || foundPeriod.endDate !== originalPeriod.endDate)) {
          isChanged = true;
        }
      });
    }

    return isChanged;
  }

  /**
   * Create SoReach
   *
   * @param selectedSoreachIndex {number} index of soreach to be selected after validation
   * @return {void}
   */
  createSoReach(selectedSoreachIndex: number = null): void {
    this.isSaving = true;

    this.soReachService.postSoReach(this.soReach)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((createdSoreach: SoReach) => {
        this.isSaving = false;
        this.year = this.soReachForm.value.year;

        this.allSoReaches.push(createdSoreach);
        this.filterSoReachesByYear();

        // Get index of soreach the user selected before soreach update, or index of created soreach if user didn't select any soreach
        if (selectedSoreachIndex === null || selectedSoreachIndex < 0 || (this.soReaches && !this.soReaches[selectedSoreachIndex])) {
          selectedSoreachIndex = this.soReaches.findIndex((soreach) => +soreach.id === +createdSoreach.id);
        }

        this.selectedSoReach(selectedSoreachIndex, false);

        this.customToastrService.displayToastr('SUCCESS', 'SoReach créé avec succès!');
      }, () => {
        this.isSaving = false;
        this.customToastrService.displayToastr('ERROR', 'Erreur lors de la création du SoReach!');
      })
  }

  /**
   * Update SoReach
   *
   * @param selectedSoreachIndex {number} index of soreach to be selected after validation
   * @return {void}
   */
  updateSoReach(selectedSoreachIndex: number = null): void {
    this.isSaving = true;

    this.soReachService.putSoReach(this.soReach)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((updatedSoreach: SoReach) => {
        this.isSaving = false;
        const index: number = this.allSoReaches.findIndex((soreach) => +soreach.id === +updatedSoreach.id);

        // Update allSoReaches list with updated soreach
        if (index >= 0) {
          this.allSoReaches[index] = updatedSoreach;
          this.filterSoReachesByYear();
        }

        // Get index of soreach the user selected before soreach update, or index of updated soreach if user didn't select any soreach
        if (selectedSoreachIndex === null || selectedSoreachIndex < 0 || (this.soReaches && !this.soReaches[selectedSoreachIndex])) {
          selectedSoreachIndex = this.soReaches.findIndex((soreach: SoReach) => +soreach.id === +updatedSoreach.id);
        }

        this.selectedSoReach(selectedSoreachIndex, false);

        this.customToastrService.displayToastr('SUCCESS', 'SoReach mis à jour avec succès!');
      }, () => {
        this.isSaving = false;
        this.customToastrService.displayToastr('ERROR', 'Erreur lors de la mise à jour du SoReach!');
      })
  }

  /**
   * Save SoReach
   *
   * @param selectedSoreachIndex {number} index of soreach to be selected after validation
   * @return {void}
   */
  saveSoReach(selectedSoreachIndex: number = null): void {
    if (this.soReachForm.valid) {
      if (this.checkPeriodsValid()) {
        this.soReach = new SoReach(this.parseSoReachToJson(this.soReachForm));
        this.isCreating ? this.createSoReach(selectedSoreachIndex) : this.updateSoReach(selectedSoreachIndex);
      }
    } else {
      this.customToastrService.displayToastr('WARNING', 'Votre SoReach est incomplet!');
    }
  }

  /**
   * Delete SoReach
   *
   * @return {void}
   */
  deleteSoReach(): void {
    this.isDeleting = true;

    if (this.soReachForm.value.id) {
      this.soReachService.deleteSoReach(this.soReachForm.value.id)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(() => {
          this.isDeleting = false;
          const index: number = this.allSoReaches.findIndex((soReach: SoReach) => +soReach.id === +this.soReachForm.value.id);

          if (index >= 0) {
            this.allSoReaches.splice(index, 1);
            this.filterSoReachesByYear();

            // Reset soreach form
            this.soReachForm.reset();
            this.soReachForm.get('year').setValue(this.year);

            this.selectedSoreachIndex = null;
            this.soreachOriginalCopy = null;
          }

          this.customToastrService.displayToastr('SUCCESS', 'SoReach supprimé.');
        }, () => {
          this.isDeleting = false;
          this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
        })
    } else {
      this.customToastrService.displayToastr('WARNING', 'Veuillez sélectionner un SoReach.');
      this.isDeleting = false;
    }
  }

  /**
   * Open form to add new SoReach
   *
   * @return {void}
   */
  addSoReach(): void {
    if (!this.soreachOriginalCopy || this.isSoreachSimilar(this.soReachForm, this.soreachOriginalCopy)) {
      this.isCreating = true;
      this.selectedSoreachIndex = null;

      // Reset soreach form, remove all periods from 'periodsBudgets' ControlForm and init a new period form
      this.soReachForm.reset();
      this.soReachForm.setControl('periodsBudgets', new FormArray([]));
      this.initSoreachPeriodBudget();

      // Activate channels budget change event in order to keep total budget always updated in selected soreach period
      if (this.SoReachPeriodBudgetComponent) {
        this.SoReachPeriodBudgetComponent.computeBudgetChange();
      }

      this.soReachForm.get('year').setValue(this.year);
      this.soreachOriginalCopy = {...this.soReachForm.value} as SoReach;
    } else {
      this.openDialogValidation();
    }
  }

  /**
   * Decrease Year form or filter
   *
   * @return {void}
   */
  decreaseYear(type: boolean): void {
    if (type) {
      this.soReachForm.get('year').setValue(+this.soReachForm.get('year').value - 1);
    } else {
      if (!this.soreachOriginalCopy || this.isSoreachSimilar(this.soReachForm, this.soreachOriginalCopy)) {
        this.year--;
        this.soreachOriginalCopy = null;
        this.filterSoReachesByYear();

        if (!this.isCreating) {
          this.soReachForm.reset();
          this.selectedSoreachIndex = null;
        }
      } else {
        this.openDialogValidation();
      }
    }
  }

  /**
   * Increase Year for form or filter
   *
   * @return {void}
   */
  increaseYear(type: boolean): void {
    if (type) {
      this.soReachForm.get('year').setValue(+this.soReachForm.get('year').value + 1);
    } else {
      if (!this.soreachOriginalCopy || this.isSoreachSimilar(this.soReachForm, this.soreachOriginalCopy)) {
        this.year++;
        this.soreachOriginalCopy = null;
        this.filterSoReachesByYear();

        if (!this.isCreating) {
          this.soReachForm.reset();
          this.selectedSoreachIndex = null;
        }
      } else {
        this.openDialogValidation();
      }
    }
  }

  /**
   * Filter So Reach By year
   *
   * @return {void}
   */
  filterSoReachesByYear(): void {
    this.soReaches = this.allSoReaches.filter((soReach: SoReach) => soReach.year.toString() === this.year.toString());
  }

  /**
   * Check if all soreach periods are valid
   *
   * @return boolean
   */
   checkPeriodsValid(): boolean {
    const sortedPeriods = this.sortSoReachPeriods(this.soReachForm.get('periodsBudgets').value);

    return this.isPeriodsNotOverlap(sortedPeriods) && this.isPeriodsCoveringYear(sortedPeriods);
  }

  /**
   * Order/sort soreach periods by startPeriod value
   *
   * @param periods {SoReachPeriodBudget[]}
   * @return {SoReachPeriodBudget[]}
   */
  sortSoReachPeriods(periods: SoReachPeriodBudget[]): SoReachPeriodBudget[] {
    return periods.sort((date1: SoReachPeriodBudget, date2: SoReachPeriodBudget) => {
      if (moment(date1.startDate).isSameOrBefore(date2.startDate)) {
        return -1;
      } else if (moment(date1.startDate).isAfter(date2.startDate)) {
        return 1;
      }
    });
  }

  /**
   * Check if there is not overlap between soreach periods
   *
   * @param periods {SoReachPeriodBudget[]}
   * @return {boolean}
   */
   isPeriodsNotOverlap(periods: SoReachPeriodBudget[]): boolean {
    let referenceDate: Moment;

    return periods.every((period: SoReachPeriodBudget, index: number) => {
      // Compare period.startDate and referenceDate of all periods except the first period
      if (index !== 0 && moment(period.startDate).isSameOrBefore(referenceDate)) {
        this.customToastrService.displayToastr('WARNING', 'Les périodes ne peuvent pas se chevaucher!');

        return false;
      }

      // Set referenceDate with endDate of current period
      referenceDate = moment(period.endDate);

      // Default case true
      return true;
    });
  }

  /**
   * Check if periods cover the whole year
   *
   * @param periods {SoReachPeriodBudget[]}
   * @return {boolean}
   */
  isPeriodsCoveringYear(periods: SoReachPeriodBudget[]): boolean {
    const startYear = moment(`${this.soReachForm.get('year').value}-01-01`, 'YYYY-MM-DD');
    const endYear = moment(`${this.soReachForm.get('year').value}-12-31`, 'YYYY-MM-DD');
    const lastIndex = periods.length - 1;

    let referenceDate: Moment;
    let isCoveringAllYear = true;
    let hasPeriodsSameYear = true;

    // Check if periods are in the same year than soreach, then check if first period starts with startYear
    // and last period ends with endYear, and finally check if periods are covering the whole year!
    if (!startYear.isSame(moment(periods[0].startDate), 'year') || !endYear.isSame(moment(periods[lastIndex].endDate), 'year')) {
      hasPeriodsSameYear = false;
    } else if (!startYear.isSame(moment(periods[0].startDate)) || !endYear.isSame(moment(periods[lastIndex].endDate))) {
      isCoveringAllYear = false;
    } else {
      isCoveringAllYear = periods.every((period: SoReachPeriodBudget, index: number) => {
        // Compare period.startDate and referenceDate of all periods except the first period
        if (index !== 0 && (moment(period.startDate).diff(referenceDate, 'days') > 1)) {
          return false;
        }

        // Set referenceDate with endDate of current period
        referenceDate = moment(period.endDate);

        // Default case true
        return true;
      });
    }

    // Display error message whether periods are not in the same year as soreach or not covering the whole year!
    if (!hasPeriodsSameYear) {
      this.customToastrService.displayToastr('WARNING', 'Les périodes doivent être sur la même année du SoReach!');
    } else if (!isCoveringAllYear) {
      this.customToastrService.displayToastr('WARNING', 'Les périodes saisies ne couvrent pas toute l\'année!');
    }

    return hasPeriodsSameYear && isCoveringAllYear;
  }

    /**
   * Remove soreach period
   *
   * @param {number} index
   * @return {void}
   */
  removePeriod(index: number): void {
    if (this.soReachForm && this.soReachForm.get('periodsBudgets') && this.soReachForm.get('periodsBudgets').value) {
      if (this.soReachForm.get('periodsBudgets').value.length <= 1) {
        this.customToastrService.displayToastr('WARNING', 'Vous ne pouvez pas supprimer la dernière ligne');

        this.soReachForm.get('periodsBudgets').get('0').get('startDate').setValue(moment(`${this.year}-01-01`, 'YYYY-MM-DD'));
        this.soReachForm.get('periodsBudgets').get('0').get('endDate').setValue(moment(`${this.year}-12-31`, 'YYYY-MM-DD'));
        this.soReachForm.get('periodsBudgets').get('0').get('orderPeriod').setValue(0);
      } else if (this.soReachForm.get('periodsBudgets').value[index]) {
        (this.soReachForm.get('periodsBudgets') as FormArray).removeAt(index);
        this.selectedPeriodIndex = 0;
      }
    }

    this.checkOrderPeriod();
  }

  /**
   * Triggered when user asks to close soreach dialog
   *
   * @return {void}
   */
  closeSoreachDialog(): void {
    this.clickToClose = true;

    if (this.soreachOriginalCopy === null || this.isSoreachSimilar(this.soReachForm, this.soreachOriginalCopy)) {
      this.dialogRef.close();
    } else {
      this.openDialogValidation();
    }
  }

  /**
   * Redefine periods order
   *
   * @return {void}
   */
  checkOrderPeriod(): void {
    let orderPeriod = 0;

    this.soReachForm.get('periodsBudgets').value.forEach((soReachPeriod: SoReachPeriodBudget) => {
      soReachPeriod.orderPeriod = orderPeriod;
      orderPeriod = orderPeriod + 1;
    });
  }

  /**
   * Open dialog for user validation if soreach have been modified and not saved
   *
   * @param soreachIndex {number} index of soreach to be selected after validation
   * @return {void}
   */
   openDialogValidation(soreachIndex: number = null): void {
    const dialogRef = this.dialog.open(ValidationDialogComponent, {
      data: {
        title: 'Voulez-vous enregistrer les modifications que vous avez apportées?',
        subTitle: 'Vos modifications seront perdues si vous ne les enregistrez pas.',
      },
      disableClose: true
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((isToSave: boolean) => {
        if (isToSave) {
          this.saveSoReach(soreachIndex);

          // Close soreach dialog after save, if user choosed to quit
          if (this.clickToClose) {
            this.dialogRef.close();
          }
        } else if (this.clickToClose) {
          // Close soreach dialog without saving
          this.dialogRef.close();
        } else {
          this.selectedSoReach(this.selectedSoreachIndex, false);
        }
    });
  }

  /**
   * Replace, in string, "capital letters" with "underscore and lowercase"
   *
   * @param string {string}
   * @return {string}
   */
  toUnderscore(string): string {
    return string.replace(/([A-Z])/g, function($1) { return '_' + $1.toLowerCase(); });
  };

  /**
   * Parse soreach form to json object
   *
   * @param formGroup {FormGroup}
   */
  parseSoReachToJson(formGroup: FormGroup): any {
    let jsonSoReach = {};
    let listPeriodBudget = [];
    let orderPeriod = 0;

    Object.keys(formGroup.value).forEach(key => {
      let underscoreKey = this.toUnderscore(key);

      // If object instance is moment, we change the format to 'YYYY-MM-DD'
      if (formGroup.value[key] instanceof moment) {
        jsonSoReach[underscoreKey] = formGroup.value[key].format('YYYY-MM-DD');
      } else {
        jsonSoReach[underscoreKey] = formGroup.value[key];
      }
    });

    // Add soreach form periods to json soreach
    formGroup.value.periodsBudgets.forEach((soReachPeriod: SoReachPeriodBudget) => {
      const periodBudget = {
        'start_date' : moment(soReachPeriod.startDate).format('YYYY-MM-DD'),
        'end_date' : moment(soReachPeriod.endDate).format('YYYY-MM-DD'),
        'order_period' : orderPeriod,
        'id' : soReachPeriod.id,
        'budget_total' : soReachPeriod.budgetTotal,
        'f2_budget' : soReachPeriod.f2Budget,
        'f3_budget' : soReachPeriod.f3Budget,
        'f4_budget' : soReachPeriod.f4Budget,
        'f5_budget' : soReachPeriod.f5Budget,
        'fo_budget' : soReachPeriod.foBudget,
      }

      listPeriodBudget.push(periodBudget)
      orderPeriod = orderPeriod + 1;
    });

    jsonSoReach['periods_budgets'] = listPeriodBudget;

    return jsonSoReach;
  }
}
