
import {map,  catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User } from '../../resource/user/user.resource';
import { environment } from '../../../environments/environment';
import { UtilitiesHandler } from '../utilities-handlers/utilitiesHandlers';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';

import { Observable } from 'rxjs';

@Injectable()
export class UserService {
  private userConnected: User;

  constructor(
    private http: HttpClient,
    private utilitiesHandler: UtilitiesHandler,
    private customToastrService: CustomToastrService
  ) {}

  /**
   * Set user connected
   *
   * @param {User} user
   * @memberof UserService
   */
  setUserConnected(user: User): void {
    this.userConnected = user;
    localStorage.setItem('trigramme', this.userConnected.sub.toUpperCase());
    localStorage.setItem('email', this.userConnected.email);
  };

  /**
   * Get user connected
   *
   * @returns {User}
   * @memberof UserService
   */
  getUserConnected(): User {
    return this.userConnected;
  };

  /**
   * Get user
   *
   * @returns {Observable<User>}
   * @memberof UserService
   */
  getUser(): Observable<User>  {
    const api_base_url: string = `${environment.api_base_url}/oauth/userinfo`;

    return this.http
      .get(api_base_url).pipe(
      map((data: any) =>
        data
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get email from local storage
   *
   * @returns {string}
   * @memberof UserService
   */
  getEmailFromLocalStorage(): string {
    if (!localStorage.getItem('email')) {
      this.askForReconnect();
    }

    return localStorage.getItem('email');
  }

  /**
   * Ask for reconnect
   *
   * @private
   * @memberof UserService
   */
  private askForReconnect(): void {
    this.customToastrService.displayToastr('ERROR', 'Merci de vous déconnecter et de vous ' +
      'reconnecter afin de mettre à jours vos informations.');
    }
}
