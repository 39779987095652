
import {map,  catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Advertiser } from '../../resource/advertiser.resource';
import { FilteredItem } from '../../resource/filteredItem.resource';
import { environment } from '../../../environments/environment';
import { UtilitiesHandler } from '@service/utilities-handlers/utilitiesHandlers';

import { Observable } from 'rxjs';


@Injectable()
export class AdvertiserService {

  public advertisers: Advertiser[];

  constructor(
    private http: HttpClient,
    private utilitiesHandler: UtilitiesHandler
  ) { }

  /**
   * Get the specific Advertiser with ID
   *
   * @param {(string | number)} id
   * @returns {Observable<Advertiser>}
   * @memberof AdvertiserService
   */
  get(id: string | number): Observable<Advertiser> {
    const api_base_url: string = `${environment.api_base_url}/advertiser/${id}`;

    return this.http
      .get(api_base_url).pipe(
      map((data: any) =>
        new Advertiser(data)
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get the list of Advertisers for filter
   *
   * @param {(Object)} [filters=null]
   * @returns {Observable<FilteredItem[]>}
   * @memberof AdvertiserService
   */
  getListForFilter(filters: Object = null): Observable<FilteredItem[]> {
    const api_base_url: string = `${environment.api_base_url}/advertiser`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params})
      .pipe(
        map((data: any) =>
          data._embedded.advertiser
            .map((advertiser: any) => new Advertiser(advertiser))
            .map((advertisers: any) => new FilteredItem(advertisers))
        )
      )
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get list of Advertiser for autoComplete
   *
   * @param {(Object)} [filters=null]
   * @returns {Observable<Array<Advertiser>>}
   * @memberof AdvertiserService
   */
  getListForAutoComplete(filters: Object = null): Observable<Array<Advertiser>> {
    const api_base_url: string = `${environment.api_base_url}/advertiser`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params}).pipe(
      map((data: any) =>
        data._embedded.advertiser
          .map((advertiser: Advertiser) =>
            new Object({
              display: (filters['id'] || filters['name']) ? advertiser.name : advertiser.name + '(' + advertiser.id + ')',
              value: advertiser.id
            })
          )
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

}
