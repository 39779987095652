import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';

import { ScheduleService } from '../../../service/schedule/schedule.service';
import { CustomToastrService } from '../../../service/toastr/custom-toastr.service';
import { OfferProgram } from '../../../resource/offerProgram.resource';
import { Broadcast } from '../../../resource/broadcast.resource';

import { Subject } from 'rxjs';
import { Schedule } from '../../../resource/schedule/schedule.resource';

@Component({
  selector: 'app-program-card',
  templateUrl: 'program-card.component.html',
  styleUrls: ['program-card.component.scss']
})
export class ProgramCardComponent implements OnInit, OnDestroy, OnChanges {

  @Input() offerProgram: OfferProgram;
  @Input() broadcasts: Broadcast[];
  @Input() schedules: Schedule[]

  public path: String;
  public logo: any;
  public channel: String;
  public comments: Array<String>;

  destroyCommentsFromBroadcast$: Subject<boolean> = new Subject<boolean>();
  destroyList$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private scheduleService: ScheduleService,
    private customToastrService: CustomToastrService,
  ) {
    this.comments = [];
  }

  ngOnInit() {
    this.path = 'assets/images/logos/channels/';
    this.logo = this.getProgramLogo();
    this.getProgramChannel();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // listener for schedules data
    if (changes.schedules && this.schedules) {
      this.schedules.forEach((schedule) => {
        if (schedule.comments) {
          this.comments.push(schedule.comments);
        }
      });

      // removing duplicate comments
      this.comments = this.distinctComments(this.comments);
    }
  }

  ngOnDestroy() {
    this.destroyCommentsFromBroadcast$.next(true);
    this.destroyList$.next(true);
    this.destroyCommentsFromBroadcast$.unsubscribe();
    this.destroyList$.unsubscribe();
  }

  /**
   * Remove duplicates from comments collection
   *
   * @param {Array<String>} comments
   * @returns {Array<String>}
   */
  private distinctComments(comments: Array<String>): Array<String> {
    return Array.from(new Set(comments));
  }

  /**
   * Get logo for program channel
   *
   * @returns {string}
   */
  private getProgramLogo(): string {
    if (this.offerProgram && this.offerProgram.area && this.offerProgram.area.channel && this.offerProgram.area.channel.image) {
      this.logo = this.offerProgram.area.channel.image.split('.');
      return this.logo[0] + '-G.' + this.logo[1];
    } else {
      return null;
    }
  }

  /**
   * Get channel for program
   */
  private getProgramChannel(): void {
    if (this.offerProgram && this.offerProgram.area && this.offerProgram.area.channel && this.offerProgram.area.channel.name) {
      this.channel = this.offerProgram.area.channel.name;
    }
  }
}
