import * as moment from 'moment';
import { Base } from './base.resource';


/**
 * SoReach Period budget interface
 * @export
 * @interface jsonSoReach
 */
export interface JsonSoReachPeriodBudget {
    id: number;
    start_date: string;
    end_date: string;
    order_period: number;
    budget_total: number;
    f2_budget: number;
    f3_budget: number;
    f4_budget: number;
    f5_budget: number;
    fo_budget: number;
}

/**
 * SoReach Period budget resource
 * @export
 * @class SoReachPeriodBudget
 * @extends {Base}
 */
export class SoReachPeriodBudget extends Base {

    static init(): JsonSoReachPeriodBudget {
        return {
            id: null,
            start_date: 'du',
            end_date: 'au',
            order_period: 0,
            budget_total: 0,
            f2_budget: 0,
            f3_budget: 0,
            f4_budget: 0,
            f5_budget: 0,
            fo_budget: 0,
        };
    }

    public id: number;
    public startDate: string;
    public endDate: string;
    public orderPeriod: number;
    public budgetTotal: number;
    public f2Budget: number;
    public f3Budget: number;
    public f4Budget: number;
    public f5Budget: number;
    public foBudget: number;

    constructor(jsonSoReachPeriodBudget: JsonSoReachPeriodBudget) {
      super();
      jsonSoReachPeriodBudget = this.convertToUnderscore(jsonSoReachPeriodBudget);

      this.id = jsonSoReachPeriodBudget.id;
      this.startDate = jsonSoReachPeriodBudget.start_date ? ((jsonSoReachPeriodBudget.start_date['date']
        && typeof jsonSoReachPeriodBudget.start_date['date'] === 'string')
        ? moment(jsonSoReachPeriodBudget.start_date['date']).format('YYYY-MM-DD')
        : moment(jsonSoReachPeriodBudget.start_date).format('YYYY-MM-DD')) : null;
      this.endDate = jsonSoReachPeriodBudget.end_date ? ((jsonSoReachPeriodBudget.end_date['date']
        && typeof jsonSoReachPeriodBudget.end_date['date'] === 'string')
        ? moment(jsonSoReachPeriodBudget.end_date['date']).format('YYYY-MM-DD')
        : moment(jsonSoReachPeriodBudget.end_date).format('YYYY-MM-DD')) : null;
      this.orderPeriod = jsonSoReachPeriodBudget.order_period;
      this.budgetTotal = jsonSoReachPeriodBudget.budget_total;
      this.f2Budget = jsonSoReachPeriodBudget.f2_budget;
      this.f3Budget = jsonSoReachPeriodBudget.f3_budget;
      this.f4Budget = jsonSoReachPeriodBudget.f4_budget;
      this.f5Budget = jsonSoReachPeriodBudget.f5_budget;
      this.foBudget = jsonSoReachPeriodBudget.fo_budget;
    }
}
