import { Base } from './base.resource';
import { Product } from './product.resource';
import { Secodip } from './secodip.resource';
import { Target, JsonTarget } from './target.resource';
import { Advertiser } from './advertiser.resource';
import { Employee } from './employee.resource';
import { Customer } from './customer.resource';

export interface JsonCampaign {
    id: string;
    name: string;
    year: number;
    campaign_number: number;
    product?: Product;
    secodip?: Secodip;
    target?: JsonTarget;
    commercial?: Employee;
    planning_id?: Employee;
    advertiser?: Advertiser;
    mandatary?: Customer;
    sub_mandatary?: Customer;
}

export class Campaign extends Base {
    public id: string;
    public name: string;
    public year: number;
    public campaignNumber: number;

    public product: Product;
    public secodip: Secodip;
    public target: Target;

    public commercial: Employee;
    public planning: Employee;
    public advertiser: Advertiser;
    public mandatary: Customer;
    public subMandatary: Customer;

    constructor(jsonCampaign: JsonCampaign) {
      super();
      jsonCampaign = this.convertToUnderscore(jsonCampaign);

      this.id = jsonCampaign.id;
      this.name = jsonCampaign.name;
      this.year = jsonCampaign.year;
      this.campaignNumber = jsonCampaign.campaign_number;

      if (jsonCampaign.product) {
        this.product = new Product(jsonCampaign.product);
      }

      if (jsonCampaign.secodip) {
        this.secodip = new Secodip(jsonCampaign.secodip);
      }

      if (jsonCampaign.target) {
        this.target = new Target(<JsonTarget> jsonCampaign.target);
      }

      if (jsonCampaign.advertiser) {
        this.advertiser = new Advertiser(jsonCampaign.advertiser);
      }

      if (jsonCampaign.commercial) {
        this.commercial = new Employee(jsonCampaign.commercial);
      }

      if (jsonCampaign.planning_id) {
        this.planning = new Employee(jsonCampaign.planning_id);
      }

      if (jsonCampaign.mandatary) {
        this.mandatary = new Customer(jsonCampaign.mandatary);
      }

      if (jsonCampaign.sub_mandatary) {
        this.subMandatary = new Customer(jsonCampaign.sub_mandatary);
      }

    }

    public getFullName(): string {
        return `${this.year} - ${this.campaignNumber} ${this.name}`;
    }
}
