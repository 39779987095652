import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatAutocomplete } from '@angular/material';

import { FiltersService } from '../service/filters.service';
import { AreaService } from '@service/area/area.service';
import { CategoryService } from '@service/category/category.service';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';
import { AvailabilityComponent } from '../availability/availability.component';
import { GridComponent } from '../grid/grid.component';

import { ChannelService } from '@service/channel/channel.service';
import { InputFilterExtendsComponent } from 'src/app/shared/class/input-filter-extends/input-filter-extends.component';
import { ProgramService } from '@service/program/program.service';
import { OfferService } from '@service/offer/offer.service';
import { OfferProgramService} from '@service/offer-program/offer-program.service';
import { TargetService } from '@service/target/target.service';

import { Observable, Subject, of } from 'rxjs';
import { map, startWith, takeUntil, debounceTime, filter } from 'rxjs/operators';

import * as moment from 'moment';

@Component({
  selector: 'app-program-filter',
  templateUrl: './program-filter.component.html',
  styleUrls: ['./program-filter.component.scss'],
  providers: [ FiltersService ],
})
export class ProgramFilterComponent extends InputFilterExtendsComponent implements OnInit, OnDestroy {
  @Input() filters;
  @Input() DateFrom: FormControl;
  @Input() DateTo: FormControl;
  @Input() loading: boolean;
  @Output() filtersChange: EventEmitter<any> = new EventEmitter();
  @Output() doSearch: EventEmitter<any> = new EventEmitter();

  channelsCtrl = new FormControl();
  areasCtrl = new FormControl();
  category_idCtrl = new FormControl();
  programsCtrl = new FormControl();
  offersCtrl = new FormControl();

  filteredChannels: Observable<any[]>;
  filteredAreas: Observable<any[]>;
  filteredCategory: Observable<any[]>;
  filteredPrograms: Observable<any[]>;
  filteredOffers: Observable<any[]>;

  separatorKeysCodes: number[] = [ENTER, COMMA];

  loadingInput: any;
  onlyExclusivesOffers = false;

  @ViewChild('channelInput') channelsInput: ElementRef<HTMLInputElement>;
  @ViewChild('areaInput') areasInput: ElementRef<HTMLInputElement>;
  @ViewChild('category_idInput') category_idInput: ElementRef<HTMLInputElement>;
  @ViewChild('programInput') programsInput: ElementRef<HTMLInputElement>;
  @ViewChild('offerInput') offersInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoChannel') matAutocompleteChannels: MatAutocomplete;
  @ViewChild('autoArea') matAutocompleteAreas: MatAutocomplete;
  @ViewChild('autoCategory') matAutocompleteCategory_id: MatAutocomplete;
  @ViewChild('autoProgram') matAutocompletePrograms: MatAutocomplete;
  @ViewChild('autoOffer') matAutocompleteOffers: MatAutocomplete;
  @Input() target: FormControl;
  @Input() groupeStatus: boolean;

  public path: string;
  public targetItems: any;
  public defaultLogo = 'default.gif';
  public programFilterOptions = [];
  public channelsFilterOptions;
  public areasFilterOptions;
  public category_idFilterOptions;
  public programsFilterOptions;
  // public programUniteFilterOption = [];
  public disableArea: boolean = true;

  private componentDestroyed: Subject<any> = new Subject();
  public channelFilterOptions = [];
  public areaFilterOptions = [];
  public programUniteFilterOption = [];
  public filterTargetOptions: Observable<string[]>;

  componentDestroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private offerService: OfferService,
    private customToastrService: CustomToastrService,
    private channelService: ChannelService,
    private categoryService: CategoryService,
    private programService: ProgramService,
    private areaService: AreaService,
    private targetService: TargetService,
    public availabilityComponent: AvailabilityComponent,
    public filtersService: FiltersService,
    public offerProgramService: OfferProgramService) {
        super(filtersService);
        this.filtersService = filtersService;
        this.path = 'assets/images/logos/channels/';
        this.loadingInput = {
          channel: true,
          area: true,
          category_id: true,
          program: false,
          offer: false
        }
  }

  ngOnInit() {
    this.getTargetItem();
    // remove unused filter params
    this.filters.offer_from = null;
    this.filters.offer_to = null;
    this.filters.start_communication_period = null;
    this.filters.end_communication_period = null;

    this.getChannelOptionsFilter();
    this.getAreaOptionsFilter();
    this.getCategory();

    this.target.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        // Init total selected grp data with default values after change event on GRP Cible field
        GridComponent.initTotalSelectedGrpData();
        this.filterTargetOptions = of(this.filterTarget(data));
      });
    this.getProgramOptionsFilter();
    this.getOfferOptionsFilter();
  }

  getLogoByValue(item): string {
    let channel;

    if (this.channelsFilterOptions && this.channelsFilterOptions.length) {
      channel = this.channelsFilterOptions.find(
        function(elem) {
          return elem.display === item.display;
        });

      return this.path + channel.logo;
    } else {
      return null;
    }
  }

  getCategory(): void {
    this.categoryService.getListForFilter()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(categories => {
          this.category_idFilterOptions = categories;
          this.loadingInput['category_id'] = false;
          this.filteredCategory = this.category_idCtrl.valueChanges.pipe(
            startWith(null),
            map((category) => category ? this._filter(category, 'category_id') : this.category_idFilterOptions.slice()));
      });
  }

  getProgramOptionsFilter(): void {
    this.programsCtrl.valueChanges
      .pipe(
        debounceTime(500),
        filter((item) => item),
        takeUntil(this.componentDestroyed)
      )
      .subscribe((data) => {
        this.loadingInput['program'] = true;
        let filterList: Object = {
          broadcasted_from: this.filters.broadcasted_from,
          broadcasted_to: this.filters.broadcasted_to,
          name: data.toUpperCase()
        };

        if (this.filters.category_id && this.filters.category_id[0]) {
          filterList['category_id'] = this.filters.category_id[0].value;
        }

        if (this.filters.channels && this.filters.channels.length > 0) {
          filterList['channel_id'] = '';
           this.filters.channels.forEach((element, index) => {
            filterList['channel_id'] +=  element.value;
            if (index !== this.filters.channels.length - 1 ) {
              filterList['channel_id'] += ';';
            }
          });
        }

        if (this.filters.offer_id) {
          filterList['offer_id'] = this.filters.offer_id;
        }

        this.filteredPrograms = this.programService.getListForFilter(filterList)
          .pipe(
            map((response) => {
              this.loadingInput['program'] = false;
              return response;
            })
          )
      });
  }

  getOfferOptionsFilter(): Observable<any> | void {
    this.offersCtrl.valueChanges
      .pipe(
        debounceTime(500),
        filter((item) => item),
        takeUntil(this.componentDestroyed)
      )
      .subscribe((data) => {
        let filterOffer: Object = {
          offer_from: this.filters.broadcasted_from,
          offer_to: this.filters.broadcasted_to,
          name: data.toUpperCase()
        };
        this.loadingInput['offer'] = true;
        this.filteredOffers = this.offerService.getListForFilter(filterOffer)
          .pipe(
            map((response) => {
              this.loadingInput['offer'] = false;
              return response;
            })
          )
      });
  };

  getChannelOptionsFilter(): void {
    this.channelService.getListForFilter()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(channels => {
          this.channelsFilterOptions = channels;
          this.loadingInput['channel'] = false;
          this.filteredChannels = this.channelsCtrl.valueChanges.pipe(
            startWith(null),
            map((channel) => channel ? this._filter(channel, 'channels') : this.channelsFilterOptions.slice()));
      }, error => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });
  };

  updateAreaOptionsFilter() {
    if (!this.filtersService.isFr3Selected(this.filters)) {
      this.filters.areas = [];
      this.areasFilterOptions = [];
    } else {
        let fr3ChannelId = 3;
        this.getAreaOptionsFilter(fr3ChannelId);
    }
  }

  getAreaOptionsFilter(channelId?) {
    this.areaService.getListForFilter()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(areas => {
          this.areasFilterOptions = areas;
          this.loadingInput['area'] = false;
          this.filteredAreas = this.areasCtrl.valueChanges.pipe(
            startWith(null),
            map((area) => area ? this._filter(area, 'areas') : this.areasFilterOptions.slice()));
      });
  };

  updateFilters() {
    this.filters.broadcasted_from = moment(this.DateFrom.value).format('YYYY-MM-DD');
    this.filters.broadcasted_to = moment(this.DateTo.value).format('YYYY-MM-DD');
    this.filters.year = moment(this.filters.broadcasted_from, 'YYYY-MM-DDTHH:mm:ss').year();
    this.filters.month = moment(this.filters.broadcasted_from, 'YYYY-MM-DDTHH:mm:ss').month();
    this.filtersChange.emit(this.filters);
  }

  /**
   * Closure call a member function
   * @param item void
   */
  formatTarget(item: void): any {
    return (value) => this.displayTarget(value);
  }

  /**
   * Get the value for display in HTML, display nothing if don't find in item Target
   * @param value string
   * @return string
   */
  displayTarget(value: string): string {
    if (this.targetItems) {
      let item = this.targetItems.find((data) => data.value === value)
      if (item) {
        return item.display;
      };
    }
    return value;
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.unsubscribe();
  }

  search(): void {
    this.doSearch.emit();
  }

  /**
   * Export Xlsx Conducteur file
   */
  generateConducteur() {
    this.loading = true;
    const params = {};

    // Check that all required filter values had been set
    if (!this.filters || !this.filters.broadcasted_from || !this.filters.broadcasted_to || !this.filters.channels
        || !this.filters.areas || !this.filters.channels.length || !this.filters.areas.length) {
      this.loading = false;
      this.customToastrService.displayToastr('WARNING', 'Veuillez renseigner les champs: période, chaîne et région');

      return;
    }

    // set filter values
    params['start_period'] = this.filters.broadcasted_from;
    params['end_period'] = this.filters.broadcasted_to;
    params['areas'] = [];
    this.filters.areas.forEach((area) => {
      params['areas'].push('\'' + area.value + '\'');
    });

    this.offerProgramService.generateXlsxConducteur(params)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        this.loading = false;
        this.customToastrService.displayToastr('SUCCESS', 'Fichier Conducteur généré avec succès!');
      }, () => {
        this.loading = false;
        this.customToastrService.displayToastr('ERROR', 'Erreur lors da la génération du fichier Conducteur!');
      });
  }

  /**
   * Event when user click on toggle to get only or not exclusives offers
   * @param event
   */
  switchExclusivesOffers(event: MatSlideToggleChange): void {
    if (this.onlyExclusivesOffers = !!event.checked) {
      this.filters.isExclusive = 1;
    } else {
      this.filters.isExclusive = 0
    }
    this.onQueryParams();
  }

  /**
   * Get Target Item
   * @returns void
   */
  private getTargetItem(): void {
    this.targetService
      .getListForFilter()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(result => {
        this.targetItems = result;
        this.filterTargetOptions = of(this.targetItems);
    })
  }

  /**
   * Filter Targer item for autocomplete
   * @param value string
   * @return string[]
   */
  private filterTarget(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.targetItems.filter(option => option.display.toLowerCase().includes(filterValue));
  }
}
