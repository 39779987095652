import { UserParam } from '../resource/user/user.resource';

export abstract class AbstractService {

  /**
   * To underscore
   *
   * @param {*} string
   * @returns {string}
   * @memberof AbstractService
   */
  public toUnderscore(string): string {
    return string.replace(/([A-Z])/g, function($1) { return '_' + $1.toLowerCase(); });
  };

  /**
   * convert to underscore << _ >>
   *
   * @param {*} object
   * @returns
   * @memberof AbstractService
   */
  public convertToUnderscore(object) {
      let result = <any>{};
      Object.keys(object).forEach(key => {
          let underscoreKey = this.toUnderscore(key);
          result[underscoreKey] = object[key];
      });

      return result;
  }

  /**
   * convert to collection
   *
   * @param {*} array
   * @returns {Array<any>}
   * @memberof AbstractService
   */
  public convertCollection(array): Array<any> {
    let result = [];
    array.forEach(item => {
      result.push(this.convertToUnderscore(item));
    });

    return result;
  }

  /**
   * Get user param from localStorage for region, program etc...
   *
   * @return {UserParam}
   */
  public getUserParam(): UserParam {
    return localStorage.getItem('parameter') ? JSON.parse(localStorage.getItem('parameter')) : new UserParam();
  }

  /**
   * Set user param for localStorage
   *
   * @param params {UserParam}
   */
  public setUserParam(params: UserParam): void {
    localStorage.setItem('parameter', JSON.stringify(params));
  }
}
