import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilitiesHandler } from '@service/utilities-handlers/utilitiesHandlers';
import { environment } from '../../../environments/environment';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

export interface JsonEmail {
    id_entity: string;
    email_ut?: string;
    type: string;
}

@Injectable()
export class EmailService {
  private route: string = 'email';

  constructor(
      private http: HttpClient,
      private utilitiesHandler: UtilitiesHandler
  ) { }

  /**
   * Create email and send
   *
   * @param {*} email
   * @returns {Observable<Object>}
   * @memberof EmailService
   */
  public create(email): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;

    return this.http
      .post(api_base_url, this.extract(email))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Build param email
   *
   * @private
   * @param {JsonEmail} email
   * @returns {Object}
   * @memberof EmailService
   */
  private extract(email: JsonEmail): Object {
    return {
        type: email.type,
        email_ut: email.email_ut,
        id_entity: email.id_entity
    }
  }
}
