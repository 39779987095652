import { Purchase } from '../purchase.resource';
import { SchedulePackage } from './schedule-package.resource';
import { SchedulePeriod } from './schedule-period.resource';
import { ScheduleTime } from './schedule-time.resource';
import { Base } from '../base.resource';
import { PurchaseProgram } from '../purchaseProgram.resource';
import { ScheduleException } from './schedule-exception.resource';
import { SoReach } from '../so-reach.resource';

export interface JsonSchedule {
    id?: number;
    comments?: string;
    days?: any;
    except_start_period?: string;
    except_end_period?: string;
    except_start_time?: string;
    except_end_time?: string;
    is_legacy?: number;
    broadcast_count?: number;
    offer_program?: any;
    parent?: any;
    children?: any;
    purchase?: any;
    periods?: any[];
    times?: any[];
    schedule_packages?: any[];
    purchase_program?: any;
    exceptions?: any[];
    so_reachs?: Array<SoReach>;
    soreach_availability?: number;
}

export class Schedule extends Base {
    public id: number;
    public comments: string;
    public days: any;
    public periods: SchedulePeriod[];
    public times: ScheduleTime[];
    public schedulePackages: SchedulePackage[];
    public exceptStartPeriod: string;
    public exceptEndPeriod: string;
    public exceptStartTime: string;
    public exceptEndTime: string;
    public offerProgram: any;
    public isLegacy: number;
    public broadcastCount: number;
    public parent: Schedule;
    public children: Schedule[];
    public purchase: Purchase;
    public purchaseProgram: PurchaseProgram;
    public scheduleExceptions: ScheduleException[];
    public soReachAvailability: number;
    public soReachs: Array<SoReach>;

    constructor(jsonSchedule: JsonSchedule) {
        super();
        jsonSchedule = this.convertToUnderscore(jsonSchedule);
        this.id = jsonSchedule.id;
        this.comments = jsonSchedule.comments;
        this.days = jsonSchedule.days;
        // this.setExcepts(jsonSchedule);
        this.isLegacy = jsonSchedule.is_legacy;
        this.broadcastCount = jsonSchedule.broadcast_count;

        if (jsonSchedule.periods) {
            this.setPeriods(jsonSchedule);
        }

        if (jsonSchedule.times) {
            this.setTimes(jsonSchedule);
        }

        if (jsonSchedule.schedule_packages) {
            this.setPackages(jsonSchedule);
        }

        if (jsonSchedule.offer_program) {
            this.offerProgram = {id: jsonSchedule.offer_program.id};
        }

        if (jsonSchedule.parent) {
            this.parent = new Schedule(jsonSchedule.parent);
        }

        if (jsonSchedule.children) {
            this.setChildren(jsonSchedule)
        }

        if (jsonSchedule.purchase) {
            this.purchase = new Purchase(jsonSchedule.purchase);
        }

        if (jsonSchedule.purchase_program) {
            this.purchaseProgram = jsonSchedule.purchase_program;
        }

        if (jsonSchedule.exceptions) {
            this.setExceptions(jsonSchedule);
        }

        if (jsonSchedule.soreach_availability) {
          this.soReachAvailability = jsonSchedule.soreach_availability;
        }

        if (jsonSchedule.so_reachs) {
          this.soReachs = jsonSchedule.so_reachs;
        }
    }

    public hasCompleteExceptData() {
        return !!this.scheduleExceptions;
    }

    public getDaysAsString(): string {
        if (!Array.isArray(this.days)) {
            return this.days;
        }

        let result = '';
        this.getDayList().forEach(day => {
            if (this.days.indexOf(Number(day.value)) !== -1) {
                result = result + '1';
            } else {
                result = result + '0';
            }
        });

        return result;
    }

    public getDaysAsArray(): any[] {
        if (Array.isArray(this.days)) {
            return this.days;
        }

        let result = [];
        let pushSundayAtEnd = false;
        let splitedDays = this.days.split('');
        let lastItem = splitedDays.pop();
        splitedDays.unshift(lastItem);

        splitedDays.forEach(function(value, index) {
            if (value === '1') {
                if (index === 0) {
                    pushSundayAtEnd = true;
                } else {
                    result.push(index);
                }
            }
        });

        if (pushSundayAtEnd) {
            result.push(0);
        }

        return result;
    }

    /**
     * Convert a string binary week's days to an array week's days
     *
     * @param {string} binaryDays
     * @return {string[]}
     */
    public binaryDaysToStringDays(binaryDays: string): string[] {
        const days = ['lu', 'ma', 'me', 'je', 've', 'sa', 'di'];
        const arrayDays = [];

        for (let i = 0; i < binaryDays.length; i++) {
            // '1' => day is selected, '0' => day is not selected
            if (binaryDays[i] === '1') {
                arrayDays.push(days[i]);
            }
        }

        return arrayDays;
    }

    public getNumberOfSelectedDays(): number {
        if (!Array.isArray(this.days)) {
            let arrayDays = this.getDaysAsArray();
            return arrayDays.length;
        } else {
            return this.days.length;
        }
    }

    private setChildren(jsonSchedule) {
        let result = [];
        jsonSchedule.children.forEach((schedule: any) => {
            result.push(new Schedule(schedule));
        });

        this.children = result;
    }

    private setPeriods(jsonSchedule: JsonSchedule) {
        let periods = [];
        jsonSchedule.periods.forEach((period: any) => {
            periods.push(new SchedulePeriod(period));
        });

        this.periods = periods;
    }

    private setTimes(jsonSchedule: JsonSchedule) {
        let times = [];
        jsonSchedule.times.forEach((time: any) => {
            times.push(new ScheduleTime(time));
        });
        this.times = times;
    }

    private setPackages(jsonSchedule: JsonSchedule) {
        let schedulePackages = [];
        if (jsonSchedule.schedule_packages) {
            jsonSchedule.schedule_packages.forEach((schedulePackage: any) => {
                schedulePackages.push(new SchedulePackage(schedulePackage));
            });
        }
        this.schedulePackages = schedulePackages;
    }

    private setExcepts(jsonSchedule: JsonSchedule) {
        // from FORM
        if (jsonSchedule['except'] && Array.isArray(jsonSchedule['except'])) {
            if (jsonSchedule['except'].length === 0) {
                this.resetExcepts();
                return;
            }
            let exceptFromForm = this.convertToUnderscore(jsonSchedule['except'][0]);
            this.exceptStartPeriod = exceptFromForm.except_start_period;
            this.exceptEndPeriod = exceptFromForm.except_end_period;
            this.exceptStartTime = exceptFromForm.except_start_time;
            this.exceptEndTime = exceptFromForm.except_end_time;
        } else if (jsonSchedule.except_start_period && !jsonSchedule.except_start_period['date']) {
           this.exceptStartPeriod = jsonSchedule.except_start_period ? jsonSchedule.except_start_period : null;
           this.exceptEndPeriod = jsonSchedule.except_end_period ? jsonSchedule.except_end_period : null;
           this.exceptStartTime = jsonSchedule.except_start_time;
           this.exceptEndTime = jsonSchedule.except_end_time;

       } else {
           // from API
           this.exceptStartPeriod = jsonSchedule.except_start_period ? jsonSchedule.except_start_period['date'] : null;
           this.exceptEndPeriod = jsonSchedule.except_end_period ? jsonSchedule.except_end_period['date'] : null;
           this.exceptStartTime = jsonSchedule.except_start_time;
           this.exceptEndTime = jsonSchedule.except_end_time;
       }
    }

    private resetExcepts() {
        this.exceptStartPeriod = null;
        this.exceptEndPeriod = null;
        this.exceptStartTime = null;
        this.exceptEndTime = null;
    }

    private getDayList() {
        return [
          {value: '1', display: 'Lu'},
          {value: '2', display: 'Ma'},
          {value: '3', display: 'Me'},
          {value: '4', display: 'Je'},
          {value: '5', display: 'Ve'},
          {value: '6', display: 'Sa'},
          {value: '0', display: 'Di'},
        ];
      }

    private setExceptions(jsonSchedule: JsonSchedule) {
        let scheduleExceptions = [];
        jsonSchedule.exceptions.forEach((exception: any) => {
            scheduleExceptions.push(new ScheduleException(exception));
        });
        this.scheduleExceptions = scheduleExceptions;
    }
}
