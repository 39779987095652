import { Injectable } from '@angular/core';

import { OfferProgram } from '../../resource/offerProgram.resource';
import { Schedule } from '../../resource/schedule/schedule.resource';
import { SchedulePeriod } from '../../resource/schedule/schedule-period.resource';
import { ScheduleTime } from '../../resource/schedule/schedule-time.resource';
import { Broadcast, JsonBroadcast } from '../../resource/broadcast.resource';

import * as moment from 'moment';

@Injectable()
export class ScheduleBroadcastService {

  constructor() { }

  /**
   * Create Broadcasts from Schedule
   *
   * @param {Schedule} schedule
   * @param {OfferProgram} offerProgram
   * @returns {Broadcast[]}
   * @memberof ScheduleBroadcastService
   */
  public createBroadcastsFromSchedule(schedule: Schedule, offerProgram: OfferProgram): Broadcast[] {
    let result = [];
    if (!schedule) {
      return result;
    }

    schedule.periods.forEach(period => {
      schedule.times.forEach(time => {
        result = result.concat(this.createBroadcastByPeriodTimeAndDays(period, time, schedule.days));
      });
    });

    if (schedule.scheduleExceptions) {
      result = this.removeExcept(schedule, result);
    }

    result.forEach((broadcast: Broadcast) => {
      broadcast.addOfferProgram(offerProgram);
      broadcast.program = offerProgram.program;
      broadcast.area = offerProgram.area;
    })

    return result;
  }

  /**
   * Create Broadcast by period time and days
   *
   * @private
   * @param {SchedulePeriod} period
   * @param {ScheduleTime} time
   * @param {any []} days
   * @returns {Array<Broadcast[]>}
   * @memberof ScheduleBroadcastService
   */
  private createBroadcastByPeriodTimeAndDays(period: SchedulePeriod, time: ScheduleTime , days: any []): Array<Broadcast[]> {
    let broadcasts = [];
    const diffDays = moment(period.endTime, 'YYYY-MM-DD').diff(moment(period.startTime, 'YYYY-MM-DD'), 'days');

    for (let i = 0; i <= diffDays; i++) {
        const broadcastStartDate = moment(period.startTime, 'YYYY-MM-DD').add(i, 'days');
        const broadcastEndDate = moment(period.startTime, 'YYYY-MM-DD').add(i, 'days');
        const dayNumber = parseInt(broadcastStartDate.format('d'), 10);

        if (days.indexOf(dayNumber) !== -1) {
          const startTimeSplited = time.startTime.split(':');
          const endTimeSplited = time.endTime.split(':');
          const startTimeLabel = startTimeSplited[0] + startTimeSplited[1] + '00';
          const endTimeLabel = endTimeSplited[0] + endTimeSplited[1] + '00';

          const jsonBroadcast: JsonBroadcast = {
            id: null,
            start_time: broadcastStartDate.format('YYYY-MM-DD'),
            end_time: broadcastEndDate.format('YYYY-MM-DD'),
            start_time_label: startTimeLabel,
            end_time_label: endTimeLabel,
            broadcast_type: 0,
          };

          let broadcast = new Broadcast(jsonBroadcast);
          broadcast.action = Broadcast.NEW;
          broadcasts.push(<Broadcast>broadcast);
      }
    }

    return broadcasts;
  }

  /**
   * Remove Except
   *
   * @private
   * @param {Schedule} schedule
   * @param {*} broadcasts
   * @returns {Array<any>}
   * @memberof ScheduleBroadcastService
   */
  private removeExcept(schedule: Schedule, broadcasts): Array<any> {
    let result = [];
    let indexToRemove = [];
    let exceptionList = [];

    schedule.scheduleExceptions.forEach(exception => {
      const exceptStartTimeSplited = exception.exceptStartTime.split(':');
      const exceptEndTimeSplited = exception.exceptEndTime.split(':');
      const exceptStartTime = exceptStartTimeSplited[0] + exceptStartTimeSplited[1] + '00';
      const exceptEndTime = exceptEndTimeSplited[0] + exceptEndTimeSplited[1] + '00';
      const exceptStartPeriod = moment(exception.exceptStartPeriod, 'YYYY-MM-DD');
      const exceptEndPeriod = moment(exception.exceptEndPeriod, 'YYYY-MM-DD');

      const currentException = {
        exceptStartPeriod: exceptStartPeriod,
        exceptEndPeriod: exceptEndPeriod,
        exceptStartTime: exceptStartTime,
        exceptEndTime: exceptEndTime
      };

      exceptionList.push(currentException);
    });

    broadcasts.forEach((broadcast: Broadcast, index) => {
      const startDate = moment(broadcast.startTime, 'YYYY-MM-DD');

      for (let i = 0; i < exceptionList.length; i++) {
        if (startDate >= exceptionList[i].exceptStartPeriod && startDate <= exceptionList[i].exceptEndPeriod) {
          if (broadcast.startTimeLabel >= exceptionList[i].exceptStartTime && broadcast.endTimeLabel <= exceptionList[i].exceptEndTime) {
            indexToRemove.push(index);
            break;
          }
        }
      };
    });

    broadcasts.forEach((broadcast, index ) => {
      if (indexToRemove.indexOf(index) === -1) {
        result.push(broadcast);
      }
    });

    return result;
  }
}
