
import {map,  catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Customer } from '../../resource/customer.resource';
import { FilteredItem } from '../../resource/filteredItem.resource';
import { environment } from '../../../environments/environment';
import { UtilitiesHandler } from '@service/utilities-handlers/utilitiesHandlers';

import { Observable } from 'rxjs';

@Injectable()
export class CustomerService {
  private route: string = 'customer';

  constructor(
    private http: HttpClient,
    private utilitiesHandler: UtilitiesHandler
  ) { }

  /**
   * Get Customer with ID
   *
   * @param {(string | number)} id
   * @returns {Observable<Customer>}
   * @memberof CustomerService
   */
  get(id: string | number): Observable<Customer> {
    const api_base_url = `${environment.api_base_url}/${this.route}/${id}`;

    return this.http
      .get(api_base_url).pipe(
      map((data: any) =>
        new Customer(data)
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get List of Customer for Filter
   *
   * @param {Object} [filters=null]
   * @returns {Observable<FilteredItem[]>}
   * @memberof CustomerService
   */
  getListForFilter(filters: Object = null): Observable<FilteredItem[]> {
    const api_base_url = `${environment.api_base_url}/${this.route}`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params}).pipe(
      map((data: any) =>
        data._embedded.customer
          .map((jsonCustomer: any) =>
            new Customer(jsonCustomer)
          )
          .map((customer: Customer) =>
            new FilteredItem(customer)
          )
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get list of Customer for auto complete
   *
   * @param {Object} [filters=null]
   * @returns {Observable<Array<any>>}
   * @memberof CustomerService
   */
  getListForAutoComplete(filters: Object = null): Observable<Array<any>> {
    const api_base_url = `${environment.api_base_url}/${this.route}`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params}).pipe(
      map((data: any) =>
        data._embedded.customer
          .map((customer: any) =>
            ({
              display: customer.name + '(' + customer.id + ')',
              value: customer.id
            })
          )
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }
}
