<div class="grid-header">
  <table>
      <tr>
          <td class="sub-header" *ngFor="let broadcastsTime of broadcastsTimes; index as i">
              <div *ngIf="broadcastsTime.length > 0" [matTooltip]="broadcastWarning[i]" matTooltipClass="styleTooltipWarning">
                  <mat-icon [ngClass]="broadcastWarningIconClass[i]">warning</mat-icon>
              </div>
          </td>
      </tr>
  </table>
</div>
