
export interface JsonAdvertiser {
    id: number;
    name: string;
}

export class Advertiser {
    public id: number;
    public name: string;

    constructor(jsonAdvertiser: JsonAdvertiser) {
        this.id = jsonAdvertiser.id;
        this.name = jsonAdvertiser.name;
    }
}
