<div class="informationDispo">
  <div *ngFor="let programs of informationOffer; index as i; last as isLast">
    <table [ngClass]="{'border-bottom': !isLast}">
      <tr fxLayout="row" fxLayoutGap="5px">
        <td class="table-row" fxLayoutAlign="start center">
          <img class="image" [src]="pathImage(programs[0].channelImage)">
          <span>{{ programs[0].programName }}</span>
        </td>
        <td>
          <div *ngFor="let program of programs; index as i">
            <span>{{ program.productName }} ({{ program.advertiserName }})</span>
          </div>
        </td>
      </tr>
    </table>
  </div>
</div>
