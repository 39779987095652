import { CellPosition } from './cell-position.resource';
import { Broadcast } from '../broadcast.resource';
import * as moment from 'moment';

const UNAVAILABLE     = 'unavailable';
const AVAILABLE       = 'available';
const PROPAL_CHECKED  = 'propal-checked';
const PARTAVAILABLE   = 'partially-available';

export class Cell {

  static UNAVAILABLE = UNAVAILABLE;
  static AVAILABLE = AVAILABLE;
  static PROPAL_CHECKED = PROPAL_CHECKED;
  static PARTALLY_AVAILABLE = PARTAVAILABLE;

  public type: string;
  public duration: number;
  public hasGameModule: boolean;
  public position: CellPosition;
  public info: Info;
  public broadcasts: Broadcast[];
  public tooltip: PurchaseTooltip;

  public isSoReach: boolean;
  public isDisable: boolean;
  public isCompletelyChecked: boolean;
  public isPartiallyChecked: boolean;

  public channelGroup: string;
  public channelImage: string;
  public programName: string;

  constructor(type) {
    this.type = type;
    this.isPartiallyChecked = false;
    this.isCompletelyChecked = false;
  }
}

export class Info {}

export class PurchaseTooltip {
  public productName: string;
  public advertiserName: string;
  public secodip: string;
  public commercialName: string;
  public expidationDate: Date;
  public alertSwitchDate: string;

  public toString(): string {
    let formatedTooltip = '';

    if (this.productName) {
      formatedTooltip = this.productName.toUpperCase() + `\n`;
    }
    if (this.advertiserName)  {
      formatedTooltip += `\n` + this.advertiserName.toUpperCase();
    }
    if (this.secodip)  {
      formatedTooltip += `\n` + this.secodip.toUpperCase();
    }
    if (this.commercialName)  {
      formatedTooltip += `\n` +  'Commercial : ' + this.commercialName.toUpperCase();
    }
    if (this.expidationDate) {
      formatedTooltip += `\n` + 'Expire le : ' + moment(this.expidationDate, 'YYYY-MM-DD').format('DD/MM/YYYY');
    }
    if (this.alertSwitchDate) {
      formatedTooltip += `\n\n Alerte posée le ${this.alertSwitchDate}`
    }

    return formatedTooltip;
  }
}
