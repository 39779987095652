import { OfferProgramBudget } from './offerProgramBudget.resource';
import { AdvertisingPackage, JsonAdvertisingPackage } from './advertising-package.resource';
import { Base } from './base.resource';

export interface JsonOfferProgramBudgetPackage {
    id: number;
    quantity: number ;
    price: number;
    duration: number;
    advertising_package: any;
    offer_program_budget: any;
}

export class OfferProgramBudgetPackage extends Base {
    public id: number;
    public quantity: number;
    public price: number;
    public duration: number;
    public advertisingPackage: AdvertisingPackage;
    public offerProgramBudget: OfferProgramBudget;
    public periodicity: any;
    public status: string;
    public theoricalPresence: number;

    constructor(jsonPackage: JsonOfferProgramBudgetPackage) {
        super();
        jsonPackage = this.convertToUnderscore(jsonPackage);

        this.id = jsonPackage.id;
        this.quantity = typeof jsonPackage.quantity === 'boolean' && jsonPackage.quantity === true ? 1 : jsonPackage.quantity;
        this.price = jsonPackage.price;
        this.duration = jsonPackage.duration;
        this.advertisingPackage = new AdvertisingPackage(jsonPackage.advertising_package);
        this.offerProgramBudget = new OfferProgramBudget(jsonPackage.offer_program_budget);
        this.theoricalPresence = 0;
        this.status = '';
    }
}
