import { Base } from './base.resource';
import { JsonSoReachPeriodBudget, SoReachPeriodBudget } from './soreach-period-budget.resource';

export interface JsonSoReach {
  id?: number;
  name: string;
  year: number;
  periods_budgets?: JsonSoReachPeriodBudget[];
}

export class SoReach extends Base {
  public id: number;
  public name: string;
  public year: number;
  public periodsBudgets: SoReachPeriodBudget[];

  constructor(jsonSoReach: JsonSoReach) {
    super();
    jsonSoReach = this.convertToUnderscore(jsonSoReach);

    this.id = jsonSoReach.id ? jsonSoReach.id : null;
    this.name = jsonSoReach.name ? jsonSoReach.name : null;
    this.year = jsonSoReach.year ? jsonSoReach.year : null;
    this.periodsBudgets = [];

    if (jsonSoReach.periods_budgets) {
      jsonSoReach.periods_budgets.forEach((periodBudget: JsonSoReachPeriodBudget)  => {
        this.periodsBudgets.push(new SoReachPeriodBudget(periodBudget));
      });
    }
  }
}
