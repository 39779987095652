import { Injectable } from '@angular/core';

import * as moment from 'moment';
import 'moment/locale/fr';

@Injectable()
export class DateService {

    // deprecated : please use momentJs
    public getNumberOfWeek(year: number = null): number {
        year = year || new Date().getFullYear();
        let day, isLeap;

        day = new Date(year, 0, 1);
        isLeap = new Date(year, 1, 29).getMonth() === 1;

        return day.getDay() === 4 || isLeap && day.getDay() === 3 ? 53 : 52;
    }

    // deprecated : please use momentJs
    public getNumberOfDay(year: number = null, month: number = null) {
        year = year || new Date().getFullYear();
        month = month || new Date().getMonth();
        month = month - 1;
        let isLeap = ((year % 4) === 0 && ((year % 100) !== 0 || (year % 400) === 0));
        return [31, (isLeap ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
    }

    public getMonthFormWeek(weekNumber, year) {
        let formatter = new Intl.DateTimeFormat('fr', { month: 'long' });
        return formatter.format(new Date(year, 0, 1 + ((weekNumber - 1) * 7)));
    }

    public getWeek(date) {
        let onejan: any = new Date(date.getFullYear(), 0, 1);
        return Math.ceil((((date - onejan) / 86400000) + onejan.getDay() + 1) / 7);
    }

    public getMonthLabel(month): string {
        return moment().month(month).format('MMMM');
    }

    private extractYearFromBroadcast(program): number {
        let start_time = program._embedded.broadcasts[0].start_time;
        return new Date(start_time).getFullYear();
    }

    public getI18iForDatePicker() {
      return {
        monthNames: moment.months(),
        weekdays: moment.weekdays(),
        weekdaysShort: moment.weekdaysShort(),
        firstDayOfWeek: moment.localeData().firstDayOfWeek(),
        week: 'Semaine',
        calendar: 'Calendrier',

        clear: 'Réinitialiser',
        today: 'Aujourd\'hui',
        cancel: 'Annuler',
        formatDate: function(d) {
            return moment(d,'YYYY-MM-DDTHH:mm:ss').format(moment.localeData().longDateFormat('L'));
        },
        parseDate: function(s) {
            return moment(s, moment.localeData().longDateFormat('L')).toDate();
        },
        formatTitle: function(monthName, fullYear) {
            return monthName + ' ' + fullYear;
        }
      };
  }
}
