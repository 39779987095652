import { PurchaseBudgetService } from '../../../../../../service/purchase-budget/purchase-budget.service';
import { BroadcastPurchasedService } from '../../../../../../service/broadcast-purchased/broadcast-purchased.service';
import { Purchase } from '../../../../../../resource/purchase.resource';
import { Schedule } from '../../../../../../resource/schedule/schedule.resource';
import { ScheduleService } from '../../../../../../service/schedule/schedule.service';
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { PurchaseProgram } from '../../../../../../resource/purchaseProgram.resource';
import {PurchaseService} from '@service/purchase/purchase.service';

@Component({
  selector: 'app-advertising-package',
  templateUrl: './advertising-package.component.html',
  styleUrls: ['./advertising-package.component.scss']
})
export class AdvertisingPackageComponent implements OnInit, OnChanges {

  @Input() purchaseProgram: PurchaseProgram;
  @Input() purchase: Purchase;

  private filters: any;
  public schedules: Schedule[];
  public loading: boolean;

  constructor(
    private scheduleService: ScheduleService,
    private purchaseBudgetService: PurchaseBudgetService,
    private broadcastPurchasedService: BroadcastPurchasedService,
    private purchaseService: PurchaseService
  ) { }

  ngOnInit() {
    this.broadcastPurchasedService.removeScheduleFromList$.subscribe((schedule: Schedule) => {
        this.removeScheduleFromArray(schedule);
    });

    this.purchaseBudgetService.schedulePackageLoadingSource$.subscribe(loading => {
        this.loading = loading;
    });
  }

  ngOnChanges(changes: any): void {
    if (changes.purchaseProgram) {
      this.initComponent();
    }
  }

  private initComponent() {
    if (!this.purchase.id) {
      return null;
    }

    this.initFilter();
    this.getSchedules();
  }

  private getSchedules() {
    this.loading = true;
    this.scheduleService
      .getList(this.filters)
      .subscribe(schedules => {
        this.schedules = schedules;
        this.broadcastPurchasedService.schedulesPurchasedByOfferProgram = this.schedules;
        this.loading = false;
        this.purchaseService.savePurchaseForCompare('dispositif');
      })
  }

  private initFilter() {
    this.filters = {
      purchase_program_id: this.purchaseProgram.id
    };
  }

  private removeScheduleFromArray(schedule: Schedule) {
    let newArray = []; this.schedules.forEach(item => {
      if (schedule.id !== item.id) {
        newArray.push(item);
      }
    });

    this.schedules = newArray;
    this.broadcastPurchasedService.schedulesPurchasedByOfferProgram = this.schedules;
  }
}
