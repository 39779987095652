import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Offer } from "../../resource/offer.resource";
import { FilteredItem } from "../../resource/filteredItem.resource";

import { UtilitiesHandler } from "@service/utilities-handlers/utilitiesHandlers";
import { AbstractService } from "../abstract.service";

import {
  DigitalBudgetName,
  JsonDigitalBudgetName,
} from "../../resource/digital-budget-name.resource";

import { environment } from "../../../environments/environment";
import { Observable, Subject } from "rxjs";

import { map, catchError } from "rxjs/operators";

import * as fileServer from "file-saver";
import * as moment from "moment";
import { JsonEvent, Event } from "src/app/resource/offerProgram.resource";

@Injectable()
export class OfferService extends AbstractService {
  private route = "offer";
  private routeDigitalBudgetNames = "budget_name";
  private routePurchasedExclusivePeriod = "is_exclusive_period_purchased";
  private exclusiveOfferPdfRoute = "exclusive_offer_pdf";
  private checkExclusiveOfferPdfRoute = "check_exclusive_offer_pdf";
  private updateOfferEventRoute: string = "update_offer_events";
  private offerEvtRoute: string = "get_offer_events";

  private headers: HttpHeaders;
  public offerEvent: Event[] = [];

  private lastDateOfferExclusive = new Subject<string>();
  public lastDateOfferExclusive$ = this.lastDateOfferExclusive.asObservable();

  private getOfferId = new Subject<{}>();
  public getOfferId$ = this.getOfferId.asObservable();

  constructor(
    private http: HttpClient,
    private utilitiesHandler: UtilitiesHandler
  ) {
    super();
    this.headers = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    });
    this.headers.append("Accept", "application/x-www-form-urlencoded");
    this.headers.append("Content-Type", "application/x-www-form-urlencoded");
  }

  /**
   * Get the list of Offer (Result search offer)
   *
   * @param {*} filters
   * @param {string} [groups=null]
   * @returns {Observable<Offer[]>}
   * @memberof OfferService
   */
  public getList(filters: any, groups: string = null): Observable<Offer[]> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    if (groups) {
      filters.groups = groups;
    }

    if (filters && filters.name) {
      filters.name = filters.name.toUpperCase();
    }

    return this.http
      .get(api_base_url, { params })
      .pipe(
        map((data: any) =>
          data._embedded.offer.map((jsonOffer: any) => new Offer(jsonOffer))
        )
      )
      .pipe(catchError(this.utilitiesHandler.handleErrorApi));
  }

  /**
   * Get the list of Offer for filter (search)
   *
   * @param {Object} [filters=null]
   * @returns {Observable<FilteredItem[]>}
   * @memberof OfferService
   */
  public getListForFilter(filters: Object = null): Observable<FilteredItem[]> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, { params })
      .pipe(
        map((data: any) =>
          data._embedded.offer
            .map((jsonOffer: any) => new Offer(jsonOffer))
            .map((offer: Offer) => new FilteredItem(offer))
        )
      )
      .pipe(catchError(this.utilitiesHandler.handleErrorApi));
  }

  /**
   * Get purchased with id exclusive period
   * @param id
   */
  public getPurchasedForExclusivePeriod(
    id: string | number
  ): Observable<boolean> {
    const api_base_url: string = `${environment.api_base_url}/${this.routePurchasedExclusivePeriod}/${id}`;

    return this.http
      .get(api_base_url)
      .pipe(map((isPurchased: number) => !!isPurchased))
      .pipe(catchError(this.utilitiesHandler.handleErrorApi));
  }

  /**
   * Get the offer with ID
   *
   * @param {(string | number)} id
   * @returns {Observable<Offer>}
   * @memberof OfferService
   */
  public get(id: string | number): Observable<Offer> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}/${id}`;

    return this.http
      .get(api_base_url)
      .pipe(map((data: any) => new Offer(data)))
      .pipe(catchError(this.utilitiesHandler.handleErrorApi));
  }

  /**
   * Post Offer (creation)
   *
   * @param {Offer} offer
   * @returns {Observable<Object>}
   * @memberof OfferService
   */
  public createOffer(offer: Offer): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;

    return this.http
      .post(api_base_url, this.convertToUnderscore(offer))
      .pipe(catchError(this.utilitiesHandler.handleErrorApi));
  }

  sendOfferId(offerId): void {
    this.getOfferId.next(offerId);
  }

  /**
   * Put offer for edition
   *
   * @param {*} offer
   * @returns {Observable<Object>}
   * @memberof OfferService
   */
  public editOffer(offer: any): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}/${offer.id}`;

    return this.http
      .put(api_base_url, this.convertToUnderscore(offer))
      .pipe(map((updatedOffer: any) => new Offer(updatedOffer)))
      .pipe(catchError(this.utilitiesHandler.handleErrorApi));
  }

  /**
   * Get list of digital budget names
   */
  getDigitalBudgetNameList(): Observable<Object> {
    const api_base_url = `${environment.api_base_url}/${this.routeDigitalBudgetNames}`;

    return this.http
      .get(api_base_url)
      .pipe(
        map((data: any) =>
          data._embedded.budget_name.map(
            (jsonDigitalBudget: JsonDigitalBudgetName) =>
              new DigitalBudgetName(jsonDigitalBudget)
          )
        )
      )
      .pipe(catchError(this.utilitiesHandler.handleErrorApi));
  }

  /**
   * Build new Object
   *
   * @param {Offer} offer
   * @returns {Object}
   * @memberof OfferService
   */
  public extract(offer: Offer): Object {
    return {
      id: offer.id,
      name: offer.name,
      is_exclusive: offer.isExclusive,
      global_price: offer.globalPrice,
      max_number_of_advertiser: offer.maxNumberOfAdvertiser,
      marketing_base_number: offer.marketingBaseNumber,
      marketing_base_type: offer.marketingBaseType,
      period_type: offer.periodType,
      budget_type: offer.budgetType,
      optionnable: offer.optionnable,
      tv_budget: offer.tvBudget,
      tv_classic_budget: offer.tvClassicBudget,
      tv_thema_budget: offer.tvThemaBudget,
      digital_budget: offer.digitalBudget,
      digital_display_budget: offer.digitalDisplayBudget,
      digital_replay_budget: offer.digitalReplayBudget,
      dig_vid_bill_budget: offer.digVidBillBudget,
      dig_vid_pre_budget: offer.digVidPreBudget,
      digital_social_budget: offer.digitalSocialBudget,
      social_fb_budget: offer.socialFbBudget,
      social_twit_budget: offer.socialTwitBudget,
      social_insta_budget: offer.socialInstaBudget,
      digital_other_budget: offer.digitalOtherBudget,
      floor_price: offer.floorPrice,
      period_start_date: moment(offer.periodStartDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      ),
      period_end_date: moment(offer.periodEndDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      ),
      created: offer.created,
      updated: offer.updated,
      upduid: offer.upduid,
      target: offer.target
        ? {
            id: offer.target.id,
          }
        : null,
      grp: offer.grp,
      cgrp: offer.cgrp,
      average_duration_rate: offer.averageDurationRate,
    };
  }

  /**
   * Check if current exclusive offer, with given offerId, has a linked pdf file saved in database
   *
   * @param {number} offerId
   * @memberof OfferService
   */
  checkExclusiveOfferPdfPresence(offerId: number) {
    const api_base_url: string = `${environment.api_base_url}/${this.checkExclusiveOfferPdfRoute}/${offerId}`;

    return this.http
      .get(api_base_url)
      .pipe(map((fileName: string) => fileName))
      .pipe(catchError(this.utilitiesHandler.handleErrorApi));
  }

  /**
   * Post exclusive offer pdf
   *
   * @param {File} fileToUpload
   * @param {number} offerId
   * @memberof OfferService
   */
  postExclusiveOfferPdf(fileToUpload: File, offerId: number): Observable<any> {
    const api_base_url: string = `${environment.api_base_url}/${this.exclusiveOfferPdfRoute}/${offerId}`;
    const formData: FormData = new FormData();

    // Add file content
    formData.append("file", fileToUpload);

    return this.http
      .post(api_base_url, formData)
      .pipe(catchError(this.utilitiesHandler.handleErrorApi));
  }

  /**
   * Get exclusive offer pdf
   *
   * @param {number} offerId
   * @param {string} fileName
   * @memberof OfferService
   */
  getExclusiveOfferPdf(offerId: number, fileName?: string): Observable<void> {
    const api_base_url: string = `${environment.api_base_url}/${this.exclusiveOfferPdfRoute}/${offerId}`;
    this.headers.set("Accept", "application/pdf");

    return this.http
      .get(api_base_url, { headers: this.headers, responseType: "blob" })
      .pipe(
        map((response: any) => {
          const blob = new Blob([response], { type: "application/pdf" });
          fileServer.saveAs(blob, fileName);
        })
      )
      .pipe(catchError(this.utilitiesHandler.handleErrorApi));
  }

  /**
   * Delete exclusive offer pdf
   *
   * @param {number} offerId
   * @memberof OfferService
   */
  deleteExclusiveOfferPdf(offerId: number) {
    const api_base_url = `${environment.api_base_url}/${this.exclusiveOfferPdfRoute}/${offerId}`;

    return this.http
      .delete(api_base_url)
      .pipe(catchError(this.utilitiesHandler.handleErrorApi));
  }

  /**
   * Update lastDate of oe
   * @param lastDate
   */
  updateLastDate(lastDate: string): void {
    this.lastDateOfferExclusive.next(lastDate);
  }

  public getOfferEvents(filters: Object = null): Observable<Object> {
    const apiBaseUrl = `${environment.api_base_url}/${this.offerEvtRoute}`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(apiBaseUrl, { params })
      .pipe(
        map((response: []) => {
          return (this.offerEvent = response.map(
            (event: JsonEvent) => new Event(event)
          ));
        })
      )
      .pipe(catchError(this.utilitiesHandler.handleErrorApi));
  }

  public updateOfferEvents(selectedEventList): Observable<any> {
    const api_base_url: string = `${environment.api_base_url}/${this.updateOfferEventRoute}`;

    return this.http
      .post(api_base_url, this.convertToUnderscore(selectedEventList))
      .pipe(catchError(this.utilitiesHandler.handleErrorApi));
  }
}
