import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';
import { Channel } from 'src/app/resource/channel.resource';

import { merge, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-so-reach-period-budget',
  templateUrl: './so-reach-period-budget.component.html',
  styleUrls: ['./so-reach-period-budget.component.scss']
})
export class SoReachPeriodBudgetComponent implements OnInit, OnDestroy, OnChanges {
  @Input() soReachForm: FormControl;
  @Input() selectedPeriodIndex: number;

  public  isLoading = false;
  public year = '';
  public path: string = Channel.PATH;
  private subscription: Subscription;
  private componentDestroyed$: Subject<any> = new Subject();

  /**
   * Return selected period budget formArray
   */
  get selectedFormPeriod(): any {
    return this.soReachForm.get('periodsBudgets')['controls'][this.selectedPeriodIndex];
  }

  constructor(
    private customToastrService: CustomToastrService,
  ) { }

  ngOnInit() {
    // Default
    this.year = this.soReachForm.get('year').value;
    // Update year if change
    this.soReachForm.get('year').valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((year: string) => {
        this.year = year;
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Erreur lors du traitement de l\'année.');
      })
      this.computeBudgetChange();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.unsubscribe();
  }

  ngOnChanges(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.computeBudgetChange();
    }
  }

  /**
   * Compute all lines of channel budget to set value in total line
   */
  computeTotalBudget(): void {
    this.selectedFormPeriod.get('budgetTotal').setValue(
        Number(this.selectedFormPeriod.get('f2Budget').value) +
        Number(this.selectedFormPeriod.get('f3Budget').value) +
        Number(this.selectedFormPeriod.get('f4Budget').value) +
        Number(this.selectedFormPeriod.get('f5Budget').value) +
        Number(this.selectedFormPeriod.get('foBudget').value)
    );
  }

  /**
   * Compute budget when value change
   */
  computeBudgetChange(): void {
    if (this.selectedFormPeriod) {
      this.subscription = merge(
        this.selectedFormPeriod.get('f2Budget').valueChanges,
        this.selectedFormPeriod.get('f3Budget').valueChanges,
        this.selectedFormPeriod.get('f4Budget').valueChanges,
        this.selectedFormPeriod.get('f5Budget').valueChanges,
        this.selectedFormPeriod.get('foBudget').valueChanges
      )
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe(() => {
      this.computeTotalBudget();
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });
    }
  }
}
