import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';

import { CustomToastrService } from '@service/toastr/custom-toastr.service';
import { AuthService } from '@service/auth.service';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private injector: Injector,
              private authService: AuthService,
              private customToastrService: CustomToastrService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        catchError((err) => {
          switch (err.status) {
            case 400: {
              if (err && err.error && err.error.error === 'invalid_token') {
                this.invalidToken();
              }
              break;
            }
            case 422: {
              const errorMessageProgramStatusDeactivated: string = 'Impossible d\'ajouter des diffusions, l\'émission est désactivée';
              const errorMessageMonitoringLotAlreadyInvoiced: string =
                'Lot Dotation: Some monitoring lots are already invoiced, can\'t modify unit price';
              const erroMessagePeriodsOverlaps: string = 'Monitoring Lot: Periods overlap';
              const errorMessageMonitoringAlreadyRelatedToTheLot: string = 'Lot Dotation: Some monitoring lots are already related, can\'t modify purchase file';
              const errorMessageMissingMonitoringPeriodInNewLotPeriod: string = 'Lot Dotation: Some monitoring lots period aren\'t into the new lot period'

              if (err && err.error && err.error.detail === errorMessageProgramStatusDeactivated) {
                this.customToastrService.displayToastr('WARNING', errorMessageProgramStatusDeactivated);
              }
              if (err && err.error && err.error.detail === errorMessageMonitoringLotAlreadyInvoiced) {
                this.customToastrService.displayToastr(
                  'ERROR', 'Le prix unitaire ne peut être modifié car des lots ont déjà été facturés');
              }
              if (err && err.error && err.error.detail === erroMessagePeriodsOverlaps) {
                this.customToastrService.displayToastr(
                  'WARNING', 'Une ou plusieurs périodes saisies se chevauchent'
                );
              }
              if (err && err.error && err.error.detail === errorMessageMonitoringAlreadyRelatedToTheLot) {
                this.customToastrService.displayToastr(
                  'ERROR', 'Le dossier ne peut être modifié car des suivis ont déjà été renseignés sur ce lot');
              }
              if (err && err.error && err.error.detail === errorMessageMissingMonitoringPeriodInNewLotPeriod) {
                this.customToastrService.displayToastr(
                  'ERROR', 'Un lot a déjà été gagné en dehors de cette période');
              }
              break;
            }
            case 404: {
              const errorMessageNoBroadcastsInPeriod: string = 'Monitoring Lot: Some periods have no broadcast'
              if (err && err.error && err.error.detail === errorMessageNoBroadcastsInPeriod) {
                this.customToastrService.displayToastr(
                  'ERROR', 'Il n\'y a pas de diffusions achetées sur une ou plusieurs des périodes saisies'
                );
              }
              break;
            }
            default: {
              break;
            }
          }
          const error = err['message'] || err.statusText;
          return throwError(error);
        })
      )
  }

  /**
   * When user have invalid Token, clear storage and redirect
   */
  invalidToken(): void {
    const router: Router = this.injector.get(Router);
    const auth = this.injector.get(AuthService);

    try {
      localStorage.removeItem('access_token');
      localStorage.removeItem('email');
      localStorage.removeItem('trigramme');
      localStorage.removeItem('parameter');
      this.customToastrService.displayToastr('WARNING', 'Votre session a expiré, veuillez vous reconnecter.');
      auth.sendLoggedStatus(false);
      router.navigate(['/']);
    } catch (err) {
      this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
    }
  }
}
