<div fxLayout="row" fxLayoutAlign="space-between center">
  <h1 class="pull-left h1-dispo">Les disponibilités</h1>
  <div fxLayout="row">
    <mat-button-toggle-group [formControl]="mode">
      <mat-button-toggle value="program" (click)="changeGroupeToogle()">Emission</mat-button-toggle>
      <mat-button-toggle value="offer" (click)="changeGroupeToogle()">Offre</mat-button-toggle>
      <mat-button-toggle value="region">Régions</mat-button-toggle>
      <mat-button-toggle value="synthesis">Synthèse</mat-button-toggle>
      <mat-button-toggle value="soreach">SoReach</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</div>

<app-program-filter
  [filters]="filters"
  [DateFrom]="DateFrom"
  [DateTo]="DateTo"
  [target]="target"
  [loading]="loading"
  [groupeStatus]="mode.value === 'offer'"
  (doSearch)="search()"
  *ngIf="mode.value !== 'synthesis' && mode.value !== 'soreach' && mode.value !== 'region'"
></app-program-filter>

<div class="clearfix">
  <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
</div>

<div *ngIf="mode.value === 'synthesis'" class="full-height-synthesis">
  <app-synthesis [filters]="filters" (decreaseY)="decreaseYear()" (increaseY)="increaseYear()"></app-synthesis>
</div>

<div *ngIf="mode.value === 'soreach'" class="full-height-calc">
  <app-soreach-dispo [filters]="filters" [purchaseParams]="purchaseParams"></app-soreach-dispo>
</div>

<div *ngIf="mode.value === 'region'" class="full-height-calc">
  <app-region-dispo></app-region-dispo>
</div>

<div *ngIf="mode.value !== 'synthesis' && mode.value !== 'soreach' && mode.value !== 'region'" class="margin-bottom-20">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutGap="20px" class="result-height" fxLayoutAlign="start center">
      <div class="thin">
        Résultats des filtres
      </div>
      <div class="title-find" fxLayoutAlign="start center" *ngIf="offerPrograms.length > 1 && mode.value === 'program'">
        <span>{{ offerPrograms.length }} émissions trouvées</span>
      </div>
      <div class="title-find" fxLayoutAlign="start center"
           *ngIf="offerPrograms.length <= 1 && mode.value === 'program'">
        <span>{{ offerPrograms.length }} émission trouvée</span>
      </div>
      <div class="title-find" fxLayoutAlign="start center" *ngIf="offerPrograms.length > 1 && mode.value === 'offer'">
        <span>{{ offerPrograms.length }} offres trouvées</span>
      </div>
      <div class="title-find" fxLayoutAlign="start center" *ngIf="offerPrograms.length <= 1 && mode.value === 'offer'">
        <span>{{ offerPrograms.length }} offre trouvée</span>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="end center">
      <div *ngIf="mode.value === 'offer'" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="10px">
        <mat-icon class="hold-color">track_changes</mat-icon>
        <span class="hold-color thin">GRP cible :</span>
        <mat-form-field>
          <input appRemoveAccents type="text" placeholder="Cible GRP" matInput [formControl]="target"
                 [matAutocomplete]="auto" #targetName>
          <mat-icon matSuffix class="search-icon">search</mat-icon>
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="formatTarget(this)">
            <mat-option *ngFor="let targetItem of filterTargetOptions | async" [value]="targetItem.value">
              {{ targetItem.display }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div *ngIf="filters.displayMode == 'year'">
        <button mat-icon-button [disabled]="loading" (click)="decreaseYear()">
          <mat-icon class="chevron">chevron_left</mat-icon>
        </button>
        <span class="switch-year hold-color">{{ filters.year }}</span>
        <button mat-icon-button [disabled]="loading" (click)="increaseYear()">
          <mat-icon class="chevron">chevron_right</mat-icon>
        </button>
      </div>
      <div class="layout-flex" *ngIf="filters.displayMode == 'month'">
        <button mat-button [disabled]="loading" (click)="decreaseMonth()" raised>
          <mat-icon class="chevron">chevron_left</mat-icon>
        </button>
        <span
          class="switch-month layout-flex align-date">{{ dateService.getMonthLabel(filters.month) }} {{ filters.year }}</span>
        <button mat-button [disabled]="loading" (click)="increaseMonth()" raised>
          <mat-icon class="chevron">chevron_right</mat-icon>
        </button>
      </div>
      <mat-form-field>
        <mat-select class="size-select" [disabled]="loading" [(value)]="selectedPeriod"
                    (selectionChange)="updateDisplayMode($event)">
          <mat-option [disabled]="loading" value="0" class="hold-color">Mois</mat-option>
          <mat-option [disabled]="loading" value="1" class="hold-color">Année</mat-option>
        </mat-select>
      </mat-form-field>
      <div fxLayout="row" class="legend" (click)="legend.toggleLegend()">
        <span class="hold-color">Légende</span>
        <mat-icon class="hold-color" fxFlexOffset="10px">launch</mat-icon>
      </div>
    </div>
  </div>

  <app-audience-indicators-cart [filters]="filters" [target]="target" [propalCart]="propalCart"
                                *ngIf="mode.value === 'offer'"></app-audience-indicators-cart>

  <div class="separator-dispo"></div>

  <div class="clearfix" *ngFor="let offerProgram of offerPrograms; index as i; last as isLast">
    <app-offer-program-grid
      *ngIf="mode.value === 'program'"
      [offerProgram]="offerProgram"
      [queryParams]="queryParams"
      [propalCart]="propalCart"
      [lastProgram]="isLast"
      [filters]="filters">
    </app-offer-program-grid>
    <div *ngIf="(mode.value === 'offer') && (offerProgram.program.length || offerProgram.program.length === 0)"
         class="margin-bottom-10">
      <mat-accordion *ngIf="offerProgram.program">
        <mat-expansion-panel [disabled]="true" [hideToggle]="true" [expanded]="indexOpened[i]">
          <mat-expansion-panel-header (mouseleave)="leaveGridInformation()">
            <app-grid-offer-group
              class="box-grid"
              [offerProgram]="offerProgram"
              [filters]="filters"
              [expanded]="indexOpened[i]"
              [target]="target"
              [lastOffer]="isLast"
              (expandPanel)="openOfferPanel($event, i)">
            </app-grid-offer-group>
          </mat-expansion-panel-header>

          <div class="clearfix" *ngFor="let offer of offerProgram.program; last as isLastProgram">
            <app-offer-program-grid
              [offerProgram]="offer"
              [queryParams]="queryParams"
              [propalCart]="propalCart"
              [lastProgram]="isLastProgram && isLast"
              [filters]="filters"
              (onSendBroadcast)="pushBroadcastAll($event, offer)">
            </app-offer-program-grid>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>

<app-legend
  #legend
  [legendToogleStatus]="legendToogleStatus"
  (legendToogleStatusEmitter)="legendToogleStatus=$event">
</app-legend>

<app-propal-cart
  *ngIf="mode.value === 'program' || mode.value === 'offer' || mode.value === 'region'"
  [availabilityMode]="mode"
  [propalCart]="propalCart"
  [purchaseParams]="purchaseParams">
</app-propal-cart>

<app-soreach-card
  *ngIf="mode.value === 'soreach'"
  [purchaseParams]="purchaseParams">
</app-soreach-card>
