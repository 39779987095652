import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { BroadcastService } from '../broadcast/broadcast.service';
import { ScheduleTime } from '../../resource/schedule/schedule-time.resource';
import { ScheduleTimeService } from '../schedule-time/schedule-time.service';
import { SchedulePeriod } from '../../resource/schedule/schedule-period.resource';
import { Schedule} from '../../resource/schedule/schedule.resource';
import { SchedulePeriodService } from '../schedule-period/schedule-period.service';
import { AbstractService } from '../abstract.service';
import { environment } from '../../../environments/environment';
import { ScheduleExceptionService } from '../schedule-exception/schedule-exception.service';
import { UtilitiesHandler } from '@service/utilities-handlers/utilitiesHandlers';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';

import { Observable } from 'rxjs';

import * as moment from 'moment';
import 'moment/locale/fr';

@Injectable()
export class ScheduleService extends AbstractService {
  private route: string = 'schedule';

  private daysLabels: Array<string> = [
      'Dimanche',
      'Lundi',
      'Mardi',
      'Mercredi',
      'Jeudi',
      'Vendredi',
      'Samedi',
  ];

  private everyDaysLabel: string = 'Tous les jours';
  public dialogRef;

  constructor(
      private schedulePeriodService: SchedulePeriodService,
      private scheduleTimeService: ScheduleTimeService,
      private broadcastService: BroadcastService,
      private scheduleExceptionService: ScheduleExceptionService,
      private customToastrService: CustomToastrService,
      private http: HttpClient,
      private utilitiesHandler: UtilitiesHandler
    ) {
      super();
    }

  /**
   * Get schedule with ID
   *
   * @param {(string | number)} id
   * @returns {Observable<Schedule>}
   * @memberof ScheduleService
   */
  public get(id: string | number): Observable<Schedule> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}/${id}`;

    return this.http
      .get(api_base_url).pipe(
      map((data: any) =>
        new Schedule(data)
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get list of schedules
   *
   * @param {Object} filters
   * @returns {Observable<Schedule[]>}
   * @memberof ScheduleService
   */
  public getList(filters: Object): Observable<Schedule[]> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params})
      .pipe(
        map((data: any) =>
          data._embedded.schedule
            .map((jsonSchedule: any) =>
              new Schedule(jsonSchedule)
            )
        )
      )
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Edit schedule
   *
   * @param {Schedule} schedule
   * @returns {Observable<Object>}
   * @memberof ScheduleService
   */
  public edit(schedule: Schedule): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}/${schedule.id}`;

    return this.http
      .put(api_base_url, this.extract(schedule))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Edit schedule
   *
   * @param id {number}
   * @param {Schedule} schedule
   * @returns {Observable<Object>}
   * @memberof ScheduleService
   */
  public patchSchedule(id: number, schedule): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}/${id}`;

    return this.http
      .patch(api_base_url, schedule)
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Post schedule
   *
   * @param {Schedule} schedule
   * @returns {Observable<Object>}
   * @memberof ScheduleService
   */
  public post(schedule: Schedule): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;

    return this.http
      .post(api_base_url, this.extract(schedule))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Delete schedule
   *
   * @param {Schedule} schedule
   * @returns {Observable<Object>}
   * @memberof ScheduleService
   */
  public delete(schedule: Schedule): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}/${schedule.id}`;

    return this.http
      .delete(api_base_url)
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Check action count
   *
   * @param {*} totalActions
   * @param {*} actionCount
   * @returns {boolean}
   * @memberof ScheduleService
   */
  public checkActionCount(totalActions, actionCount): boolean {
    return totalActions === actionCount;
  }

  /**
   * Create schedule
   *
   * @param {Schedule} schedule
   * @param {*} broadcasts
   * @param {*} offerProgram
   * @returns {Observable<any>}
   * @memberof ScheduleService
   */
  public createSchedule(schedule: Schedule, broadcasts, offerProgram): Observable<any> {
    return Observable.create(observer => {

      this.post(schedule)
        .subscribe(result => {
          schedule.id = result['id'];

          let totalActions = 4;
          let actionCount = 0;
          this.schedulePeriodService.managePeriods(schedule)
            .subscribe(() => {
              if (this.checkActionCount(totalActions, ++actionCount)) {
                observer.next();
              }
            }, () => {
              this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            });

          this.scheduleTimeService.manageTimes(schedule)
            .subscribe(() => {
              if (this.checkActionCount(totalActions, ++actionCount)) {
                observer.next();
              }
            }, () => {
              this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            });

          this.broadcastService.manageBroadcasts(broadcasts, schedule, offerProgram)
            .subscribe(() => {
              if (this.checkActionCount(totalActions, ++actionCount)) {
                observer.next();
              }
            }, () => {
              this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            });

          if (schedule.scheduleExceptions.length > 0) {
            this.scheduleExceptionService.manageExceptions(schedule)
              .subscribe(() => {
                if (this.checkActionCount(totalActions, ++actionCount)) {
                  observer.next();
                }
              }, () => {
                this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
              });
          } else {
            ++actionCount;
          }
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });
    });
  }

  /**
   * Edit schedule
   *
   * @param {Schedule} schedule
   * @param {*} oldSchedule
   * @param {*} broadcasts
   * @param {*} offerProgram
   * @returns {Observable<any>}
   * @memberof ScheduleService
   */
  public editSchedule(schedule: Schedule, oldSchedule, broadcasts, offerProgram): Observable<any> {
    return Observable.create(observer => {
        let totalActions = 5;
        let actionCount = 0;

        this.edit(schedule)
          .subscribe(() => {
            if (this.checkActionCount(totalActions, ++actionCount)) {
              observer.next();
            }
          }, () => {
            this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
          });

        this.schedulePeriodService.managePeriods(schedule, oldSchedule)
          .subscribe(() => {
            if (this.checkActionCount(totalActions, ++actionCount)) {
              observer.next();
            }
          }, () => {
            this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
          });

        this.scheduleTimeService.manageTimes(schedule, oldSchedule)
          .subscribe(() => {
            if (this.checkActionCount(totalActions, ++actionCount)) {
              observer.next();
            }
          }, () => {
            this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
          });

        this.broadcastService.manageBroadcasts(broadcasts, schedule, offerProgram)
          .subscribe(() => {
            if (this.checkActionCount(totalActions, ++actionCount)) {
              observer.next();
            }
          }, () => {
            this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
          });

        if ((schedule.scheduleExceptions.length > 0) || (oldSchedule.scheduleExceptions.length !== schedule.scheduleExceptions.length)) {
          this.scheduleExceptionService.manageExceptions(schedule, oldSchedule)
            .subscribe(() => {
              if (this.checkActionCount(totalActions, ++actionCount)) {
                observer.next();
              }
            }, () => {
              this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            });
        } else {
          ++actionCount;
        }
    });
  }

  /**
   * Generate broadcast summary
   *
   * @param {Schedule} schedule
   * @returns {string}
   * @memberof ScheduleService
   */
  public generateBroadcastSummary(schedule: Schedule): string {
    let days: Array<number> = schedule.getDaysAsArray();
    let times: Array<any> = schedule.times;
    let summary = {
        days: '',
        times: ''
    };

    if (days.length === this.daysLabels.length) {
        summary.days = this.everyDaysLabel;
    } else {
        summary.days = days.map(day => {
            return this.daysLabels[day];
        }).join(', ');
    }

    summary.times = times.map((time: ScheduleTime) => {
        return time.startTime.replace(':', 'h') + ' à ' + time.endTime.replace(':', 'h')
    })
    .join(' + de ');

    return summary.days + ' de ' + summary.times;
  }

  /**
   * Generate perdios summary
   *
   * @param {Schedule} schedule
   * @returns {string}
   * @memberof ScheduleService
   */
  public generatePerdiodsSummary(schedule: Schedule): string {
    let periods: Array<any> = schedule.periods;

    return periods.map((period: SchedulePeriod) => {
        let startTime = moment(period.startTime, 'YYYY-MM-DD').format('DD/MM/YYYY');
        let endTime = moment(period.endTime, 'YYYY-MM-DD').format('DD/MM/YYYY');

        return 'Du ' + startTime + ' au ' + endTime;
    }).join(' et ');
  }

  /**
   * Generate exeption sumary
   *
   * @param {Schedule} schedule
   * @returns {*}
   * @memberof ScheduleService
   */
  public generateExeptionSumary(schedule: Schedule): any {
      let resume = [];

      if (!schedule.hasCompleteExceptData()) {
        return resume;
      }

      schedule.scheduleExceptions.forEach( scheduleException => {
        resume.push('Du ' + moment(scheduleException.exceptStartPeriod).format('DD/MM/YYYY') +
          ' Au ' + moment(scheduleException.exceptEndPeriod).format('DD/MM/YYYY') +
          ' de ' + scheduleException.exceptStartTime.replace(':', 'h') +
          ' à ' + scheduleException.exceptEndTime.replace(':', 'h'));
      });

      return resume;
  }

  /**
   * Extract
   *
   * @param {Schedule} schedule
   * @returns {Object}
   * @memberof ScheduleService
   */
  public extract(schedule: Schedule): Object {
    return {
      id: schedule.id,
      comments: schedule.comments,
      days: schedule.getDaysAsString(),
      offer_program: schedule.offerProgram ? schedule.offerProgram.id : null,
      except_start_period: schedule.exceptStartPeriod ? moment(schedule.exceptStartPeriod).format('YYYY-MM-DD') : null,
      except_end_period: schedule.exceptEndPeriod ? moment(schedule.exceptEndPeriod).format('YYYY-MM-DD') : null,
      except_start_time: schedule.exceptStartTime,
      except_end_time: schedule.exceptEndTime,
      broadcast_count: schedule.broadcastCount,
      purchase: schedule.purchase ? schedule.purchase.id : null,
      parent : schedule.parent ? schedule.parent.id : null,
      purchase_program: schedule.purchaseProgram ? schedule.purchaseProgram.id : null
    };
  }
}
