
import {take, refCount, publishReplay, map,  catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Offer } from '../../resource/offer.resource';
import { OfferPush } from '../../resource/offer-push.resource';
import { DurationRate } from '../../resource/duration-rate.resource';
import { OfferProgram } from '../../resource/offerProgram.resource';
import { OfferProgramBudgetPackage } from '../../resource/offerProgramBudgetPackage.resource';
import { OfferProgramBudgetPackageService } from '../offer-program-budget-package/offer-program-budget-package.service';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';
import { UtilitiesHandler } from '@service/utilities-handlers/utilitiesHandlers';
import { environment } from '../../../environments/environment';

import { Observable, Subject } from 'rxjs';

import * as moment from 'moment';

@Injectable()
export class DurationRateService {
  private route: string = 'duration_rate';

  private averageDurationRateSource = new Subject<number>();
  private loadingStatusSource = new Subject<boolean>();

  public averageDurationFormatRate$ = this.averageDurationRateSource.asObservable();
  public loadingStatus$ = this.loadingStatusSource.asObservable();

  private offerProgramBudgetPackages: OfferProgramBudgetPackage[] = [];
  private offer: Offer;
  private packagesByOfferProgram = <any>{};
  private dataCache: Observable<DurationRate>[] = [];
  private durationRates: any = {};
  private totalBroadcast: number = 0;


  constructor(
    private customToastrService: CustomToastrService,
    private http: HttpClient,
    private utilitiesHandler: UtilitiesHandler,
    private offerProgramBudgetPackageService: OfferProgramBudgetPackageService) { }

  /**
   * Get list of Duration rate
   *
   * @param {object} [filters=null]
   * @returns {Observable<DurationRate[]>}
   * @memberof DurationRateService
   */
  getList(filters: object = null): Observable<DurationRate[]> {
    const query = JSON.stringify(filters);

    if (!this.dataCache[query]) {
      const api_base_url: string = `${environment.api_base_url}/${this.route}`;
      const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

      this.dataCache[query] = this.http
        .get(api_base_url, {params})
        .pipe(
          map((data: any) =>
            data._embedded.duration_rate
              .map((json: any) =>
                new DurationRate(json)
              )
          ),
          publishReplay(1, 4000),
          refCount(),
          take(1)
        )
        .pipe(
          catchError(this.utilitiesHandler.handleErrorApi)
        )
    }

    return this.dataCache[query];
  }

  /**
   * Update average duration rate
   *
   * @param {*} offer
   * @returns {void}
   * @memberof DurationRateService
   */
  public updateAverageDurationRate(offer): void {
    this.getAverageDurationRate(offer)
      .subscribe(averageDurationRate => {
        this.averageDurationRateSource.next(averageDurationRate);
        this.updateLoadingStatus(false);
      }, (error) => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });
  }

  /**
   * Get average duration rate
   *
   * @param {*} offer
   * @returns {Observable<any>}
   * @memberof DurationRateService
   */
  public getAverageDurationRate(offer): Observable<any> {
    this.resetService();
    return new Observable(observer => {
      if (!offer.id || !offer.offerPrograms) {
        observer.next(null);
      } else {
        this.offer = offer;
        this.updateLoadingStatus(true);
        this.getOfferProgramBudgetsPackages()
          .subscribe(() => {
              this.getPackagesByOfferProgram().subscribe(() => {
                  this.getOfferProgramsDurationRate()
                    .subscribe(() => {
                      const averageDurationRate = this.getFinalDurationRate();

                      observer.next(averageDurationRate);
                      this.updateLoadingStatus(false);
                    }, (error) => {
                      this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
                    });
              });
          }, (error) => {
            this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
          });
      }
    })
  }

  /**
   * Get final duration rate
   *
   * @private
   * @returns {number}
   * @memberof DurationRateService
   */
  private getFinalDurationRate(): number {
    if (!Object.keys(this.packagesByOfferProgram).length) {
      return null;
    }

    let finalDurationRate = 0;
    Object.keys(this.durationRates).forEach( offerProgramId => {
        let offerProgramRate = this.durationRates[offerProgramId];
        let broadcastRate = this.packagesByOfferProgram[offerProgramId].totalBroadcast / this.totalBroadcast;
        finalDurationRate = finalDurationRate + (broadcastRate * offerProgramRate);
    });

    return finalDurationRate;
  }

  /**
   * Get offer program budgets packages
   *
   * @private
   * @returns {Observable<any>}
   * @memberof DurationRateService
   */
  private getOfferProgramBudgetsPackages(): Observable<any> {
    return new Observable(observer => {
      let filter = {};
      let offerId = this.offer.id;

      if (this.offer instanceof OfferPush) {
        filter['offer_push_id'] = offerId;
      } else {
        filter['offer_id'] = offerId;
      }

      this.offerProgramBudgetPackageService
        .getList(filter)
        .subscribe(offerProgramBudgetPackages => {
          this.offerProgramBudgetPackages = offerProgramBudgetPackages;
          observer.next();
        });
    });
  }


  /**
   * Get budget packages by offer program
   *
   * @private
   * @param {OfferProgram} offerProgram
   * @returns {OfferProgramBudgetPackage[]}
   * @memberof DurationRateService
   */
  private getBudgetPackagesByofferProgram(offerProgram: OfferProgram): OfferProgramBudgetPackage[] {
    let result = [];
    this.offerProgramBudgetPackages.forEach(item => {
      if (item.offerProgramBudget.offerProgram.id === offerProgram.id.toString()) {
        result.push(item);
      }
    });
    return result;
  }

  /**
   * Get total broadcast by offer program
   *
   * @private
   * @param {OfferProgram} offerProgram
   * @returns {number}
   * @memberof DurationRateService
   */
  private getTotalBroadcastByOfferProgram(offerProgram: OfferProgram): number {
    let totalBroadcast: number = 0;
    this.offerProgramBudgetPackages.forEach(item => {
      if (item.offerProgramBudget.offerProgram.id === offerProgram.id.toString()) {
        totalBroadcast = totalBroadcast + item.quantity;
      }
    });

    return totalBroadcast;
  }

  /**
   * Get packages by offer program
   *
   * @private
   * @returns {Observable<any>}
   * @memberof DurationRateService
   */
  private getPackagesByOfferProgram(): Observable<any> {
    return new Observable(observer => {
      this.offer.offerPrograms.forEach(offerProgram => {
        // Take only national channel (2, 3, 5)
        if (offerProgram.area && offerProgram.area.channel && offerProgram.area.channel.channelGroup === 'F'
        &&  offerProgram.area.id === 'NAT' &&  offerProgram.area.channel.id !== '4') {
          // Bidouille en attendant la modification de France O en 2019 en régie W dans la table chaine
          if ((offerProgram.area.channel.id !== 'O') ||
              (offerProgram.area.channel.id === 'O' && (moment(this.offer.periodEndDate).year() <= 2018))) {
            this.packagesByOfferProgram[offerProgram.id] = {
              totalBroadcast: this.getTotalBroadcastByOfferProgram(offerProgram),
              offerProgramBudgetPackages: this.getBudgetPackagesByofferProgram(offerProgram),
              filter : {
                area_id: offerProgram.area.id,
                channel_id: offerProgram.area.channel.id,
                year: moment(this.offer.periodStartDate, 'YYYY-MM-DD').format('YYYY'),
                duration: null
              }
            }
          };
        }
      });
      observer.next();
    });
  }

  /**
   * Get offer of programs duration rate
   *
   * @private
   * @returns {Observable<any>}
   * @memberof DurationRateService
   */
  private getOfferProgramsDurationRate(): Observable<any> {
    return new Observable(observer => {
      let count = 0;
      let packagesByOfferProgramlength = Object.keys(this.packagesByOfferProgram).length;

      if (packagesByOfferProgramlength === 0) {
        observer.next();
      }
      let totalOfferProgram = packagesByOfferProgramlength;

      Object.keys(this.packagesByOfferProgram).forEach( key => {
          let item = this.packagesByOfferProgram[key];
          this.getOfferProgramDurationRate(item).subscribe(offerProgramDurationRate => {
              if (offerProgramDurationRate) {
                this.durationRates[key] = offerProgramDurationRate;
                this.totalBroadcast = this.totalBroadcast + item.totalBroadcast;
              }

              if (this.checkCallCount(packagesByOfferProgramlength, ++count)) {
                return observer.next();
              }
          });
      });
    });
  }

  /**
   * Get offer of program duration rate
   *
   * @private
   * @param {*} item
   * @returns {Observable<any>}
   * @memberof DurationRateService
   */
  private getOfferProgramDurationRate(item): Observable<any> {
    return new Observable(observer => {
      let count = 0;
      let offerProgramDurationRate = 0;
      let offerProgramBudgetPackages = item.offerProgramBudgetPackages;
      let filter = item.filter;
      let totalBroadcast = item.totalBroadcast;

      if (!offerProgramBudgetPackages.length) {
        observer.next(null);
      }

      offerProgramBudgetPackages.forEach((offerProgramBudgetPackage: OfferProgramBudgetPackage) => {
        filter.duration = offerProgramBudgetPackage.duration;

        let broadcastRate = offerProgramBudgetPackage.quantity / totalBroadcast;

        this.getDurationFormatRate(filter).subscribe(rate => {
          offerProgramDurationRate = offerProgramDurationRate + (broadcastRate * rate);
          if (this.checkCallCount(offerProgramBudgetPackages.length, ++count)) {
            observer.next(offerProgramDurationRate);
          }
        });
      })
    });
  }

  /**
   * Get duration of format rate
   *
   * @private
   * @param {*} filter
   * @returns {Observable<any>}
   * @memberof DurationRateService
   */
  private getDurationFormatRate(filter): Observable<any> {
    return new Observable(observer => {
      if (filter.duration === 0) {
        observer.next(0);
        return null;
      }
      this.getList(filter).subscribe(result => {
        if (result && result.length > 0) {
          let durationRate = new DurationRate(result[0]);
          observer.next(durationRate.rate / 100);
        } else {
          observer.next(0);
        }
      })
    });
  }

  /**
   * Check equality total and count
   *
   * @private
   * @param {*} total
   * @param {*} count
   * @returns {boolean}
   * @memberof DurationRateService
   */
  private checkCallCount(total, count): boolean {
    return total === count;
  }

  /**
   * Reset service
   *
   * @return {void}
   * @private
   * @memberof DurationRateService
   */
  private resetService(): void {
    this.offerProgramBudgetPackages = [];
    this.packagesByOfferProgram = <any>{};
    this.durationRates = <any>{};
    this.totalBroadcast = 0;
  }

  /**
   * Updante and loding status (Emiteur)
   *
   * @private
   * @param {*} status
   * @return {void}
   * @memberof DurationRateService
   */
  private updateLoadingStatus(status): void {
    this.loadingStatusSource.next(status);
  }
}
