import { BroadcastPurchasedService } from '../../../../../../service/broadcast-purchased/broadcast-purchased.service';
import { Component, OnInit, Output, Input, OnChanges, EventEmitter } from '@angular/core';
import { Purchase } from '../../../../../../resource/purchase.resource';
import { PurchaseProgram } from '../../../../../../resource/purchaseProgram.resource';
import {PurchaseService} from '@service/purchase/purchase.service';

@Component({
  selector: 'app-broadcast',
  templateUrl: './broadcast.component.html',
  styleUrls: ['./broadcast.component.scss']
})
export class BroadcastComponent implements OnInit, OnChanges {
  @Output() refreshEmitter = new EventEmitter<boolean>();

  @Input() purchaseProgram: PurchaseProgram;
  @Input() purchase: Purchase;

  public loading: boolean;

  constructor(
    private broadcastPurchasedService: BroadcastPurchasedService,
    private purchaseService: PurchaseService) {}

  ngOnInit() {
    this.loading = true;
    this.broadcastPurchasedService.loadingSource$.subscribe(
        loading => {
          this.loading = loading;
          this.purchaseService.savePurchaseForCompare('diffusion');
        }
    );
  }

  ngOnChanges(changes: any): void {
    if (changes.purchaseProgram) {
        this.loading = true;
    }
    this.refreshBrutEmitter(true);
  }

  refreshBrutEmitter($event: boolean): void {
    this.refreshEmitter.emit($event);
  }
}
