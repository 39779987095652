import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'broadcastTimeLabel',
  pure: false,
})
export class BroadcastTimeLabelPipe implements PipeTransform {

  /**
   * Transform value to format time hour
   *
   * @param {*} value
   * @returns {string}
   * @memberof BroadcastTimeLabelPipe
   */
  transform(value): string {
    const hour = value.substr(0, 2);
    const minute = value.substr(2, 2);

    return hour + ':' + minute;
  }
}
