
import {map, tap,  catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { FilteredItem } from '../../resource/filteredItem.resource';
import { PurchaseProgram } from '../../resource/purchaseProgram.resource';
import { environment } from '../../../environments/environment';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';
import { UtilitiesHandler } from '@service/utilities-handlers/utilitiesHandlers';

import { Observable } from 'rxjs';

@Injectable()
export class PurchaseProgramService {
  private route: string = 'purchase_program';
  private headers: HttpHeaders;
  private counts = [];
  private pageCount = [];
  private pageNumber = [];
  private purchasePrograms: PurchaseProgram[];
  public purchaseProgram: PurchaseProgram[];

  constructor(
    private customToastrService: CustomToastrService,
    private utilitiesHandler: UtilitiesHandler,
    private http: HttpClient
    ) { }

  /**
   * Get purchase program with ID
   *
   * @param {(string | number)} id
   * @returns {Observable<PurchaseProgram>}
   * @memberof PurchaseProgramService
   */
  get(id: string | number): Observable<PurchaseProgram> {
    const api_base_url = `${environment.api_base_url}/${this.route}/${id}`;

    return this.http
      .get(api_base_url).pipe(
      map((data: any) =>
        new PurchaseProgram(data)
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get purchase program sum
   *
   * @param {(string | number)} id
   * @returns {Observable<any>}
   * @memberof PurchaseProgramService
   */
  getPurchaseProgramSum(id: string | number): Observable<any> {
    const api_base_url = `${environment.api_base_url}/purchase_program_sum/${id}`;

    return this.http
      .get(api_base_url, { headers: this.headers }).pipe(
      map((data) =>
        data
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get complete List
   *
   * @param {*} filters
   * @returns {Observable<any>}
   * @memberof PurchaseProgramService
   */
  public getCompleteList(filters: any): Observable<any> {
    return Observable.create(observer => {
      this.initPagination(filters);
      this.getList(filters)
        .subscribe(purchasePrograms => {
          this.purchasePrograms = purchasePrograms;
          this.paginateResult(filters)
            .subscribe(() => {
              observer.next(this.purchasePrograms);
            }, (error) => {
              this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            });
        }, (error) => {
          this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
        })
      });
  }

  /**
   * Get list of purchase program
   *
   * @param {Object} filters
   * @returns {Observable<PurchaseProgram[]>}
   * @memberof PurchaseProgramService
   */
  public getList(filters: Object): Observable<PurchaseProgram[]> {
    const query = JSON.stringify(filters);
    const api_base_url = `${environment.api_base_url}/${this.route}`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params})
      .pipe(
        tap((x: any) => {this.pageCount[query] = x.page_count}),
        map((data: any) =>
          data._embedded.purchase_program
            .map((jsonPurchaseProgram: any) =>
              new PurchaseProgram(jsonPurchaseProgram)
            )
        )
      )
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Paginate result
   *
   * @param {*} filters
   * @returns {Observable<any>}
   * @memberof PurchaseProgramService
   */
  public paginateResult(filters): Observable<any> {
    return Observable.create(observer => {
        let query = JSON.stringify(filters);

        if (this.pageCount[query] <= 1) {
          observer.next();
        }

        this.counts[query] = 1;

        while (this.pageNumber[query] < this.pageCount[query]) {
          this.pageNumber[query]++;
          filters.page = this.pageNumber[query];
          this.getList(filters)
            .subscribe(purchaseProgram => {
              this.counts[query]++;
              this.purchasePrograms = this.purchasePrograms.concat(purchaseProgram);
              if (this.checkActionCount(this.pageCount[query], this.counts[query])) {
                observer.next();
              }
          }, (error) => {
            this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
          })
        }
    })
  }

  /**
   *Check action count if it's true or false
   *
   * @param {*} totalActions
   * @param {*} actionCount
   * @returns {boolean}
   * @memberof PurchaseProgramService
   */
  public checkActionCount(totalActions, actionCount): boolean {
    return totalActions === actionCount;
  }

  /**
   * Init pagination
   *
   * @param {*} filters
   * @memberof PurchaseProgramService
   */
  public initPagination(filters): void {
    let query = JSON.stringify(filters);

    this.counts[query] = 0;
    this.pageCount[query] = 0;
    this.pageNumber[query] = 1;
    this.purchaseProgram = [];
    this.purchasePrograms = [];
  }

  /**
   * Get list of purchase programe
   *
   * @param {Object} [filters=null]
   * @returns {Observable<FilteredItem[]>}
   * @memberof PurchaseProgramService
   */
  public getListForFilter(filters: Object = null): Observable<FilteredItem[]> {
    const api_base_url = `${environment.api_base_url}/${this.route}`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params}).pipe(
      map((data: any) =>
        data._embedded.purchase_program
          .map((jsonPurchaseProgram: any) =>
            new PurchaseProgram(jsonPurchaseProgram)
          )
          .map((purchaseProgram: any) =>
            new FilteredItem(purchaseProgram)
          )
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  public postPurchaseProgram(purchaseProgram, purchase?): Observable<PurchaseProgram> {
    const api_base_url = `${environment.api_base_url}/${this.route}`;

    return this.http
      .post(api_base_url, this.extract(purchaseProgram, purchase))
      .pipe(
        map((data: any) =>
          new PurchaseProgram(data)
        )
      )
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Edit purchase program
   *
   * @param {PurchaseProgram} purchaseProgram
   * @returns {Observable<PurchaseProgram>}
   * @memberof PurchaseProgramService
   */
  public editPurchase(purchaseProgram: PurchaseProgram): Observable<PurchaseProgram> {
    const api_base_url = `${environment.api_base_url}/${this.route}/${purchaseProgram.id}`;

    return this.http
      .put(api_base_url, this.extract(purchaseProgram))
      .pipe(
        map((data: any) =>
          new PurchaseProgram(data)
        )
      )
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Delete purchase program
   *
   * @param {PurchaseProgram} purchaseProgram
   * @returns {Observable<Object>}
   * @memberof PurchaseProgramService
   */
  public delete(purchaseProgram: PurchaseProgram): Observable<Object> {
    const api_base_url = `${environment.api_base_url}/${this.route}/${purchaseProgram.id}`;

    return this.http
      .delete(api_base_url)
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Extract
   *
   * @private
   * @param {PurchaseProgram} purchaseProgram
   * @param purchase
   * @returns {Object}
   * @memberof PurchaseProgramService
   */
  private extract(purchaseProgram: PurchaseProgram, purchase?): Object {
    return {
      offer_program: purchase ? purchaseProgram['offer_program'] : purchaseProgram.offerProgram.id,
      program: purchase ? purchaseProgram.program : purchaseProgram.program.id,
      area: {
          id: purchaseProgram.area ? purchaseProgram.area.id : 'NAT',
          channelId: purchase ? purchaseProgram.channel : purchaseProgram.channel.id
      },
      channel: purchase ? purchaseProgram.channel : purchaseProgram.channel.id,
      purchase: purchase ? purchase.id : purchaseProgram.purchase.id,
      broadcast_count: purchaseProgram.broadcastCount || 0
    }
  }
}
