const WAITING         = 1;
const IN_PROGRESS     = 2;
const DONE            = 3;

import { Base } from './base.resource';

export interface JsonQueueLog {
    id: number;
    name: string;
    entity_id: number;
    status: string;
    total_count: number;
    error_count: number;
    ok_count: number;
}

export class QueueLog extends Base {
    public static PURCHASE_PROPALE  = 0;
    public static PURCHASE_BROADCAST = 1;

    public static QUEUE_NAME_BROADCAST_PURCHASED = 'broadcast_purchased';

    public id: number;
    public name: string;
    public entityId: number;
    public status: string;
    public totalCount: number;
    public errorCount: number;
    public okCount: number;

    public static WAITING         = WAITING;
    public static IN_PROGRESS     = IN_PROGRESS;
    public static DONE            = DONE;

    constructor(json: JsonQueueLog) {
        super();
        json = this.convertToUnderscore(json);
        this.id = json.id;
        this.name = json.name;
        this.entityId = json.entity_id;
        this.status = json.status;
        this.totalCount = json.total_count;
        this.errorCount = json.error_count;
        this.okCount = json.ok_count;
    }
}