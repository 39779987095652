<div>
    <h1 class="h1-dossier">Les dossiers</h1>
</div>

<app-purchase-list-filter [loading]="loading" (queryParams)="getFilter($event)" (getPurchase)="getPurchase()"></app-purchase-list-filter>
<mat-progress-bar  *ngIf="loading" mode="indeterminate"></mat-progress-bar>

<div class="clearfix">
    <!-- <button mat-button class="new bold pull-right blue" routerLink='/purchases/create'>+  Créer un dossier</button> -->
    <!-- non utiliser par le service planning et non fonctionnel -->
</div>
<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
    <div class="thin">
        Résultats des filtres
    </div>
    <div class="title-find" fxLayoutAlign="start center" *ngIf="purchases > 1">
        <span>{{ purchases }} dossiers trouvés</span>
    </div>
    <div class="title-find" fxLayoutAlign="start center" *ngIf="purchases <= 1">
        <span>{{ purchases }} dossier trouvé</span>
    </div>
</div>

<div class="separator"></div>

<app-purchase-list-detail
    *ngFor="let purchase of purchases$ | async"
    [purchase]="purchase">
</app-purchase-list-detail>
