
import { Directive, ElementRef, OnInit, Input, HostListener, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';
import { takeUntil, distinctUntilChanged, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Directive({
   selector: '[appThousandsNumber]'
})
export class ThousandsNumberDirective implements OnInit {
  @Input() noFixed: boolean;
  @Input() min: number;
  @Input() max: number;

  destroyDirectiveSource$: Subject<boolean> = new Subject<boolean>();
  oldValue: string;
  focused: boolean = false;

  constructor(
      private elemRef: ElementRef,
      private model: NgControl,
      private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.formatNumber(this.elemRef.nativeElement.value);

    this.model.valueChanges
      .pipe(
        takeUntil(this.destroyDirectiveSource$),
        distinctUntilChanged(),
        filter((item) => item !== this.oldValue) // OPTIONAL
      )
      .subscribe((data) => {
        if (!this.focused) {
          this.formatNumber(data);
        }
      })
  }

  @HostListener('blur') onBlur(): void {
    this.elemRef.nativeElement.type = 'string';
    this.formatNumber(this.model.control.value);
    this.focused = false;
  }

  @HostListener('focus') onFocus(): void {
    this.allowedChar(this.model.control.value);
    this.elemRef.nativeElement.type = 'number';
    this.focused = true;
  }

  /**
   * Transforme with a format the number
   *
   * @param {string} number
   * @memberof ThousandsNumberDirective
   */
  formatNumber(number: string): void {
    let nbr: string = this.transformNumber(number);

    if (this.noFixed) {
      nbr = <string>Number(nbr).toLocaleString('fr-FR');
    } else {
      nbr = <string>Number(Number(nbr).toFixed(2)).toLocaleString('fr-FR');
    }
    this.renderer.setProperty(this.elemRef.nativeElement, 'value', nbr);
  }

  /**
   * Transforme without format the number
   *
   * @param {string} number
   * @memberof ThousandsNumberDirective
   */
  allowedChar(number: string): void {
    let nbr: string = this.transformNumber(number);

    this.renderer.setProperty(this.elemRef.nativeElement, 'value', nbr);
  }

  /**
   * Transforme the number
   *
   * @param {string} number
   * @returns {string}
   * @memberof ThousandsNumberDirective
   */
  transformNumber(number: string): string {
    let newNumber: string = number.toString().replace(/[^0-9,.-]+/g, '').replace(/[,]+/g, '.');
    newNumber = this.noFixed ? newNumber : Number(newNumber).toFixed(2);

    if (this.min && this.min >= 0) {
      newNumber = newNumber.toString().replace(/[-]+/g, '');
    }

    this.oldValue = newNumber;
    return newNumber;
  }
}
