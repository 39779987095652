import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-lots',
  templateUrl: './lots.component.html',
  styleUrls: ['./lots.component.scss']
})
export class LotsComponent implements OnInit {
  public onglet: FormControl;

  constructor() {}

  ngOnInit() {
    // Default lot onglet value
    this.onglet = new FormControl('management');
  }
}
