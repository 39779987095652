import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { OfferProgramGridSynthesisService } from '../../../service/offer-program-grid-synthesis.service';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';

import { AdvertiserSynthesis, StatusSynthesis, Synthesis, SynthesisFilter } from '../../../../../resource/synthesis.resource';
import { Channel } from '../../../../../resource/channel.resource';

import { HeaderGridExtendsComponent } from '../../../../../shared/class/header-grid-extends/header-grid-extends.component';

import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-table-synthesis',
  templateUrl: './table-synthesis.component.html',
  styleUrls: ['./table-synthesis.component.scss']
})
export class TableSynthesisComponent extends HeaderGridExtendsComponent implements OnInit, OnDestroy {
  @Input() filters: any;
  @Input() synthesisFilter: SynthesisFilter;
  @Output() getValueSourceSynthesis: EventEmitter<any> = new EventEmitter();

  public months = [];
  public weeks = [];
  public numberMonths = [];

  public isFullScreen: boolean = false;
  public view: boolean = false;
  public loading = false;
  public sourceSynthesis = [];
  public path: string = Channel.PATH;

  private saveSourceSynthesis = [];
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private offerProgramGridSynthesisService: OfferProgramGridSynthesisService,
    private customToastrService: CustomToastrService
  ) {
    super();
  }

  ngOnInit() {
    this.programGridSynthesis();

    this.offerProgramGridSynthesisService.loadingSynthesis$
      .pipe(
        takeUntil(this.componentDestroyed$),
        debounceTime(500)
      )
      .subscribe(() => {
        this.programGridSynthesis();
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.offerProgramGridSynthesisService.filterSynthesis$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        this.sourceSynthesis = this.saveSourceSynthesis;
        this.filterSynthesisGrid();
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.offerProgramGridSynthesisService.orderSynthesis$
      .pipe(
        takeUntil(this.componentDestroyed$),
        debounceTime(100)
      )
      .subscribe((order: string) => {
        switch (order) {
          case 'asc': {
            this.orderASC();
            break;
          }
          case 'desc': {
            this.orderDESC();
            break;
          }
        }
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.offerProgramGridSynthesisService.viewChangeToogle$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((viewStatus: boolean) => {
        this.view = viewStatus;
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.unsubscribe();
  }

  /**
   * Host listener (like observable), detect esc press down
   *
   * @param event
   */
  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.isFullScreen && (event.code === 'Escape' || event.key === 'Escape')) {
      this.isFullScreen = false;
    }
  }

  /**
   * Get offer for the table with year
   *
   * @return {void}
   */
  programGridSynthesis(): void {
    this.loading = true;
    this.updateHeaderYear();

    this.offerProgramGridSynthesisService
      .get(this.filters.year)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((synthesisData) => {
        this.formatSynthesis(synthesisData);
        this.filterSynthesisGrid();
        this.loading = false;
      }, () => {
        this.loading = false;
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });
  }

  /**
   * Format data to table
   *
   * @param data
   * @return {void}
   */
  formatSynthesis(data): void {
    this.sourceSynthesis = [];

    data.forEach((item) => {
      let row: AdvertiserSynthesis[];
      let groupRow: Array<AdvertiserSynthesis[]> = [];
      let maxRow: number = item.offer.slots;

      let size: number = 0;
      let saveSize: number;
      let offerDeprogWeekStatus = [];
      let exceptionsByWeek = [];

      // Count max Row
      for (let index of Object.keys(item['weeks'])) {
        // Replace week 52e/53e in first place
        if (index === '52e' || index === '53e') {
          offerDeprogWeekStatus.unshift(item['weeks'][index]['deprogStatus']);
          exceptionsByWeek.unshift(item['weeks'][index]['exceptions']);
        } else {
          offerDeprogWeekStatus.push(item['weeks'][index]['deprogStatus']);
          exceptionsByWeek.push(item['weeks'][index]['exceptions']);
        }

        if (maxRow < item['weeks'][index]['purchases'].length) {
          maxRow = item['weeks'][index]['purchases'].length;
        }

        size++;
      }

      // Exception week 1e need to be in last place
      if (item.weeks['1e']) {
        const indexWeek = Object.keys(item.weeks).findIndex((week: string) => week === '1e');

        if (indexWeek >= 0) {
          offerDeprogWeekStatus.splice(indexWeek, 1);
          exceptionsByWeek.splice(indexWeek, 1);

          offerDeprogWeekStatus.push(item.weeks['1e']);
          exceptionsByWeek.push(item.weeks['1e']);
        }
      }

      saveSize = size;

      // Add cell
      for (let i = 0; i < maxRow; i++) {
        row = [];
        // order data all weeks
        if (i === 0) {
          item = this.reOrderData(item);
        }

        for (let t = 1; t <= size; t++) {
          if (t === 1) {
            if (item.weeks['52e']) {
              if (item && item.weeks['52e'] && item.weeks['52e'].purchases[i]) {
                row = this.pushRowWithcolor(row, item, '52e', i);
                size--;
              } else {
                row = this.pushRowNocolor(row, item, '52e');
                size--;
              }
            } else if (item.weeks['53e']) {
              if (item && item.weeks['53e'] && item.weeks['53e'].purchases[i]) {
                row = this.pushRowWithcolor(row, item, '53e', i);
                size--;
              } else {
                row = this.pushRowNocolor(row, item, '53e');
                size--;
              }
            }
          }

          if (t === 53) {
            if (item.weeks['1e']) {
              if (item && item.weeks['1e'] && item.weeks['1e'].purchases[i]) {
                row = this.pushRowWithcolor(row, item, '1e', i);
              } else {
                row = this.pushRowNocolor(row, item, '1e');
              }
            } else {
              if (item && item.weeks[t] && item.weeks[t].purchases[i]) {
                row = this.pushRowWithcolor(row, item, t, i);
              } else {
                row = this.pushRowNocolor(row, item, t);
              }
            }
          } else {
            if (item && item.weeks[t] && item.weeks[t].purchases[i]) {
              row = this.pushRowWithcolor(row, item, t, i);
            } else {
              row = this.pushRowNocolor(row, item, t);
            }
          }
        }

        size = saveSize;
        groupRow.push(<AdvertiserSynthesis[]>row);
      }

      // List of soReach ID
      const soReachIds: string[] = item.offer.soreach_ids ? item.offer.soreach_ids : [];
      // Order and add extra slots for SoReach
      groupRow = this.formatSynthesisSoReach(groupRow, item.offer.slots, soReachIds, saveSize);

      this.sourceSynthesis.push(new Synthesis({
        id: item.offer.id,
        name: item.offer.name,
        channels: item.offer.channels.map((channel) => channel.toLowerCase()),
        natChannels: item.offer.nat_channels.map((channel) => channel.toLowerCase()),
        programCategories: item.offer.program_categories.map((category) => category.toUpperCase()),
        f3Regions: item.offer.f3_areas.map((area) => area.toUpperCase()),
        advertiser: groupRow,
        deprogWeekStatus: offerDeprogWeekStatus,
        exceptions: exceptionsByWeek,
        channelGroups: item.offer.channel_groups,
        soreachIds: item.offer.soreach_ids,
        slots: item.offer.slots,
        isExclusive: item.offer.is_exclusive
      }));
    });

    this.saveSourceSynthesis = this.sourceSynthesis;
  }

  /**
   * Format for SoReach
   * We separate normal row and soReach row for add extra slots
   *
   * @param groupRow {Array<AdvertiserSynthesis[]>}
   * @param maxNRow {number} Max Normal Row
   * @param soReachIds {Array<string>} List od ID soReach
   * @param sizeWeek {number}
   *
   * @return {Array<AdvertiserSynthesis[]>}
   */
  formatSynthesisSoReach(groupRow: Array<AdvertiserSynthesis[]>, maxNRow: number, soReachIds: Array<string>, sizeWeek: number): Array<AdvertiserSynthesis[]> {
    let normalRow: Array<AdvertiserSynthesis[]> = [];
    let soReachRow: Array<AdvertiserSynthesis[]> = [];
    let protection: number = 0; // extra-security, just in case
    let extra: number = 0;
    const cloneRow: AdvertiserSynthesis[] = groupRow.length ? JSON.parse(JSON.stringify(groupRow[0])) : null; // Clone first element

    // We count normal and soReach row
    groupRow.forEach((row: AdvertiserSynthesis[]) => {
      // Find soReach for exemple
      const resultAdvertiserSynthesis = row.find((item: AdvertiserSynthesis) => !!(item && item.soreach_purchase));

      // If row have SoReach
      if (resultAdvertiserSynthesis) {
        // If row have soReach and normal purchase
        if (row.find((item: AdvertiserSynthesis) => !item.soreach_purchase && item.status !== StatusSynthesis.Unavailable && item.status !== StatusSynthesis.Available)) {
          // Clone row and purge to normal row
          const cloneNormalRow = JSON.parse(JSON.stringify(row.map((item) => {
            // If have purchase and soReach create a available
            if ((item.status === StatusSynthesis.Purchase || item.status === StatusSynthesis.Option || item.status === StatusSynthesis.Alerte || item.status === StatusSynthesis.Priority) && item.soreach_purchase) {
              item = new AdvertiserSynthesis({
                status: item.isActivated ? StatusSynthesis.Available : StatusSynthesis.Unavailable,
                duration: item.duration
              });
            }

            return item;
          })));
          normalRow.push(cloneNormalRow);

          // Clone row and purge to soReach row
          const cloneSoReachRow = JSON.parse(JSON.stringify(row.map((item) => {
            if (item.status === StatusSynthesis.Available) {
              item.status = StatusSynthesis.Unavailable;
              item.soreach_purchase = resultAdvertiserSynthesis.soreach_purchase;
            } else if ((item.status === StatusSynthesis.Purchase || item.status === StatusSynthesis.Option || item.status === StatusSynthesis.Alerte || item.status === StatusSynthesis.Priority || item.status === StatusSynthesis.Unavailable) && !item.soreach_purchase) {
              item = new AdvertiserSynthesis({
                status: StatusSynthesis.Unavailable,
                duration: item.duration,
                soreach_purchase: resultAdvertiserSynthesis.soreach_purchase
              });
            }
            return item;
          })));
          soReachRow.push(cloneSoReachRow);
        } else {
          // Push if have just purchase soReach
          row.forEach((item) => {
            item.soreach_purchase = resultAdvertiserSynthesis.soreach_purchase;
            if (item.status === StatusSynthesis.Available) {
              item.status = StatusSynthesis.Unavailable;
            }
          });
          soReachRow.push(row);
        }
      } else {
        // Push if have no Soreach
        normalRow.push(row);
        // If it's option/alert/etc... row, add extra row
        if (row.find((advertiserSynthesis: AdvertiserSynthesis) => advertiserSynthesis.status >= StatusSynthesis.Option && advertiserSynthesis.status !== StatusSynthesis.Available)) {
          extra++;
        }
      }
    });

    // Add extra slots if needed
    while (normalRow.length < (maxNRow + extra) && protection++ < 25) {
      // Take the first statue of row
      const row = normalRow.length ? JSON.parse(JSON.stringify(normalRow[0])) : JSON.parse(JSON.stringify(cloneRow));
      row.forEach((item: AdvertiserSynthesis, index: number) => {
        // If have color create available
        if (item.status !== StatusSynthesis.Available && item.status !== StatusSynthesis.Unavailable) {
          row[index] = new AdvertiserSynthesis({
            status: item.isActivated ? StatusSynthesis.Available : StatusSynthesis.Unavailable,
            duration: item.duration,
          });
        }
        // Remove property
        if (item.soreach_purchase) {
          delete item.soreach_purchase;
        }
      });
      normalRow.push(row);
    }

    // Add extra row if its empty
    for (let i = soReachRow.length; i < soReachIds.length; i++) {
      soReachRow.push(new Array(new AdvertiserSynthesis({
        status: 0,
        duration: sizeWeek,
        soreach_purchase: '0'
      })));
    }

    // Merge normal row + soReach row
    return [...normalRow, ...soReachRow];
  }

  /**
   * Table synthesis full screen
   *
   * @return {void}
   */
  fullScreen(): void {
    this.isFullScreen = true;
  }

  /**
   * Remove full screen
   *
   * @return {void}
   */
  removeFullScreen(): void {
    this.isFullScreen = false;
  }

  private buildTooltip(data): string {
    let text = '';

    text += 'Annonceur: ' + (data.advertiser_name ? data.advertiser_name : 'N/A') + '\n';
    text += 'Produit: ' + (data.product_id ? `${data.product_name} (${data.product_id})` : 'N/A') + '\n';
    text += 'Commercial: ' + (data.employee_name ? data.employee_name : 'N/A');

    if (data.status === 5) {
      text += '\n' + 'Expiration: ' + (data.expiration ? data.expiration : 'N/A');

      if (data.alert_switch_date) {
        text += `\n\n Alerte posée le ${moment(data.alert_switch_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}`
      }
    }

    return text;
  }

  /**
   * Add new cell or upgrade duration for no color
   *
   * @param row {AdvertiserSynthesis[]}
   * @param item
   * @param t {number|string}
   * @return {AdvertiserSynthesis[]}
   */
  private pushRowNocolor(row: AdvertiserSynthesis[], item: any, t: number | string): AdvertiserSynthesis[] {
    if (row.length && (row[(row.length - 1)].status === (item.weeks[t].isAvailable ? 7 : 0))) {
      row[(row.length - 1)] = new AdvertiserSynthesis({
        status: item.weeks[t].isAvailable ? 7 : 0,
        duration: row[(row.length - 1)].duration + 1,
      });
    } else {
      const advertiserSynthesis: AdvertiserSynthesis = new AdvertiserSynthesis({
        status: item.weeks[t].isAvailable ? 7 : 0,
        duration: 1
      });
      if (t === '53e') {
        row.unshift(advertiserSynthesis);
      } else if (t === '52e') {
        row.unshift(advertiserSynthesis);
      } else {
        row.push(advertiserSynthesis);
      }
    }
    return row;
  }

  /**
   * Add new cell or upgrade duration for with color
   *
   * @param row {AdvertiserSynthesis[]}
   * @param item
   * @param t {number|string}
   * @param i {number}
   * @return {AdvertiserSynthesis[]}
   */
  private pushRowWithcolor(row: AdvertiserSynthesis[], item: any, t: number | string, i: number): AdvertiserSynthesis[] {
    if (row.length && row[(row.length - 1)].id === item.weeks[t].purchases[i].id) {
      row[(row.length - 1)] = new AdvertiserSynthesis({
        status: item.weeks[t].purchases[i].status,
        id: item.weeks[t].purchases[i].id,
        product_name: item.weeks[t].purchases[i].product_name,
        product_id: item.weeks[t].purchases[i].product_id,
        duration: row[(row.length - 1)].duration + 1,
        advertiser: item.weeks[t].purchases[i].advertiser_name,
        tooltip: row[(row.length - 1)].tooltip,
        soreach_purchase: item.weeks[t].purchases[i].soreach_purchase,
        isActivated: item.weeks[t].isAvailable
      });
    } else {
      const advertiserSynthesis: AdvertiserSynthesis = new AdvertiserSynthesis({
        status: item.weeks[t].purchases[i].status,
        id: item.weeks[t].purchases[i].id,
        product_name: item.weeks[t].purchases[i].product_name,
        product_id: item.weeks[t].purchases[i].product_id,
        duration: 1,
        advertiser: item.weeks[t].purchases[i].advertiser_name,
        soreach_purchase: item.weeks[t].purchases[i].soreach_purchase,
        tooltip: this.buildTooltip(item.weeks[t].purchases[i]),
        isActivated: item.weeks[t].isAvailable
      });
      if (t === '53e') {
        row.unshift(advertiserSynthesis);
      } else if (t === '52e') {
        row.unshift(advertiserSynthesis);
      } else {
        row.push(advertiserSynthesis);
      }
    }
    return row;
  }

  /**
   * re order the data for format
   *
   * @param item
   * @return {Object}
   */
  private reOrderData(item: Object): Object {
    let oldPurchases: Array<any>;
    let finding: number;

    for (let index of Object.keys(item['weeks'])) {
      if (index !== '1') {
        item['weeks'][index].purchases.forEach((data, position) => {
          finding = oldPurchases.findIndex((oldPurchase) => {
            return !!(oldPurchase && data && oldPurchase.id && data.id && (oldPurchase.id === data.id));
          });
          if ((finding >= 0) && (finding !== position)) {
            const copyPurchase = item['weeks'][index].purchases[finding];
            item['weeks'][index].purchases[finding] = item['weeks'][index].purchases[position];
            item['weeks'][index].purchases[position] = copyPurchase;
          }
        });
      }
      oldPurchases = item['weeks'][index].purchases;
    }

    return item;
  }

  /**
   * Order by ASC immutable data
   */
  private orderASC(): void {
    this.sourceSynthesis = [...this.sourceSynthesis.sort((a: Synthesis, b: Synthesis) => {
      return a.name.localeCompare(b.name);
    })];
  }

  /**
   * Order by DESC immutable data
   *
   * @return {void}
   */
  private orderDESC(): void {
    this.sourceSynthesis = [...this.sourceSynthesis.sort((a: Synthesis, b: Synthesis) => {
      return b.name.localeCompare(a.name);
    })];
  }

  /**
   * Filter Synthesis grid
   *
   * @return {void}
   */
  private filterSynthesisGrid(): void {
    if (this.synthesisFilter.offerType === 0) {
      this.filterNationalOffers();
    } else if (this.synthesisFilter.offerType === 1) {
      this.filterF3RegionalOffers();
    } else if (this.synthesisFilter.offerType === 2) {
      this.filterSoReachOffers();
    } else if (this.synthesisFilter.offerType === 3) {
      this.filterOverseaOffers();
    }

    this.filterByChannel();
    this.filterByF3Area();
    this.filterByCategory();
    this.filterByOfferName();

    if (this.filters && this.filters.isExclusive === 1) {
      this.filterByOfferExclusive();
    }

    this.getValueSourceSynthesis.emit(this.sourceSynthesis);
  }

  /**
   * Filter synthesis grid by France 3 areas
   *
   * @return {void}
   */
  private filterByF3Area(): void {
    if (this.synthesisFilter.areas && this.synthesisFilter.areas.length) {
      this.sourceSynthesis = this.sourceSynthesis.filter((offerSynthesis: Synthesis) => {
        return offerSynthesis.channels.includes('3') && this.synthesisFilter.areas
          .some((area) => offerSynthesis.f3Regions.includes(area.value))
      });
    }
  }

  /**
   * Filter synthesis grid by offer name
   *
   * @return {void}
   */
  private filterByOfferName(): void {
    if (this.synthesisFilter.offerName && !!this.synthesisFilter.offerName.length) {
      this.sourceSynthesis = this.sourceSynthesis.filter((offerSynthesis: Synthesis) => {
        return offerSynthesis.name.toLowerCase().includes(this.synthesisFilter.offerName.toLowerCase());
      });
    }
  }

  /**
   * Filter synthesis grid by channels
   *
   * @return {void}
   */
  private filterByChannel(): void {
    if (this.synthesisFilter.channels && this.synthesisFilter.channels.length) {
      this.sourceSynthesis = this.sourceSynthesis.filter((offerSynthesis: Synthesis) => {
        return this.synthesisFilter.channels.some((channel) => {
          return offerSynthesis.channels.includes(channel.value.toLowerCase());
        })
      });
    }
  }

  /**
   * Filter synthesis grid by program categories
   *
   * @return {void}
   */
  private filterByCategory(): void {
    this.sourceSynthesis = (!this.synthesisFilter.categories || !this.synthesisFilter.categories.length) ? [] : this.sourceSynthesis.filter(
      (offerSynthesis: Synthesis) => {
        return this.synthesisFilter.categories.some((category) => offerSynthesis.programCategories.includes(category.value))
      }
    );
  }

  /**
   * Filter synthesis grid by national offers
   *
   * @return {void}
   */
  private filterNationalOffers(): void {
    this.sourceSynthesis = this.sourceSynthesis.filter((offerSynthesis: Synthesis) => {
      return offerSynthesis.channelGroups.includes('F') && offerSynthesis.natChannels && offerSynthesis.natChannels.length !== 0;
    });
  }

  /**
   * Filter synthesis grid by france 3 regional offers
   *
   * @return {void}
   */
  private filterF3RegionalOffers(): void {
    this.sourceSynthesis = this.sourceSynthesis.filter((offerSynthesis: Synthesis) => {
      return offerSynthesis.channelGroups.includes('F') && !!offerSynthesis.f3Regions.length;
    });
  }

  /**
   * Filter synthesis grid by soreach offers
   *
   * @return {void}
   */
  private filterSoReachOffers(): void {
    this.sourceSynthesis = this.sourceSynthesis.filter((offerSynthesis: Synthesis) => {
      return (offerSynthesis.soReachIds && offerSynthesis.soReachIds.length !== 0);
    });
  }

  /**
   * Filter synthesis grid by oversea offers
   *
   * @return {void}
   */
  private filterOverseaOffers(): void {
    this.sourceSynthesis = this.sourceSynthesis.filter((offerSynthesis: Synthesis) => {
      return offerSynthesis.channelGroups.includes('O');
    });
  }

  /**
   * Filter synthesis grid by exclusive offer
   * @private
   */
  private filterByOfferExclusive(): void {
    this.sourceSynthesis = this.sourceSynthesis.filter((offerSynthesis: Synthesis) => {
      return offerSynthesis.isExclusive === '1';
    });
  }
}
