import { map, catchError } from 'rxjs/operators';
import { HttpParams, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SchedulePackage } from '../../resource/schedule/schedule-package.resource';
import { AdvertisingPackage } from '../../resource/advertising-package.resource'
import { Channel } from 'src/app/resource/channel.resource';
import { TrailerPeriodicity } from '../../resource/trailerPeriodicity.resource';
import { Schedule } from '../../resource/schedule/schedule.resource';
import { PurchaseProgram } from '../../resource/purchaseProgram.resource';

import { CustomToastrService } from '@service/toastr/custom-toastr.service';

import { environment } from '../../../environments/environment';
import { UtilitiesHandler } from '@service/utilities-handlers/utilitiesHandlers';

import { Observable } from 'rxjs';

@Injectable()
export class SchedulePackageService {
 private route: string = 'schedule_package';
 public purchasedSchedulePackages: SchedulePackage[] = [];

 constructor(
    private customToastrService: CustomToastrService,
    private http: HttpClient,
    private utilitiesHandler: UtilitiesHandler
  ) { }

  /**
   * Get schedule package with ID
   *
   * @param {(number | string)} id
   * @returns {Observable<SchedulePackage>}
   * @memberof SchedulePackageService
   */
  get(id: number | string): Observable<SchedulePackage> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}/${id}`;

    return this.http
      .get(api_base_url).pipe(
      map((data: any) =>
        new SchedulePackage(data)
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get list of schedule package
   *
   * @param {Object} filters
   * @returns {Observable<SchedulePackage[]>}
   * @memberof SchedulePackageService
   */
  getList(filters: Object): Observable<SchedulePackage[]> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params}).pipe(
      map((data: any) =>
        data._embedded.schedule_package
          .map((jsonSchedule: any) =>
            new SchedulePackage(jsonSchedule)
          )
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Post schedule package
   *
   * @param {SchedulePackage} schedulePackage
   * @returns {Observable<Object>}
   * @memberof SchedulePackageService
   */
  public post(schedulePackage: SchedulePackage): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;

    return this.http
      .post(api_base_url, this.extract(schedulePackage))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Post collection schedule package
   *
   * @param {SchedulePackage[]} schedulePackage
   * @returns {Observable<Object>}
   * @memberof SchedulePackageService
   */
  public postCollection(schedulePackage: SchedulePackage[]): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;

    return this.http
      .post(api_base_url, this.extractCollection(schedulePackage, false))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Edit schedule package
   *
   * @param {SchedulePackage} schedulePackage
   * @returns {Observable<Object>}
   * @memberof SchedulePackageService
   */
  public edit(schedulePackage: SchedulePackage): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}/${schedulePackage.id}`;

    return this.http
      .get(api_base_url, this.extract(schedulePackage))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Edit collection
   *
   * @param {SchedulePackage[]} schedulePackage
   * @returns {Observable<Object>}
   * @memberof SchedulePackageService
   */
  public editCollection(schedulePackage: SchedulePackage[]): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;

    return this.http
      .put(api_base_url, this.extractCollection(schedulePackage, true))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Remove schedule package
   *
   * @param {SchedulePackage} schedulePackage
   * @returns {Observable<Object>}
   * @memberof SchedulePackageService
   */
  public remove(schedulePackage: SchedulePackage):  Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}/${schedulePackage.id}`;

    return this.http
      .delete(api_base_url)
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

/**
 * Post cloned schedule packages
 *
 * @param clonedSchedule {Schedule}
 * @param soReach {number}
 * @returns {Observable<any>}
 * @memberof SchedulePackageService
 */
public postClonedSchedulePackages(clonedSchedule: Schedule, soReach?: number): Observable<any> {
    return Observable.create(observer => {
      if (!clonedSchedule.schedulePackages.length) {
        observer.next();
      } else {
        let count = 0;

        clonedSchedule.schedulePackages.forEach((schedulePackage: SchedulePackage) => {
          // Allow if isn't a soReach or soReach with only post/pre and mini generic or it is soreach but with channel thema
          if (!soReach || (clonedSchedule && clonedSchedule.purchaseProgram && clonedSchedule.purchaseProgram.area
            && clonedSchedule.purchaseProgram.area.channel && clonedSchedule.purchaseProgram.area.channel.channelGroup
            && clonedSchedule.purchaseProgram.area.channel.channelGroup === Channel.THEMA_CHANNEL_GROUP)
            || schedulePackage.advertisingPackage.id === AdvertisingPackage.PRE_GENERIC ||
            schedulePackage.advertisingPackage.id === AdvertisingPackage.POST_GENERIC ||
            schedulePackage.advertisingPackage.id === AdvertisingPackage.MINI_GENERIC_CUT ||
            schedulePackage.advertisingPackage.id === AdvertisingPackage.MINI_GENERIC_RECOVERY) {
            schedulePackage.parent = Object.assign({}, schedulePackage);
            schedulePackage.schedule = clonedSchedule;
            schedulePackage.id = null;
            this.post(schedulePackage)
            .subscribe(() => {
              if (this.checkCallCount(clonedSchedule.schedulePackages.length, ++count)) {
                observer.next();
              }
            }, () => {
              this.customToastrService.displayToastr('ERROR', 'Une erreur lors de la creation des Schedule Packages!');
            })
          } else {
            if (this.checkCallCount(clonedSchedule.schedulePackages.length, ++count)) {
              observer.next();
            }
          }
        })
      }
    })
  }

  /**
   * Manage schedule packages
   *
   * @param {*} schedulePackageForm
   * @param {Schedule} schedule
   * @returns {Observable<any>}
   * @memberof SchedulePackageService
   */
  public manageSchedulePackages(schedulePackageForm, schedule: Schedule): Observable<any> {
    return Observable.create(observer => {
      let entitiesToPost = [];
      let entitiesToUpdate = [];
      let entitiesToDelete = [];

      schedulePackageForm.value['schedulePackages'].forEach(
        (formSchedulePackage: SchedulePackage)  => {

          let schedulePackage = new SchedulePackage(this.getJsonSchedulePackage(formSchedulePackage));
          schedulePackage.advertisingPackage = formSchedulePackage.advertisingPackage;
          schedulePackage.periodicity = new TrailerPeriodicity({id: formSchedulePackage['periodicityId']});
          schedulePackage.schedule = schedule;

          if (schedulePackage.quantity && !schedulePackage.id) {
            entitiesToPost.push(schedulePackage);
          } else {
              if (schedulePackage.id && (schedulePackage.quantity === null || schedulePackage.quantity === 0)) {
                entitiesToDelete.push(schedulePackage);
              } else {
                if (schedulePackage.id && (schedulePackage.quantity !== 0)) {
                  entitiesToUpdate.push(schedulePackage);
                }
              }
          }
      });

      let callCount = 0;
      let totalCalls = entitiesToDelete.length;

      if (entitiesToPost.length) {
        totalCalls++;
        this.postCollection(entitiesToPost)
          .subscribe(() => {
            if (this.checkCallCount(totalCalls, ++callCount)) {
              observer.next();
            }
          }, (error) => {
            this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
          })
      }


      if (entitiesToUpdate.length) {
        totalCalls++;
        this.editCollection(entitiesToUpdate)
          .subscribe(() => {
            if (this.checkCallCount(totalCalls, ++callCount)) {
              observer.next();
            }
          }, (error) => {
            this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
          })
      }

      entitiesToDelete.forEach(entity => {
        this.remove(entity)
          .subscribe(() => {
            if (this.checkCallCount(totalCalls, ++callCount)) {
              observer.next();
            }
          }, (error) => {
            this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
          });
      });

      if (totalCalls === 0) {
        observer.next();
      }
    });
  }

  /**
   * To underscore
   *
   * @param {*} string
   * @returns {string}
   * @memberof SchedulePackageService
   */
  toUnderscore(string): string {
    return string.replace(/([A-Z])/g, function($1) {return '_' + $1.toLowerCase(); });
  };

  /**
   * Get json schedule package
   *
   * @param {*} formGroup
   * @returns {*}
   * @memberof SchedulePackageService
   */
  getJsonSchedulePackage(formGroup): any {
    let json = {};
    Object.keys(formGroup).forEach(key => {
        let underscoreKey = this.toUnderscore(key);
        json[underscoreKey] = formGroup[key];
    });
    return json;
  }

  /**
   * Remove all fields exept id
   *
   * @param {*} object
   * @returns {*}
   * @memberof SchedulePackageService
   */
  removeAllFieldsExceptId(object): any {
    Object.keys(object).forEach(key => {
      if (key !== 'id') {
        delete object[key];
      }
    });
    return object;
  }

  /**
   * Update purchase schedule package
   *
   * @param {*} data
   * @returns {Observable<any>}
   * @memberof SchedulePackageService
   */
  public updatePurchasedSchedulePackage(data): Observable<any> {
    return Observable.create(observer => {
      // Update purchasedSchedulePackages
      data.items.forEach(item => {
        let schedulePackage: SchedulePackage = item['item'];
        let parent: SchedulePackage = item['parent'];

        schedulePackage.quantity = (item['quantity'] || item['quantity'] === 0) ? item['quantity'] : parent.quantity;
        schedulePackage.duration = item['duration'];
        this.purchasedSchedulePackages[schedulePackage.id] = schedulePackage;
      });

      this.editCollection(this.purchasedSchedulePackages)
        .subscribe(() => {
          observer.next();
        }, (error) => {
          this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
        })
    })
  }

  /**
   * Extract
   *
   * @param {SchedulePackage} schedulePackage
   * @param {boolean} [withId=false]
   * @returns {Object}
   * @memberof SchedulePackageService
   */
  public extract(schedulePackage: SchedulePackage, withId: boolean = false): Object {
    let object = {
        quantity: schedulePackage.quantity,
        duration: schedulePackage.duration,
        advertising_package: schedulePackage.advertisingPackage.id,
        schedule: schedulePackage.schedule.id,
        periodicity: schedulePackage.periodicity.id,
        prime_quantity: schedulePackage.primeQuantity,
        parent: schedulePackage.parent && schedulePackage.parent.id ? schedulePackage.parent.id : null
    };

    if (withId) {
      object['id'] = schedulePackage.id ;
    }

    return object;
  }

  /**
   * Extract collection
   *
   * @param {*} array
   * @param {boolean} [withId=false]
   * @returns {Array<any>}
   * @memberof SchedulePackageService
   */
  public extractCollection(array, withId: boolean = false): Array<any> {
    let result = [];
    array.forEach(item => {
      result.push(this.extract(item, withId));
    });

    return result;
  }

  /**
   * Check call count
   *
   * @private
   * @param {*} total
   * @param {*} count
   * @returns {boolean}
   * @memberof SchedulePackageService
   */
  private checkCallCount(total, count): boolean {
    return total === count;
  }
}
