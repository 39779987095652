import { Component, OnDestroy, EventEmitter } from '@angular/core';

import { OfferProgram } from 'src/app/resource/offerProgram.resource';
import { PropalCart } from 'src/app/resource/availability/propal-cart.resource';
import { Broadcast } from 'src/app/resource/broadcast.resource';
import { OfferProgramPropalCart } from 'src/app/resource/availability/offer-program-propal-cart.resource';
import { PurchaseType } from 'src/app/resource/purchaseType.resource';

import { BroadcastService } from 'src/app/service/broadcast/broadcast.service';
import { CustomToastrService } from 'src/app/service/toastr/custom-toastr.service';
import { GridService } from 'src/app/private/availability/service/grid.service';
import { ArrayService } from 'src/app/service/utilities/array.service';
import { PurchaseService } from 'src/app/service/purchase/purchase.service';
import { PropalCartService } from 'src/app/service/propal-cart/propal-cart.service';

import { takeUntil } from 'rxjs/operators';
import { Subject ,  Observable } from 'rxjs';

import * as moment from 'moment';
import { GridHeaderService } from 'src/app/private/availability/service/grid.header.service';
import { OfferProgramService } from 'src/app/service/offer-program/offer-program.service';
import { ScheduleService } from '../../service/schedule/schedule.service';
import { Schedule } from '../../resource/schedule/schedule.resource';
import { ScheduleException } from 'src/app/resource/schedule/schedule-exception.resource';

@Component({
  selector: 'app-grid-extends',
  template: ''
})
export class GridExtendsComponent implements OnDestroy {

  protected offerProgram: OfferProgram;
  protected propalCart: PropalCart;
  protected queryParams;
  protected filters;

  protected startWeek: number;
  protected week: number;

  protected offerProgramPropalCart: OfferProgramPropalCart;

  protected loading: boolean;
  protected header: Array<any>;
  protected headerMonth: Array<any>;

  // grid data month
  protected broadcastsByDay;
  protected purchasesByDay = [];
  protected exceptionsByDay = [];
  protected purchasesByDayOrdered = [];
  protected advertisersByDay = [];

  // grid data year
  protected broadcastByWeek;
  protected purchasesByWeek = [];
  protected exceptionsByWeek = [];
  protected purchasesByWeekOrdered = [];
  protected advertisersByWeek = [];

  // put in service
  protected broadcasts: Broadcast[];
  protected schedules: Schedule[];
  protected purchaseIds: any[] = [];
  protected gameModuleList: any[] = [];
  protected gridData: any[];
  protected innerQueryParams: any;

  protected broadcastsTimes;

  protected onSendBroadcast: EventEmitter<any> = new EventEmitter();

  destroyCompleteList$: Subject<boolean> = new Subject<boolean>();

  constructor(
    protected customToastrService: CustomToastrService,
    protected broadcastService: BroadcastService,
    protected gridService: GridService,
    protected arrayService: ArrayService,
    protected purchaseService: PurchaseService,
    protected propalCartService: PropalCartService,
    protected gridHeaderService: GridHeaderService,
    protected offerProgramService: OfferProgramService,
    protected scheduleService: ScheduleService,
  ) {}

    ngOnDestroy() {
      this.destroyCompleteList$.next(true);
      this.destroyCompleteList$.unsubscribe();
    }

    public setOfferProgramPropalCart() {
      if (this.propalCart) {
        let offerProgramPropalCart = this.propalCart.getOfferProgramPropalCart(this.offerProgram);

        if (!offerProgramPropalCart) {
          return null;
        }
        this.offerProgramPropalCart = offerProgramPropalCart;
      }
    }

    public initQueryParams() {
      this.innerQueryParams =  Object.assign({}, this.queryParams);
      this.innerQueryParams.offer_program_id  = this.offerProgram.id;
      this.innerQueryParams.page = 1;
      this.innerQueryParams.groups = 'availability';

      if (this.offerProgram && this.offerProgram.area && this.offerProgram.area.channel && this.offerProgram.area.channel.id) {
        this.innerQueryParams.channel_id = this.offerProgram.area.channel.id;
      }
      if (this.offerProgram && this.offerProgram.program && this.offerProgram.program.id) {
        this.innerQueryParams.program_id = this.offerProgram.program.id
      }
      if (this.offerProgram && this.offerProgram.program && this.offerProgram.program.category && this.offerProgram.program.category.id) {
        this.innerQueryParams.category_id = this.offerProgram.program.category.id;
      }
      if (this.offerProgram && this.offerProgram.area && this.offerProgram.area.id) {
        this.innerQueryParams.area_id = this.offerProgram.area.id;
      }
    }

    public getBroadcasts() {
      return new Observable(observer => {
        this.loading = true;
        this.broadcastService
          .getCompleteList(this.innerQueryParams)
          .pipe(takeUntil(this.destroyCompleteList$))
          .subscribe(broadcasts => {
              this.broadcasts = broadcasts;
              observer.next();
          }, (error) => {
            this.customToastrService.displayToastr('ERROR', 'Erreur lors de la recupération des dates de diffusions.');
            return error;
          })
        });
    }

    public getSchedules() {
      return new Observable(observer => {
        this.scheduleService
        .getList({
          offer_program_id: this.offerProgram.id,
          is_purchased: false,
          groups: 'offerProgram'
        })
        .pipe(takeUntil(this.destroyCompleteList$))
        .subscribe(schedules => {
          this.schedules = schedules;
          observer.next();
        }, (error) => {
          this.customToastrService.displayToastr('ERROR', 'Erreur lors de la recupération des programmations.');
          return error;
        });
      });
    }

    public getGameModules() {
      return new Observable(observer => {
        this.getUniquePurchaseIdList()
          .pipe(takeUntil(this.destroyCompleteList$))
          .subscribe((purchaseIds: any) => {
            this.purchaseIds = this.arrayService.removeDuplicate(purchaseIds);
            if (this.purchaseIds.length === 0) {
              this.gameModuleList = [];
              observer.next();
            } else {
              this.purchaseService
                .getPurchaseWithGameListByPurchaseId(this.purchaseIds)
                .pipe(takeUntil(this.destroyCompleteList$))
                .subscribe(gameModuleList => {
                    this.gameModuleList = this.arrayService.arrayObjectToFullHashMap(gameModuleList);
                    observer.next();
                }, error => {
                  this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
                });
            }
          }, error => {
            return error;
          });
      });
    }

    public getUniquePurchaseIdList() {
      return new Observable(observer => {
        let purchaseIds = [];
        this.broadcasts.forEach(broadcast => {
          broadcast.broadcastPurchased.forEach(broadcastPurchase => {
              let broadcastPurchaseRelation = broadcastPurchase._embedded;

              if (this.gridService.isPurchaseOfCurrentOfferProgram(broadcastPurchaseRelation.offer_program, this.offerProgram)) {
                  let id = broadcastPurchaseRelation.purchase._links.self.href.split('/');
                  purchaseIds.push(id[id.length - 1]);
              }
          });
        });

        observer.next(purchaseIds);
      });
    }

    public displayGrid() {
      this.constructGrid()
        .pipe(takeUntil(this.destroyCompleteList$))
        .subscribe((gridData: any) => {
          this.constructHeader();
          this.gridData = gridData;
          this.loading = false;
        }, error => {
          console.error('error: ', error);
          this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
        })
    }

    /**
     * Create Header month/year
     */
    public constructHeader(): void {
      let displayMode = this.filters.displayMode;
      let year = this.filters.year;
      let month = this.filters.month;

      // init startWeek
      if (!this.startWeek) {
        this.startWeek = moment(this.filters.broadcasted_from).isoWeek();
      }

      switch (displayMode) {
        // construct year with total numb week
        case 'year':
          this.week = this.calculateNumberOfWeeks(this.filters.broadcasted_from, this.filters.broadcasted_to);
          this.header = this.gridHeaderService.getWeeksOfYear(this.week, this.startWeek, month, year);
          this.headerMonth = this.gridHeaderService.getMonthHeader(year, this.filters.broadcasted_from, this.filters.broadcasted_to);
          break;
        // construct month
        case 'month':
          this.header = this.gridHeaderService.getDaysOfMonth(year, month, this.filters.broadcasted_from, this.filters.broadcasted_to);
          this.headerMonth = null;
          break;
      }
    }

    private deprogrammedConstruct(broadcast, program: string, channel: string) {
      let deprogrammed: Array<Array<Broadcast>> = [];

      broadcast.forEach((element) => {
        let broadTime = [];

        element.forEach(data => {
          if (data.broadcastType === 1) {
            data['programeName'] = program;
            data['channelName'] = channel;
            broadTime.push(data);
          }
        });

        deprogrammed.push(broadTime);
      });
      return deprogrammed;
    }

    /**
     * Return total weeks between two dates
     *
     * @param {string} startDate
     * @param {string} endDate
     * @returns {(number)}
     */
    private calculateNumberOfWeeks(startDate: string, endDate: string): number {
      let startMonth, startWeek, endMonth, endWeek;

      // reference numbers month and week of startDate
      startMonth = moment(startDate).month();
      startWeek =  moment(startDate).isoWeek();

      // if startMonth refers to "january" and startWeek equal 52, we set startWeek to 0
      // if else startMonth refers to "december" and startWeek equal 1, we set startWeek to 53
      // if else startMonth refers to "january" and startWeek equal 53, we set startWeek to 0
      if (startMonth === 0 && startWeek === 52) {
        startWeek = 0;
      } else if (startMonth === 11 && startWeek === 1) {
        startWeek = 53;
      } else if (startMonth === 0 && startWeek === 53) {
        startWeek = 0;
      }

      // reference numbers month and week of endDate
      endMonth = moment(endDate).month();
      endWeek = moment(endDate).isoWeek();

      // if endMonth refers to "january" and endWeek equal 52, we set endWeek to 0
      // if else endMonth refers to "december" and endWeek equal 1, we set endWeek to 53
      if (endMonth === 0 && endWeek === 52) {
        endWeek = 0;
      } else if (endMonth === 11 && endWeek === 1) {
        endWeek = 53;
      }

      // total of weeks
      return endWeek - startWeek + 1;
    }

    private getChannelGroup(): string {
      return (this.offerProgram.area.channel.id === 'O' && this.filters.year >= 2019) ? 'W' : this.offerProgram.area.channel.channelGroup
    }

    private constructGrid() {
      return new Observable(observer => {
        let allBroadcastsByProgram = this.broadcasts;
        let compact;

        // sort program broadcasts by date order
        allBroadcastsByProgram.sort((broadcastX, broadcastY) => {
          return Date.parse(broadcastX.startTime) - Date.parse(broadcastY.startTime);
        });

        let purchaseTypes = PurchaseType.TYPES;
        let maxNumberOfAdvertiser: number = this.offerProgram.offer.maxNumberOfAdvertiser;

        this.gridService.setMaxOfLinesByDefault(maxNumberOfAdvertiser);

        // used to know how many programs are solded by partTime
        let purchasesSoldedByTime = null;
        // final array wich go to the grid
        let mergedArray = [];
        let mergedSoReachArray = [];

        switch (this.filters.displayMode) {

          // "Year" tab
          case 'year':
            this.startWeek = moment(this.filters.broadcasted_from).isoWeek();
            let totalNumberOfWeeks = this.calculateNumberOfWeeks(this.filters.broadcasted_from, this.filters.broadcasted_to);

            if (!this.broadcastByWeek) {
              this.broadcastByWeek = this.gridService.getBroadcastsByWeeks(
                allBroadcastsByProgram,
                totalNumberOfWeeks,
                this.filters.broadcasted_from
              );
            }

            this.exceptionsByWeek = this.gridService.getExceptionByWeeksFromSchedules(
              this.schedules,
              totalNumberOfWeeks,
              this.filters.broadcasted_from
            );

            // for each type of purchase
            for (let i = 0; i < purchaseTypes.length; i++) {
              let type = purchaseTypes[i];

              if (!this.purchasesByWeek[i]) {
                this.purchasesByWeek[i] = this.gridService.getPurchasesByTimeParts(this.broadcastByWeek, type, this.offerProgram);
              }
              if (type.id === PurchaseType.PURCHASE) {
                  purchasesSoldedByTime = this.purchasesByWeek[i];
              }

              if (!this.purchasesByWeekOrdered[i]) {
                this.purchasesByWeekOrdered[i] = this.gridService.orderPurchases(
                  this.broadcastByWeek,
                  this.purchasesByWeek[i],
                  totalNumberOfWeeks,
                  this.gameModuleList
                );
              }

              if (!this.advertisersByWeek[i]) {
                this.advertisersByWeek[i] = this.gridService
                  .insertPurchasesInGrid(this.purchasesByWeekOrdered[i], totalNumberOfWeeks, type);
              }

              // get max number of line
              this.gridService.setCurrentLinesGroup(type.id); // set group to add copart for "achat" type only
              let maxOfLines: number = this.gridService.getMaxLinesOfGridGroup(this.advertisersByWeek[i]);

              let programsTime = this.gridService.constructCells(
                this.broadcastByWeek,
                this.advertisersByWeek[i],
                totalNumberOfWeeks,
                maxOfLines,
                maxNumberOfAdvertiser,
                purchasesSoldedByTime,
                this.offerProgramPropalCart,
                this.getChannelGroup(),
                this.offerProgram.area.channel.image,
                this.offerProgram.program.name,
                this.offerProgram.status
              );
              // merge all type of purchases
              mergedArray = this.gridService.mergeAllTypeOfPurchase(mergedArray, programsTime);

              // Check for soReach && create array of soReach
              if (this.offerProgram && this.offerProgram.soReach && this.offerProgram.soReach.length) {
                this.offerProgram.soReach.forEach((soReach) => {
                  let broadcastSoReach = this.gridService.constructCellsSoReaches(
                    this.broadcastByWeek,
                    this.advertisersByWeek[i],
                    purchaseListYear,
                    this.getChannelGroup(),
                    this.offerProgram.area.channel.image,
                    soReach,
                    this.offerProgram.program.name,
                    i
                  );

                  if (broadcastSoReach) {
                    mergedSoReachArray.push(broadcastSoReach);
                  }
                });
              }
            }

            mergedSoReachArray.forEach((soReachArray) => {
              mergedArray = this.gridService.mergeSoReachOfPurchase(mergedArray, soReachArray)
            });

            // management when no purchase on offer program. Set grid length to maxNumberOfAdvertiser
            let maxNbLineYear = 0;
            mergedArray.forEach(function(array) {
              if (array && array.length > maxNbLineYear) {
                  maxNbLineYear ++;
              }
            });
            let purchaseListYear = [];
            for (let i = 0; i < totalNumberOfWeeks; i++) {
              purchaseListYear.push([]);
            }

            if (maxNbLineYear < maxNumberOfAdvertiser) {
                let broadcastTime = this.gridService.constructCells(
                  this.broadcastByWeek,
                  purchaseListYear,
                  totalNumberOfWeeks,
                  maxNumberOfAdvertiser - maxNbLineYear,
                  maxNumberOfAdvertiser,
                  purchasesSoldedByTime,
                  this.offerProgramPropalCart,
                  this.getChannelGroup(),
                  this.offerProgram.area.channel.image,
                  this.offerProgram.program.name,
                  this.offerProgram.status
                );

                mergedArray = this.gridService.mergeAllTypeOfPurchase(mergedArray, broadcastTime);
            }

            this.broadcastsTimes =
              this.deprogrammedConstruct(this.broadcastByWeek, this.offerProgram.program.name, this.offerProgram.area.channel.name);

            // merging deprogrammings and exceptions arrays
            for (let z = 0; z < this.broadcastsTimes.length; z++) {
              if (this.exceptionsByWeek[z]) {
                if (this.exceptionsByWeek[z].length > 0) {
                  this.exceptionsByWeek[z].forEach((exception) => {
                    this.broadcastsTimes[z].push(exception);
                  });
                }
              }
            }

            compact = {
              mergedArray: mergedArray,
              mergedTime: this.broadcastsTimes
            };

            this.onSendBroadcast.emit(compact);
            observer.next(mergedArray);
            break;

          // "Month" tab
          case 'month':
            let totalNumberOfDays = moment().set('year', this.filters.year).set('month', this.filters.month).daysInMonth();
            let startDay = 1;

            // Init totalNumberOfDays by moment
            if (moment(this.filters.broadcasted_from).month() === this.filters.month) {
                startDay = moment(this.filters.broadcasted_from).date();
                totalNumberOfDays = totalNumberOfDays - (startDay - 1);
                if (moment(this.filters.broadcasted_to).month() === this.filters.month) {
                  totalNumberOfDays = moment(this.filters.broadcasted_to).date() - (startDay - 1);
                }
            } else if (moment(this.filters.broadcasted_to).month() === this.filters.month) {
              totalNumberOfDays = moment(this.filters.broadcasted_to).date();
            }

            if (!this.broadcastsByDay) {
              // get broadcast per day collection
              this.broadcastsByDay = this.gridService
                .getBroadcastsByDays(allBroadcastsByProgram, this.filters.year, this.filters.month, startDay);
            }

            // getting exceptions by day
            this.exceptionsByDay = this.gridService.getExceptionByDaysFromSchedules(
              this.schedules,
              this.filters.year,
              this.filters.month,
              startDay,
              totalNumberOfDays
            );

            for (let i = 0; i < purchaseTypes.length; i++) {

              let type = purchaseTypes[i];
              if (!this.purchasesByDay[i]) {
                 this.purchasesByDay[i] = this.gridService.getPurchasesByTimeParts(this.broadcastsByDay, type, this.offerProgram);
              }

              if (type.id === PurchaseType.PURCHASE) {
                purchasesSoldedByTime = this.purchasesByDay[i];
              }
              if (!this.purchasesByDayOrdered[i]) {
                this.purchasesByDayOrdered[i] = this.gridService.orderPurchases(
                  this.broadcastsByDay,
                  this.purchasesByDay[i],
                  totalNumberOfDays,
                  this.gameModuleList
                );
              }
              if (!this.advertisersByDay[i]) {
                this.advertisersByDay[i] = this.gridService.insertPurchasesInGrid(this.purchasesByDayOrdered[i], totalNumberOfDays, type);
              }

              // get max number of line
              this.gridService.setCurrentLinesGroup(type.id); // set group to add copart for "achat" type only
              let maxOfLines: number = this.gridService.getMaxLinesOfGridGroup(this.advertisersByDay[i]);
              let programsTime = this.gridService.constructCells(
                this.broadcastsByDay,
                this.advertisersByDay[i],
                totalNumberOfDays,
                maxOfLines,
                maxNumberOfAdvertiser,
                purchasesSoldedByTime,
                this.offerProgramPropalCart,
                this.getChannelGroup(),
                this.offerProgram.area.channel.image,
                this.offerProgram.program.name,
                this.offerProgram.status
              );

              // merge all type of purchases

              mergedArray = this.gridService.mergeAllTypeOfPurchase(mergedArray, programsTime);

              // Check for soReach && create array of soReach
              if (this.offerProgram && this.offerProgram.soReach && this.offerProgram.soReach.length) {
                this.offerProgram.soReach.forEach((soReach) => {
                  let broadcastSoReach = this.gridService.constructCellsSoReaches(
                    this.broadcastsByDay,
                    this.advertisersByDay[i],
                    purchaseList,
                    this.getChannelGroup(),
                    this.offerProgram.area.channel.image,
                    soReach,
                    this.offerProgram.program.name,
                    i
                  );

                  if (broadcastSoReach) {
                    mergedSoReachArray.push(broadcastSoReach);
                  }
                });
              }
            }
            mergedSoReachArray.forEach((soReachArray) => {
              mergedArray = this.gridService.mergeSoReachOfPurchase(mergedArray, soReachArray)
            });
            // management when no purchase on offer program. Set grid length to maxNumberOfAdvertiser
            let maxNbLine = 0;
            mergedArray.forEach(function(array) {
              if (array && array.length > maxNbLine) {
                  maxNbLine ++;
              }
            });
            let purchaseList = [];
            for (let i = 0; i < totalNumberOfDays; i++) {
              purchaseList.push([]);
            }

            if (maxNbLine < maxNumberOfAdvertiser) {
                let broadcastTime = this.gridService.constructCells(
                  this.broadcastsByDay,
                  purchaseList,
                  totalNumberOfDays,
                  maxNumberOfAdvertiser - maxNbLine,
                  maxNumberOfAdvertiser,
                  purchasesSoldedByTime,
                  this.offerProgramPropalCart,
                  this.getChannelGroup(),
                  this.offerProgram.area.channel.image,
                  this.offerProgram.program.name,
                  this.offerProgram.status
                );
                mergedArray = this.gridService.mergeAllTypeOfPurchase(mergedArray, broadcastTime);
            }

            this.broadcastsTimes =
              this.deprogrammedConstruct(this.broadcastsByDay, this.offerProgram.program.name, this.offerProgram.area.channel.name);
            this.offerProgramService.allBroadcast.next(mergedArray);

            // merging deprogrammings and exceptions arrays
            for (let z = 0; z < this.broadcastsTimes.length; z++) {
              if (this.exceptionsByDay[z]) {
                if (this.exceptionsByDay[z].length > 0) {
                  this.exceptionsByDay[z].forEach((exception) => {
                    this.broadcastsTimes[z].push(exception);
                  });
                }
              }
            }

            compact = {
              mergedArray: mergedArray,
              mergedTime: this.broadcastsTimes
            };

            this.onSendBroadcast.emit(compact);
            observer.next(mergedArray);
            break;
          }
        });
      }
}
