
import {map,  catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Target } from '../../resource/target.resource';
import { FilteredItem } from '../../resource/filteredItem.resource';
import { environment } from '../../../environments/environment';
import { UtilitiesHandler } from '@service/utilities-handlers/utilitiesHandlers';

import { Observable } from 'rxjs';

@Injectable()
export class TargetService {
  private route: string = 'target';

  constructor(
    private http: HttpClient,
    private utilitiesHandler: UtilitiesHandler
  ) { }

  /**
   * Get list of targets for filter
   *
   * @param {Object} [filters=null]
   * @returns {Observable<FilteredItem[]>}
   * @memberof TargetService
   */
  getListForFilter(filters: Object = null): Observable<FilteredItem[]> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params}).pipe(
      map((data: any) =>
        data._embedded.target
          .map((jsonTarget: any) =>
            new Target(jsonTarget)
          )
          .map((target: Target) =>
            new FilteredItem(target)
          )
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )

  }
}
