
import {map,  catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TrailerPeriodicity } from '../../resource/trailerPeriodicity.resource';
import { UtilitiesHandler } from '@service/utilities-handlers/utilitiesHandlers';
import { environment } from '../../../environments/environment';

import { Observable } from 'rxjs';

@Injectable()
export class TrailerPeriodicityService {
  private route: string = 'trailer_periodicity';

  constructor(
    private http: HttpClient,
    private utilitiesHandler: UtilitiesHandler
  ) { }

  /**
   * Get list of trailer periodicity
   *
   * @returns {Observable<TrailerPeriodicity[]>}
   * @memberof TrailerPeriodicityService
   */
  getList(): Observable<TrailerPeriodicity[]> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;

    return this.http
      .get(api_base_url).pipe(
      map((data: any) =>
        data._embedded.trailer_periodicity
          .map((jsonTrailerPeriodicity: any) =>
            new TrailerPeriodicity(jsonTrailerPeriodicity)
          )
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }
}
