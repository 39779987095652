import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { GrpService } from '../../../service/grp/grp.service';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PropalCart } from '../../../resource/availability/propal-cart.resource';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';

@Component({
  selector: 'app-audience-indicators-cart',
  templateUrl: './audience-indicators-cart.component.html',
  styleUrls: ['./audience-indicators-cart.component.scss'],
})
export class AudienceIndicatorsCartComponent implements  OnInit, OnDestroy {
  @Input() filters;
  @Input() target;
  @Input() propalCart: PropalCart;

  totalSelectedGrp: number = 0;
  totalSelectedGrpAlert: boolean =  false;
  totalCouverture: number = null;
  totalRepetition: number = null;
  totalBudget: number = null;
  startWeek: number = null;
  duration: number = null;
  listClickOffer: Object = {};
  destroyGrpSource$: Subject<boolean> = new Subject<boolean>();
  opened: boolean = false;
  loading: boolean = false;
  available: boolean = true;

  constructor(
    private grpService: GrpService,
    private customToastrService: CustomToastrService
  ) { }

  ngOnInit() {
    this.grpService.totalSelectedGrpSource$
      .pipe(
        takeUntil(this.destroyGrpSource$),
        distinctUntilChanged()
      )
      .subscribe(data => {
        let alert: number = 0;

        this.listClickOffer[data.id] = data;
        this.totalSelectedGrp = 0;
        this.totalSelectedGrpAlert = false;
        Object.keys(this.listClickOffer).forEach(id => {
          this.totalSelectedGrp += this.listClickOffer[id].total !== -1 ? this.listClickOffer[id].total : 0;
          if (this.listClickOffer[id].alert !== '') {
            alert++;
          }
          if (this.listClickOffer[id].total < 0) {
            this.totalSelectedGrpAlert = true;
          }
        });
        if (alert === 0) {
          this.available = true;
        } else {
          this.available = false;
        }
        this.checkProgramOffer(data.id);
      }, (error) => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });
  }

  ngOnDestroy() {
    this.destroyGrpSource$.next(true);
    this.destroyGrpSource$.unsubscribe();
  }

  checkProgramOffer(idProgram: number): void {
    let itemsOffer: Array<string> = Object.keys(this.listClickOffer);
    let offers: Array<string> = new Array;

    itemsOffer.forEach((id: string) => {
      if (this.listClickOffer[id] && (this.listClickOffer[id].total > 0)) {
        offers.push(id);
      }
    });
    if (offers.length === 1 && !this.totalSelectedGrpAlert && this.available) {
      const offer = this.listClickOffer[offers[0]];
      const id: number = idProgram;
      const year: number = this.filters.year;
      const target: number = this.target.value;
      const startWeek: number = offer.startWeek + 1;
      const duration: number = offer.duration;
      this.loading = true;

      this.grpService.getCouvRepetOffer(id, year, startWeek, duration)
        .pipe(takeUntil(this.destroyGrpSource$))
        .subscribe((data) => {
          let result = data.offer.find((element) => element.CODCIBLE === target);
          this.loading = false;

          if (result) {
            result.COUVA ? this.totalCouverture = result.COUVA : this.totalCouverture = null;
            result.COUVA && result.GRPA ? this.totalRepetition = result.GRPA / result.COUVA : this.totalRepetition = null;
          } else {
            this.totalCouverture = null;
            this.totalRepetition = null;
          }
        }, (error) => {
          this.loading = false;
          this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
        })
    } else {
      this.totalCouverture = null;
      this.totalRepetition = null;
      this.totalBudget = null;
    }
  }

  /**
   * Open/Close card
   *
   * @returns {void}
   * @memberof AudienceIndicatorsCartComponent
   */
  toggleAudienceIndicators(): void {
    this.opened = !this.opened;
  }
}
