import { Employee } from './employee.resource';
import { Advertiser } from './advertiser.resource';
import { PurchaseProgram } from './purchaseProgram.resource';
import { FilteredItem } from './filteredItem.resource';

const AVAILABLE           = '0';
const PURCHASE            = '1';
const OPTION              = '2';
const CANCELED            = '3';
const PARTIAL_PURCHASE    = '4';
const ALERT               = '5';
const IN_PROGRESS         = '6';
const SENT                = '7';
const REFUSED             = '8';
const EXPIRED             = '9';
const RECONDUCT_PRIORITY  = '10';

export interface JsonPurchaseType {
    id: string;
    name: string;
}

export class PurchaseType {
    public static AVAILABLE             = AVAILABLE;
    public static PURCHASE              = PURCHASE;
    public static OPTION                = OPTION;
    public static CANCELED              = CANCELED;
    public static PARTIAL_PURCHASE      = PARTIAL_PURCHASE;
    public static ALERT                 = ALERT;
    public static IN_PROGRESS           = IN_PROGRESS;
    public static SENT                  = SENT;
    public static REFUSED               = REFUSED;
    public static EXPIRED               = EXPIRED;
    public static RECONDUCT_PRIORITY    = RECONDUCT_PRIORITY;

    public static TYPES = [
        { id: PURCHASE, label: 'achat' },
        { id: OPTION, label: 'option' },
        { id: ALERT, label: 'alerte' },
        { id: RECONDUCT_PRIORITY, label: 'reconduct' },
    ];

    public static PURCHASE_FORM_TYPES = [
        IN_PROGRESS,
        SENT,
        ALERT,
        OPTION,
        PURCHASE,
        REFUSED,
        CANCELED,
        EXPIRED,
        RECONDUCT_PRIORITY
    ];

    public id: string;
    public name: string;

    constructor(jsonPurchaseType: JsonPurchaseType) {
        this.id = jsonPurchaseType.id;
        this.name = jsonPurchaseType.name;
    }
}

export class PurchaseParams {

    public id: number;
    public name: string;
    public commercial: FilteredItem;
    public advertiser: FilteredItem;
    public soReachPurchase: number;
    public startDate: string;
    public endDate: string;
    public purchasedPrograms: Array<PurchaseProgram>;

    constructor(id: number, name: string, commercial: Employee, advertiser: Advertiser, soReachPurchase: number, startDate: string,
        endDate: string, purchasedPrograms: Array<PurchaseProgram>) {
        this.id = id;
        this.name = name;
        this.commercial = new FilteredItem(commercial);
        this.advertiser = new FilteredItem(advertiser);
        this.soReachPurchase = soReachPurchase || null;
        this.startDate = startDate || null;
        this.endDate = endDate || null;
        this.purchasedPrograms = purchasedPrograms || [];
    }
}
