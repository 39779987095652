import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { ScheduleException } from '../../resource/schedule/schedule-exception.resource';
import { environment } from '../../../environments/environment';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';
import { UtilitiesHandler } from '@service/utilities-handlers/utilitiesHandlers';

import { Observable } from 'rxjs';

@Injectable()
export class ScheduleExceptionService {
 private route: string = 'schedule_exception';

 constructor(
    private customToastrService: CustomToastrService,
    private http: HttpClient,
    private utilitiesHandler: UtilitiesHandler
  ) { }

  /**
   * Get schedule exception with ID
   *
   * @param {(string | number)} id
   * @returns {Observable<ScheduleException>}
   * @memberof ScheduleExceptionService
   */
  get(id: string | number): Observable<ScheduleException> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}/${id}`;

    return this.http
      .get(api_base_url).pipe(
      map((data: any) =>
        new ScheduleException(data)
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get list of schedule exception
   *
   * @param {Object} filters
   * @returns {Observable<ScheduleException[]>}
   * @memberof ScheduleExceptionService
   */
  getList(filters: Object): Observable<ScheduleException[]> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params}).pipe(
      map((data: any) =>
        data._embedded.schedule_exception
          .map((jsonScheduleException: any) =>
            new ScheduleException(jsonScheduleException)
          )
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Post schedule exception
   *
   * @param {ScheduleException} scheduleException
   * @returns {Observable<Object>}
   * @memberof ScheduleExceptionService
   */
  public post(scheduleException: ScheduleException): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;

    return this.http
      .post(api_base_url, this.extract(scheduleException))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Post collection of schedule
   *
   * @param {ScheduleException[]} scheduleException
   * @returns {Observable<Object>}
   * @memberof ScheduleExceptionService
   */
  public postCollection(scheduleException: ScheduleException[]): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;

    return this.http
      .post(api_base_url, this.extractCollection(scheduleException, false))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Edit schedule exception
   *
   * @param {ScheduleException} scheduleException
   * @returns {Observable<Object>}
   * @memberof ScheduleExceptionService
   */
  public edit(scheduleException: ScheduleException): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}/${scheduleException.id}`;

    return this.http
      .put(api_base_url, this.extract(scheduleException))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Edit collection
   *
   * @param {ScheduleException[]} scheduleException
   * @returns {Observable<Object>}
   * @memberof ScheduleExceptionService
   */
  public editCollection(scheduleException: ScheduleException[]): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;

    return this.http
      .put(api_base_url, this.extractCollection(scheduleException, true))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Remove schedule exception
   *
   * @param {ScheduleException} scheduleException
   * @returns {Observable<Object>}
   * @memberof ScheduleExceptionService
   */
  public remove(scheduleException: ScheduleException): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}/${scheduleException.id}`;

    return this.http
      .delete(api_base_url)
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Transforme to underscore
   *
   * @param {*} string
   * @returns {string}
   * @memberof ScheduleExceptionService
   */
  public toUnderscore(string): string {
    return string.replace(/([A-Z])/g, function($1) {return '_' + $1.toLowerCase(); });
  };

  /**
   * Extract
   *
   * @param {ScheduleException} scheduleException
   * @param {boolean} [withId=false]
   * @returns {Object}
   * @memberof ScheduleExceptionService
   */
  public extract(scheduleException: ScheduleException, withId: boolean = false): Object {
    let object = {
        label: scheduleException.label,
        except_start_period: scheduleException.exceptStartPeriod,
        except_end_period: scheduleException.exceptEndPeriod,
        except_start_time: scheduleException.exceptStartTime,
        except_end_time: scheduleException.exceptEndTime,
        schedule: scheduleException.schedule.id,
        parent: scheduleException.parent && scheduleException.parent.id ? scheduleException.parent.id : null
      };

    if (withId) {
      object['id'] = scheduleException.id ;
    }

    return object;
  }

  /**
   * Extract collection
   *
   * @param {*} array
   * @param {boolean} [withId=false]
   * @returns {Array<any>}
   * @memberof ScheduleExceptionService
   */
  public extractCollection(array, withId: boolean = false): Array<any> {
    let result = [];
    array.forEach(item => {
      result.push(this.extract(item, withId));
    });

    return result;
  }

  /**
   * Manage exceptions
   *
   * @param {*} schedule
   * @param {*} [oldSchedule=null]
   * @returns {Observable<any>}
   * @memberof ScheduleExceptionService
   */
  public manageExceptions(schedule, oldSchedule = null): Observable<any> {
    return Observable.create(observer => {

      schedule.scheduleExceptions.forEach(exception => {
        exception.schedule = schedule;
      })

      let totalActions = 0;
      let actionCount = 0;
      let exceptionsToDelete = [];

      let exceptionsToAdd = schedule.scheduleExceptions.filter((exception) => exception.id === null);
      let exceptionsToEdit = schedule.scheduleExceptions.filter((exception) => exception.id !== null);
      let oldExceptions = oldSchedule ? oldSchedule.scheduleExceptions : [];

      oldExceptions.forEach(oldException => {
        let idFound = schedule.scheduleExceptions.filter((exception) => exception.id === oldException.id)
        if (!idFound.length) {
          exceptionsToDelete.push(oldException);
        }
      });

      if (exceptionsToAdd.length) {
          totalActions++;
          this.postCollection(exceptionsToAdd)
            .subscribe(() => {
              if (this.checkActionCount(totalActions, ++actionCount)) {
                observer.next();
              }
            }, (error) => {
              this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            });
      }

      if (exceptionsToEdit.length) {
          totalActions++;
          this.editCollection(exceptionsToEdit)
            .subscribe(() => {
              if (this.checkActionCount(totalActions, ++actionCount)) {
                observer.next();
              }
            }, (error) => {
              this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            });
      }

      if (exceptionsToDelete.length) {
        exceptionsToDelete.forEach(exception => {
          totalActions++;
          this.remove(exception)
            .subscribe(() => {
              if (this.checkActionCount(totalActions, ++actionCount)) {
                observer.next();
              }
            }, (error) => {
              this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            });
        });
      }
    });
  }

  /**
   * Check action count
   *
   * @private
   * @param {*} totalActions
   * @param {*} actionCount
   * @returns {boolean}
   * @memberof ScheduleExceptionService
   */
  private checkActionCount(totalActions, actionCount): boolean {
    return totalActions === actionCount;
  }
}
