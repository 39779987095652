<div fxLayout="row">
  <div class="header-offer" [ngClass]="{'margin-bottom-60': lastOffer && !expanded}" (click)="expandOffer()" fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="row">
        <div *ngFor="let logo of offerProgram.images" class="logo" fxLayout="row" fxLayoutGap="5px">
            <img class="image-width" preload fade [src]="path + logo">
        </div>
      </div>
      <div fxLayout="row">
        <mat-icon *ngIf="!expanded">keyboard_arrow_down</mat-icon>
        <mat-icon *ngIf="expanded">keyboard_arrow_up</mat-icon>
      </div>
    </div>
    <h3 class="margin-h align-header title-card">{{ offerProgram.name }}</h3>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <h4 class="margin-h thin">{{ offerProgram.globalPrice | number:'0.0-2':'fr' }}€ {{ offerProgram.budgetType | lowercase }}</h4>
      <mat-icon class="icon-right" [matTooltip]="budgetToltip" matTooltipClass="styleTooltip">help_outline</mat-icon>
    </div>
    <div class="margin-top" fxLayout="row" fxLayoutGap="10px">
      <span class="thin">Date :</span>
      <span>{{ offerProgram.periodStartDate | date:'d/M/yy' }} au {{ offerProgram.periodEndDate | date:'d/M/yy' }}</span>
  </div>
    <div class="margin-top" fxLayout="row" fxLayoutGap="10px">
      <span class="thin">Nombre de place(s) :</span>
      <span *ngIf="offerProgram.maxNumberOfAdvertiser" class="bold">{{ offerProgram.maxNumberOfAdvertiser }}</span>
    </div>
  </div>
  <div class="box-grid">
    <mat-progress-bar *ngIf="loading" class="bar" mode="indeterminate"></mat-progress-bar>
    <app-grid
      [offerProgram]="offerProgram"
      [gridData]="gridData"
      [header]="header"
      [headerMonth]="headerMonth"
      [loading]="loading"
      [mode]="true"
      [grpOffer]="grpOffer"
      [target]="target"
      [broadcastsTimes]="broadcastsTimes"
      *ngIf="gridData && grpOffer">
    </app-grid>
  </div>
</div>
