
import {map,  catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { AdvertisingPackage } from '../../resource/advertising-package.resource';
import { environment } from '../../../environments/environment';
import { UtilitiesHandler } from '@service/utilities-handlers/utilitiesHandlers';

import { Observable } from 'rxjs';

@Injectable()
export class AdvertisingPackageService {

  constructor(
    private http: HttpClient,
    private utilitiesHandler: UtilitiesHandler
  ) { }

  /**
   * Get game module with ID & getByPurchaseId()
   *
   * @param {*} purchaseId
   * @returns {Observable<AdvertisingPackage>}
   * @memberof AdvertisingPackageService
   */
  getGameModuleByPurchaseId(purchaseId): Observable<AdvertisingPackage> {
    return this.getByPurchaseId(purchaseId);
  }

  /**
   * Get purchase Advertising package with ID
   *
   * @param {*} purchaseId
   * @returns {Observable<AdvertisingPackage>}
   * @memberof AdvertisingPackageService
   */
  getByPurchaseId(purchaseId): Observable<AdvertisingPackage> {
    let filters = {};
    filters['is_game'] = 1;
    filters['purchase_id'] = purchaseId;

    const api_base_url: string = `${environment.api_base_url}/advertising_package`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params}).pipe(
      map((data: any) =>
        data._embedded.advertising_package
          .map((jsonAdvertisingPackage: any) =>
            new AdvertisingPackage(jsonAdvertisingPackage)
          )
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get the list of Advertising packqge
   *
   * @param {Object} [filters=null]
   * @returns {Observable<AdvertisingPackage[]>}
   * @memberof AdvertisingPackageService
   */
  getList(filters: Object = null): Observable<AdvertisingPackage[]> {
    const api_base_url: string = `${environment.api_base_url}/advertising_package`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params}).pipe(
      map((data: any) =>
        data._embedded.advertising_package
          .map((jsonChannel: any) =>
            new AdvertisingPackage(jsonChannel)
          )
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }
}
