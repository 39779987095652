import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { CampaignService } from '@service/campaign/campaign.service';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';
import { AutocompleteService } from '@service/utilities/autocomplete.service';
import { LotDotationService } from '@service/lot-dotation/lot-dotation.service';

import { FilteredItem } from '../../../../resource/filteredItem.resource';
import { CustomValidators } from '../../../../validator/custom-validators';

import { Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, takeUntil, filter } from 'rxjs/operators';

@Component({
  selector: 'app-new-dotation-purchase',
  templateUrl: './new-dotation-purchase.component.html',
  styleUrls: ['./new-dotation-purchase.component.scss']
})
export class NewDotationPurchaseComponent implements OnInit, OnDestroy {
  public campaignYears = [];
  public loading = false;
  public newDotationPurchaseForm: FormGroup;
  public filteredCampaignItems: Observable<FilteredItem[]>;

  private componentDestroyed$: Subject<void> = new Subject();
  private campaginsList: FilteredItem[];

  constructor(
    public dotationService: LotDotationService,
    public autocompleteService: AutocompleteService,
    public dialogRef: MatDialogRef<NewDotationPurchaseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private campaignService: CampaignService,
    private customToastrService: CustomToastrService,
  ) { }

  ngOnInit() {
    this.initForm();
    this.getFilteredCampaigns();
    this.setValueChanges();
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.unsubscribe();
  }

  /**
   * Init new dotation purchase form
   *
   * @returns {void}
   * @memberof NewDotationPurchaseComponent
   */
  public initForm(): void {
    this.newDotationPurchaseForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(50)]],
      product: [
        {
          value: {
            display: this.data.product.display ? this.data.product.display : '',
            id: this.data.product.id ? this.data.product.id : ''
          },
          disabled: true
        },
        [Validators.required]
      ],
      campaign: ['', [Validators.required, CustomValidators.inFilteredItemValidator]],
    });
  }

  /**
   * Close NewDotationPurchaseComponent dialog
   *
   * @returns {void}
   * @memberof NewDotationPurchaseComponent
   */
  cancel(): void  {
    this.dialogRef.close();
  }

  /**
   * Trigger the API request to create a new dotation purchase and its related database objects
   *
   * @returns {void}
   * @memberof NewDotationPurchaseComponent
   */
  save(): void {
    if (this.newDotationPurchaseForm.valid) {
      this.loading = true;

      // Post params
      const params = {
        name: this.newDotationPurchaseForm.get('name').value,
        product: this.newDotationPurchaseForm.get('product').value.id,
        campaignNum: this.newDotationPurchaseForm.get('campaign').value.entity.campaignNumber,
        campaignYear: this.newDotationPurchaseForm.get('campaign').value.entity.year
      };

      this.dotationService.createDotationPurchase(params)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((dotationPurchase) => {
          this.loading = false;
          this.customToastrService.displayToastr('SUCCESS', 'Création effectuée avec succès!');
          this.dialogRef.close(dotationPurchase);
        }, () => {
          this.loading = false;
          this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue!');
        });
    }
  }

  /**
   * Generate 5 last years list
   *
   * @private
   * @returns {void}
   * @memberof NewDotationPurchaseComponent
   */
  private generateCampaignYears(): void {
    let max = new Date().getFullYear();
    let min = max - 5;

    for (let i = max; i >= min; i--) {
      this.campaignYears.push(i)
    }
  }

  /**
   * Set value change listener on newDotationPurchaseForm
   *
   * @private
   * @returns {void}
   * @memberof NewDotationPurchaseComponent
   */
  private setValueChanges(): void {
    // Value change on campaign input
    this.newDotationPurchaseForm.controls['campaign'].valueChanges
      .pipe(
        startWith(null),
        debounceTime(500),
        filter((value) => (typeof value === 'string')),
        distinctUntilChanged(),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((value) => {
        this.loading = true;
        this.filteredCampaignItems = of([]);

        if (value && typeof value === 'string') {
          // Get dotation campaigns list linked to the product id
          if (this.campaginsList && this.campaginsList.length > 0) {
            this.filteredCampaignItems = of(
              this.autocompleteService.filterItems(value, this.campaginsList)
            );
          }
        }

        this.loading = false;
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue!');
        this.loading = false;
      });
  }

  /**
   * Get filtered campaigns
   *
   * @private
   * @returns {void}
   * @memberof NewDotationPurchaseComponent
   */
  private getFilteredCampaigns(): void {
    this.loading = true;
    this.generateCampaignYears();

    // Get dotation campaigns list linked to the product id
    if (
      this.newDotationPurchaseForm.controls['product'] != null
      && this.newDotationPurchaseForm.controls['product'].value !== ''
      && this.newDotationPurchaseForm.controls['product'].value.id
      && typeof this.newDotationPurchaseForm.controls['product'].value.id === 'number'
    ) {

      this.campaignService.getListForFilter({
        product_id: this.newDotationPurchaseForm.controls['product'].value.id,
        campaign_type: ['DOT', 'ASL'], // campaign type : "dotation and "droit d'asile"
        year: this.campaignYears
      })
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((campaignItems: FilteredItem[]) => {
          this.campaginsList = campaignItems;
          this.filteredCampaignItems = of(campaignItems);
          this.loading = false;
        }, () => {
          this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue!');
          this.loading = false;
        });
    }
  }
}
