<div fxLayout="row" fxLayoutAlign="space-between center">
    <h1>Les Lots</h1>
    <div fxLayout="row">
        <mat-button-toggle-group [formControl]="onglet">
            <mat-button-toggle value="management">Création</mat-button-toggle>
            <mat-button-toggle value="monitoring">Suivi</mat-button-toggle>
            <mat-button-toggle value="purchase">Contrats</mat-button-toggle>
        </mat-button-toggle-group>
    </div>
</div>

<div *ngIf="onglet.value === 'management'">
    <app-lots-form-advertiser></app-lots-form-advertiser>
</div>

<div *ngIf="onglet.value === 'monitoring'">
    <app-lots-monitoring></app-lots-monitoring>
</div>

<div *ngIf="onglet.value === 'purchase'">
    <app-lots-purchase></app-lots-purchase>
</div>
