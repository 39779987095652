<p class="alert-danger" *ngIf="!purchase.id">
    Vous devez enregister les informations principales du dossier avant de pouvoir continuer.
</p>
<br>
<div *ngIf="!programIsDeactivated" class="flex-display">
    <div class="no-margin">
        <mat-form-field>
            <input matInput
                [matDatepicker]="broadcastStartDate"
                [formControl]="broadcastFrom"
                [max]="addFilter.broadcasted_to"
                placeholder="Du">
            <mat-datepicker-toggle matSuffix [for]="broadcastStartDate"></mat-datepicker-toggle>
            <mat-datepicker #broadcastStartDate></mat-datepicker>
        </mat-form-field>
    </div>

    <div>
        <mat-form-field>
            <input matInput
                [matDatepicker]="broadcastEndDate"
                [formControl]="broadcastTo"
                [min]="addFilter.broadcasted_from"
                placeholder="Au">
            <mat-datepicker-toggle matSuffix [for]="broadcastEndDate"></mat-datepicker-toggle>
            <mat-datepicker #broadcastEndDate></mat-datepicker>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between center">
        <button mat-flat-button (click)="addBroadcasts()" class="add-broadcast" [disabled]="adding || !purchase.id || !addFilter.broadcasted_from || !addFilter.broadcasted_to"
            class="button-flat black">
            Ajouter
        </button>
    </div>
</div>
<div *ngIf="programIsDeactivated" class="flex-display">
    <p class="programIsDeactivated">Impossible d'ajouter des diffusions, cette émission est désactivée</p>
</div>

