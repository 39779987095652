import { ApiError } from '../../../../../resource/api.error.resource';
import { PurchaseBudgetService } from '../../../../../service/purchase-budget/purchase-budget.service';
import { PurchaseService } from '../../../../../service/purchase/purchase.service';
import { PurchaseGrpService } from '../../../../../service/purchase-grp/purchase-grp.service';
import { PurchaseProgramService } from '../../../../../service/purchase-program/purchase-program.service';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from '../../../../../shared/dialog/confirm-dialog/confirm-dialog.component';
import { BroadcastPurchasedService } from '../../../../../service/broadcast-purchased/broadcast-purchased.service';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { PurchaseProgram } from '../../../../../resource/purchaseProgram.resource';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-offer-program-list',
  templateUrl: './offer-program-list.component.html',
  styleUrls: ['./offer-program-list.component.scss']
})
export class OfferProgramListComponent implements OnInit, OnChanges {

  @Input() purchaseId: string;
  @Input() purchasePrograms: PurchaseProgram[];
  @Input() selectedPurchaseProgram: PurchaseProgram;

  @Output() selectedPurchaseProgramEmitter = new EventEmitter<PurchaseProgram>();
  @Output() deletedPurchaseProgramEmitter = new EventEmitter<any>();

  public deleting: boolean;
  public dialogRef;
  public index: number = -1;
  public listWarning: Object = new Object();

  private componentDestroyed$: Subject<any> = new Subject();

  constructor(
    private dialog: MatDialog,
    private purchaseSevice: PurchaseService,
    private purchaseBudgetService: PurchaseBudgetService,
    private purchaseProgramService: PurchaseProgramService,
    private broadcastPurchasedService: BroadcastPurchasedService,
    private purchaseGrpService: PurchaseGrpService,
    private customToastrService: CustomToastrService,
  ) {}

  ngOnInit() { }

  ngOnChanges(changes: any): void {
    if (changes.selectedPurchaseProgram) {
      this.selectedPurchaseProgram = changes.selectedPurchaseProgram.currentValue;
    }
    if (this.purchasePrograms && this.purchasePrograms.length > 0) {
      this.getWarning();
    }
  }

  public onSelect(purchaseProgram: PurchaseProgram): void {
    if (this.deleting || this.isSelected(purchaseProgram)) {
      return null;
    }

    this.selectedPurchaseProgram = purchaseProgram;
    this.selectedPurchaseProgramEmitter.emit(purchaseProgram);
    this.broadcastPurchasedService.reset();
  }

  public isSelected(purchaseProgram: PurchaseProgram): boolean {
    return this.selectedPurchaseProgram &&
        purchaseProgram.id === this.selectedPurchaseProgram.id ? true : false;
  }

  public deletePurchaseProgram(purchaseProgram: PurchaseProgram, index: number) {

    if (this.deleting || !this.isSelected(purchaseProgram)) {
      return null;
    }

    if (purchaseProgram.id < 1) {
      return null;
    }

    this.dialogRef = this.dialog.open(ConfirmDialogComponent);
    let instance = this.dialogRef.componentInstance;
    instance.type = 'suppression';
    instance.resource = 'émission';
    instance.dialogRef = this.dialogRef;

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.index = index;
        this.deleting = true;
        this.purchaseProgramService.delete(purchaseProgram).subscribe(() => {
            this.customToastrService.displayToastr('SUCCESS', 'Emission supprimée avec succès');
            this.deletedPurchaseProgramEmitter.emit(true);
            this.broadcastPurchasedService.reset();
            this.purchaseBudgetService.updateGlobalBudgetLoading(true);
            this.purchaseSevice.getUpdatedPurchase();
            this.purchaseGrpService.updatePurchaseGrp(this.purchaseSevice.purchase.id);
            this.deleting = false;
            this.index = -1;
        },
        error => {
            this.deleting = false;
            if (error && error.detail) {
              let apiError = new ApiError(error.detail);
              this.customToastrService.displayToastr('ERROR', apiError.message);
            } else {
              this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue');
            }
        });
      }
    });
  }

  /**
   * Get List of Warning
   *
   * @private
   * @memberof OfferProgramListComponent
   */
  private getWarning(): void {
    let groupeId: Array<number> = new Array();

    this.purchasePrograms.forEach((data) => {
      if (data && data.id) {
        groupeId.push(data.id);
      }
    });

    this.purchaseSevice.getListWarning(groupeId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.listWarning = new Object();
        data.forEach(element => {
          this.listWarning[element.purchaseProgramId] = true;
        });
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });
  }
}
