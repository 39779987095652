import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class GridHeaderService {

    public getDaysOfMonth(year, month, fromDate, toDate) {
        let result = [];
        let days = ['D', 'L', 'M', 'M', 'J', 'V', 'S'];
        let totalDays = moment().set('year', year).set('month', month).daysInMonth();
        let limitDay = 1;

        if (moment(fromDate).month() === month) {
            limitDay = moment(fromDate).date();
        }
        if (moment(toDate).month() === month) {
            totalDays = moment(toDate).date()
        }

        for (let day = limitDay; day <= totalDays; day++) {
            let dayDate = new Date(year, month, day);
            let strDay = days[dayDate.getDay()];
            let dayNumber = day < 10 ? '0' + day : day;
            result.push(strDay + dayNumber);
        }
        return result;
    }

    public getWeeksOfYear(totalWeek, firstWeek, firstMonth, year) {
        let result = [];

        if ((firstWeek === 52 || firstWeek === 53) && firstMonth === 0) { // If first week start with 52/53 we push and start at 1 after
          result.push(`S${firstWeek}`);
          firstWeek = 1;
          totalWeek -= 1;
        }

        for (let week = firstWeek; week < (firstWeek + totalWeek); week++) {
            const weekNumber = week < 10 ? (week === 0 ? moment(moment(year.toString()), 'DD-MM-YYYY').week() : week) :
              (week === 53 ? moment(moment(year.toString()).add(52, 'w'), 'DD-MM-YYYY').week() : week);

            result.push((weekNumber >= 10) ? ('S' + weekNumber) : ('S0' + weekNumber));
        }

        return result;
    }

    public getMonthHeader(year, fromDate, toDate) {
        let headerMonth = [];
        for (let month = moment(fromDate).month(); month <= moment(toDate).month(); month++) {
            let numberOfDay = moment().set('year', year).set('month', month).daysInMonth();

            if (month === moment(fromDate).month() && month === moment(toDate).month()) {
                numberOfDay = (moment(toDate).date() - moment(fromDate).date()) + 1;
            } else if (month === moment(fromDate).month()) {
                numberOfDay = numberOfDay - moment(fromDate).date() + 1;
            } else if (month === moment(toDate).month()) {
                numberOfDay = moment(toDate).date();
            }

            let monthSize = numberOfDay / 7;
            let label = moment().month(month).format('MMMM');
            headerMonth.push({
                name: label,
                value: monthSize
            });
        }

        return headerMonth;
    }
}
