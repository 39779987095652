import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { CustomToastrService } from '@service/toastr/custom-toastr.service';
import { OfferProgramService } from '@service/offer-program/offer-program.service';

import { Category } from '../../../resource/category.resource';
import { Area } from '../../../resource/area.resource';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Moment } from 'moment';
import * as moment from 'moment';

@Component({
  selector: 'app-export-conducteur',
  templateUrl: './export-conducteur.component.html',
  styleUrls: ['./export-conducteur.component.scss']
})
export class ExportConducteurComponent {
  public isSaving = false;
  public exportConducteurForm: FormGroup;

  private componentDestroyed$: Subject<any> = new Subject();

  constructor(
    public dialogExportConducteurRef: MatDialogRef<ExportConducteurComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private customToastrService: CustomToastrService,
    private offerProgramService: OfferProgramService,
    private fb: FormBuilder) {
    this.exportConducteurForm = this.fb.group({
      periodStartDate: [null, [Validators.required]],
      periodEndDate: [null, [Validators.required]],
      option: [null],
      alert: [null]
    });
  }

  /**
   * Close modal
   */
  close(): void {
    this.dialogExportConducteurRef.close();
  }

  /**
   * Export conducteur with parameter and download
   */
  generateConducteur(): void {
    this.isSaving = true;

    const params = {
      start_period: moment(this.exportConducteurForm.get('periodStartDate').value).format('YYYY-MM-DD'),
      end_period: moment(this.exportConducteurForm.get('periodEndDate').value).format('YYYY-MM-DD'),
      areas: [],
      categories: [],
    };

    if (this.data.program) {
      params['program'] = this.data.program;
    }

    if (this.exportConducteurForm.get('alert').value) {
      params['alert'] = 1;
    }

    if (this.exportConducteurForm.get('option').value) {
      params['option'] = 1;
    }

    this.data.areas.forEach((area: Area) => {
      params.areas.push('\'' + area.id + '\'');
    });

    this.data.categories.forEach((category: Category) => {
      params.categories.push('\'' + category.id + '\'');
    });

    this.offerProgramService.generateXlsxConducteur(params)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        this.isSaving = false;
        this.close();
        this.customToastrService.displayToastr('SUCCESS', 'Fichier Conducteur généré avec succès!');
      }, () => {
        this.isSaving = false;
        this.customToastrService.displayToastr('ERROR', 'Erreur lors da la génération du fichier Conducteur!');
      });
  }

  /**
   * Get Start date from the FormGroup
   *
   * @return {Moment | null}
   */
  get startDateForm(): Moment | null {
    return this.exportConducteurForm.value.periodStartDate;
  }

  /**
   * Get End date from the FormGroup
   *
   * @return {Moment | null}
   */
  get endDateForm(): Moment | null {
    return this.exportConducteurForm.value.periodStartDate;
  }
}
