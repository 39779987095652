<mat-card class="filter-card padding-bottom-10">
    <mat-progress-bar *ngIf="loadingInput.channel || loadingInput.area || loadingInput.category_id || loadingInput.program || loadingInput.offer"
        mode="indeterminate" class="bar"></mat-progress-bar>
    <div class="three-columns-grid" fxLayout="column">
        <!-- 1st row -->
        <div fxLayout="column" fxLayoutGap="20px">
            <span class="title-groupe">Période</span>
            <div fxLayout="row" fxLayoutGap="3%">
                <div class="filter-control">
                    <mat-form-field class="date-full-width">
                        <input
                            matInput
                            [matDatepicker]="startDate"
                            placeholder="Du"
                            (dateChange)="updateFilters()"
                            [formControl]="DateFrom"
                            [max]="DateTo.value"
                            ngDefaultControl>
                            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                        <mat-datepicker #startDate></mat-datepicker>
                    </mat-form-field>
                </div>
                    <div class="filter-control">
                        <mat-form-field class="date-full-width">
                            <input
                                matInput
                                [matDatepicker]="endDate"
                                placeholder="Au"
                                (dateChange)="updateFilters()"
                                [formControl]="DateTo"
                                [min]="DateFrom.value"
                                ngDefaultControl>
                            <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                            <mat-datepicker #endDate></mat-datepicker>
                        </mat-form-field>
                    </div>
            </div>
        </div>
        <div class="divider"></div>
        <!-- 2th row -->
        <div fxLayout="column">
            <span class="title-groupe">Intitulés</span>
            <div fxLayout="row" fxLayoutGap="3%" class="less-margin-10">
                <div class="filter-control">
                    <div>
                        <mat-form-field (click)="openInput('channels')" class="date-full-width">
                            <mat-chip-list #chipListChannel>
                                <mat-chip
                                    *ngFor="let channel of filters.channels"
                                    [selectable]="true"
                                    [removable]="true"
                                    (removed)="remove(channel, 'channels')">
                                    <span>
                                        <img preload fade class="tag" [src]="path + channel.logo" [default]="path + defaultLogo">
                                        <span> {{ channel.display }}  </span>
                                    </span>
                                <mat-icon matChipRemove>clear</mat-icon>
                                </mat-chip>
                                <input
                                    #channelInput
                                    appRemoveAccents
                                    [placeholder]="filters.channels.length ? '+ Ajouter une Chaîne' : 'Chaîne'"
                                    [formControl]="channelsCtrl"
                                    [matAutocomplete]="autoChannel"
                                    [matChipInputFor]="chipListChannel"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="true">
                                <mat-icon matSuffix>search</mat-icon>
                            </mat-chip-list>
                            <mat-autocomplete #autoChannel="matAutocomplete" (optionSelected)="selected($event, 'channels')">
                                <mat-option *ngFor="let channel of filteredChannels | async" [value]="channel">
                                    <img preload fade class="tag" [src]="path + channel.logo" [default]="path + defaultLogo">
                                    {{ channel.display }}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                </div>

                <div class="filter-control">
                    <div>
                        <mat-form-field (click)="openInput('areas')" class="date-full-width">
                            <mat-chip-list #chipListArea>
                                <mat-chip
                                    *ngFor="let area of filters.areas"
                                    [selectable]="true"
                                    [removable]="true"
                                    (removed)="remove(area, 'areas')">
                                    {{ area.display }}
                                <mat-icon matChipRemove>clear</mat-icon>
                                </mat-chip>
                                <input
                                    #areaInput
                                    appRemoveAccents
                                    [placeholder]="filters.areas.length ? '+ Ajouter une région' : 'Région'"
                                    [disabled]="disableArea"
                                    [formControl]="areasCtrl"
                                    [matAutocomplete]="autoArea"
                                    [matChipInputFor]="chipListArea"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="true">
                                <mat-icon matSuffix>search</mat-icon>
                            </mat-chip-list>
                            <mat-autocomplete #autoArea="matAutocomplete" (optionSelected)="selected($event, 'areas')">
                                <mat-option *ngFor="let area of filteredAreas | async" [value]="area">
                                    {{ area.display }}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                </div>
                <div class="filter-control">
                    <div>
                        <mat-form-field (click)="openInput('category_id')" class="inputCategory" class="date-full-width">
                            <mat-chip-list #chipListCategory>
                                <mat-chip
                                    *ngFor="let category of filters.category_id"
                                    [selectable]="true"
                                    [removable]="true"
                                    (removed)="remove(category, 'category_id')">
                                    {{ category.name }}
                                <mat-icon matChipRemove>clear</mat-icon>
                                </mat-chip>
                                <input
                                    #category_idInput
                                    appRemoveAccents
                                    placeholder="Unité de programme"
                                    [disabled]="filters.category_id.length >= 1"
                                    [formControl]="category_idCtrl"
                                    [matAutocomplete]="autoCategory"
                                    [matChipInputFor]="chipListCategory"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="true">
                                <mat-icon matSuffix>search</mat-icon>
                            </mat-chip-list>
                            <mat-autocomplete #autoCategory="matAutocomplete" (optionSelected)="selected($event, 'category_id')">
                                <mat-option *ngFor="let category of filteredCategory | async" [value]="category">
                                    {{ category.display }}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <!-- 3rd row -->
        <div fxLayout="row" fxLayoutGap="3%" class="last-input">
            <div class="filter-control">
                <div>
                    <mat-form-field (click)="openInput('programs')" class="date-full-width">
                        <mat-chip-list #chipListProgram>
                            <mat-chip
                                *ngFor="let program of filters.programs"
                                [selectable]="true"
                                [removable]="true"
                                (removed)="remove(program, 'programs')">
                                {{ program.display }}
                            <mat-icon matChipRemove>clear</mat-icon>
                            </mat-chip>
                            <input
                                #programInput
                                appRemoveAccents
                                [placeholder]="filters.programs.length ? '+ Ajouter une Emission' : 'Emission'"
                                [formControl]="programsCtrl"
                                [matAutocomplete]="autoProgram"
                                [matChipInputFor]="chipListProgram"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                [matChipInputAddOnBlur]="true">
                            <mat-icon matSuffix>search</mat-icon>
                        </mat-chip-list>
                        <mat-autocomplete #autoProgram="matAutocomplete" (optionSelected)="selected($event, 'programs')">
                            <mat-option *ngFor="let program of filteredPrograms | async" [value]="program">
                                {{ program.display }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>

            <div class="filter-control">
                <div class="no-overflow">
                    <mat-form-field (click)="openInput('offers')" class="date-full-width">
                        <mat-chip-list #chipListOffer>
                            <mat-chip
                                *ngFor="let offer of filters.offers"
                                [selectable]="true"
                                [removable]="true"
                                (removed)="remove(offer, 'offers')">
                                {{ offer.display }}
                            <mat-icon matChipRemove>clear</mat-icon>
                            </mat-chip>
                            <input
                                #offerInput
                                appRemoveAccents
                                [placeholder]="filters.offers.length ? '+ Ajouter une offre' : 'Offre'"
                                [formControl]="offersCtrl"
                                [matAutocomplete]="autoOffer"
                                [matChipInputFor]="chipListOffer"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                [matChipInputAddOnBlur]="true">
                            <mat-icon matSuffix>search</mat-icon>
                        </mat-chip-list>
                        <mat-autocomplete #autoOffer="matAutocomplete" (optionSelected)="selected($event, 'offers')">
                            <mat-option *ngFor="let offer of filteredOffers | async" [value]="offer">
                                {{ offer.display }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
            <button mat-flat-button [disabled]="loading" (click)="generateConducteur()" class="export-conducteur-button">
                Export Conducteur
            </button>
            <button mat-flat-button [disabled]="loading" (click)="search()" class="find-button">Rechercher</button>
        </div>
        <mat-slide-toggle *ngIf="groupeStatus" class="toggle-oe" (change)="switchExclusivesOffers($event)" color="primary">Uniquement Offres Exclusives</mat-slide-toggle>
      <div class="filter-control"></div>
    </div>
</mat-card>
