import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { MatAutocompleteSelectedEvent, MatChipInputEvent, MatAutocomplete } from '@angular/material';
import { FiltersService } from 'src/app/private/availability/service/filters.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-input-filter-extends',
  template: '',
  styleUrls: ['./input-filter-extends.component.scss']
})
export class InputFilterExtendsComponent implements OnInit {
  @Output() protected queryParams: EventEmitter<any> = new EventEmitter<string>();

  protected filters;
  protected disableArea: boolean;

  constructor(protected filtersService: FiltersService) { }

  ngOnInit() {
  }

  /**
   * Focus of Input when we click
   *
   * @param {string} type
   * @return {void}
   * @memberof InputFilterExtendsComponent
   */
  openInput(type: string): void {
    this[`${type}Input`].nativeElement.focus();
  }

  /**
   * Uppercase first letter
   *
   * @param {string} type
   * @returns {string}
   * @memberof InputFilterExtendsComponent
   */
  upperFirstCase(type: string): string {
    return type.charAt(0).toUpperCase() + type.slice(1);
  }

  /**
   * Emit the filter for the search
   *
   * @return {void}
   * @memberof OfferListFilterComponent
   */
  onQueryParams(): void {
    this.queryParams.emit(this.filtersService.getQueryParams(this.filters));
  }

  /**
   * Reset filter after selected value
   *
   * @param {string} name
   * @returns {boolean}
   * @memberof InputFilterExtendsComponent
   */
  resetFilter(name: string): boolean {
    this[name] = of(new Array());

    return false;
  }

  /**
   * Add the text brut and reset the input
   *
   * @param {MatChipInputEvent} event
   * @param {string} type
   * @return {void}
   * @memberof InputFilterExtendsComponent
   */
  add(event: MatChipInputEvent, type: string): void {
    if (!this[`matAutocomplete${this.upperFirstCase(type)}`].isOpen) {
      const input = event.input;
      const value = event.value;

      if ((value || '').trim()) {
        this.filters[type].push(value.trim());
      }

      if (input) {
        input.value = '';
      }
      this[`${type}Ctrl`].setValue(null);
      this.onQueryParams();
    }
  }

  /**
   * remove the item in the input
   *
   * @param {*} data
   * @param {string} type
   * @return {void}
   * @memberof InputFilterExtendsComponent
   */
  remove(data, type: string): void {
    const index: number = this.filters[type].indexOf(data);

    if (index >= 0 ) {
      this.filters[type].splice(index, 1);
    }
    if (type === 'channels' && data.entity && data.entity.id === '3') {
      this.disableArea = true;
    }
    this.onQueryParams();
  }

  /**
   * check if there is double value identical
   *
   * @param {string} type
   * @param {*} data
   * @memberof InputFilterExtendsComponent
   */
  checkDouble(type: string, data): void {
    if (!this.filters[type].find((item) => item.value === data.value)) {
      this.filters[type].push(data);
    }
  }

  /**
   * add the item selected in the item list when click the input
   *
   * @param {MatAutocompleteSelectedEvent} event
   * @param {string} type
   * @return {void}
   * @memberof InputFilterExtendsComponent
   */
  selected(event: MatAutocompleteSelectedEvent, type: string): void {
    const data = event.option.value;

    type === 'category_id' ? this.filters[type] = this.formatCategory(data) :
      typeof this.filters[type] === 'string' ? this.filters[type] = new Array(data) : this.checkDouble(type, data);
    this[`${type}Input`].nativeElement.value = '';
    this[`${type}Ctrl`].setValue(null);
    if (type !== 'channels' && type !== 'areas' && type !== 'purchase_type_id' && type !== 'category_id') {
      this[`filtered${type.charAt(0).toUpperCase() + type.slice(1)}`] = of([]);
    }

    if (type === 'channels' && data && data.entity && data.entity.id === '3') {
      this.disableArea = false;
    }
    this.onQueryParams();
  }

  /**
   * Set new Object for Category
   *
   * @param {*} data
   * @return {Array<Object>}
   * @memberof InputFilterExtendsComponent
   */
  formatCategory(data): Array<Object> {
    let format = data.entity;

    format['value'] = data.value ? data.value : null;

    return new Array(data.entity);
  }

  /**
   * Get a new list with filter
   *
   * @private
   * @param {*} data
   * @param {string} type
   * @returns {string[]}
   * @memberof OfferListFilterComponent
   */
  protected _filter(data, type: string): string[] {
    let value: string;

    if (data.display) {
      value = data.display;
    } else {
      value = data;
    }

    const filterValue = value.toLowerCase();

    return this[`${type}FilterOptions`].filter(area => area.display.toLowerCase().includes(filterValue));
  }

}
