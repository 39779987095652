<div fxLayout="column" fxLayoutGap="5px">
  <form [formGroup]="informationGroup" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutGap="15px">
      <mat-form-field>
        <input matInput formControlName="period" [matDatepicker]="on" placeholder="Mois et Année">
        <mat-datepicker-toggle matSuffix [for]="on"></mat-datepicker-toggle>
        <mat-datepicker startView="year" #on></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="width-input">
        <input matInput placeholder="Numéro de bilan"
               appRemoveAccents
               [matAutocomplete]="bilanNumberAutocomplete"
               formControlName="bilanNumber">
      </mat-form-field>
      <mat-autocomplete #bilanNumberAutocomplete="matAutocomplete" [displayWith]="autocompleteService.displayAutocomplete.bind(this)">
        <mat-option *ngFor="let bilanNumber of filteredBilanNumberItems | async" [value]="bilanNumber">
          {{ bilanNumber.display }}
        </mat-option>
      </mat-autocomplete>
      <mat-form-field class="width-input">
        <input matInput placeholder="Numéro de contrat"
               appRemoveAccents
               [matAutocomplete]="contractNumberAutocomplete"
               formControlName="contractNumber">
      </mat-form-field>
      <mat-autocomplete #contractNumberAutocomplete="matAutocomplete" [displayWith]="autocompleteService.displayAutocomplete.bind(this)">
        <mat-option *ngFor="let contractNumber of filteredContractNumberItems | async" [value]="contractNumber">
          {{ contractNumber.display }}
        </mat-option>
      </mat-autocomplete>
      <mat-form-field class="width-input">
        <input matInput appRemoveAccents placeholder="Annonceur" [matAutocomplete]="advertiserAutocomplete"
               formControlName="advertiser">
      </mat-form-field>
      <mat-autocomplete #advertiserAutocomplete="matAutocomplete" [displayWith]="autocompleteService.displayAutocomplete.bind(this)">
        <mat-option *ngFor="let advertiser of filteredAdvertiserItems | async" [value]="advertiser">
          {{ advertiser.display }} ({{ advertiser.value }})
        </mat-option>
      </mat-autocomplete>
      <mat-form-field class="width-input">
        <input matInput appRemoveAccents placeholder="Emission" [matAutocomplete]="programAutocomplete"
               formControlName="program">
      </mat-form-field>
      <mat-autocomplete #programAutocomplete="matAutocomplete"
                        [displayWith]="autocompleteService.displayAutocomplete.bind(this)">
        <mat-option *ngFor="let program of filteredProgramItems | async" [value]="program">
          {{ program.display }} ({{ program.value }})
        </mat-option>
      </mat-autocomplete>
    </div>
    <div fxLayoutGap="15px">
      <button mat-flat-button (click)="loadMonitoringLotList()" class="black button-flat">
        Rechercher
      </button>
      <button mat-flat-button (click)="openNewMonitoringRowDialog()" class="red button-flat">
        Ajouter
      </button>
    </div>
  </form>
  <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  <div class="table-monitoring-lot-container">
    <table mat-table [dataSource]="dataSource">
      <!--Contrat-->
      <ng-container matColumnDef="contract">
        <th mat-header-cell *matHeaderCellDef> Contrat</th>
        <td mat-cell *matCellDef="let element"> {{element.contractNumber}} </td>
      </ng-container>
      <!--Advertiser nom + code-->
      <ng-container matColumnDef="advertiserName">
        <th mat-header-cell *matHeaderCellDef> Annonceur</th>
        <td mat-cell *matCellDef="let element" matTooltip="{{element.advertiser.name}} ({{element.advertiser.id}})">
          {{element.advertiser.name.length > 20 ? ((element.advertiser.name | slice:0:20) + '...') : element.advertiser.name}} ({{element.advertiser.id}})
        </td>
      </ng-container>
      <!--Produit nom + code-->
      <ng-container matColumnDef="product">
        <th mat-header-cell *matHeaderCellDef> Produit</th>
        <td mat-cell *matCellDef="let element" matTooltip="{{element.product.name}} ({{element.product.id}})">
          {{element.product.name.length > 20 ? ((element.product.name | slice:0:20) + '...') : element.product.name}} ({{element.product.id}})
        </td>
      </ng-container>
      <!--Description-->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef> Description</th>
        <td mat-cell *matCellDef="let element" matTooltip="{{element.description}}">
          {{element.description.length > 20 ? ((element.description | slice:0:20) + '...') : element.description}}
        </td>
      </ng-container>
      <!--Numéro de bilan-->
      <ng-container matColumnDef="bilanNumber">
        <th mat-header-cell *matHeaderCellDef> Numéro de bilan</th>
        <td mat-cell *matCellDef="let element"> {{element.bilanNumber}} </td>
      </ng-container>
      <!--Nombre d'émissions-->
      <ng-container matColumnDef="broadcastsNumber">
        <th mat-header-cell *matHeaderCellDef> Nombre d'émissions</th>
        <td mat-cell *matCellDef="let element"> {{element.broadcastsNumber}} </td>
      </ng-container>
      <!--Nombre de lots gagnés-->
      <ng-container matColumnDef="totalLotsWon">
        <th mat-header-cell *matHeaderCellDef> Lots gagnés</th>
        <td mat-cell *matCellDef="let element"> {{element.totalLotsWon}} </td>
      </ng-container>
      <!--Prix HT du lot-->
      <ng-container matColumnDef="unitPrice">
        <th mat-header-cell *matHeaderCellDef> Prix HT (€)</th>
        <td mat-cell *matCellDef="let element"> {{element.unitPrice | number:'0.2-2':'fr'}} </td>
      </ng-container>
      <!--Prix total (prix unitaire x nbr gagnés)-->
      <ng-container matColumnDef="totalPrice">
        <th mat-header-cell *matHeaderCellDef> Prix Total (€)</th>
        <td mat-cell *matCellDef="let element"> {{element.totalLotsWonPrice | number:'0.2-2':'fr'}} </td>
      </ng-container>
      <!--Chaîne + nom émission-->
      <ng-container matColumnDef="channel">
        <th mat-header-cell *matHeaderCellDef> Emission</th>
        <td mat-cell *matCellDef="let element" matTooltip="({{element.channel.name}}) {{element.program.name}}">
          <div class="scroll height-50">
            ({{element.channel.name}}) {{element.program.name.length > 20 ? ((element.program.name | slice:0:20) + '...') : element.program.name}}
          </div>
        </td>
      </ng-container>
      <!--Période-->
      <ng-container matColumnDef="periods">
        <th mat-header-cell *matHeaderCellDef> Périodes</th>
        <td mat-cell *matCellDef="let element">
          <div fxLayout="column" class="scroll height-50">
            <ng-container *ngFor="let period of element.periods">
              <span *ngIf="period.startPeriod === period.endPeriod">
                {{period.startPeriod | date:'dd/MM/yy'}}
              </span>
              <span *ngIf="period.startPeriod !== period.endPeriod">
                {{period.startPeriod | date:'dd/MM/yy'}} - {{period.endPeriod | date:'dd/MM/yy'}}
              </span>
            </ng-container>
          </div>
        </td>
      </ng-container>
      <!--Pièces-->
      <ng-container matColumnDef="invoice">
        <th mat-header-cell *matHeaderCellDef>Pièce</th>
        <td mat-cell *matCellDef="let element"> {{element.getInvoiceName()}} </td>
      </ng-container>
      <!--Action-->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <div fxLayout="row" fxLayoutGap="5px">
            <button *ngIf="element.invoiced !== 'Y'" mat-mini-fab (click)="openNewMonitoringRowDialog(element)"
                    matTooltip="Modifier">
              <mat-icon>create</mat-icon>
            </button>
            <button *ngIf="element.invoiced !== 'Y'" mat-mini-fab (click)="deleteMonitoringLot(element.id)"
                    matTooltip="Supprimer">
              <mat-icon>delete_outline</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="height-row"></tr>
    </table>
  </div>
</div>
