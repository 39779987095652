import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

import {MatDialog} from '@angular/material';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {NewDotationPurchaseComponent} from '../new-dotation-purchase/new-dotation-purchase.component';
import {CustomValidators} from '../../../../validator/custom-validators';

import {CustomToastrService} from '@service/toastr/custom-toastr.service';
import {LotDotationService} from '@service/lot-dotation/lot-dotation.service';
import {PurchaseService} from '@service/purchase/purchase.service';
import {ProductService} from '@service/product/product.service';
import {AutocompleteService} from '@service/utilities/autocomplete.service';

import {LotDotation} from '../../../../resource/lot-dotation.resource';
import {Advertiser} from '../../../../resource/advertiser.resource';
import {JsonPurchaseDotation, PurchaseDotation} from '../../../../resource/purchase.resource';
import {FilteredItem} from '../../../../resource/filteredItem.resource';

import {Observable, Subject, of} from 'rxjs';
import {startWith, takeUntil} from 'rxjs/operators';

import * as moment from 'moment';

export interface DialogDataNewLot {
  advertiser?: Advertiser;
  products?: FilteredItem[];
  lot?: LotDotation;
  isDuplicate?: boolean;
}

@Component({
  selector: 'app-new-lot-dotation-form',
  templateUrl: './new-lot-dotation-form.component.html',
  styleUrls: ['./new-lot-dotation-form.component.scss']
})
export class NewLotDotationFormComponent implements OnDestroy, OnInit {
  public lot: LotDotation;
  public isDuplicate: boolean = false;
  public advertiser: Advertiser;
  public isSaving: boolean = false;
  public newLotDotationForm: FormGroup;
  public year: FormControl = new FormControl();
  public FilteredProducts: Observable<FilteredItem[]>;
  public FilteredPurchases: Observable<FilteredItem[]>;
  public minDate: number;

  private products: FilteredItem[] = [];
  private dotationPurchases: FilteredItem[] = [];
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    public autocompleteService: AutocompleteService,
    public dialogRef: MatDialogRef<NewLotDotationFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataNewLot,
    public dialog: MatDialog,
    private customToastrService: CustomToastrService,
    private lotDotationService: LotDotationService,
    private purchaseService: PurchaseService,
    private productService: ProductService,
    private fb: FormBuilder,
  ) {
    this.advertiser = this.data.advertiser;
    this.products = this.data.products;
    this.lot = this.data.lot;
    this.isDuplicate = this.data.isDuplicate;
    this.initForm();
  }

  ngOnInit(): void {
    this.autoSelectValue();
    this.initDuplicateYearForm();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.unsubscribe();
  }

  /**
   * Init new lot dotation form
   *
   * @returns {void}
   * @memberof NewLotDotationFormComponent
   */
  public initForm(): void {
    if (this.isDuplicate) {
      this.onDuplicate();
    } else {
      this.newLotDotationForm = this.fb.group({
        product: [this.lot ? {
          value: this.lot.product,
          disabled: true
        } : null, [Validators.required, CustomValidators.inFilteredItemValidator]],
        purchase: [this.lot ? {
          value: {display: this.lot.parroptionName, id: this.lot.parroption},
          disabled: !!this.lot.totalWonLots
        } : null, [Validators.required]],
        name: [this.lot ? this.lot.name : '', [Validators.required, Validators.maxLength(30)]],
        description: [this.lot ? this.lot.description : '', [Validators.required, Validators.maxLength(255)]],
        quantity: [
          this.lot ? this.lot.quantity : '',
          [
            Validators.required,
            Validators.min((this.lot && this.lot.totalWonLots > 0) ? this.lot.totalWonLots : 0),
            Validators.max(9999)
          ]
        ],
        unitPrice: [this.lot ? {
          value: this.lot.unitPrice,
          disabled: (this.lot.totalInvoicedLots !== 0)
        } : '', [Validators.required, Validators.min(0)]],
        advertiser: [this.lot ? {value: this.advertiser, disabled: true} : this.advertiser, [Validators.required]]
      });
    }
  }

  /**
   * Init Form when duplicate
   */
  public onDuplicate(): void {
    this.newLotDotationForm = this.fb.group({
      product: [{value: this.lot.product, disabled: true}, [Validators.required, CustomValidators.inFilteredItemValidator]],
      purchase: [
        this.lot ? {
          value: {display: this.lot.parroptionName, id: this.lot.parroption},
          disabled: false,
        } : null,
        [Validators.required]
      ],
      name: [this.lot.name, [Validators.required, Validators.maxLength(30)]],
      description: [this.lot.description, [Validators.required, Validators.maxLength(255)]],
      quantity: [this.lot.quantity, [Validators.required, Validators.min(0), Validators.max(9999)]],
      unitPrice: [{value: this.lot.unitPrice, disabled: false}, [Validators.required, Validators.min(0)]],
      advertiser: [{value: this.advertiser, disabled: true}, [Validators.required]]
    });
  }

  /**
   * Set product and purchase value if there is only one
   */
  public autoSelectValue(): void {
    if (this.advertiser && this.advertiser.id && typeof this.advertiser.id === 'number') {
      const productParams = {
        advertiser_id: this.advertiser.id,
        campaign_type: ['DOT', 'ASL'],
      };

      // get list of product with params
      this.productService.getListForFilter(productParams)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((productItems: FilteredItem[]) => {
          // if only one product set automatically this product
          if (productItems.length === 1) {
            this.FilteredProducts = of(productItems);
            this.newLotDotationForm.controls['product'].setValue(productItems[0], {emitEvent: false});
            this.getListDotationPurchase();
          } else if (productItems.length > 1) {
            this.FilteredProducts = of(productItems);
            if (this.isDuplicate) {
              // Find the productItems which match with our lot.product and set it in the form
              // and get the list of purchases dotation related to our lot.product
              let productMatch = productItems.find(element => element.display === this.lot.product);
              this.newLotDotationForm.controls['product'].setValue(productMatch, {emitEvent: false});
              this.getListDotationPurchase();
            }

            this.newLotDotationForm.controls['product'].valueChanges
              .pipe(takeUntil(this.componentDestroyed$))
              .subscribe(() => {
                // empty the value of purchase if we replace product value
                this.newLotDotationForm.controls['purchase'].setValue('', {emitEvent: false});
                this.getListDotationPurchase();
              }, () => {
                this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
              });
          }
        }, () => {
          this.customToastrService.displayToastr('ERROR', 'Erreur lors de la récuperation des marques');
        });
    }
  }

  /**
   * Close dialog and cancel lot dotation creation
   *
   * @returns {void}
   * @memberof NewLotDotationFormComponent
   */
  public cancel(): void {
    this.dialogRef.close();
  }

  /**
   * Submit new lot dotation form
   *
   * @returns {void}
   * @memberof NewLotDotationFormComponent
   */
  public submit(): void {
    if (this.newLotDotationForm.valid) {
      this.isSaving = true;

      let observableLotService$;

      if (this.lot && !this.isDuplicate) {
        observableLotService$ = this.lotDotationService.put(this.convertFormGroupToLotDotationObject());
      } else {
        observableLotService$ = this.lotDotationService.post(this.convertFormGroupToLotDotationObject());
      }

      observableLotService$
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((createdLotDotation: LotDotation) => {
          this.isSaving = false;
          if (this.isDuplicate) {
            this.customToastrService.displayToastr('SUCCESS', 'La duplication du lot a été créé avec succès!');
          } else {
            this.customToastrService.displayToastr('SUCCESS', this.lot ? 'Mise à jour avec succès!' : 'Le lot de dotation a été créé avec succès!');
          }
          this.dialogRef.close(createdLotDotation);
        }, () => {
          this.isSaving = false;
          if (this.isDuplicate) {
            this.customToastrService.displayToastr('ERROR', 'Erreur lors de la duplication du lot');
          } else {
            this.customToastrService.displayToastr('ERROR', this.lot ? 'Erreur lors de la mise à jour!' : 'Erreur lors de la création!');
          }
        });
    } else {
      this.customToastrService.displayToastr('ERROR', 'Les champs du formulaire ne sont pas valides!');
    }
  }

  /**
   * Open new dotation purchase form dialog
   *
   * @returns {void}
   * @memberof NewLotDotationFormComponent
   */
  public openNewDotationPurchaseFormDialog(): void {
    const purchaseData = {product: this.newLotDotationForm.get('product').value};
    const dialogRef: MatDialogRef<NewDotationPurchaseComponent> = this.dialog.open(NewDotationPurchaseComponent, {
      width: '60%',
      data: purchaseData,
      autoFocus: false
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((dotationPurchase: JsonPurchaseDotation) => {
        if (dotationPurchase) {
          const filteredPurchase = new FilteredItem(new PurchaseDotation(dotationPurchase));
          this.dotationPurchases.push(filteredPurchase);
          this.FilteredPurchases = of(this.dotationPurchases);
          this.newLotDotationForm.controls['purchase'].setValue(filteredPurchase, {emitEvent: false});
        }
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue!');
      });
  }

  /**
   * Convert LotDotation FormGroup to LotDotation resource object
   *
   * @returns {LotDotation}
   * @memberof NewLotDotationFormComponent
   */
  private convertFormGroupToLotDotationObject(): LotDotation {
    return new LotDotation({
      id: this.lot ? this.lot.id : null,
      name: this.newLotDotationForm.get('name').value,
      description: this.newLotDotationForm.get('description').value,
      quantity: this.newLotDotationForm.get('quantity').value,
      unit_price: this.newLotDotationForm.get('unitPrice').value,
      product: this.newLotDotationForm.get('product').value,
      advertiser: this.newLotDotationForm.get('advertiser').value,
      parroption: this.newLotDotationForm.get('purchase').value.id,
      parroption_name: this.newLotDotationForm.get('purchase').value.display,
      period_start_date: moment(this.newLotDotationForm.get('periodStartDate').value).format('YYYY-MM-DD'),
      period_end_date: moment(this.newLotDotationForm.get('periodEndDate').value).format('YYYY-MM-DD')
    });
  }

  /**
   * Get the purchase dotation list with product and year param
   */
  private getListDotationPurchase() {
    if (this.newLotDotationForm.controls['product'].value != null
      && this.newLotDotationForm.controls['product'].value !== ''
      && this.newLotDotationForm.controls['product'].value.id
      && typeof this.newLotDotationForm.controls['product'].value.id === 'number') {
      this.purchaseService.getListDotationPurchaseForFilter({
        product_id: this.newLotDotationForm.controls['product'].value.id,
        year: moment().format('YYYY')
      })
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((purchaseItems: FilteredItem[]) => {
          this.dotationPurchases = purchaseItems;
          this.FilteredPurchases = of(this.dotationPurchases);

          // if only one purchase set automatically this purchase
          if (purchaseItems.length === 1) {
            this.newLotDotationForm.controls['purchase'].setValue(purchaseItems[0], {emitEvent: false});
          } else if (purchaseItems.length > 1 || purchaseItems.length === 0) {
            this.getFilteredPurchases(purchaseItems);
          }
        }, () => {
          this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
        });
    }
  }

  /**
   * Get the filtered purchases after purchase value change
   *
   * @param purchaseItems
   */
  private getFilteredPurchases(purchaseItems: FilteredItem[]) {
    this.newLotDotationForm.controls['purchase'].valueChanges
      .pipe(
        startWith(null),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((value) => {
        if (value && typeof value === 'string') {
          this.FilteredPurchases = of([]);
          this.FilteredPurchases = of(this.autocompleteService.filterItems(value, purchaseItems));
        }
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });
  }

  /**
   * Init year form when duplicate, by default set to N+1
   */
  private initDuplicateYearForm(): void {
    if (this.isDuplicate && this.data && this.data.lot) {
      this.minDate = new Date(this.data.lot.periodStartDate).getFullYear();
      this.year.setValue(Number(moment(this.data.lot.periodStartDate).format('YYYY')) + 1);
    }
  }
}
