import { PurchaseBudgetService } from '@service/purchase-budget/purchase-budget.service';
import { BroadcastPurchased } from '../../../../../../../resource/broadcast-purchased.resource';
import { Purchase } from '../../../../../../../resource/purchase.resource';
import { BroadcastPurchasedService } from '@service/broadcast-purchased/broadcast-purchased.service';
import { PurchaseService } from '@service/purchase/purchase.service';
import { PurchaseGrpService } from '@service/purchase-grp/purchase-grp.service';
import { Component, Input, OnInit, ViewChild, OnChanges, Output, EventEmitter, OnDestroy } from '@angular/core';

import { PurchaseProgram } from '../../../../../../../resource/purchaseProgram.resource';
import { MatSort, MatTableDataSource } from '@angular/material';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';
import { QueueLogService } from '@service/queue-log/queue-log.service';
import { QueueLog } from '../../../../../../../resource/queue-log.resource';
import { delay, takeUntil} from 'rxjs/operators';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-broadcast-list',
  templateUrl: './broadcast-list.component.html',
  styleUrls: ['./broadcast-list.component.scss'],
})
export class BroadcastListComponent implements OnInit, OnChanges, OnDestroy {

  @Input() purchaseProgram: PurchaseProgram;
  @Input() purchase: Purchase;

  @Output() deleteBroadcastEmitter = new EventEmitter<boolean>();

  @ViewChild(MatSort) sort: MatSort;

  public broadcastsPurchased: MatTableDataSource<BroadcastPurchased>;
  public broadcastsPurchasedCount: number;
  public listDeleteBroadcasts: BroadcastPurchased[] = [];

  public min: BroadcastPurchased;
  public max: BroadcastPurchased;
  public loading = false;
  public checkedDelete;

  public matHeaderRowDefArray = [];
  public matRowDefArray = [];

  private selectedAllBox: boolean = false;

  private componentDestroyed: Subject<any> = new Subject();

  constructor(
    private broadcastPurchasedService: BroadcastPurchasedService,
    private purchaseService: PurchaseService,
    private purchaseBudgetService: PurchaseBudgetService,
    private purchaseGrpService: PurchaseGrpService,
    private customToastrService: CustomToastrService,
    private queueLogService: QueueLogService
  ) {}

  ngOnInit() {
      // Init matHeaderRowDef and matRowDef whether it's a soreach purchase or not
      this.matHeaderRowDefArray = ['startTime', 'unprograme', 'price', 'statusLabel',
          'broadcast.startTimeLabel', 'broadcast.broadcast.endTimeLabel', 'remove'];

      if (this.purchase.soreachPurchase) {
          this.matHeaderRowDefArray.splice(3, 0, 'soreachBillboardPrice');
      }

      this.matRowDefArray = this.matHeaderRowDefArray;

    this.broadcastPurchasedService.broadcastPurchasedList$
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(result => {
          this.broadcastsPurchased = new MatTableDataSource<BroadcastPurchased>(result);
          this.broadcastsPurchased.sort = this.sort;
          // setting item count
          let broadcastPurchasedData = this.broadcastsPurchased.connect().value;
          this.broadcastsPurchasedCount = broadcastPurchasedData.length;
          // setting boundaries
          this.min = broadcastPurchasedData[0];
          this.max = broadcastPurchasedData[broadcastPurchasedData.length - 1];
          this.checkedDelete = new Array(result.length);
          this.checkedDelete.fill(false);
        }
    );
  }

  ngOnChanges(changes: any): void {
    if (changes.purchaseProgram) {
        this.purchaseProgram = changes.purchaseProgram.currentValue;
        this.initComponent();
    }
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  public initComponent() {
    if (!this.purchase.id) {
      return null;
    }
    this.broadcastPurchasedService.purchase = this.purchase;
    this.broadcastPurchasedService.purchaseProgram = this.purchaseProgram;
    this.broadcastPurchasedService.getBroadcastsPurchased().subscribe(result => {
      this.broadcastPurchasedService.updateLoading(false);
    });
  }

  selectAllBox() {
    if (!this.selectedAllBox) {
      this.checkedDelete.fill(true);
      this.listDeleteBroadcasts = this.broadcastsPurchased.data;
    } else {
      this.checkedDelete.fill(false);
      this.listDeleteBroadcasts = [];
    }
    this.selectedAllBox = !this.selectedAllBox;
  }

  /**
   * list of delete broadcast when user click
   *
   * @param {BroadcastPurchased} broadcast
   * @param {number} i
   */
  public checkDelete(broadcast: BroadcastPurchased, i: number): void {
    const index: number  = this.listDeleteBroadcasts.findIndex((item: BroadcastPurchased) => {
      return item.id === broadcast.id;
    });

    if (index >= 0) {
      this.listDeleteBroadcasts.splice(index, 1);
    } else {
      this.listDeleteBroadcasts.push(broadcast);
    }
  }

  /**
   * Delete broadcast list
   *
   * @memberof BroadcastListComponent
   */
  public deleteBroadcasts(): void {
    this.purchaseBudgetService.updateGlobalBudgetLoading(true);
    this.broadcastPurchasedService.deleteList(this.listDeleteBroadcasts, this.purchase.id, this.purchaseProgram.id)
      .pipe(
        takeUntil(this.componentDestroyed),
        delay(3000))
      .subscribe(() => {
        let source = this.broadcastsPurchased.data;
        this.listDeleteBroadcasts.forEach((item) => {
          let index = source.findIndex((element) => {
            return item.id === element.id;
          });
        source.splice(index, 1);
        });
        this.broadcastsPurchased = new MatTableDataSource<BroadcastPurchased>(source);
        this.broadcastsPurchased.sort = this.sort;
        this.broadcastsPurchasedCount = source.length;

        this.listDeleteBroadcasts = [];
        this.broadcastPurchasedService.updateLoading(true);
        this.broadcastPurchasedService.updateEditing(true);
        this.purchaseService.getUpdatedPurchase(true);
        this.purchaseGrpService.updatePurchaseGrp(this.purchase.id);
        this.loading = false;

        this.customToastrService.displayToastr('SUCCESS', 'Suppression reussie');
        this.queueLogService.checkQueueProgressOnTimer(QueueLog.QUEUE_NAME_BROADCAST_PURCHASED,
          this.purchase.id,
          QueueLog.PURCHASE_BROADCAST,
          false);
        }, (error) => {
        this.loading = false;
        this.customToastrService.displayToastr('ERROR', 'Erreur de suppression');
      })
  }
}
