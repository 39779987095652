import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import { MatDialog } from '@angular/material';
import { MatDialogRef } from '@angular/material/dialog';

import { NewLotDotationFormComponent } from '../dialog/new-lot-dotation-form/new-lot-dotation-form.component';

import { AdvertiserService } from '@service/advertiser/advertiser.service';
import { LotDotationService } from '@service/lot-dotation/lot-dotation.service';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';
import { AutocompleteService } from '@service/utilities/autocomplete.service';
import { ProductService } from '@service/product/product.service';

import { FilteredItem } from '../../../resource/filteredItem.resource';
import { Advertiser } from '../../../resource/advertiser.resource';
import { LotDotation } from '../../../resource/lot-dotation.resource';

import { Subject, Observable, of } from 'rxjs';
import { takeUntil, startWith, debounceTime, distinctUntilChanged } from 'rxjs/operators';

export interface LotDotationElement {
  id: number;
  product: string;
  name: string;
  description: string;
  quantity: number;
  unitPrice: number
  total: number;
  totalWonLots?: number;
  totalInvoicedLots?: number;
}

export interface LotDotationFilter {
  advertiser: number;
  year: number;
}

@Component({
  selector: 'app-lots-form-advertiser',
  templateUrl: './lots-form-advertiser.component.html',
  styleUrls: ['./lots-form-advertiser.component.scss']
})
export class LotsFormAdvertiserComponent implements OnInit, OnDestroy {

  public advertiserInformationGroup: FormGroup;
  public filteredAdvertiserItems: Observable<FilteredItem[]>;
  public loadingProducts: boolean = false;
  public loadingInputAdvertiser: boolean = false;
  public loadingAdvertiserLotsDotation: boolean = false;
  public isDeleting: boolean = false;
  public lotDotationList: LotDotation[] = [];
  public filters: LotDotationFilter;
  public dataSource: LotDotationElement[] = [];
  public displayedColumns: string[] = ['product', 'parroption', 'name', 'description', 'quantity', 'unitPrice', 'total', 'wonLots',
    'invoicedLots', 'endingBalance', 'actions'];

  private productsItems: FilteredItem[];
  private advertiserItems: FilteredItem[];
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    public autocompleteService: AutocompleteService,
    private advertiserService: AdvertiserService,
    private customToastrService: CustomToastrService,
    private lotDotationService: LotDotationService,
    private productService: ProductService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.initForm();
    this.initFilters();
    this.setValueChanges();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.unsubscribe();
  }

  /**
   * Get Advertiser's lots dotation
   */
  getLotDotationCollection() {
    // If advertiser input not valid, show error message and not trigger get lots dotation action!
    if (!this.isAdvertiserInputValid()) {
      this.resetDataSource();
      this.customToastrService.displayToastr(
          'WARNING',
          'Vous devez saisir un annonceur valide avant de pouvoir lancer une recherche!'
      );

      return;
    }

    this.loadingAdvertiserLotsDotation = true;
    this.filters = {
      advertiser: this.advertiserInformationGroup.controls['advertiser'].value.id,
      year: this.advertiserInformationGroup.controls['year'].value,
    }
    this.lotDotationService.getList(this.filters)
      .subscribe(response => {
        this.loadingAdvertiserLotsDotation = false;
        this.lotDotationList = response;
        this.dataSource = this.setDataSourceFromLotDotationList();
        if (this.lotDotationList.length === 0) {
          this.customToastrService.displayToastr('SUCCESS', 'Aucun lot dotation n\'est lié à cet annonceur!');
        } else {
          this.customToastrService.displayToastr('SUCCESS', 'Les lots dotation ont été récupérés avec succès!');
        }
      }, () => {
        this.resetDataSource();
        this.customToastrService.displayToastr(
          'ERROR',
          'Une erreur est survenue lors de la récupération des lots dotation!'
        );
        this.loadingAdvertiserLotsDotation = false;
      });
    this.productsAdvertiser(this.filters.advertiser);
  }

  /**
   * Get list of advertiser's lot dotation when an advertiser is selected!
   *
   * @param event
   */
  selectedOption(event) {
    const selectedAdvertiser = event.option.value;
    if (selectedAdvertiser && selectedAdvertiser instanceof FilteredItem && selectedAdvertiser.entity
      && selectedAdvertiser.entity instanceof Advertiser) {
      this.loadingAdvertiserLotsDotation = true;
      this.filters = {
        advertiser: selectedAdvertiser.entity.id,
        year: this.advertiserInformationGroup.controls['year'].value,
      }
      this.lotDotationService.getList(this.filters)
        .subscribe((response) => {
          this.loadingAdvertiserLotsDotation = false;
          this.lotDotationList = response;
          this.dataSource = this.setDataSourceFromLotDotationList();

          if (this.lotDotationList.length === 0) {
            this.customToastrService.displayToastr('SUCCESS', 'Aucun lot dotation n\'est lié à cet annonceur!');
          } else {
            this.customToastrService.displayToastr('SUCCESS', 'Les lots dotation ont été récupérés avec succès!');
          }
        }, () => {
          this.customToastrService.displayToastr(
            'ERROR',
            'Une erreur est survenue lors de la récupération des lots dotation!'
          );
          this.loadingAdvertiserLotsDotation = false;
          this.resetDataSource();
        });
      this.productsAdvertiser(this.filters.advertiser);
    } else {
      this.filters = {
        advertiser: null,
        year: null,
      };
      this.resetDataSource();
    }
  }

  /**
   * Open create/modification lot dotation form dialog
   *
   * @param lot? {LotDotation}
   * @param isDuplicate?
   * @return {void}
   * @memberof LotsFormAdvertiserComponent
   */
  openNewLotDotationFormDialog(lot?: LotDotation, isDuplicate?: boolean): void {
    // If advertiser input not valid, show error message and not open new lot dotation form dialog!
    if (!this.isAdvertiserInputValid()) {
      this.customToastrService.displayToastr(
        'WARNING',
        'Vous devez saisir un annonceur valide avant de pouvoir ajouter un nouveau lot dotation!'
      );

      return;
    }

    const dataDialogNewLot = {
      advertiser: this.advertiserInformationGroup.get('advertiser').value.entity,
      products: this.productsItems,
      lot: lot,
      isDuplicate: isDuplicate
    };

    const dialogRef: MatDialogRef<NewLotDotationFormComponent> = this.dialog.open(NewLotDotationFormComponent, {
      width: '60%',
      data: dataDialogNewLot,
      autoFocus: false
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        this.getLotDotationCollection();
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });
  }

  /**
   * Delete Lot Dotation Element
   *
   * @param id {number}
   */
  deleteLotDotation(id: number) {
    this.isDeleting = true;
    this.lotDotationService.delete(id)
      .subscribe(() => {
        this.isDeleting = false;
        // Get lot dotation element index in lotDotationList to remove from the list
        const index = this.lotDotationList.findIndex(lotDotation => lotDotation.id === id);
        if (index !== -1) {
          this.lotDotationList.splice(index, 1);
          this.dataSource = this.setDataSourceFromLotDotationList();
        }
        this.customToastrService.displayToastr('SUCCESS', 'Le lot dotation a été supprimé avec succès!');
      }, () => {
        this.isDeleting = false;
        this.customToastrService.displayToastr('ERROR', 'Erreur lors de la suppression du lot dotation!');
      })
  }

  /**
   * Get products with the parameter Advertiser & lot
   *
   * @param advertiserId {number}
   * @return {void}
   */
  private productsAdvertiser(advertiserId: number): void {
    const dataProduct = {
      advertiser_id: advertiserId,
      campaign_type: ['DOT', 'ASL'] // Type de campagne dotation et droit d'asile
    };

    this.loadingProducts = true;
    this.productService.getListForFilter(dataProduct)
      .subscribe((products: FilteredItem[]) => {
        this.productsItems = products;
        this.loadingProducts = false;
      }, () => {
        this.productsItems = null;
        this.loadingProducts = false;
        this.customToastrService.displayToastr('ERROR', 'Erreur lors de la recuperation des produits!');
      });
  }

  /**
   * Init lot dotation advertiser form
   *
   * @return {void}
   */
  private initForm(): void {
    const year = new Date;
    this.advertiserInformationGroup = this.formBuilder.group({
      advertiser: new FormControl(),
      year: [
        year.getFullYear(),
        [
          Validators.required,
          Validators.pattern('^[0-9]{4}$')
        ],
      ],
    });
  }

  /**
   * Init filters
   */
  private initFilters(): void {
    this.filters = {
      advertiser: null,
      year: null,
    }
  }

  /**
   * Set the new value after change
   *
   * @return {void}
   */
  private setValueChanges(): void {
    // check if year input is valid and desactivate advertiser input
    this.advertiserInformationGroup.controls['year'].valueChanges
      .pipe(
        startWith(null),
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.componentDestroyed$))
      .subscribe(value => {
        this.advertiserInformationGroup.controls['year'].valid ?
          this.advertiserInformationGroup.get('advertiser').enable() :
          this.advertiserInformationGroup.get('advertiser').disable()
      });

    // load advertiser
    this.advertiserInformationGroup.controls['advertiser'].valueChanges
    .pipe(
      startWith(null),
      debounceTime(500),
      distinctUntilChanged(),
      takeUntil(this.componentDestroyed$))
    .subscribe(value => {
      this.filteredAdvertiserItems = of([]);

      if (value && typeof value === 'string') {
        this.loadingInputAdvertiser = true;
        this.resetDataSource();

        // Can search advertiser by id or by name
        const filterAdvertiser =  Number(value) ? {id: value.toUpperCase()} : {name: value.toUpperCase()};

        // Get the list of Advertiser
        this.advertiserService.getListForFilter(filterAdvertiser)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(advertiserItems => {
          if (advertiserItems && advertiserItems.length > 0) {
            this.advertiserItems = advertiserItems;
            this.filteredAdvertiserItems = of(this.autocompleteService.filterItems(value, this.advertiserItems));
          }
          this.loadingInputAdvertiser = false;
        }, () => {
          this.customToastrService.displayToastr('ERROR', 'Erreur lors de la récupération des annonceurs');
          this.loadingInputAdvertiser = false;
        });
      }
    }, () => {
      this.resetDataSource();
      this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      this.loadingInputAdvertiser = false;
    });
  }

  /**
   * Check if advertiser value is valid before opening new lot dotation form dialog
   *
   * @return {boolean}
   * @memberof LotsFormAdvertiserComponent
   */
  private isAdvertiserInputValid(): boolean {
    const advertiserInputVal = this.advertiserInformationGroup.get('advertiser').value;
    let result = true;

    if (!advertiserInputVal || !advertiserInputVal.entity || !(advertiserInputVal.entity instanceof Advertiser)) {
      result = false;
    }

    return result;
  }

  /**
   * Reset to empty lotDotation list and dataSource
   */
  private resetDataSource(): void {
    this.lotDotationList = [];
    this.dataSource = [];
    this.productsItems = null;
  }

  /**
   * Construct table DataSource from lotDotationList
   *
   * @return {LotDotationElement[]}
   */
  private setDataSourceFromLotDotationList(): LotDotationElement[] {
    return this.lotDotationList.map((lotDotation: LotDotation) => {
      return {
        id: lotDotation.id,
        product: lotDotation.product.name,
        name: lotDotation.name,
        description: lotDotation.description,
        quantity: lotDotation.quantity,
        unitPrice: lotDotation.unitPrice,
        total: lotDotation.quantity * lotDotation.unitPrice,
        parroption: lotDotation.parroption,
        parroptionName: lotDotation.parroptionName,
        periodStartDate: lotDotation.periodStartDate['date'],
        periodEndDate: lotDotation.periodEndDate['date'],
        totalWonLots: lotDotation.totalWonLots,
        totalInvoicedLots: lotDotation.totalInvoicedLots,
        endingBalance: lotDotation.endingBalance,
      };
    });
  }
}
