import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { JsonProgram, Program } from '../../resource/program.resource';
import { FilteredItem } from '../../resource/filteredItem.resource';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UtilitiesHandler } from '@service/utilities-handlers/utilitiesHandlers';
import { AbstractService } from '@service/abstract.service';

import { Observable } from 'rxjs';

@Injectable()
export class ProgramService extends AbstractService {
  private route: string = 'program';

  constructor(
    private http: HttpClient,
    private utilitiesHandler: UtilitiesHandler
  ) {
    super();
  }

  /**
   * Get the program with ID
   *
   * @param {(string | number)} id
   * @returns {Observable<Program>}
   * @memberof ProgramService
   */
  get(id: string | number): Observable<Program> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}/${id}`;

    return this.http
      .get(api_base_url).pipe(
      map((data: any) =>
        new Program(data)
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get list of programs
   *
   * @param {Object} [filters=null]
   * @returns {Observable<Program[]>}
   * @memberof ProgramService
   */
  public getList(filters: Object = null): Observable<Program[]> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params}).pipe(
      map((data: any) =>
          data._embedded.program
            .map((jsonProgram: any) =>
              new Program(jsonProgram)
            )
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get list of programs for filter
   *
   * @param {Object} [filters=null]
   * @returns {Observable<FilteredItem[]>}
   * @memberof ProgramService
   */
  public getListForFilter(filters: Object = null): Observable<FilteredItem[]> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params}).pipe(
      map((data: any) =>
        data._embedded.program
          .map((jsonProgram: any) =>
            new Program(jsonProgram)
          )
          .map((program: Program) =>
            new FilteredItem(program)
          )
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

    /**
   * Get list of program with purchase dotation
   * @param {*} [filters=null]
   * @returns {Observable<FilteredItem[]>}
   */
  public getListDotationProgramForFilter(filters: any = null): Observable<FilteredItem[]> {
    const api_base_url: string = `${environment.api_base_url}/dotation_program`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params})
      .pipe(
        map((data: any) => {
           return data
             .map((jsonProgram: JsonProgram) => new Program(jsonProgram))
             .map((program: Program) => new FilteredItem(program))
        })
      )
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Save program
   *
   * @param {*} data
   * @returns {Observable<Object>}
   * @memberof ProgramService
   */
  public saveProgram(data): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;

    return this.http
      .post(api_base_url, data)
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Check if program exist
   *
   * @param {string} name
   * @returns {Observable<Program>}
   * @memberof ProgramService
   */
  public programExist(name: string): Observable<Program> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi({exact_name: encodeURIComponent(name)});

    return this.http
      .get(api_base_url, {params})
      .pipe(
        map((data: any) =>
          data._embedded.program
            .map((jsonProgram: any) =>
              new Program(jsonProgram)
            )
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Update type Diffusion/Production for program
   *
   * @param programGroup
   * @return {Observable<Object>}
   */
  public putProductionDiffusion(programGroup): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}/${programGroup.id}`;

    const filters = {
      name: programGroup.name,
      category: programGroup.category,
      type: programGroup.type
    };

    return this.http
      .put(api_base_url, this.convertToUnderscore(filters))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }
}
