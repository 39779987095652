<h2 mat-dialog-title *ngIf="!monitoringLotDotation">Création suivi de lot :</h2>
<h2 mat-dialog-title *ngIf="monitoringLotDotation">Modification suivi de lot :</h2>

<mat-divider></mat-divider>

<mat-progress-bar mode="indeterminate" *ngIf="loading || isSaving" class="progress-bar"></mat-progress-bar>

<mat-dialog-content class="full-width">
  <form [formGroup]="informationGroup" class="full-width" fxLayout="column" fxLayoutAlign="center center"
        *ngIf="informationGroup">

    <!--Channel-->
    <div fxLayout="row" fxLayoutGap="25px" class="full-width-input" fxLayoutAlign="start center">
    <mat-form-field class="width-input">
        <input matInput appRemoveAccents placeholder="Chaine"
               [matAutocomplete]="channelAutocomplete"
               formControlName="channel">
        <mat-error>Veuillez renseigner une chaîne !</mat-error>
      </mat-form-field>
      <mat-autocomplete #channelAutocomplete="matAutocomplete"
                        [displayWith]="autocompleteService.displayAutocomplete.bind(this)">
        <mat-option *ngFor="let channel of filteredChannelItems | async" [value]="channel">
          <img preload fade class="logo" [src]="path + channel.logo">
          {{ channel.display }}
        </mat-option>
      </mat-autocomplete>
    </div>

    <!--Program-->
    <div fxLayout="row" fxLayoutGap="25px" class="full-width-input" fxLayoutAlign="start center">
      <mat-form-field class="width-input">
        <input matInput appRemoveAccents placeholder="Emission" [matAutocomplete]="programAutocomplete"
               formControlName="program">
        <mat-error>Veuillez renseigner une émission !</mat-error>
      </mat-form-field>

      <mat-autocomplete #programAutocomplete="matAutocomplete"
                        [displayWith]="autocompleteService.displayAutocompleteWithValue.bind(this)">
        <mat-option *ngFor="let program of filteredProgramItems | async" [value]="program">
          {{ program.display }} ({{ program.value }})
        </mat-option>
      </mat-autocomplete>

      <button mat-icon-button class="program-dotation" aria-label="Créer une émission" matTooltip="Créer une émission"
              [disabled]="!!!informationGroup.get('channel').value || !!informationGroup.get('program').value"
              (click)="openNewMonitoringLotProgramFormDialog()">
        <mat-icon class="white-text">add</mat-icon>
      </button>
    </div>

    <!--Bilan Number-->
    <div fxLayout="row" fxLayoutGap="25px" class="full-width-input" fxLayoutAlign="start center">
      <mat-form-field class="width-input">
        <input matInput placeholder="Numéro bilan"
              appRemoveAccents
              formControlName="bilanNumber">
        <mat-error>Veuillez renseigner un numéro de bilan !</mat-error>
      </mat-form-field>
    </div>

    <!--Contract Number-->
    <div fxLayout="row" fxLayoutGap="25px" class="full-width-input" fxLayoutAlign="start center">
      <mat-form-field class="width-input">
        <input matInput placeholder="Contrat"
                appRemoveAccents
                formControlName="contractNumber">
        <mat-error>Veuillez renseigner un numéro de contrat !</mat-error>
      </mat-form-field>
    </div>

    <!--Advertiser-->
    <div fxLayout="row" fxLayoutGap="25px" class="full-width-input" fxLayoutAlign="start center">
      <mat-form-field class="width-input">
        <input matInput appRemoveAccents placeholder="Annonceur" [matAutocomplete]="advertiserAutocomplete"
               formControlName="advertiser">
        <mat-error>Veuillez renseigner un annonceur !</mat-error>
      </mat-form-field>
      <mat-autocomplete #advertiserAutocomplete="matAutocomplete" (optionSelected)="valueSelected('advertiser')"
                        [displayWith]="autocompleteService.displayAutocompleteWithValue.bind(this)">
        <mat-option *ngFor="let advertiser of filteredAdvertiserItems | async" [value]="advertiser">
          {{ advertiser.display }} ({{ advertiser.value }})
        </mat-option>
      </mat-autocomplete>
    </div>

    <!--Product-->
    <div fxLayout="row" fxLayoutGap="25px" class="full-width-input" fxLayoutAlign="start center">
      <mat-form-field class="width-input">
        <input appRemoveAccents matInput placeholder="Marque / Produit"
               [matAutocomplete]="productAutocomplete"
               formControlName="product">
        <mat-error>Veuillez renseigner un produit !</mat-error>
      </mat-form-field>
      <mat-autocomplete #productAutocomplete="matAutocomplete" (optionSelected)="valueSelected('product')"
                        [displayWith]="autocompleteService.displayAutocompleteWithValue.bind(this)">
        <mat-option *ngFor="let product of filteredProductItems | async" [value]="product">
          {{ product.display }} ({{ product.value }})
        </mat-option>
      </mat-autocomplete>
    </div>

    <!--Campaign-->
    <div fxLayout="row" fxLayoutGap="25px" class="full-width-input" fxLayoutAlign="start center">
      <mat-form-field class="width-input">
        <input matInput placeholder="Campagne"
               appRemoveAccents
               [matAutocomplete]="campaignAutocomplete"
               formControlName="campaign">
        <mat-error>Veuillez renseigner une campagne !</mat-error>
      </mat-form-field>
      <mat-autocomplete #campaignAutocomplete="matAutocomplete" (optionSelected)="valueSelected('campaign')"
                        [displayWith]="autocompleteService.displayCampaignFullName.bind(this)">
        <mat-option *ngFor="let campaign of filteredCampaignItems | async" [value]="campaign">
          {{ campaign.entity.getFullName() }}
        </mat-option>
      </mat-autocomplete>
    </div>

    <!--Lot-->
    <div fxLayout="row" fxLayoutGap="25px" class="full-width-input" fxLayoutAlign="start center">
      <mat-form-field class="width-input">
        <input matInput placeholder="Lot"
               appRemoveAccents
               [matAutocomplete]="lotAutocomplete"
               formControlName="lot">
        <mat-error>Veuillez renseigner un lot !</mat-error>
      </mat-form-field>
      <mat-autocomplete #lotAutocomplete="matAutocomplete" (optionSelected)="valueSelected('lot')"
                        [displayWith]="autocompleteService.displayAutocompleteWithValue.bind(this)" >
        <mat-option *ngFor="let lot of filteredLotItems | async" [value]="lot">
          <span class="font-bold">{{ lot.display }}</span> -
          <small class="font-italic ">{{ lot.entity.parroptionName }}</small> -
          <small class="small-color">{{ lot.entity.unitPrice | number:'0.2-2':'fr' }}€</small>
        </mat-option>
      </mat-autocomplete>
    </div>
    <!--Purchase-->
    <div fxLayout="row" fxLayoutGap="25px" class="full-width-input" fxLayoutAlign="start center">
      <mat-form-field class="width-input">
        <input matInput appRemoveAccents placeholder="Dossier" [matAutocomplete]="purchaseAutocomplete"
                formControlName="purchase" readonly="true">
      </mat-form-field>
      <mat-autocomplete #purchaseAutocomplete="matAutocomplete"
                        [displayWith]="autocompleteService.displayAutocompleteWithValue.bind(this)"
                        (optionSelected)="valueSelected('purchase')">
        <mat-option *ngFor="let purchase of filteredPurchaseDotationItems | async" [value]="purchase">
          {{ purchase.display }} ({{ purchase.id }})
        </mat-option>
      </mat-autocomplete>
    </div>

    <!--Module-->
    <div fxLayout="row" fxLayoutGap="25px" class="full-width-input" fxLayoutAlign="start center">
      <mat-form-field class="width-input">
        <input matInput appRemoveAccents placeholder="Module" [matAutocomplete]="moduleAutocomplete"
               formControlName="module">
      </mat-form-field>
      <mat-autocomplete #moduleAutocomplete="matAutocomplete"
                        [displayWith]="autocompleteService.displayAutocompleteWithValue.bind(this)">
        <mat-option *ngFor="let filteredModule of filteredModuleDotationItems | async" [value]="filteredModule">
          {{ filteredModule.display }} ({{ filteredModule.id }})
        </mat-option>
      </mat-autocomplete>
    </div>

    <!--Periods-->
    <app-add-monitoring-lot-period [informationGroup]="informationGroup" [monitoringLot]="monitoringLotDotation">
    </app-add-monitoring-lot-period>

  </form>
</mat-dialog-content>

<!--Actions-->
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end stretch" fxLayoutGap="30px">
  <!-- Annuler -->
  <button mat-flat-button mat-dialog-close class="button-flat black white-text"
          (click)="cancel()">Annuler
  </button>

  <!-- Valider et continuer-->
  <button mat-flat-button class="button-flat white-text" [disabled]="isSaving || !informationGroup.valid"
          (click)="submit(false)" *ngIf="!monitoringLotDotation">
    Valider et continuer
  </button>

  <!-- Valider et fermer -->
  <button mat-flat-button class="button-flat white-text" [disabled]="isSaving || !informationGroup.valid"
          (click)="submit(true)">
    Valider et fermer
  </button>
</mat-dialog-actions>
