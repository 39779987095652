import { Base } from './base.resource';
import { Secodip } from './secodip.resource';
import { Advertiser } from './advertiser.resource';

export interface JsonProduct {
    id: number;
    name: string;
    secodip?: Secodip;
    advertiser?: Advertiser;
}

export class Product extends Base {
    public id: number;
    public name: string;
    public secodip: Secodip;
    public advertiser: Advertiser;

    constructor(jsonProduct: JsonProduct) {
        super();
        jsonProduct = this.convertToUnderscore(jsonProduct);
        this.id = jsonProduct.id;
        this.name = jsonProduct.name;
        if (jsonProduct.secodip) {
            this.secodip = new Secodip(jsonProduct.secodip);
        }

        if (jsonProduct.advertiser) {
            this.advertiser = new Advertiser(jsonProduct.advertiser);
        }
    }
}
