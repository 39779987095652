import { OfferProgram } from './offerProgram.resource';
import { Base } from './base.resource';
import { UtilitiesService } from '../service/utilities/utilities.service';

export interface JsonOfferProgramBudget {
    id: number;
    price: number;
    currency: string;
    offer_program: any;
}

export class OfferProgramBudget extends Base {
    public id: number;
    public price: number;
    public currency: string;
    public offerProgram: any;

    public utilitiesService: UtilitiesService;

    constructor(json) {
        super();
        json = this.convertToUnderscore(json);

        this.utilitiesService = new UtilitiesService();

        this.id = json.id;
        this.price = json.price;
        this.currency = json.currency;
        this.offerProgram = this.setOfferProgram(json['offer_program']);
    }

    private setOfferProgram(jsonOfferProgram: any): any {
        if (!jsonOfferProgram) {
            return false;
        };

        if (jsonOfferProgram['_links']) {
            return {
                id: this.utilitiesService.getIdFromLink(jsonOfferProgram['_links'].self.href)
            };
        } else {
            return new OfferProgram(jsonOfferProgram);
        };
    }
}
