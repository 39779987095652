<mat-card class="form-card">
  <mat-card-title>
      <h2 class="no-margin-top">Parties prenantes</h2>
  </mat-card-title>
    <!-- Client -->
    <div class="margin-bottom-legend">
        <mat-progress-bar *ngIf="loadingClient || loading" mode="indeterminate"></mat-progress-bar>
        <span class="sub-title">Client</span>
    </div>

    <mat-card-content>
            <form novalidate [formGroup]="stakeholderGroup">
                <div formGroupName="customer" fxLayout="row" fxLayoutGap="2%">
                    <div fxFlex="60" fxLayout="column">
                        <div fxLayout="row" fxLayoutGap="4%">
                            <div fxFlex="30">
                                <mat-form-field class="full-width">
                                    <mat-icon *ngIf="campaignSelected" matPrefix class="material-icons locked-field"
                                        matTooltip="Ce champ n'est pas modifiable quand une campagne est sélectionnée">lock</mat-icon>
                                    <input appRemoveAccents matInput placeholder="Annonceur"
                                        [readonly]="campaignSelected"
                                        [matAutocomplete]="advertiserAutocomplete"
                                        formControlName="advertiser">
                                    <mat-error *ngIf="!stakeholderGroup['controls'].customer['controls'].advertiser.valid">Ce champ est obligatoire</mat-error>
                                    <mat-hint *ngIf="hintAdvertiser" align="start">Annonceur temporaire</mat-hint>
                                </mat-form-field>
                                <mat-autocomplete #advertiserAutocomplete="matAutocomplete" [displayWith]="autocompleteService.displayAutocomplete.bind(this)">
                                    <mat-option *ngFor="let advertiser of filteredAdvertiserItems | async" [value]="advertiser" (onSelectionChange)="onAdvertiserSelect(advertiser)">
                                        {{ advertiser.display }} ({{ advertiser.value }})
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                            <div fxFlex="30" fxLayout="row">
                                <mat-form-field class="full-width">
                                    <input appRemoveAccents matInput placeholder="Signataire"
                                        [matAutocomplete]="advertiserSignatoryAutocomplete"
                                        formControlName="advertiserSignatory">
                                </mat-form-field>
                                <mat-autocomplete #advertiserSignatoryAutocomplete="matAutocomplete" [displayWith]="autocompleteService.displayAutocomplete.bind(this)">
                                    <mat-option *ngFor="let advertiserSignatory of filteredAdvertiserSignatory | async" [value]="advertiserSignatory">
                                      {{ advertiserSignatory.display }}
                                    </mat-option>
                                </mat-autocomplete>
                                <button (click)="openSignatoryDialog('advertiserSignatory')" mat-icon-button matTooltip="Ajouter un signataire inexistant">
                                  <mat-icon [ngClass]="stakeholderGroup.get('customer').get('advertiser').value.entity ? 'red cursor' : 'hold-color'">add_circle_outline</mat-icon>
                                </button>
                            </div>
                            <div fxFlex="30" fxLayout="row">
                                <mat-form-field class="full-width">
                                    <input appRemoveAccents matInput placeholder="Fonction"
                                        [matAutocomplete]="advertiserFonctionAutocomplete"
                                        formControlName="advertiserFonction">
                                </mat-form-field>
                                <mat-autocomplete #advertiserFonctionAutocomplete="matAutocomplete" [displayWith]="autocompleteService.displayAutocomplete.bind(this)">
                                    <mat-option *ngFor="let advertiserFonction of filteredAdvertiserFonction | async" [value]="advertiserFonction">
                                        {{ advertiserFonction.display }}
                                    </mat-option>
                                </mat-autocomplete>
                                <button (click)="openFunctionDialog('advertiserFonction')" mat-icon-button matTooltip="Ajouter une fonction inexistante">
                                  <mat-icon [ngClass]="stakeholderGroup.get('customer').get('advertiser').value.entity ? 'red cursor' : 'hold-color'">add_circle_outline</mat-icon>
                                </button>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutGap="4%">
                            <div fxFlex="30">
                                <mat-form-field class="full-width">
                                    <mat-icon *ngIf="campaignSelected" matPrefix class="material-icons locked-field"
                                    matTooltip="Ce champ n'est pas modifiable quand une campagne est sélectionnée">lock</mat-icon>
                                    <input matInput placeholder="Mandataire"
                                        appRemoveAccents
                                        [readonly]="campaignSelected"
                                        [matAutocomplete]="mandataryAutocomplete"
                                        formControlName="mandatary">
                                    <mat-hint *ngIf="hintMandatary" align="start">Mandataire temporaire</mat-hint>

                                    <mat-autocomplete #mandataryAutocomplete="matAutocomplete" [displayWith]="autocompleteService.displayAutocomplete.bind(this)">
                                        <mat-option *ngFor="let mandatary of filteredMandataryItems | async" [value]="mandatary" (onSelectionChange)="onMandatarySelect(mandatary)">
                                            {{ mandatary.display }} ({{ mandatary.value }})
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                            <div fxFlex="30" fxLayout="row">
                                <mat-form-field class="full-width">
                                    <mat-icon *ngIf="campaignSelected && stakeholderGroup.get('customer').get('mandatary').value === null"
                                              matPrefix class="material-icons locked-field"
                                              matTooltip="Ce champ n'est pas modifiable quand une campagne est sélectionnée">lock</mat-icon>
                                    <input matInput placeholder="Signataire"
                                        appRemoveAccents
                                        [readonly]="campaignSelected && stakeholderGroup.get('customer').get('mandatary').value === null"
                                        [matAutocomplete]="mandatarySignatoryAutocomplete"
                                        formControlName="mandatarySignatory">
                                    <mat-autocomplete #mandatarySignatoryAutocomplete="matAutocomplete" [displayWith]="autocompleteService.displayAutocomplete.bind(this)">
                                        <mat-option *ngFor="let mandatarySignatory of filteredMandatarySignatory | async" [value]="mandatarySignatory">
                                            {{ mandatarySignatory.display }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <button (click)="openSignatoryDialog('mandatarySignatory')" mat-icon-button matTooltip="Ajouter un signataire inexistant">
                                  <mat-icon [ngClass]="stakeholderGroup.get('customer').get('advertiser').value.entity ? 'red cursor' : 'hold-color'">add_circle_outline</mat-icon>
                                </button>
                            </div>
                            <div fxFlex="30" fxLayout="row">
                                <mat-form-field class="full-width">
                                    <mat-icon *ngIf="campaignSelected && stakeholderGroup.get('customer').get('mandatary').value === null"
                                              matPrefix class="material-icons locked-field"
                                              matTooltip="Ce champ n'est pas modifiable quand une campagne est sélectionnée">lock</mat-icon>
                                    <input matInput placeholder="Fonction"
                                        appRemoveAccents
                                        [readonly]="campaignSelected && stakeholderGroup.get('customer').get('mandatary').value === null"
                                        [matAutocomplete]="mandataryFonctionAutocomplete"
                                        formControlName="mandataryFonction">
                                    <mat-autocomplete #mandataryFonctionAutocomplete="matAutocomplete" [displayWith]="autocompleteService.displayAutocomplete.bind(this)">
                                        <mat-option *ngFor="let mandataryFonction of filteredMandataryFonction | async" [value]="mandataryFonction">
                                            {{ mandataryFonction.display }}
                                            <!-- TODO -->
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <button (click)="openFunctionDialog('mandataryFonction')" mat-icon-button matTooltip="Ajouter une fonction inexistante">
                                  <mat-icon [ngClass]="stakeholderGroup.get('customer').get('advertiser').value.entity ? 'red cursor' : 'hold-color'">add_circle_outline</mat-icon>
                                </button>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutGap="4%">
                            <div fxFlex="30">
                                <mat-form-field class="full-width">
                                    <mat-icon *ngIf="campaignSelected" matPrefix class="material-icons locked-field"
                                        matTooltip="Ce champ n'est pas modifiable quand une campagne est sélectionnée">lock</mat-icon>
                                    <input matInput placeholder="Sous-mandataire"
                                        appRemoveAccents
                                        [readonly]="campaignSelected"
                                        [matAutocomplete]="subMandataryAutocomplete"
                                        formControlName="subMandatary">
                                    <mat-hint *ngIf="hintSubMandatary" align="start">Sous Mandataire temporaire</mat-hint>
                                </mat-form-field>

                                <mat-autocomplete #subMandataryAutocomplete="matAutocomplete" [displayWith]="autocompleteService.displayAutocomplete.bind(this)">
                                    <mat-option *ngFor="let subMandatary of filteredSubMandataryItems | async" [value]="subMandatary" (onSelectionChange)="onSubMandatarySelect(subMandatary)">
                                        {{ subMandatary.display }} ({{ subMandatary.value }})
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                            <div fxFlex="30" fxLayout="row">
                                <mat-form-field class="full-width">
                                    <mat-icon *ngIf="campaignSelected && stakeholderGroup.get('customer').get('subMandatary').value === null"
                                        matPrefix class="material-icons locked-field"
                                        matTooltip="Ce champ n'est pas modifiable quand une campagne est sélectionnée">lock</mat-icon>
                                    <input matInput placeholder="Signataire"
                                        appRemoveAccents
                                        [readonly]="campaignSelected && stakeholderGroup.get('customer').get('subMandatary').value === null"
                                        [matAutocomplete]="subMandatarySignatoryAutocomplete"
                                        formControlName="subMandatarySignatory">
                                    <mat-autocomplete #subMandatarySignatoryAutocomplete="matAutocomplete" [displayWith]="autocompleteService.displayAutocomplete.bind(this)">
                                        <mat-option *ngFor="let subMandatarySignatory of filteredSubMandatarySignatory | async" [value]="subMandatarySignatory">
                                            {{ subMandatarySignatory.display }}
                                            <!-- TODO -->
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <button (click)="openSignatoryDialog('subMandatarySignatory')" mat-icon-button matTooltip="Ajouter un signataire inexistant">
                                    <mat-icon [ngClass]="stakeholderGroup.get('customer').get('advertiser').value.entity ? 'red cursor' : 'hold-color'">add_circle_outline</mat-icon>
                                </button>
                            </div>
                            <div fxFlex="30" fxLayout="row">
                                <mat-form-field class="full-width">
                                    <mat-icon *ngIf="campaignSelected && stakeholderGroup.get('customer').get('subMandatary').value === null"
                                            matPrefix class="material-icons locked-field"
                                            matTooltip="Ce champ n'est pas modifiable quand une campagne est sélectionnée">lock</mat-icon>
                                    <input matInput placeholder="Fonction"
                                        appRemoveAccents
                                         [readonly]="campaignSelected && stakeholderGroup.get('customer').get('subMandatary').value === null"
                                        [matAutocomplete]="subMandataryFonctionAutocomplete"
                                        formControlName="subMandataryFonction">
                                    <mat-autocomplete #subMandataryFonctionAutocomplete="matAutocomplete" [displayWith]="autocompleteService.displayAutocomplete.bind(this)">
                                        <mat-option *ngFor="let subMandataryFonction of filteredSubMandataryFonction | async" [value]="subMandataryFonction">
                                            {{ subMandataryFonction.display }}
                                            <!-- TODO -->
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <button (click)="openFunctionDialog('subMandataryFonction')" mat-icon-button matTooltip="Ajouter une fonction inexistante">
                                    <mat-icon [ngClass]="stakeholderGroup.get('customer').get('advertiser').value.entity ? 'red cursor' : 'hold-color'">add_circle_outline</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div fxFlex="30" fxLayout="column" class="padding-left">
                        <div fxLayout="row" fxLayoutGap="5%">
                            <div fxFlex="65">
                                <mat-form-field class="full-width">
                                    <input appRemoveAccents matInput placeholder="Marque / Produit"
                                        [matAutocomplete]="productAutocomplete"
                                        formControlName="product">
                                    <mat-hint *ngIf="hintProduct" align="start">Produit / Marque temporaire</mat-hint>
                                </mat-form-field>

                                <mat-autocomplete #productAutocomplete="matAutocomplete" [displayWith]="autocompleteService.displayAutocomplete.bind(this)">
                                    <mat-option *ngFor="let product of filteredProductItems | async" [value]="product" (onSelectionChange)="onProductSelect(product)">
                                        {{ product.display }} ({{ product.value }})
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                            <div fxFlex="65">
                                <mat-form-field class="full-width">
                                    <input matInput placeholder="Campagne"
                                        appRemoveAccents
                                        [readonly]="!purchase.product"
                                        [matAutocomplete]="campaignAutocomplete"
                                        formControlName="campaign">
                                </mat-form-field>

                                <mat-autocomplete #campaignAutocomplete="matAutocomplete" [displayWith]="autocompleteService.displayCampaignFullName.bind(this)">
                                    <mat-option *ngFor="let campaign of filteredCampaignItems | async" [value]="campaign" (onSelectionChange)="onCampaignSelect(campaign)">
                                        {{ campaign.entity.getFullName() }}
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutGap="6%">
                            <div fxFlex="65">
                                <mat-form-field class="full-width">
                                    <mat-select formControlName="mandateCertificate" ngDefaultControl
                                        placeholder="Attestation de mandat"
                                        class="form-control-medium size-select margin-select">
                                        <mat-option *ngFor="let mandatCertificate of mandatCertificateItems"
                                                [value]="mandatCertificate.value">
                                            {{ mandatCertificate.display }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div fxFlex="65">
                                <mat-form-field class="full-width">
                                    <mat-icon *ngIf="campaignSelected" matPrefix class="material-icons locked-field"
                                        matTooltip="Ce champ n'est pas modifiable quand une campagne est sélectionnée">lock</mat-icon>
                                    <input matInput placeholder="Secteur"
                                        appRemoveAccents
                                        [readonly]="campaignSelected"
                                        [matAutocomplete]="secodipAutocomplete"
                                        formControlName="secodip">
                                </mat-form-field>

                                <mat-autocomplete #secodipAutocomplete="matAutocomplete" [displayWith]="autocompleteService.displayAutocomplete.bind(this)">
                                    <mat-option *ngFor="let secodip of filteredSecodipItems | async" [value]="secodip">
                                        {{ secodip.display }}
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutGap="6%">
                            <div fxFlex="65">
                                <mat-form-field class="full-width">
                                    <mat-icon *ngIf="campaignSelected" matPrefix class="material-icons locked-field"
                                        matTooltip="Ce champ n'est pas modifiable quand une campagne est sélectionnée">lock</mat-icon>
                                    <input matInput placeholder="Cible"
                                        appRemoveAccents
                                        [readonly]="campaignSelected"
                                        [matAutocomplete]="targetAutocomplete"
                                        formControlName="target">
                                </mat-form-field>

                                <mat-autocomplete #targetAutocomplete="matAutocomplete"
                                    [displayWith]="autocompleteService.displayAutocomplete.bind(this)">
                                    <mat-option *ngFor="let target of filteredTargetItem | async"  [value]="target">
                                        {{ target.display }}
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                            <div fxFlex="65">

                            </div>
                        </div>
                    </div>
                </div>
            </form>
    </mat-card-content>

  <!-- FTV Publicité -->
    <div class="margin-bottom-legend border-top">
        <span class="sub-title">FTP</span>
        <mat-progress-bar *ngIf="loadingMainInformation" mode="indeterminate"></mat-progress-bar>
    </div>

      <mat-card-content>
          <form novalidate [formGroup]="stakeholderGroup">
              <div formGroupName="mainInformation" fxLayout="row" fxLayoutGap="50px">
                  <!-- Commercial -->
                  <div fxFlex="22">
                      <mat-form-field class="full-width">
                          <mat-icon *ngIf="campaignSelected" matPrefix class="material-icons locked-field"
                              matTooltip="Ce champ n'est pas modifiable quand une campagne est sélectionnée">lock</mat-icon>
                          <input matInput appRemoveAccents placeholder="Commercial"
                              [readonly]="campaignSelected"
                              [matAutocomplete]="commercialAutocomplete"
                              formControlName="commercial">
                          <mat-error *ngIf="!stakeholderGroup['controls'].mainInformation['controls'].commercial.valid">Ce champ est obligatoire</mat-error>
                      </mat-form-field>

                      <mat-autocomplete #commercialAutocomplete="matAutocomplete" [displayWith]="autocompleteService.displayAutocompleteWithValue.bind(this)">
                          <mat-option *ngFor="let employee of filteredEmployeeCommercialItems | async"  [value]="employee">
                              {{ employee.display }} ({{ employee.value }})
                          </mat-option>
                      </mat-autocomplete>
                  </div>

                  <!-- Assistante Commercial -->
                  <div fxFlex="22">
                      <mat-form-field class="full-width">
                          <input matInput appRemoveAccents placeholder="Assitant Commercial"
                              [matAutocomplete]="subCommercialAutocomplete"
                              formControlName="subCommercial">
                      </mat-form-field>

                      <mat-autocomplete #subCommercialAutocomplete="matAutocomplete" [displayWith]="autocompleteService.displayAutocompleteWithValue.bind(this)">
                          <mat-option *ngFor="let employee of filteredSubEmployeeCommercialItems | async" [value]="employee">
                              {{ employee.display }} ({{ employee.value }})
                          </mat-option>
                      </mat-autocomplete>
                  </div>

                  <!-- Chargé de planning -->
                  <div fxFlex="22">
                      <mat-form-field class="full-width">
                          <mat-icon *ngIf="campaignSelected" matPrefix class="material-icons locked-field"
                              matTooltip="Ce champ n'est pas modifiable quand une campagne est sélectionnée">lock</mat-icon>
                          <input appRemoveAccents matInput placeholder="Chargé de planning"
                              [readonly]="campaignSelected"
                              [matAutocomplete]="planningAutocomplete"
                              formControlName="planningUser">
                      </mat-form-field>

                      <mat-autocomplete #planningAutocomplete="matAutocomplete" [displayWith]="autocompleteService.displayAutocompleteWithValue.bind(this)">
                          <mat-option *ngFor="let employee of filteredEmployeePlanningItems | async"  [value]="employee">
                              {{ employee.display }} ({{ employee.value }})
                          </mat-option>
                      </mat-autocomplete>
                  </div>

                <!-- Signataire FTP -->
                <div fxFlex="22">
                  <mat-form-field class="full-width">
                    <input appRemoveAccents matInput placeholder="Signataire FTP"
                           [matAutocomplete]="signatoryFTPAutocomplete"
                           formControlName="signatoryFtp">
                    <mat-autocomplete #signatoryFTPAutocomplete="matAutocomplete" [displayWith]="autocompleteService.displayAutocompleteWithValue.bind(this)">
                      <mat-option *ngFor="let signatory of filteredSignatoryFtpItems | async"  [value]="signatory">
                        {{ signatory.display }} ({{ signatory.value }})
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
              </div>
          </form>
      </mat-card-content>
</mat-card>
