import { Base } from './base.resource';

export interface JsonChannel {
    id: string;
    channel_group?: string;
    name: string;
    image: string;
    created?: string
}


export class Channel extends Base {
    public static PATH: string = 'assets/images/logos/channels/';
    public static THEMA_CHANNEL_GROUP = 'W';

    public id: string;
    public channelGroup: string;
    public name: string;
    public image: string;
    public created: string;


    constructor(jsonChannel: JsonChannel) {
        super();
        jsonChannel = this.convertToUnderscore(jsonChannel);

        this.id = jsonChannel.id;
        this.name = jsonChannel.name;
        this.image = jsonChannel.image;

        if (jsonChannel.channel_group) {
            this.channelGroup = jsonChannel.channel_group;
        }
        if (jsonChannel.created) {
            this.created = jsonChannel.created;
        }
    }

    public getFullPath(): string | null {
        return this.image ? `${Channel.PATH}${this.image}` : null;
    }
}
