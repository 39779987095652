import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';

import { GridExtendsComponent } from 'src/app/shared/class/grid-extends.component';

import { Channel } from '../../../resource/channel.resource';
import { Cell } from 'src/app/resource/availability/cell.resource';

import { CustomToastrService } from 'src/app/service/toastr/custom-toastr.service';
import { BroadcastService } from 'src/app/service/broadcast/broadcast.service';
import { GridService } from '../service/grid.service';
import { ArrayService } from 'src/app/service/utilities/array.service';
import { PurchaseService } from 'src/app/service/purchase/purchase.service';
import { PropalCartService } from 'src/app/service/propal-cart/propal-cart.service';
import { GridHeaderService } from '../service/grid.header.service';
import { OfferProgramService } from 'src/app/service/offer-program/offer-program.service';
import { GrpService } from '@service/grp/grp.service';
import { ScheduleService } from '@service/schedule/schedule.service';

import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

@Component({
  selector: 'app-grid-offer-group',
  templateUrl: './grid-offer-group.component.html',
  styleUrls: ['./grid-offer-group.component.scss']
})
export class GridOfferGroupComponent extends GridExtendsComponent implements OnInit, OnDestroy {
  @Input() offerProgram;
  @Input() filters;
  @Input() expanded: boolean;
  @Input() target;
  @Input() lastOffer: boolean;

  @Output() expandPanel: EventEmitter<boolean> = new EventEmitter();

  public path: string = Channel.PATH;
  public loading: boolean = true;
  public week: number;
  public startWeek: number;
  public gridData: any[];
  public grpOffer: Object = {};
  public saveValue: {total: number, length: number} = {total: null, length: null};
  public oldTotal: number = null;
  public oldLength: number = null;
  public broadcastsTimes;
  public budgetToltip: string;

  private listChecked: Array<Number> = [];

  destroyCompleteList$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public grpService: GrpService,
    public offerProgramService: OfferProgramService,
    public customToastrService: CustomToastrService,
    public broadcastService: BroadcastService,
    public gridService: GridService,
    public arrayService: ArrayService,
    public purchaseService: PurchaseService,
    public propalCartService: PropalCartService,
    public gridHeaderService: GridHeaderService,
    public scheduleService: ScheduleService
    ) {
    super(
      customToastrService,
      broadcastService,
      gridService,
      arrayService,
      purchaseService,
      propalCartService,
      gridHeaderService,
      offerProgramService,
      scheduleService
    );
  }

  ngOnInit() {
    this.grpService.getGrpOffer(this.offerProgram.id, this.filters.year)
      .pipe(takeUntil(this.destroyCompleteList$))
      .subscribe((data) => {
        this.grpOffer['offer'] = data.offer;
    });

    this.offerProgramService.allBroadcast
      .pipe(
        filter((item) => {
          if (item[this.offerProgram.id]) {
            return this.offerProgram.program.length === Object.keys(item[this.offerProgram.id]).length;
          } else {
            return false;
          }
        }),
        takeUntil(this.destroyCompleteList$),
      )
      .subscribe((data) => {
        let xdata: Array<any> = [];
        let wdata: Array<any> = [];
        let dictionary: Array<string> = Object.keys(data[this.offerProgram.id]);

        for (let i = 0; i < dictionary.length; i++) {
          xdata.push(data[this.offerProgram.id][dictionary[i]].mergedArray);
          wdata.push(data[this.offerProgram.id][dictionary[i]].mergedTime);
        }
        this.checkCompletelyChecked(xdata);
        this.checkCompletelyTimes(wdata);
        if ((this.saveValue.total !== this.oldTotal) || (this.saveValue.length !== this.oldLength)) {
          this.constructHeader();
          this.grpOffer['week'] = this.week;
          this.grpOffer['startWeek'] = this.startWeek;
          this.createCell(xdata);
          this.oldTotal = this.saveValue.total;
          this.oldLength = this.saveValue.length;
        }
        this.loading = false;
      });
      this.buildBudgetOffer();
  }

  ngOnDestroy() {
    this.destroyCompleteList$.next(true);
    this.destroyCompleteList$.unsubscribe();
  }

  /**
   * Check a list for create Time list
   *
   * @param {*} times
   * @memberof GridOfferGroupComponent
   */
  checkCompletelyTimes(times: any): void {
    let timeChecked = [];

    for (let i = 0; i < times[0].length; i++) {
      timeChecked.push([]);

      times.forEach((element) => {
        timeChecked[i] = timeChecked[i].concat(element[i]);
      });
    }
    this.broadcastsTimes = timeChecked;
  }

  /* Build string for tooltip information budget
   *
   * @returns {void}
   * @memberof GridOfferGroupComponent
   */
  buildBudgetOffer(): void {
    this.budgetToltip = `Base communication : ${this.offerProgram.marketingBaseNumber} ${this.offerProgram.marketingBaseType}\n\n`;
    this.budgetToltip += `Total TV : ${this.offerProgram.tvBudget} € NET\n`;
    this.budgetToltip += `Total DIGITAL : ${this.offerProgram.digitalBudget} € NET\n`;
    this.budgetToltip += `Total : ${this.offerProgram.globalPrice} € NET\n\n`;

    if (this.offerProgram && this.offerProgram.program && this.offerProgram.program.length) {
      this.offerProgram.program.forEach(element => {
        this.budgetToltip += `- ${element.program.name}: ${element.offerProgramBudgets[0].price} € NET\n`;
      });
    }
  }

  /**
   * Check a list-object of checked for the next step
   *
   * @return {void}
   * @param {*} offer
   * @memberof GridOfferGroupComponent
   */
  checkCompletelyChecked(offer: any): void {
    let listChecked: Array<Object> = [];
    this.listChecked = [];

    offer.forEach((program, indexProgram) => {
      listChecked.push({});
      program.forEach((column, indexColumn) => {
        let cellChecked: boolean = false;
        let other: number = 0;

        column.forEach((cell) => {
          if (cell.isCompletelyChecked) {
            cellChecked = true;
          }
          if (cell.type === 'achat' || cell.type === 'achatEnd' || cell.type === 'unavailable') {
            other++;
          }
        });
        if (cellChecked) {
          listChecked[indexProgram][indexColumn] = 'checked';
        } else if (column.length === other) {
          listChecked[indexProgram][indexColumn] = 'other';
        }
      });
    });
    this.createCompletelyChecked(listChecked, offer);
  }

  /**
   * Create list with the cehcecked list for next step
   *
   * @param {*} listChecked
   * @param {*} offer
   * @returns {void}
   * @memberof GridOfferGroupComponent
   */
  createCompletelyChecked(listChecked, offer): void {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    let dictionary = Object.keys(listChecked[0]);

    dictionary.forEach((position) => {
      let iteration: number = 0;
      let other: number = 0;

      listChecked.forEach((program) => {
        if (program[position] === 'checked')  {
          iteration++;
        } else if (program[position] === 'other') {
          other++;
        }
      });
      if ((iteration === (offer.length - other)) && iteration > 0) {
        this.listChecked.push(Number(position));
      }
    });
    this.saveValue.total = this.listChecked.length > 0 ? Number(this.listChecked.reduce(reducer)) : 0;
    this.saveValue.length = this.listChecked.length;
  }

  /**
   * Create Cell with the color and name for normal mode and offer mode
   *
   * @param data Any
   * @returns void
   */
  createCell(data: any): void {
    let groupeOffer = [];

    for (let i = 0; i < data[0].length; i++) {
      let groupeProgram: number = 0;
      let groupeBuy: number = 0;
      let groupeThematic: number = 0;
      let groupePresence: boolean = false;
      let groupeInformation: Array<any> = [];
      let groupeDisable: number = 0;

      data.forEach(element => {
        let program: number = 0;
        let buy: number = 0;
        let thematic: number = 0;
        let soReach: number = 0; // Count the total of soReach for determinate if have groupePresence
        let information: Array<any> = [];
        let disable: number = 0;

        // calculate each cell of emission
        element[i].forEach((item: Cell) => {
          if (item.channelGroup === 'W') {
            thematic++;
          }
          if (item.isDisable && item.channelGroup !== 'W') {
            disable++;
          }
          if (item.type === 'available' && item.channelGroup !== 'W') {
            program++;
          } else if (item.type === 'achat' || item.type === 'achatEnd') {
            let tmpInformation: Object = item.tooltip;
            tmpInformation['channelImage'] = item.channelImage;
            tmpInformation['programName'] = item.programName;
            information.push(tmpInformation);

            if (item['soReach']) {
              soReach++;
            } else {
              buy++;
            }
          }
        });
        // total of emission
        if (program > 0) {
          groupeProgram++;
          // If have some purchase/soReach add concurrence (A)
          if ((buy > 0 || soReach > 0) && ((program - thematic) > 0)) {
            groupePresence = true;
          }
        } else if (buy > 0) {
          groupeBuy++;
        }
        if (thematic > 0) {
          groupeThematic++;
        }
        if (information.length > 0) {
          groupeInformation.push(information);
        }
        if (disable > 0) {
          groupeDisable++;
        }
      });

      let cell: Cell;
      //  total of offer and dont allow when have disable program
      if ((groupeProgram + groupeDisable) === (data.length - groupeThematic) && (groupeDisable !== data.length) && groupeProgram > 0) {
        cell = new Cell('available');
      } else if ((groupeProgram < (data.length - groupeThematic)) && groupeProgram > 0) {
        cell = new Cell('partially-available');
      } else if (groupeBuy > 0) {
        cell = new Cell('full-purchase');
      } else {
        cell = new Cell('unavailable');
      }

      if (groupePresence) {
        cell['isConcurrence'] = true;
      }
      if (groupeInformation.length !== 0) {
        cell['information'] = groupeInformation;
      }
      if (this.listChecked.find((item) => item === i) !== undefined) {
        cell['isCompletelyChecked'] = true;
      }
      groupeOffer.push(new Array(cell));
    }
    this.gridData = groupeOffer;
  }

  /**
   * Emit Event when click on pannel
   * @returns void
   */
  expandOffer(): void {
    this.expandPanel.emit(true);
  }
}
