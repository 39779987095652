import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import {
  Event,
  JsonEvent,
  OfferProgram,
} from "../../resource/offerProgram.resource";
import { UtilitiesHandler } from "@service/utilities-handlers/utilitiesHandlers";
import { AbstractService } from "@service/abstract.service";

import { environment } from "../../../environments/environment";

import { Observable, Subject } from "rxjs";
import { map, catchError, tap } from "rxjs/operators";
import * as fileServer from "file-saver";

@Injectable()
export class OfferProgramService extends AbstractService {
  private route: string = "offer_program";
  private reloadSource: Subject<any> = new Subject<boolean>();
  private soReachOfferProgramsRoute: string =
    "soreach_offer_programs_availability";
  private conducteurExportRoute: string = "export_conducteur";
  private evtListRoute: string = "get_evt_list";
  private offerProgramEvtRoute: string = "get_offer_program_events";
  private updateEventRoute: string = "update_offer_program_events";

  public reloadSource$ = this.reloadSource.asObservable();
  public allBroadcast: Subject<Object> = new Subject<{}>();

  public eventList: Event[] = [];
  public offerProgramEvent: Event[] = [];

  constructor(
    private http: HttpClient,
    private utilitiesHandler: UtilitiesHandler
  ) {
    super();
  }

  /**
   * Reload (event)
   *
   * @returns {void}
   * @memberof OfferProgramService
   */
  public reload(): void {
    this.reloadSource.next();
  }

  /**
   * Get offer program with ID
   *
   * @param {(string | number)} id
   * @returns {Observable<OfferProgram>}
   * @memberof OfferProgramService
   */
  public get(id: string | number): Observable<OfferProgram> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}/${id}`;

    return this.http
      .get(api_base_url)
      .pipe(
        map((data: any) => {
          const offerProgram = new OfferProgram(data);
          if (data && data["_embedded"] && data["_embedded"].so_reachs) {
            offerProgram["soReach"] = data["_embedded"].so_reachs;
          }
          return offerProgram;
        })
      )
      .pipe(catchError(this.utilitiesHandler.handleErrorApi));
  }

  /**
   * Get list of offer program for (search)
   *
   * @param {*} [filters=null]
   * @param {string} [groups=null]
   * @param {boolean} [mode]
   * @returns {Observable<OfferProgram[]>}
   * @memberof OfferProgramService
   */
  public getList(
    filters: any = null,
    groups: string = null,
    mode?: boolean
  ): Observable<OfferProgram[]> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;

    if (groups) {
      filters.groups = groups;
    }
    if (mode) {
      filters.mode = "offer";
    }

    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, { params })
      .pipe(
        map((data: any) =>
          data._embedded.offer_program.map(
            (jsonOfferProgram: any) => new OfferProgram(jsonOfferProgram)
          )
        )
      )
      .pipe(catchError(this.utilitiesHandler.handleErrorApi));
  }

  /**
   * Save offer program
   *
   * @param {*} offerProgramData
   * @returns {Observable<Object>}
   * @memberof OfferProgramService
   */
  public saveOfferProgram(offerProgramData): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;

    return this.http
      .post(api_base_url, this.convertToUnderscore(offerProgramData))
      .pipe(map((data: any) => new OfferProgram(data)))
      .pipe(catchError(this.utilitiesHandler.handleErrorApi));
  }

  /**
   * Update offer program
   *
   * @param partialOfferProgram
   * @param id {number}
   */
  public updateOfferProgram(
    partialOfferProgram,
    id: number
  ): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}/${id}`;

    return this.http
      .patch(api_base_url, this.convertToUnderscore(partialOfferProgram))
      .pipe(catchError(this.utilitiesHandler.handleErrorApi));
  }

  /**
   * Update Offer Program with SoReach
   *
   * @param offerProgram
   */
  public updateOfferProgramSoReach(offerProgram): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}/${offerProgram.id}`;
    const data = { soReachs: offerProgram["soReach"] };

    return this.http
      .patch(api_base_url, this.convertToUnderscore(data))
      .pipe(catchError(this.utilitiesHandler.handleErrorApi));
  }

  /**
   * Detele program // TODO NEED MORE TEST
   *
   * @param {OfferProgram} offerProgram
   * @returns {Observable<Object>}
   * @memberof OfferProgramService
   */
  public delete(offerProgram: OfferProgram): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}/${offerProgram.id}`;

    return this.http
      .delete(api_base_url)
      .pipe(catchError(this.utilitiesHandler.handleErrorApi));
  }

  /**
   * Get soReach types for offer program
   *
   * @param {Object} [filters=null]
   * @return {Observable<Object>}
   * @memberof ProgramService
   */
  public getSoReachOfferPrograms(filters: Object = null): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.soReachOfferProgramsRoute}`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, { params })
      .pipe(catchError(this.utilitiesHandler.handleErrorApi));
  }

  /**
   *
   * @param filters
   */
  public generateXlsxConducteur(filters: Object = null): Observable<any> {
    const apiUrl: string = `${environment.api_base_url}/${this.conducteurExportRoute}`;
    const xlsxType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(apiUrl, { params: params, responseType: "blob" })
      .pipe(
        map((response: any) => {
          const blob = new Blob([response], { type: xlsxType });
          fileServer.saveAs(blob, "conducteur.xlsx");
        })
      )
      .pipe(catchError(this.utilitiesHandler.handleErrorApi));
  }

  public getEventList(): Observable<Object> {
    const apiBaseUrl = `${environment.api_base_url}/${this.evtListRoute}`;

    return this.http
      .get(apiBaseUrl, {})
      .pipe(
        map((response: []) => {
          return (this.eventList = response.map(
            (event: JsonEvent) => new Event(event)
          ));
        })
      )
      .pipe(catchError(this.utilitiesHandler.handleErrorApi));
  }

  public getOfferProgramEvents(filters: Object = null): Observable<Object> {
    const apiBaseUrl = `${environment.api_base_url}/${this.offerProgramEvtRoute}`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(apiBaseUrl, { params })
      .pipe(
        map((response: []) => {
          return (this.offerProgramEvent = response.map(
            (event: JsonEvent) => new Event(event)
          ));
        })
      )
      .pipe(catchError(this.utilitiesHandler.handleErrorApi));
  }

  public updateEvents(selectedEventList): Observable<any> {
    const api_base_url: string = `${environment.api_base_url}/${this.updateEventRoute}`;

    return this.http
      .post(api_base_url, this.convertToUnderscore(selectedEventList))
      .pipe(catchError(this.utilitiesHandler.handleErrorApi));
  }

  public deepSearch(arr, target, arrayToDisplay): any {
    for (let obj of arr) {
      for (let key in obj) {
        if (key === "libevt" && obj[key] === target.libevt.toUpperCase()) {
          if (!arrayToDisplay.includes(obj)) {
            obj.isSelected = true;
            arrayToDisplay.push(obj);
          }
        } else {
          if (typeof obj[key] === "object") {
            this.deepSearch(obj[key], target, arrayToDisplay);
          }
        }
      }
    }
    return null;
  }

  /**
   * If we remove an event's parent, we must find its children and remove them as well
   * @param element
   * @param codevtList
   */
  public getChildrenEventSelected(element, codevtList) {
    if (element.children) {
      element.children.forEach((item) => {
        if (item.isSelected) {
          codevtList.push(item.codevt);
          item.isSelected = false;
        }
        this.getChildrenEventSelected(item, codevtList);
      });
    }
  }

  public getCodevtList(eventListSelected: any, deleting: boolean): any[] {
    let codevtList = [];
    eventListSelected.forEach((element) => {
      if (!codevtList.includes(element.codevt)) {
        codevtList.push(element.codevt);
      }
      // if element has parent, we add the parent codevt also
      if (element.parent && !deleting) {
        element.parent.forEach((item) => {
          if (!codevtList.includes(item)) {
            codevtList.push(item);
          }
        });
      } else {
        // if we remove parent codevt, we also removing its children
        this.getChildrenEventSelected(element, codevtList);
      }
    });

    return codevtList;
  }
}
