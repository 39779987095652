import { Schedule } from './schedule.resource';
import { Base } from '../base.resource';

export interface JsonScheduleException {
    id: number;
    label?: string;
    except_start_period: string;
    except_end_period: string;
    except_start_time: string;
    except_end_time: string;
    schedule?: any;
    parent?: any;
}

export class ScheduleException extends Base {
    public id: number;
    public label: string;
    public exceptStartPeriod: string;
    public exceptEndPeriod: string;
    public exceptStartTime: string;
    public exceptEndTime: string;
    public schedule: Schedule;
    public parent: ScheduleException;

    constructor(jsonScheduleException: JsonScheduleException ) {
        super();
        jsonScheduleException = this.convertToUnderscore(jsonScheduleException);

        this.id = jsonScheduleException.id;
        this.label = jsonScheduleException.label;

        this.setExcepts(jsonScheduleException);

        if (jsonScheduleException.schedule) {
            this.schedule = new Schedule(jsonScheduleException.schedule);
        }

        if (jsonScheduleException.parent) {
            this.parent = new ScheduleException(jsonScheduleException.parent);
        }
    }

    private setExcepts(jsonScheduleException: JsonScheduleException) {
        // from FORM
        if (jsonScheduleException['except'] && Array.isArray(jsonScheduleException['except'])) {
            if (jsonScheduleException['except'].length === 0) {
                this.resetExcepts();
                return;
            }
            let exceptFromForm = this.convertToUnderscore(jsonScheduleException['except'][0]);
            this.exceptStartPeriod = exceptFromForm.except_start_period;
            this.exceptEndPeriod = exceptFromForm.except_end_period;
            this.exceptStartTime = exceptFromForm.except_start_time;
            this.exceptEndTime = exceptFromForm.except_end_time;
        } else if (jsonScheduleException.except_start_period && !jsonScheduleException.except_start_period['date']) {
           this.exceptStartPeriod = jsonScheduleException.except_start_period ? jsonScheduleException.except_start_period : null;
           this.exceptEndPeriod = jsonScheduleException.except_end_period ? jsonScheduleException.except_end_period : null;
           this.exceptStartTime = jsonScheduleException.except_start_time;
           this.exceptEndTime = jsonScheduleException.except_end_time;

       } else {
           // from API
           this.exceptStartPeriod = jsonScheduleException.except_start_period ? jsonScheduleException.except_start_period['date'] : null;
           this.exceptEndPeriod = jsonScheduleException.except_end_period ? jsonScheduleException.except_end_period['date'] : null;
           this.exceptStartTime = jsonScheduleException.except_start_time;
           this.exceptEndTime = jsonScheduleException.except_end_time;
       }
    }

    private resetExcepts() {
        this.exceptStartPeriod = null;
        this.exceptEndPeriod = null;
        this.exceptStartTime = null;
        this.exceptEndTime = null;
    }
}
