import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material';

import { CustomToastrService } from '@service/toastr/custom-toastr.service';
import { PurchaseService } from '@service/purchase/purchase.service';
import { AutocompleteService } from '@service/utilities/autocomplete.service';
import { PurchaseBudgetService } from '@service/purchase-budget/purchase-budget.service';
import { PurchaseProgramService } from '@service/purchase-program/purchase-program.service';
import { PurchaseAbatementService } from '@service/purchase-abatement/purchase-abatement.service';

import { Purchase } from '../../../../resource/purchase.resource';
import { FilteredItem } from '../../../../resource/filteredItem.resource';
import { PurchaseAbatement } from '../../../../resource/purchase-abatement.resource';

import { AbatementComponent } from '../../dialog/abatement/abatement.component';

import { Observable, of, Subject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';

import * as moment from 'moment';

@Component({
  selector: 'app-purchase-global-budget',
  templateUrl: './purchase-global-budget.component.html',
  styleUrls: ['./purchase-global-budget.component.scss'],
})
export class PurchaseGlobalBudgetComponent implements OnInit, OnChanges {
  @Input() purchase: Purchase;

  public purchaseGlobalBudgetGroup: FormGroup;
  public isSocialHidden: boolean = true;
  public isSocialExpanded: boolean = false;
  public isVideoHidden: boolean = true;
  public isVideoExpanded: boolean = false;

  private compensationPurchaseItems: FilteredItem[];
  public filteredCompensationPurchaseItems: Observable<FilteredItem[]>;
  public compensationFormGroup: FormGroup;

  private sectionArray = ['tv', 'digital'];
  private tvSectionArray = ['Classic', 'Region', 'Domtom', 'Thema', 'Inter', 'F4'];
  private digitalAllSectionArray = ['Display', 'Replay', 'VideoBill', 'VideoPre', 'Social', 'SocialFb',
    'SocialTwit', 'SocialInsta', 'Other'];
  private digitalWithoutSomeSectionArray = ['Display', 'VideoBill', 'VideoPre', 'SocialFb', 'SocialTwit', 'SocialInsta' , 'Other'];
  private digitalSectionArray = ['Display', 'Replay', 'Social' , 'Other'];
  private digitalSubCategoryVideoArray = ['VideoBill', 'VideoPre'];
  private digitalSubCategorySocialArray = ['SocialFb', 'SocialTwit', 'SocialInsta'];
  private loadingComposation: boolean = false;
  private fieldFocused: string;
  private componentDestroyed: Subject<any> = new Subject();

  public purchasedAbatClassic: Observable<PurchaseAbatement[]>;
  public purchasedAbatRegion: Observable<PurchaseAbatement[]>;
  public purchasedAbatDomTom: Observable<PurchaseAbatement[]>;
  public purchasedAbatInter: Observable<PurchaseAbatement[]>;
  public purchasedAbatThema: Observable<PurchaseAbatement[]>;
  public purchasedAbatF4: Observable<PurchaseAbatement[]>;

  public budgetType = '';
  public dialogRef;
  public updatingValues: boolean = false;
  public settingValues: boolean = false;
  public loading: boolean;
  public purchaseYear: string;

  constructor(
    private fb: FormBuilder,
    private purchaseService: PurchaseService,
    private purchaseProgramService: PurchaseProgramService,
    private purchaseBudgetService: PurchaseBudgetService,
    private purchaseAbatementService: PurchaseAbatementService,
    private dialog: MatDialog,
    public autocompleteService: AutocompleteService,
    private customToastrService: CustomToastrService
  ) { }

  /**
   * Register the focused field
   *
   * @param  {string} field
   */
  public setFocus(field: string) {
    this.fieldFocused = field;
  }

  ngOnInit() {
    this.purchaseYear = moment(this.purchase.startCommunicationPeriod).format('YYYY')
    this.purchaseBudgetService.globalBudgetLoadingSource$
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(loading => {
        this.loading = loading;
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.purchaseService.purchaseSource$
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(purchase => {
        if (purchase.advertiser) {
          this.purchase = purchase;
          this.getCompensationPurchase();
        }
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.initForm();
    this.populateForm();
    this.getCompensationPurchase();
    this.purchaseGlobalBudgetGroup.controls['tv']['controls']['tvBudget']['controls']['tvBudgetRate'].valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('tvBudgetRate', data);
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.purchaseGlobalBudgetGroup.controls['tv']['controls']['tvBudget']['controls']['tvBudgetNet'].valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('tvBudgetNet', data);
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.purchaseGlobalBudgetGroup.controls['tv']['controls']['tvClassic']['controls']['tvClassicBudgetNet'].valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('tvClassicBudgetNet', data);
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.purchaseGlobalBudgetGroup.controls['tv']['controls']['tvClassic']['controls']['tvClassicBudgetRate'].valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('tvClassicBudgetRate', data);
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    // FTP Regional
    this.purchaseGlobalBudgetGroup.controls['tv']['controls']['tvRegion']['controls']['tvRegionBudgetNet'].valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('tvRegionBudgetNet', data);
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.purchaseGlobalBudgetGroup.controls['tv']['controls']['tvRegion']['controls']['tvRegionBudgetRate'].valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('tvRegionBudgetRate', data);
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    // FTP Dom-Tom
    this.purchaseGlobalBudgetGroup.controls['tv']['controls']['tvDomtom']['controls']['tvDomtomBudgetNet'].valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('tvDomtomBudgetNet', data);
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.purchaseGlobalBudgetGroup.controls['tv']['controls']['tvDomtom']['controls']['tvDomtomBudgetRate'].valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('tvDomtomBudgetRate', data);
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });


    // FTP Thematic
    this.purchaseGlobalBudgetGroup.controls['tv']['controls']['tvThema']['controls']['tvThemaBudgetNet'].valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('tvThemaBudgetNet', data);
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.purchaseGlobalBudgetGroup.controls['tv']['controls']['tvThema']['controls']['tvThemaBudgetRate'].valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('tvThemaBudgetRate', data);
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    // FTP International
    this.purchaseGlobalBudgetGroup.controls['tv']['controls']['tvInter']['controls']['tvInterBudgetNet'].valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('tvInterBudgetNet', data);
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.purchaseGlobalBudgetGroup.controls['tv']['controls']['tvInter']['controls']['tvInterBudgetRate'].valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('tvInterBudgetRate', data);
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    // FTP France 4
    this.purchaseGlobalBudgetGroup.controls['tv']['controls']['tvF4']['controls']['tvF4BudgetNet'].valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('tvF4BudgetNet', data);
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.purchaseGlobalBudgetGroup.controls['tv']['controls']['tvF4']['controls']['tvF4BudgetRate'].valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('tvF4BudgetRate', data);
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });
 
    // Digital budget
    this.purchaseGlobalBudgetGroup.controls['digital']['controls']['digitalBudget']['controls']['digitalBudgetNet'].valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('digitalBudgetNet', data, 'digital');
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.purchaseGlobalBudgetGroup.controls['digital']['controls']['digitalBudget']['controls']['digitalBudgetRate'].valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('digitalBudgetRate', data, 'digital');
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });


    // Digital Vidéo CA INI
    this.purchaseGlobalBudgetGroup.controls['digital']['controls']['digitalReplay']['controls']['digitalReplayBudget'].valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('digitalReplayBudget', data, 'digital');
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.purchaseGlobalBudgetGroup.controls['digital']['controls']['digitalReplay']['controls']['digitalReplayBudgetNet']
      .valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('digitalReplayBudgetNet', data, 'digital');
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.purchaseGlobalBudgetGroup.controls['digital']['controls']['digitalReplay']['controls']['digitalReplayBudgetRate']
      .valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('digitalReplayBudgetRate', data, 'digital');
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    // Digital Video Billboard CA INI
    this.purchaseGlobalBudgetGroup.controls['digital']['controls']['digitalVideoBill']['controls']['digitalVideoBillBudget']
      .valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('digitalVideoBillBudget', data, 'digital');
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.purchaseGlobalBudgetGroup.controls['digital']['controls']['digitalVideoBill']['controls']['digitalVideoBillBudgetNet']
      .valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('digitalVideoBillBudgetNet', data, 'digital');
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.purchaseGlobalBudgetGroup.controls['digital']['controls']['digitalVideoBill']['controls']['digitalVideoBillBudgetRate']
      .valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('digitalVideoBillBudgetRate', data, 'digital');
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    // Digital Video Préroll CA INI
    this.purchaseGlobalBudgetGroup.controls['digital']['controls']['digitalVideoPre']['controls']['digitalVideoPreBudget']
      .valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('digitalVideoPreBudget', data, 'digital');
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.purchaseGlobalBudgetGroup.controls['digital']['controls']['digitalVideoPre']['controls']['digitalVideoPreBudgetNet']
      .valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('digitalVideoPreBudgetNet', data, 'digital');
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.purchaseGlobalBudgetGroup.controls['digital']['controls']['digitalVideoPre']['controls']['digitalVideoPreBudgetRate']
      .valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('digitalVideoPreBudgetRate', data, 'digital');
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    // Digital "Display" CA INI
    this.purchaseGlobalBudgetGroup.controls['digital']['controls']['digitalDisplay']['controls']['digitalDisplayBudget']
      .valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('digitalDisplayBudget', data, 'digital');
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.purchaseGlobalBudgetGroup.controls['digital']['controls']['digitalDisplay']['controls']['digitalDisplayBudgetNet']
      .valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('digitalDisplayBudgetNet', data, 'digital');
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.purchaseGlobalBudgetGroup.controls['digital']['controls']['digitalDisplay']['controls']['digitalDisplayBudgetRate']
      .valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('digitalDisplayBudgetRate', data, 'digital');
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    // Digital "Social" CA INI
    this.purchaseGlobalBudgetGroup.controls['digital']['controls']['digitalSocial']['controls']['digitalSocialBudget'].valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('digitalSocialBudget', data, 'digital');
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.purchaseGlobalBudgetGroup.controls['digital']['controls']['digitalSocial']['controls']['digitalSocialBudgetNet'].valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('digitalSocialBudgetNet', data, 'digital');
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.purchaseGlobalBudgetGroup.controls['digital']['controls']['digitalSocial']['controls']['digitalSocialBudgetRate']
      .valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('digitalSocialBudgetRate', data, 'digital');
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    // Digital "Social Facebook" CA INI
    this.purchaseGlobalBudgetGroup.controls['digital']['controls']['digitalSocialFb']['controls']['digitalSocialFbBudget']
      .valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('digitalSocialFbBudget', data, 'digital');
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.purchaseGlobalBudgetGroup.controls['digital']['controls']['digitalSocialFb']['controls']['digitalSocialFbBudgetNet']
      .valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('digitalSocialFbBudgetNet', data, 'digital');
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.purchaseGlobalBudgetGroup.controls['digital']['controls']['digitalSocialFb']['controls']['digitalSocialFbBudgetRate']
      .valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('digitalSocialFbBudgetRate', data, 'digital');
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    // Digital "Social Twitter" CA INI
    this.purchaseGlobalBudgetGroup.controls['digital']['controls']['digitalSocialTwit']['controls']['digitalSocialTwitBudget']
      .valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('digitalSocialTwitBudget', data, 'digital');
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.purchaseGlobalBudgetGroup.controls['digital']['controls']['digitalSocialTwit']['controls']['digitalSocialTwitBudgetNet']
      .valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('digitalSocialTwitBudgetNet', data, 'digital');
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.purchaseGlobalBudgetGroup.controls['digital']['controls']['digitalSocialTwit']['controls']['digitalSocialTwitBudgetRate']
      .valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('digitalSocialTwitBudgetRate', data, 'digital');
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    // Digital "Social Instagram" CA INI
    this.purchaseGlobalBudgetGroup.controls['digital']['controls']['digitalSocialInsta']['controls']['digitalSocialInstaBudget']
      .valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('digitalSocialInstaBudget', data, 'digital');
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.purchaseGlobalBudgetGroup.controls['digital']['controls']['digitalSocialInsta']['controls']['digitalSocialInstaBudgetNet']
      .valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('digitalSocialInstaBudgetNet', data, 'digital');
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.purchaseGlobalBudgetGroup.controls['digital']['controls']['digitalSocialInsta']['controls']['digitalSocialInstaBudgetRate']
      .valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('digitalSocialInstaBudgetRate', data, 'digital');
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    // Digital "Autres" CA INI
    this.purchaseGlobalBudgetGroup.controls['digital']['controls']['digitalOther']['controls']['digitalOtherBudget'].valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('digitalOtherBudget', data, 'digital');
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.purchaseGlobalBudgetGroup.controls['digital']['controls']['digitalOther']['controls']['digitalOtherBudgetNet'].valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('digitalOtherBudgetNet', data, 'digital');
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.purchaseGlobalBudgetGroup.controls['digital']['controls']['digitalOther']['controls']['digitalOtherBudgetRate'].valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('digitalOtherBudgetRate', data, 'digital');
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    // Total budget
    this.purchaseGlobalBudgetGroup.controls['total']['controls']['totalBudgetNet'].valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('totalBudgetNet', data);
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.purchaseGlobalBudgetGroup.controls['total']['controls']['totalBudgetRate'].valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.computeBudget('totalBudgetRate', data);
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });


    this.purchaseGlobalBudgetGroup.valueChanges
      .pipe(
        debounceTime(500),
        takeUntil(this.componentDestroyed)
      )
      .subscribe(data => {
        if (!this.settingValues) {
          this.purchaseService.updateGlobalBudget(data);
        }
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.purchaseBudgetService.purchaseBudgetLoadFromResource$
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(reloadFromResource => {
        if (reloadFromResource) {
          this.settingValues = true;
          this.updatingValues = true;
          this.populateForm();
          this.settingValues = false;
          this.updatingValues = false;
        }
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.purchaseAbatementService.loadPurchaseAbatements(this.purchase, 'F')
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(() => {
        this.purchasedAbatClassic = of(this.purchaseAbatementService.getPurchaseAbatements('F'));
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.purchaseAbatementService.loadPurchaseAbatements(this.purchase, 'FREG')
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(() => {
        this.purchasedAbatRegion = of(this.purchaseAbatementService.getPurchaseAbatements('FREG'));
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.purchaseAbatementService.loadPurchaseAbatements(this.purchase, 'O')
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(() => {
        this.purchasedAbatDomTom = of(this.purchaseAbatementService.getPurchaseAbatements('O'));
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.purchaseAbatementService.loadPurchaseAbatements(this.purchase, 'W')
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(() => {
        this.purchasedAbatThema = of(this.purchaseAbatementService.getPurchaseAbatements('W'));
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.purchaseAbatementService.loadPurchaseAbatements(this.purchase, 'I')
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(() => {
        this.purchasedAbatInter = of(this.purchaseAbatementService.getPurchaseAbatements('I'));
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });
    
    this.purchaseAbatementService.loadPurchaseAbatements(this.purchase, 'F4')
    .pipe(takeUntil(this.componentDestroyed))
    .subscribe(() => {
      this.purchasedAbatF4 = of(this.purchaseAbatementService.getPurchaseAbatements('F4'));
    }, () => {
      this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
    });

    this.compensationFormGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.purchaseService.updateCompensation(data);
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });
  }

  ngOnChanges(changes: any): void {
    if (changes.purchase && this.purchaseGlobalBudgetGroup) {
      this.settingValues = true;
      this.purchase = changes.purchase.currentValue;
      this.populateForm();
      this.settingValues = false;
    }

    if (changes.purchase) {
      this.getCompensationPurchase();
    }
  }

  public populateForm(): void {
    const controlsTvTotal = this.purchaseGlobalBudgetGroup.controls['tv']['controls']['tvBudget']['controls'];
    const controlsTvClassic = this.purchaseGlobalBudgetGroup.controls['tv']['controls']['tvClassic']['controls'];
    const controlsTvRegion = this.purchaseGlobalBudgetGroup.controls['tv']['controls']['tvRegion']['controls'];
    const controlsTvDomtom = this.purchaseGlobalBudgetGroup.controls['tv']['controls']['tvDomtom']['controls'];
    const controlsTvThema = this.purchaseGlobalBudgetGroup.controls['tv']['controls']['tvThema']['controls'];
    const controlsTvInter = this.purchaseGlobalBudgetGroup.controls['tv']['controls']['tvInter']['controls'];
    const controlsTvF4 = this.purchaseGlobalBudgetGroup.controls['tv']['controls']['tvF4']['controls'];
    const controlsDigitalTotal = this.purchaseGlobalBudgetGroup.controls['digital']['controls']['digitalBudget']['controls'];
    const controlsDigitalDisplay = this.purchaseGlobalBudgetGroup.controls['digital']['controls']['digitalDisplay']['controls'];
    const controlsDigitalReplay = this.purchaseGlobalBudgetGroup.controls['digital']['controls']['digitalReplay']['controls'];
    const controlsDigitalVideoBill = this.purchaseGlobalBudgetGroup.controls['digital']['controls']['digitalVideoBill']['controls'];
    const controlsDigitalVideoPre = this.purchaseGlobalBudgetGroup.controls['digital']['controls']['digitalVideoPre']['controls'];
    const controlsDigitalSocial = this.purchaseGlobalBudgetGroup.controls['digital']['controls']['digitalSocial']['controls'];
    const controlsSocialFb = this.purchaseGlobalBudgetGroup.controls['digital']['controls']['digitalSocialFb']['controls'];
    const controlsSocialTwit = this.purchaseGlobalBudgetGroup.controls['digital']['controls']['digitalSocialTwit']['controls'];
    const controlsSocialInsta = this.purchaseGlobalBudgetGroup.controls['digital']['controls']['digitalSocialInsta']['controls'];
    const controlsDigitalOther = this.purchaseGlobalBudgetGroup.controls['digital']['controls']['digitalOther']['controls'];

    const controlsTotal = this.purchaseGlobalBudgetGroup.controls['total']['controls'];

    // TV
    controlsTvClassic['tvClassicBudget'].setValue(this.purchase.tvClassicBudget);
    controlsTvClassic['tvClassicBudgetNet'].setValue(this.purchase.tvClassicBudgetNet);
    controlsTvClassic['tvClassicBudgetRate'].setValue(this.purchase.tvClassicBudgetRate);
    controlsTvRegion['tvRegionBudget'].setValue(this.purchase.tvRegionBudget);
    controlsTvRegion['tvRegionBudgetNet'].setValue(this.purchase.tvRegionBudgetNet);
    controlsTvRegion['tvRegionBudgetRate'].setValue(this.purchase.tvRegionBudgetRate);
    controlsTvDomtom['tvDomtomBudget'].setValue(this.purchase.tvDomtomBudget);
    controlsTvDomtom['tvDomtomBudgetNet'].setValue(this.purchase.tvDomtomBudgetNet);
    controlsTvDomtom['tvDomtomBudgetRate'].setValue(this.purchase.tvDomtomBudgetRate);
    controlsTvThema['tvThemaBudget'].setValue(this.purchase.tvThemaBudget);
    controlsTvThema['tvThemaBudgetNet'].setValue(this.purchase.tvThemaBudgetNet);
    controlsTvThema['tvThemaBudgetRate'].setValue(this.purchase.tvThemaBudgetRate);
    controlsTvInter['tvInterBudget'].setValue(this.purchase.tvInterBudget);
    controlsTvInter['tvInterBudgetNet'].setValue(this.purchase.tvInterBudgetNet);
    controlsTvInter['tvInterBudgetRate'].setValue(this.purchase.tvInterBudgetRate);
    controlsTvF4['tvF4Budget'].setValue(this.purchase.tvF4Budget);
    controlsTvF4['tvF4BudgetNet'].setValue(this.purchase.tvF4BudgetNet);
    controlsTvF4['tvF4BudgetRate'].setValue(this.purchase.tvF4BudgetRate);
    controlsTvTotal['tvBudget'].setValue(this.purchase.tvBudget);
    controlsTvTotal['tvBudgetNet'].setValue(this.purchase.tvBudgetNet);
    controlsTvTotal['tvBudgetRate'].setValue(this.purchase.tvBudgetRate);

    // Digital
    controlsDigitalTotal['digitalBudget'].setValue(this.purchase.digitalBudget);
    controlsDigitalTotal['digitalBudgetNet'].setValue(this.purchase.digitalBudgetNet);
    controlsDigitalTotal['digitalBudgetRate'].setValue(this.purchase.digitalBudgetRate);
    controlsDigitalDisplay['digitalDisplayBudget'].setValue(this.purchase.digitalDisplayBudget);
    controlsDigitalDisplay['digitalDisplayBudgetNet'].setValue(this.purchase.digitalDisplayBudgetNet);
    controlsDigitalDisplay['digitalDisplayBudgetRate'].setValue(this.purchase.digitalDisplayBudgetRate);
    controlsDigitalReplay['digitalReplayBudget'].setValue(this.purchase.digitalReplayBudget);
    controlsDigitalReplay['digitalReplayBudgetNet'].setValue(this.purchase.digitalReplayBudgetNet);
    controlsDigitalReplay['digitalReplayBudgetRate'].setValue(this.purchase.digitalReplayBudgetRate);
    controlsDigitalVideoBill['digitalVideoBillBudget'].setValue(this.purchase.digVidBillBudget);
    controlsDigitalVideoBill['digitalVideoBillBudgetNet'].setValue(this.purchase.digVidBillBudgetNet);
    controlsDigitalVideoBill['digitalVideoBillBudgetRate'].setValue(this.purchase.digVidBillBudgetRate);
    controlsDigitalVideoPre['digitalVideoPreBudget'].setValue(this.purchase.digVidPreBudget);
    controlsDigitalVideoPre['digitalVideoPreBudgetNet'].setValue(this.purchase.digVidPreBudgetNet);
    controlsDigitalVideoPre['digitalVideoPreBudgetRate'].setValue(this.purchase.digVidPreBudgetRate);
    controlsDigitalSocial['digitalSocialBudget'].setValue(this.purchase.digitalSocialBudget);
    controlsDigitalSocial['digitalSocialBudgetNet'].setValue(this.purchase.digitalSocialBudgetNet);
    controlsDigitalSocial['digitalSocialBudgetRate'].setValue(this.purchase.digitalSocialBudgetRate);
    controlsSocialFb['digitalSocialFbBudget'].setValue(this.purchase.socialFbBudget);
    controlsSocialFb['digitalSocialFbBudgetNet'].setValue(this.purchase.socialFbBudgetNet);
    controlsSocialFb['digitalSocialFbBudgetRate'].setValue(this.purchase.socialFbBudgetRate);
    controlsSocialTwit['digitalSocialTwitBudget'].setValue(this.purchase.socialTwitBudget);
    controlsSocialTwit['digitalSocialTwitBudgetNet'].setValue(this.purchase.socialTwitBudgetNet);
    controlsSocialTwit['digitalSocialTwitBudgetRate'].setValue(this.purchase.socialTwitBudgetRate);
    controlsSocialInsta['digitalSocialInstaBudget'].setValue(this.purchase.socialInstaBudget);
    controlsSocialInsta['digitalSocialInstaBudgetNet'].setValue(this.purchase.socialInstaBudgetNet);
    controlsSocialInsta['digitalSocialInstaBudgetRate'].setValue(this.purchase.socialInstaBudgetRate);
    controlsDigitalOther['digitalOtherBudget'].setValue(this.purchase.digitalOtherBudget);
    controlsDigitalOther['digitalOtherBudgetNet'].setValue(this.purchase.digitalOtherBudgetNet);
    controlsDigitalOther['digitalOtherBudgetRate'].setValue(this.purchase.digitalOtherBudgetRate);

    // Total
    controlsTotal['totalBudget'].setValue(this.purchase.totalBudget);
    controlsTotal['totalBudgetNet'].setValue(this.purchase.totalBudgetNet);
    controlsTotal['totalBudgetRate'].setValue(this.purchase.totalBudgetRate);

    this.compensationFormGroup.controls['compensationPurchase']
      .setValue(this.purchase.compensationPurchase ? new FilteredItem(this.purchase.compensationPurchase) : '');
  }

  public checkIfCompensation() {
    if (!this.purchase.advertiser) {
      this.customToastrService.displayToastr('WARNING', 'Merci de renseigner l\'annonceur');
    } else if (this.loadingComposation) {
      this.customToastrService.displayToastr('WARNING', 'Recherche en cours, merci de patienter');
    }
  }

  /**
   * Open the abatements popup
   * @param  group F, FREG, O, W, I
   * @return
   */
  public openAbatementDialog(group: string) {
    if (!this.purchase.startCommunicationPeriod) {
      this.customToastrService.displayToastr('WARNING', 'Periode de communication obligatoire');
      return null;
    }

    this.dialogRef = this.dialog.open(AbatementComponent, {
      height: '700px',
      width: '80%',
    });

    let budgetKey = '';

    if (group === 'F') {
      budgetKey = 'tvClassic';
    } else if (group === 'FREG') {
      budgetKey = 'tvRegion';
    } else if (group === 'O') {
      budgetKey = 'tvDomtom';
    } else if (group === 'W') {
      budgetKey = 'tvThema';
    } else if (group === 'I') {
      budgetKey = 'tvInter';
    } else if (group === 'F4') {
      budgetKey = 'tvF4';
    }

    let instance = this.dialogRef.componentInstance;
    instance.purchase = this.purchase;
    instance.dialogRef = this.dialogRef;
    instance.group = group;

    instance.purchaseAbatements = [];

    this.purchaseAbatementService.setPurchaseAbatementsSavePoint(this.purchaseAbatementService.getPurchaseAbatements(group));
    instance.purchaseAbatements = this.purchaseAbatementService.getPurchaseAbatements(group);

    this.dialogRef.afterClosed()
    .pipe(takeUntil(this.componentDestroyed))
    .subscribe((purchase: Purchase) => {
      if (purchase instanceof Purchase) {
        this.purchase = purchase;
        let controlsTv = this.purchaseGlobalBudgetGroup.controls['tv']['controls'][budgetKey]['controls'];
        this.setPurchasedAbattements(group);
        controlsTv[budgetKey + 'BudgetNet'].setValue(eval('purchase.' + budgetKey + 'BudgetNet'));
        controlsTv[budgetKey + 'BudgetRate'].setValue(eval('purchase.' + budgetKey + 'BudgetRate'));
      } else {
        this.purchaseAbatementService.restorePurchaseAbatementsToSavePoint(group);
      }
    }, () => {
      this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
    });
  }

  /**
   * show or hide sub category details
   */
  public expandSubCategories(name: string): void {
    if (name === 'video') {
      this.isVideoHidden = !this.isVideoHidden;
      this.isVideoExpanded = !this.isVideoExpanded;
    }
    if (name === 'social') {
    this.isSocialHidden = !this.isSocialHidden;
    this.isSocialExpanded = !this.isSocialExpanded;
    }
  }

  private initForm(): void {
    if (this.purchase.id) {
      this.purchaseProgramService.getList({purchase_id: this.purchase.id, groups: 'purchaseBudget'})
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(
          result => {
              if (result.length > 0)  {
                if (result[0].offerProgram) {
                  if (result[0].offerProgram.offer.budgetType === 'CAINI') {
                    this.budgetType = 'CA INI';
                  } else {
                    this.budgetType = 'NET';
                  }
                }
              }
            }, error => {
              this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            });
    }

    this.purchaseGlobalBudgetGroup = this.fb.group({
      tv: this.fb.group({
        tvBudget: this.fb.group({
            tvBudget: this.purchase.tvBudget,
            tvBudgetRate: this.purchase.tvBudgetRate,
            tvBudgetNet: this.purchase.tvBudgetNet,
        }),
        tvClassic: this.fb.group({
            tvClassicBudget: this.purchase.tvClassicBudget,
            tvClassicBudgetRate: this.purchase.tvClassicBudgetRate,
            tvClassicBudgetNet: this.purchase.tvClassicBudgetNet,
        }),
        tvRegion: this.fb.group({
            tvRegionBudget: this.purchase.tvRegionBudget,
            tvRegionBudgetRate: this.purchase.tvRegionBudgetRate,
            tvRegionBudgetNet: this.purchase.tvRegionBudgetNet,
        }),
        tvDomtom: this.fb.group({
            tvDomtomBudget: this.purchase.tvDomtomBudget,
            tvDomtomBudgetRate: this.purchase.tvDomtomBudgetRate,
            tvDomtomBudgetNet: this.purchase.tvDomtomBudgetNet,
        }),
        tvThema: this.fb.group({
            tvThemaBudget: this.purchase.tvThemaBudget,
            tvThemaBudgetRate: this.purchase.tvThemaBudgetRate,
            tvThemaBudgetNet: this.purchase.tvThemaBudgetNet,
        }),
        tvInter: this.fb.group({
            tvInterBudget: this.purchase.tvInterBudget,
            tvInterBudgetRate: this.purchase.tvInterBudgetRate,
            tvInterBudgetNet: this.purchase.tvInterBudgetNet,
        }),
        tvF4: this.fb.group({
          tvF4Budget: this.purchase.tvF4Budget,
          tvF4BudgetRate: this.purchase.tvF4BudgetRate,
          tvF4BudgetNet: this.purchase.tvF4BudgetNet,
        })
      }),
      digital: this.fb.group({
        digitalBudget: this.fb.group({
            digitalBudget: this.purchase.digitalBudget,
            digitalBudgetRate: this.purchase.digitalBudgetRate,
            digitalBudgetNet: this.purchase.digitalBudgetNet,
        }),
        digitalReplay: this.fb.group({
          digitalReplayBudget: this.purchase.digitalReplayBudget,
          digitalReplayBudgetRate: this.purchase.digitalReplayBudgetRate,
          digitalReplayBudgetNet: this.purchase.digitalReplayBudgetNet,
        }),
        digitalVideoBill: this.fb.group({
          digitalVideoBillBudget: this.purchase.digVidBillBudget,
          digitalVideoBillBudgetRate: this.purchase.digVidBillBudgetRate,
          digitalVideoBillBudgetNet: this.purchase.digVidBillBudgetNet,
        }),
        digitalVideoPre: this.fb.group({
          digitalVideoPreBudget: this.purchase.digVidPreBudget,
          digitalVideoPreBudgetRate: this.purchase.digVidPreBudgetRate,
          digitalVideoPreBudgetNet: this.purchase.digVidPreBudgetNet,
        }),
        digitalDisplay: this.fb.group({
            digitalDisplayBudget: this.purchase.digitalDisplayBudget,
            digitalDisplayBudgetRate: this.purchase.digitalDisplayBudgetRate,
            digitalDisplayBudgetNet: this.purchase.digitalDisplayBudgetNet,
        }),
        digitalSocial: this.fb.group({
          digitalSocialBudget: this.purchase.digitalSocialBudget,
          digitalSocialBudgetRate: this.purchase.digitalSocialBudgetRate,
          digitalSocialBudgetNet: this.purchase.digitalSocialBudgetNet,
        }),
        digitalSocialFb: this.fb.group({
          digitalSocialFbBudget: this.purchase.socialFbBudget,
          digitalSocialFbBudgetRate: this.purchase.socialFbBudgetRate,
          digitalSocialFbBudgetNet: this.purchase.socialFbBudgetNet,
        }),
        digitalSocialTwit: this.fb.group({
          digitalSocialTwitBudget: this.purchase.socialTwitBudget,
          digitalSocialTwitBudgetRate: this.purchase.socialTwitBudgetRate,
          digitalSocialTwitBudgetNet: this.purchase.socialTwitBudgetNet,
        }),
        digitalSocialInsta: this.fb.group({
          digitalSocialInstaBudget: this.purchase.socialInstaBudget,
          digitalSocialInstaBudgetRate: this.purchase.socialInstaBudgetRate,
          digitalSocialInstaBudgetNet: this.purchase.socialInstaBudgetNet,
        }),
        digitalOther: this.fb.group({
            digitalOtherBudget: this.purchase.digitalOtherBudget,
            digitalOtherBudgetRate: this.purchase.digitalOtherBudgetRate,
            digitalOtherBudgetNet: this.purchase.digitalOtherBudgetNet,
        })
      }),
      total: this.fb.group({
        totalBudget: this.purchase.totalBudget,
        totalBudgetRate: this.purchase.totalBudgetRate,
        totalBudgetNet: this.purchase.totalBudgetNet,
      }),
    });

    this.compensationFormGroup = this.fb.group({
        compensationPurchase: ''
    });
  }

  private UpperCaseArray(input) {
    let result = input.replace(/([A-Z]+)/g, ',$1').replace(/^,/, '');
    return result.split(',');
  }

  /**
   * compute section digital budget
   *
   * @private
   * @param {Array<string>} [field=null]
   * @param {number} [value=0]
   * @memberof PurchaseGlobalBudgetComponent
   */
  private computeSectionDigital(field: Array<string> = null, value: number = 0): void {
    const controlsDigital: FormGroup = <FormGroup>this.purchaseGlobalBudgetGroup.get('digital');

    this.totalDigitalBudget(controlsDigital);
    if (field.includes('Rate')) {
      // if change Rate
      if (field.includes('Replay')) {
        // if change Replay Rate set same value to children
        for (let i = 0; i < this.digitalSubCategoryVideoArray.length; i++) {
          controlsDigital.get(`digital${this.digitalSubCategoryVideoArray[i]}`)
            .get(`digital${this.digitalSubCategoryVideoArray[i]}BudgetRate`).setValue(value ? value : 0);
        }
      } else if (field.includes('Social') && field.length === 4) {
        // if change Social Rate set same value to children
        for (let i = 0; i < this.digitalSubCategorySocialArray.length; i++) {
          controlsDigital.get(`digital${this.digitalSubCategorySocialArray[i]}`)
            .get(`digital${this.digitalSubCategorySocialArray[i]}BudgetRate`).setValue(value ? value : 0);
        }
      } else if (field.length === 3) {
        // if change digital budget rate set same value to children
        for (let i = 0; i < this.digitalAllSectionArray.length; i++) {
          controlsDigital.get(`digital${this.digitalAllSectionArray[i]}`).get(`digital${this.digitalAllSectionArray[i]}BudgetRate`)
          .setValue(controlsDigital.get('digitalBudget').get('digitalBudgetRate').value);
        }
      }
      for (let i = 0; i < this.digitalAllSectionArray.length; i++) {
        let tempValue = Math.round((1 + (controlsDigital.get(`digital${this.digitalAllSectionArray[i]}`)
        .get(`digital${this.digitalAllSectionArray[i]}BudgetRate`).value / 100)) * parseFloat(controlsDigital
        .get(`digital${this.digitalAllSectionArray[i]}`)
        .get(`digital${this.digitalAllSectionArray[i]}Budget`).value) * 100) / 100;
        controlsDigital.get(`digital${this.digitalAllSectionArray[i]}`).get(`digital${this.digitalAllSectionArray[i]}BudgetNet`)
         .setValue(tempValue ? tempValue : 0);
      }
    } else if (field.includes('Net')) {
      // if change Net
      if (field.includes('Replay')) {
        // if change Replay Net, change replay Rate and recalculate budget of children
        const rate = Number(((controlsDigital.get('digitalReplay').get('digitalReplayBudgetNet').value -
          controlsDigital.get('digitalReplay').get('digitalReplayBudget').value) * 100) /
          controlsDigital.get('digitalReplay').get('digitalReplayBudget').value);
        for (let i = 0; i < this.digitalSubCategoryVideoArray.length; i++) {
          controlsDigital.get(`digital${this.digitalSubCategoryVideoArray[i]}`)
          .get(`digital${this.digitalSubCategoryVideoArray[i]}BudgetRate`)
          .setValue(rate ? rate : 0);
        }
        controlsDigital.get('digitalReplay').get('digitalReplayBudgetRate').setValue(rate);
        for (let i = 0; i < this.digitalSubCategoryVideoArray.length; i++) {
          controlsDigital.get(`digital${this.digitalSubCategoryVideoArray[i]}`)
            .get(`digital${this.digitalSubCategoryVideoArray[i]}BudgetNet`)
            .setValue(Math.round((1 + (controlsDigital.get(`digital${this.digitalSubCategoryVideoArray[i]}`)
              .get(`digital${this.digitalSubCategoryVideoArray[i]}BudgetRate`).value / 100)) * parseFloat(controlsDigital
              .get(`digital${this.digitalSubCategoryVideoArray[i]}`)
              .get(`digital${this.digitalSubCategoryVideoArray[i]}Budget`).value) * 100) / 100);
        }
      } else if (field.includes('Social') && field.length === 4) {
        // if change Social Net, change social Rate and recalculate budget of children
        const rate = Number(((controlsDigital.get('digitalSocial').get('digitalSocialBudgetNet').value -
          controlsDigital.get('digitalSocial').get('digitalSocialBudget').value) * 100) /
          controlsDigital.get('digitalSocial').get('digitalSocialBudget').value);
        for (let i = 0; i < this.digitalSubCategorySocialArray.length; i++) {
          controlsDigital.get(`digital${this.digitalSubCategorySocialArray[i]}`)
          .get(`digital${this.digitalSubCategorySocialArray[i]}BudgetRate`)
          .setValue(rate ? rate : 0);
        }
        controlsDigital.get('digitalSocial').get('digitalSocialBudgetRate').setValue(rate);
        for (let i = 0; i < this.digitalSubCategorySocialArray.length; i++) {
          controlsDigital.get(`digital${this.digitalSubCategorySocialArray[i]}`)
            .get(`digital${this.digitalSubCategorySocialArray[i]}BudgetNet`)
            .setValue(Math.round((1 + (controlsDigital.get(`digital${this.digitalSubCategorySocialArray[i]}`)
              .get(`digital${this.digitalSubCategorySocialArray[i]}BudgetRate`).value / 100)) * parseFloat(controlsDigital
              .get(`digital${this.digitalSubCategorySocialArray[i]}`)
              .get(`digital${this.digitalSubCategorySocialArray[i]}Budget`).value) * 100) / 100);
        }
      } else if (field.length === 3) {
        // if change Net of digital budget change rate and recalculate budget of children
        const rateBudget = Number(((controlsDigital.get('digitalBudget').get(`digitalBudgetNet`).value -
          controlsDigital.get('digitalBudget').get(`digitalBudget`).value) * 100) /
          controlsDigital.get('digitalBudget').get(`digitalBudget`).value);
        controlsDigital.get('digitalBudget').get('digitalBudgetRate').setValue(rateBudget);
        for (let i = 0; i < this.digitalAllSectionArray.length; i++) {
          controlsDigital.get(`digital${this.digitalAllSectionArray[i]}`).get(`digital${this.digitalAllSectionArray[i]}BudgetRate`)
          .setValue(controlsDigital.get('digitalBudget').get('digitalBudgetRate').value);
        }
        for (let i = 0; i < this.digitalAllSectionArray.length; i++) {
          let tempValue = Math.round((1 + (controlsDigital.get(`digital${this.digitalAllSectionArray[i]}`)
          .get(`digital${this.digitalAllSectionArray[i]}BudgetRate`).value / 100)) * parseFloat(controlsDigital
          .get(`digital${this.digitalAllSectionArray[i]}`)
          .get(`digital${this.digitalAllSectionArray[i]}Budget`).value) * 100) / 100;
          controlsDigital.get(`digital${this.digitalAllSectionArray[i]}`).get(`digital${this.digitalAllSectionArray[i]}BudgetNet`)
          .setValue(tempValue ? tempValue : 0);
       }
      }
    }
    this.totalDigitalBudgetNet(controlsDigital, field);
    this.calculateRate(controlsDigital, field);
    // compute total budget of TV and Digital
    this.computeTotalBudget();
  }

  /**
   * calculate total digital budget Brut
   *
   * @private
   * @param {FormGroup} controlsDigital
   * @memberof PurchaseGlobalBudgetComponent
   */
  private totalDigitalBudget(controlsDigital: FormGroup): void {
    // total of video budget Brut
    let totalVideo: number = 0;
    for (let i = 0; i < this.digitalSubCategoryVideoArray.length; i++) {
      totalVideo += Number(controlsDigital.get(`digital${this.digitalSubCategoryVideoArray[i]}`)
        .get(`digital${this.digitalSubCategoryVideoArray[i]}Budget`).value);
    }
    controlsDigital.get('digitalReplay').get('digitalReplayBudget').setValue(totalVideo);
    // total of social budget Brut
    let totalSocial: number = 0;
    for (let i = 0; i < this.digitalSubCategorySocialArray.length; i++) {
      totalSocial += Number(controlsDigital.get(`digital${this.digitalSubCategorySocialArray[i]}`)
        .get(`digital${this.digitalSubCategorySocialArray[i]}Budget`).value);
    }
    controlsDigital.get('digitalSocial').get('digitalSocialBudget').setValue(totalSocial);
    // total of digital budget Brut
    let totalDigitalBrut: number = 0;
    for (let i = 0; i < this.digitalWithoutSomeSectionArray.length; i++) {
      totalDigitalBrut += Number(controlsDigital.get(`digital${this.digitalWithoutSomeSectionArray[i]}`)
        .get(`digital${this.digitalWithoutSomeSectionArray[i]}Budget`).value);
    }
    controlsDigital.get('digitalBudget').get('digitalBudget').setValue(totalDigitalBrut);
  }

  /**
   * calculate total digital budget Net
   *
   * @private
   * @param {FormGroup} controlsDigital
   * @param {Array<string>} field
   * @memberof PurchaseGlobalBudgetComponent
   */
  private totalDigitalBudgetNet(controlsDigital: FormGroup, field: Array<string>): void {
    // total of video budget Net
    if (!(field.length === 4 && field.includes('Replay'))) {
      let totalVideoNet: number = 0;
      for (let i = 0; i < this.digitalSubCategoryVideoArray.length; i++) {
        totalVideoNet += Number(controlsDigital.get(`digital${this.digitalSubCategoryVideoArray[i]}`)
          .get(`digital${this.digitalSubCategoryVideoArray[i]}BudgetNet`).value);
      }
      controlsDigital.get('digitalReplay').get('digitalReplayBudgetNet').setValue(totalVideoNet);
    }
    // total of social budget Net
    if (!(field.length === 4 && field.includes('Social'))) {
      let totalSocialNet: number = 0;
      for (let i = 0; i < this.digitalSubCategorySocialArray.length; i++) {
        totalSocialNet += Number(controlsDigital.get(`digital${this.digitalSubCategorySocialArray[i]}`)
          .get(`digital${this.digitalSubCategorySocialArray[i]}BudgetNet`).value);
      }
      controlsDigital.get('digitalSocial').get('digitalSocialBudgetNet').setValue(totalSocialNet);
    }
    // total of digital budget Net
    if (!(field.length === 3) || (field.length === 3 && field.includes('Rate'))) {
      let totalDigitalNet: number = 0;
      for (let i = 0; i < this.digitalSectionArray.length; i++) {
        totalDigitalNet += Number(controlsDigital.get(`digital${this.digitalSectionArray[i]}`)
          .get(`digital${this.digitalSectionArray[i]}BudgetNet`).value);
      }
     controlsDigital.get('digitalBudget').get('digitalBudgetNet').setValue(totalDigitalNet);
    }
  }

  /**
   * calculate rate
   *
   * @private
   * @param {FormGroup} controlsDigital
   * @param {Array<string>} field
   * @memberof PurchaseGlobalBudgetComponent
   */
  private calculateRate(controlsDigital: FormGroup, field: Array<string>): void {
    if (!(field.length === 3 && field.includes('Rate'))) {
      for (let i = 0; i < this.digitalAllSectionArray.length; i++) {
        const currentSection = controlsDigital.get(`digital${this.digitalAllSectionArray[i]}`);
        let rate = Number(((currentSection.get(`digital${this.digitalAllSectionArray[i]}BudgetNet`).value -
        currentSection.get(`digital${this.digitalAllSectionArray[i]}Budget`).value) * 100) /
        currentSection.get(`digital${this.digitalAllSectionArray[i]}Budget`).value);
        currentSection.get(`digital${this.digitalAllSectionArray[i]}BudgetRate`).setValue(rate ? rate : 0);
      }
      const rateBudget = Number((controlsDigital.get('digitalBudget').get(`digitalBudgetNet`).value -
      controlsDigital.get('digitalBudget').get(`digitalBudget`).value) * 100) /
      controlsDigital.get('digitalBudget').get(`digitalBudget`).value;
      controlsDigital.get('digitalBudget').get('digitalBudgetRate').setValue(rateBudget ? rateBudget : 0);
    }
  }

  /**
   * Update a subtotal accordingly to sections data
   *
   * @param field string[]
   * @param value number
   * @param computeTotal boolean
   */
  private computeSection(field = null, value = 0, computeTotal = true) {
    let currentAllSection = null;
    let currentSectionLessCategory = null;
    let currentSubCategoryVideo = null;
    let currentSubCategorySocial = null
    let totalBudgetCalc = 0;
    let tempValue = 0;
    let controls = null;
    let section = field[0];
    let subSection = field[1];
    let currentField = field[field.length - 1];
    let controlsTotal = this.purchaseGlobalBudgetGroup.controls['total']['controls'];
    let subCategory = '';

    // Build data selectors, basing on section
    switch (section) {
      case 'tv':
        currentAllSection = this.tvSectionArray;
        currentSectionLessCategory = currentAllSection;
        controls = this.purchaseGlobalBudgetGroup.controls['tv']['controls'];
        break;
      case 'digital':
        currentAllSection = this.digitalAllSectionArray;
        currentSectionLessCategory = this.digitalWithoutSomeSectionArray;
        currentSubCategoryVideo = this.digitalSubCategoryVideoArray;
        currentSubCategorySocial = this.digitalSubCategorySocialArray;
        if (subSection === 'Video' || subSection === 'Social') {
          subCategory = field[2];
        }
        controls = this.purchaseGlobalBudgetGroup.controls['digital']['controls'];
        break;
      case 'total':
        if (currentField === 'Rate') {
          this.computeSection(['tv', 'Budget', 'Rate'], value, false);
          this.computeSection(['digital', 'Budget', 'Rate'], value, false);
          this.computeTotalBudget('Rate', value);
        } else {
          tempValue = 100 * ((value - controlsTotal['totalBudget'].value) / controlsTotal['totalBudget'].value);
          controlsTotal['totalBudgetRate'].setValue(tempValue);
          this.computeSection(['tv', 'Budget', 'Rate'], tempValue, false);
          this.computeSection(['digital', 'Budget', 'Rate'], tempValue, false);
        }
        return null;
    }
    // si on touche à la colonne Taux
    if (currentField === 'Rate') {
      // // Traitement si on modifie le taux au niveau du budget
        if (subSection === 'Budget') {
          for (let i = 0, len = currentAllSection.length; i < len; i++) {
            // Si on a renseigné sur une ligne de total, on applique le taux sur tous les enfants
            tempValue = Math.round(
              (1 + (value / 100)) * parseFloat(controls[section + currentAllSection[i]]['controls']
              [section + currentAllSection[i] + 'Budget'].value) * 100) / 100;

            tempValue = Number.isNaN(tempValue) ? 0 : tempValue;
            controls[section + currentAllSection[i]]['controls'][section + currentAllSection[i] + 'BudgetRate'].setValue(value);
            controls[section + currentAllSection[i]]['controls'][section + currentAllSection[i] + 'BudgetNet'].setValue(tempValue);

            if (currentAllSection[i] !== 'VideoBill' && currentAllSection[i] !== 'VideoPre' &&
            currentAllSection[i] !== 'SocialFb' && currentAllSection[i] !== 'SocialTwit' &&
            currentAllSection[i] !== 'SocialInsta') {
              totalBudgetCalc = totalBudgetCalc + tempValue;
            }
          }
        } else {
          for (let ind = 0, leng = currentSectionLessCategory.length; ind < leng; ind++) {
            if (subSection + subCategory === currentSectionLessCategory[ind]) {
              // Traitement sur le taux si on modifie le taux sur une ligne de categorie
              tempValue = Math.round(
                (1 + (value / 100)) * parseFloat(
                controls[section + currentSectionLessCategory[ind]]['controls']
                [section + currentSectionLessCategory[ind] + 'Budget'].value) * 100) / 100;

              tempValue = Number.isNaN(tempValue) ? 0 : tempValue;
              controls[section + currentSectionLessCategory[ind]]['controls'][section + currentSectionLessCategory[ind] + 'BudgetNet']
              .setValue(tempValue);
              totalBudgetCalc = totalBudgetCalc + tempValue;
            } else {
              if (currentSectionLessCategory[ind]) {
                totalBudgetCalc = totalBudgetCalc + parseFloat(controls[section + currentSectionLessCategory[ind]]['controls']
                [section + currentSectionLessCategory[ind] + 'BudgetNet'].value);
              }
            }
          }
        }

      // // On met à jour la ligne de total
      controls[section + 'Budget']['controls'][section + 'BudgetNet'].setValue(totalBudgetCalc);
      // si la modification a été faite au niveau du budget
      if (subSection === 'Budget') {
          controls[section + 'Budget']['controls'][section + 'BudgetRate'].setValue(value);
      } else {
        // si la modification a été faite sur une ligne de catégorie
        let tempValue = ((totalBudgetCalc -
          parseFloat(controls[section + 'Budget']['controls'][section + 'Budget'].value)
        ) / parseFloat(controls[section + 'Budget']['controls'][section + 'Budget'].value)) * 100;
        controls[section + 'Budget']['controls'][section + 'BudgetRate'].setValue(tempValue);
      }
    } else { // si on modifie la colonne brut sur TV
      if (subSection === 'Budget') {
        // Traitement si modification au niveau du budget
        // On calcule le taux qui sera appliqué sur toute les lignes
        tempValue = Math.round(
        100 * ((value - controls[section + 'Budget']['controls'][section + 'Budget'].value) /
                      controls[section + 'Budget']['controls'][section + 'Budget'].value)
        * 1000000000
        ) / 1000000000;
        controls[section + 'Budget']['controls'][section + 'BudgetRate'].setValue(tempValue);
      }

      // si on modifie le net du budget sur digital
      for (let i = 0, len = currentSectionLessCategory.length; i < len; i++) {
        if (subSection === 'Budget') {
          for (let ind = 0, leng = currentAllSection.length; ind < leng; ind++) {
          // Application de la nego sur les enfants
          tempValue = Number.isNaN(tempValue) ? 0 : tempValue;
          controls[section + currentAllSection[ind]]['controls'][section + currentAllSection[ind] + 'BudgetRate'].setValue(tempValue);
          controls[section + currentAllSection[ind]]['controls'][section + currentAllSection[ind] + 'BudgetNet'].setValue(
            Math.round(parseFloat(controls[section + currentAllSection[ind]]['controls']
            [section + currentAllSection[ind] + 'Budget'].value) * (1 + (tempValue / 100)) * 100) / 100);
          }
        }
        // si on modifie le  brut/net d'une catégorie Digital et le brut d'une categorie de TV
        if (subSection !== 'Budget') {
            tempValue =
            ((controls[section + currentSectionLessCategory[i]]['controls'][section + currentSectionLessCategory[i] + 'BudgetNet'].value -
            controls[section + currentSectionLessCategory[i]]['controls'][section + currentSectionLessCategory[i] + 'Budget'].value) * 100)
            / controls[section + currentSectionLessCategory[i]]['controls'][section + currentSectionLessCategory[i] + 'Budget'].value;
            controls[section + currentSectionLessCategory[i]]['controls'][section +  currentSectionLessCategory[i] + 'BudgetRate']
              .setValue(tempValue);
        }
          totalBudgetCalc = totalBudgetCalc + parseFloat(controls[section + currentSectionLessCategory[i]]['controls']
          [section + currentSectionLessCategory[i] + 'BudgetNet'].value);
          controls[section + 'Budget']['controls'][section + 'BudgetRate'].setValue(
            ((totalBudgetCalc - controls[section + 'Budget']['controls'][section + 'Budget'].value) * 100) /
            controls[section + 'Budget']['controls'][section + 'Budget'].value
          );
      }

      if (subSection !== 'Budget') {
        // // On calcule le taux qui sera appliqué sur toute les lignes
        controls[section + 'Budget']['controls'][section + 'BudgetNet'].setValue(totalBudgetCalc);
      }
    }

    if (computeTotal) {
      this.computeTotalBudget();
    }
  }

  /**
   * Getting values from TV and digital subtotal to update grand total field
   *
   * @param field
   * @param value
   */
  private computeTotalBudget(field = null, value = 0) {
    let totalBudgetCalc = 0;
    let totalBudgetRaw = 0;
    let controlsTvTotal = this.purchaseGlobalBudgetGroup.controls['tv']['controls']['tvBudget']['controls'];
    let controlsDigitalTotal = this.purchaseGlobalBudgetGroup.controls['digital']['controls']['digitalBudget']['controls'];
    let controlsArray = [controlsTvTotal, controlsDigitalTotal];
    let controlsTotal = this.purchaseGlobalBudgetGroup.controls['total']['controls'];

    if (!field) {
      // Make sum of tv subtotal + digital subtotal
      for (let i = 0, len = this.sectionArray.length; i < len; i++) {
        let fieldValue: number = parseFloat(controlsArray[i][this.sectionArray[i] + 'Budget'].value);
        let fiedlRateValue: number = parseFloat(controlsArray[i][this.sectionArray[i] + 'BudgetRate'].value);

        // Set to zero if NaN
        fieldValue = Number.isNaN(fieldValue) ? 0 : fieldValue;
        fiedlRateValue = Number.isNaN(fiedlRateValue) ? 0 : fiedlRateValue;
        fiedlRateValue = fiedlRateValue === Infinity ? 0 : fiedlRateValue;

        totalBudgetRaw += fieldValue;
        totalBudgetCalc += (fieldValue * (1 + (fiedlRateValue / 100)));
      }

      // Updating budgets grand total
      controlsTotal['totalBudget'].setValue(totalBudgetRaw);
      controlsTotal['totalBudgetNet'].setValue(
        Math.round(totalBudgetCalc * 100) / 100
      );

      let rateValue =
        ((100 * ((totalBudgetCalc - controlsTotal['totalBudget'].value) /
        controlsTotal['totalBudget'].value)) * 1000000000) / 1000000000;

      rateValue = Number.isNaN(rateValue) ? 0 : rateValue;

      // Ajouter calcul du taux
      controlsTotal['totalBudgetRate'].setValue(rateValue);
    } else {
      if (field === 'Rate') {
        controlsTotal['totalBudgetRate'].setValue(value);
        controlsTotal['totalBudgetNet'].setValue(Math.round(
          parseFloat(controlsTotal['totalBudget'].value) * (1 + (value / 100)) * 100 ) / 100
        );
      }
    }
  }

  /**
   * Update / compute with budget form data
   *
   * @param field
   * @param value
   * @param type
   */
  private computeBudget(field = null, value = null, type: string = null): void {
    if (!this.updatingValues && !this.settingValues) {
      let sectionValueChangedArray = this.UpperCaseArray(field);
      this.updatingValues = true;
      if (type === 'digital' && (value || value === 0)) {
        this.computeSectionDigital(sectionValueChangedArray, value);
      } else if (value || value === 0) {
          this.computeSection(sectionValueChangedArray, value);
      }
      this.updatingValues = false;
    }
  }

  private getCompensationPurchase() {
      if (!this.purchase || !this.purchase.advertiser) {
        return;
      }

      this.loadingComposation = true;

      let startDate = moment();
      this.purchaseService.getListForFilter({
        advertiser_or_customer: this.purchase.advertiser.id,
        start_communication_period: startDate.subtract(3, 'years').format('YYYY-MM-DD'),
      }, 'compensationList')
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(purchases => {
        this.loadingComposation = false;

        if (purchases && purchases.length > 0) {
          this.compensationPurchaseItems = purchases;

          // Remove current purchase from the result
          if (this.purchase && this.purchase.id) {
              this.compensationPurchaseItems.filter((elem, index) => {
                  if (elem.entity.id === this.purchase.id) {
                      this.compensationPurchaseItems.splice(index, 1);
                  }
              });
          }

          this.filteredCompensationPurchaseItems = of(
              this.autocompleteService.filterItems('', this.compensationPurchaseItems)
          );
        }
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });
  }

  /**
   * Update purchased abatements of related group
   *
   * @param {string} group
   * @return void
   */
  private setPurchasedAbattements(group: string): void {
    if (group === 'F') {
      this.purchasedAbatClassic = of(this.purchaseAbatementService.getPurchaseAbatements('F'));
    } else if (group === 'FREG') {
      this.purchasedAbatRegion = of(this.purchaseAbatementService.getPurchaseAbatements('FREG'));
    } else if (group === 'O') {
      this.purchasedAbatDomTom = of(this.purchaseAbatementService.getPurchaseAbatements('O'));
    } else if (group === 'W') {
      this.purchasedAbatThema = of(this.purchaseAbatementService.getPurchaseAbatements('W'));
    } else if (group === 'I') {
      this.purchasedAbatInter = of(this.purchaseAbatementService.getPurchaseAbatements('I'));
    } else if (group === 'F4') {
      this.purchasedAbatF4 = of(this.purchaseAbatementService.getPurchaseAbatements('F4'));
    }
  }
}
