import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatDialog } from "@angular/material";

import { NewMonitoringLotProgramComponent } from "../new-monitoring-lot-program/new-monitoring-lot-program.component";

import { Channel } from "../../../../resource/channel.resource";
import { FilteredItem } from "../../../../resource/filteredItem.resource";
import {
  LotDotation,
  MonitoringLotDotation,
} from "../../../../resource/lot-dotation.resource";

import { CustomToastrService } from "@service/toastr/custom-toastr.service";
import { ChannelService } from "@service/channel/channel.service";
import { ProgramService } from "@service/program/program.service";
import { AdvertiserService } from "@service/advertiser/advertiser.service";
import { ProductService } from "@service/product/product.service";
import { CampaignService } from "@service/campaign/campaign.service";
import { AutocompleteService } from "@service/utilities/autocomplete.service";
import {
  LotDotationService,
  MonitoringLotParrDiffusionFilter,
  MonitoringLotParrDiffusionPanierFilter,
} from "@service/lot-dotation/lot-dotation.service";
import { PurchaseService } from "@service/purchase/purchase.service";

import {
  debounceTime,
  distinctUntilChanged,
  startWith,
  takeUntil,
  filter,
} from "rxjs/operators";
import { Observable, of, Subject } from "rxjs";

import * as moment from "moment";

@Component({
  selector: "app-add-monitoring-lot",
  templateUrl: "./add-monitoring-lot.component.html",
  styleUrls: ["./add-monitoring-lot.component.scss"],
})
export class AddMonitoringLotComponent implements OnInit, OnDestroy {
  public informationGroup: FormGroup;
  public filteredChannelItems: Observable<FilteredItem[]>;
  public filteredProgramItems: Observable<FilteredItem[]>;
  public filteredAdvertiserItems: Observable<FilteredItem[]>;
  public filteredProductItems: Observable<FilteredItem[]>;
  public filteredCampaignItems: Observable<FilteredItem[]>;
  public filteredLotItems: Observable<FilteredItem[]>;
  public filteredPurchaseDotationItems: Observable<FilteredItem[]>;
  public filteredModuleDotationItems: Observable<FilteredItem[]>;
  public loading: boolean = false;
  public isSaving: boolean = false;
  public path: string = Channel.PATH;
  public nationalChannels = [];
  public listOfYears = [];

  private componentDestroyed$: Subject<void> = new Subject();
  private initModuleCtrlVal: FilteredItem;
  private modulesList: FilteredItem[];
  private purchaseList: FilteredItem[];

  constructor(
    private fb: FormBuilder,
    private customToastrService: CustomToastrService,
    private advertiserService: AdvertiserService,
    private productService: ProductService,
    private campaignService: CampaignService,
    private channelService: ChannelService,
    private programService: ProgramService,
    private lotDotationService: LotDotationService,
    private purchaseService: PurchaseService,
    public autocompleteService: AutocompleteService,
    public dialogRef: MatDialogRef<AddMonitoringLotComponent>,
    @Inject(MAT_DIALOG_DATA)
    public monitoringLotDotation: MonitoringLotDotation,
    public dialog: MatDialog
  ) {
    if (this.monitoringLotDotation && this.monitoringLotDotation.parroption) {
      this.getFilteredModules(+this.monitoringLotDotation.parroption, true);
    }
  }

  ngOnInit() {
    this.initForm();
    this.setValueChanges();
    this.generateListOfYears();
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.unsubscribe();
  }

  /**
   *
   * @param purchase
   * @param initModuleVal
   */
  public getFilteredModules(
    purchase: number,
    initModuleVal: boolean = false
  ): void {
    const filters = { purchase: purchase };
    let triggerApiRequest = true;

    // maybe check empty string
    /*
    if (purchase) {
      triggerApiRequest = true;
      filters.purchase = `${this.monitoringLotDotation.parroption}`;
    } else if (!purchase && this.monitoringLotDotation && this.monitoringLotDotation.parroption) {
      triggerApiRequest = true;
      filters.purchase = `${purchase}`;
    }*/

    this.lotDotationService
      .getFilteredDotationPurchaseModules(filters)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (moduleFilteredItems: FilteredItem[]) => {
          this.modulesList = moduleFilteredItems;
          this.filteredModuleDotationItems = of(moduleFilteredItems);
          /*
        if (moduleFilteredItems.length === 1) {
          const moduleValue = moduleFilteredItems[0].id;
          this.informationGroup.controls['module'].setValue(moduleValue, {emitEvent: false});
          // this.valueSelected('campaign');
        }*/
          // TODO maybe useless, check if we can remove it

          if (initModuleVal && this.modulesList) {
            this.modulesList.forEach((module: FilteredItem) => {
              if (+module.id === +this.monitoringLotDotation.module) {
                this.informationGroup.controls["module"].setValue(module, {
                  emitEvent: false,
                });
              }
            });
          }
          this.loading = false;
        },
        () => {
          this.customToastrService.displayToastr(
            "ERROR",
            "Une erreur est survenue."
          );
          this.loading = false;
        }
      );

    // if (triggerApiRequest) {}
  }

  /**
   * Verify if the next is alone and select it
   *
   * @param controlName
   */
  public valueSelected(controlName: string): void {
    this.loading = true;
    if (
      controlName === "advertiser" &&
      this.informationGroup.controls["advertiser"] != null &&
      this.informationGroup.controls["advertiser"].value !== null &&
      this.informationGroup.controls["advertiser"].value !== "" &&
      this.informationGroup.controls["advertiser"].value.id &&
      typeof this.informationGroup.controls["advertiser"].value.id === "number"
    ) {
      const productParams = {
        advertiser_id: this.informationGroup.controls["advertiser"].value.id,
      };

      this.productService
        .getListForFilter(productParams)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(
          (productItems: FilteredItem[]) => {
            if (productItems.length === 1) {
              this.informationGroup.controls["product"].setValue(
                productItems[0],
                { emitEvent: false }
              );
              this.valueSelected("product");
            } else if (productItems.length > 1) {
              this.filteredProductItems = of(productItems);
            }
            this.loading = false;
          },
          () => {
            this.customToastrService.displayToastr(
              "ERROR",
              "Une erreur est survenue."
            );
            this.loading = false;
          }
        );
    } else if (
      controlName === "product" &&
      this.informationGroup.controls["product"] !== null &&
      this.informationGroup.controls["product"].value !== null &&
      this.informationGroup.controls["product"].value !== "" &&
      this.informationGroup.controls["product"].value.id &&
      typeof this.informationGroup.controls["product"].value.id === "number"
    ) {
      this.campaignService
        .getListForFilter({
          product_id: this.informationGroup.controls["product"].value.id,
          campaign_type: ["DOT", "ASL"], // campaign type dotation et droit d'asile
          year: this.listOfYears,
        })
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(
          (campaignItems: FilteredItem[]) => {
            if (campaignItems.length === 1) {
              this.informationGroup.controls["campaign"].setValue(
                campaignItems[0],
                { emitEvent: false }
              );
              this.valueSelected("campaign");
            } else if (campaignItems.length > 1) {
              this.filteredCampaignItems = of(campaignItems);
            }
            this.loading = false;
          },
          () => {
            this.customToastrService.displayToastr(
              "ERROR",
              "Une erreur est survenue."
            );
            this.loading = false;
          }
        );
    } else if (
      controlName === "campaign" &&
      this.informationGroup.controls["advertiser"] != null &&
      this.informationGroup.controls["advertiser"].value !== null &&
      this.informationGroup.controls["advertiser"].value !== "" &&
      this.informationGroup.controls["advertiser"].value.id &&
      typeof this.informationGroup.controls["advertiser"].value.id ===
        "number" &&
      this.informationGroup.controls["product"] !== null &&
      this.informationGroup.controls["product"].value !== null &&
      this.informationGroup.controls["product"].value !== "" &&
      this.informationGroup.controls["product"].value.id &&
      typeof this.informationGroup.controls["product"].value.id === "number"
    ) {
      this.lotDotationService
        .getListForFilter({
          advertiser: this.informationGroup.controls["advertiser"].value.id,
          product: this.informationGroup.controls["product"].value.id,
          campaign_num:
            this.informationGroup.controls["campaign"].value.entity
              .campaignNumber,
          campaign_year:
            this.informationGroup.controls["campaign"].value.entity.year,
        })
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(
          (lotItems: FilteredItem[]) => {
            if (lotItems.length === 1) {
              this.informationGroup.controls["lot"].setValue(lotItems[0], {
                emitEvent: false,
              });
              this.valueSelected("lot");
            } else if (lotItems.length > 1) {
              this.filteredLotItems = of(lotItems);
            }
            this.loading = false;
          },
          () => {
            this.customToastrService.displayToastr(
              "ERROR",
              "Une erreur est survenue."
            );
            this.loading = false;
          }
        );
    } else if (
      controlName === "lot" &&
      this.informationGroup.controls["lot"] != null &&
      this.informationGroup.controls["lot"].value !== null &&
      this.informationGroup.controls["lot"].value !== "" &&
      this.informationGroup.controls["lot"].value.id &&
      typeof this.informationGroup.controls["lot"].value.id === "number"
    ) {
      this.purchaseService
        .getListDotationPurchaseForFilter({
          lot_id: this.informationGroup.controls["lot"].value.id,
        })
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(
          (purchaseItems: FilteredItem[]) => {
            if (purchaseItems.length === 1) {
              this.informationGroup.controls["purchase"].setValue(
                purchaseItems[0],
                { emitEvent: false }
              );
              this.valueSelected("purchase");
            }
            this.loading = false;
          },
          () => {
            this.customToastrService.displayToastr(
              "ERROR",
              "Une erreur est survenue."
            );
            this.loading = false;
          }
        );
    } else if (
      controlName === "purchase" &&
      this.informationGroup.controls["purchase"] != null &&
      this.informationGroup.controls["purchase"].value !== null &&
      this.informationGroup.controls["purchase"].value !== "" &&
      this.informationGroup.controls["purchase"].value.id &&
      typeof +this.informationGroup.controls["purchase"].value.id === "number"
    ) {
      // maybe add this
      // TODO this.informationGroup.controls['module'].setValue(null, {emitEvent: false});
      this.getFilteredModules(
        +this.informationGroup.controls["purchase"].value.id
      );
      /*
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((purchaseItems: FilteredItem[]) => {
          if (purchaseItems.length === 1) {
            this.informationGroup.controls['purchase'].setValue(purchaseItems[0], {emitEvent: false});
          }
          this.loading = false;
        }, () => {
          this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
          this.loading = false;
        });*/
    } else {
      this.loading = false;
    }
  }

  /**
   * Init form
   *
   * @returns {void}
   */
  public initForm() {
    let disabledControl = false;

    if (this.monitoringLotDotation && this.monitoringLotDotation.invoiced) {
      disabledControl = !!this.monitoringLotDotation.invoiceNumber;
    }

    this.informationGroup = this.fb.group({
      channel: [
        this.monitoringLotDotation &&
        this.monitoringLotDotation.channel &&
        this.monitoringLotDotation.channel.name
          ? {
              value: new FilteredItem(this.monitoringLotDotation.channel),
              disabled: disabledControl,
            }
          : "",
        [Validators.required, Validators.maxLength(100)],
      ],
      program: [
        this.monitoringLotDotation &&
        this.monitoringLotDotation.program &&
        this.monitoringLotDotation.program.name
          ? {
              value: new FilteredItem(this.monitoringLotDotation.program),
              disabled: disabledControl,
            }
          : "",
        [Validators.required, Validators.maxLength(150)],
      ],
      advertiser: [
        this.monitoringLotDotation &&
        this.monitoringLotDotation.advertiser &&
        this.monitoringLotDotation.advertiser.id &&
        this.monitoringLotDotation.advertiser.name
          ? {
              value: new FilteredItem(this.monitoringLotDotation.advertiser),
              disabled: true,
            }
          : "",
        [Validators.required, Validators.maxLength(100)],
      ],
      product: [
        this.monitoringLotDotation &&
        this.monitoringLotDotation.product &&
        this.monitoringLotDotation.product.id &&
        this.monitoringLotDotation.product.name
          ? {
              value: new FilteredItem(this.monitoringLotDotation.product),
              disabled: true,
            }
          : "",
        [Validators.required, Validators.maxLength(100)],
      ],
      campaign: [
        this.monitoringLotDotation &&
        this.monitoringLotDotation.campaign &&
        this.monitoringLotDotation.campaign.name &&
        this.monitoringLotDotation.campaign.campaignNumber
          ? {
              value: new FilteredItem(this.monitoringLotDotation.campaign),
              disabled: true,
            }
          : "",
        [Validators.required, Validators.maxLength(150)],
      ],
      lot: [
        this.monitoringLotDotation &&
        this.monitoringLotDotation.lot &&
        this.monitoringLotDotation.lot.name
          ? {
              value: new FilteredItem(this.monitoringLotDotation.lot),
              disabled: true,
            }
          : "",
        [Validators.required, Validators.maxLength(150)],
      ],
      contractNumber: [
        this.monitoringLotDotation && this.monitoringLotDotation.contractNumber
          ? {
              value: this.monitoringLotDotation.contractNumber,
              disabled: true,
            }
          : "",
        [Validators.maxLength(15)],
      ],
      bilanNumber: [
        this.monitoringLotDotation && this.monitoringLotDotation.bilanNumber
          ? {
              value: this.monitoringLotDotation.bilanNumber,
              disabled: disabledControl,
            }
          : "",
        [Validators.maxLength(15)],
      ],
      purchase: [
        this.monitoringLotDotation &&
        this.monitoringLotDotation.lot &&
        this.monitoringLotDotation.lot.parroptionName
          ? {
              value: new FilteredItem({
                id: this.monitoringLotDotation.parroption,
                name: this.monitoringLotDotation.lot.parroptionName,
              }),
              disabled: true,
            }
          : "",
        [Validators.required, Validators.maxLength(150)],
      ],
      module: [
        this.monitoringLotDotation && this.monitoringLotDotation.module
          ? {
              value: this.monitoringLotDotation.module,
              disabled: true,
            }
          : "",
        [Validators.maxLength(150)],
      ],
    });
  }

  /**
   * Close dialog and cancel suivi lot dotation creation
   *
   * @returns {void}
   */
  public cancel(): void {
    this.dialogRef.close();
  }

  /**
   * Save monitoring lot
   *
   * @param closeForm {boolean}
   */
  public submit(closeForm: boolean): void {
    if (this.informationGroup.valid) {
      this.isSaving = true;

      this.postMonitoringLotParrDiffusion().subscribe(
        (diffusionIds) => {
          this.postMonitoringLotParrDiffusionPanier(diffusionIds).subscribe(
            (response) => {
              this.isSaving = false;
              let observableLotService$;

              if (this.monitoringLotDotation) {
                observableLotService$ =
                  this.lotDotationService.putMonitoringLot(
                    this.convertFormGroupToMonitoringLotDotationObject(),
                    "monitoring_lot",
                    this.monitoringLotDotation.id
                  );
              } else {
                observableLotService$ =
                  this.lotDotationService.postMonitoringLot(
                    this.convertFormGroupToMonitoringLotDotationObject(),
                    "monitoring_lot"
                  );
              }

              observableLotService$
                .pipe(takeUntil(this.componentDestroyed$))
                .subscribe(
                  (createdMonitoringLotDotation: MonitoringLotDotation) => {
                    this.isSaving = false;

                    this.customToastrService.displayToastr(
                      "SUCCESS",
                      this.monitoringLotDotation
                        ? "La modification a été un succès"
                        : "Le suivi de lot a été créé avec succès!"
                    );

                    closeForm
                      ? this.dialogRef.close(createdMonitoringLotDotation)
                      : this.clearForm();
                  },
                  () => {
                    this.isSaving = false;
                    this.customToastrService.displayToastr(
                      "ERROR",
                      "Erreur lors de la création du suivi de lot!"
                    );
                  }
                );
            },
            (error) => {
              return error;
            }
          );
        },
        (error) => {
          this.isSaving = false;
          this.customToastrService.displayToastr(
            "ERROR",
            "Erreur lors de la création du suivi de lot au niveau de la diffusion!"
          );
        }
      );
    } else {
      this.customToastrService.displayToastr(
        "ERROR",
        "Les champs du formulaire ne sont pas valides!"
      );
    }
  }

  /**
   * Open new monitoring lot program form dialog
   *
   * @returns {void}
   * @memberof AddMonitoringLotComponent
   */
  public openNewMonitoringLotProgramFormDialog(): void {
    const programData = { channel: this.informationGroup.get("channel").value };
    const dialogRef: MatDialogRef<NewMonitoringLotProgramComponent> =
      this.dialog.open(NewMonitoringLotProgramComponent, {
        width: "54%",
        height: "75%",
        data: programData,
        autoFocus: false,
      });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (createdProgram: FilteredItem) => {
          if (createdProgram) {
            this.filteredProgramItems = of([createdProgram]);
            this.informationGroup.controls["program"].setValue(createdProgram, {
              emitEvent: false,
            });
          }
        },
        () => {
          this.customToastrService.displayToastr(
            "ERROR",
            "Une erreur est survenue!"
          );
        }
      );
  }

  /**
   * Clear Monitoring Lot form
   *
   * @private
   */
  private clearForm(): void {
    this.informationGroup.get("advertiser").reset();
    this.informationGroup.get("product").reset();
    this.informationGroup.get("campaign").reset();
    this.informationGroup.get("lot").reset();
    this.informationGroup.get("contractNumber").reset();
    this.informationGroup.get("purchase").reset();
    this.informationGroup.get("module").reset();
    this.informationGroup.get("periods").reset();

    // reset diffusionHour value to avoid error
    this.informationGroup.controls["periods"]["controls"].forEach((element) => {
      element.controls["diffusionHour"].setValue("00:00");
    });
  }

  /**
   * Convert SuiviLotDotation FormGroup to SuiviLotDotation resource object
   *
   * @returns {LotDotation}
   */
  private convertFormGroupToMonitoringLotDotationObject(): MonitoringLotDotation {
    return new MonitoringLotDotation({
      channel: this.informationGroup.get("channel").value,
      program: this.informationGroup.get("program").value,
      advertiser: this.informationGroup.get("advertiser").value,
      product: this.informationGroup.get("product").value,
      campaign: this.informationGroup.get("campaign").value,
      lot: this.informationGroup.get("lot").value,
      contract_number: this.informationGroup.get("contractNumber").value,
      bilan_number: this.informationGroup.get("bilanNumber").value,
      parroption: this.informationGroup.get("purchase").value.id,
      module: this.informationGroup.get("module").value.id,
      periods: this.informationGroup.get("periods").value.map((period) => ({
        id: period.id ? period.id : null,
        start_period: period.startPeriod,
        end_period: period.endPeriod,
        diffusionHour: period.diffusionHour,
        lots_won: period.lotsWon,
        lots_broadcasted: period.lotsBroadcasted,
      })),
    });
  }

  /**
   * Set the value after change
   *
   * @return {void}
   */
  private setValueChanges(): void {
    // Channel
    this.informationGroup.controls["channel"].valueChanges
      .pipe(
        startWith(null),
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(
        (value) => {
          this.filteredChannelItems = of([]);

          if (value && typeof value === "string") {
            // empty the value of program and clean the program list if we replace channel value
            this.informationGroup.controls["program"].setValue("", {
              emitEvent: false,
            });
            this.filteredProgramItems = of([]);

            this.loading = true;

            // Can search channel by id or by name
            const filterChannel = Number(value)
              ? { id: value }
              : { name: value.toUpperCase() };

            this.channelService
              .getListForFilter(filterChannel)
              .pipe(takeUntil(this.componentDestroyed$))
              .subscribe(
                (channelItems: FilteredItem[]) => {
                  // filter channels only national and 2, 3, 4 and 5 // 11/05/2023 Reactivation of France 4
                  this.nationalChannels = channelItems.filter(
                    (item) =>
                      item &&
                      item.entity &&
                      item.entity.channelGroup === "F" &&
                      ((item.entity.id && item.entity.id === "2") ||
                        item.entity.id === "3" ||
                        item.entity.id === "4" ||
                        item.entity.id === "5")
                  );
                  if (
                    this.nationalChannels &&
                    this.nationalChannels.length > 0
                  ) {
                    this.filteredChannelItems = of(
                      this.autocompleteService.filterItems(
                        value,
                        this.nationalChannels
                      )
                    );
                  }
                  this.loading = false;
                },
                () => {
                  this.customToastrService.displayToastr(
                    "ERROR",
                    "Erreur lors de la récupération des chaines"
                  );
                  this.loading = false;
                }
              );
          }
        },
        () => {
          this.customToastrService.displayToastr(
            "ERROR",
            "Une erreur est survenue."
          );
          this.loading = false;
        }
      );

    // Program
    this.informationGroup.controls["program"].valueChanges
      .pipe(
        startWith(null),
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(
        (value) => {
          this.filteredProgramItems = of([]);

          if (value && typeof value === "string") {
            this.loading = true;

            // Can search program by id or by name
            const programParams = Number(value)
              ? { id: value }
              : { name: value.toUpperCase() };

            // Add channel id and year in programParam to get the list of program according to the channel
            if (
              this.informationGroup.controls["channel"] != null &&
              this.informationGroup.controls["channel"].value !== "" &&
              this.informationGroup.controls["channel"].value.id &&
              typeof this.informationGroup.controls["channel"].value.id ===
                "string"
            ) {
              programParams["channel_id"] =
                this.informationGroup.controls["channel"].value.id;
              programParams["year"] = moment().format("YYYY");
            }

            this.programService
              .getListDotationProgramForFilter(programParams)
              .pipe(takeUntil(this.componentDestroyed$))
              .subscribe(
                (programItems: FilteredItem[]) => {
                  if (programItems && programItems.length > 0) {
                    this.filteredProgramItems = of(
                      this.autocompleteService.filterItems(value, programItems)
                    );
                  }
                  this.loading = false;
                },
                () => {
                  this.customToastrService.displayToastr(
                    "ERROR",
                    "Une erreur est survenue."
                  );
                  this.loading = false;
                }
              );
          }
        },
        () => {
          this.customToastrService.displayToastr(
            "ERROR",
            "Une erreur est survenue."
          );
          this.loading = false;
        }
      );

    // Advertiser
    this.informationGroup.controls["advertiser"].valueChanges
      .pipe(
        startWith(null),
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(
        (value) => {
          this.filteredAdvertiserItems = of([]);

          if (value && typeof value === "string") {
            // empty the value of product and campaign and clean the products and campaign list if we replace advertiser value
            this.informationGroup.controls["product"].setValue("", {
              emitEvent: false,
            });
            this.informationGroup.controls["campaign"].setValue("", {
              emitEvent: false,
            });
            this.filteredProductItems = of([]);
            this.filteredCampaignItems = of([]);

            this.loading = true;

            // Can search advertiser by id or by name
            const filterAdvertiser = Number(value)
              ? { id: value }
              : { name: value.toUpperCase() };

            // Get the list of Advertiser
            this.advertiserService
              .getListForFilter(filterAdvertiser)
              .pipe(takeUntil(this.componentDestroyed$))
              .subscribe(
                (advertiserItems: FilteredItem[]) => {
                  if (advertiserItems && advertiserItems.length > 0) {
                    this.filteredAdvertiserItems = of(
                      this.autocompleteService.filterItems(
                        value,
                        advertiserItems
                      )
                    );
                  }
                  this.loading = false;
                },
                () => {
                  this.customToastrService.displayToastr(
                    "ERROR",
                    "Erreur lors de la récupération des annonceurs"
                  );
                  this.loading = false;
                }
              );
          }
        },
        () => {
          this.customToastrService.displayToastr(
            "ERROR",
            "Une erreur est survenue."
          );
          this.loading = false;
        }
      );

    // Product
    this.informationGroup.controls["product"].valueChanges
      .pipe(
        startWith(null),
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(
        (value) => {
          this.filteredProductItems = of([]);

          if (value && typeof value === "string") {
            // empty the value of campaign, lot and purchase and clean the campaign, lot and purchase list if we replace product value
            this.informationGroup.controls["campaign"].setValue("", {
              emitEvent: false,
            });
            this.informationGroup.controls["lot"].setValue("", {
              emitEvent: false,
            });
            this.informationGroup.controls["purchase"].setValue("", {
              emitEvent: false,
            });
            this.filteredCampaignItems = of([]);
            this.filteredLotItems = of([]);
            this.filteredPurchaseDotationItems = of([]);

            this.loading = true;

            // Can search product by id or by name
            const productParams = Number(value)
              ? { id: value }
              : { name: value.toUpperCase() };

            // Add advertiser id in productParam to get the list of product according to the advertiser
            if (
              this.informationGroup.controls["advertiser"] != null &&
              this.informationGroup.controls["advertiser"].value !== "" &&
              this.informationGroup.controls["advertiser"].value.id &&
              typeof this.informationGroup.controls["advertiser"].value.id ===
                "number"
            ) {
              productParams["advertiser_id"] =
                this.informationGroup.controls["advertiser"].value.id;
            }

            this.productService
              .getListForFilter(productParams)
              .pipe(takeUntil(this.componentDestroyed$))
              .subscribe(
                (productItems: FilteredItem[]) => {
                  if (productItems && productItems.length > 0) {
                    this.filteredProductItems = of(
                      this.autocompleteService.filterItems(value, productItems)
                    );
                  }
                  this.loading = false;
                },
                () => {
                  this.customToastrService.displayToastr(
                    "ERROR",
                    "Une erreur est survenue."
                  );
                  this.loading = false;
                }
              );
          }
        },
        () => {
          this.customToastrService.displayToastr(
            "ERROR",
            "Une erreur est survenue."
          );
          this.loading = false;
        }
      );

    // Campaign
    this.informationGroup.controls["campaign"].valueChanges
      .pipe(
        startWith(null),
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(
        (value) => {
          this.filteredCampaignItems = of([]);

          if (value && typeof value === "string") {
            // empty the value of purchase, lot and clean the purchase and lot lists if we replace campaign value
            this.informationGroup.controls["purchase"].setValue("", {
              emitEvent: false,
            });
            this.informationGroup.controls["lot"].setValue("", {
              emitEvent: false,
            });
            this.filteredPurchaseDotationItems = of([]);
            this.filteredLotItems = of([]);

            this.loading = true;

            // get the list of campaign according to the product id
            if (
              this.informationGroup.controls["product"] != null &&
              this.informationGroup.controls["product"].value !== "" &&
              this.informationGroup.controls["product"].value.id &&
              typeof this.informationGroup.controls["product"].value.id ===
                "number"
            ) {
              this.campaignService
                .getListForFilter({
                  product_id:
                    this.informationGroup.controls["product"].value.id,
                  campaign_type: ["DOT", "ASL"], // campaign type dotation et droit d'asile
                  year: this.listOfYears,
                })
                .pipe(takeUntil(this.componentDestroyed$))
                .subscribe(
                  (campaignItems: FilteredItem[]) => {
                    if (campaignItems && campaignItems.length > 0) {
                      this.filteredCampaignItems = of(
                        this.autocompleteService.filterItems(
                          value,
                          campaignItems
                        )
                      );
                    }
                    this.loading = false;
                  },
                  () => {
                    this.customToastrService.displayToastr(
                      "ERROR",
                      "Une erreur est survenue."
                    );
                    this.loading = false;
                  }
                );
            }
          }
        },
        () => {
          this.customToastrService.displayToastr(
            "ERROR",
            "Une erreur est survenue."
          );
          this.loading = false;
        }
      );

    // Lot
    this.informationGroup.controls["lot"].valueChanges
      .pipe(
        startWith(null),
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(
        (value) => {
          this.filteredLotItems = of([]);

          if (value && typeof value === "string") {
            this.loading = true;

            // get the list of lot according to the advertiser and product id
            if (
              this.informationGroup.controls["advertiser"] != null &&
              this.informationGroup.controls["advertiser"].value !== "" &&
              this.informationGroup.controls["advertiser"].value.id &&
              typeof this.informationGroup.controls["advertiser"].value.id ===
                "number" &&
              this.informationGroup.controls["product"] != null &&
              this.informationGroup.controls["product"].value !== "" &&
              this.informationGroup.controls["product"].value.id &&
              typeof this.informationGroup.controls["product"].value.id ===
                "number"
            ) {
              this.lotDotationService
                .getListForFilter({
                  advertiser:
                    this.informationGroup.controls["advertiser"].value.id,
                  product: this.informationGroup.controls["product"].value.id,
                  campaign_num:
                    this.informationGroup.controls["campaign"].value.entity
                      .campaignNumber,
                  campaign_year:
                    this.informationGroup.controls["campaign"].value.entity
                      .year,
                })
                .pipe(takeUntil(this.componentDestroyed$))
                .subscribe(
                  (lotItems: FilteredItem[]) => {
                    if (lotItems && lotItems.length > 0) {
                      this.filteredLotItems = of(
                        this.autocompleteService.filterItems(value, lotItems)
                      );
                    }
                    this.loading = false;
                  },
                  () => {
                    this.customToastrService.displayToastr(
                      "ERROR",
                      "Une erreur est survenue."
                    );
                    this.loading = false;
                  }
                );
            }
          }
        },
        () => {
          this.customToastrService.displayToastr(
            "ERROR",
            "Une erreur est survenue."
          );
          this.loading = false;
        }
      );

    // Module
    this.informationGroup.controls["module"].valueChanges
      .pipe(
        startWith(null),
        debounceTime(500),
        distinctUntilChanged(),
        filter((value) => typeof value === "string"),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(
        (value) => {
          // maybe to remove
          // this.filteredModuleDotationItems = of([]);

          // And do instead
          /*if (value !== '') {
          this.filteredModuleDotationItems = of([]);
        }*/

          // TODO check iv value = '' doesnt pass the if condition
          if (typeof value === "string") {
            this.filteredModuleDotationItems = of(
              this.autocompleteService.filterItems(value, this.modulesList)
            );
          }
        },
        () => {
          this.customToastrService.displayToastr(
            "ERROR",
            "Une erreur est survenue."
          );
          this.loading = false;
        }
      );
  }

  /**
   * Generate a list of the 5 last years
   */
  private generateListOfYears() {
    let max = new Date().getFullYear();
    let min = max - 5;

    for (let i = max; i >= min; i--) {
      this.listOfYears.push(i);
    }
  }

  /**
   * Create Data In ParrDiffusion Table (1 by period)
   */
  private postMonitoringLotParrDiffusion(): Observable<any> {
    const params: MonitoringLotParrDiffusionFilter = {
      channelId: this.informationGroup.get("channel").value.id,
      programId: this.informationGroup.get("program").value.id || "",
      periods: this.informationGroup.get("periods").value.map((period) => ({
        id: period.id ? period.id : null,
        start_period: moment(period.startPeriod).format("DD/MM/YYYY"),
        end_period: moment(period.endPeriod).format("DD/MM/YYYY"),
        diffusionHour: period.diffusionHour,
        lots_won: period.lotsWon,
        lots_broadcasted: period.lotsBroadcasted,
      })),
    };

    return this.lotDotationService.postMonitoringLotParrDiffusion(params);
  }

  /**
   * Create Data In ParrDiffusionPanier Table (1 by new line in ParrDiffusion)
   */
  private postMonitoringLotParrDiffusionPanier(
    diffusionIds = []
  ): Observable<any> {
    const params: MonitoringLotParrDiffusionPanierFilter = {
      lotDiffId: this.informationGroup.get("lot").value.id || "",
      moduleId: this.informationGroup.get("module").value.id || "",
      diffusionIds: diffusionIds || [],
    };

    return this.lotDotationService.postMonitoringLotParrDiffusionPanier(params);
  }
}
