
import {map,  catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Broadcast } from '../../resource/broadcast.resource';
import { PropalCart } from '../../resource/availability/propal-cart.resource';
import { OfferProgram } from '../../resource/offerProgram.resource';
import { PropalCartService } from '../propal-cart/propal-cart.service';
import { OfferPush } from '../../resource/offer-push.resource';
import { environment } from '../../../environments/environment';
import { UtilitiesHandler } from '@service/utilities-handlers/utilitiesHandlers';

import { Observable } from 'rxjs';

@Injectable()
export class OfferPushService {
  private route: string = 'offer_push';

  constructor(
      private router: Router,
      private propalCartService: PropalCartService,
      private http: HttpClient,
      private utilitiesHandler: UtilitiesHandler
  ) { }

  /**
   * Get offerpush with ID
   *
   * @param {(string | number)} id
   * @returns {Observable<OfferPush>}
   * @memberof OfferPushService
   */
  public get(id: string | number): Observable<OfferPush> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}/${id}`;

    return this.http
      .get(api_base_url).pipe(
      map((data: any) =>
        new OfferPush(data)
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get the list offer push
   *
   * @param {*} filters
   * @param {string} [groups=null]
   * @returns {Observable<OfferPush[]>}
   * @memberof OfferPushService
   */
  public getList(filters: any, groups: string = null): Observable<OfferPush[]> {
    if (groups) {
      filters.groups = groups;
    }

    const api_base_url: string = `${environment.api_base_url}/${this.route}`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params}).pipe(
      map((data: any) =>
        data._embedded.offer_push
          .map((jsonOfferPush: any) =>
            new OfferPush(jsonOfferPush)
          )
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Create
   *
   * @param {PropalCart} propalCart
   * @returns {Observable<boolean>}
   * @memberof OfferPushService
   */
  public create(propalCart: PropalCart): Observable<boolean> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;

      return Observable.create(observer => {
          if (!this.checkIfPropalCartValid(propalCart)) {
              observer.next(false);
              return;
          }

          this.http
            .post(api_base_url, this.extractPost(propalCart))
            .subscribe((offerPush: any) => {
                this.propalCartService.resetPropalCart();
                this.router.navigate([`/offers-push/edit/${offerPush.id}`]);
              }, (error) => observer.next(false)
          );
      });
  }

  /**
   * Edit offer push
   *
   * @param {OfferPush} offerPush
   * @returns {(Observable<OfferPush | boolean>)}
   * @memberof OfferPushService
   */
  public edit(offerPush: OfferPush): Observable<OfferPush | boolean> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}/${offerPush.id}`;

    return this.http
      .put(api_base_url, this.extractPut(offerPush)).pipe(
      map((data: any) =>
        data._embedded.offer_push
          .map((jsonOfferPush: any) =>
            new OfferPush(jsonOfferPush)
          )
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Check if propal cart is valid
   *
   * @param {PropalCart} propalCart
   * @returns {boolean}
   * @memberof OfferPushService
   */
  public checkIfPropalCartValid(propalCart: PropalCart): boolean {
    let valid = true;

    if (propalCart.offerPrograms.length === 1) {
        return valid;
    }

    let tempOfferProgram: OfferProgram;
    propalCart.offerPrograms.forEach(offerProgramPropalCart => {
        if (!tempOfferProgram) {
            tempOfferProgram = offerProgramPropalCart.offerProgram;
        }

        if (tempOfferProgram.offer.id !== offerProgramPropalCart.offerProgram.offer.id) {
            valid = false;
        } else {
            tempOfferProgram = offerProgramPropalCart.offerProgram;
        }
    });

    return valid;
  }

  /**
   * Extract broadcast from propal cart
   *
   * @private
   * @param {PropalCart} propalCart
   * @returns {Broadcast[]}
   * @memberof OfferPushService
   */
  private extractBroadcastFromPropalCart(propalCart: PropalCart): Broadcast[] {
    let broadcasts: Broadcast[] = [];

    propalCart.offerPrograms.forEach(offerProgram => {
        offerProgram.broadcasts.forEach(broadcast => {
          broadcasts.push(broadcast);
        });
    });

    return broadcasts;
  }

  /**
   * Extract post
   *
   * @private
   * @param {PropalCart} propalCart
   * @returns {Object}
   * @memberof OfferPushService
   */
  private extractPost(propalCart: PropalCart): Object {
    return {
      name: propalCart.offerPrograms[0].offerProgram.offer.name,
      parent: propalCart.offerPrograms[0].offerProgram.offer.id,
      broadcasts: this.extractBroadcastFromPropalCart(propalCart).map(broadcast => broadcast.id)
    }
  }

  /**
   * Extract Put
   *
   * @private
   * @param {OfferPush} offerPush
   * @returns {Object}
   * @memberof OfferPushService
   */
  private extractPut(offerPush: OfferPush): Object {
    return {
      emails: offerPush.emailRecipients.map(emailRecipient => emailRecipient.email).join(';')
    }
  }
}
