import { Target } from './target.resource';
import { Base } from './base.resource';
import { PurchaseType } from './purchaseType.resource';
import { OfferProgram } from './offerProgram.resource';
import { OfferPeriodIndice } from './offer-period-indice.resource';
import { ExclusiveOfferPeriod, JsonExclusiveOfferPeriod } from './exclusive-offer-period.resource';

const WEEKS     = 'Semaines';
const DAYS      = 'Jours';
const PROGRAMS  = 'Emissions';
const TOTAL     = 'Offre totale';

/**
 * Offer interface
 *
 * @export
 * @interface JsonOffer
 */
export interface JsonOffer {
    id: number;
    isExclusive: number;
    name: string;
    global_price: number;
    max_number_of_advertiser: number;
    marketing_base_number: number;
    marketing_base_type: string;
    period_type: string;
    budget_type: string;
    optionnable: number;
    tv_budget: number;
    tv_classic_budget: number;
    tv_thema_budget: number;
    digital_budget: number;
    digital_display_budget: number;
    digital_replay_budget: number;
    dig_vid_bill_budget: number;
    dig_vid_pre_budget: number;
    digital_social_budget: number;
    social_fb_budget: number;
    social_twit_budget: number;
    social_insta_budget: number;
    digital_other_budget: number;
    floor_price: number;
    period_start_date: string;
    period_end_date: string;
    created: string;
    updated: string;
    upduid: string;
    offer_programs: OfferProgram[];
    target: any;
    grp: string;
    cgrp: number;
    average_duration_rate: number;
    parent: number;
    periods_indices?: OfferPeriodIndice[];
    exclusive_periods?: JsonExclusiveOfferPeriod[];
    is_exclusive?: string;
    last_date_offer_exclusive?: string;
}

/**
 * Offer resource
 *
 * @export
 * @class Offer
 * @extends {Base}
 */
export class Offer extends Base {
    /**
     * Marketing Base types
     *
     * @static
     * @memberOf Offer
     */
    static marketingBaseTypeItems = [
      { value: WEEKS,    display: WEEKS },
      { value: DAYS,     display: DAYS },
      { value: PROGRAMS, display: PROGRAMS },
      { value: TOTAL,    display: TOTAL }
    ];

    public static WEEKS = WEEKS;
    public static DAYS = DAYS;
    public static PROGRAMS = PROGRAMS;
    public static TOTAL = TOTAL;

    /**
     * Period types
     *
     * @static
     * @memberOf Offer
     */
    static periodTypeItems = [
        { value: 'ETE', display: 'Eté' },
        { value: 'HETE', display: 'Hors été' },
        { value: 'AUTRE', display: 'Autre' }
    ];

    /**
     * Budget types
     *
     * @static
     * @memberOf Offer
     */
    static budgetTypeItems = [
        { value: 'NET', display: 'Net' },
        { value: 'CAINI', display: 'CA initial' }
    ];

    static init(): JsonOffer {
        return {
            id: null,
            name: '',
            isExclusive: 0,
            global_price: 0,
            max_number_of_advertiser: null,
            marketing_base_number: null,
            marketing_base_type: '',
            period_type: '',
            budget_type: 'NET',
            optionnable: 1,
            period_start_date: '',
            period_end_date: '',
            tv_budget : 0,
            tv_classic_budget: 0,
            tv_thema_budget: 0,
            digital_budget: 0,
            digital_display_budget: 0,
            digital_replay_budget: 0,
            dig_vid_bill_budget: 0,
            dig_vid_pre_budget: 0,
            digital_social_budget: 0,
            social_fb_budget: 0,
            social_twit_budget: 0,
            social_insta_budget: 0,
            digital_other_budget: 0,
            floor_price: 0,
            created: '',
            updated: '',
            upduid: '' ,
            offer_programs: [],
            target: null,
            grp: '',
            cgrp: 0,
            average_duration_rate: 0,
            parent: null,
            periods_indices: [],
            exclusive_periods: []
        };
    }

    public id: number;
    public isExclusive: number;
    public name: string;
    public globalPrice: number;
    public maxNumberOfAdvertiser: number;
    public marketingBaseNumber: number;
    public marketingBaseType: string;
    public periodType: string;
    public budgetType: string;
    public optionnable: number;
    public tvBudget: number;
    public tvClassicBudget: number;
    public tvThemaBudget: number;
    public digitalBudget: number;
    public digitalDisplayBudget: number;
    public digitalReplayBudget: number;
    public digVidBillBudget: number;
    public digVidPreBudget: number;
    public digitalSocialBudget: number;
    public socialFbBudget: number;
    public socialTwitBudget: number;
    public socialInstaBudget: number;
    public digitalOtherBudget: number;
    public floorPrice: number;
    public periodStartDate: string;
    public periodEndDate: string;
    public created: string;
    public updated: string;
    public upduid: string;
    public offerPrograms: OfferProgram[];
    public target: any;
    public grp: string;
    public cgrp: number;
    public averageDurationRate: number;
    public parent: number;
    public periodsIndices: OfferPeriodIndice[];
    public exclusivePeriods: ExclusiveOfferPeriod[];
    public lastDateOfferExclusive?: string;

    constructor(jsonOffer) {
        super();

        jsonOffer = this.convertToUnderscore(jsonOffer);

        this.id = jsonOffer.id;
        this.isExclusive = jsonOffer.is_exclusive;
        this.name = jsonOffer.name;
        this.globalPrice = jsonOffer.global_price;
        this.maxNumberOfAdvertiser = jsonOffer.max_number_of_advertiser;
        this.marketingBaseNumber = jsonOffer.marketing_base_number;
        this.marketingBaseType = jsonOffer.marketing_base_type;
        this.periodType = jsonOffer.period_type;
        this.budgetType = jsonOffer.budget_type;
        this.optionnable = jsonOffer.optionnable;
        this.tvBudget = jsonOffer.tv_budget;
        this.tvClassicBudget = jsonOffer.tv_classic_budget;
        this.tvThemaBudget = jsonOffer.tv_thema_budget;
        this.digitalBudget = jsonOffer.digital_budget;
        this.digitalDisplayBudget = jsonOffer.digital_display_budget || 0;
        this.digitalReplayBudget = jsonOffer.digital_replay_budget || 0;
        this.digVidBillBudget = jsonOffer.dig_vid_bill_budget || 0;
        this.digVidPreBudget = jsonOffer.dig_vid_pre_budget || 0;
        this.digitalSocialBudget = jsonOffer.digital_social_budget || 0;
        this.socialFbBudget = jsonOffer.social_fb_budget || 0;
        this.socialTwitBudget = jsonOffer.social_twit_budget || 0;
        this.socialInstaBudget = jsonOffer.social_insta_budget || 0;
        this.digitalOtherBudget = jsonOffer.digital_other_budget || 0;
        this.floorPrice = jsonOffer.floor_price;
        this.periodStartDate = jsonOffer.period_start_date;
        this.periodEndDate = jsonOffer.period_end_date;
        this.created = jsonOffer.created;
        this.updated = jsonOffer.updated;
        this.upduid = jsonOffer.upduid;
        this.offerPrograms = jsonOffer.offer_programs ? this.setOfferPrograms(jsonOffer.offer_programs) : null;
        this.target = jsonOffer.target ? new Target(jsonOffer.target) : null;
        this.grp = jsonOffer.grp;
        this.cgrp = jsonOffer.cgrp;
        this.averageDurationRate = jsonOffer.average_duration_rate;
        this.parent = jsonOffer.parent ? jsonOffer.parent : null;
        this.lastDateOfferExclusive = jsonOffer.last_date_offer_exclusive ? jsonOffer.last_date_offer_exclusive : null

        if (jsonOffer.periods_indices) {
            let constructOfferPeriod = [];
            jsonOffer.periods_indices.forEach(indicePeriod => {
                constructOfferPeriod.push(new OfferPeriodIndice(indicePeriod));
            });

            this.periodsIndices = constructOfferPeriod;
        } else {
            this.periodsIndices = null;
        }

        if (jsonOffer.exclusive_periods) {
          let constructOfferExclusive = [];

          jsonOffer.exclusive_periods.forEach((jsonExclusivePeriod: JsonExclusiveOfferPeriod) => {
            constructOfferExclusive.push(new ExclusiveOfferPeriod(jsonExclusivePeriod));
          });

          this.exclusivePeriods = constructOfferExclusive
        } else {
          this.exclusivePeriods = [];
        }
    }

    public checkDate() {
        this.periodStartDate = this.periodStartDate === 'Invalid date' ? null : this.periodStartDate;
        this.periodEndDate = this.periodEndDate === 'Invalid date' ? null : this.periodEndDate;
    }

    public removeOfferProgram(id) {
        let offerPrograms = this.offerPrograms;

        let newArray = [];
        this.offerPrograms.forEach(offerProgram => {
            if (offerProgram.id !== id) {
               newArray.push(offerProgram)
           }
        });

        this.offerPrograms = newArray;
    }

    public isOptionable(): boolean {
        return this.optionnable === 1;
    }

    public isPush(): boolean {
        return !!this.parent;
    }

    private setOfferPrograms(offerPrograms): OfferProgram[] {
        return offerPrograms.map(offerProgram => new OfferProgram(offerProgram));
    }
}
