import { Base } from './base.resource';

const CATEGORY_5    = 5;
const CATEGORY_10   = 10;
const CATEGORY_20   = 20;

export interface JsonAbatement {
    id?: number;
    name?: string;
    code?: string;
    rate?: number;
    is_fixed?: boolean;
    is_cascade?: boolean;
    abatement_order?: number;
    start_time?: string;
    end_time?: string;
    category?: number;
}

export class Abatement extends Base {
    public static CATEGORY_5    = CATEGORY_5;
    public static CATEGORY_10   = CATEGORY_10;
    public static CATEGORY_20   = CATEGORY_20;

    public id: number;
    public name: string;
    public code: string;
    public rate: number;
    public isFixed: boolean;
    public isCascade: boolean;
    public abatementOrder: number;
    public startTime: string;
    public endTime: string;
    public category: number;

    // custom
    public isFixedLabel: string;
    public isSelected: boolean;

    constructor(jsonAbatement: JsonAbatement) {

        super();
        jsonAbatement = this.convertToUnderscore(jsonAbatement);

        this.id = jsonAbatement.id;
        this.name = jsonAbatement.name;
        this.code = jsonAbatement.code;
        this.rate = jsonAbatement.rate;
        this.isFixed = jsonAbatement.is_fixed;
        this.isCascade = jsonAbatement.is_cascade;
        this.abatementOrder = jsonAbatement.abatement_order;
        this.startTime = jsonAbatement.start_time;
        this.endTime = jsonAbatement.end_time;
        this.category = jsonAbatement.category;
        this.isFixedLabel = jsonAbatement.is_fixed ? 'oui' : 'non';
    }
}
