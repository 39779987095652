import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { UtilitiesHandler } from '@service/utilities-handlers/utilitiesHandlers';

import { Observable, Subject } from 'rxjs';
import {catchError, filter, map, tap} from 'rxjs/operators';
import { FilteredItem } from '../../resource/filteredItem.resource';

@Injectable()
export class SignataireService {
  private routeS: string = 'signatory';
  private routeF: string = 'signatory_function';
  private routeX: string = 'signatory_ftp';

  private enableConvention = new Subject<boolean>();
  public enableConvention$ = this.enableConvention.asObservable();

  constructor(
    private http: HttpClient,
    private utilitiesHandler: UtilitiesHandler
  ) { }

  /**
   * Get one time a List for signatory FTP
   *
   * @return {Observable<any>}
   * @memberOf SignataireService
   */
  public getSignatoryFtpListForFilter(): Observable<any> {
    const api_base_url: string = `${environment.api_base_url}/${this.routeX}`;

    return this.http
      .get(api_base_url)
      .pipe(
        map((data: any) =>
          data._embedded.signatory_ftp
            .map((signatory) =>
              new FilteredItem(signatory)
            )
        )
      )
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get all signataires with name
   *
   * @param {number} customer_id
   * @param {string} name
   * @memberOf SignataireService
   */
  public getSignatorysListForFilter(customer_id: number, name?: string): Observable<any> {
    const api_base_url: string = `${environment.api_base_url}/${this.routeS}`;
    let filters = {};

    if (customer_id) {
      filters['customer_id'] = customer_id;
    }
    if (name) {
      filters['signatory_name'] = name;
    }

    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params})
      .pipe(
        map((data: any) =>
          data._embedded.signatory
            .map((signatory) =>
              new FilteredItem(signatory)
            )
        ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Post new signataire
   *
   * @param {string} signatory
   * @param {number} customer
   * @memberOf SignataireService
   */
  public postSignatory(signatory: Object, customer: number): Observable<any> {
    const api_base_url: string = `${environment.api_base_url}/${this.routeS}`;

    return this.http
      .post(api_base_url, this.extractSignatory(signatory, customer))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Put signataire with id
   *
   * @param data
   * @memberOf SignataireService
   */
  public putSignatory(data: any): Observable<any> {
    const api_base_url: string = `${environment.api_base_url}/${this.routeS}/${data.id}`;

    return this.http
      .put(api_base_url, data)
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get all functions with name
   *
   * @param {string} name
   * @memberOf SignataireService
   */
  public getFonctionsListForFilter(name?: string): Observable<any> {
    const api_base_url: string = `${environment.api_base_url}/${this.routeF}`;
    const filters: Object = name ? {name: name} : {};
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, { params })
      .pipe(
        map((data: any) =>
          data._embedded.signatory_function
            .map((fonction) =>
              new FilteredItem(fonction)
            )
        ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Post new function
   *
   * @param {string} signatory
   * @memberOf SignataireService
   */
  public postFonction(signatory: Object): Observable<any> {
    const api_base_url: string = `${environment.api_base_url}/${this.routeF}`;

    return this.http
      .post(api_base_url, this.extractSignatory(signatory))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Put function with id
   *
   * @param data
   * @memberOf SignataireService
   */
  public putFonction(data: any): Observable<any> {
    const api_base_url: string = `${environment.api_base_url}/${this.routeF}/${data.id}`;

    return this.http
      .put(api_base_url, data)
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Send event to status convention
   *
   * @param status
   */
  public sendStatusConvention (status: boolean): void {
    this.enableConvention.next(status);
  }

  /**
   * Extract for param API
   *
   * @param data
   * @param {number} customer
   * @memberOf SignataireService
   */
  private extractSignatory(data, customer?: number): Object {
    if (customer) {
      return {
        customer_id: customer,
        civility: data.civility.value,
        first_name: data.firstName.value,
        last_name: data.lastName.value
      }
    } else {
      return {
        signatory_function: data.value.name,
      }
    }
  }

}
