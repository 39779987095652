import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { SignataireService } from '@service/signataire/signataire.service';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-add-signataire-dialog',
  templateUrl: './add-signataire-dialog.component.html',
  styleUrls: ['./add-signataire-dialog.component.scss']
})
export class AddSignataireDialogComponent implements OnInit {
  public signatory: FormGroup;
  public loading: boolean = false;
  private componentDestroyed$: Subject<any> = new Subject();

  constructor(
    private signataireService: SignataireService,
    private customToastrService: CustomToastrService,
    public dialogRef: MatDialogRef<AddSignataireDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: number
  ) {
    this.signatory = new FormGroup({
      civility: new FormControl(null, [Validators.maxLength(30), Validators.required]),
      firstName: new FormControl(null, [Validators.maxLength(30), Validators.required]),
      lastName: new FormControl(null, [Validators.maxLength(30), Validators.required])
    });
  }

  ngOnInit() {}

  /**
   * Close dialog signataire without save
   *
   * @memberOf AddSignataireDialogComponent
   */
  close(name = null): void {
    this.dialogRef.close(name);
  }

  /**
   * Save new signataire
   *
   * @memberOf AddSignataireDialogComponent
   */
  save(): void {
    if (this.signatory.valid) {
      this.loading = true;

      this.signataireService.postSignatory(this.signatory.controls, this.data)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((response) => {
          this.loading = false;
          this.customToastrService.displayToastr('SUCCESS', 'Signataire créée');
          this.close(response);
        }, error => {
          this.customToastrService.displayToastr('ERREUR', error.data.detail + 'Enregistrement échoué :');
          this.loading = false;
          this.close();
      });
    }
  }
}
