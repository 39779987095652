import { Broadcast } from './broadcast.resource';
import { Purchase } from './purchase.resource';
import { OfferProgram } from './offerProgram.resource';
import { Base } from './base.resource';

const NO_STATUS = null;
const BOOKED = 1;
const NOT_BOOKABLE = 2;
const PURCHASED = 3;
const INVOICED = 4;

const STATUS_LABELS = [
        {value: NO_STATUS, label: 'statut indéfini'},
        {value: BOOKED, label: 'réservé'},
        {value: NOT_BOOKABLE, label: 'non réservable'},
        {value: PURCHASED, label: 'acheté'},
        {value: INVOICED, label: 'facturé'},
    ];

export interface JsonBroadcastPurchased {
    id: number;
    offer_program?: any;
    purchase?: any;
    broadcast?: any;
    price?: number;
    reference_price?: number;
    net_before_discount_price?: number;
    net_price?: number;
    status?: number;
    soreach_billboard_price?: number;
}

export class BroadcastPurchased extends Base {

    public static BOOKED = BOOKED;
    public static NOT_BOOKABLE = NOT_BOOKABLE;
    public static PURCHASED = PURCHASED;
    public static INVOICED = INVOICED;

    public id: number;
    public startTime: string;
    public endTime: string;
    public price: number;
    public referencePrice?: number;
    public netBeforeDiscountPrice?: number;
    public netPrice?: number;
    public soreachBillboardPrice?: number;

    public status: number;
    public statusLabel: string;

    // relations
    public offerProgram: OfferProgram;
    public purchase: Purchase;
    public broadcast: Broadcast;
    public broadcastId: number;

    constructor(json: JsonBroadcastPurchased) {
        super();
        json = this.convertToUnderscore(json);

        this.id = json.id;
        this.price = json.price;
        this.referencePrice = json.reference_price;
        this.netBeforeDiscountPrice = json.net_before_discount_price;
        this.netPrice = json.net_price;
        this.soreachBillboardPrice = json.soreach_billboard_price;
        this.status = json.status;

        if (this.status) {
            let statusLabel = STATUS_LABELS.filter(item => item.value === json.status);
            if (statusLabel.length) {
                this.statusLabel = statusLabel.shift().label;
            }
        }

        if (json.offer_program) {
            this.offerProgram = new OfferProgram(json.offer_program);
        }

        if (json.purchase) {
            this.purchase = new Purchase(json.purchase);
        }

        if (json.offer_program) {
            this.offerProgram = new OfferProgram(json.offer_program);
        }

        if (json.broadcast && json.broadcast.id && json.broadcast.start_time && json.broadcast.end_time) { // Program/offer purchase
            this.startTime = json.broadcast.start_time;
            this.endTime = json.broadcast.end_time;
            this.broadcastId = json.broadcast.id;
            this.broadcast = new Broadcast(json.broadcast);
        } else if (json.broadcast && json.broadcast.broadcastIds) { // soReach purchase
            this.broadcastId = json.broadcast.broadcastIds;
            this.broadcast = new Broadcast(json.broadcast);
        } else if (json.broadcast && json.broadcast.id) { // Default purchase
            this.broadcastId = json.broadcast.id;
            this.broadcast = new Broadcast(json.broadcast);
        }
    }

    public isInvoiced(): boolean {
        return this.status === INVOICED;
    }
}
