import { Base } from './base.resource';
import { Advertiser } from './advertiser.resource';
import { Product } from './product.resource';
import { Program } from './program.resource';
import { Campaign, JsonCampaign } from './campaign.resource';
import { Channel } from './channel.resource';

import * as moment from 'moment';

export interface JsonLotDotation {
  id?: number;
  name: string;
  description: string;
  quantity: number;
  unit_price: number;
  product: Product;
  advertiser: Advertiser;
  parroption: number;
  parroption_name: string;
  period_start_date?: string;
  period_end_date?: string;
  total_won_lots?: number;
  total_invoiced_lots?: number;
  ending_balance?: number;
}

export class LotDotation extends Base {
  public id: number;
  public name: string;
  public description: string;
  public quantity: number;
  public unitPrice: number;
  public product: Product;
  public advertiser: Advertiser;
  public parroption: number;
  public parroptionName: string;
  public periodStartDate: string;
  public periodEndDate: string;
  public totalWonLots?: number;
  public totalInvoicedLots?: number;
  public endingBalance?: number;

  constructor(json: JsonLotDotation) {
    super();

    json = this.convertToUnderscore(json);

    this.id = json.id ? json.id : null;
    this.name = json.name ? json.name : null;
    this.description = json.description ? json.description : null;
    this.quantity = json.quantity ? json.quantity : null;
    this.unitPrice = json.unit_price ? json.unit_price : null;
    this.product = json.product ? new Product(json.product) : null;
    this.advertiser = json.advertiser ? new Advertiser(json.advertiser) : null;
    this.parroption  = json.parroption ? json.parroption : null;
    this.parroptionName  = json.parroption_name ? json.parroption_name : null;
    this.periodStartDate = json.period_start_date ? json.period_start_date : null;
    this.periodEndDate = json.period_end_date ? json.period_end_date : null;
    this.totalWonLots = json.total_won_lots ? json.total_won_lots : 0;
    this.totalInvoicedLots = json.total_invoiced_lots ? json.total_invoiced_lots : 0;
    this.endingBalance = this.quantity - this.totalWonLots;
  }
}

export interface JsonPeriodMonitoringLotDotation {
  id?: number;
  start_period: string | object;
  end_period: string | object;
  diffusionHour: string;
  lots_won: number;
  lots_broadcasted: number;
}

export class PeriodMonitoringLotDotation {
  id?: number;
  startPeriod: string;
  endPeriod: string;
  diffusionHour: string;
  lotsBroadcasted: number;
  lotsWon: number;

  constructor(json: JsonPeriodMonitoringLotDotation) {
    this.id = json.id ? json.id : null;
    this.startPeriod = json.start_period ? ((json.start_period['date'] && typeof json.start_period['date'] === 'string') ?
      moment(json.start_period['date']).format('YYYY-MM-DD') : moment(json.start_period).format('YYYY-MM-DD')) : null;
    this.endPeriod = json.end_period ? ((json.end_period['date'] && typeof json.start_period['date'] === 'string') ?
      moment(json.end_period['date']).format('YYYY-MM-DD') : moment(json.end_period).format('YYYY-MM-DD')) : null;
    this.diffusionHour = json.diffusionHour ? json.diffusionHour : null;
    this.lotsBroadcasted = json.lots_broadcasted ? json.lots_broadcasted : null;
    this.lotsWon = json.lots_won ? json.lots_won : null;
  }
}

export interface JsonMonitoringLotDotation {
  id?: number;
  channel: Channel,
  program: Program,
  advertiser: Advertiser,
  product: Product,
  campaign: JsonCampaign,
  lot: JsonLotDotation,
  contract_number: string,
  bilan_number: string,
  parroption: number,
  periods: JsonPeriodMonitoringLotDotation[],
  invoiced?: string,
  invoice_origin?: string,
  invoice_year?: string,
  invoice_number?: number,
  module?: number
}

export class MonitoringLotDotation extends Base {
  id?: number;
  channel: Channel;
  program: Program;
  advertiser: Advertiser;
  product: Product;
  campaign: Campaign;
  lot: LotDotation;
  contractNumber: string;
  bilanNumber: string;
  parroption: number;
  periods: PeriodMonitoringLotDotation[];
  broadcastsNumber?: number;
  totalLotsWon?: number;
  unitPrice?: number;
  totalLotsWonPrice?: number;
  description?: string;
  invoiced?: string;
  invoiceOrigin?: string;
  invoiceYear?: string;
  invoiceNumber?: number;
  module?: number;

  constructor(json: JsonMonitoringLotDotation) {
    super();
    json = this.convertToUnderscore(json);

    this.id = json.id ? json.id : null;
    this.channel = json.channel ? json.channel : null;
    this.program = json.program ? json.program : null;
    this.advertiser = json.advertiser ? json.advertiser : null;
    this.product = json.product ? json.product : null;
    this.campaign = json.campaign ? new Campaign(json.campaign) : null;
    this.lot = json.lot ? new LotDotation(json.lot) : null;
    this.contractNumber = json.contract_number ? json.contract_number : null;
    this.bilanNumber = json.bilan_number ? json.bilan_number : null;
    this.parroption = json.parroption ? json.parroption : null;
    this.description = (json.lot && json.lot.description) ? json.lot.description : '';
    this.invoiced = json.invoiced ? json.invoiced : 'N';
    this.invoiceOrigin = json.invoice_origin ? json.invoice_origin : '';
    this.invoiceYear = json.invoice_year ? json.invoice_year : '';
    this.invoiceNumber = json.invoice_number ? json.invoice_number : null;
    this.module = json.module ? json.module : null;

    if (this.lot && this.lot.unitPrice) {
      this.unitPrice = this.lot.unitPrice;
    }

    if (json.periods) {
      const periodsCollection = [];

      json.periods.forEach((period: JsonPeriodMonitoringLotDotation) => {
        periodsCollection.push(new PeriodMonitoringLotDotation(period));
      });

      this.periods = periodsCollection;
    } else {
      this.periods = null;
    }

    if (this.periods && this.periods.length) {
      this.periods.forEach((period: PeriodMonitoringLotDotation) => {
        if ((period.lotsBroadcasted || period.lotsBroadcasted === 0) && period.lotsBroadcasted > 0) {
          if (!this.broadcastsNumber) {
            this.broadcastsNumber = 0;
          }
          this.broadcastsNumber += period.lotsBroadcasted;
        }
        if ((period.lotsWon || period.lotsWon === 0) && period.lotsWon > 0) {
          if (!this.totalLotsWon) {
            this.totalLotsWon = 0;
          }
          this.totalLotsWon += period.lotsWon;
        }
      });
    }

    if (this.unitPrice && this.totalLotsWon) {
      this.totalLotsWonPrice = this.unitPrice * this.totalLotsWon;
    }
  }

  /**
   * formate the name of the invoice
   */
  public getInvoiceName(): string {
    let invoiceName = ''

    if (this.invoiced === 'Y') {
      invoiceName = `${this.invoiceOrigin.substring(0, 1)}${this.invoiceYear}${this.invoiceNumber}`;
    }

     return invoiceName;
  }
}

/*
export interface JsonDotationModule {
  id: number;
  name: string;
}

export class DotationModule extends Base {

  public id: number;
  public name: string;

  constructor(jsonDotationModule: JsonDotationModule) {
    super();

    this.id = jsonDotationModule.id;
    this.name = jsonDotationModule.name;
  }
}*/
