export interface JsonExclusiveOfferPeriod {
  id?: number;
  offer?: number;
  start_period: string;
  end_period: string;
  weeks_number: number;
  discount_level1: number;
  discount_level2: number;
  discount_level3: number;
  digital_budget_id: number;
  thema_channel_id: string;
  is_visible: number;
}

export class ExclusiveOfferPeriod {
  id?: number;
  offer?: number;
  startPeriod: string;
  endPeriod: string;
  weeksNumber: number;
  digitalBudgetId: number;
  themaChannelId?: string;
  discountLevel1: number;
  discountLevel2: number;
  discountLevel3: number;
  isPurchased?: boolean;
  isVisible: number;

  constructor(jsonExclusive: JsonExclusiveOfferPeriod) {
    if (jsonExclusive.id) {
      this.id = jsonExclusive.id;
    }

    if (jsonExclusive.offer) {
      this.offer = jsonExclusive.offer;
    }

    if (jsonExclusive.start_period) {
     this.startPeriod = jsonExclusive.start_period;
    }

    if (jsonExclusive.end_period) {
      this.endPeriod = jsonExclusive.end_period;
    }

    if (jsonExclusive.weeks_number) {
      this.weeksNumber = jsonExclusive.weeks_number;
    }

    this.digitalBudgetId = jsonExclusive.digital_budget_id ? jsonExclusive.digital_budget_id : null;

    if (jsonExclusive.discount_level1) {
      this.discountLevel1 = jsonExclusive.discount_level1;
    }

    if (jsonExclusive.discount_level2) {
      this.discountLevel2 = jsonExclusive.discount_level2;
    }

    if (jsonExclusive.discount_level3) {
      this.discountLevel3 = jsonExclusive.discount_level3;
    }

    this.isVisible = jsonExclusive.is_visible;

    this.themaChannelId = jsonExclusive.thema_channel_id ? jsonExclusive.thema_channel_id : null;
  }
}
