export interface JsonDigitalBudgetName {
  id: number;
  budget_name: string;
}

export class DigitalBudgetName {
  id: number;
  budgetName: string;

  constructor(jsonBudgetDigital: JsonDigitalBudgetName) {
    if (jsonBudgetDigital.id) {
      this.id = jsonBudgetDigital.id;
    }

    if (jsonBudgetDigital.budget_name) {
      this.budgetName = jsonBudgetDigital.budget_name;
    }
  }
}
