import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appRemoveAccents]'
})
export class RemoveAccentsDirective {

  constructor(
    private el: ElementRef,
    private model: NgControl,
    private renderer: Renderer2
  ) { }

  @HostListener('keyup') onKeyUp() {
    const newValue = this.removeAccent(this.model.value);

    this.model.control.setValue(newValue);
    this.renderer.setProperty(this.el.nativeElement, 'value', newValue);
  }

  removeAccent(text: string): string {
    if (typeof text === 'string') {
      return text.toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    }
    return text;
  }
}
