import { animate, transition, state, trigger, style } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss'],

  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translateY(0)'
      })),
      state('out', style({
        transform: 'translateY(100%)',
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})
export class LegendComponent implements OnInit {

  @Input() legendToogleStatus;
  @Output() legendToogleStatusEmitter = new EventEmitter();

  legendeItem: Array<Object>;
  liWidth: number;
  legendState: string = 'out';

  constructor() {
    this.legendeItem = [
      { name: 'Disponible', class: 'available' },
      { name: 'Indisponible', class: 'unavailable' },
      { name: 'Partiellement disponible', class: 'achat-partially' },
      { name: 'Achat', class: 'purchase' },
      { name: 'Option', class: 'option' },
      { name: 'Alerte', class: 'alert' },
      { name: 'Module jeu', class: 'icon', text: 'MJ' },
      { name: 'Présence d\'un autre annonceur', class: 'icon', text: 'A' },
      { name: 'Reconduction', class: 'reconduct' },
    ];

    this.liWidth = Math.floor(100 / this.legendeItem.length);
  }

  ngOnInit() { }

  toggleLegend() {
    this.legendState = this.legendState === 'out' ? 'in' : 'out';
    this.legendToogleStatus = !this.legendToogleStatus;
    this.legendToogleStatusEmitter.emit(this.legendToogleStatus);
  }
}
