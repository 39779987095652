import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Product } from '../../resource/product.resource';
import { FilteredItem } from '../../resource/filteredItem.resource';
import { environment } from '../../../environments/environment';

import { UtilitiesHandler } from '@service/utilities-handlers/utilitiesHandlers';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class ProductService {
  private route: string = 'product';

  constructor(
    private http: HttpClient,
    private utilitiesHandler: UtilitiesHandler
  ) {
  }

  /**
   * Get product with ID
   *
   * @param {(string | number)} id
   * @returns {Observable<Product>}
   * @memberof ProductService
   */
  get(id: string | number): Observable<Product> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}/${id}`;

    return this.http
      .get(api_base_url)
      .pipe(
        map((data: any) =>
          new Product(data)
        )
      )
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get list of products
   *
   * @param {Object} [filters=null]
   * @returns {Observable<FilteredItem[]>}
   * @memberof ProductService
   */
  getListForFilter(filters: Object = null): Observable<FilteredItem[]> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params})
      .pipe(
        map((data: any) =>
          data._embedded.product
            .map((jsonProduct: any) =>
              new Product(jsonProduct)
            )
            .map((product: Product) =>
              new FilteredItem(product)
            )
        ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get list for auto complete product for filter
   *
   * @param {Object} [filters=null]
   * @returns {Observable<Object>}
   * @memberof ProductService
   */
  getListForAutoCompleteProduct(filters: Object = null): Observable<any> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params})
      .pipe(
        map((data: any) =>
          data._embedded.product
            .map((jsonProduct: any) =>
              ({
                display: (jsonProduct.name && jsonProduct.id) ? jsonProduct.name + ' (' + jsonProduct.id + ')' : jsonProduct.name,
                value: jsonProduct.id
              })
            )
        ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

}
