import { Base } from './base.resource';

import { Program } from './program.resource';
import { Area } from './area.resource';
import { OfferProgram } from './offerProgram.resource';
import { Channel } from './channel.resource';
import { Purchase } from './purchase.resource';
import { Schedule, JsonSchedule } from './schedule/schedule.resource';

export interface JsonPurchaseProgram {
    id: number;
    offer_program: OfferProgram;
    program: Program;
    area: Area;
    channel: Channel;
    purchase: any;
    schedules?: Schedule[];
    broadcast_count: number;
}

export class PurchaseProgram extends Base {
    public id: number;
    public offerProgram: OfferProgram;
    public program: Program;
    public area: Area;
    public channel: Channel;
    public purchase: Purchase;
    public schedules: Schedule[] = [];
    public broadcastCount: number;

    constructor(jsonPurchaseProgram: JsonPurchaseProgram) {
        super();
        jsonPurchaseProgram = this.convertToUnderscore(jsonPurchaseProgram);

        this.id = jsonPurchaseProgram.id;
        this.broadcastCount = jsonPurchaseProgram.broadcast_count || 0;

        if (jsonPurchaseProgram.offer_program) {
            this.offerProgram = new OfferProgram(this.convertToUnderscore(jsonPurchaseProgram.offer_program));
        }

        if (jsonPurchaseProgram.program) {
            this.program = new Program(jsonPurchaseProgram.program);
        }

        if (jsonPurchaseProgram.area) {
            this.area = new Area(jsonPurchaseProgram.area);
        }

        if (jsonPurchaseProgram.channel) {
            this.channel = new Channel(jsonPurchaseProgram.channel);
        }

        if (jsonPurchaseProgram.purchase) {
            this.purchase = new Purchase(jsonPurchaseProgram.purchase);
        }

        if (jsonPurchaseProgram.schedules) {
          jsonPurchaseProgram.schedules.forEach((schedule: JsonSchedule) => {
            this.schedules.push(new Schedule(schedule));
          });
        }
    }
}
