import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';

import { PurchaseBudgetService } from '@service/purchase-budget/purchase-budget.service';
import { BroadcastPurchasedService } from '@service/broadcast-purchased/broadcast-purchased.service';
import { SchedulePackageService } from '@service/schedule-package/schedule-package.service';
import { PurchaseService } from '@service/purchase/purchase.service';
import { ScheduleService } from '@service/schedule/schedule.service';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';

import { AdvertisingPackage } from '../../../../../../../resource/advertising-package.resource';
import { SchedulePackage } from '../../../../../../../resource/schedule/schedule-package.resource';
import { Schedule } from '../../../../../../../resource/schedule/schedule.resource';
import { PurchaseProgram } from '../../../../../../../resource/purchaseProgram.resource';
import { Channel } from '../../../../../../../resource/channel.resource';

import { distinctUntilChanged, debounceTime, tap } from 'rxjs/operators';

class ScheduleSummary {
  broadcastSummary: string = '';
  periodsSummary: string = '';
  expectSummary: string = '';
}

@Component({
  selector: 'app-advertising-package-details',
  templateUrl: './advertising-package-details.component.html',
  styleUrls: ['./advertising-package-details.component.scss'],
})
export class AdvertisingPackageDetailsComponent implements OnInit, OnChanges {

  @Input() schedule: Schedule;
  @Input() purchaseProgram: PurchaseProgram;

  public scheduleSummary: ScheduleSummary = new ScheduleSummary;
  public form: FormGroup;
  public preGeneric = AdvertisingPackage.PRE_GENERIC;
  public postGeneric = AdvertisingPackage.POST_GENERIC;
  public miniGenericCut = AdvertisingPackage.MINI_GENERIC_CUT;
  public miniGenericRecovery = AdvertisingPackage.MINI_GENERIC_RECOVERY;
  public channelThema = Channel.THEMA_CHANNEL_GROUP;

  constructor(
    private scheduleService: ScheduleService,
    private schedulePackageService: SchedulePackageService,
    private broadcastPurchasedService: BroadcastPurchasedService,
    private purchaseService: PurchaseService,
    private purchaseBudgetService: PurchaseBudgetService,
    private _fb: FormBuilder,
    private customToastrService: CustomToastrService
    ) { }

  ngOnInit() {
    this.getScheduleSummary();
    this.initForm();
    this.populateForm();
    this.form.valueChanges
      .pipe(
        tap(x => {
          this.purchaseBudgetService.updateGlobalBudgetLoading(true);
          this.broadcastPurchasedService.updateLoading(true);
        }),
        debounceTime(2000),
        distinctUntilChanged()
      )
      .subscribe(data => {
        this.schedulePackageService.updatePurchasedSchedulePackage(data)
          .pipe(distinctUntilChanged())
          .subscribe(() => {
            this.broadcastPurchasedService.getBroadcastsPurchased()
              .pipe(distinctUntilChanged())
              .subscribe(() => {
                this.broadcastPurchasedService.updateLoading(false);
                this.customToastrService.displayToastr('SUCCESS', 'Dispositif édité avec succès');
                this.customToastrService.displayToastr('SUCCESS', 'Prix brut mis à jour');
              }, () => {
                this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
              });
            this.purchaseService.getUpdatedPurchase();
          }, () => {
            this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
          })
    });
  }

  ngOnChanges(changes: any): void {
    if (changes.schedule) {
        this.getScheduleSummary();
        this.initForm();
    }
  }

  private initForm() {
    this.form = this._fb.group({
        items: this._fb.array([]),
    });
  }

  private populateForm() {
    this.schedule.schedulePackages.forEach(schedulePackage => {
      this.addItem(schedulePackage);
    });
  }

  private initItem(item: SchedulePackage) {
    return this._fb.group({
        id: item.id,
        quantity: item.quantity,
        duration: item.duration,
        item: item,
        parent: item.parent
    });
  }

  private addItem(item: SchedulePackage) {
    const control = <FormArray>this.form.controls['items'];
    control.push(this.initItem(item));
  }

  private getScheduleSummary() {
    this.scheduleSummary = {
      broadcastSummary: this.scheduleService.generateBroadcastSummary(this.schedule),
      periodsSummary: this.scheduleService.generatePerdiodsSummary(this.schedule),
      expectSummary: this.scheduleService.generateExeptionSumary(this.schedule)
    };
  }

}
