<mat-card>
    <div class="layout horizontal">

        <!-- Card-content -->
        <div class="flex-12">
            <!-- Periods -->
            <div class="layout horizontal margin-top flex" fxLayout="row" fxLayoutGap="25px">
                <div class=" thin" fxLayoutAlign="end center" fxFlex="20">
                    Période
                </div>
                <div fxFlex="auto">
                    {{scheduleSummary?.periodsSummary}}
                </div>
            </div>

            <!-- days + times -->
            <div class="layout horizontal margin-top flex" fxLayout="row" fxLayoutGap="25px">
                <div class="thin" fxLayoutAlign="end center" fxFlex="20">
                    Diffusion
                </div>
                <div fxFlex="auto">
                    {{scheduleSummary?.broadcastSummary}}
                </div>
            </div>

            <!-- expect -->
            <div *ngIf="schedule.hasCompleteExceptData()" class="layout horizontal margin-top flex" fxLayout="row" fxLayoutGap="25px">
                <div class="thin" fxLayoutAlign="end center" fxFlex="20">
                    Sauf
                </div>
                <div fxFlex="auto">
                    {{scheduleSummary?.expectSummary}}
                </div>
            </div>

            <!-- advertising packages -->
            <div class="layout horizontal margin-top flex" fxLayout="row" fxLayoutGap="25px">
                <div class="thin" fxLayoutAlign="end center" fxFlex="20">
                    Dispositif parrainage
                </div>

                <div [formGroup]="form" *ngIf="form" fxFlex="auto">
                    <div formArrayName="items">
                        <div *ngFor="let item of form.controls.items.controls; let i = index">
                            <div [formGroupName]="i" fxLayout="row" fxLayoutGap="5px"
                                *ngIf="!schedule.purchase.soreachPurchase || (schedule.purchase.soreachPurchase && this.purchaseProgram.area.channel.channelGroup === channelThema)
                                    || (item.controls.item.value.advertisingPackage.id === preGeneric || item.controls.item.value.advertisingPackage.id === postGeneric
                                    || item.controls.item.value.advertisingPackage.id === miniGenericCut || item.controls.item.value.advertisingPackage.id === miniGenericRecovery)">
                                <div fxFlex="30">
                                    <span>x</span>
                                    <input matInput formControlName="quantity" min=0 type="number">
                                  <span>-</span>
                                    &nbsp;{{ item.controls.item.value.advertisingPackage.name }}
                                </div>
                                <div>
                                    <span>-</span>
                                    <input matInput formControlName="duration">&nbsp;<span>sec</span>
                                    <span *ngIf="item.controls.item.value.advertisingPackage.id == 6" class="ba-details">&nbsp;par
                                        {{ item.controls.item.value.periodicity.name }} dont {{ item.controls.item.value.primeQuantity }} en prime
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-card>
