import { Schedule } from './schedule.resource';
import { AdvertisingPackage } from '../advertising-package.resource';
import { TrailerPeriodicity } from '../trailerPeriodicity.resource';
import { Base } from '../base.resource';

export interface JsonSchedulePackage {
    id: number;
    quantity: number;
    duration: number;
    prime_quantity: number;
    advertising_package?: any;
    periodicity?: any;
    schedule: any;
    parent?: any;
}

export class SchedulePackage extends Base {
    public id: number;
    public quantity: number;
    public duration: number;
    public primeQuantity: number;
    public advertisingPackage: AdvertisingPackage;
    public periodicity: TrailerPeriodicity;
    public schedule: Schedule;
    public parent: SchedulePackage;
    public price: number;

    constructor(jsonSchedulePackage: JsonSchedulePackage) {
        super();
        jsonSchedulePackage = this.convertToUnderscore(jsonSchedulePackage);

        this.id = jsonSchedulePackage.id;
        this.duration = jsonSchedulePackage.duration;
        this.primeQuantity = jsonSchedulePackage.prime_quantity;
        this.price = 0;

        if (typeof jsonSchedulePackage.quantity === 'boolean') {
            this.quantity = jsonSchedulePackage.quantity  ? 1 : 0;
        } else {
            this.quantity = jsonSchedulePackage.quantity;
        }

        if (jsonSchedulePackage.periodicity) {
            this.periodicity = new TrailerPeriodicity(jsonSchedulePackage.periodicity);
        }

        if (jsonSchedulePackage.schedule) {
            this.schedule = new Schedule(jsonSchedulePackage.schedule);
        }

        if (jsonSchedulePackage.parent) {
            this.parent = new SchedulePackage(jsonSchedulePackage.parent);
        }

        if (jsonSchedulePackage.advertising_package) {
            this.advertisingPackage = new AdvertisingPackage(jsonSchedulePackage.advertising_package);
        }
    }
}
