<mat-card [ngClass]="{'translate': opened}" *ngIf="totalSelectedGrp > 0">
    <mat-card-content>
        <button  *ngIf="opened" (click)="toggleAudienceIndicators()" class="arrow" mat-icon-button>
            <mat-icon>arrow_left</mat-icon>
        </button>
        <button  *ngIf="!opened" (click)="toggleAudienceIndicators()" class="arrow" mat-icon-button>
            <mat-icon>arrow_right</mat-icon>
        </button>
        <mat-icon *ngIf="opened && totalSelectedGrpAlert" class="warning-out warning-color">warning</mat-icon>
        <app-spinner *ngIf="loading" class="spinner-form refresh" type="info" size="small"></app-spinner>
        <div class="margin-b">
            <div *ngIf="totalSelectedGrpAlert">
                <mat-icon class="warning-color">warning</mat-icon>
                <span>GRP partiellement disponibles</span>
            </div>
        </div>
        <table>
            <tr>
                <td>GRP: </td>
                <td class="text-result">{{ totalSelectedGrp }}</td>
            </tr>
            <tr>
                <td>COUVERTURE: </td>
                <td class="text-result" *ngIf="available && totalCouverture; else unavailable">{{ (totalCouverture | number : '1.0-2') }}</td>
            </tr>
            <tr>
                <td>REPETITION: </td>
                <td class="text-result" *ngIf="available && totalCouverture; else unavailable">{{ (totalRepetition | number : '1.0-2') }}</td>
            </tr>
            <tr>
                <td>BUDGET: </td>
                <td class="text-result" *ngIf="available && totalCouverture; else unavailable">{{ (totalBudget | number : '1.0-2') }}</td>
            </tr>
        </table>
    </mat-card-content>
</mat-card>

<ng-template #unavailable>
    <td class="text-result">{{ 'NON DISPONIBLE' }}</td>
</ng-template>