<mat-card>
  <mat-card-content class="offer-program-header">
    <div class="layout horizontal flex">
      <div class="flex-1">
        <img preload fade src="{{ path + logo }}">
      </div>
      <div class="flex-5">
        {{ offerProgramPropal.offerProgram.program.name }}
      </div>
      <div class="flex-1">
        <button (click)="removeOfferProgramPropal()" mat-mini-fab>
          <mat-icon>delete_outline</mat-icon>
        </button>
      </div>
    </div>
    <p>{{ offerProgramPropal.offerProgram.offer.name }}</p>
    <ul class="schedule-summary">
      <li *ngFor="let schedule of scheduleSummary" class="thin">
        {{ schedule.dayLabels }} de {{ schedule.start }} à {{ schedule.end }}
      </li>
    </ul>
  </mat-card-content>

  <mat-card-content>
    <div class="broadcast-list">
      <div class="layout horizontal broadcast-row flex" *ngFor="let broadcast of offerProgramPropal.broadcasts">
        <div class="flex-5 thin">
          {{ broadcast.startTime| momentPipe:'dd'}} {{ broadcast.startTime| momentPipe:'DD/MM/YYYY'}} {{ broadcast.startTimeLabel | broadcastTimeLabel}}
        </div>
        <div class="flex-1 position-icon">
          <mat-icon (click)="removeBroadcast(broadcast)">delete_outline</mat-icon>
        </div>
      </div>
    </div>

    <div fxLayout="column">
      <span class="thin italic" *ngIf="broadcasts.length <= 1">{{ broadcasts.length }} diffusion</span>
      <span class="thin italic" *ngIf="broadcasts.length > 1">{{ broadcasts.length }} diffusions</span>
      <span class="thin italic" *ngIf="min && max"> du {{ min.startTime | momentPipe:'DD/MM/YYYY'}} au {{ max.startTime| momentPipe:'DD/MM/YYYY'}}</span>
    </div>
  </mat-card-content>
</mat-card>