<mat-progress-bar mode="indeterminate" *ngIf="isSaving"></mat-progress-bar>

<h2 mat-dialog-title *ngIf="!lot">Création de Lot :</h2>
<h2 mat-dialog-title *ngIf="lot && !isDuplicate">Modification de Lot :</h2>

<div fxLayout="row" fxLayoutAlign="space-between center" >
  <h2 mat-dialog-title *ngIf="isDuplicate">Duplication de Lot :</h2>
  <mat-form-field *ngIf="isDuplicate">
    <input matInput
          type="number"
          [min]="minDate"
          [max]="minDate + 1"
          [formControl]="year"
          placeholder="Année *">
  </mat-form-field>
</div>

<mat-dialog-content class="full-width">
  <form [formGroup]="newLotDotationForm" class="full-width" fxLayout="column" fxLayoutAlign="center center">

    <!-- product -->
    <div fxLayout="row" fxLayoutGap="25px" class="full-width-input" fxLayoutAlign="start center">
      <span class="span-width thin bold" fxFlex="30">Marque :</span>
      <mat-form-field class="full-width">
        <input formControlName="product" placeholder="Marque"
               matInput maxlength="30" type="text" [matAutocomplete]="productAutocomplete">
        <mat-error>Veuillez sélectionner une marque !</mat-error>
      </mat-form-field>

      <mat-autocomplete #productAutocomplete="matAutocomplete" [displayWith]="autocompleteService.displayAutocomplete.bind(this)">
        <mat-option *ngFor="let product of FilteredProducts | async" [value]="product">
          {{ product.display }} ({{ product.value }})
        </mat-option>
      </mat-autocomplete>
    </div>

    <!-- purchase -->
    <div fxLayout="row" fxLayoutGap="25px" class="full-width-input" fxLayoutAlign="start center">
      <span class="span-width thin bold" fxFlex="30">Contrat :</span>
      <mat-form-field class="full-width">
        <input formControlName="purchase" placeholder="Contrat" matInput [matAutocomplete]="purchaseAutocomplete">
        <mat-error>Veuillez sélectionner un contrat !</mat-error>
      </mat-form-field>
      <mat-autocomplete #purchaseAutocomplete="matAutocomplete" [displayWith]="autocompleteService.displayAutocomplete.bind(this)">
        <mat-option *ngFor="let purchase of FilteredPurchases | async" [value]="purchase">
          {{ purchase.display }}
        </mat-option>
      </mat-autocomplete>
      <button mat-icon-button class="achat-dotation" aria-label="Créer un achat dotation" matTooltip="Créer un achat dotation"
          [disabled]="!!!newLotDotationForm.get('product').value || !!newLotDotationForm.get('purchase').value"
          (click)="openNewDotationPurchaseFormDialog()">
        <mat-icon class="white-text">add</mat-icon>
      </button>
    </div>

    <!-- name -->
    <div fxLayout="row" fxLayoutGap="25px" class="full-width-input" fxLayoutAlign="start center">
      <span class="span-width thin bold" fxFlex="30">Nom :</span>
      <mat-form-field class="full-width">
        <input formControlName="name" placeholder="Nom" matInput appRemoveAccents maxlength="30" type="text">
      </mat-form-field>
    </div>

    <!-- description -->
    <div fxLayout="row" fxLayoutGap="25px" class="full-width-input" fxLayoutAlign="start center">
      <span class="span-width thin bold" fxFlex="30">Description :</span>
      <mat-form-field class="full-width">
        <input formControlName="description" placeholder="Description" matInput appRemoveAccents maxlength="255" type="text">
      </mat-form-field>
    </div>

    <!-- quantity -->
    <div fxLayout="row" fxLayoutGap="25px" class="full-width-input" fxLayoutAlign="start center">
      <span class="span-width thin bold" fxFlex="30">Quantité :</span>
      <mat-form-field class="full-width">
        <input formControlName="quantity" placeholder="Quantité" matInput type="number">
        <mat-error *ngIf="newLotDotationForm.get('quantity').errors && newLotDotationForm.get('quantity').errors.min">
          La quantité doit être supérieure à {{newLotDotationForm.get('quantity').errors.min.min}}.
        </mat-error>
        <mat-error *ngIf="newLotDotationForm.get('quantity').errors && newLotDotationForm.get('quantity').errors.max">
          La quantité doit être inférieur à {{newLotDotationForm.get('quantity').errors.max.max}}.
        </mat-error>
      </mat-form-field>
    </div>

    <!-- unit price -->
    <div fxLayout="row" fxLayoutGap="25px" class="full-width-input" fxLayoutAlign="start center">
      <span class="span-width thin bold" fxFlex="30">Prix unitaire :</span>
      <mat-form-field class="full-width">
        <input formControlName="unitPrice" placeholder="Prix" matInput type="number" class="form-width hold-color">
        <mat-icon class="euro" matSuffix>euro</mat-icon>
      </mat-form-field>
    </div>

    <app-new-lot-dotation-period [newLotDotationForm]="newLotDotationForm" [lot]="lot" [year]="year" [isDuplicate]="isDuplicate" [minDate]="minDate" class="full-width-input"></app-new-lot-dotation-period>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="full-width" fxLayout="row" fxLayoutAlign="end stretch" fxLayoutGap="30px">
  <button mat-flat-button mat-dialog-close class="button-flat black white-text" (click)="cancel()">Annuler</button>
  <button mat-flat-button class="button-flat white-text" [disabled]="isSaving || !newLotDotationForm.valid" (click)="submit()">
    Sauvegarder
  </button>
</mat-dialog-actions>
