import {map,  catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Employee } from '../../resource/employee.resource';
import { FilteredItem } from '../../resource/filteredItem.resource';
import { UtilitiesHandler } from '@service/utilities-handlers/utilitiesHandlers';
import { environment } from '../../../environments/environment';

import { Observable } from 'rxjs';

@Injectable()
export class EmployeeService {
  private route: string = 'employee';

  constructor(
    private http: HttpClient,
    private utilitiesHandler: UtilitiesHandler
  ) { }

  /**
   * Get the employee with the ID
   *
   * @param {(string | number)} id
   * @returns {Observable<Employee>}
   * @memberof EmployeeService
   */
  get(id: string | number): Observable<Employee> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}/${id}`;

    return this.http
      .get(api_base_url).pipe(
      map((jsonEmployee: any) =>
        new Employee(jsonEmployee)
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get the list of employee
   *
   * @param {Object} [filters=null]
   * @returns {Observable<Employee[]>}
   * @memberof EmployeeService
   */
  getList(filters: Object = null): Observable<Employee[]> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params})
      .pipe(
        map((data: any) =>
          data._embedded.employee
          .map((jsonEmployee: any) =>
            new Employee(jsonEmployee)
          )
        )
      )
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get list of Employee for filter
   *
   * @param {Object} [filters=null]
   * @returns {Observable<FilteredItem[]>}
   * @memberof EmployeeService
   */
  getListForFilter(filters: Object = null): Observable<FilteredItem[]> | any {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params})
      .pipe(
        map((data: any) =>
          data._embedded.employee
          .map((jsonEmployee: any) =>
            new Employee(jsonEmployee)
          )
          .map((employee: Employee) =>
            new FilteredItem(employee)
          )
        )
      )
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }
}
