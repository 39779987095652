<mat-card class="program">
    <div class="program-content">
        <mat-card-content class="card-content-dispo" fxLayout="column" fxLayoutGap="5px">
            <div>
                <img *ngIf="logo" preload fade src="{{ path + logo }}" class="logo-channel-card">
                <!--Info bulle-->
                <mat-icon class="information" *ngIf="comments && comments.length > 0" id="info"
                    [matTooltip]="comments.join('\n')"
                    matTooltipClass="styleTooltip">
                    info
                </mat-icon>
            </div>

            <span class="bold size-title">{{ offerProgram.program.name | uppercase }}</span>

            <div class="thin size-sub-title" *ngIf="offerProgram && offerProgram.program && offerProgram.program.category && offerProgram.program.category.name"
              >{{ offerProgram.program.category.name | lowercase | capitalize }}</div>

            <!-- more information -->
            <div fxLayout="row" fxLayoutGap="10px">
                <span class="thin size-text">Nombre de place(s) :</span>
                <span *ngIf="offerProgram && offerProgram.offer && offerProgram.offer.maxNumberOfAdvertiser" class="bold size-text">{{ offerProgram.offer.maxNumberOfAdvertiser }}</span>
            </div>
            <div fxLayout="row" fxLayoutGap="10px">
                <span class="thin size-text">Offre : <span>{{ offerProgram.offer?.name }}</span></span>
            </div>
            <!-- <p>Offre : <a class="offer-link" [routerLink]="['/offers', 'edit', offerProgram.offer.id]" target="_blank">{{ offerProgram.offer.name }}</a></p> -->
        </mat-card-content>
    </div>
</mat-card>
