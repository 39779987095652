import { PropalCartService } from '../../../service/propal-cart/propal-cart.service';
import { GrpService } from '../../../service/grp/grp.service';
import { CustomToastrService } from '../../../service/toastr/custom-toastr.service';

import { OfferProgram } from '../../../resource/offerProgram.resource';
import { Cell } from '../../../resource/availability/cell.resource';

import { Component, Input, OnInit, OnDestroy, ViewChild, ElementRef, ViewEncapsulation, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { Schedule } from '../../../resource/schedule/schedule.resource';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class GridComponent implements OnInit, OnDestroy, OnChanges {

  static totalSelectedGrp: number = 0;
  static totalSelectedCellsWithoutGrp: number = 0;
  static totalSelectedGrpAlert: string = '';

  @Input() offerProgram: OfferProgram;
  @Input() gridData;
  @Input() header;
  @Input() headerMonth;
  @Input() loading;
  @Input() mode: boolean;
  @Input() grpOffer;
  @Input() target: FormControl;
  @Input() broadcastsTimes;
  @Input() schedules: Schedule[];
  @Input() lastProgram: boolean;

  @ViewChild('gridElement') gridElement: ElementRef;

  public weeksCount: number;
  public rowsCount: number;
  public grid: Array<any>;
  public cellWidth: number = 30; // 46 OLD VALUE
  public saving: boolean;
  public informationDisplay: any;
  public index: number = null;

  private newGrpOffer: Array<any> = [];
  private componentDestroyed: Subject<any> = new Subject();

  constructor(
    private propalCartService: PropalCartService,
    private grpService: GrpService,
    private customToastrService: CustomToastrService
  ) {}

  ngOnInit() {
    this.weeksCount = this.gridData.length;
    if (this.mode) {
      this.calculNewGrp();
      this.rowsCount = 1;
      // Init total selected grp data with default values after offer grid init
      GridComponent.initTotalSelectedGrpData();
      this.recalculateTotalSelectedGrp();
    } else {
      this.rowsCount = this.offerProgram.offer.maxNumberOfAdvertiser;
    }
    this.initGrid();

    if (this.target) {
      this.target.valueChanges
        .pipe(takeUntil(this.componentDestroyed))
        .subscribe(data => {
          if (!isNaN(data)) {
            this.calculNewGrp();
            // recalculate total selected grp data on grid offer after change event on GRP Cible field
            this.recalculateTotalSelectedGrp();
          }
      })
    }

    this.propalCartService.currentClickOffer
      .pipe(
        filter((item) =>
          !this.mode && this.offerProgram.offer && this.offerProgram.offer.id &&
            item && item.offerId && this.offerProgram.offer.id === item.offerId),
        takeUntil(this.componentDestroyed)
      )
      .subscribe((data) => {
        this.selectCell(data);
      })

    this.propalCartService.closeGridSource$
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(() => {
        this.leave();
      }, (error) => {
        this.customToastrService.displayToastr('ERROR', 'Erreur destruction panier.');
        return error;
      });

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.recalculateTotalSelectedGrp();
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  /**
   * When the component receive a click for parent (offer)
   *
   * @param {*} data
   * @returns {void}
   * @memberof GridComponent
   */
  public selectCell(data): void {
    this.gridData[data.index].forEach((element, index) => {
      if (element.type === 'available') {
        if (data.cell.isCompletelyChecked) {
          if (element.broadcasts.find((broadcast) => broadcast.broadcastType === 0)
            && (!element.isCompletelyChecked || element.isPartiallyChecked)) {
            this.propalCartService.addCell(element, this.offerProgram);
            this.gridData[data.index][index].isCompletelyChecked = true;
          }
        } else if (!data.cell.isCompletelyChecked) {
          if (element.isCompletelyChecked || element.isPartiallyChecked) {
            this.propalCartService.removeCell(element, this.offerProgram);
            this.gridData[data.index][index].isCompletelyChecked = false;
            this.gridData[data.index][index].isPartiallyChecked = false;
          }
        }
      }
    });
  }

  /**
   * Init selected grp data (totalSelectedGrp, totalSelectedCellsWithoutGrp, totalSelectedGrpAlert)
   * with default values
   *
   * @returns {void}
   * @memberof GridComponent
   */
  static initTotalSelectedGrpData(): void {
    GridComponent.totalSelectedGrp = 0;
    GridComponent.totalSelectedCellsWithoutGrp = 0;
    GridComponent.totalSelectedGrpAlert = '';
  }

  /**
   * Recalculate total selected grp data (totalSelectedGrp, totalSelectedCellsWithoutGrp, totalSelectedGrpAlert)
   * for a grid offer when event change on GRP cible
   *
   * @returns {void}
   * @memberof GridComponent
   */
  public recalculateTotalSelectedGrp(): void {
    if (this.mode) {
      let dictionary: Array<number> = [];
      let totalGrp: number = 0;
      let duration: number = 0;
      let alert: boolean = false;

      this.gridData.forEach((element, index) => {
        if (element[0].isCompletelyChecked) {
          dictionary.push(index);
        }
      });
      if (this.newGrpOffer.length) {
        let firstValue: number;

        dictionary.forEach((data, index) => {
          if (index === 0) {
            firstValue = data;
            duration++;
          } else {
            if ((firstValue + 1) === data) {
              firstValue = data;
              duration++;
            } else {
              alert = true;
            }
          }
          totalGrp += Number(this.newGrpOffer[data].GRPA);
        })
      }

      this.grpService.displayTotalSelectedGrp({
        total: totalGrp ? totalGrp : (dictionary.length ? -1 : 0),
        alert: alert ? 'audiance unavailable' : '',
        id: this.offerProgram.id,
        startWeek: dictionary.length ? Math.min(...dictionary) : 0,
        duration: duration
      });
    }
  }

/**
 * Initiate Grid
 *
 * @returns {void}
 * @memberof GridComponent
 */
public initGrid(): void {
    this.grid = [];
    for (let row = 0; row < this.rowsCount; row++ ) {
       this.addRow();
    }
  }

  /**
   * Add one row
   *
   * @returns {void}
   * @memberof GridComponent
   */
  public addRow(): void {
    this.grid.push(new Array(this.weeksCount).fill(0));
  }

  /**
   * When the user click on cell for select or unselect
   *
   * @param {Cell} cell
   * @param {number} index
   * @returns {void}
   * @memberof GridComponent
   */
  public clickOnCell(cell: Cell, index: number): void {
    if (this.loading) {
       this.customToastrService.displayToastr('WARNING', 'Chargement non terminé');
       return null;
    }

    if (this.mode) {
      let data = {
        offerId: this.offerProgram.id,
        index: index,
        cell: cell
      };

      switch (cell.type) {
        case Cell.AVAILABLE:
        case Cell.PARTALLY_AVAILABLE:
          if (cell.isCompletelyChecked || cell.isPartiallyChecked) {
            cell.isCompletelyChecked = false;
            cell.isPartiallyChecked = false;
          } else {
            cell.isCompletelyChecked = true;
          }
          this.propalCartService.clickOnOffer(data);
          break;
        default:
          break;
      }
    } else {
      switch (cell.type) {
        case Cell.AVAILABLE:
          if (cell.isCompletelyChecked || cell.isPartiallyChecked) {
            this.propalCartService.removeCell(cell, this.offerProgram);
            cell.isCompletelyChecked = false;
            cell.isPartiallyChecked = false;
          } else if (cell.broadcasts.find((broadcast) => broadcast.broadcastType === 0)) {
            this.propalCartService.addCell(cell, this.offerProgram);
            cell.isCompletelyChecked = true;
          }
          break;
        default:
          break;
      }
    }
  }

  /**
   * Feed with Information cardGrid
   *
   * @param {*} information
   * @param {number} index
   * @returns {void}
   * @memberof GridComponent
   */
  enter(information, index: number): void {
    this.informationDisplay = information;
    this.index = index;
  }

  /**
   * Reset Information cardGrid
   *
   * @returns {void}
   * @memberof GridComponent
   */
  leave(): void {
    this.informationDisplay = null;
    this.index = null;
  }

  /**
   * Calculate the new grp for header
   * calculate depend of week
   * @returns void
   */
  private calculNewGrp(): void {
    this.newGrpOffer = [];
    if (this.grpOffer && this.target && this.target.value) {
      let tmpGrpOffer = [];
      let startWeek = (this.grpOffer.startWeek === 53) ? this.grpOffer.startWeek - 1 : this.grpOffer.startWeek;
      let week = this.grpOffer.week - 1;
      let findItem;

      this.grpOffer.offer.forEach(element => {
        if (element.CODCIBLE === this.target.value) {
          tmpGrpOffer.push(element);
        }
      });

      if (this.header[0] === 'S52' && this.header.length === 53 && this.headerMonth) {
        startWeek = 1;
      }
      if (tmpGrpOffer.length) {
        for (let i = startWeek; i <= (week + startWeek); i++) {
          if (i === 1 && this.header[0] === 'S52' && this.header.length === 53 && this.headerMonth) {
            this.newGrpOffer.push([]);
          }
          findItem = tmpGrpOffer.find(item => {
            return item.DTD === i.toString();
          });
          if (findItem) {
            this.newGrpOffer.push(findItem);
          } else {
            this.newGrpOffer.push([]);
          }
        }
      }
    }
  }
}
