<div class="container-type">
  <div class="full-width display">
      <mat-form-field class="full-width" floatLabel="never">
          <mat-label class="label">Chercher</mat-label>
          <input matInput [formControl]="searchControl"/>
      </mat-form-field>
      <button
              *ngIf="searchControl.value"
              aria-label="Clear"
              class="clear-input-icon-button"
              (click)="clearSearchInput()">
              <mat-icon matSuffix>close</mat-icon>
          </button>
  </div>
  <div class="container-route-name" *ngIf="goBack">
      <mat-icon (click)="goBackParent()"  matPrefix class="icon-back">arrow_circle_left</mat-icon>
      <span class="route-name">{{this.routeName}}</span>
  </div>

  <div class="full-width list-container-wrapper">
      <div class="list-container">
          <mat-list>
              <mat-list-item *ngFor="let event of this.eventSelectedDisplayList">
                  <div class="type-line full-width">
                      <button class="select-action" (click)="selectEvent(event, index)">
                          <mat-icon *ngIf="!event?.isSelected" matPrefix class="small-icon-add" >add_circle</mat-icon>
                          <mat-icon *ngIf="event?.isSelected" matPrefix class="small-icon-check" >check_circle</mat-icon>
                      </button>
                      <p class="type-name">{{event?.libevt}}</p>
                      <mat-icon *ngIf="event?.children?.length > 0" (click)="showChildren(event)" matSuffix class="small-icon-arrow">arrow_right</mat-icon>
                  </div>  
              </mat-list-item>
          </mat-list>
      </div>
      <div class="action-container">
          <button class="button-flat white-text" (click)="submit()">Valider</button>
      </div>
  </div>
</div>