import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'dashboard.component.html',
  styleUrls: ['dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  urlPowerBi: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {
    this.urlPowerBi = this.sanitizer.bypassSecurityTrustResourceUrl(environment.power_bi);
  }

  ngOnInit() {
  }

}
