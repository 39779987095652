import { Cell } from './cell.resource';
import { OfferProgram } from '../offerProgram.resource';
import { Broadcast } from '../broadcast.resource';
import { OfferProgramPropalCart } from './offer-program-propal-cart.resource';

import * as moment from 'moment';

const STATE_IN           = 'in';
const STATE_OUT          = 'out';
const STATE_NOT_CREATED  = 'notCreated';

export class PropalCart {

  public static STATE_IN          = STATE_IN;
  public static STATE_OUT         = STATE_OUT;
  public static STATE_NOT_CREATED = STATE_NOT_CREATED;

  public offerPrograms: OfferProgramPropalCart[];

  constructor() {
    this.offerPrograms = [];
  }

  public addCell(cell: Cell, offerProgram: OfferProgram) {
    if (!this.hasOfferProgram(offerProgram)) {
      this.addOfferProgram(offerProgram);
    }

    let offerProgramPropalCart = this.getOfferProgramPropalCart(offerProgram);
    offerProgramPropalCart.addBroadcasts(cell.broadcasts);
  }

  public removeCell(cell: Cell, offerProgram: OfferProgram) {
    let offerProgramPropalCart = this.getOfferProgramPropalCart(offerProgram);
    if (offerProgramPropalCart) {
      offerProgramPropalCart.removeBroadcasts(cell.broadcasts);
    }
  }

  public addOfferProgram(offerProgram: OfferProgram) {
    this.offerPrograms.push(new OfferProgramPropalCart(offerProgram, this));
  }

  public removeBroadcast(broadcast: Broadcast, offerProgram: OfferProgram) {
    let offerProgramPropalCart = this.getOfferProgramPropalCart(offerProgram);
    offerProgramPropalCart.removeBroadcast(broadcast);
  }

  public removeOfferProgramPropal(offerProgramPropal: OfferProgramPropalCart) {
    let newArray = [];
    this.offerPrograms.forEach(item => {
      if (item.offerProgram.id !== offerProgramPropal.offerProgram.id) {
        newArray.push(item);
      }
    });

    this.offerPrograms = newArray;
  }

  public hasOfferProgram(offerProgram: OfferProgram): boolean {
    let offerProgramMatch = this.offerPrograms.filter(item => item.offerProgram.id === offerProgram.id)
    return offerProgramMatch.length > 0;
  }

  public getOfferProgramPropalCart(offerProgram): OfferProgramPropalCart {
    let result = null;
    this.offerPrograms.forEach((item, index) => {
      if (item.offerProgram.id === offerProgram.id) {
        result = index;
      }
    });

    return this.offerPrograms[result];
  }

  public getAllBroadcasts(): Broadcast[] {
    let result = [];
    this.offerPrograms.forEach(offerProgramPropal => {
      result = result.concat(offerProgramPropal.broadcasts);
    });
    return result;
  }

  public getMinBroadcastDate() {
    let allBroadcasts = this.getAllBroadcasts();

    if (!allBroadcasts.length) {
      return null;
    }

    allBroadcasts.sort((a, b) =>
        moment(a.startTime, 'YYYY-MM-DDTHH:mm:ss').dayOfYear() - moment(b.startTime, 'YYYY-MM-DDTHH:mm:ss').dayOfYear());
    return allBroadcasts.shift().startTime;
  }

  public getMaxBroadcastDate() {
    let allBroadcasts = this.getAllBroadcasts();

    if (!allBroadcasts.length) {
      return null;
    }

    allBroadcasts.sort((a, b) =>
        moment(a.startTime, 'YYYY-MM-DDTHH:mm:ss').dayOfYear() - moment(b.startTime, 'YYYY-MM-DDTHH:mm:ss').dayOfYear());
    return allBroadcasts.pop().startTime;
  }
}
