import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";

@Component({
  selector: "app-event-dialog",
  templateUrl: "./event-dialog.component.html",
  styleUrls: ["./event-dialog.component.scss"],
})
export class EventDialogComponent implements OnInit, OnDestroy {
  componentDestroyed$: Subject<void> = new Subject();
  searchControl: FormControl;
  goBack = false;
  eventListSource = [];
  eventSelectedDisplayList = [];
  eventSelectedRoute = [];
  routeName = "";
  routeNameList = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<EventDialogComponent>
  ) {
    this.searchControl = new FormControl(null, [
      Validators.minLength(2),
      Validators.maxLength(40),
    ]);
  }

  ngOnInit() {
    this.eventListSource = this.data.eventList;
    this.eventSelectedDisplayList = this.data.eventList;
    this.search();
    this.closeByClickingOutside();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.unsubscribe();
  }

  /**
   * Display children event list
   * @param type
   */
  showChildren(type): void {
    this.eventSelectedDisplayList = type.children;

    // push children libevt to create and generate routename
    this.routeNameList.push(type.libevt);
    this.generateRouteName(this.routeNameList);

    // we push children display list to know the path back to display
    this.eventSelectedRoute.push(this.eventSelectedDisplayList);

    this.goBack = true;
  }

  goBackParent(): void {
    this.searchControl.setValue("", { emitEvent: false });
    for (let key of Object.keys(this.eventSelectedRoute)) {
      if (this.eventSelectedRoute.length > 1) {
        this.eventSelectedDisplayList =
          this.eventSelectedRoute[Number(key) - 1];
      } else {
        this.eventSelectedDisplayList = this.eventListSource;
        this.goBack = false;
        this.routeName = "";
      }
    }
    //delete last element of array
    this.eventSelectedRoute.pop();
    this.routeNameList.pop();
    this.generateRouteName(this.routeNameList);
  }

  generateRouteName(routeNameList): void {
    this.routeName = routeNameList.join("/ ");
  }

  selectEvent(type): void {
    if (!type.isSelected) {
      type.isSelected = true;
      this.data.events.selectedEvent.push(type);
      this.data.events.eventToInsert.push(type);
    } else {
      type.isSelected = false;
      let isNotSelected = this.data.events.selectedEvent.findIndex(
        (e) => e.codevt === type.codevt
      );
      this.data.events.selectedEvent.splice(isNotSelected, 1);
      this.data.events.eventToRemove.push(type);
    }
  }

  submit(): void {
    this.dialogRef.close(this.data.events);
  }

  clearSearchInput(): void {
    this.searchControl.setValue("", { emitEvent: false });
    this.goBack = false;
    this.routeName = "";
    this.eventSelectedRoute = [];
    this.eventSelectedDisplayList = this.eventListSource;
  }

  closeByClickingOutside() {
    this.dialogRef.backdropClick().subscribe(() => {
      this.dialogRef.close(this.data.events);
    });
  }

  search(): void {
    this.searchControl.valueChanges
      .pipe(debounceTime(500), takeUntil(this.componentDestroyed$))
      .subscribe((data: string) => {
        this.eventSelectedDisplayList = [];
        this.deepSearch(this.eventListSource, data.toUpperCase());
      });
  }

  /**
   * search correspondence inside parent/children/grandchildren
   * @param arr
   * @param target
   * @returns
   */
  deepSearch(arr, target) {
    for (let obj of arr) {
      for (let key in obj) {
        if (key === "libevt" && obj[key].includes(target)) {
          this.eventSelectedDisplayList.push(obj);
        } else {
          if (typeof obj[key] === "object") {
            this.deepSearch(obj[key], target);
          }
        }
      }
    }
    return null;
  }
}
