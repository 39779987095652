import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '../../../../environments/environment';
import { UtilitiesHandler } from '@service/utilities-handlers/utilitiesHandlers';

import { Observable, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import * as fileServer from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class OfferProgramGridSynthesisService {
  private route: string = 'offer_synthetic';
  private downloadRoute: string = 'generate_offer_synthetic';

  private loadingSynthesis = new Subject<void>();
  public loadingSynthesis$ = this.loadingSynthesis.asObservable();

  private orderSynthesis = new Subject<string>();
  public orderSynthesis$ = this.orderSynthesis.asObservable();

  private filterSynthesis = new Subject<void>();
  public filterSynthesis$ = this.filterSynthesis.asObservable();

  private viewChangeToogle = new Subject<boolean>();
  public viewChangeToogle$ = this.viewChangeToogle.asObservable();

  private headers: HttpHeaders;

  constructor(private http: HttpClient,
  private utilitiesHandler: UtilitiesHandler) {
      this.headers = new HttpHeaders({});
      this.headers.append('Accept', 'application/x-www-form-urlencoded');
      this.headers.append('Content-Type', 'application/x-www-form-urlencoded');
  }

  /**
   * Get all offer for synthesis
   *
   * @return {Observable<any>}
   */
  public get(year: string | number): Observable<any> {
    let offset = 0;
    let max_items = 250;
    const api_base_url: string = `${environment.api_base_url}/${this.route}?offset=${offset}&max_items=${max_items}&year=${year}`;

    return this.http
      .get(api_base_url)
      .pipe(
        map((data: any) =>
          data._embedded.offer_synthetic
            .map((jsonSynthesis: any) => {
              return jsonSynthesis;
            })
        ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

   /**
   * Get all offer for synthesis
   *
   * @return {Observable<any>}
   */
  public download(year: string | number, extraParams?: any): Observable<any> {
    let api_base_url: string = `${environment.api_base_url}/${this.downloadRoute}?year=${year}`;

    if (extraParams['channels'] != null) {
      api_base_url += `&channels=${extraParams['channels']}`;
    }

    if (extraParams['programsCategories'] != null) {
      api_base_url += `&programs_categories=${extraParams['programsCategories']}`;
    }

    if (extraParams['areas'] != null) {
      api_base_url += `&regional_areas=${extraParams['areas']}`;
    }

    if (extraParams['offerName'] != null) {
      api_base_url += `&offer_name=${extraParams['offerName']}`;
    }

    if (extraParams['mode'] != null) {
      api_base_url += `&mode=${extraParams['mode']}`;
    }

    const fileName = 'offer_synthetic.xlsx';
    this.headers.set('Accept', 'application/vnd.ms-excel, */*');

    return this.http.get(api_base_url, { headers: this.headers, responseType: 'blob'})
      .pipe(
        map((response: any) => {
          const blob = new Blob([response], { type: 'application/vnd.ms-excel, */*'});
          fileServer.saveAs(blob, fileName);
        })
      )
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      );
  }

  /**
   * Event for search Synthesis
   */
  public searchSynthesis(): void {
    this.loadingSynthesis.next();
  }

  /**
   * Event for order asc/desc search Synthesis
   */
  public orderAscDescSynthesis(order: string): void {
    this.orderSynthesis.next(order);
  }

  /**
   * Event for search Synthesis
   */
  public filterSynthesisGrid(): void {
    this.filterSynthesis.next();
  }

  /**
   * Event to change view Advertiser / Product for synthesis grid
   * @param check {boolean}
   * @return {void}
   */
  public changeViewAdvertiserProduct(check: boolean): void {
    this.viewChangeToogle.next(check);
  }
}
