import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { OfferProgram } from '../../resource/offerProgram.resource';
import { environment } from '../../../environments/environment';
import { UtilitiesHandler } from '../utilities-handlers/utilitiesHandlers';

import { Observable, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Channel } from 'src/app/resource/channel.resource';
import { Area } from 'src/app/resource/area.resource';

@Injectable()
export class OfferBudgetService {
  private tvClassicBudgetSource = new Subject<number>();
  private tvThemaBudgetSource = new Subject<number>();
  private budgetTypeSource = new Subject<string>();

  offerProgramBudgets: Array<any>;
  f3RegionAndF4Budget: number = 0;

  tvClassicBudget$ = this.tvClassicBudgetSource.asObservable();
  tvThemaBudget$ = this.tvThemaBudgetSource.asObservable();
  budgetType$ = this.budgetTypeSource.asObservable();

  constructor(
    private http: HttpClient,
    private utilitiesHandler: UtilitiesHandler) {
    this.offerProgramBudgets = [];
    this.tvClassicBudgetSource.next(0);
    this.tvThemaBudgetSource.next(0);
  }

  /**
   * Add offer programs budget
   *
   * @param {*} offerProgram
   * @param {*} year
   * @memberof OfferBudgetService
   */
  addOfferProgramsBudget(offerProgram, year): void {
    const offerProgramBudgets = offerProgram.offerProgramBudgets[0];
    offerProgramBudgets.offerProgram['status'] = offerProgram.status;
    let index = this.offerProgramBudgets.push(offerProgramBudgets);
    let isClassic = false;
    let isNotCgrp = false;

    if (offerProgram.area && offerProgram.area.channel) {
        isClassic = this.isClassic(offerProgram.area.channel, year);
        isNotCgrp = this.isNotCgrp(offerProgram.area.channel, offerProgram.area)
    }
    this.offerProgramBudgets[index - 1].isClassic = isClassic;
    this.offerProgramBudgets[index - 1].isNotCgrp = isNotCgrp;
    this.recalculateBudget();
  }

  /**
   * Edit the variable offerProgramBudgets for status with the id
   *
   * @param status {number}
   * @param offerProgram {offerProgram}
   */
  editGlobalOfferProgramBudget(status: number, offerProgram: OfferProgram): void {
    this.offerProgramBudgets.forEach((offerProgramBudget) => {
      if (offerProgramBudget.offerProgram.id === offerProgram.id.toString()) {
        offerProgramBudget.offerProgram.status = status;
      }
    });
    this.recalculateBudget();
  }

  /**
   * Edit offer program budget
   *
   * @param {*} offerProgramBudget
   * @memberof OfferBudgetService
   */
  editOfferProgramBudget(offerProgramBudget): void {
    this.offerProgramBudgets.forEach(elem => {
      if (elem.id === offerProgramBudget.id) {
        elem.price = offerProgramBudget.price;
      }
    });
    this.recalculateBudget();
  }

  /**
   * Reset offer program budget
   *
   * @returns {void}
   * @memberof OfferBudgetService
   */
  resetOfferProgramBudget(): void {
    if (this.offerProgramBudgets.length > 0) {
        this.offerProgramBudgets = [];
    }
  }

  /**
   * Check if it's Classic
   *
   * @param channel 
   * @param year 
   * @returns 
   */
  isClassic(channel: Channel, year: number): boolean {
    let channelGroup: string;

    (channel.id === 'O' && year > 2018) ? channelGroup = 'W' : channelGroup = channel.channelGroup;

    return (channelGroup === 'F') || (channelGroup === 'O') ? true : false;
  }

   /**
   * Check if it's F3 region or F4 to exclude from CGRP calcul
   *
   * @param channel 
   * @param area 
   * @returns 
   */
  isNotCgrp(channel: Channel, area: Area): boolean {

    return (channel.channelGroup === 'F') && (channel.id === '4') || (area.id !== 'NAT') ? true : false;
  }

  /**
   * Data change (event)
   *
   * @param {number} tvThemaBudget
   * @param {number} tvClassicBudget
   * @memberof OfferBudgetService
   */
  onDataChange(tvThemaBudget: number, tvClassicBudget: number): void {
    this.tvClassicBudgetSource.next(tvClassicBudget);
    this.tvThemaBudgetSource.next(tvThemaBudget);
  }

  /**
   * Set budget type (event)
   *
   * @param {string} budgetType
   * @memberof OfferBudgetService
   */
  setBudgetType(budgetType: string): void {
    this.budgetTypeSource.next(budgetType);
  }

  /**
   * Delete Indice periode with ID
   *
   * @param {number} id
   * @returns {Observable<any>}
   * @memberof OfferBudgetService
   */
  deleteIndicePeriod(id: number): Observable<any> {
    const api_base_url = `${environment.api_base_url}/offer_indice_period/${id}`;

    return this.http
      .delete(api_base_url)
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      );
  }

  /**
   * Recalculate budget
   *
   * @private
   * @memberof OfferBudgetService
   */
  private recalculateBudget(): void {
    this.resetValues();

    let tvClassic: number = 0;
    let tvThema: number = 0;
    this.f3RegionAndF4Budget = 0;

    this.offerProgramBudgets.forEach((offerProgramBudget) => {
      if (offerProgramBudget && offerProgramBudget.offerProgram && offerProgramBudget.offerProgram.status) {
        if (offerProgramBudget.isClassic === true) {
          tvClassic += Number(offerProgramBudget.price);
        } else {
          tvThema += Number(offerProgramBudget.price);
        }
        if (offerProgramBudget.isNotCgrp) {
          this.f3RegionAndF4Budget += Number(offerProgramBudget.price);
        }
      }
    });

    this.onDataChange(tvThema, tvClassic);
  }

  /**
   * Reset values
   *
   * @private
   * @memberof OfferBudgetService
   */
  private resetValues(): void {
    this.onDataChange(0, 0);
  }
}
