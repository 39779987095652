import { Component, ElementRef, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { GridService } from '../../../service/grid.service';
import { OfferProgramService } from '@service/offer-program/offer-program.service';
import { SoReachService } from '@service/so-reach/so-reach.service';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';

import { SoReach } from '../../../../../resource/so-reach.resource';
import { PurchaseParams } from '../../../../../resource/purchaseType.resource';
import { ValidationDialogComponent } from '../../../../../shared/dialog/validation-dialog/validation-dialog.component';

import { Observable, Subject, of } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import * as moment from 'moment';

@Component({
  selector: 'app-soreach-dispo-filters',
  templateUrl: './soreach-dispo-filters.component.html',
  styleUrls: ['./soreach-dispo-filters.component.scss']
})
export class SoreachDispoFiltersComponent implements OnInit, OnDestroy {
  public loading = true;

  soReachDateCtrl: FormControl = new FormControl();
  soReachTypeCtrl: FormControl = new FormControl();

  filteredSoReachTypes: Observable<any[]>;
  allSoReachTypes = [];
  filterYear: number = null;

  isEmptyCard: boolean = true;

  private typeSoReach;
  private dateSoReach;
  private componentDestroyed$: Subject<any> = new Subject();

  @ViewChild('soReachTypeInput') soReachTypeInput: ElementRef;
  @ViewChild('soReachDateInput') soReachDateInput: ElementRef;
  @Input('purchaseParams') purchaseParams: PurchaseParams;

  constructor(
    private customToastrService: CustomToastrService,
    private soReachService: SoReachService,
    private offerProgramService: OfferProgramService,
    private gridService: GridService,
    public dialog: MatDialog) {
    this.soReachDateCtrl.disable();
  }

  ngOnInit() {
    this.soReachService.getSoReach()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((soReachTypes: SoReach[]) => {
        this.allSoReachTypes = soReachTypes.map((soReachType: SoReach) => {
         return {'id': soReachType.id, 'name': soReachType.name, 'year': soReachType.year};
        });
        this.loading = false;
        this.soReachDateCtrl.enable();
        this.initFiltersIfRedirectedFromPurchase();
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
        this.loading = false;
        this.soReachDateCtrl.enable();
      });

    this.gridService.isEmptyCardSoReach$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((isEmpty: boolean) => {
        this.isEmptyCard = isEmpty;
      }, (error) => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.soReachTypeCtrl.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((soReachTypeName) => {
        if (moment(this.soReachDateCtrl.value).isValid()) {
          this.filterSoReachType(soReachTypeName);
        } else {
          this.filteredSoReachTypes = of();
          this.customToastrService.displayToastr('WARNING', 'Vous devez sélectionnez une date valide avant de choisir le type SoReach!')
        }
      }, (error) => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.soReachDateCtrl.valueChanges
      .pipe(
        debounceTime(500),
        takeUntil(this.componentDestroyed$)
      ).subscribe((selectedDate) => {
        if (!moment(selectedDate).isValid()) {
          if (!this.soReachTypeCtrl.value === null) {
            this.soReachTypeCtrl.setValue(null);
          }
          this.filteredSoReachTypes = of();
        } else if (moment(selectedDate).toDate().getFullYear() !== this.filterYear) {
          this.filterYear = moment(selectedDate).toDate().getFullYear();
          if (!this.soReachTypeCtrl.value === null) {
            this.soReachTypeCtrl.setValue(null);
          }
          this.filteredSoReachTypes = of(this.allSoReachTypes.filter(soReachType => this.filterYear === parseInt(soReachType.year, 10)));
        }
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.unsubscribe();
  }

  /**
   * Filter so reach types autocomplete list
   *
   * @param soReachTypeName string
   */
  filterSoReachType(soReachTypeName): void {
    const year = moment(this.soReachDateCtrl.value).year();
    soReachTypeName = soReachTypeName ? (typeof soReachTypeName === 'string' ? soReachTypeName : soReachTypeName.name) : '';

    this.filteredSoReachTypes = of(this.allSoReachTypes.filter(
      soReachType => year === parseInt(soReachType.year, 10) && soReachType.name.toUpperCase().includes(soReachTypeName.toUpperCase())
    ));
  }

  /**
   * Get soReach offer programs grid availability
   *
   * @return {void}
   */
  getSoReachOfferProgramsGrid(): void {
    this.loading = true;
    if (!moment(this.soReachDateCtrl.value).isValid() || !this.soReachTypeCtrl.value
        || !Number.isInteger(parseInt(this.soReachTypeCtrl.value.id, 10))) {
      this.customToastrService.displayToastr(
  'WARNING', 'Vous devez sélectionnez une date valide et un SoReach avant de lancer la recherche!'
      );
      this.loading = false;
      return;
    }

    if (!this.isEmptyCard && this.typeSoReach && this.soReachTypeCtrl.value && this.typeSoReach.id !== this.soReachTypeCtrl.value.id) {
      this.openDialogValidation();
    } else {
      this.getSoReachOfferPrograms();
    }
  }

  /**
   * Open dialog for validation to clear card
   */
  openDialogValidation(): void {
    const dialogRef = this.dialog.open(ValidationDialogComponent, {
      data: {
        title: 'Attention votre panier sera vidé !',
        subTitle: 'Voulez-vous continuer ?'
      }
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((result: boolean) => {
        if (result) {
          this.gridService.clearSoReach();
          this.getSoReachOfferPrograms();
        } else {
          this.soReachDateCtrl.setValue(this.dateSoReach);
          this.soReachTypeCtrl.setValue(this.typeSoReach);
          this.loading = false;
        }
    });
  }

  /**
   * Get so Reach after validation
   */
  getSoReachOfferPrograms(): void {
    const startDate = moment(this.soReachDateCtrl.value).startOf('week').format('YYYY-MM-DD');
    const endDate = moment(this.soReachDateCtrl.value).endOf('week').format('YYYY-MM-DD');
    const soreachOfferProgramsFilters = {
      'soreach_id': parseInt(this.soReachTypeCtrl.value.id, 10),
      'start_date': startDate,
      'end_date': endDate
    };

    this.offerProgramService.getSoReachOfferPrograms(soreachOfferProgramsFilters)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(soReachOfferPrograms => {
        this.gridService.loadingSelectedYear(moment(this.soReachDateCtrl.value).isoWeekYear());
        this.gridService.loadingWeekNumber(moment(startDate).isoWeek());
        this.gridService.loadingSoReachOfferProgramsGrid(soReachOfferPrograms);
        this.typeSoReach = this.soReachTypeCtrl.value;
        this.dateSoReach = this.soReachDateCtrl.value;

        if (this.soReachTypeCtrl.value && this.soReachTypeCtrl.value.id && this.soReachTypeCtrl.value.name) {
          this.gridService.sendSelectedSoreachToPropalCart(this.soReachTypeCtrl.value);
        }
        this.loading = false;
      }, () => {
        this.loading = false;
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
        this.gridService.loadingSoReachOfferProgramsGrid();
      });
  }

  /**
   * Return channel name if given or empty string else
   *
   * @param soreach any
   * @return string
   */
  public displayChannelName(soreach: any): string {
    return soreach ? (typeof soreach === 'string' ? soreach : soreach.name) : '';
  }

  /**
   * If it have been redirected to soreach availability from Purchase,
   * then set date and soreach filters to purchase start date and soreach type
   */
  initFiltersIfRedirectedFromPurchase() {
    if (this.purchaseParams && this.purchaseParams.soReachPurchase) {
      this.soReachDateCtrl.setValue(moment(this.purchaseParams.startDate).format('YYYY-MM-DD'));
      this.allSoReachTypes.forEach(element => {
        if (this.purchaseParams.soReachPurchase === Number(element.id)) {
          this.soReachTypeCtrl.setValue({'id': element.id, 'name': element.name, 'year': element.year});
          this.soReachTypeCtrl.disable();
        }
      });
      this.getSoReachOfferProgramsGrid();
    }
  }
}
