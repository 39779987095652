<mat-card class="card" fxLayout="column">
  <div fxLayout="row">
    <mat-card-title fxFlex>{{ offerProgramPropal.name }}</mat-card-title>
    <button mat-mini-fab (click)="removeOfferProgramFromF3AreaCart()">
      <mat-icon>delete_outline</mat-icon>
    </button>
  </div>
  <mat-card-subtitle>{{ offerProgramPropal.region }}</mat-card-subtitle>
  <ul class="schedule-summary" fxLayout="row">
    <li *ngFor="let schedule of scheduleSummary" class="thin">
      {{ schedule.dayLabels }} de {{ schedule.start }} à {{ schedule.end }}
    </li>
  </ul>
  <mat-card-content fxLayout="column" fxFlex class="content-area">
    <ng-container *ngFor="let broadcast of offerProgramPropal.broadcasts">
      <div fxLayout="row">
        <span fxFlex="25" class="font-thin">{{ broadcast.startTime | momentPipe:'dd' }}</span>
        <span fxFlex class="font-thin">{{ broadcast.startTime | momentPipe:'DD/MM/YYYY' }}</span>
        <span fxFlex="25" class="font-thin">{{ broadcast.startTimeLabel | broadcastTimeLabel }}</span>
      </div>
    </ng-container>
  </mat-card-content>
  <mat-card-footer fxLayout="column" class="footer-area">
    <span class="thin italic" *ngIf="offerProgramPropal.broadcasts.length <= 1">{{ offerProgramPropal.broadcasts.length }} diffusion</span>
    <span class="thin italic" *ngIf="offerProgramPropal.broadcasts.length > 1">{{ offerProgramPropal.broadcasts.length }} diffusions</span>
    <span class="thin italic" *ngIf="min && max"> du {{ min.startTime | momentPipe:'DD/MM/YYYY'}} au {{ max.startTime| momentPipe:'DD/MM/YYYY'}}</span>
  </mat-card-footer>
</mat-card>
