import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { PurchaseService } from '../../../service/purchase/purchase.service';
import { Purchase } from '../../../resource/purchase.resource';
import { PurchaseType } from '../../../resource/purchaseType.resource';
import { PurchaseTypeService } from '../../../service/purchase-type/purchase-type.service';
import * as moment from 'moment';
import { Title } from '@angular/platform-browser';
import 'moment/locale/fr';

@Component({
  selector: 'app-purchase-list',
  templateUrl: './purchase-list.component.html',
  styleUrls: ['./purchase-list.component.scss']
})
export class PurchaseListComponent implements OnInit {

  public loading: boolean = false;
  public filters: any;
  public purchases: number = 0;

  purchases$: Observable<Purchase[]>;

  constructor(
      private purchaseService: PurchaseService,
      private titleService: Title
  ) { }

  ngOnInit() {
    this.titleService.setTitle(`Dossiers - recherche`);
  }

  getFilter($event) {
    this.filters = $event;
  }

  getPurchase() {
    this.loading = true;
    let purchaseSource = this.purchaseService.getList(this.filters);

    this.purchases$ = purchaseSource;
    purchaseSource.subscribe(
        response => {
            this.purchases = response.length;
            this.loading = false;
        }
    );
  }

}
