import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';

import { OfferProgram } from '../../resource/offerProgram.resource';
import { Broadcast } from '../../resource/broadcast.resource';
import { Schedule } from '../../resource/schedule/schedule.resource';
import { environment } from '../../../environments/environment';

import { CustomToastrService } from '@service/toastr/custom-toastr.service';
import { UtilitiesHandler } from '@service/utilities-handlers/utilitiesHandlers';
import { AbstractService } from '../abstract.service';

import { Observable, of, Subject } from 'rxjs';
import { map, tap,  catchError } from 'rxjs/operators';

import * as moment from 'moment';

const MAX_SIZE = 100;

@Injectable()
export class BroadcastService extends AbstractService {
  private route: string = 'broadcast';
  private counts = [];
  private pageCount = [];
  private pageNumber = [];
  private broadcasts: Broadcast[];
  private headers: HttpHeaders;

  private resetLoaderSource = new Subject<void>();
  public resetLoaderSource$ = this.resetLoaderSource.asObservable();

  constructor(
    private customToastrService: CustomToastrService,
    private utilitiesHandler: UtilitiesHandler,
    private http: HttpClient
  ) {
    super();
  }

  /**
    * Check if there's some broadcasts in IdList which we cannot delete
    *
    * @param {Array<number>} idList
    * @returns {Observable<any>}
    * @memberof BroadcastService
  */
  public getBroadcastListImpossibleToDelete(idList: Array<number>): Observable<any> {
    if (!idList.length) {
      return of([]);
    }

    const api_base_url = `${environment.api_base_url}/check_if_can_delete_broadcast`;
    const data: Object = {
      broadcast_ids: idList.join(';')
    };

    return this.http
      .post(api_base_url, data)
      .pipe(
        map((response: any[]) => response.map(id => Number(id))),
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get list of Broadcast (ex: Synthese des diffusions)
   *
   * @param {Object} filters
   * @returns {Observable<Broadcast[]>}
   * @memberof BroadcastService
   */
  getList(filters: Object): Observable<Broadcast[]> {
    const query = JSON.stringify(filters);
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params})
      .pipe(
        tap((x: any) => {this.pageCount[query] = x.page_count}),
        map((data: any) =>
          data._embedded.broadcast
            .map((json: any) =>
              new Broadcast(json)
            )
        ),
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Delete from the list of Broadcast (ex: Synthese des diffusions)
   *
   * @param {Broadcast[]} broadcasts
   * @returns {Observable<Object>}
   * @memberof BroadcastService
   */
  deleteList(broadcasts: Broadcast[]): Observable<Object> {
    const api_base_url = `${environment.api_base_url}/${this.route}`;

    return this.http
      .request('delete', api_base_url,
        {
          headers: this.headers,
          body: {ids: broadcasts.map(broadcast => broadcast.id)}
        })
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Count the result of Broadcast
   *
   * @param {*} filters
   * @returns {Observable<number>}
   * @memberof BroadcastService
   */
  count(filters: any): Observable<number> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params}).pipe(
      map((data: any) =>
        data._embedded.broadcast
          .map((jsonArray: any) =>
            <number>jsonArray.total_items
          )
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Patch list
   *
   * @param {Broadcast[]} broadcasts
   * @returns {Observable<Object>}
   * @memberof BroadcastService
   */
  patchList(broadcasts: Broadcast[]): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;

    return this.http
      .patch(api_base_url, this.extractCollectionForPatch(broadcasts))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Post a Broadcast
   *
   * @param {Broadcast} broadcast
   * @returns {Observable<Object>}
   * @memberof BroadcastService
   */
  post(broadcast: Broadcast): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;

    return this.http
      .post(api_base_url, this.extract(broadcast))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Post collection of Broadcasts
   *
   * @param {Broadcast[]} broadcasts
   * @returns {Observable<Object>}
   * @memberof BroadcastService
   */
  postCollection(broadcasts: Broadcast[]): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;

    return this.http
      .post(api_base_url, this.extractCollection(broadcasts))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Edit collection of Broadcasts // TODO MORE TEST HERE
   *
   * @param {Broadcast[]} broadcasts
   * @returns {Observable<Object>}
   * @memberof BroadcastService
   */
  editCollection(broadcasts: Broadcast[]): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;

    return this.http
      .put(api_base_url, this.extractCollection(broadcasts))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Remove a broadcast with ID // TODO NEED MORE TEST
   *
   * @param {Broadcast} broadcast
   * @returns {Observable<Object>}
   * @memberof BroadcastService
   */
  remove(broadcast: Broadcast): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}/${broadcast.id}`;

    return this.http
      .delete(api_base_url)
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get all the complete list of Broadcast
   *
   * @param {*} filters
   * @returns {Observable<Response>}
   * @memberof BroadcastService
   */
  getCompleteList(filters: any): Observable<any> {
    return Observable.create(observer => {
      this.initPagination(filters);
      this.getList(filters)
        .subscribe(broadcasts => {
          this.broadcasts = broadcasts;
          this.paginateResult(filters)
            .subscribe(() => {
              observer.next(this.broadcasts);
            }, (error) => {
              this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            });
        }, (error) => {
          this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
        })
      });
  }

  /**
   * Post paginated of collection
   *
   * @param {Broadcast[]} broadcasts
   * @returns {Observable<HttpResponse<any>>}
   * @memberof BroadcastService
   */
  postPaginatedCollection(broadcasts: Broadcast[]): Observable<HttpResponse<any>> {
    return Observable.create(observer => {
      if (broadcasts.length > MAX_SIZE) {
        let array = this.getSplitedArray(broadcasts);
        let count = 0;
        array.forEach(item => {
          this.postCollection(item).subscribe(() => {
            if (this.checkActionCount(array.length, ++count)) {
              observer.next();
            }
          }, (error) => {
            this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
          })
        })
      } else {
        this.postCollection(broadcasts)
          .subscribe(result => {
            observer.next(result);
        }, (error) => {
          this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
        })
      }
    })
  }

  /**
   * Delete paginated Collection
   *
   * @param {Broadcast[]} broadcasts
   * @returns {Observable<HttpResponse<any>>}
   * @memberof BroadcastService
   */
  deletePaginatedCollection(broadcasts: Broadcast[]): Observable<HttpResponse<any>> {
    return Observable.create(observer => {
      if (broadcasts.length > MAX_SIZE) {
        let array = this.getSplitedArray(broadcasts);
        let count = 0;
        array.forEach(item => {
          this.deleteList(item)
            .subscribe(() => {
              if (this.checkActionCount(array.length, ++count)) {
                observer.next();
              }
            }, () => {
              this.customToastrService.displayToastr(
                'ERROR', 'Une ou plusieurs diffusions sont déjà présentes dans un dossier en cours de saisi, alerte ou option.');
              this.resetLoaderSource.next();
            })
        })
      } else {
          return this.deleteList(broadcasts)
            .subscribe(result => {
              observer.next(result);
            }, () => {
              this.customToastrService.displayToastr(
                'ERROR', 'Une ou plusieurs diffusions sont déjà présentes dans un dossier en cours de saisi, alerte ou option.');
              this.resetLoaderSource.next();
            })
      }
    })
  }

  /**
   * Patch broadcasts collection
   *
   * @param {Broadcast[]} broadcasts
   * @returns {Observable<any>}
   * @memberof BroadcastService
   */
  public patchBroadcastsCollection(broadcasts: Broadcast[]): Observable<any> {
    return Observable.create(observer => {
      if (broadcasts.length > MAX_SIZE) {
        let array = this.getSplitedArray(broadcasts);
        let count = 0;
        array.forEach(item => {
          this.patchList(item)
            .subscribe(() => {
              if (this.checkActionCount(array.length, ++count)) {
                observer.next();
              }
            }, (error) => {
              this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            })
        })
      } else {
        return this.patchList(broadcasts)
          .subscribe(result => {
            observer.next(result);
          }, (error) => {
            this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
          })
      }
    })
  }

  /**
   * Manage Broadcasts
   *
   * @param {Broadcast[]} broadcasts
   * @param {Schedule} schedule
   * @param {OfferProgram} offerProgram
   * @returns {Observable<HttpResponse<any>>}
   * @memberof BroadcastService
   */
  manageBroadcasts(broadcasts: Broadcast[], schedule: Schedule, offerProgram: OfferProgram): Observable<HttpResponse<any>> {
    return Observable.create(observer => {
      if (!broadcasts.length) {
        observer.next();
      }

      let totalActions = 0;
      let actionCount = 0;
      let broadcastToAdd = [];
      let broadcastToEdit = [];
      let broadcastToDelete = [];
      let broadcastToPatch = [];

      broadcasts.forEach((broadcast: Broadcast) => {

        broadcast.program = offerProgram.program;

        switch (broadcast.action) {
          case Broadcast.NEW:
            broadcast.addSchedule(schedule);
            broadcastToAdd.push(broadcast);
            break;
          case Broadcast.LINK:
            broadcast.addSchedule(schedule);
            broadcast.addOfferProgram(offerProgram);
            broadcastToEdit.push(broadcast);
            break;
          case Broadcast.UNLINK:
            broadcast.removeSchedule(schedule);
            broadcast.removeOfferProgram(offerProgram);
            broadcastToEdit.push(broadcast);
            break;
          case Broadcast.EDIT:
            broadcast.addSchedule(schedule);
            broadcastToEdit.push(broadcast);
            break;
          case Broadcast.DELETE:
            broadcastToDelete.push(broadcast);
            break;
          case Broadcast.PATCH:
            broadcastToPatch.push(broadcast);
            break;
        }
      });

      if (!broadcastToAdd.length && !broadcastToEdit.length && !broadcastToDelete.length) {
        observer.next();
      }

      if (broadcastToAdd.length) {
        totalActions++;
        this.postPaginatedCollection(broadcastToAdd)
          .subscribe(() => {
            if (this.checkActionCount(totalActions, ++actionCount)) {
              observer.next();
            }
          }, (error) => {
            this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
          });
      }

      if (broadcastToEdit.length) {
        totalActions++;
        this.editCollection(broadcastToEdit)
          .subscribe(() => {
            if (this.checkActionCount(totalActions, ++actionCount)) {
              observer.next();
            }
          }, (error) => {
            this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
          });
      }

      if (broadcastToDelete.length) {
        totalActions++;
        this.deletePaginatedCollection(broadcastToDelete).subscribe(() => {
          if (this.checkActionCount(totalActions, ++actionCount)) {
            observer.next();
          }
        });
      }

      if (broadcastToPatch.length) {
        totalActions++;
        this.patchBroadcastsCollection(broadcastToPatch)
          .subscribe(() => {
            if (this.checkActionCount(totalActions, ++actionCount)) {
              observer.next();
            }
          }, (error) => {
            this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
          });
      }
    });
  }

  /**
   * Check if it's need to be edited
   *
   * @param {Broadcast} newBroadcast
   * @param {Broadcast} broadcast
   * @returns {boolean}
   * @memberof BroadcastService
   */
  needToBeEdited(newBroadcast: Broadcast, broadcast: Broadcast): boolean {
    return this.hasSameDayOfYear(newBroadcast, broadcast) && this.hasDifferentHour(newBroadcast, broadcast) ? true : false;
  }

  /**
   * Check if it's need to be keeped
   *
   * @param {Broadcast} newBroadcast
   * @param {Broadcast} broadcast
   * @returns {boolean}
   * @memberof BroadcastService
   */
  needToBeKeeped(newBroadcast: Broadcast, broadcast: Broadcast): boolean {
    return this.hasSameDates(newBroadcast, broadcast) && broadcast.id ? true : false;
  }

  /**
   * Check if it's have same days of year
   *
   * @param {Broadcast} newBroadcast
   * @param {Broadcast} broadcast
   * @returns {boolean}
   * @memberof BroadcastService
   */
  hasSameDayOfYear(newBroadcast: Broadcast, broadcast: Broadcast): boolean {
    return moment(newBroadcast.startTime).dayOfYear() === moment(broadcast.startTime).dayOfYear() &&
      moment(newBroadcast.endTime).dayOfYear() === moment(broadcast.endTime).dayOfYear() ? true : false;
  }

  /**
   * Check if it's have different hour
   *
   * @param {Broadcast} newBroadcast
   * @param {Broadcast} broadcast
   * @returns {boolean}
   * @memberof BroadcastService
   */
  hasDifferentHour(newBroadcast: Broadcast, broadcast: Broadcast): boolean {
   return newBroadcast.startTimeLabel !== broadcast.startTimeLabel ||
      newBroadcast.endTimeLabel !== broadcast.endTimeLabel ? true : false;
  }

  /**
   * Check if it's the same
   *
   * @param {Broadcast} newBroadcast
   * @param {Broadcast} broadcast
   * @returns {boolean}
   * @memberof BroadcastService
   */
  hasSameDates(newBroadcast: Broadcast, broadcast: Broadcast): boolean {
    return moment(newBroadcast.startTime).format() === moment(broadcast.startTime).format() &&
      moment(newBroadcast.endTime).format() === moment(broadcast.endTime).format() &&
      newBroadcast.startTimeLabel === broadcast.startTimeLabel &&
      newBroadcast.endTimeLabel === broadcast.endTimeLabel ?  true : false;
  }

  public extractForPatch(broadcast: Broadcast) {
    return {
      id: broadcast.id,
      broadcast_type: broadcast.broadcastType
    };
  }

  /**
   * Build new Object
   *
   * @param {Broadcast} broadcast
   * @param {boolean} [withId=false]
   * @returns {Object}
   * @memberof BroadcastService
   */
  extract(broadcast: Broadcast, withId: boolean = false): Object {
    return {
      id: withId ? broadcast.id : null,
      program: broadcast.program.id,
      start_time: broadcast.startTime,
      end_time: broadcast.endTime,
      start_time_label: broadcast.startTimeLabel,
      end_time_label: broadcast.endTimeLabel,
      offer_programs: broadcast.getOfferProgramIds(),
      schedules : broadcast.getScheduleIds(),
      area: {
        id : broadcast.area.id,
        channelId: broadcast.area.channel.id
      }
    };
  }

  /**
   * Extract collection for patch
   *
   * @param {*} array
   * @returns {Array<any>}
   * @memberof BroadcastService
   */
  extractCollectionForPatch(array): Array<any> {
    let result = [];

    array.forEach(item => {
      result.push(this.extractForPatch(item));
    });

    return result;
  }

  /**
   * Extract collection
   *
   * @param {Array<any>} array
   * @returns {Array<any>}
   * @memberof BroadcastService
   */
  extractCollection(array: Array<any>): Array<any> {
    let result = [];

    array.forEach(item => {
      result.push(this.extract(item, true));
    });

    return result;
  }

  /**
   * Get the time from Broadcasts
   *
   * @param {Broadcast[]} broadcasts
   * @returns {Object}
   * @memberof BroadcastService
   */
  getTimesFromBroadcasts(broadcasts: Broadcast[]): Object {
    if (!broadcasts && broadcasts.length) {
      return null;
    }

    let startTime: string = null;
    let endTime: string = null;

    if (broadcasts[0] && broadcasts[0].startTime && broadcasts[0].endTime) {
      startTime = broadcasts[0].startTime;
      endTime = broadcasts[0].endTime;

      broadcasts.forEach(broadcast => {
        if (moment(broadcast.startTime).unix() < moment(startTime).unix()) {
          startTime = broadcast.startTime;
        }

        if (moment(broadcast.endTime).unix() > moment(endTime).unix() ) {
          endTime = broadcast.endTime;
        }
      });
    } else if (broadcasts[0] && broadcasts[0]['date']) {
      startTime = broadcasts[0]['date'];
      endTime = broadcasts[0]['date'];

      broadcasts.forEach((broadcast) => {
        if (moment(broadcast['date'], 'DD/MM/YYYY').isAfter(moment(endTime, 'DD/MM/YYYY'))) {
          endTime = moment(broadcast['date'], 'DD/MM/YYYY').format('L');
        }
        if (moment(broadcast['date'], 'DD/MM/YYYY').isBefore(moment(startTime, 'DD/MM/YYYY'))) {
          startTime = moment(broadcast['date'], 'DD/MM/YYYY').format('L');
        }
      });

      startTime = moment(startTime, 'DD/MM/YYYY').format('YYYY-MM-DD');
      endTime = moment(endTime, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }

    return {
      startTime: startTime,
      endTime: endTime
    };
  }

  /**
   * Change broadcast date, update schedule periods and excpetions of related schedule and recompute related purchased broadcasts price
   *
   * @param {Object} data
   * @returns {Observable<any>}
   * @memberof BroadcastService
   */
  changeBroadcastDate(data: Object) {
    const api_base_url = `${environment.api_base_url}/change_broadcast_date`;

    return this.http
      .post(api_base_url, data)
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Pagination the result
   *
   * @private
   * @param {*} filters
   * @returns {any}
   * @memberof BroadcastService
   */
  private paginateResult(filters): any {
    return Observable.create(observer => {
      let query = JSON.stringify(filters);

      if (this.pageCount[query] <= 1) {
        observer.next();
      }
      this.counts[query] = 1;

      while (this.pageNumber[query] < this.pageCount[query]) {
        this.pageNumber[query]++;
        filters.page = this.pageNumber[query];
        this.getList(filters)
          .subscribe(broadcasts => {
            this.counts[query]++;
            this.broadcasts = this.broadcasts.concat(broadcasts);
            if (this.checkActionCount(this.pageCount[query], this.counts[query])) {
              observer.next();
            }
          }, (error) => {
            this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
          })
      }
    })
  }

  /**
   * Init pagination
   *
   * @private
   * @param {*} filters
   * @returns {void}
   * @memberof BroadcastService
   */
  private initPagination(filters): void {
    let query = JSON.stringify(filters);
    this.pageCount[query] = 0;
    this.pageNumber[query] = 1;
    this.broadcasts = [];
  }

  /**
   * Check if it's equal
   *
   * @private
   * @param {*} totalActions
   * @param {*} actionCount
   * @returns {boolean}
   * @memberof BroadcastService
   */
  private checkActionCount(totalActions, actionCount): boolean {
    return totalActions === actionCount;
  }

  /**
   * Splice array
   *
   * @private
   * @param {*} array
   * @returns {Array<any>}
   * @memberof BroadcastService
   */
  private getSplitedArray(array): Array<any> {
    let result = [];
    while (array.length > 0) {
      result.push(array.splice(0, MAX_SIZE));
    }

    return result;
  }
}
