import { PropalCart } from './propal-cart.resource';
import { OfferProgram } from '../offerProgram.resource';
import { Broadcast } from '../broadcast.resource';

export class OfferProgramPropalCart {

  // offerProgram
  public offerProgram: OfferProgram;

  // data
  public broadcasts: Broadcast[];

  // parent
  public propal: PropalCart;

  constructor(offerProgram: OfferProgram, propalCart: PropalCart) {
    this.offerProgram = offerProgram;
    this.broadcasts = [];
    this.propal = propalCart;
  }

  public addBroadcasts(broadcasts: Broadcast[]) {
    broadcasts.forEach(broadcast => {
      this.addBroadcast(broadcast);
    });
  }

  public addBroadcast(broadcast: Broadcast) {
    if (!broadcast.broadcastType && !this.broadcasts.find(item => item.id === broadcast.id)) {
      this.broadcasts.push(broadcast);
    }
  }

  public removeBroadcasts(broadcasts: Broadcast[]) {
    broadcasts.forEach(broadcast => {
      this.removeBroadcast(broadcast);
    })
  }

  public removeBroadcast(broadcast: Broadcast) {
    let newArray = [];
    this.broadcasts.forEach(item => {
      if (item.id !== broadcast.id) {
        newArray.push(item);
      }
    });

    this.broadcasts = newArray;
  }
}
