import { Broadcast } from '../../resource/broadcast.resource';
import { Cell } from '../../resource/availability/cell.resource';
import { OfferProgramPropalCart } from '../../resource/availability/offer-program-propal-cart.resource';
import { OfferProgram } from '../../resource/offerProgram.resource';
import { PropalCart } from '../../resource/availability/propal-cart.resource';
import { Injectable } from '@angular/core';
import { Observable ,  BehaviorSubject ,  Subject } from 'rxjs';

import * as moment from 'moment';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';

@Injectable()
export class PropalCartService {

  public propalCart: PropalCart;

  private propalCartSource = new Subject<PropalCart>();
  public propalCartSource$ = this.propalCartSource.asObservable();

  private deleteOfferProgramFromF3AreaCartSource = new Subject<number>();
  public deleteProgramCartSource$ = this.deleteOfferProgramFromF3AreaCartSource.asObservable();

  private propalCartAreaSource = new Subject<any>();
  public propalCartAreaSource$ = this.propalCartAreaSource.asObservable();

  private offerProgramPropalSource = new Subject<OfferProgramPropalCart>();
  public offerProgramPropalSource$ = this.offerProgramPropalSource.asObservable();

  private tooglePropalSource = new Subject<string>();
  public tooglePropalSource$ = this.tooglePropalSource.asObservable();

  private resetGridSource = new Subject<boolean>();
  public resetGridSource$ = this.resetGridSource.asObservable();

  private closeGridSource = new Subject<void>();
  public closeGridSource$ = this.closeGridSource.asObservable();

  private clickOfferSource = new BehaviorSubject(null);
  public currentClickOffer = this.clickOfferSource.asObservable();

  private days = ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'];

  constructor(public customToastrService: CustomToastrService) {
    this.propalCartSource.next(this.propalCart);
  }

  /**
   * Mouse leave grid (event)
   *
   * @memberof PropalCartService
   */
  public mouseLeaveGrid(): void {
    this.closeGridSource.next();
  }

  /**
   * Click on offer (event)
   *
   * @param {*} data
   * @memberof PropalCartService
   */
  public clickOnOffer(data): void {
    this.clickOfferSource.next(data);
  }

  /**
   * Init propal cart
   *
   * @returns {PropalCart}
   * @memberof PropalCartService
   */
  public initPropalCart(): PropalCart {
    if (!this.propalCart) {
      this.propalCart = new PropalCart();
    }

    if (this.getOfferProgramLength()) {
      this.tooglePropalSource.next(PropalCart.STATE_OUT);
    }

    return this.propalCart;
  }

  /**
   * Reset propal cart
   *
   * @memberof PropalCartService
   */
  public resetPropalCart(): void {
    this.propalCart = new PropalCart();
    this.emitPropalCart();
    this.tooglePropalSource.next(PropalCart.STATE_NOT_CREATED);
    this.resetGridSource.next(true);
  }

  /**
   * Add cell
   *
   * @param {Cell} cell
   * @param {OfferProgram} offerProgram
   * @returns {void}
   * @memberof PropalCartService
   */
  public addCell(cell: Cell, offerProgram: OfferProgram): void {
    let count = this.propalCart.offerPrograms.length;

    this.propalCart.addCell(cell, offerProgram);
    this.emitOfferProgramPropalCart(offerProgram);
    this.emitPropalCart();

    if (this.getOfferProgramLength() === 1 && count === 0) {
      this.tooglePropalSource.next(PropalCart.STATE_OUT);
    }
  }

  /**
   * Remove cell
   *
   * @param {Cell} cell
   * @param {OfferProgram} offerProgram
   * @returns {void}
   * @memberof PropalCartService
   */
  public removeCell(cell: Cell, offerProgram: OfferProgram): void {
    this.propalCart.removeCell(cell, offerProgram);
    this.postRemove(offerProgram);
  }

  /**
   * Remove broadcast
   *
   * @param {Broadcast} broadcast
   * @param {OfferProgramPropalCart} offerProgramPropal
   * @returns {void}
   * @memberof PropalCartService
   */
  public removeBroadcast(broadcast: Broadcast, offerProgramPropal: OfferProgramPropalCart): void {
    let offerProgram = offerProgramPropal.offerProgram;
    this.propalCart.removeBroadcast(broadcast, offerProgram);
    this.postRemove(offerProgram);
  }

  /**
   * Remove offer program propal
   *
   * @param {OfferProgramPropalCart} offerProgramPropal
   * @returns {void}
   * @memberof PropalCartService
   */
  public removeOfferProgramPropal(offerProgramPropal: OfferProgramPropalCart): void {
    offerProgramPropal.broadcasts = [];
    this.offerProgramPropalSource.next(offerProgramPropal);
    this.propalCart.removeOfferProgramPropal(offerProgramPropal);
    this.emitPropalCart();

    if (!this.getOfferProgramLength()) {
      this.resetPropalCart();
    }
  }

  /**
   * Update grid
   *
   * @param {OfferProgramPropalCart} offerProgramPropal
   * @returns {void}
   * @memberof PropalCartService
   */
  public updateGrid(offerProgramPropal: OfferProgramPropalCart): void {
    this.offerProgramPropalSource.next(offerProgramPropal);
  }

  /**
   * Emit id to delete offer program from area cart
   *
   * @param id {number}
   */
  public deleteOfferProgramFromF3AreaCart(id: number): void {
    this.deleteOfferProgramFromF3AreaCartSource.next(id);
  }

  /**
   * Get offer program length
   *
   * @returns {*}
   * @memberof PropalCartService
   */
  public getOfferProgramLength(): any {
    return this.propalCart
      ? this.propalCart.offerPrograms.length
      : 0;
  }

  /**
   * Get schedule summary
   *
   * @param {Broadcast[]} broadcasts
   * @returns {Observable<any>}
   * @memberof PropalCartService
   */
  public getScheduleSummary(broadcasts: Broadcast[]): Observable<any> {
    return Observable.create(observer => {
        this.aggregateBroadcast(broadcasts).subscribe(result => {
          let scheduleSummary = [];
          Object.keys(result).forEach(key => {
            let hours = key.split('-');
            scheduleSummary.push({
              dayLabels: this.getDaySummary(result[key]),
              start: hours[0],
              end: hours[1]
            });

            observer.next(scheduleSummary);
          });
      }, (error) => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });
    });
  }

  /**
   * Emit the selected offerProgram to F3 Area cart
   *
   * @returns {void}
   */
  public emitF3AreaPropalCart(data): void {
    this.propalCartAreaSource.next(data);
  }

  /**
   * Get broadcast Label
   *
   * @private
   * @param {*} value
   * @returns {string}
   * @memberof PropalCartService
   */
  private getBroadcastLabel(value): string {
    let hour = value.substr(0, 2);
    let minute = value.substr(2, 2);

    return hour + ':' + minute;
  }

  /**
   * Aggregate broadcast
   *
   * @private
   * @param {Broadcast[]} broadcasts
   * @returns {Observable<any>}
   * @memberof PropalCartService
   */
  private aggregateBroadcast(broadcasts: Broadcast[]): Observable<any> {
    return Observable.create(observer => {
      let result = {};
      broadcasts.forEach(broadcast => {
        let startTime = this.getBroadcastLabel(broadcast.startTimeLabel);
        let endTime = this.getBroadcastLabel(broadcast.endTimeLabel);
        let index = startTime + '-' + endTime;
        let dayOfWeek = moment(broadcast.startTime, 'YYYY-MM-DD').weekday();

        if (!result[index]) {
          result[index] = [];
        }

        if (result[index].indexOf(dayOfWeek) === -1) {
          result[index].push(dayOfWeek);
        }
      });

      observer.next(result);
    })
  }

  /**
   * Get day summary
   *
   * @private
   * @param {*} days
   * @returns {(string | any)}
   * @memberof PropalCartService
   */
  private getDaySummary(days): string | any {
    days.sort();
    let splited = false;
    for (let i = 0; i < days.length; i++) {
      if (i !== 0 && days[i - 1] !== days[i] - 1) {
        splited = true;
      }
    }

    let dayLabels = [];
    days.forEach(day => {
      dayLabels.push(this.days[day]);
    })

    if (dayLabels.length === 1) {
      return dayLabels[0];
    }

    return splited
      ? dayLabels.join('-')
      : dayLabels[0] + ' au ' + dayLabels[dayLabels.length - 1];
  }

  /**
   * Emit offer prgram propal cart
   *
   * @private
   * @param {OfferProgram} offerProgram
   * @return {void}
   * @memberof PropalCartService
   */
  private emitOfferProgramPropalCart(offerProgram: OfferProgram): void {
    let offerProgramPropalCart = this.propalCart.getOfferProgramPropalCart(offerProgram);

    if (offerProgramPropalCart) {
      this.offerProgramPropalSource.next(offerProgramPropalCart);
    }
  }

  /**
   * Emit propal cart
   *
   * @private
   * @returns {void}
   * @memberof PropalCartService
   */
  private emitPropalCart(): void {
    this.propalCartSource.next(this.propalCart);
  }

  /**
   * Post remove
   *
   * @private
   * @param {OfferProgram} offerProgram
   * @returns {void}
   * @memberof PropalCartService
   */
  private postRemove(offerProgram: OfferProgram): void {
    let offerProgramPropalCart = this.propalCart.getOfferProgramPropalCart(offerProgram);
    if (offerProgramPropalCart) {
      this.updateGrid(offerProgramPropalCart);

      if (!offerProgramPropalCart.broadcasts.length) {
        this.removeOfferProgramPropal(offerProgramPropalCart);
      }

      this.emitPropalCart();
    }
  }
}
