import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";

import { GridExtendsComponent } from "./class/grid-extends.component";
import { SpinnerComponent } from "./spinner/spinner.component";
import { InputFilterExtendsComponent } from "./class/input-filter-extends/input-filter-extends.component";

import { MatDatepickerModule, MatDateFormats } from "@angular/material";
import { MatListModule } from "@angular/material/list";
import {
  MAT_DATE_LOCALE,
  DateAdapter,
  MAT_DATE_FORMATS,
} from "@angular/material";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatRadioModule } from "@angular/material/radio";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatDividerModule } from "@angular/material/divider";
import { MatCheckboxModule } from "@angular/material/checkbox";

import { AddSignataireDialogComponent } from "./dialog/add-signataire-dialog/add-signataire-dialog.component";
import { AddFunctionDialogComponent } from "./dialog/add-function-dialog/add-function-dialog.component";
import { SoReachDialogComponent } from "./dialog/so-reach-dialog/so-reach-dialog.component";
import { SoReachPeriodBudgetComponent } from "./dialog/so-reach-dialog/so-reach-period-budget/so-reach-period-budget.component";
import { ValidationDialogComponent } from "./dialog/validation-dialog/validation-dialog.component";
import { ExportConducteurComponent } from "./dialog/export-conducteur/export-conducteur.component";

export const FRENCH_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "DD/MM/YYYY",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatDividerModule,
    MatCheckboxModule,
    MatListModule,
  ],
  declarations: [
    SpinnerComponent,
    InputFilterExtendsComponent,
    GridExtendsComponent,
    AddSignataireDialogComponent,
    AddFunctionDialogComponent,
    SoReachDialogComponent,
    SoReachPeriodBudgetComponent,
    ValidationDialogComponent,
    ExportConducteurComponent,
  ],
  exports: [
    SpinnerComponent,
    InputFilterExtendsComponent,
    GridExtendsComponent,
    AddSignataireDialogComponent,
    AddFunctionDialogComponent,
    SoReachDialogComponent,
    SoReachPeriodBudgetComponent,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "fr-FR" },
    {
      provide: MAT_DATE_FORMATS,
      useValue: FRENCH_DATE_FORMATS,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: DateAdapter, useClass: MomentDateAdapter },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
