import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

import { UtilitiesHandler } from '@service/utilities-handlers/utilitiesHandlers';
import { AbstractService } from '@service/abstract.service';

import { JsonRegionSynthesis, RegionSynthesis } from '../../../resource/synthesis-region';

import { Observable, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RegionGridService extends AbstractService {
  private route: string = 'region_availability';

  private regionSynthesis = new Subject<RegionSynthesis[]>();
  public regionSynthesis$ = this.regionSynthesis.asObservable();

  constructor(
    private http: HttpClient,
    private utilitiesHandler: UtilitiesHandler) {
    super();
  }

  /**
   * Get all offer for synthesis
   *
   * @return {Observable<JsonRegionSynthesis[]>}
   */
  public get(filters): Observable<JsonRegionSynthesis[]> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params})
      .pipe(
        map((data: any) =>
          data._embedded.region_availability
        ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Event to send region data
   *
   * @param dataRegion {RegionSynthesis[]}
   */
  public filterRegionSynthesis(dataRegion: RegionSynthesis[]): void {
    this.regionSynthesis.next(dataRegion);
  }
}
