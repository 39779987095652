
<h2>Définir les abattements</h2>
<small class="thin sub-title">Annonceur :  {{ purchase.advertiser?.name || purchase.temporaryAdvertiser }}</small>

<mat-dialog-content>
  <div class="flex">
    <div class="flex-3">
      <mat-card>
        <mat-card-title>
          Sélectionnez les abattements à appliquer
        </mat-card-title>
        <mat-card-content>
          <mat-progress-bar
            mode="indeterminate"
            color="accent"
            *ngIf="loadingAbatement">
          </mat-progress-bar>

          <div style="max-height: 342px!important;overflow-y: scroll!important;border-radius: 6px 6px 0 0;">
            <table mat-table [dataSource]="dataSource">
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Abattements</th>
                <td mat-cell *matCellDef="let abatement" class="thin">{{ abatement.name }}</td>
              </ng-container>

              <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef>Code</th>
                <td mat-cell *matCellDef="let abatement" class="thin">{{ abatement.code }}</td>
              </ng-container>

              <ng-container matColumnDef="rate">
                <th mat-header-cell *matHeaderCellDef>Taux</th>
                <td mat-cell *matCellDef="let abatement" class="thin">{{ abatement.rate }}</td>
              </ng-container>

              <ng-container matColumnDef="isFixedLabel">
                <th mat-header-cell *matHeaderCellDef>Fixe</th>
                <td mat-cell *matCellDef="let abatement" class="thin">{{ abatement.isFixedLabel }}</td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="addAbatement(row, group)" [ngClass]="{'selected': row.isSelected}"></tr>
            </table>
          </div>

        </mat-card-content>
      </mat-card>
    </div>
    <div class="flex-2">
      <mat-card>
        <mat-card-title>Cascade tarifaire</mat-card-title>

        <div [formGroup]="form" *ngIf="form">
          <div class="cascade-units">
            <ng-container [ngSwitch]="true">
                <!-- Tv Classic -->
                <div *ngSwitchCase="this.group === 'F'">
                    <app-cascade-unit
                      [form]="form"
                      [total]="purchase.tvClassicBudget"
                      groupName="tvBudget"
                      unitName="CA initial"
                      [totalAlreadyFact]="purchase.iniFact">
                    </app-cascade-unit>

                    <app-cascade-unit
                      [form]="form"
                      [total]="purchase.caRef"
                      groupName="caRef"
                      unitName="CA de référence">
                    </app-cascade-unit>

                    <app-cascade-unit
                      [form]="form"
                      [total]="purchase.caFact"
                      groupName="caFact"
                      unitName="CA NET avant remise">
                    </app-cascade-unit>
                </div>

                <!-- Tv Region -->
                <div *ngSwitchCase="this.group == 'FREG'">
                    <app-cascade-unit
                      [form]="form"
                      [total]="purchase.tvRegionBudget"
                      groupName="tvBudget"
                      unitName="CA initial"
                      [totalAlreadyFact]="purchase.iniFactRegion">
                    </app-cascade-unit>

                    <app-cascade-unit
                      [form]="form"
                      [total]="purchase.caRefRegion"
                      groupName="caRef"
                      unitName="CA de référence">
                    </app-cascade-unit>

                    <app-cascade-unit
                      [form]="form"
                      [total]="purchase.caFactRegion"
                      groupName="caFact"
                      unitName="CA NET avant remise">
                    </app-cascade-unit>
                </div>

                <!-- Tv Domtom -->
                <div *ngSwitchCase="this.group == 'O'">
                    <app-cascade-unit
                      [form]="form"
                      [total]="purchase.tvDomtomBudget"
                      groupName="tvBudget"
                      unitName="CA initial"
                      [totalAlreadyFact]="purchase.iniFactDomtom">
                    </app-cascade-unit>

                    <app-cascade-unit
                      [form]="form"
                      [total]="purchase.caRefDomtom"
                      groupName="caRef"
                      unitName="CA de référence">
                    </app-cascade-unit>

                    <app-cascade-unit
                      [form]="form"
                      [total]="purchase.caFactDomtom"
                      groupName="caFact"
                      unitName="CA NET avant remise">
                    </app-cascade-unit>
                </div>

                <!-- Tv Thematique -->
                <div *ngSwitchCase="this.group == 'W'">
                    <app-cascade-unit
                      [form]="form"
                      [total]="purchase.tvThemaBudget"
                      groupName="tvBudget"
                      unitName="CA initial"
                      [totalAlreadyFact]="purchase.iniFactThema">
                    </app-cascade-unit>

                    <app-cascade-unit
                      [form]="form"
                      [total]="purchase.caRefThema"
                      groupName="caRef"
                      unitName="CA de référence">
                    </app-cascade-unit>

                    <app-cascade-unit
                      [form]="form"
                      [total]="purchase.caFactThema"
                      groupName="caFact"
                      unitName="CA NET avant remise">
                    </app-cascade-unit>
                </div>

                <!-- Tv International -->
                <div *ngSwitchCase="this.group == 'I'">
                    <app-cascade-unit
                      [form]="form"
                      [total]="purchase.tvInterBudget"
                      groupName="tvBudget"
                      unitName="CA initial"
                      [totalAlreadyFact]="purchase.iniFactInter">
                    </app-cascade-unit>

                    <app-cascade-unit
                      [form]="form"
                      [total]="purchase.caRefInter"
                      groupName="caRef"
                      unitName="CA de référence">
                    </app-cascade-unit>

                    <app-cascade-unit
                      [form]="form"
                      [total]="purchase.caFactInter"
                      groupName="caFact"
                      unitName="CA NET avant remise">
                    </app-cascade-unit>
                </div>

                <!-- Tv France 4 -->
                <div *ngSwitchCase="this.group == 'F4'">
                  <app-cascade-unit
                    [form]="form"
                    [total]="purchase.tvF4Budget"
                    groupName="tvBudget"
                    unitName="CA initial"
                    [totalAlreadyFact]="purchase.iniFactF4">
                  </app-cascade-unit>

                  <app-cascade-unit
                    [form]="form"
                    [total]="purchase.caRefF4"
                    groupName="caRef"
                    unitName="CA de référence">
                  </app-cascade-unit>

                  <app-cascade-unit
                    [form]="form"
                    [total]="purchase.caFactF4"
                    groupName="caFact"
                    unitName="CA NET avant remise">
                  </app-cascade-unit>
                </div>
            </ng-container>
          </div>

            <mat-card-subtitle>CA Net</mat-card-subtitle>
            <mat-card-content>
                <div class="flex totalBudgetNet">
                    <ng-container [ngSwitch]="true">
                        <div class="flex-2">
                            <span *ngSwitchCase="this.group == 'F'" class="hold-color bold">
                                {{ purchase.tvClassicBudgetRate | roundTo:8 }} %
                            </span>
                            <span *ngSwitchCase="this.group == 'FREG'" class="hold-color bold">
                                {{ purchase.tvRegionBudgetRate | roundTo:8 }} %
                            </span>
                            <span *ngSwitchCase="this.group == 'O'" class="hold-color bold">
                                {{ purchase.tvDomtomBudgetRate | roundTo:8 }} %
                            </span>
                            <span *ngSwitchCase="this.group == 'W'" class="hold-color bold">
                                {{ purchase.tvThemaBudgetRate | roundTo:8 }} %
                            </span>
                            <span *ngSwitchCase="this.group == 'I'" class="hold-color bold">
                                {{ purchase.tvInterBudgetRate | roundTo:8 }} %
                            </span>
                            <span *ngSwitchCase="this.group == 'F4'" class="hold-color bold">
                              {{ purchase.tvF4BudgetRate | roundTo:8 }} %
                            </span>
                        </div>
                        <div class="flex-1"></div>
                        <div class="flex-2">
                            <span *ngSwitchCase="this.group == 'F'" class="hold-color bold">
                                {{ purchase.tvClassicBudgetNet | roundTo:2 | number:'0.0-2':'fr' }} €
                            </span>
                            <span *ngSwitchCase="this.group == 'FREG'" class="hold-color bold">
                                {{ purchase.tvRegionBudgetNet | roundTo:2 | number:'0.0-2':'fr' }} €
                            </span>
                            <span *ngSwitchCase="this.group == 'O'" class="hold-color bold">
                                {{ purchase.tvDomtomBudgetNet | roundTo:2 | number:'0.0-2':'fr' }} €
                            </span>
                            <span *ngSwitchCase="this.group == 'W'" class="hold-color bold">
                                {{ purchase.tvThemaBudgetNet | roundTo:2 | number:'0.0-2':'fr' }} €
                            </span>
                            <span *ngSwitchCase="this.group == 'I'" class="hold-color bold">
                                {{ purchase.tvInterBudgetNet | roundTo:2 | number:'0.0-2':'fr' }} €
                            </span>
                            <span *ngSwitchCase="this.group == 'F4'" class="hold-color bold">
                              {{ purchase.tvF4BudgetNet | roundTo:2 | number:'0.0-2':'fr' }} €
                            </span>
                        </div>
                    </ng-container>
                </div>

              <!-- Already billed section -->
              <div class="flex totalBudgetNet">
                <div class="flex-2"></div>
                <div class="flex-1"></div>

                <ng-container [ngSwitch]="true">
                    <div class="flex-2" *ngSwitchCase="this.group == 'F'">
                        <span *ngIf="(purchase.netFact) && (purchase.netFact != 0)" class="hold-color bold">
                            {{ purchase.netFact | roundTo:2 | number:'0.0-2':'fr' }} € facturé
                        </span>
                    </div>
                    <div class="flex-2" *ngSwitchCase="this.group == 'FREG'">
                        <span *ngIf="(purchase.netFactRegion) && (purchase.netFactRegion != 0)" class="hold-color bold">
                            {{ purchase.netFactRegion | roundTo:2 | number:'0.0-2':'fr' }} € facturé
                        </span>
                    </div>
                    <div class="flex-2" *ngSwitchCase="this.group == 'O'">
                        <span *ngIf="(purchase.netFactDomtom) && (purchase.netFactDomtom != 0)" class="hold-color bold">
                            {{ purchase.netFactDomtom | roundTo:2 | number:'0.0-2':'fr' }} € facturé
                        </span>
                    </div>
                    <div class="flex-2" *ngSwitchCase="this.group == 'W'">
                        <span *ngIf="(purchase.netFactThema) && (purchase.netFactThema != 0)" class="hold-color bold">
                            {{ purchase.netFactThema | roundTo:2 | number:'0.0-2':'fr' }} € facturé
                        </span>
                    </div>
                    <div class="flex-2" *ngSwitchCase="this.group == 'I'">
                        <span *ngIf="(purchase.netFactInter) && (purchase.netFactInter != 0)" class="hold-color bold">
                            {{ purchase.netFactInter | roundTo:2 | number:'0.0-2':'fr' }} € facturé
                        </span>
                    </div>
                    <div class="flex-2" *ngSwitchCase="this.group == 'F4'">
                      <span *ngIf="(purchase.netFactF4) && (purchase.netFactF4 != 0)" class="hold-color bold">
                          {{ purchase.netFactF4 | roundTo:2 | number:'0.0-2':'fr' }} € facturé
                      </span>
                    </div>
                </ng-container>
              </div>
            </mat-card-content>
        </div>
      </mat-card>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutGap="20px">
  <app-spinner *ngIf="saving" class="spinner-form" type="warning" size="small"></app-spinner>
  <button mat-button mat-dialog-close>Annuler</button>
  <button mat-flat-button class="button-flat red" (click)="save()">Valider</button>
</mat-dialog-actions>
