export enum StatusSynthesis {
  Unavailable = 0,
  Purchase = 1,
  Option = 2,
  Alerte = 5,
  Available = 7,
  Priority = 10
}

export interface JsonAdvertiserSynthesis {
  id?: number;
  advertiser?: string;
  product_id?: number;
  product_name?: string;
  commercial?: string;
  tooltip?: string;
  soreach_purchase?: string;
  duration: number;
  status: number;
  isActivated?: boolean;
}

export class AdvertiserSynthesis {
  public id: number;
  public advertiser: string;
  public product_id: number;
  public product_name: string;
  public commercial: string;
  public tooltip: string;
  public soreach_purchase: string;
  public duration: number;
  public status: number;
  public isActivated: boolean;

  constructor(jsonAdvertiserSynthesis: JsonAdvertiserSynthesis) {
    if (jsonAdvertiserSynthesis.id) {
      this.id = jsonAdvertiserSynthesis.id;
    }

    if (jsonAdvertiserSynthesis.advertiser) {
      this.advertiser = jsonAdvertiserSynthesis.advertiser;
    }

    if (jsonAdvertiserSynthesis.duration) {
      this.duration = jsonAdvertiserSynthesis.duration;
    }

    if (jsonAdvertiserSynthesis.status || jsonAdvertiserSynthesis.status === 0) {
      this.status = jsonAdvertiserSynthesis.status;
    }

    if (jsonAdvertiserSynthesis.product_id) {
      this.product_id = jsonAdvertiserSynthesis.product_id;
    }

    if (jsonAdvertiserSynthesis.product_name) {
      this.product_name = jsonAdvertiserSynthesis.product_name;
    }

    if (jsonAdvertiserSynthesis.commercial) {
      this.commercial = jsonAdvertiserSynthesis.commercial;
    }

    if (jsonAdvertiserSynthesis.tooltip) {
      this.tooltip = jsonAdvertiserSynthesis.tooltip;
    }

    if (jsonAdvertiserSynthesis.soreach_purchase) {
      this.soreach_purchase = jsonAdvertiserSynthesis.soreach_purchase;
    }

    this.isActivated = jsonAdvertiserSynthesis.isActivated;
  }
}

export interface JsonSynthesis {
  id: number;
  slots: number;
  name: string;
  channels: string[];
  natChannels: string[];
  programCategories: string[];
  f3Regions: string[];
  deprogWeekStatus: number[];
  exceptions: number[];
  advertiser: Array<Array<AdvertiserSynthesis>>;
  channelGroups: string[];
  soreachIds: string[];
  isExclusive: string;
}

export class Synthesis {
  public id: number;
  public slots: number;
  public name: string;
  public channels: string[];
  public natChannels: string[];
  public programCategories: string[];
  public f3Regions: string[];
  public deprogWeekStatus: number[];
  public exceptions: number[];
  public advertiser: Array<Array<AdvertiserSynthesis>>;
  public channelGroups: string[];
  public soReachIds: string[];
  public isExclusive: string;

  constructor(jsonSynthesis: JsonSynthesis) {
    if (jsonSynthesis.id) {
      this.id = jsonSynthesis.id;
    }

    if (jsonSynthesis.name) {
      this.name = jsonSynthesis.name;
    }

    if (jsonSynthesis.advertiser) {
      this.advertiser = jsonSynthesis.advertiser;
    }

    if (jsonSynthesis.channels) {
      this.channels = jsonSynthesis.channels;
    }

    if (jsonSynthesis.natChannels) {
      this.natChannels = jsonSynthesis.natChannels;
    }

    if (jsonSynthesis.programCategories) {
      this.programCategories = jsonSynthesis.programCategories;
    }

    if (jsonSynthesis.f3Regions) {
      this.f3Regions = jsonSynthesis.f3Regions;
    }

    if (jsonSynthesis.deprogWeekStatus) {
      this.deprogWeekStatus = jsonSynthesis.deprogWeekStatus;
    }

    if (jsonSynthesis.exceptions) {
      this.exceptions = jsonSynthesis.exceptions;
    }

    if (jsonSynthesis.channelGroups) {
      this.channelGroups = jsonSynthesis.channelGroups;
    }

    if (jsonSynthesis.soreachIds) {
      this.soReachIds = jsonSynthesis.soreachIds;
    }

    if (jsonSynthesis.slots) {
      this.slots = jsonSynthesis.slots;
    }

    if (jsonSynthesis.isExclusive) {
      this.isExclusive = jsonSynthesis.isExclusive;
    }
  }
}

export interface JsonSynthesisFilter {
  offerType?: number;
  offerName?: string;
  channels?: any[];
  areas?: any[];
  categories?: any[];
}

export class SynthesisFilter {
  offerType = 0;
  offerName?: string;
  channels?: any[];
  areas?: any[];
  categories: any[] = [
    {value: 'A', name: 'FILMS-FICTIONS'},
    {value: 'C', name: 'DIVERTISSEMENTS-JEUX-VARIETES'},
    {value: 'D', name: 'MAGAZINES'},
    {value: 'DG', name: 'METEO NATIONALE'},
    {value: 'F', name: 'SPORT'},
    {value: 'G', name: 'JEUNESSE'},
    {value: 'X', name: 'METEO MULTI-REGIONALE'},
    {value: 'MER', name: 'METEO REGIONALE'},
    {value: 'MAR', name: 'MAGAZINE REGIONALE'},
    {value: 'ISR', name: 'INFO SERVICE REGIONALE'},
    {value: 'H', name: 'HISTOIRE ET SOCIETE'},
  ];

  constructor(jsonSynthesisFilter?: JsonSynthesisFilter) {
    if (jsonSynthesisFilter && jsonSynthesisFilter.offerName) {
      this.offerName = jsonSynthesisFilter.offerName;
    }

    if (jsonSynthesisFilter && jsonSynthesisFilter.channels) {
      this.channels = jsonSynthesisFilter.channels;
    }

    if (jsonSynthesisFilter && jsonSynthesisFilter.areas) {
      this.areas = jsonSynthesisFilter.areas;
    }
  }
}
