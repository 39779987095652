import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';

import { PropalCartService } from '@service/propal-cart/propal-cart.service';
import { GridHeaderService } from '../service/grid.header.service';
import { PurchaseService } from '@service/purchase/purchase.service';
import { ArrayService } from '@service/utilities/array.service';
import { GridService } from '../service/grid.service';
import { BroadcastService } from '@service/broadcast/broadcast.service';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';
import { ProgramService } from '@service/program/program.service';
import { ScheduleService } from '@service/schedule/schedule.service';

import { PropalCart } from '../../../resource/availability/propal-cart.resource';
import { OfferProgram } from '../../../resource/offerProgram.resource';
import { Broadcast } from 'src/app/resource/broadcast.resource';

import { GridExtendsComponent } from 'src/app/shared/class/grid-extends.component';
import { OfferProgramService } from 'src/app/service/offer-program/offer-program.service';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-offer-program-grid',
  templateUrl: './offer-program-grid.component.html',
  styleUrls: ['./offer-program-grid.component.scss'],
  providers: [ GridService, BroadcastService]
})
export class OfferProgramGridComponent extends GridExtendsComponent implements OnInit, OnDestroy {

  @Input() offerProgram: OfferProgram;
  @Input() propalCart: PropalCart;
  @Input() queryParams;
  @Input() filters;
  @Input() lastProgram: boolean;

  @Output() onSendBroadcast: EventEmitter<Broadcast[]> = new EventEmitter();

  destroyCompleteList$: Subject<boolean> = new Subject<boolean>();
  destroyList$: Subject<boolean> = new Subject<boolean>();

  public loading: boolean;
  public gridData: any[];
  public broadcastsTimes: any;
  public schedules: any[];
  public header;
  public headerMonth;
  public broadcasts: Broadcast[];

  constructor(
    public broadcastService: BroadcastService,
    public gridService: GridService,
    public arrayService: ArrayService,
    public propalCartService: PropalCartService,
    public purchaseService: PurchaseService,
    public gridHeaderService: GridHeaderService,
    public customToastrService: CustomToastrService,
    public offerProgramService: OfferProgramService,
    public programService: ProgramService,
    public scheduleService: ScheduleService,
  ) {
    super(
      customToastrService,
      broadcastService,
      gridService,
      arrayService,
      purchaseService,
      propalCartService,
      gridHeaderService,
      offerProgramService,
      scheduleService
    );
  }

  ngOnInit() {
    this.setOfferProgramPropalCart();
    this.initQueryParams();

    this.getBroadcasts()
      .pipe(takeUntil(this.destroyCompleteList$))
      .subscribe(() => {
        this.getGameModules()
          .pipe(takeUntil(this.destroyCompleteList$))
          .subscribe(() => {
            this.getSchedules()
              .pipe(takeUntil(this.destroyCompleteList$))
              .subscribe(() => {
                this.displayGrid();
              }, error => {
                this.customToastrService.displayToastr('ERROR', 'Erreur lors de la récupération des programmations');
              });
          }, error => {
            this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
          });
      }, error => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    // update checked propal cells if offerProgram match
    this.propalCartService.offerProgramPropalSource$
      .pipe(takeUntil(this.destroyCompleteList$))
      .subscribe(offerProgramPropalCart => {
        if (!this.broadcasts || !offerProgramPropalCart) {
          return null;
        }

        if (offerProgramPropalCart.offerProgram.id === this.offerProgram.id) {
          this.offerProgramPropalCart = offerProgramPropalCart;
          this.displayGrid();
        }
    }, error => {
      this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
    });

    // reset grid if propal cart is destroyed
    this.propalCartService.resetGridSource$
      .pipe(takeUntil(this.destroyCompleteList$))
      .subscribe(status => {
        if (status && this.broadcasts) {
          this.offerProgramPropalCart = null;
          this.displayGrid();
        }
      }, error => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.scheduleService
      .getList({
        offer_program_id: this.offerProgram.id,
        is_purchased: false,
        groups: 'offerProgram'
      })
      .pipe(takeUntil(this.destroyList$))
      .subscribe(schedules => {
        this.schedules = schedules;
      }, error => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });
  }

  ngOnDestroy() {
    this.destroyCompleteList$.next(true);
    this.destroyCompleteList$.unsubscribe();
  }
}
