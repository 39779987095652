import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LotsRoutingModule } from './lots-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { DirectiveModule } from '../../directive/directive.module';

import { LotsComponent } from './lots/lots.component';
import { LotsPurchaseComponent } from './lots-purchase/lots-purchase.component';
import { LotsMonitoringComponent } from './lots-monitoring/lots-monitoring.component';
import { LotsFormAdvertiserComponent } from './lots-form-advertiser/lots-form-advertiser.component';
import { AddMonitoringLotComponent } from './dialog/add-monitoring-row/add-monitoring-lot.component';
import { NewLotDotationFormComponent } from './dialog/new-lot-dotation-form/new-lot-dotation-form.component';
import { NewDotationPurchaseComponent } from './dialog/new-dotation-purchase/new-dotation-purchase.component';
import { NewMonitoringLotProgramComponent } from './dialog/new-monitoring-lot-program/new-monitoring-lot-program.component';
import { NewLotDotationPeriodComponent } from './dialog/new-lot-dotation-form/new-lot-dotation-period/new-lot-dotation-period.component';
import { AddMonitoringLotPeriodComponent } from './dialog/add-monitoring-row/add-monitoring-row-period/add-monitoring-lot-period.component';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [
    LotsComponent,
    LotsFormAdvertiserComponent,
    NewLotDotationFormComponent,
    LotsMonitoringComponent,
    AddMonitoringLotComponent,
    AddMonitoringLotPeriodComponent,
    NewLotDotationPeriodComponent,
    LotsPurchaseComponent,
    NewDotationPurchaseComponent,
    NewMonitoringLotProgramComponent
  ],
  imports: [
    CommonModule,
    LotsRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatTableModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatInputModule,
    SharedModule,
    FlexLayoutModule,
    MatExpansionModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    DirectiveModule
  ],
  entryComponents: [
    NewLotDotationFormComponent,
    AddMonitoringLotComponent,
    NewDotationPurchaseComponent,
    NewMonitoringLotProgramComponent
  ]
})
export class LotsModule {
}
