import { Schedule } from './schedule.resource';
import { Base } from '../base.resource';

export interface JsonScheduleTime {
    id: number;
    start_time: string;
    end_time: string;
    schedule?: any;
    parent?: any;
}

export class ScheduleTime extends Base {
    public id: number;
    public startTime: string;
    public endTime: string;
    public schedule: Schedule;
    public parent: ScheduleTime;

    constructor(jsonScheduleTime: JsonScheduleTime) {
        super();
        jsonScheduleTime = this.convertToUnderscore(jsonScheduleTime);

        this.id = jsonScheduleTime.id;
        this.startTime = jsonScheduleTime.start_time;
        this.endTime = jsonScheduleTime.end_time;

        if (jsonScheduleTime.schedule) {
            this.schedule = new Schedule(jsonScheduleTime.schedule);
        }

        if (jsonScheduleTime.parent) {
            this.parent = new ScheduleTime(jsonScheduleTime.parent);
        }
    }
}