import { BroadcastTimeLabelPipe } from './broadcast-time-label.pipe';
import { ThousandsSeparatorPipe } from './thousands-separator.pipe';
import { KeysPipe } from './ngfor-object.pipe';
import { MomentPipe } from './moment.pipe';
import { CapitalizePipe } from './capitalize.pipe';
import { NgModule } from '@angular/core';
import { CurrencyPipe } from './currency.pipe';
import { RoundToPipe } from './round-to.pipe';
import { PercentagePipe } from './percentage.pipe'

@NgModule({
    imports: [],
    declarations: [
      CapitalizePipe,
      MomentPipe,
      KeysPipe,
      ThousandsSeparatorPipe,
      CurrencyPipe,
      RoundToPipe,
      PercentagePipe,
      BroadcastTimeLabelPipe
    ],
    exports: [
      CapitalizePipe,
      MomentPipe,
      KeysPipe,
      ThousandsSeparatorPipe,
      CurrencyPipe,
      RoundToPipe,
      PercentagePipe,
      BroadcastTimeLabelPipe
    ]
})
export class PipeModule {}
