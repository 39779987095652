import { Component, OnInit, Output, EventEmitter, OnDestroy, Input } from '@angular/core';
import { CustomValidators } from '../../../../validator/custom-validators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdvertiserService } from '../../../../service/advertiser/advertiser.service';
import { EmployeeService } from '../../../../service/employee/employee.service';
import { CustomToastrService } from '../../../../service/toastr/custom-toastr.service';
import { AutocompleteService } from '../../../../service/utilities/autocomplete.service';
import { FilteredItem } from '../../../../resource/filteredItem.resource';
import { Employee } from '../../../../resource/employee.resource';
import { Purchase } from '../../../../resource/purchase.resource';

import { takeUntil, startWith, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject, Observable, of } from 'rxjs';
import { PurchaseParams } from '../../../../resource/purchaseType.resource';

@Component({
  selector: 'app-propal-main-information',
  templateUrl: './propal-main-information.component.html',
  styleUrls: ['./propal-main-information.component.scss']
})
export class PropalMainInformationComponent implements OnInit, OnDestroy {

    @Output() valid = new EventEmitter<boolean>();
    @Output() purchase = new EventEmitter<Purchase>();
    @Input() purchaseParams: PurchaseParams;

    public mainInformationGroup: FormGroup;
    public loadingInputAdvertiser: boolean = false;
    public loadingInputCommercial: boolean = false;

    private employeeCommercialItems: FilteredItem[];
    private advertiserItems: FilteredItem[];

    public filteredEmployeeCommercialItems: Observable<FilteredItem[]>;
    public filteredAdvertiserItems: Observable<FilteredItem[]>;

    private componentDestroyed$: Subject<any> = new Subject();

    constructor(
        private fb: FormBuilder,
        private advertiserService: AdvertiserService,
        private employeeService: EmployeeService,
        private customToastrService: CustomToastrService,
        public autocompleteService: AutocompleteService
    ) { }

  ngOnInit() {
      this.initForm();
      this.setValueChanges();
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.unsubscribe();
  }

 /**
   * Post select action on advertiser field
   *
   * @param {*} advertiser
   * @memberof PurchaseStakeholderComponent
   */
  onAdvertiserSelect(advertiser: any) {
    if (advertiser) {
      let controlsCustomer = this.mainInformationGroup.controls;

      // A quick and dirty way to not contatenate the id a second time
      advertiser.entity.name = advertiser.entity.name .replace(/ \([0-9]+\)$/, '');
      advertiser.entity.name = advertiser.entity.name + ' (' + advertiser.entity.id + ')';

      controlsCustomer['advertiser'].setValue(
        advertiser.entity ? new FilteredItem(advertiser.entity) : ''
      );
    }
  }

  private emitPurchase() {
      if (this.mainInformationGroup.valid) {
          let newPurchase = new Purchase(Purchase.init());
          newPurchase.name = this.mainInformationGroup.get('name').value;
          newPurchase.commercial = this.mainInformationGroup.get('commercial').value['entity'];
          newPurchase.advertiser = this.mainInformationGroup.get('advertiser').value['entity'] || null;
          if (!newPurchase.advertiser) {
              newPurchase.temporaryAdvertiser = this.mainInformationGroup.get('advertiser').value;
          }

          this.purchase.emit(newPurchase);
          this.valid.emit(true);
      } else {
          this.purchase.emit(null);
          this.valid.emit(false);
      }
  }

  private initForm(): void {
    this.mainInformationGroup = this.fb.group({
      name: this.purchaseParams ? [this.purchaseParams.name, [Validators.required]] : [null, [Validators.required]],
      commercial: this.purchaseParams ? [this.purchaseParams.commercial, [Validators.required]]
        : [null, [Validators.required, CustomValidators.isFilteredItem]],
      advertiser: this.purchaseParams ? [this.purchaseParams.advertiser, [Validators.required]]
        : [null, [Validators.required, CustomValidators.temporyFieldRequired]]
    });

    if (this.purchaseParams) {
      this.mainInformationGroup.controls['advertiser'].disable();
      this.mainInformationGroup.controls['commercial'].disable();
      this.mainInformationGroup.controls['name'].disable();
      this.valid.emit(true);
    }
  }

  private setValueChanges() {
    const controls = this.mainInformationGroup.controls;

    this.mainInformationGroup.valueChanges
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(() => {
        this.emitPurchase();
    }, error => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
    })

    // Commercial
    controls['commercial'].valueChanges
    .pipe(
      startWith(null),
      debounceTime(500),
      distinctUntilChanged(),
      takeUntil(this.componentDestroyed$)
    )
    .subscribe(value => {
        this.filteredEmployeeCommercialItems = of([]);
        if (!value) {
          return;
        }
        this.loadingInputCommercial = true;
        this.employeeService.getListForFilter({
            user : value,
            employee_type_id: Employee.commercialType.join(';'),
            commercial_cell_id: Employee.commercialCell.join(';')
        })
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(employeeItems => {
            if (employeeItems && employeeItems.length > 0) {
                this.employeeCommercialItems = employeeItems;
                this.filteredEmployeeCommercialItems = of(
                    this.autocompleteService.filterItems(value, this.employeeCommercialItems)
                );
            }
            this.loadingInputCommercial = false;
        }, error => {
            this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            this.loadingInputCommercial = false;
        });
    });

    // Advertisier
    controls['advertiser'].valueChanges
    .pipe(
      startWith(null),
      debounceTime(500),
      distinctUntilChanged(),
      takeUntil(this.componentDestroyed$)
    )
    .subscribe(value => {
        this.filteredAdvertiserItems = of([]);
        if (!value || typeof value !== 'string') {
            return;
        }
        this.loadingInputAdvertiser = true;
        const filterAdvertiser =  Number(value) ? {id: value.toUpperCase()} : {name: value.toUpperCase()};

        this.advertiserService.getListForFilter(filterAdvertiser)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(advertiserItems => {
                if (advertiserItems && advertiserItems.length > 0) {
                    this.advertiserItems = advertiserItems;
                    this.filteredAdvertiserItems = of(
                        this.autocompleteService.filterItems(value, this.advertiserItems)
                    );
                }
                this.loadingInputAdvertiser = false;
            }, error => {
                this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
                this.loadingInputAdvertiser = false;
            });
    }, error => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
        this.loadingInputAdvertiser = false;
    });
  }
}
