import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

import { UtilitiesHandler } from '@service/utilities-handlers/utilitiesHandlers';

import { Category } from '../../resource/category.resource';
import { FilteredItem } from '../../resource/filteredItem.resource';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class CategoryService {
  private route: string = 'category';

  constructor(
    private http: HttpClient,
    private utilitiesHandler: UtilitiesHandler
  ) {
  }

  /**
   * Get list of Category
   *
   * @returns {Observable<Category[]>}
   * @memberof CategoryService
   */
  getList(): Observable<Category[]> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;

    return this.http
      .get(api_base_url)
      .pipe(
        map((data: any) =>
          data._embedded.category
            .map((jsonCategory: any) =>
              new Category(jsonCategory)
            )
        )
      )
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get list of Category for filter
   *
   * @param {Object} [filters=null]
   * @returns {Observable<FilteredItem[]>}
   * @memberof CategoryService
   */
  getListForFilter(filters: Object = null): Observable<FilteredItem[]> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params})
      .pipe(
        map((data: any) =>
          data._embedded.category
            .map((json: any) =>
              new Category(json)
            )
            .map((category: Category) =>
              new FilteredItem(category)
            )
        ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }
}
