<form fxLayout="column" fxLayoutAlign="center center" class="card" [@slideInOut]="cardState" [formGroup]="soReachForm" *ngIf="!isEmptyCard">
  <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="center center" (click)="openClose()">
    <h2 class="title cursor"> Créer une propale {{ nameTypeSoReach }}</h2>
    <mat-icon class="cursor" *ngIf="cardState === 'in'">arrow_drop_down</mat-icon>
    <mat-icon class="cursor" *ngIf="cardState === 'out'">arrow_drop_up</mat-icon>
  </div>
  <div fxLayout="row" class="full-width" fxLayoutGap="15px">
    <div fxLayout="row" fxLayoutAlign="center center" class="checked-soreach" fxFlex="70" fxLayoutGap="5px">
      <div class="full-height card-channel" fxFlex *ngFor="let channel of dataCard | keyvalue">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <img [src]="path + channel.key.toLowerCase() + '.png'" class="icon-card">
          <mat-icon class="cursor" (click)="deleteAllProgramsSoReach(channel.key)">delete_outline</mat-icon>
        </div>
        <div fxLayout="column" class="height-card-program">
          <div *ngFor="let weekDay of channel.value | keyvalue">
            <div *ngIf="weekDay.value.length">
              <div *ngFor="let program of weekDay.value; index as index" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
                <span fxFlex="25px" fxFlexOffset="5px">{{ weekDay.key }}</span>
                <div fxLayout="row" fxLayoutGap="5px">
                  <span>{{ program.date }}</span>
                </div>
                <span fxFlex class="text-wrap">{{ program.name }}</span>
                <mat-icon class="cursor" (click)="deleteProgramSoReach(channel.key, weekDay.key, program, index)">delete_outline</mat-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" fxFlex>
      <mat-progress-bar mode="indeterminate" *ngIf="loadingInputAdvertiser || loadingInputCommercial || isLoading"></mat-progress-bar>

      <mat-form-field class="full-width">
        <input matInput type="text" placeholder="Entrez un nom" formControlName="name" appRemoveAccents maxlength="50" autocomplete="off" required>
      </mat-form-field>

      <mat-form-field class="full-width">
        <input matInput type="text" placeholder="Commercial" formControlName="commercial" appRemoveAccents [matAutocomplete]="commercialAutocomplete" autocomplete="off" required>
      </mat-form-field>
      <mat-autocomplete #commercialAutocomplete="matAutocomplete" [displayWith]="autocompleteService.displayAutocompleteWithValue.bind(this)">
        <mat-option *ngFor="let employee of filteredEmployeeCommercialItems | async"  [value]="employee">
          {{ employee.display }} ({{ employee.value }})
        </mat-option>
      </mat-autocomplete>

      <mat-form-field class="full-width">
        <input matInput type="text" placeholder="Annonceur" formControlName="advertiser" appRemoveAccents [matAutocomplete]="advertiserAutocomplete" autocomplete="off" required>
      </mat-form-field>
      <mat-autocomplete #advertiserAutocomplete="matAutocomplete" [displayWith]="autocompleteService.displayAutocomplete.bind(this)">
        <mat-option *ngFor="let advertiser of filteredAdvertiserItems | async"  [value]="advertiser" (onSelect)="onAdvertiserSelect(advertiser)">
          {{ advertiser.display }} ({{ advertiser.value }})
        </mat-option>
      </mat-autocomplete>

      <button mat-button (click)="cancel()">Annuler</button>
      <button mat-flat-button (click)="save()" class="button-flat red" [disabled]="!soReachForm.valid || isEmptyCard || isLoading">
        {{ createOrUpdateMessage }}
      </button>
    </div>
  </div>
</form>
