<div class="grid-header">
    
    <table class="grid-header-month" *ngIf="headerMonth">
        <tr>
            <td class="td-top" *ngFor="let month of headerMonth" width="{{cellWidth * month.value}}">
                {{month.name}}
            </td>
        </tr>
    </table>

    <table *ngIf="header">
        <tr>
            <td class="td-bot" *ngFor="let cell of header">
                <span class="text-cell thin">{{cell}}</span>
            </td>
        </tr>
    </table>
</div>

