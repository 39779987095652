import { CustomValidators } from '../validator/custom-validators';
import { PurchaseBudgetService } from './purchase-budget/purchase-budget.service';
import { ScheduleBroadcastService } from './broadcast/schedule-broadcast.service';
import { SchedulePurchasedService } from './schedule/schedule-purchased.service';
import { BroadcastPurchasedService } from './broadcast-purchased/broadcast-purchased.service';
import { CustomerService } from './customer/customer.service';
import { CampaignService } from './campaign/campaign.service';
import { TargetService } from './target/target.service';
import { SchedulePackageService } from './schedule-package/schedule-package.service';
import { ScheduleTimeService } from './schedule-time/schedule-time.service';
import { ScheduleService } from './schedule/schedule.service';
import { AdvertisingPackageService } from './advertising-package/advertising-package.service';
import { ArrayService } from './utilities/array.service';
import { SecodipService } from './secodip/secodip.service';
import { AuthService } from './auth.service';
import { UserService } from './user/user.service';
import { OfferService } from './offer/offer.service';
import { NgModule } from '@angular/core';
import { BroadcastService } from './broadcast/broadcast.service';
import { AreaService } from './area/area.service';
import { PurchaseService } from './purchase/purchase.service';
import { PurchaseTypeService } from './purchase-type/purchase-type.service';
import { AdvertiserService } from './advertiser/advertiser.service';
import { ProductService } from './product/product.service';
import { ChannelService } from './channel/channel.service';
import { CategoryService } from './category/category.service';
import { ProgramService } from './program/program.service';
import { SchedulePeriodService } from './schedule-period/schedule-period.service';
import { OfferProgramService } from './offer-program/offer-program.service';
import { UtilitiesService } from './utilities/utilities.service';
import { OfferProgramBudgetPackageService } from './offer-program-budget-package/offer-program-budget-package.service';
import { OfferProgramBudgetService } from './offer-program-budget/offer-program-budget.service';
import { TrailerPeriodicityService } from './trailer-periodicity/trailer-periodicity.service';
import { OfferBudgetService } from './offer-budget/offer-budget.service';
import { DurationRateService } from './duration-rate/duration-rate.service';
import { EmployeeService } from './employee/employee.service';
import { AutocompleteService } from './utilities/autocomplete.service';
import { PurchaseProgramService } from './purchase-program/purchase-program.service';
import { AbatementService } from './abatement/abatement.service';
import { PurchaseAbatementService } from './purchase-abatement/purchase-abatement.service';
import { PropalCartService } from './propal-cart/propal-cart.service';
import { PropalCartGenerateService } from './propal-cart/propal-cart-generate.service';
import { GrpService } from './grp/grp.service';
import { GrpFormService } from './grp/grp-form.service';
import { MathService } from './utilities/math.service';
import { ScheduleExceptionService } from './schedule-exception/schedule-exception.service';
import { EmailService } from './email/email.service';
import { OfferPushService } from './offer-push/offer-push.service';
import { QueueLogService } from './queue-log/queue-log.service';
import { PurchaseGrpService } from './purchase-grp/purchase-grp.service';
import { DuplicateOfferService } from './duplicate-offer/duplicate-offer.service';
import { CustomToastrService } from './toastr/custom-toastr.service';
import { UtilitiesHandler } from './utilities-handlers/utilitiesHandlers';
import { SignataireService } from '@service/signataire/signataire.service';

@NgModule({
  providers: [
    // Handler
    UtilitiesHandler,
    // Ressource service
    OfferService,
    BroadcastService,
    SecodipService,
    AreaService,
    PurchaseService,
    PurchaseTypeService,
    AdvertiserService,
    ProductService,
    ChannelService,
    CategoryService,
    ProgramService,
    OfferProgramService,
    UserService,
    AuthService,
    ArrayService,
    AdvertisingPackageService,
    ScheduleService,
    SchedulePeriodService,
    ScheduleTimeService,
    UtilitiesService,
    OfferProgramBudgetService,
    OfferProgramBudgetPackageService,
    TrailerPeriodicityService,
    ScheduleService,
    SchedulePeriodService,
    ScheduleTimeService,
    SchedulePackageService,
    OfferBudgetService,
    TargetService,
    DurationRateService,
    EmployeeService,
    AutocompleteService,
    CampaignService,
    CustomerService,
    BroadcastPurchasedService,
    SchedulePurchasedService,
    PurchaseProgramService,
    AbatementService,
    PurchaseAbatementService,
    PropalCartService,
    ScheduleBroadcastService,
    PurchaseBudgetService,
    CustomValidators,
    GrpService,
    GrpFormService,
    PropalCartGenerateService,
    MathService,
    EmailService,
    OfferPushService,
    ScheduleExceptionService,
    QueueLogService,
    PurchaseGrpService,
    DuplicateOfferService,
    CustomToastrService,
    SignataireService
  ]
})
export class ServicesModule { }
