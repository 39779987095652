<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

<app-program-card
    class="app-program-card"
    [offerProgram]="offerProgram"
    [broadcasts]="broadcasts"
    [schedules]="schedules"
    *ngIf="offerProgram">
</app-program-card>

<app-grid
    class="app-grid"
    [offerProgram]="offerProgram"
    [gridData]="gridData"
    [header]="header"
    [loading]="loading"
    [headerMonth]="headerMonth"
    [broadcastsTimes]="broadcastsTimes"
    [schedules]="schedules"
    [lastProgram]="lastProgram"
    *ngIf="gridData && broadcastsTimes">
</app-grid>
