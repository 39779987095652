export interface JsonRegionSynthesis {
  name: string,
  region: string,
  places: number,
  category: string;
  idRegion: string;
  idOffer?: string;
  idProgram?: string;
  weeks?: RegionWeeksSynthesis[];
  offerProgram?: {};
  offerProgramId?: string | number;
  digitalBudgets?: {
    dig_vid_bill_budget?: string | number;
    dig_vid_pre_budget?: string | number;
    digital_display_budget?: string | number;
    digital_other_budget?: string | number;
    digital_replay_budget?: string | number;
    digital_social_budget?: string | number;
    social_fb_budget?: string | number;
    social_insta_budget?: string | number;
    social_twit_budget?: string | number;
  }
}

export class RegionSynthesis {
  public name: string;
  public region: string;
  public places: number;
  public category: string;
  public idRegion: string;
  public idProgram: string;
  public offerProgramId: string | number;
  public weeks: RegionWeeksSynthesis[];
  public offerProgram: {};

  constructor(jsonRegionSynthesis: JsonRegionSynthesis) {
    if (jsonRegionSynthesis.name) {
      this.name = jsonRegionSynthesis.name;
    }

    if (jsonRegionSynthesis.region) {
      this.region = jsonRegionSynthesis.region;
    }

    if (jsonRegionSynthesis.places) {
      this.places = Number(jsonRegionSynthesis.places) ? Number(jsonRegionSynthesis.places) : 0;
    }

    if (jsonRegionSynthesis.weeks) {
      this.weeks = jsonRegionSynthesis.weeks;
    }

    if (jsonRegionSynthesis.weeks) {
      this.category = jsonRegionSynthesis.category;
    }

    if (jsonRegionSynthesis.idRegion) {
      this.idRegion = jsonRegionSynthesis.idRegion;
    }

    if (jsonRegionSynthesis.idProgram) {
      this.idProgram = jsonRegionSynthesis.idProgram;
    }

    if (jsonRegionSynthesis.offerProgramId) {
      this.offerProgramId = jsonRegionSynthesis.offerProgramId;
    }

    if (jsonRegionSynthesis.offerProgram) {
      this.offerProgram = jsonRegionSynthesis.offerProgram;
    }
  }
}

export interface JsonRegionFilterSynthesis {
  year: number,
}

export class RegionFilterSynthesis {
  public year: number;

  constructor(jsonRegionFilterSynthesis: JsonRegionFilterSynthesis) {
    if (jsonRegionFilterSynthesis.year) {
      this.year = jsonRegionFilterSynthesis.year;
    }
  }
}

export class RegionWeeksSynthesis {
  weeks: Object[];
  maxLines: number;
  checked: number;

  constructor(weeks: Object[], maxLines: number) {
    this.weeks = weeks;
    this.maxLines = maxLines;
  }
}
