import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { AbstractService } from '@service/abstract.service';
import { UtilitiesHandler } from '@service/utilities-handlers/utilitiesHandlers';

import { JsonMonitoringLotDotation, LotDotation, MonitoringLotDotation } from '../../resource/lot-dotation.resource';
import {FilteredItem, JsonFilteredItem} from '../../resource/filteredItem.resource';

import { environment } from '../../../environments/environment';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export interface MonitoringLotParrDiffusionFilter {
  programId: string,
  channelId: string,
  periods: any,
}

export interface MonitoringLotParrDiffusionPanierFilter {
  lotDiffId: number,
  diffusionIds: any[],
  moduleId: number,
}

@Injectable({
  providedIn: 'root'
})
export class LotDotationService extends AbstractService {
  private lot_dotation_url = `${environment.api_base_url}/lot_dotation`;
  private monitoring_lot_url = `${environment.api_base_url}/monitoring_lot`;
  private dotation_purchase_url = `${environment.api_base_url}/create_dotation_purchase`;
  private dotation_purchase_modules_url = `${environment.api_base_url}/dotation_purchase_modules`;
  private monitoring_lot_program_url = `${environment.api_base_url}/monitoring_lot_program`;
  private monitoring_lot_parr_diffusion = `${environment.api_base_url}/create_monitoring_lot_parrdiffusion`;
  private monitoring_lot_parr_diffusion_panier = `${environment.api_base_url}/create_monitoring_lot_parrdiffusionpanier`;

  constructor(
      private http: HttpClient,
      private utilitiesHandler: UtilitiesHandler
  ) {
    super();
  }

  /**
   * Get lot dotation entity
   *
   * @param id {id}
   * @returns {Observable<LotDotation>}
   * @memberof LotDotationService
   */
  get(id: number): Observable<LotDotation> {
    return this.http
      .get(`${this.lot_dotation_url}/${id}`)
      .pipe(
        map((response: any) =>
          new LotDotation(response)
        ))
      .pipe(
          catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get list of Monitoring Lot
   *
   * @returns {Observable<void>}
   */
  getMonitoringLotList(filters): Observable<MonitoringLotDotation[]> {
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(this.monitoring_lot_url, {params})
      .pipe(
        map((data: any) =>
          data._embedded.monitoring_lot
            .map((jsonMonitoringLot) =>
              new MonitoringLotDotation(jsonMonitoringLot)
            )
        ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get lot dotation list
   *
   * @return {Observable<void>>}
   * @memberof LotDotationService
   */
  getList(filters): Observable<LotDotation[]> {
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(this.lot_dotation_url, {params})
      .pipe(
        map((collection: any) => {
          return collection._embedded.lot_dotation
            .map((jsonLotDotation: any) =>
              new LotDotation(jsonLotDotation)
            )
        })
      )
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get list of lot for filter
   *
   * @param {Object} [filters=null]
   * @returns {Observable<FilteredItem[]>}
   */
  getListForFilter(filters: Object = null): Observable<FilteredItem[]> {
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(this.lot_dotation_url, {params})
      .pipe(
        map((data: any) => {
          return data._embedded.lot_dotation
            .map((jsonLotDotation: any) =>
              new LotDotation(jsonLotDotation)
            )
            .map((lotDotation: LotDotation) =>
              new FilteredItem(lotDotation)
            )
        })
      )
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Put lot dotation entity
   *
   * @param lotDotation {LotDotation}
   * @return {Observable<LotDotation>}
   * @memberof LotDotationService
   */
  put(lotDotation: LotDotation): Observable<LotDotation> {
    const api_base_url: string = `${this.lot_dotation_url}/${lotDotation.id}`;

    return this.http
      .put(api_base_url, this.convertToUnderscore(this.extract(lotDotation)))
      .pipe(
        map((response: any) =>
          new LotDotation(response)
        ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Post lot dotation entity
   *
   * @param lotDotation {LotDotation}
   * @return {Observable<LotDotation>}
   * @memberof LotDotationService
   */
  post(lotDotation: LotDotation): Observable<LotDotation> {
    return this.http
      .post(this.lot_dotation_url, this.convertToUnderscore(this.extract(lotDotation)))
      .pipe(
        map((response: any) =>
          new LotDotation(response)
        ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Post Monitoring Lot entity
   *
   * @return {Observable<MonitoringLotDotation>}
   * @param monitoringLotDotation {MonitoringLotDotation}
   * @param filterGroup {string}
   */
  postMonitoringLot(monitoringLotDotation: MonitoringLotDotation, filterGroup: string = null): Observable<MonitoringLotDotation> {
    const filters = {};

    if (filterGroup) {
      filters['groups'] = filterGroup;
    }

    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .post(
        this.monitoring_lot_url,
        this.convertToUnderscore(this.extractMonitoringLot(monitoringLotDotation)),
        {params: params}
      )
      .pipe(
        map((response: any) =>
          new MonitoringLotDotation(response)
        ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Put Monitoring Lot entity
   *
   * @return {Observable<MonitoringLotDotation>}
   * @param monitoringLotDotation {MonitoringLotDotation}
   * @param filterGroup {string}
   * @param id {number}
   */
  putMonitoringLot(
    monitoringLotDotation: MonitoringLotDotation,
    filterGroup: string = null,
    id: number
  ): Observable<MonitoringLotDotation> {
    const filters = {};

    if (filterGroup) {
      filters['groups'] = filterGroup;
    }

    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .put(
        `${this.monitoring_lot_url}/${id}`,
        this.convertToUnderscore(this.extractMonitoringLot(monitoringLotDotation)),
        {params: params}
      )
      .pipe(
        map((response: any) =>
          new MonitoringLotDotation(response)
        ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

   /**
    * * Post Monitoring Lot Parr Diffusion
    *
    * @param filter
    */
  postMonitoringLotParrDiffusion(filter: MonitoringLotParrDiffusionFilter): Observable<any> {
    return this.http
      .post(this.monitoring_lot_parr_diffusion, filter)
      .pipe(
        map((response: any) => {
          return response;
        })
      )
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      );
  }

  /**
   * * Post Monitoring Lot Parr Diffusion
   *
   * @param filter
   */
  postMonitoringLotParrDiffusionPanier(filter: MonitoringLotParrDiffusionPanierFilter): Observable<any> {
    return this.http
      .post(this.monitoring_lot_parr_diffusion_panier, filter)
      .pipe(
        map((response: any) => {
          return response;
        })
      )
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      );
  }

  /**
   * Delete lot dotation entity
   *
   * @param id {number}
   * @return {Observable<Object>}
   * @memberof LotDotationService
   */
  delete(id: number): Observable<Object> {
    return this.http
      .delete(`${this.lot_dotation_url}/${id}`)
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Filter by bilan number
   *
   * @param bilanNumberValue {string}
   * @returns {Observable<FilteredItem[]>}
   */
  filterByBilanNumber(bilanNumberValue: string): Observable<FilteredItem[]> {
    const params: HttpParams = this.utilitiesHandler.buildParamsApi({bilan_number: bilanNumberValue});

    return this.http
      .get(this.monitoring_lot_url, {params})
      .pipe(
        map((response: any) =>
          response._embedded.monitoring_lot
            // Filter to keep unique items by bilan number
            .filter((itemX, i, arr) => arr.findIndex(itemY => itemY.bilan_number === itemX.bilan_number) === i)
            .map((jsonMonitoringLot: JsonMonitoringLotDotation) =>
              ({
                display: jsonMonitoringLot.bilan_number,
                value: jsonMonitoringLot.bilan_number
              })
            )
        ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get contract number
   *
   * @param value {string}
   * @returns {Observable<FilteredItem[]>}
   */
  getContractNumber(value: string): Observable<FilteredItem[]> {
    const params: HttpParams = this.utilitiesHandler.buildParamsApi({contractNumber: value});

    return this.http
      .get(this.monitoring_lot_url, {params})
      .pipe(
        map((data: any) =>
          data._embedded.monitoring_lot
            .map((jsonMonitoringLot) =>
              new MonitoringLotDotation(jsonMonitoringLot)
            )
            .map((jsonMonitoringLot: MonitoringLotDotation) =>
              ({
                display: jsonMonitoringLot.contractNumber,
                value: jsonMonitoringLot.contractNumber
              })
            )
        ))
  }

  /**
   * Delete monitoring lot entity
   *
   * @param id {number}
   * @return {Observable<Object>}
   * @memberof LotDotationService
   */
  deleteMonitoringLot(id: number): Observable<Object> {
    return this.http
      .delete(`${this.monitoring_lot_url}/${id}`)
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Post request to create dotation purchase
   *
   * @param postParams {Object}
   * @returns {Observable<Object>}
   * @memberof LotDotationService
   */
  createDotationPurchase(postParams: Object): Observable<Object> {
    return this.http
      .post(this.dotation_purchase_url, this.convertToUnderscore(postParams))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   *
   * @param filters
   */
  getFilteredDotationPurchaseModules(filters: Object): Observable<FilteredItem[]>  {
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(this.dotation_purchase_modules_url, {params})
      .pipe(
        map((response: JsonFilteredItem[]) =>
          /*map((response: JsonFilteredItem[]) => {
            return response.map((module: JsonFilteredItem) => {
              return new FilteredItem(module)
            })
          })*/
          response.map((module: JsonFilteredItem) =>
              new FilteredItem(module)
            )
        ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  createProgram(params: Object): Observable<FilteredItem>   {
    return this.http
      .post(this.monitoring_lot_program_url, params)
      .pipe(
        map((program: JsonFilteredItem) =>
          new FilteredItem(program)
        ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Extract
   *
   * @param lotDotation {LotDotation}
   * @returns {Object}
   * @memberof LotDotationService
   */
  private extract(lotDotation: LotDotation): Object {
    return {
      id: lotDotation.id ? lotDotation.id : null,
      name: lotDotation.name,
      description: lotDotation.description,
      quantity: lotDotation.quantity,
      unitPrice: lotDotation.unitPrice,
      product: lotDotation.product.id,
      advertiser: lotDotation.advertiser.id,
      parroption: lotDotation.parroption,
      parroptionName: lotDotation.parroptionName,
      periodStartDate: lotDotation.periodStartDate,
      periodEndDate: lotDotation.periodEndDate
    };
  }

  /**
   * Extract Monitoring Lot
   *
   * @param monitoringLotDotation {MonitoringLotDotation}
   * @returns {Object}
   */
  private extractMonitoringLot(monitoringLotDotation: MonitoringLotDotation): Object {
    return {
      id: monitoringLotDotation.id ? monitoringLotDotation.id : null,
      channel: monitoringLotDotation.channel && monitoringLotDotation.channel.id ? monitoringLotDotation.channel.id : null,
      program: monitoringLotDotation.program && monitoringLotDotation.program.id ? monitoringLotDotation.program.id : null,
      advertiser: monitoringLotDotation.advertiser && monitoringLotDotation.advertiser.id ? monitoringLotDotation.advertiser.id : null,
      product: monitoringLotDotation.product && monitoringLotDotation.product.id ? monitoringLotDotation.product.id : null,
      campaign: monitoringLotDotation.campaign && monitoringLotDotation.campaign.id ? monitoringLotDotation.campaign.id : null,
      lot: monitoringLotDotation.lot && monitoringLotDotation.lot.id ? monitoringLotDotation.lot.id : null,
      contractNumber: monitoringLotDotation.contractNumber ? monitoringLotDotation.contractNumber : null,
      bilanNumber: monitoringLotDotation.bilanNumber ? monitoringLotDotation.bilanNumber : null,
      parroption: monitoringLotDotation.parroption ? monitoringLotDotation.parroption : null,
      module: monitoringLotDotation.module ? monitoringLotDotation.module : null,
      periods: monitoringLotDotation.periods ? monitoringLotDotation.periods : null
    };
  }
}
