import { Program } from './program.resource';
import { Schedule } from './schedule/schedule.resource';
import { OfferProgram } from './offerProgram.resource';
import { Area } from './area.resource';
import { Base } from './base.resource';
import * as moment from 'moment';


export interface JsonBroadcast {
    id: number;
    start_time?: string;
    end_time?: string;
    start_time_label?: string;
    end_time_label?: string;
    area?: any;
    schedules?: any;
    offer_programs?: any;
    broadcast_purchased?: any;
    program?: any;
    broadcasts?: string;
    channel_Name?: string;
    programe_Name?: string;
    broadcast_type: number;
    broadcastIds?: string;
}

export class Broadcast extends Base {

    static readonly NEW = 'new';
    static readonly EDIT = 'edit';
    static readonly KEEP = 'keep';
    static readonly DELETE = 'delete';
    static readonly LINK = 'link';
    static readonly UNLINK = 'unlink';
    static readonly PATCH = 'patch';

    public id: number;
    public startTime: string;
    public endTime: string;
    public startTimeLabel: string;
    public endTimeLabel: string;
    public broadcastType: number;

    // relations
    public program: Program;
    public offerPrograms: OfferProgram[];
    public schedules: Schedule[];
    public area: Area;

    public broadcastPurchased: any[];

    // manage
    public action: string;
    public error: boolean;
    public warning: boolean;

    // broadcast
    public broadcasts: string;
    public channelName: string;
    public programeName: string;

    public broadcastIds: string;

    constructor(jsonBroadcast: JsonBroadcast) {
        super();
        jsonBroadcast = this.convertToUnderscore(jsonBroadcast);

        this.id = jsonBroadcast.id;
        this.startTime = moment(jsonBroadcast.start_time, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD'),
        this.endTime = moment(jsonBroadcast.end_time, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD'),
        this.startTimeLabel = jsonBroadcast.start_time_label;
        this.endTimeLabel = jsonBroadcast.end_time_label;
        this.broadcastType = jsonBroadcast.broadcast_type;

        this.setSchedules(jsonBroadcast);
        this.setOfferPrograms(jsonBroadcast);
        this.setBroadcastPurchased(jsonBroadcast);

        if (jsonBroadcast.channel_Name) {
          this.channelName = jsonBroadcast.channel_Name;
        }

        if (jsonBroadcast.programe_Name) {
          this.programeName = jsonBroadcast.programe_Name;
        }

        if (jsonBroadcast.area) {
            this.area = new Area(jsonBroadcast.area)
        }

        if (jsonBroadcast.program) {
            this.program = new Program(jsonBroadcast.program);
        }

        if (jsonBroadcast.broadcasts) {
          this.broadcasts = jsonBroadcast.broadcasts;
        }

        if (jsonBroadcast.broadcastIds) {
          this.broadcastIds = jsonBroadcast.broadcastIds;
        }
    }

    public addSchedule(schedule: Schedule) {
       let duplicate = this.schedules.filter(item => item.id === schedule.id);
        if (!duplicate.length) {
            this.schedules.push(schedule);
        }
    }

    public removeSchedule(scheduleToRemove: Schedule) {
        let result = [];
        this.schedules.forEach(schedule => {
            if (schedule.id !== scheduleToRemove.id) {
                result.push(schedule);
            }
        });

        this.schedules = result;
    }

    public removeOfferProgram(offerProgramToRemove: OfferProgram) {
        let result = [];
        this.offerPrograms.forEach(offerProgram => {
            if (offerProgram.id !== offerProgramToRemove.id) {
                result.push(offerProgram);
            }
        });

        this.offerPrograms = result;
    }

    public addOfferProgram(offerProgram: OfferProgram) {
        let duplicate = this.offerPrograms.filter(item => item.id === offerProgram.id);
        if (!duplicate.length) {
            this.offerPrograms.push(offerProgram);
        }
    }

    public setSchedules(jsonBroadcast) {
        this.schedules = [];
        if (jsonBroadcast.schedules) {
            jsonBroadcast.schedules.forEach(schedule => {
                this.schedules.push(new Schedule(schedule))
            });
        }
    }

    public setOfferPrograms(jsonBroadcast) {
        this.offerPrograms = [];
        if (jsonBroadcast.offer_programs) {
            jsonBroadcast.offer_programs.forEach(offerProgram => {
                this.offerPrograms.push(new OfferProgram(offerProgram))
            });
        }
    }

    public getOfferProgramIds() {
        let result = [];
        this.offerPrograms.forEach(offerProgram => {
            result.push(offerProgram.id)
        });
        return result;
    }

    public getScheduleIds() {
        let result = [];
        this.schedules.forEach(schedule => {
            result.push(schedule.id)
        });

        return result;
    }
    private setBroadcastPurchased(jsonBroadcast) {
        this.broadcastPurchased = [];
        if (jsonBroadcast.broadcast_purchased) {
            jsonBroadcast.broadcast_purchased.forEach(item => {
                this.broadcastPurchased.push(item)
            });
        }
    }
}
