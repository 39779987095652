
import {map,  catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { ScheduleService } from '../schedule/schedule.service';
import { OfferProgram } from '../../resource/offerProgram.resource';
import { BroadcastService } from '../broadcast/broadcast.service';
import { Schedule } from '../../resource/schedule/schedule.resource';

import { environment } from '../../../environments/environment';
import { OfferProgramBudgetPackage } from '../../resource/offerProgramBudgetPackage.resource';
import { Offer } from '../../resource/offer.resource';
import { SchedulePackage } from '../../resource/schedule/schedule-package.resource';
import { AbstractService } from '../abstract.service';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';
import { UtilitiesHandler } from '@service/utilities-handlers/utilitiesHandlers';

import { Observable } from 'rxjs';

import * as moment from 'moment';

@Injectable()
export class OfferProgramBudgetPackageService extends AbstractService {
  private route: string = 'offer_program_budget_package';
  private schedulePackages: SchedulePackage[] = [];
  private computedSchedulePackages: SchedulePackage[] = [];
  private offerProgramBudgetPackages: OfferProgramBudgetPackage[] = [];
  private counts = [];
  private offer: Offer;
  private offerProgram: OfferProgram;

  constructor(
    private scheduleService: ScheduleService,
    private broadcastService: BroadcastService,
    private customToastrService: CustomToastrService,
    private http: HttpClient,
    private utilitiesHandler: UtilitiesHandler) {
    super();
  }

  /**
   * Get the list of offer program budget package
   *
   * @param {Object} filters
   * @returns {Observable<OfferProgramBudgetPackage[]>}
   * @memberof OfferProgramBudgetPackageService
   */
  public getList(filters: Object): Observable<OfferProgramBudgetPackage[]> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params}).pipe(
      map((data: any) =>
        data._embedded.offer_program_budget_package
          .map((jsonResult: any) =>
            new OfferProgramBudgetPackage(jsonResult)
          )
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Edit offer prgram budget package
   *
   * @param {OfferProgramBudgetPackage} offerProgramBudgetPackage
   * @returns {Observable<Object>}
   * @memberof OfferProgramBudgetPackageService
   */
  edit(offerProgramBudgetPackage: OfferProgramBudgetPackage): Observable<Object> {
    const item = this.prepareToPut(offerProgramBudgetPackage);
    const api_base_url: string = `${environment.api_base_url}/${this.route}/${offerProgramBudgetPackage.id}`;

    return this.http
      .put(api_base_url, this.convertToUnderscore(item))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Post collection
   *
   * @param {OfferProgramBudgetPackage[]} collection
   * @returns {Observable<Object>}
   * @memberof OfferProgramBudgetPackageService
   */
  public postCollection(collection: OfferProgramBudgetPackage[]): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;

    return this.http
      .post(api_base_url, this.convertCollection(collection))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Prepare to put
   *
   * @param {*} data
   * @returns {Object}
   * @memberof OfferProgramBudgetPackageService
   */
  public prepareToPut(data): Object {
    return {
      id: data.id,
      price: data.price,
      quantity: data.quantity,
      duration: data.duration,
      advertising_package: {
          id: data.advertisingPackage.id
      },
      offer_program_budget: {
          id: data.offerProgramBudget.id
      }
    };
  }

  /**
   * Post offer program budget package
   *
   * @param {OfferProgramBudgetPackage} offerProgramBudgetPackage
   * @returns {Observable<Object>}
   * @memberof OfferProgramBudgetPackageService
   */
  public post(offerProgramBudgetPackage: OfferProgramBudgetPackage): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;

    return this.http
      .post(api_base_url, this.convertToUnderscore(offerProgramBudgetPackage))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Edit collection
   *
   * @param {OfferProgramBudgetPackage[]} offerProgramBudgetPackage
   * @returns {Observable<Object>}
   * @memberof OfferProgramBudgetPackageService
   */
  public editCollection(offerProgramBudgetPackage: OfferProgramBudgetPackage[]): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;

    return this.http
      .put(api_base_url, this.convertCollection(offerProgramBudgetPackage))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Loop edit
   *
   * @param {OfferProgramBudgetPackage[]} offerProgramBudgetPackage
   * @returns {Observable<Object>}
   * @memberof OfferProgramBudgetPackageService
   */
  public loopEdit(offerProgramBudgetPackage: OfferProgramBudgetPackage[]): Observable<Object> {
    let count = 0;
    return Observable.create(observer => {
      offerProgramBudgetPackage.forEach(item => {
        this.edit(item).subscribe(() => {
          if (this.checkCallCount(offerProgramBudgetPackage.length, ++count)) {
            observer.next();
          }
        }, (error) => {
          this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
        })
      })
    })
  }

  /**
   * Remove offer program budget package
   *
   * @param {OfferProgramBudgetPackage} offerProgramBudgetPackage
   * @returns {Observable<Object>}
   * @memberof OfferProgramBudgetPackageService
   */
  public remove(offerProgramBudgetPackage: OfferProgramBudgetPackage): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}/${offerProgramBudgetPackage.id}`;

    return this.http
      .delete(api_base_url)
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Manage offer program budget packages
   *
   * @param {*} offerProgramBudgetPackageList
   * @returns {Observable<any>}
   * @memberof OfferProgramBudgetPackageService
   */
  public manageOfferProgramBudgetPackages(offerProgramBudgetPackageList): Observable<any> {
    return Observable.create(observer => {
      let callCount = 0;
      let totalCalls = offerProgramBudgetPackageList.length;

      if (totalCalls === 0) {
        observer.next('noUpdate');
      }

      for (let offerProgramBudgetPackage of offerProgramBudgetPackageList) {
        if (offerProgramBudgetPackage.status === 'created' || offerProgramBudgetPackage.id == null ) {
          this.post(offerProgramBudgetPackage).subscribe(() => {
            if (this.checkCallCount(totalCalls, ++callCount)) {
              observer.next();
            }
          }, (error) => {
            this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
          });
        };

        if (offerProgramBudgetPackage.status === 'delete') {
          this.remove(offerProgramBudgetPackage).subscribe(() => {
            if (this.checkCallCount(totalCalls, ++callCount)) {
              observer.next();
            }
          }, (error) => {
            this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
          });
        }

        if (offerProgramBudgetPackage.status === 'edited' && offerProgramBudgetPackage.id != null) {
          this.edit(offerProgramBudgetPackage).subscribe(() => {
            if (this.checkCallCount(totalCalls, ++callCount)) {
              observer.next();
            }
          }, (error) => {
            this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
          });
        }
      }
    });
  }

  /**
   * Check if call is equal
   *
   * @param {*} total
   * @param {*} count
   * @returns {boolean}
   * @memberof OfferProgramBudgetPackageService
   */
  public checkCallCount(total, count): boolean {
    return total === count;
  }

  /**
   * Get update BA quantity
   *
   * @param {Object} baQuantities
   * @param {OfferProgramBudgetPackage} offerProgramBudgetPackage
   * @param {number} quantity
   * @returns {number}
   * @memberof OfferProgramBudgetPackageService
   */
  public getUpdatedBaQuantity(baQuantities: Object, offerProgramBudgetPackage:  OfferProgramBudgetPackage, quantity: number): number {
    if (!baQuantities[offerProgramBudgetPackage.id]) {
      baQuantities[offerProgramBudgetPackage.id] = 0;
    }
    baQuantities[offerProgramBudgetPackage.id] = baQuantities[offerProgramBudgetPackage.id] + quantity;
    let updatedBaQuantity = baQuantities[offerProgramBudgetPackage.id];

    return updatedBaQuantity;
  }

  /**
   * Get offer program budget to delete
   *
   * @returns {Observable<any>}
   * @memberof OfferProgramBudgetPackageService
   */
  public getOfferProgramBudgetToDelete(): Observable<any> {
    return Observable.create(observer => {
      for (let offerProgramBudgetPackage of this.offerProgramBudgetPackages) {
        let found = false;
        for (let schedulePackage of this.computedSchedulePackages) {
          if ((offerProgramBudgetPackage.advertisingPackage['id'] === schedulePackage.advertisingPackage['id']) &&
            (offerProgramBudgetPackage.duration === schedulePackage.duration)) {
            found = true;
          }
        }
        if (!found) {
          offerProgramBudgetPackage.status = 'delete';
        }
      }
      observer.next();
    });
  }

  /**
   * Get quantity
   *
   * @param {OfferProgramBudgetPackage} offerProgramBudgetPackage
   * @param {Array<Schedule>} schedules
   * @returns {number}
   * @memberof OfferProgramBudgetPackageService
   */
  public getQuantity(offerProgramBudgetPackage: OfferProgramBudgetPackage, schedules: Array<Schedule>): number {
    let quantity = 0;

    schedules.forEach(schedule => {
      schedule.schedulePackages.forEach(schedulePackage => {
        if ((offerProgramBudgetPackage.advertisingPackage['id'] === schedulePackage.advertisingPackage['id'])
          && (offerProgramBudgetPackage.duration === schedulePackage.parent.duration)) {
            quantity += offerProgramBudgetPackage.quantity
        }
      });
    });
    return quantity;
  }

  /**
   * Set offer
   *
   * @param {Offer} offer
   * @returns {void}
   * @memberof OfferProgramBudgetPackageService
   */
  public setOffer(offer: Offer): void {
    this.offer = offer;
  }

  /**
   * Get marketing base day count
   *
   * @private
   * @param {SchedulePackage} schedulePackage
   * @param {*} offerProgramId
   * @returns {Observable<any>}
   * @memberof OfferProgramBudgetPackageService
   */
  private getMarketingBaseDayCount(schedulePackage: SchedulePackage, offerProgramId): Observable<any> {
    return Observable.create(observer => {
      let offer = this.offer;

      switch (offer.marketingBaseType) {
        case Offer.WEEKS:

          this.scheduleService
            .get(schedulePackage.schedule['id'])
            .subscribe(schedule => {
              observer.next(offer.marketingBaseNumber * schedule.getNumberOfSelectedDays());
            }, (error) => {
              this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            });

          break;

        case Offer.DAYS:
        case Offer.PROGRAMS:

          observer.next(offer.marketingBaseNumber);

          break;
        case Offer.TOTAL:
          this.broadcastService.getList({schedule_id: schedulePackage.schedule['id'], groups: 'level0'})
            .subscribe(broadcasts => {
              broadcasts.forEach(() => {
                observer.next(broadcasts.length);
              });
            }, (error) => {
              this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            });
          break;
        default:
          observer.next(1);
          break;
      }
    })
  }

  /**
   * Get week count
   *
   * @private
   * @param {SchedulePackage} schedulePackage
   * @returns {Observable<any>}
   * @memberof OfferProgramBudgetPackageService
   */
  private getWeekCount(schedulePackage: SchedulePackage): Observable<any> {
    return Observable.create(observer => {
      let weekNumber = 0;
      let arrayWeek = [];
      this.broadcastService.getList({schedule_id: schedulePackage.schedule['id'], groups: 'level0'})
      .subscribe(broadcasts => {
        broadcasts.forEach(broadcast => {
          weekNumber = moment(broadcast.startTime, 'YYYY-MM-DD').isoWeek();
          if (arrayWeek.indexOf(weekNumber) === -1) {
            arrayWeek.push(moment(broadcast.startTime, 'YYYY-MM-DD').isoWeek());
          }
        });
        observer.next(arrayWeek.length);
      }, (error) => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });
    });
  }

  /**
   * Get counts
   *
   * @private
   * @returns {Observable<any>}
   * @memberof OfferProgramBudgetPackageService
   */
  private getCounts(): Observable<any> {
    return Observable.create(observer => {
      let result = [];

      this.schedulePackages.forEach((schedulePackage: SchedulePackage) => {
        let index = this.getIndexCount(schedulePackage);

        if (!result[index]) {
          result[index] = [];
          result[index] = 0;
        }

        result[index] = result[index] + (schedulePackage.schedule.broadcastCount * schedulePackage.quantity);
      });

      observer.next(result);
    });
  }

  /**
   * Computer offer program budget list
   *
   * @returns {void}
   * @private
   * @memberof OfferProgramBudgetPackageService
   */
  private computeOfferProgramBudgetList(): void {
    this.schedulePackages.forEach(
        schedulePackage => {
          if (this.containsSchedulePackage(this.computedSchedulePackages, schedulePackage) === null) {
            this.computedSchedulePackages.push(schedulePackage);
          }
      }
    );
  }

  /**
   * Contains schedule package
   *
   * @private
   * @param {SchedulePackage[]} list
   * @param {SchedulePackage} item
   * @returns {SchedulePackage}
   * @memberof OfferProgramBudgetPackageService
   */
  private containsSchedulePackage(list: SchedulePackage[], item: SchedulePackage): SchedulePackage {
    for (let schedulePackage of list) {
      if ((schedulePackage.advertisingPackage['id'] === item.advertisingPackage['id'])
        && (schedulePackage.duration === item.duration)) {
        return item;
      }
    }
    return null;
  }

  /**
   * Add offer program budget package
   *
   * @private
   * @param {*} quantity
   * @param {*} schedulePackage
   * @param {*} offerProgram
   * @memberof OfferProgramBudgetPackageService
   */
  private addOfferProgramBudgetPackage(quantity, schedulePackage, offerProgram): void {
    let newOfferProgramBudgetPackage = new OfferProgramBudgetPackage({
        id: null,
        quantity: quantity,
        price: 0,
        duration: schedulePackage.duration,
        advertising_package: {
          id: schedulePackage.advertisingPackage['id']
        },
        offer_program_budget: {
          id: offerProgram.offerProgramBudgets[0].id
        }
      }
    );

    newOfferProgramBudgetPackage.status = 'created';

    delete newOfferProgramBudgetPackage.offerProgramBudget.utilitiesService;
    delete newOfferProgramBudgetPackage.offerProgramBudget.price;
    delete newOfferProgramBudgetPackage.offerProgramBudget.currency;
    delete newOfferProgramBudgetPackage.offerProgramBudget.offerProgram;
    delete newOfferProgramBudgetPackage.advertisingPackage.game;
    delete newOfferProgramBudgetPackage.advertisingPackage.name;
    delete newOfferProgramBudgetPackage.advertisingPackage.type;
    delete newOfferProgramBudgetPackage.theoricalPresence;

    this.offerProgramBudgetPackages.push(newOfferProgramBudgetPackage);
  }

  /**
   * Get index count
   *
   * @private
   * @param {SchedulePackage} schedulePackage
   * @returns {string}
   * @memberof OfferProgramBudgetPackageService
   */
  private getIndexCount(schedulePackage: SchedulePackage): string {
    let advertisingPackageName = schedulePackage.advertisingPackage['name'];
    let duration = schedulePackage.duration;

    return advertisingPackageName + '-' + duration;
  }

  /**
   * Reset service
   *
   * @private
   * @returns {void}
   * @memberof OfferProgramBudgetPackageService
   */
  private resetService(): void {
    this.schedulePackages = [];
    this.offerProgramBudgetPackages = [];
    this.computedSchedulePackages = [];
    this.counts = [];
    this.offer = null;
    this.offerProgram = null;
  }
}
