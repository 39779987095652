<h2 fxLayoutAlign="center center">
  Ajouter un signataire inexistant
</h2>
<form fxLayout="column" fxLayoutAlign="center center" [formGroup]="signatory">
    <mat-radio-group class="full-width margin-bottom" formControlName="civility" required>
      <mat-radio-button value="M">Monsieur</mat-radio-button>
      <mat-radio-button value="Mme">Madame</mat-radio-button>
    </mat-radio-group>
    <mat-form-field class="full-width">
      <input type="text" matInput placeholder="Nom" formControlName="lastName" required>
    </mat-form-field>
    <mat-form-field class="full-width">
      <input type="text" matInput placeholder="Prénom" formControlName="firstName" required>
    </mat-form-field>
    <div class="full-width" fxLayout="row" fxLayoutAlign="space-around center">
      <button mat-flat-button class="button-flat black" (click)="close()">
        <span class="white-text">Annuler</span>
      </button>
      <button mat-flat-button class="button-flat red" type="submit" (click)="save()" [disabled]="!signatory.valid">
        <span class="white-text">Enregistrer</span>
      </button>
    </div>
</form>
