<mat-progress-bar  *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<form [formGroup]="soReachForm.get('periodsBudgets')['controls'][selectedPeriodIndex]" class="full-width">
  <div fxLayout="column" fxLayoutAlign="start center" >
    <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center" class="full-width">
      <div fxFlex="50">
        <mat-form-field class="full-width">
          <input
          matInput
          [matDatepicker]="du"
          placeholder="Du"
          formControlName="startDate">
          <mat-datepicker-toggle matSuffix [for]="du"></mat-datepicker-toggle>
          <mat-datepicker #du></mat-datepicker>
        </mat-form-field>
      </div>
      <div fxFlex="50">
        <mat-form-field class="full-width">
          <input
          matInput
          [matDatepicker]="au"
          placeholder="Au"
          formControlName="endDate">
          <mat-datepicker-toggle matSuffix [for]="au"></mat-datepicker-toggle>
          <mat-datepicker #au></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="center start">
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" class="full-width">
        <img class="list-logo" [src]="path + '2.png'">
        <mat-form-field class="full-width-icon">
          <input
            matInput
            type="number"
            formControlName="f2Budget">
          <mat-icon class="euro" matSuffix>euro</mat-icon>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" class="full-width">
        <img class="list-logo" [src]="path + '3.png'">
        <mat-form-field class="full-width-icon">
          <input
            matInput
            type="number"
            formControlName="f3Budget">
          <mat-icon class="euro" matSuffix>euro</mat-icon>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" class="full-width">
        <img class="list-logo" [src]="path + '4.png'">
        <mat-form-field class="full-width-icon">
          <input
            matInput
            type="number"
            formControlName="f4Budget">
          <mat-icon class="euro" matSuffix>euro</mat-icon>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" class="full-width">
        <img class="list-logo" [src]="path + '5.png'">
        <mat-form-field class="full-width-icon">
          <input
            matInput
            type="number"
            formControlName="f5Budget">
          <mat-icon class="euro" matSuffix>euro</mat-icon>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" class="full-width">
        <img class="list-logo" [src]="path + 'o.png'">
        <mat-form-field class="full-width-icon">
          <input
            matInput
            type="number"
            formControlName="foBudget">
          <mat-icon class="euro" matSuffix>euro</mat-icon>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center">
        <span class="thin bold">TOTAL</span>
        <mat-form-field class="full-width-text">
          <input
            matInput
            type="number"
            formControlName="budgetTotal"
            readonly="true">
          <mat-icon class="euro" matSuffix>euro</mat-icon>
        </mat-form-field>
      </div>
    </div>
  </div>
</form>
