
import {take, takeUntil, combineLatest,  map, startWith, filter, debounceTime, tap } from 'rxjs/operators';
import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable ,  Subject } from 'rxjs';
import { MatAutocomplete } from '@angular/material';
import { Employee } from '../../../../resource/employee.resource';
import { FilteredItem } from '../../../../resource/filteredItem.resource';
import { Channel } from '../../../../resource/channel.resource';

import { COMMA, ENTER } from '@angular/cdk/keycodes';

import { CustomToastrService } from '@service/toastr/custom-toastr.service';
import { AdvertiserService } from '../../../../service/advertiser/advertiser.service';
import { AreaService } from '../../../../service/area/area.service';
import { ChannelService } from '../../../../service/channel/channel.service';
import { CustomerService } from '../../../../service/customer/customer.service';
import { EmployeeService } from '../../../../service/employee/employee.service';
import { ProgramService } from '../../../../service/program/program.service';
import { PurchaseService } from '../../../../service/purchase/purchase.service';
import { AutocompleteService } from '../../../../service/utilities/autocomplete.service';
import { FiltersService } from '../../../availability/service/filters.service';
import { PurchaseTypeService } from '../../../../service/purchase-type/purchase-type.service';
import { ProductService } from '@service/product/product.service';

import { InputFilterExtendsComponent } from 'src/app/shared/class/input-filter-extends/input-filter-extends.component';
import * as moment from 'moment';

import 'moment/locale/fr';
import { Advertiser } from '../../../../resource/advertiser.resource';


@Component({
  selector: 'app-purchase-list-filter',
  templateUrl: './purchase-list-filter.component.html',
  styleUrls: ['./purchase-list-filter.component.scss'],
  providers: [ FiltersService ],
})
export class PurchaseListFilterComponent extends InputFilterExtendsComponent implements OnInit {
  @Input() loading: boolean;
  @Output() queryParams = new EventEmitter<string>();
  @Output() getPurchase = new EventEmitter<string>();

  programsCtrl = new FormControl();
  channelsCtrl = new FormControl();
  areasCtrl = new FormControl();
  product_idCtrl = new FormControl();
  advertiser_or_customerCtrl = new FormControl();
  purchase_type_idCtrl = new FormControl();
  userCtrl = new FormControl();

  public filters;
  public channelsFilterOptions;
  public productFilterOptions;
  public areasFilterOptions;
  public advertiser_or_customerFilterOptions;
  public purchase_type_idFilterOptions;
  public userFilterOptions;
  public localization: Object;
  public logoPath: string = Channel.PATH;

  public employeeItems: FilteredItem[];
  public channelItems: FilteredItem[];
  public areaItems: FilteredItem[];

  public purchaseTypeItems: FilteredItem[];
  public purchaseFilterGroup: FormGroup;
  public startCommunicationPeriod: FormControl;
  public endCommunicationPeriod: FormControl;

  public disableArea: boolean = true;

  mergeStream1 = [];

  public filteredEmployeeItem: Observable<FilteredItem[]>;
  filteredPrograms: Observable<any[]>;
  filteredChannels: Observable<any[]>;
  filteredAreas: Observable<any[]>;
  filteredAdvertiser_or_customer: Observable<any[]>;
  filteredProduct_id: Observable<any[]>;
  filteredPurchase_type_id: Observable<any[]>;
  filteredUser: Observable<any[]>;

  separatorKeysCodes: number[] = [ENTER, COMMA];

  loadingInput: any;
  public path: string;
  public defaultLogo = 'default.gif';

  @ViewChild('autoProgram') matAutocompletePrograms: MatAutocomplete;
  @ViewChild('autoChannel') matAutocompleteChannels: MatAutocomplete;
  @ViewChild('autoArea') matAutocompleteAreas: MatAutocomplete;
  @ViewChild('autoAdvertiser') matAutocompleteAdvertiser_or_customer: MatAutocomplete;
  @ViewChild('autoProduct') matAutocompleteProduct_id: MatAutocomplete;
  @ViewChild('autoPurchase') matAutocompletePurchase_type_id: MatAutocomplete;
  @ViewChild('autoUser') matAutocompleteUser: MatAutocomplete;
  @ViewChild('programInput') programsInput: ElementRef<HTMLInputElement>;
  @ViewChild('channelInput') channelsInput: ElementRef<HTMLInputElement>;
  @ViewChild('areaInput') areasInput: ElementRef<HTMLInputElement>;
  @ViewChild('advertiserInput') advertiser_or_customerInput: ElementRef<HTMLInputElement>;
  @ViewChild('purchaseInput') purchase_type_idInput: ElementRef<HTMLInputElement>;
  @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;
  @ViewChild('product_idInput') product_idInput: ElementRef<HTMLInputElement>;

  private componentDestroyed: Subject<any> = new Subject();

  constructor(public filtersService: FiltersService,
              private autocompleteService: AutocompleteService,
              private channelService: ChannelService,
              private areaService: AreaService,
              private customToastrService: CustomToastrService,
              private employeeService: EmployeeService,
              private customerService: CustomerService,
              private advertiserService: AdvertiserService,
              private purchaseService: PurchaseService,
              private productService: ProductService,
              private programService: ProgramService,
              private purchaseTypeService: PurchaseTypeService) {
                super(filtersService);
                this.filtersService = filtersService;
                this.path = 'assets/images/logos/channels/';
                this.loadingInput = {
                  channel: true,
                  area: true,
                  category_id: true,
                  advertiser: false,
                  user: false,
                  program: false,
                  offer: false,
                  status: true,
                  product: false,
               }
    }

  ngOnInit() {
    this.filters = this.filtersService.initFilters();
    this.filters.offer_from = null;
    this.filters.offer_to = null;
    this.localization = this.filtersService.getI18iForDatePicker();
    this.onQueryParams();
    this.startCommunicationPeriod = new FormControl(moment(this.filters.start_communication_period, 'YYYY-MM-DD'));
    this.endCommunicationPeriod = new FormControl(moment(this.filters.end_communication_period, 'YYYY-MM-DD'));

    this.getChannelOptionsFilter();
    this.getProgramOptionsFilter();
    this.getAreaOptionsFilter();
    this.getAdvertiserOrCustomerOptionsFilter();
    this.getPurchaseStatutOptionsFilter();
    this.getEmployeeOptionsFilter();
    this.getProductOptionsFilter();
  }

  public onQueryParams() {
    this.queryParams.emit(this.filtersService.getQueryParams(this.filters));
  }

  public updateFilters() {
    this.filters.broadcasted_from = moment(this.startCommunicationPeriod.value).format('YYYY-MM-DD');
    this.filters.broadcasted_to = moment(this.endCommunicationPeriod.value).format('YYYY-MM-DD');
    this.filters.start_communication_period = moment(this.startCommunicationPeriod.value).format('YYYY-MM-DD');
    this.filters.end_communication_period = moment(this.endCommunicationPeriod.value).format('YYYY-MM-DD');
    this.onQueryParams();
  }

  getEmployeeOptionsFilter(): void {

    this.userCtrl.valueChanges
      .pipe(
        debounceTime(500),
        filter((item) => item),
        takeUntil(this.componentDestroyed)
      )
      .subscribe((data) => {
        let filtersUser: Object = {
          commercial_cell_id : Employee.commercialCell.join(';'),
          user : data
        };

        this.loadingInput['user'] = true;
        this.filteredUser = this.employeeService.getListForFilter(filtersUser)
          .pipe(
            map((response) => {
              this.loadingInput['user'] = false;
              return response;
            })
          )
      });
    }

  public getLogoByValue(item): string {
    let channel =  this.channelsFilterOptions.find(
      function(elem) {
        return elem.display === item.display;
      });

    return this.logoPath + channel.logo;
  }

  getProgramOptionsFilter(): void {

    this.programsCtrl.valueChanges
      .pipe(
        debounceTime(500),
        filter((item) => item),
        takeUntil(this.componentDestroyed)
      )
      .subscribe((data) => {
        this.loadingInput['program'] = true;
        let filterList: Object = {
          broadcasted_from: this.filters.broadcasted_from,
          broadcasted_to: this.filters.broadcasted_to,
          name: data.toUpperCase()
        };

        if (this.filters.offer_id) {
          filterList['offer_id'] = this.filters.offer_id;
        }

        if (this.filters.channels && this.filters.channels.length > 0) {
          filterList['channel_id'] = '';
          this.filters.channels.forEach((element, index) => {
            filterList['channel_id'] +=  element.value;
            if (index !== this.filters.channels.length - 1 ) {
              filterList['channel_id'] += ';';
            }
          });
        }

        if (this.filters.areas && this.filters.areas.length > 0) {
          filterList['area_id'] = '';
           this.filters.areas.forEach((element, index) => {
            filterList['area_id'] +=  element.value;
            if (index !== this.filters.areas.length - 1 ) {
              filterList['area_id'] += ';';
            }
          });
        }

        this.filteredPrograms = this.programService.getListForFilter(filterList).pipe(
          map((response) => {
            this.loadingInput['program'] = false;
            return response;
          }));
      });
    }

  getAdvertiserOrCustomerOptionsFilter(): void {
    /*
    search by :
    - advertiser name id
    - customer name or id
    - temporary info on purchase (advertiser, mandatary & sub mandatary)
     */
     this.advertiser_or_customerCtrl.valueChanges
      .pipe(
        debounceTime(500),
        filter((item) => item),
        takeUntil(this.componentDestroyed)
      )
      .subscribe((data) => {
        const filterAdvertiser = Number(data) ? { id_purchased: data.toUpperCase() } : { name_purchased: data.toUpperCase()};
        const filterAdvertiserTemporary: Object = {
          advertiser_name: data.toUpperCase()
        };
        const filterMandataryTemporary: Object = {
          mandatary_name: data.toUpperCase()
        };
        const filterSubMandataryTemporary: Object = {
          sub_mandatary_name: data.toUpperCase()
        };

        this.loadingInput['advertiser'] = true;

        const obsAdvertiser = this.advertiserService.getListForAutoComplete(filterAdvertiser);
        const obsCustomer = this.customerService.getListForAutoComplete(filterAdvertiser);
        const obsPurchaseAdvertiserTemporary = this.purchaseService.getListForAutoCompleteAdvertiser(filterAdvertiserTemporary);
        const obsPurchaseMandataryTemporary = this.purchaseService.getListForAutoCompleteMandatary(filterMandataryTemporary);
        const obsPurchaseSubMandataryTemporary = this.purchaseService.getListForAutoCompleteSubMandatary(filterSubMandataryTemporary);


        this.filteredAdvertiser_or_customer = obsAdvertiser.pipe(combineLatest(obsCustomer,
          obsPurchaseAdvertiserTemporary, obsPurchaseMandataryTemporary, obsPurchaseSubMandataryTemporary),
           map(([rslt1, rslt2, rslt3, rslt4, rslt5]) => {
              this.loadingInput['advertiser'] = false;
              return rslt1.concat(rslt2)
                .concat(rslt3)
                .concat(rslt4)
                .concat(rslt5)
                .sort(this.compareArrayDisplay)
           }));
      })
  }

  compareArrayDisplay(a, b): number {
    if (a['display'] < b['display']) {
      return -1;
    }
    if (a['display'] > b['display']) {
      return 1;
    }
    return 0;
  }

  getProductOptionsFilter(): void {
    this.product_idCtrl.valueChanges
      .pipe(
        debounceTime(500),
        filter((item) => item),
        takeUntil(this.componentDestroyed)
      )
      .subscribe((data) => {
        this.loadingInput['product'] = true;
        const filterList: Object = Number(data) ? { product_id: data } : { name: data.toUpperCase()};
        if (this.filters.broadcasted_from !== 'Invalid date' )  {
        filterList['broadcasted_from'] = this.filters.broadcasted_from;
        }

        if (this.filters.broadcasted_to !== 'Invalid date') {
        filterList['broadcasted_to'] = this.filters.broadcasted_to;
        }

      this.filteredProduct_id = this.productService.getListForAutoCompleteProduct(filterList).pipe(
          map((response) => {
            this.loadingInput['product'] = false;
            return response;
          }));
      });
    }

  getChannelOptionsFilter(): void {
    this.channelService.getListForFilter().pipe(
      takeUntil(this.componentDestroyed))
      .subscribe(channels => {
          this.channelsFilterOptions = channels;
          this.loadingInput['channel'] = false;
          this.filteredChannels = this.channelsCtrl.valueChanges.pipe(
            startWith(null),
            map((channel) => channel ? this._filter(channel, 'channels') : this.channelsFilterOptions.slice()));
      }, error => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });
    }

    updateAreaOptionsFilter() {
      if (!this.filtersService.isFr3Selected(this.filters)) {
        this.filters.areas = [];
        this.areasFilterOptions = [];
      } else {
          let fr3ChannelId = 3;
          this.getAreaOptionsFilter(fr3ChannelId);
      }
    }

    getAreaOptionsFilter(channelId?) {
      this.areaService.getListForFilter().pipe(
      takeUntil(this.componentDestroyed))
      .subscribe(areas => {
          this.areasFilterOptions = areas;
          this.loadingInput['area'] = false;
          this.filteredAreas = this.areasCtrl.valueChanges.pipe(
            startWith(null),
            map((area) => area ? this._filter(area, 'areas') : this.areasFilterOptions.slice()));
      });
    }

    getPurchaseStatutOptionsFilter(): void {
      this.purchaseTypeService.getListForFilter().pipe(
        takeUntil(this.componentDestroyed))
        .subscribe(purchases => {
            this.purchase_type_idFilterOptions = purchases;
            this.filteredPurchase_type_id = this.purchase_type_idCtrl.valueChanges.pipe(
              startWith(null),
              map((purchase) => {
                return purchase ? this._filter(purchase, 'purchase_type_id') : this.purchase_type_idFilterOptions.slice();
              })
            );
            if ( this.loadingInput['status']) {
              this.setPurchaseTypeFilter();
            }
            this.loadingInput['status'] = false;
        }, (error) => {
          this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
        });
      }

  search(): void {
    this.getPurchase.emit();
  }

  private setEmployeeFilter(value: any): void {
    this.filters.user = value;
    this.onQueryParams();
  }

  private setPurchaseTypeFilter(): void {
    this.filters.purchase_type_id = new Array();

    this.filteredPurchase_type_id.pipe(take(1)).subscribe(items => {
      items.forEach(element => {
        if ((element.value === '1') || (element.value === '2') || (element.value === '5') || (element.value === '6')) {
          this.filters.purchase_type_id.push(element);
        }
      });
    })
  }
}
