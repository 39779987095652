import { Component, OnInit, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';

import { CustomToastrService } from '@service/toastr/custom-toastr.service';
import { PurchaseService } from '@service/purchase/purchase.service';
import { PurchaseAbatementService } from '@service/purchase-abatement/purchase-abatement.service';
import { AbatementService } from '@service/abatement/abatement.service';
import { MathService } from '@service/utilities/math.service';

import { PurchaseAbatement } from '../../../../resource/purchase-abatement.resource';
import { Abatement } from '../../../../resource/abatement.resource';
import { Purchase } from '../../../../resource/purchase.resource';

import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, debounceTime, takeUntil } from 'rxjs/operators';

import * as moment from 'moment';

@Component({
  selector: 'app-abatement',
  templateUrl: './abatement.component.html',
  styleUrls: ['./abatement.component.scss']
})
export class AbatementComponent implements OnInit {

  @Input() purchase: Purchase;
  @Input() dialogRef;

  public abatements: Abatement[];
  public purchaseAbatements: PurchaseAbatement[];
  public abatementFilters: any;
  public purchaseAbatementFilters: any;

  public loadingAbatement: boolean;
  public saving: boolean;

  public group: String;

  public caini: Number;
  public caref: Number;
  public budget: Number;

  public form: FormGroup;

  public displayedColumns: string[] = ['name', 'code', 'rate', 'isFixedLabel'];
  public dataSource: MatTableDataSource<Abatement> = new MatTableDataSource<Abatement>();

  private componentDestroyed$: Subject<any> = new Subject();

  constructor(
    private abatementService: AbatementService,
    private purchaseAbatementService: PurchaseAbatementService,
    private purchaseService: PurchaseService,
    private _fb: FormBuilder,
    private mathService: MathService,
    private customToastrService: CustomToastrService
  ) {}

  ngOnInit() {
    this.initForm();
    this.initFilters();
    this.getAbatements()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        this.updateAbatements();
        this.populateForm();
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.form
      .valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(data => {
        this.updateForm(data);
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.purchaseAbatementService.removePurchaseAbatementSource$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(code => {
        this.removePurchaseAbatementByCode(code);
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });
  }

  // LIST ACTIONS

  public addAbatement(abatement: Abatement, group: String): PurchaseAbatement {
    let purchaseAbatement = this.purchaseAbatementService.createPurchaseAbatement(abatement, this.purchase, group);
    purchaseAbatement.channelGroup = this.group;

    let duplicatedAbatement = this.purchaseAbatements.filter(purchaseAbatementData =>
      purchaseAbatementData.abatement.id === abatement.id
    );

    if (duplicatedAbatement.length) {
      this.customToastrService.displayToastr('WARNING', 'Cet abattement est déjà utilisé');
      return null;
    }

    let duplicatedAbatementUnit = this.purchaseAbatements.filter(purchaseAbatementData =>
      purchaseAbatementData.abatement.abatementOrder === abatement.abatementOrder
        && purchaseAbatementData.abatement.category === abatement.category
    );

    if (duplicatedAbatementUnit.length) {
      this.customToastrService.displayToastr('WARNING', 'Cet abattement ne peut pas être ajouté');
      return null;
    }

    this.purchaseAbatements.push(purchaseAbatement);
    this.updateAbatements();
    this.addItem(purchaseAbatement);
    return purchaseAbatement;
  }

  public save() {
    if (this.saving || this.loadingAbatement) {
      return null;
    }

    this.saving = true;

    let count = 0;
    this.purchaseAbatementService.save(this.purchase, this.purchaseAbatements, this.group)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        if (++count === 3) {
          this.purchaseService.editPurchase(this.purchase)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe((purchase: Purchase) => {
              this.purchase = purchase;
              this.purchaseAbatementService.loadPurchaseAbatements(this.purchase, this.group)
                .pipe(takeUntil(this.componentDestroyed$))
                .subscribe(() => {
                  this.customToastrService.displayToastr('SUCCESS', 'Enregitrement effectué');
                  this.dialogRef.close(this.purchase);
                  this.saving = false;
                }, () => {
                  this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
                });
            }, () => {
              this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            });
        }
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });
  }

  private initFilters() {
    this.abatementFilters = {
      start_time: moment(this.purchase.startCommunicationPeriod, 'YYYY-MM-DD').startOf('year').format('YYYY-MM-DD'),
      end_time: moment(this.purchase.startCommunicationPeriod, 'YYYY-MM-DD').endOf('year').format('YYYY-MM-DD'),
    };

    this.purchaseAbatementFilters = {
      purchase_id: this.purchase.id,
    };
  }

  private getAbatements(): Observable<any> {
    return new Observable(observer => {
      this.loadingAbatement = true;
      this.abatementService
        .getList(this.abatementFilters)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(abatements => {
          this.loadingAbatement = false;
          let filteredAbatements = abatements.filter(abatement => abatement.category !== 35);
          this.abatements = this.abatementService.sortAbatements(filteredAbatements);
          this.dataSource.data = this.abatements;
          observer.next();
        }, () => {
          this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
        });
    });
  }

  private getNCPPAbatement(): Abatement {
    let filteredAbatements = this.abatements.filter(abatement => abatement.code === 'NCPP');
    if ((filteredAbatements) && (filteredAbatements[0])) {
        return filteredAbatements[0];
    } else {
      return null;
    }
  }

  private updateAbatements() {
    this.abatements.forEach((abatement: Abatement) => {
      let isSelected = false;
      let selected = this.purchaseAbatements.filter(item => item.abatement.code === abatement.code);
        if (selected.length) {
          isSelected = true;
        }
        abatement.isSelected = isSelected;
    });
    this.dataSource.data = this.abatements;
  }

  private removePurchaseAbatementByCode(code: String) {
    let purchaseAbatements = [];
    this.purchaseAbatements.forEach(purchaseAbatement => {
      if (purchaseAbatement.abatement.code !== code) {
        purchaseAbatements.push(purchaseAbatement);
      }
    });
    this.purchaseAbatements = purchaseAbatements;
    this.updateAbatements();
  }

  private removeAbatementControl(controlArrayName: string, codeAbatement: string) {
    const control = <FormArray>this.form.controls[controlArrayName];
    control.controls.forEach((item, index) => {
      if (item.get('abatement').value.code === codeAbatement) {
        control.removeAt(index);
      }
    });
  }


  // FORM ACTIONS

  private initForm() {
    this.form = this._fb.group({
        tvBudget: this._fb.array([]),
        caRef: this._fb.array([]),
        caFact: this._fb.array([]),
    });
  }

  private populateForm() {
    this.purchaseAbatements
      .sort((a, b) => a.purchaseAbatementOrder - b.purchaseAbatementOrder)
      .forEach(purchaseAbatement => {
      this.addItem(purchaseAbatement);
    });
  }

  private addItem(item: PurchaseAbatement) {
    let group = null;

    switch (item.abatement.category) {
        case (Abatement.CATEGORY_5):
          group = PurchaseAbatement.TV_BUDGET_UNIT;
        break;
        case (Abatement.CATEGORY_10):
          group = PurchaseAbatement.CA_REF_UNIT;
        break;
        case (Abatement.CATEGORY_20):
          group = PurchaseAbatement.CA_FACT_UNIT;
        break;
    }

    if (!group) {
      this.customToastrService.displayToastr('WARNING', 'Catégorie d\'abattement de type 35');
      return null;
    }

    const control = <FormArray>this.form.controls[group];
    control.push(this.initItem(item));
  }

  private initItem(item: PurchaseAbatement) {
    return this._fb.group({
        id: item.id,
        rate: item.rate,
        amount: item.amount,
        base: item.base,
        purchaseAbatementOrder: item.purchaseAbatementOrder,
        abatement: item.abatement
    });
  }

  /**
   * Remove item who isSelected === false
   *
   * @param data
   */
  private verificationData(data) {
    data.caFact = data.caFact.filter((item) => {
      return item && item.abatement && (!('isSelected' in item.abatement) || item.abatement.isSelected === true);
    });
    data.caRef = data.caRef.filter((item) => {
      return item && item.abatement && (!('isSelected' in item.abatement) || item.abatement.isSelected === true);
    });
    data.tvBudget = data.tvBudget.filter((item) => {
      return item && item.abatement && (!('isSelected' in item.abatement) || item.abatement.isSelected === true);
    });
    return data;
  }

  private updateForm(data) {
    // 0. Verification data
    data = this.verificationData(data);
    // 1. totalBudget -- cascade
    let totalBudgetAbatements = data.tvBudget;

    // Default value 'F'
    let totalBudget = this.purchase.tvClassicBudget - this.purchase.iniFact;

    if (this.group === 'FREG') {
      totalBudget = this.purchase.tvRegionBudget - this.purchase.iniFactRegion;
    } else if (this.group === 'O') {
      totalBudget = this.purchase.tvDomtomBudget - this.purchase.iniFactDomtom;
    } else if (this.group === 'W') {
      totalBudget = this.purchase.tvThemaBudget - this.purchase.iniFactThema;
    } else if (this.group === 'I') {
      totalBudget = this.purchase.tvInterBudget - this.purchase.iniFactInter;
    } else if (this.group === 'F4') {
      totalBudget = this.purchase.tvF4Budget - this.purchase.iniFactF4;
    }

    totalBudgetAbatements.forEach((purchaseAbatement, index) => {
      let rate = purchaseAbatement.rate;
      let amount = this.mathService.getRound(totalBudget * rate / 100, 2);

      purchaseAbatement.amount = amount;
      purchaseAbatement.base = totalBudget;
      purchaseAbatement.purchaseAbatementOrder = index;
      totalBudget = totalBudget + amount;

      this.updatePurchaseAbatement(purchaseAbatement);
    });

    // Update purchase caRefBudget
    if (this.group === 'F') {
      this.purchase.caRef = totalBudget;
    } else if (this.group === 'FREG') {
      this.purchase.caRefRegion = totalBudget;
    } else if (this.group === 'O') {
      this.purchase.caRefDomtom = totalBudget;
    } else if (this.group === 'W') {
      this.purchase.caRefThema = totalBudget;
    } else if (this.group === 'I') {
      this.purchase.caRefInter = totalBudget;
    } else if (this.group === 'F4') {
      this.purchase.caRefF4 = totalBudget;
    }

    // 2. update referencialBudget -- no cascade
    let caFact = totalBudget;
    let caRef = totalBudget;
    let caRefAbatements = data.caRef;

    caRefAbatements.forEach((purchaseAbatement, index) => {
      let rate = purchaseAbatement.rate;
      let amount = this.mathService.getRound(caRef * rate / 100, 2);
      purchaseAbatement.amount = amount;
      purchaseAbatement.base = caRef;
      purchaseAbatement.purchaseAbatementOrder = index;
      caFact = caFact + amount;

      this.updatePurchaseAbatement(purchaseAbatement);
    });

    // Default caFact budget
    if (this.group === 'F') {
      this.purchase.caFact = caFact;
    } else if (this.group === 'FREG') {
      this.purchase.caFactRegion = caFact;
    } else if (this.group === 'O') {
      this.purchase.caFactDomtom = caFact;
    } else if (this.group === 'W') {
      this.purchase.caFactThema = caFact;
    } else if (this.group === 'I') {
      this.purchase.caFactInter = caFact;
    } else if (this.group === 'F4') {
      this.purchase.caFactF4 = caFact;
    }

    // 3. update beforeSoldedBudget --cascade
    let caFactAbatement = data.caFact;
    let purchaseAbatementNCPP = null;
    let maxIndexCat20 = 0;
    let caNetBeforeNCPP = caFact;
    totalBudget = caFact;

    caFactAbatement.forEach((purchaseAbatement, index) => {
      let rate = purchaseAbatement.rate;
      let amount: number = this.mathService.getRound(totalBudget * rate / 100, 2);
      if (purchaseAbatement.abatement.code === 'NCPP') {
        purchaseAbatementNCPP = purchaseAbatement;
      } else {
        purchaseAbatement.amount = amount;
        purchaseAbatement.base = totalBudget;
        purchaseAbatement.purchaseAbatementOrder = index;
        totalBudget = totalBudget + amount;
        caNetBeforeNCPP = caFact + amount;
        this.updatePurchaseAbatement(purchaseAbatement);
      }
      maxIndexCat20 = index;
    });

    // 3.bis update NCPP abatement
    let amount = this.mathService.getRound(this.purchase.tvClassicBudgetNet - this.purchase.netFact - caNetBeforeNCPP, 2);

    if (this.group === 'FREG') {
      amount = this.mathService.getRound(this.purchase.tvRegionBudgetNet - this.purchase.netFactRegion - caNetBeforeNCPP, 2);
    } else if (this.group === 'O') {
      amount = this.mathService.getRound(this.purchase.tvDomtomBudgetNet - this.purchase.netFactDomtom - caNetBeforeNCPP, 2);
    } else if (this.group === 'W') {
      amount = this.mathService.getRound(this.purchase.tvThemaBudgetNet - this.purchase.netFactThema - caNetBeforeNCPP, 2);
    } else if (this.group === 'I') {
      amount = this.mathService.getRound(this.purchase.tvInterBudgetNet - this.purchase.netFactInter - caNetBeforeNCPP, 2);
    } else if (this.group === 'F4') {
      amount = this.mathService.getRound(this.purchase.tvF4BudgetNet - this.purchase.netFactF4 - caNetBeforeNCPP, 2);
    }

    let rate = 0;
    if (caNetBeforeNCPP) {
      rate = this.mathService.getRound(amount / caNetBeforeNCPP * 100, 9);
    }

    if (purchaseAbatementNCPP) {
      if ((caNetBeforeNCPP !== 0) && amount) {
        purchaseAbatementNCPP.amount = amount;
        purchaseAbatementNCPP.rate = rate;
        purchaseAbatementNCPP.base = caNetBeforeNCPP;
        purchaseAbatementNCPP.purchaseAbatementOrder = maxIndexCat20;
        this.updatePurchaseAbatement(purchaseAbatementNCPP);
      } else {
        this.removePurchaseAbatementByCode('NCPP');
        this.removeAbatementControl('caFact', 'NCPP');
        data.caFact = data.caFact.filter(item => item.abatement.code !== 'NCPP');
      }
    } else {
      if ((caNetBeforeNCPP !== 0) && amount) {
        let abat = this.getNCPPAbatement();
        purchaseAbatementNCPP = this.addAbatement(abat, this.group);
        purchaseAbatementNCPP.amount = amount;
        purchaseAbatementNCPP.rate = rate;
        purchaseAbatementNCPP.base = caNetBeforeNCPP;
        purchaseAbatementNCPP.purchaseAbatementOrder = maxIndexCat20 + 1;
        data.caFact.push(this.initItem(purchaseAbatementNCPP).value);
      }
    }

    if (caNetBeforeNCPP <= 0) {
      if (this.group === 'F') {
        this.purchase.tvClassicBudgetNet = this.purchase.netFact;
        this.purchase.tvClassicBudgetRate = (!this.purchase.tvClassicBudget || this.purchase.tvClassicBudget === 0) ? 0 :
            (this.purchase.tvClassicBudgetNet / this.purchase.tvClassicBudget - 1) * 100;
      } else if (this.group === 'FREG') {
        this.purchase.tvRegionBudgetNet = this.purchase.netFactRegion;
        this.purchase.tvRegionBudgetRate = (!this.purchase.tvRegionBudget || this.purchase.tvRegionBudget === 0) ? 0 :
            (this.purchase.tvRegionBudgetNet / this.purchase.tvRegionBudget - 1) * 100;
      } else if (this.group === 'O') {
        this.purchase.tvDomtomBudgetNet = this.purchase.netFactDomtom;
        this.purchase.tvDomtomBudgetRate = (!this.purchase.tvDomtomBudget || this.purchase.tvDomtomBudget === 0) ? 0 :
            (this.purchase.tvDomtomBudgetNet / this.purchase.tvDomtomBudget - 1) * 100;
      } else if (this.group === 'W') {
        this.purchase.tvThemaBudgetNet = this.purchase.netFactThema;
        this.purchase.tvThemaBudgetRate = (!this.purchase.tvThemaBudget || this.purchase.tvThemaBudget === 0) ? 0 :
            (this.purchase.tvThemaBudgetNet / this.purchase.tvThemaBudget - 1) * 100;
      } else if (this.group === 'I') {
        this.purchase.tvInterBudgetNet = this.purchase.netFactInter;
        this.purchase.tvInterBudgetRate = (!this.purchase.tvInterBudget || this.purchase.tvInterBudget === 0) ? 0 :
            (this.purchase.tvInterBudgetNet / this.purchase.tvInterBudget - 1) * 100;
      } else if (this.group === 'F4') {
        this.purchase.tvF4BudgetNet = this.purchase.netFactF4;
        this.purchase.tvF4BudgetRate = (!this.purchase.tvF4Budget || this.purchase.tvF4Budget === 0) ? 0 :
          (this.purchase.tvF4BudgetNet / this.purchase.tvF4Budget - 1) * 100;
      }
    }

    this.form.setValue(data, {emitEvent: false});
  }

  private updatePurchaseAbatement(purchaseAbatement) {
    this.purchaseAbatements.forEach(item => {
      if (purchaseAbatement.abatement.code === item.abatement.code) {
        item.rate = purchaseAbatement.rate;
        item.amount = purchaseAbatement.amount;
        item.base = purchaseAbatement.base;
        item.purchaseAbatementOrder = purchaseAbatement.purchaseAbatementOrder;
      }
    });
  }
}
