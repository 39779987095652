import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LotDotation } from '../../../../../resource/lot-dotation.resource';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as moment from 'moment';

@Component({
  selector: 'app-new-lot-dotation-period',
  templateUrl: './new-lot-dotation-period.component.html',
  styleUrls: ['./new-lot-dotation-period.component.scss']
})
export class NewLotDotationPeriodComponent implements OnInit {
  @Input() public newLotDotationForm: FormGroup;
  @Input() public lot: LotDotation;
  @Input() public year: FormControl;
  @Input() public isDuplicate: Boolean;
  @Input() public minDate: number;

  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private fb: FormBuilder,
    private customToastrService: CustomToastrService,
  ) {
  }

  ngOnInit() {
    if (this.isDuplicate && this.year) {
      this.newLotDotationForm.addControl('periodStartDate', this.fb.control({
        value: moment('01/01/' + this.year.value, 'DD/MM/YYYY'),
        disabled: false
      }));
      this.newLotDotationForm.addControl('periodEndDate', this.fb.control({
        value: moment('31/12/' + this.year.value, 'DD/MM/YYYY'),
        disabled: false
      }));
    } else {
      this.newLotDotationForm.addControl('periodStartDate', this.fb.control(this.lot ? {
        value: moment(this.lot.periodStartDate),
        disabled: false
      } : null, Validators.required));
      this.newLotDotationForm.addControl('periodEndDate', this.fb.control(this.lot ? {
        value: moment(this.lot.periodEndDate),
        disabled: false
      } : null, Validators.required));
    }
    this.onYearchange();
  }

  /**
   * Set new value when user change year to duplicate
   */
  onYearchange(): void {
    if (this.isDuplicate && this.year) {
      this.year.valueChanges
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((newYear: number) => {
          if (newYear.toString().length === 4) {
            if (newYear >= this.minDate && newYear <= this.minDate + 1) {
              this.newLotDotationForm.get('periodStartDate').setValue(moment('01/01/' + newYear, 'DD/MM/YYYY'));
              this.newLotDotationForm.get('periodEndDate').setValue(moment('31/12/' + newYear, 'DD/MM/YYYY'));
            } else {
              this.customToastrService.displayToastr('WARNING', 'Vous ne pouvez pas dupliquer ce lot sur cette année!');
            }
          }
        }, () => {
          this.customToastrService.displayToastr('ERROR', 'Erreur lors de la mise à jour de l\'année');
        });
    }
  }
}
