<div [formGroup]="informationGroup">
  <div formArrayName="periods">
    <ng-container *ngFor="let period of periods.controls; index as i">
      <div [formGroupName]="i" fxLayout="column" fxLayoutAlign="center center">
        <!-- startPeriod & endPeriod-->
        <div fxLayout="row" fxLayoutGap="5px" class="width-row-period">
          <!-- startPeriod -->
          <mat-form-field fxFlex="37">
            <input [max]="periods.controls[i].get('endPeriod').value" matInput formControlName="startPeriod" [matDatepicker]="du"
                   placeholder="Du">
            <mat-datepicker-toggle matSuffix [for]="du"></mat-datepicker-toggle>
            <mat-datepicker #du></mat-datepicker>
          </mat-form-field>

          <!-- endPeriod -->
          <mat-form-field fxFlex="37">
            <input [min]="periods.controls[i].get('startPeriod').value" matInput formControlName="endPeriod" [matDatepicker]="au"
                   placeholder="Au">
            <mat-datepicker-toggle matSuffix [for]="au"></mat-datepicker-toggle>
            <mat-datepicker #au></mat-datepicker>
          </mat-form-field>

          <!-- diffusionHour -->
          <mat-form-field fxFlex="26">
            <input matInput
                   type="time"
                   formControlName="diffusionHour"
                   placeholder="Heure de diffusion">
          </mat-form-field>

          <!-- removePeriodButton -->
          <button mat-mini-fab (click)="removePeriod(i)">
            <mat-icon>delete_outline</mat-icon>
          </button>
        </div>

        <!-- lot -->
        <div fxLayout="row" fxLayoutGap="5px" class="width-row-period">
          <mat-form-field fxFlex="50">
            <input matInput placeholder="Nombre de lots diffusés"
                   type="number"
                   formControlName="lotsBroadcasted"
                   (change)="changeLotsBroadcastedQuantity(i)">
          </mat-form-field>
          <mat-form-field fxFlex="50">
            <input matInput placeholder="Nombre de lots gagnés"
                   type="number"
                   formControlName="lotsWon">
          </mat-form-field>
        </div>
      </div>
    </ng-container>
    <div class="red" *ngIf="!periods.valid && periods.errors && periods.errors.isError">{{periods.errors.isError}}</div>
  </div>

  <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
    <mat-icon (click)="addPeriod()" class="red cursor">add_circle_outline</mat-icon>
    <span (click)="addPeriod()" class="underline-text cursor">Ajouter une période diffusion</span>
  </div>
</div>
