<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

<h2 mat-dialog-title class="h2-title">Création de dossier dotation :</h2>

<mat-dialog-content class="full-width purchase-dotation">

  <form [formGroup]="newDotationPurchaseForm" class="full-width" fxLayout="column" fxLayoutAlign="center center">

    <!-- Name -->
    <div fxLayout="row" fxLayoutGap="25px" class="input-line" fxLayoutAlign="start center">
      <span class="span-width bold" fxFlex="30">Nom dossier:</span>
      <mat-form-field class="full-width">
        <input formControlName="name" placeholder="Nom du dossier" matInput appRemoveAccents maxlength="50" type="text">
        <mat-error>Veuillez renseigner le nom du dossier !</mat-error>
      </mat-form-field>
    </div>

    <!-- Brand -->
    <div fxLayout="row" fxLayoutGap="25px" class="input-line" fxLayoutAlign="start center">
      <span class="span-width bold" fxFlex="30">Marque :</span>
      <mat-form-field class="full-width">
        <input
          formControlName="product"
          placeholder="Marque"
          matInput maxlength="30"
          type="text"
         [value]="newDotationPurchaseForm.get('product').value.display + ' (' + newDotationPurchaseForm.get('product').value.id + ')'">
        <mat-error>Veuillez sélectionner une marque !</mat-error>
      </mat-form-field>
    </div>

    <!--Campaign-->
    <div fxLayout="row" fxLayoutGap="25px" class="input-line" fxLayoutAlign="start center">
      <span class="span-width bold" fxFlex="30">Campagne :</span>
      <mat-form-field class="full-width">
        <input matInput maxlength="30" placeholder="Campagne"
           appRemoveAccents
           [matAutocomplete]="campaignAutocomplete"
           formControlName="campaign">
        <mat-error>Veuillez sélectionner une campagne !</mat-error>
      </mat-form-field>
      <mat-autocomplete #campaignAutocomplete="matAutocomplete" [displayWith]="autocompleteService.displayCampaignFullName.bind(this)">
        <mat-option *ngFor="let campaign of filteredCampaignItems | async" [value]="campaign">
          {{ campaign.entity.getFullName() }}
        </mat-option>
      </mat-autocomplete>
    </div>

  </form>

</mat-dialog-content>

<mat-dialog-actions class="full-width bloc-actions" fxLayout="row" fxLayoutAlign="end stretch" fxLayoutGap="30px">
  <button mat-flat-button mat-dialog-close class="button-flat black white-text">Annuler</button>
  <button mat-flat-button class="button-flat white-text" (click)="save()" [disabled]="loading || !newDotationPurchaseForm.valid">
      Valider
  </button>
</mat-dialog-actions>
