<mat-card-subtitle>
  {{ unitName }} : 
  <span class="bold hold-color">
    {{ total | number:'0.0-2':'fr' }} €
    <span *ngIf="(totalAlreadyFact) && (totalAlreadyFact != 0)">
        dont {{ totalAlreadyFact | number:'0.0-2':'fr' }} € facturé
    </span>
  </span>
</mat-card-subtitle>	

<mat-card-content>
  <div [formGroup]="form">
    <div [formArrayName]="groupName">
      <div *ngFor="let item of items.controls; let i=index">
        <div class="flex" [formGroupName]="i">
          <div class="flex-1 code">
              {{ item.controls.abatement.value.code }}
          </div> 
          <div class="flex-2">
            <mat-form-field class="form-control-medium">
                <input matInput
                      type="number"
                      formControlName="rate" 
                      placeholder="taux %" 
                      restrictInput="float"
                      [readonly]="item.controls.abatement.value.isFixed == 1">
            </mat-form-field>
         </div> 
          <div class="flex-2">
            <mat-form-field class="form-control-medium">
                <input matInput
                      appThousandsNumber
                      formControlName="amount" 
                      placeholder="montant €"
                      readonly>
            </mat-form-field>
          </div>   
          <div class="flex-1 remove">
              <!-- <a (click)="removeItem(i)">x</a> -->
              <button mat-mini-fab>
                  <mat-icon (click)="removeItem(i)">delete_outline</mat-icon>
              </button>
          </div>   
        </div>
      </div>
    </div>
  </div>
</mat-card-content>