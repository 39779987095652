import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';

import { Observable } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor() {}

  /**
   * Automatique Interceptor for inject Token
   * /!\ FOR INTECEPTOR HTTP /!\
   *
   * @param {HttpRequest<any>} req
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   * @memberof TokenInterceptor
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string = this.getToken();

    if (token) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }
    return next.handle(req);
  }

  /**
   * Get the token from the localStorage
   *
   * @private
   * @returns {string}
   * @memberof TokenInterceptor
   */
  private getToken(): string {
    if (localStorage.length > 0) {
      return localStorage.getItem('access_token')
    } else {
      return null;
    }
  }
}
