// Custom modules
import { ServicesModule } from "@service/services.module";
import { AvailabilityModule } from "./private/availability/availability.module";
import { PipeModule } from "./pipe/pipe.module";
import { SharedModule } from "./shared/shared.module";

import { TokenInterceptor } from "@service/interceptor/tokenInterceptor";
import { ErrorInterceptor } from "@service/interceptor/errorInterceptor";
import { UtilitiesHandler } from "@service/utilities-handlers/utilitiesHandlers";

// Vendor modules
import { NgModule, ErrorHandler } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { ServiceWorkerModule } from "@angular/service-worker";

import { AppRoutingModule } from "./app-routing-module";
import { Angulartics2Module } from "angulartics2";
import { Angulartics2GoogleAnalytics } from "angulartics2/ga";

// Components
import { AppComponent } from "./app.component";
import { MenuComponent } from "./shared/menu/menu.component";
import { LoginComponent } from "./login/login.component";
import { DashboardComponent } from "./private/dashboard/dashboard.component";
import { PrivateComponent } from "./private/private.component";

// Pipes, env ...
import { environment } from "../environments/environment";
import { PurchaseModule } from "./private/purchase/purchase.module";
import { LotsModule } from "./private/lots/lots.module";
import { ConfirmDialogComponent } from "./shared/dialog/confirm-dialog/confirm-dialog.component";
import { FlexLayoutModule } from "@angular/flex-layout";

import { APP_BASE_HREF, registerLocaleData } from "@angular/common";
import { ToastrModule } from "ngx-toastr";

import localeFr from "@angular/common/locales/fr";
import "moment/locale/fr";

registerLocaleData(localeFr);

import {
  MatIconModule,
  MatTableModule,
  MatToolbarModule,
  MatCardModule,
  MatDialogModule,
  MatTabsModule,
  MatButtonModule,
  MatProgressSpinnerModule,
  MatMenuModule,
  MatDividerModule,
  MatButtonToggleModule,
  MatDateFormats,
  MatFormFieldModule,
  MatInputModule,
} from "@angular/material";
import { MatListModule } from "@angular/material/list";

import {
  MAT_DATE_LOCALE,
  DateAdapter,
  MAT_DATE_FORMATS,
} from "@angular/material";

import { AddSignataireDialogComponent } from "./shared/dialog/add-signataire-dialog/add-signataire-dialog.component";
import { AddFunctionDialogComponent } from "./shared/dialog/add-function-dialog/add-function-dialog.component";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { SoReachDialogComponent } from "./shared/dialog/so-reach-dialog/so-reach-dialog.component";
import { ValidationDialogComponent } from "./shared/dialog/validation-dialog/validation-dialog.component";
import { ExportConducteurComponent } from "./shared/dialog/export-conducteur/export-conducteur.component";
import { EventDialogComponent } from "./shared/dialog/event-dialog/event-dialog.component";

export const FRENCH_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "DD/MM/YYYY",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    LoginComponent,
    PrivateComponent,
    DashboardComponent,
    ConfirmDialogComponent,
    EventDialogComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ServicesModule,
    PurchaseModule,
    AvailabilityModule,
    Angulartics2Module.forRoot([Angulartics2GoogleAnalytics]),
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    LotsModule,
    // Material
    MatIconModule,
    MatTableModule,
    MatToolbarModule,
    MatCardModule,
    MatDialogModule,
    MatTabsModule,
    MatButtonModule,
    ScrollingModule,
    MatDividerModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatInputModule,
    MatListModule,
    SharedModule,
    PipeModule,
    MatFormFieldModule,
    ToastrModule.forRoot({
      positionClass: "toast-top-right",
      preventDuplicates: true,
      resetTimeoutOnDuplicate: true,
      maxOpened: 3,
      tapToDismiss: false,
    }),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
  ],
  providers: [
    ConfirmDialogComponent,
    EventDialogComponent,
    AddSignataireDialogComponent,
    AddFunctionDialogComponent,
    { provide: MAT_DATE_LOCALE, useValue: "fr-FR" },
    {
      provide: MAT_DATE_FORMATS,
      useValue: FRENCH_DATE_FORMATS,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: DateAdapter, useClass: MomentDateAdapter },
    { provide: APP_BASE_HREF, useValue: "/" },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: ErrorHandler, useClass: UtilitiesHandler },
  ],
  entryComponents: [
    ConfirmDialogComponent,
    EventDialogComponent,
    AddSignataireDialogComponent,
    AddFunctionDialogComponent,
    SoReachDialogComponent,
    ValidationDialogComponent,
    ExportConducteurComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
