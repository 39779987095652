<form [formGroup]="newLotDotationForm" fxLayout="row" fxLayoutGap="25px" fxLayoutAlign="start center">
  <span class="thin bold" fxFlex="30">Période :</span>
  <mat-form-field>
    <input [max]="newLotDotationForm.get('periodEndDate').value" matInput formControlName="periodStartDate" [matDatepicker]="du"
           placeholder="Du" required>
    <mat-datepicker-toggle matSuffix [for]="du"></mat-datepicker-toggle>
    <mat-datepicker #du></mat-datepicker>
  </mat-form-field>
  <mat-form-field>
    <input [min]="newLotDotationForm.get('periodStartDate').value" matInput formControlName="periodEndDate" [matDatepicker]="au"
           placeholder="Au" required>
    <mat-datepicker-toggle matSuffix [for]="au"></mat-datepicker-toggle>
    <mat-datepicker #au></mat-datepicker>
  </mat-form-field>
</form>
