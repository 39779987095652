
<mat-card class="mat-card-offer">
    <mat-progress-bar mode="indeterminate" class="bar"
        *ngIf="loadingInput.user || loadingInput.program || loadingInput.channel || loadingInput.area || loadingInput.advertiser || loadingInput.product || loadingInput.status">
    </mat-progress-bar>
    <div class="three-columns-grid" fxLayout="column">
        <!-- 1st row -->
        <div fxLayout="column" fxLayoutGap="20px">
            <span class="title-groupe">Période</span>
            <div fxLayout="row" fxLayoutGap="3%">
                <div class="filter-control">
                    <mat-form-field class="date-full-width">
                        <input matInput
                            [matDatepicker]="startDate"
                            placeholder="Du"
                            (dateChange)="updateFilters()"
                            [formControl]="startCommunicationPeriod">
                            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                        <mat-datepicker #startDate></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="filter-control">
                    <mat-form-field class="date-full-width">
                        <input matInput
                            [matDatepicker]="endDate"
                            placeholder="Au"
                            (dateChange)="updateFilters()"
                            [formControl]="endCommunicationPeriod"
                            [min]="startCommunicationPeriod.value">
                            <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                        <mat-datepicker #endDate></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="divider"></div>
        <!-- 2th row -->
        <div fxLayout="column">
            <span class="title-groupe">Intitulés</span>
            <div fxLayout="row" fxLayoutGap="3%">
                <div class="filter-control">
                    <mat-form-field (click)="openInput('user')" class="line-field">
                        <mat-chip-list #chipListUser>
                            <mat-chip
                                *ngFor="let user of filters.user"
                                class="color-chips"
                                [selectable]="true"
                                [removable]="true"
                                (removed)="remove(user, 'user')">
                                {{ user.display }}
                            <mat-icon matChipRemove>clear</mat-icon>
                            </mat-chip>
                            <input
                                #userInput
                                appRemoveAccents
                                [placeholder]="filters.user.length ? 'Utilisateur' : '+ Trigramme ou Nom'"
                                [formControl]="userCtrl"
                                [matAutocomplete]="autoUser"
                                [matChipInputFor]="chipListUser"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                [matChipInputAddOnBlur]="true">
                            <mat-icon matSuffix>search</mat-icon>
                        </mat-chip-list>
                        <mat-autocomplete #autoUser="matAutocomplete" (optionSelected)="selected($event, 'user')">
                            <mat-option *ngFor="let user of filteredUser | async" [value]="user">
                                {{ user.display }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="filter-control">
                    <mat-form-field (click)="openInput('programs')" class="line-field">
                        <mat-chip-list #chipListProgram>
                            <mat-chip
                                *ngFor="let program of filters.programs"
                                [selectable]="true"
                                [removable]="true"
                                (removed)="remove(program, 'programs')">
                                {{ program.display }}
                            <mat-icon matChipRemove>clear</mat-icon>
                            </mat-chip>
                            <input
                                #programInput
                                appRemoveAccents
                                [placeholder]="filters.programs.length ? '+ Ajouter une Emission' : 'Intitulé de l\'émission'"
                                [formControl]="programsCtrl"
                                [matAutocomplete]="autoProgram"
                                [matChipInputFor]="chipListProgram"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                [matChipInputAddOnBlur]="true">
                            <mat-icon matSuffix>search</mat-icon>
                        </mat-chip-list>
                        <mat-autocomplete #autoProgram="matAutocomplete" (optionSelected)="selected($event, 'programs')">
                            <mat-option *ngFor="let program of filteredPrograms | async" [value]="program">
                                {{ program.display }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

                <!-- Channel Filter -->
                <div class="filter-control">
                    <mat-form-field (click)="openInput('channels')" class="line-field">
                        <mat-chip-list #chipListChannel>
                            <mat-chip
                                *ngFor="let channel of filters.channels"
                                [selectable]="true"
                                [removable]="true"
                                (removed)="remove(channel, 'channels')">
                                <span>
                                    <img preload fade class="tag" [src]="getLogoByValue(channel)" [default]="path + defaultLogo">
                                    {{ channel.display }}
                                </span>
                            <mat-icon matChipRemove>clear</mat-icon>
                            </mat-chip>
                            <input
                                #channelInput
                                appRemoveAccents
                                [placeholder]="filters.channels.length ? '+ Ajouter une Chaîne' : 'Intitulé de la chaîne'"
                                [formControl]="channelsCtrl"
                                [matAutocomplete]="autoChannel"
                                [matChipInputFor]="chipListChannel"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                [matChipInputAddOnBlur]="true">
                            <mat-icon matSuffix>search</mat-icon>
                        </mat-chip-list>
                        <mat-autocomplete #autoChannel="matAutocomplete" (optionSelected)="selected($event, 'channels')">
                            <mat-option *ngFor="let channel of filteredChannels | async" [value]="channel">
                                <img preload fade class="tag" [src]="path + channel.logo" [default]="path + defaultLogo">
                                {{ channel.display }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <!-- 3rd row -->
        <!-- Area Filter -->
        <div fxLayout="row" fxLayoutGap="3%">
            <div class="filter-control">
                <mat-form-field (click)="openInput('areas')" class="line-field">
                    <mat-chip-list #chipListArea>
                        <mat-chip
                            *ngFor="let area of filters.areas"
                            [selectable]="true"
                            [removable]="true"
                            (removed)="remove(area, 'areas')">
                            {{ area.display }}
                        <mat-icon matChipRemove>clear</mat-icon>
                        </mat-chip>
                        <input
                            #areaInput
                            appRemoveAccents
                            [placeholder]="filters.areas.length ? '+ Ajouter une région' : 'Intitulé de la région'"
                            [disabled]="disableArea"
                            [formControl]="areasCtrl"
                            [matAutocomplete]="autoArea"
                            [matChipInputFor]="chipListArea"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            [matChipInputAddOnBlur]="true">
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-chip-list>
                    <mat-autocomplete #autoArea="matAutocomplete" (optionSelected)="selected($event, 'areas')">
                        <mat-option *ngFor="let area of filteredAreas | async" [value]="area">
                            {{ area.display }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>

            <div class="filter-control">
                <mat-form-field (click)="openInput('advertiser_or_customer')" class="line-field">
                    <mat-chip-list #chipListAdvertiser>
                        <mat-chip
                            *ngFor="let advertiser of filters.advertiser_or_customer"
                            class="color-chips"
                            [selectable]="true"
                            [removable]="true"
                            (removed)="remove(advertiser, 'advertiser_or_customer')">
                            {{ advertiser.display }}
                        <mat-icon matChipRemove>clear</mat-icon>
                        </mat-chip>
                        <input
                            #advertiserInput
                            appRemoveAccents
                            [placeholder]="filters.advertiser_or_customer.length ? '+ Agence, Annonceur' : 'Agence, Annonceur'"
                            [formControl]="advertiser_or_customerCtrl"
                            [matAutocomplete]="autoAdvertiser"
                            [matChipInputFor]="chipListAdvertiser"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            [matChipInputAddOnBlur]="true">
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-chip-list>
                    <mat-autocomplete #autoAdvertiser="matAutocomplete" (optionSelected)="selected($event, 'advertiser_or_customer')">
                        <mat-option *ngFor="let advertiser of filteredAdvertiser_or_customer | async" [value]="advertiser">
                            {{ advertiser.display }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div class="filter-control">
                <mat-form-field (click)="openInput('product_id')" class="line-field">
                    <mat-chip-list #chipListProduct>
                        <mat-chip
                            *ngFor="let product of filters.product_id"
                            class="color-chips"
                            [selectable]="true"
                            [removable]="true"
                            (removed)="remove(product, 'product_id')">
                            {{ product.display }}
                        <mat-icon matChipRemove>clear</mat-icon>
                        </mat-chip>
                        <input
                            #product_idInput
                            [placeholder]="filters.product_id.length ? '+ Produit' : 'Produit'"
                            [formControl]="product_idCtrl"
                            [matAutocomplete]="autoProduct"
                            [matChipInputFor]="chipListProduct"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            [matChipInputAddOnBlur]="true">
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-chip-list>
                    <mat-autocomplete #autoProduct="matAutocomplete" (optionSelected)="selected($event, 'product_id')">
                        <mat-option *ngFor="let product of filteredProduct_id | async" [value]="product">
                            {{ product.display }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>
        <!-- 4th row -->
        <div fxLayout="row" fxLayoutGap="3%">
            <div class="filter-control">
                <mat-form-field (click)="openInput('purchase_type_id')" class="line-field last-input">
                    <mat-chip-list #chipListPurchase>
                        <mat-chip
                            *ngFor="let purchase of filters.purchase_type_id"
                            class="color-chips"
                            [selectable]="true"
                            [removable]="true"
                            (removed)="remove(purchase, 'purchase_type_id')">
                            {{ purchase.display }}
                        <mat-icon matChipRemove>clear</mat-icon>
                        </mat-chip>
                        <input
                            #purchaseInput
                            appRemoveAccents
                            [placeholder]="filters.purchase_type_id.length ? '+ Ajouter statuts' : 'Statut du dossier'"
                            [formControl]="purchase_type_idCtrl"
                            [matAutocomplete]="autoPurchase"
                            [matChipInputFor]="chipListPurchase"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            [matChipInputAddOnBlur]="true">
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-chip-list>
                    <mat-autocomplete #autoPurchase="matAutocomplete" (optionSelected)="selected($event, 'purchase_type_id')">
                        <mat-option *ngFor="let purchase of filteredPurchase_type_id | async" [value]="purchase">
                            {{ purchase.display }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <button mat-flat-button [disabled]="loading" (click)="search()" class="find-button">Rechercher</button>
        </div>
    </div>
</mat-card>
