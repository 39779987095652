<mat-card class="form-card">
    <mat-card-content>
        <form novalidate [formGroup]="grpForm">
            <div fxLayout="column" fxLayoutGap="5px">
                <div fxLayout="row">
                    <div fxLayout="column" fxFlex="20"></div>
                    <div fxLayout="column" fxFlex="80" fxLayoutGap="5px">
                        <div fxLayout="row" class="height-size title-card">
                            <div fxLayoutAlign="center center" fxFlex="50">
                                <span class="normal">
                                    Performance cible annonceur
                                </span>
                            </div>
                            <div fxLayoutAlign="center center" fxFlex="50">
                                <span class="normal">
                                    Performance cible offre
                                </span>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="center center" class="height-size sub-title-card">
                            <div class="normal" fxLayout="row">
                                <span>
                                    FORMAT MOYEN :
                                </span>
                                <span class="hold-input">
                                    {{ purchase.averageDurationRate | roundTo:3 }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxLayout="row" class="color-body">
                    <div fxLayout="column" fxFlex="20" fxLayoutAlign="center center">
                        <span class="input-margin-top thin border-right normal-color" fxLayoutAlign="center center">Cible</span>
                        <span class="height-size thin border-right normal-color" fxLayoutAlign="center center">GRP</span>
                        <span class="height-size thin border-right normal-color" fxLayoutAlign="center center">Couverture</span>
                        <span class="height-size thin border-right normal-color" fxLayoutAlign="center center">Répétition</span>
                        <span class="input-margin-bottom thin border-right normal-color" fxLayoutAlign="center center">Coût GRP</span>
                    </div>
                    <div fxLayout="column" fxFlex="40" fxLayoutAlign="center center">
                        <div class="height-size padding-left-right padding-up" fxLayoutAlign="center center">
                            <div class="full-size">
                                <span *ngIf="purchase.target" fxLayoutAlign="center center">{{purchase.target.name}}</span>
                            </div>
                        </div>
                        <div fxLayout="row" class="height-size padding-left-right" fxLayoutAlign="center center">
                            <div class="full-size border-top-bot padding-up">
                                <div fxLayout="row" fxFlex="50" fxLayoutAlign="center center" fxLayoutGap="5px" class="border-right">
                                    <span>Vendu :</span>
                                    <input matInput type="text" formControlName="grp" (keyup)="updateCgrp($event)" max="99999" class="bold padding-top-10" placeholder="--">
                                </div>
                                <div fxLayout="row" fxFlex="50" fxLayoutAlign="center center" fxLayoutGap="5px">
                                    <span>Estimé :</span>
                                    <span *ngIf="!estimatedPurchaseGrpAvailable">non disponible</span>
                                    <span *ngIf="purchaseGrp && estimatedPurchaseGrpAvailable">&nbsp;Estimé : {{ purchaseGrp.estimatedPurchaseGrpForPurchase | number }}</span>
                                </div>
                            </div>
                        </div>
                        <div fxLayout="row" class="height-size padding-left-right" fxLayoutAlign="center center">
                            <div class="full-size padding-up">
                                <div fxLayout="row" fxFlex="50" fxLayoutAlign="center center" fxLayoutGap="5px" class="border-right">
                                    <span>Vendu :</span>
                                    <input matInput type="text" formControlName="coverage" (keyup)="updatePurchase()" min="0" max="99999" class="bold padding-top-10" placeholder="--">
                                </div>
                                <div fxLayout="row" fxFlex="50" fxLayoutAlign="center center" fxLayoutGap="5px">
                                    <span>Estimé :</span>
                                    <span *ngIf="!estimatedPurchaseGrpAvailable">non disponible</span>
                                    <span *ngIf="purchaseGrp && estimatedPurchaseGrpAvailable">&nbsp;Estimé : {{ purchaseGrp.estimatedPurchaseCoverageForPurchase | number }}</span>
                                </div>
                            </div>
                        </div>
                        <div fxLayout="row" class="height-size padding-left-right" fxLayoutAlign="center center">
                            <div class="full-size border-top-bot padding-up">
                                <div fxLayout="row" fxFlex="50" fxLayoutAlign="center center" fxLayoutGap="5px" class="border-right">
                                    <span>Vendu :</span>
                                    <input matInput type="text" formControlName="repeat" (keyup)="updatePurchase()" max="99999" class="padding-top-10" placeholder="--">
                                </div>
                                <div fxLayout="row" fxFlex="50" fxLayoutAlign="center center" fxLayoutGap="5px">
                                    <span>Estimé :</span>
                                    <span *ngIf="!estimatedPurchaseGrpAvailable">non disponible</span>
                                    <span *ngIf="purchaseGrp && estimatedPurchaseGrpAvailable">&nbsp;Estimé : {{ purchaseGrp.estimatedPurchaseRepeatForPurchase | number }}</span>
                                </div>
                            </div>
                        </div>
                        <span class="height-size" fxLayoutAlign="center center">{{ purchase.cgrp | number }} €</span>
                    </div>
                    <div fxLayout="column" fxFlex="40" fxLayoutAlign="center center">
                        <div class="height-size padding-left-right" fxLayoutAlign="center center">
                            <div class="full-size" fxLayoutAlign="center center">
                                <span *ngIf="offer && offer.target && offer.target.name">{{ offer.target.name }}</span>
                            </div>
                        </div>
                        <div fxLayout="row" class="height-size  padding-left-right" fxLayoutAlign="center center">
                            <div class="full-size border-top-bot padding-up">
                                <div fxLayout="row" fxFlex="50" fxLayoutAlign="center center" fxLayoutGap="5px" class="border-right">
                                    <span>Vendu :</span>
                                    <input matInput type="text" formControlName="estimatedGrp" (keyup)="updateCgrp($event)" max="99999" class="bold padding-top-10" placeholder="--">
                                </div>
                                <div fxLayout="row" fxFlex="50" fxLayoutAlign="center center" fxLayoutGap="5px">
                                    <span>Estimé :</span>
                                    <span *ngIf="!estimatedOfferGrpAvailable">non disponible</span>
                                    <span *ngIf="purchaseGrp && estimatedOfferGrpAvailable">&nbsp;Estimé : {{ purchaseGrp.estimatedPurchaseGrp | number }}</span>
                                </div>
                            </div>
                        </div>
                        <div fxLayout="row" class="height-size  padding-left-right" fxLayoutAlign="center center">
                            <div class="full-size">
                                <div fxLayout="row" fxFlex="50" fxLayoutGap="5px"></div>
                                <div fxLayout="row" fxFlex="50" fxLayoutGap="5px"></div>
                            </div>
                        </div>
                        <div fxLayout="row" class="height-size padding-left-right" fxLayoutAlign="center center">
                            <div class="full-size border-top-bot">
                                <div fxLayout="row" fxFlex="50" fxLayoutGap="5px"></div>
                                <div fxLayout="row" fxFlex="50" fxLayoutGap="5px"></div>
                            </div>
                        </div>
                        <div fxLayout="row" class="height-size  padding-left-right" fxLayoutAlign="center center">
                            <div class="full-size padding-up">
                                <div fxLayout="row" fxFlex="50" fxLayoutAlign="center center" fxLayoutGap="5px" class="border-right">
                                    <span>Vendu:</span>
                                    <input matInput type="text" value="{{ purchase.estimatedCgrp | number }}" disabled class="padding-top-10" placeholder="--">
                                </div>
                                <div fxLayout="row" fxFlex="50" fxLayoutAlign="center center" fxLayoutGap="5px">
                                    <span>Estimé :</span>
                                    <span>non disponible</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </mat-card-content>
</mat-card>