import { Injectable } from '@angular/core';

import { Schedule } from '../../resource/schedule/schedule.resource';
import { ScheduleTime } from '../../resource/schedule/schedule-time.resource';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { UtilitiesHandler } from '@service/utilities-handlers/utilitiesHandlers';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ScheduleTimeService {
  private route: string = 'schedule_time';

 constructor(
    private customToastrService: CustomToastrService,
    private http: HttpClient,
    private utilitiesHandler: UtilitiesHandler
  ) {}

  /**
   * Post of schedule time
   *
   * @param {ScheduleTime} scheduleTime
   * @returns {Observable<Object>}
   * @memberof ScheduleTimeService
   */
  public post(scheduleTime: ScheduleTime): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;

    return this.http
      .post(api_base_url, this.extract(scheduleTime))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Post collection of schedule time
   *
   * @param {ScheduleTime[]} scheduleTimes
   * @returns {Observable<Object>}
   * @memberof ScheduleTimeService
   */
  public postCollection(scheduleTimes: ScheduleTime[]): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;

    return this.http
      .post(api_base_url, this.extractCollection(scheduleTimes))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Edit collection
   *
   * @param {ScheduleTime[]} times
   * @returns {Observable<Object>}
   * @memberof ScheduleTimeService
   */
  public editCollection(times: ScheduleTime[]): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;

    return this.http
      .put(api_base_url, this.extractCollection(times))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Remove schedule Time
   *
   * @param {ScheduleTime} time
   * @returns {Observable<Object>}
   * @memberof ScheduleTimeService
   */
  public remove(time: ScheduleTime): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}/${time.id}`;

    return this.http
      .delete(api_base_url)
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Extract collection
   *
   * @param {*} array
   * @returns {Array<any>}
   * @memberof ScheduleTimeService
   */
  public extractCollection(array): Array<any> {
    let result = [];
    array.forEach(item => {
      result.push(this.extract(item));
    });

    return result;
  }

  /**
   * Post cloned schedule time
   *
   * @param {Schedule} clonedSchedule
   * @returns {Observable<any>}
   * @memberof ScheduleTimeService
   */
  public postClonedScheduleTime(clonedSchedule: Schedule): Observable<any> {
    return Observable.create(observer => {
      if (!clonedSchedule.times.length) {
        observer.next();
      } else {
        let count = 0;

        clonedSchedule.times.forEach((time: ScheduleTime) => {
          time.parent = Object.assign({}, time);
          time.schedule = clonedSchedule;
          time.id = null;

          this.post(time)
          .subscribe(() => {
            if (this.checkActionCount(clonedSchedule.times.length, ++count)) {
              observer.next();
            }
          }, () => {
            this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
          })
        })
      }
    })
  }

  /**
   * Manage times
   *
   * @param {*} schedule
   * @param {*} [oldSchedule=null]
   * @returns {Observable<any>}
   * @memberof ScheduleTimeService
   */
  public manageTimes(schedule, oldSchedule = null): Observable<any> {
    return Observable.create(observer => {

      schedule.times.forEach(time => {
        time.schedule = schedule;
      })

      let totalActions = 0;
      let actionCount = 0;
      let timesToDelete = [];

      let timesToAdd = schedule.times.filter((period) => period.id === null);
      let timesToEdit = schedule.times.filter((period) => period.id !== null);
      let oldTimes = oldSchedule ? oldSchedule.times : [];

      oldTimes.forEach(oldTime => {
        let idFound = schedule.times.filter((time) => time.id === oldTime.id)
        if (!idFound.length) {
          timesToDelete.push(oldTime);
        }
      });

      if (timesToAdd.length) {
        totalActions++;
        this.postCollection(timesToAdd)
          .subscribe(() => {
            if (this.checkActionCount(totalActions, ++actionCount)) {
              observer.next();
            }
          }, (error) => {
            this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
          });
      }

      if (timesToEdit.length) {
        totalActions++;
        this.editCollection(timesToEdit)
          .subscribe(() => {
            if (this.checkActionCount(totalActions, ++actionCount)) {
              observer.next();
            }
          }, (error) => {
            this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
          });
      }

      if (timesToDelete.length) {
        timesToDelete.forEach(period => {
          totalActions++;
          this.remove(period)
            .subscribe(() => {
              if (this.checkActionCount(totalActions, ++actionCount)) {
                observer.next();
              }
            }, (error) => {
              this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            });
        });
      }
    });
  }

  /**
   * Check action count
   *
   * @private
   * @param {*} totalActions
   * @param {*} actionCount
   * @returns {boolean}
   * @memberof ScheduleTimeService
   */
  private checkActionCount(totalActions, actionCount): boolean {
    return totalActions === actionCount;
  }

  /**
   * Extract
   *
   * @private
   * @param {ScheduleTime} scheduleTime
   * @returns {Object}
   * @memberof ScheduleTimeService
   */
  private extract(scheduleTime: ScheduleTime): Object {
    return {
      id: scheduleTime.id,
      schedule: scheduleTime.schedule.id,
      start_time: scheduleTime.startTime,
      end_time: scheduleTime.endTime,
      parent: scheduleTime.parent && scheduleTime.parent.id ? scheduleTime.parent.id : null
    }
  }
}
