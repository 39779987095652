import { Target } from './target.resource';
import { Secodip } from './secodip.resource';
import { Base } from './base.resource';
import { PurchaseType } from './purchaseType.resource';
import { Advertiser } from './advertiser.resource';
import { Product } from './product.resource';
import { Employee } from './employee.resource';
import { Campaign, JsonCampaign } from './campaign.resource';
import { Customer } from './customer.resource';

import * as moment from 'moment';

export interface JsonPurchase {
  id: number;
  name: string;
  date_of_expiration: number;
  date_of_purchase: number;
  start_communication_period: string;
  end_communication_period: string;
  comments: string;
  reference_command: string;
  preferential_position: number;
  customer_budget: number;
  mandate_certificate: string;
  commercial?: Employee
  sub_commercial?: Employee
  planning_id?: Employee
  type?: PurchaseType;
  advertiser?: Advertiser;
  product?: Product;
  campaign?: JsonCampaign;
  advertiser_signatory_name?: any;
  advertiser_signatory_funct?: any;
  mandatary?: Customer;
  mandatary_signatory_funct?: any;
  mandatary_signatory_name?: any;
  sub_mandatary?: Customer;
  sub_mandatary_signatory_name?: any;
  sub_mandatary_signatory_funct?: any;
  secodip?: Secodip;
  target?: Target;
  temporary_advertiser: string;
  temporary_product: string;
  temporary_mandatary: string;
  temporary_sub_mandatary: string;
  tv_budget: number;

  tv_classic_budget: number;
  tv_thema_budget: number;
  tv_region_budget: number;
  tv_inter_budget: number;
  tv_domtom_budget: number;
  tv_f4budget: number;

  tv_budget_net: number;

  tv_classic_budget_net: number;
  tv_thema_budget_net: number;
  tv_region_budget_net: number;
  tv_inter_budget_net: number;
  tv_domtom_budget_net: number;
  tv_f4budget_net: number;

  digital_budget: number;
  digital_display_budget: number;
  digital_replay_budget: number;
  dig_vid_bill_budget: number;
  dig_vid_pre_budget: number;
  digital_social_budget: number;
  social_fb_budget: number;
  social_twit_budget: number;
  social_insta_budget: number;
  digital_other_budget: number;
  digital_budget_net: number;
  digital_display_budget_net: number;
  digital_replay_budget_net: number;
  dig_vid_bill_budget_net: number;
  dig_vid_pre_budget_net: number;
  digital_social_budget_net: number;
  social_fb_budget_net: number;
  social_twit_budget_net: number;
  social_insta_budget_net: number;
  digital_other_budget_net: number;
  total_budget: number;
  total_budget_net: number;
  tv_budget_rate: number;

  tv_classic_budget_rate: number;
  tv_thema_budget_rate: number;
  tv_region_budget_rate: number;
  tv_inter_budget_rate: number;
  tv_domtom_budget_rate: number;
  tv_f4budget_rate: number;

  digital_budget_rate: number;
  digital_display_budget_rate: number;
  digital_replay_budget_rate: number;
  dig_vid_bill_budget_rate: number;
  dig_vid_pre_budget_rate: number;
  digital_social_budget_rate: number;
  social_fb_budget_rate: number;
  social_twit_budget_rate: number;
  social_insta_budget_rate: number;
  digital_other_budget_rate: number;
  total_budget_rate: number;
  grp: number;
  cgrp: number;
  estimated_grp: number;
  estimated_cgrp: number;
  average_duration_rate: number;
  compensation_purchase?: Purchase;
  coverage: number;
  repeat: number;

  // Cascade tv classic
  ca_ref: number;
  ca_fact: number;
  ini_fact: number;
  ref_fact: number;
  fact_fact: number;
  net_fact: number;

  // Cascade tv region
  ca_ref_region: number;
  ca_fact_region: number;
  ini_fact_region: number;
  ref_fact_region: number;
  fact_fact_region: number;
  net_fact_region: number;

  // Cascade tv domtom
  ca_ref_domtom: number;
  ca_fact_domtom: number;
  ini_fact_domtom: number;
  ref_fact_domtom: number;
  fact_fact_domtom: number;
  net_fact_domtom: number;

  // Cacade tv thema
  ca_ref_thema: number;
  ca_fact_thema: number;
  ini_fact_thema: number;
  ref_fact_thema: number;
  fact_fact_thema: number;
  net_fact_thema: number;

  // Cascade tv international
  ca_ref_inter: number;
  ca_fact_inter: number;
  ini_fact_inter: number;
  ref_fact_inter: number;
  fact_fact_inter: number;
  net_fact_inter: number;

  // Cascade tv France 4
  ca_ref_f4: number;
  ca_fact_f4: number;
  ini_fact_f4: number;
  ref_fact_f4: number;
  fact_fact_f4: number;
  net_fact_f4: number;

  fsa_id: number;
  signatory_ftp_code: string;
  soreach_purchase: number;
  alert_switch_date?: any;
}

export class Purchase extends Base {

  /**
   * Mandat Certificate Items
   *
   * @static
   *
   * @memberOf Purchase
   */
  static mandatCertificateItems = [
    { value: '0', display: 'Absence d\'attestation de mandat' },
    { value: '1', display: 'Attestation de mandat valide' },
    { value: '2', display: 'Attestation de mandat non valide' }
  ];

  public id: number;
  public name: string;
  public dateOfExpiration: number;
  public dateOfPurchase: number;
  public startCommunicationPeriod: string;
  public endCommunicationPeriod: string;
  public comments: string;
  public referenceCommand: string;
  public customerBudget: number;
  public mandateCertificate: string;
  public preferentialPosition: number;

  public commercial: Employee;
  public subCommercial: Employee;
  public planning: Employee;
  public type: PurchaseType;
  public advertiser: Advertiser;
  public product: Product;
  public campaign: Campaign;
  public advertiserSignatoryName: any;
  public advertiserSignatoryFunct: any;
  public mandatary: Customer;
  public mandatarySignatoryName: any;
  public mandatarySignatoryFunct: any;
  public subMandatary: Customer;
  public subMandatarySignatoryName: any;
  public subMandatarySignatoryFunct: any;
  public secodip: Secodip;
  public target: Target;
  public compensationPurchase: Purchase;

  public temporaryAdvertiser: string;
  public temporaryProduct: string;
  public temporaryMandatary: string;
  public temporarySubMandatary: string;

  public tvBudget: number;

  // Budgets
  public tvClassicBudget: number;
  public tvThemaBudget: number;
  public tvRegionBudget: number;
  public tvInterBudget: number;
  public tvDomtomBudget: number;
  public tvF4Budget: number;

  public tvBudgetNet: number;

  // Net budgets
  public tvClassicBudgetNet: number;
  public tvThemaBudgetNet: number;
  public tvRegionBudgetNet: number;
  public tvInterBudgetNet: number;
  public tvDomtomBudgetNet: number;
  public tvF4BudgetNet: number;

  public digitalBudget: number;
  public digitalDisplayBudget: number;
  public digitalReplayBudget: number;
  public digVidBillBudget: number;
  public digVidPreBudget: number;
  public digitalSocialBudget: number;
  public socialFbBudget: number;
  public socialTwitBudget: number;
  public socialInstaBudget: number;
  public digitalOtherBudget: number;
  public digitalBudgetNet: number;
  public digitalDisplayBudgetNet: number;
  public digitalReplayBudgetNet: number;
  public digVidBillBudgetNet: number;
  public digVidPreBudgetNet: number;
  public digitalSocialBudgetNet: number;
  public socialFbBudgetNet: number;
  public socialTwitBudgetNet: number;
  public socialInstaBudgetNet: number;
  public digitalOtherBudgetNet: number;
  public totalBudget: number;
  public totalBudgetNet: number;
  public tvBudgetRate: number;

  // Rates
  public tvClassicBudgetRate: number;
  public tvThemaBudgetRate: number;
  public tvRegionBudgetRate: number;
  public tvInterBudgetRate: number;
  public tvDomtomBudgetRate: number;
  public tvF4BudgetRate: number;

  public digitalBudgetRate: number;
  public digitalDisplayBudgetRate: number;
  public digitalReplayBudgetRate: number;
  public digVidBillBudgetRate: number;
  public digVidPreBudgetRate: number;
  public digitalSocialBudgetRate: number;
  public socialFbBudgetRate: number;
  public socialTwitBudgetRate: number;
  public socialInstaBudgetRate: number;
  public digitalOtherBudgetRate: number;
  public totalBudgetRate: number;

  // Cascade already invoiced
  public caRef: number;
  public caFact: number;
  public iniFact: number;
  public refFact: number;
  public factFact: number;
  public netFact: number;

  public caRefRegion: number;
  public caFactRegion: number;
  public iniFactRegion: number;
  public refFactRegion: number;
  public factFactRegion: number;
  public netFactRegion: number;

  public caRefDomtom: number;
  public caFactDomtom: number;
  public iniFactDomtom: number;
  public refFactDomtom: number;
  public factFactDomtom: number;
  public netFactDomtom: number;

  public caRefThema: number;
  public caFactThema: number;
  public iniFactThema: number;
  public refFactThema: number;
  public factFactThema: number;
  public netFactThema: number;

  public caRefInter: number;
  public caFactInter: number;
  public iniFactInter: number;
  public refFactInter: number;
  public factFactInter: number;
  public netFactInter: number;

  public caRefF4: number;
  public caFactF4: number;
  public iniFactF4: number;
  public refFactF4: number;
  public factFactF4: number;
  public netFactF4: number;

  // Grp
  public grp: number;
  public cgrp: number;
  public estimatedGrp: number;
  public estimatedCgrp: number;
  public averageDurationRate: number;
  public coverage: number;
  public repeat: number;

  public typeInBase: PurchaseType;
  public signatoryFtpCode: any;

  // Id FSA
  public fsaId: number;
  public soreachPurchase: number;
  public alertSwitchDate?: string;

  constructor(jsonPurchase: JsonPurchase) {
    super();

    jsonPurchase = this.convertToUnderscore(jsonPurchase);

    this.id = jsonPurchase.id;
    this.name = jsonPurchase.name;
    this.dateOfExpiration = jsonPurchase.date_of_expiration;
    this.dateOfPurchase = jsonPurchase.date_of_purchase;
    this.comments = jsonPurchase.comments;
    this.startCommunicationPeriod = jsonPurchase.start_communication_period;
    this.endCommunicationPeriod = jsonPurchase.end_communication_period;
    this.customerBudget = jsonPurchase.customer_budget;
    this.mandateCertificate = jsonPurchase.mandate_certificate;
    this.temporaryAdvertiser = jsonPurchase.temporary_advertiser;
    this.temporaryProduct = jsonPurchase.temporary_product;
    this.temporaryMandatary = jsonPurchase.temporary_mandatary;
    this.temporarySubMandatary = jsonPurchase.temporary_sub_mandatary;

    this.grp = jsonPurchase.grp;
    this.cgrp = jsonPurchase.cgrp;
    this.estimatedGrp = jsonPurchase.estimated_grp;
    this.estimatedCgrp = jsonPurchase.estimated_cgrp;
    this.averageDurationRate = jsonPurchase.average_duration_rate;
    this.coverage = jsonPurchase.coverage;
    this.repeat = jsonPurchase.repeat;
    this.signatoryFtpCode = jsonPurchase.signatory_ftp_code;

    if (jsonPurchase.type) {
      this.type = new PurchaseType(jsonPurchase.type);
      this.typeInBase = new PurchaseType(jsonPurchase.type);
    }

    if (jsonPurchase.advertiser) {
      this.advertiser = new Advertiser(jsonPurchase.advertiser);
    }

    if (jsonPurchase.product) {
      this.product = new Product(jsonPurchase.product);
    }

    if (jsonPurchase.commercial) {
      this.commercial = new Employee(jsonPurchase.commercial);
    }

    if (jsonPurchase.sub_commercial) {
      this.subCommercial = new Employee(jsonPurchase.sub_commercial);
    }

    if (jsonPurchase.planning_id) {
      this.planning = new Employee(jsonPurchase.planning_id);
    }

    if (jsonPurchase.campaign) {
      this.campaign = new Campaign(jsonPurchase.campaign);
    }

    if (jsonPurchase.advertiser_signatory_name) {
      this.advertiserSignatoryName = jsonPurchase.advertiser_signatory_name;
    }

    if (jsonPurchase.advertiser_signatory_funct) {
      this.advertiserSignatoryFunct = jsonPurchase.advertiser_signatory_funct;
    }

    if (jsonPurchase.mandatary) {
      this.mandatary = new Customer(jsonPurchase.mandatary);
    }

    if (jsonPurchase.mandatary_signatory_name) {
      this.mandatarySignatoryName = jsonPurchase.mandatary_signatory_name;
    }

    if (jsonPurchase.mandatary_signatory_funct) {
      this.mandatarySignatoryFunct = jsonPurchase.mandatary_signatory_funct;
    }

    if (jsonPurchase.sub_mandatary) {
      this.subMandatary = new Customer(jsonPurchase.sub_mandatary);
    }

    if (jsonPurchase.sub_mandatary_signatory_name) {
      this.subMandatarySignatoryName = jsonPurchase.sub_mandatary_signatory_name;
    }

    if (jsonPurchase.sub_mandatary_signatory_funct) {
      this.subMandatarySignatoryFunct = jsonPurchase.sub_mandatary_signatory_funct;
    }

    if (jsonPurchase.compensation_purchase) {
      this.compensationPurchase = jsonPurchase.compensation_purchase;
    }

    if (jsonPurchase.reference_command) {
      this.referenceCommand = jsonPurchase.reference_command;
    }

    if (jsonPurchase.preferential_position) {
      this.preferentialPosition = jsonPurchase.preferential_position;
    }

    this.tvBudget = jsonPurchase.tv_budget || 0;

    this.tvClassicBudget = jsonPurchase.tv_classic_budget || 0;
    this.tvThemaBudget = jsonPurchase.tv_thema_budget || 0;
    this.tvRegionBudget = jsonPurchase.tv_region_budget || 0;
    this.tvInterBudget = jsonPurchase.tv_inter_budget || 0;
    this.tvDomtomBudget = jsonPurchase.tv_domtom_budget || 0;
    this.tvF4Budget = jsonPurchase.tv_f4budget || 0;

    this.tvBudgetNet = jsonPurchase.tv_budget_net || 0;

    this.tvClassicBudgetNet = jsonPurchase.tv_classic_budget_net || 0;
    this.tvThemaBudgetNet = jsonPurchase.tv_thema_budget_net || 0;
    this.tvRegionBudgetNet = jsonPurchase.tv_region_budget_net || 0;
    this.tvInterBudgetNet = jsonPurchase.tv_inter_budget_net || 0;
    this.tvDomtomBudgetNet = jsonPurchase.tv_domtom_budget_net || 0;
    this.tvF4BudgetNet = jsonPurchase.tv_f4budget_net || 0;

    this.digitalBudget = jsonPurchase.digital_budget || 0;
    this.digitalDisplayBudget = jsonPurchase.digital_display_budget || 0;

    this.digitalReplayBudget = jsonPurchase.digital_replay_budget || 0;
    this.digVidBillBudget = jsonPurchase.dig_vid_bill_budget || 0;
    this.digVidPreBudget = jsonPurchase.dig_vid_pre_budget || 0;
    this.digitalSocialBudget = jsonPurchase.digital_social_budget || 0;
    this.socialFbBudget = jsonPurchase.social_fb_budget || 0;
    this.socialTwitBudget = jsonPurchase.social_twit_budget || 0;
    this.socialInstaBudget = jsonPurchase.social_insta_budget || 0;
    this.digitalOtherBudget = jsonPurchase.digital_other_budget || 0;

    this.digitalBudgetNet = jsonPurchase.digital_budget_net || 0;
    this.digitalDisplayBudgetNet = jsonPurchase.digital_display_budget_net || 0;
    this.digitalReplayBudgetNet = jsonPurchase.digital_replay_budget_net || 0;
    this.digVidBillBudgetNet = jsonPurchase.dig_vid_bill_budget_net || 0;
    this.digVidPreBudgetNet = jsonPurchase.dig_vid_pre_budget_net || 0;
    this.digitalSocialBudgetNet = jsonPurchase.digital_social_budget_net || 0;
    this.socialFbBudgetNet = jsonPurchase.social_fb_budget_net || 0;
    this.socialTwitBudgetNet = jsonPurchase.social_twit_budget_net || 0;
    this.socialInstaBudgetNet = jsonPurchase.social_insta_budget_net || 0;
    this.digitalOtherBudgetNet = jsonPurchase.digital_other_budget_net || 0;
    this.totalBudget = jsonPurchase.total_budget || 0;
    this.totalBudgetNet = jsonPurchase.total_budget_net || 0;
    this.tvBudgetRate = jsonPurchase.tv_budget_rate || 0;

    this.tvClassicBudgetRate = jsonPurchase.tv_classic_budget_rate || 0;
    this.tvThemaBudgetRate = jsonPurchase.tv_thema_budget_rate || 0;
    this.tvRegionBudgetRate = jsonPurchase.tv_region_budget_rate || 0;
    this.tvInterBudgetRate = jsonPurchase.tv_inter_budget_rate || 0;
    this.tvDomtomBudgetRate = jsonPurchase.tv_domtom_budget_rate || 0;
    this.tvF4BudgetRate = jsonPurchase.tv_f4budget_rate || 0;

    this.digitalBudgetRate = jsonPurchase.digital_budget_rate || 0;
    this.digitalDisplayBudgetRate = jsonPurchase.digital_display_budget_rate || 0;
    this.digitalReplayBudgetRate = jsonPurchase.digital_replay_budget_rate || 0;
    this.digVidBillBudgetRate = jsonPurchase.dig_vid_bill_budget_rate || 0;
    this.digVidPreBudgetRate = jsonPurchase.dig_vid_pre_budget_rate || 0;
    this.digitalSocialBudgetRate = jsonPurchase.digital_social_budget_rate || 0;
    this.socialFbBudgetRate = jsonPurchase.social_fb_budget_rate || 0;
    this.socialTwitBudgetRate = jsonPurchase.social_twit_budget_rate || 0;
    this.socialInstaBudgetRate = jsonPurchase.social_insta_budget_rate || 0;
    this.digitalOtherBudgetRate = jsonPurchase.digital_other_budget_rate || 0;
    this.totalBudgetRate = jsonPurchase.total_budget_rate || 0;

    if (jsonPurchase.secodip) {
      this.secodip = new Secodip(jsonPurchase.secodip);
    }

    if (jsonPurchase.target) {
      this.target = new Target(jsonPurchase.target);
    }

    this.caRef = jsonPurchase.ca_ref || 0;
    this.caFact = jsonPurchase.ca_fact || 0;
    this.iniFact = jsonPurchase.ini_fact;
    this.refFact = jsonPurchase.ref_fact;
    this.factFact = jsonPurchase.fact_fact;
    this.netFact = jsonPurchase.net_fact;

    this.caRefRegion = jsonPurchase.ca_ref_region || 0;
    this.caFactRegion = jsonPurchase.ca_fact_region || 0;
    this.iniFactRegion = jsonPurchase.ini_fact_region;
    this.refFactRegion = jsonPurchase.ref_fact_region;
    this.factFactRegion = jsonPurchase.fact_fact_region;
    this.netFactRegion = jsonPurchase.net_fact_region;

    this.caRefDomtom = jsonPurchase.ca_ref_domtom || 0;
    this.caFactDomtom = jsonPurchase.ca_fact_domtom || 0;
    this.iniFactDomtom = jsonPurchase.ini_fact_domtom;
    this.refFactDomtom = jsonPurchase.ref_fact_domtom;
    this.factFactDomtom = jsonPurchase.fact_fact_domtom;
    this.netFactDomtom = jsonPurchase.net_fact_domtom;

    this.caRefThema = jsonPurchase.ca_ref_thema || 0;
    this.caFactThema = jsonPurchase.ca_fact_thema || 0;
    this.iniFactThema = jsonPurchase.ini_fact_thema;
    this.refFactThema = jsonPurchase.ref_fact_thema;
    this.factFactThema = jsonPurchase.fact_fact_thema;
    this.netFactThema = jsonPurchase.net_fact_thema;

    this.caRefInter = jsonPurchase.ca_ref_inter || 0;
    this.caFactInter = jsonPurchase.ca_fact_inter || 0;
    this.iniFactInter = jsonPurchase.ini_fact_inter;
    this.refFactInter = jsonPurchase.ref_fact_inter;
    this.factFactInter = jsonPurchase.fact_fact_inter;
    this.netFactInter = jsonPurchase.net_fact_inter;

    this.caRefF4 = jsonPurchase.ca_ref_f4 || 0;
    this.caFactF4 = jsonPurchase.ca_fact_f4 || 0;
    this.iniFactF4 = jsonPurchase.ini_fact_f4;
    this.refFactF4 = jsonPurchase.ref_fact_f4;
    this.factFactF4 = jsonPurchase.fact_fact_f4;
    this.netFactF4 = jsonPurchase.net_fact_f4;

    // Set FSA Id
    this.fsaId = jsonPurchase.fsa_id;
    this.soreachPurchase = jsonPurchase.soreach_purchase;

    if (jsonPurchase.alert_switch_date && jsonPurchase.alert_switch_date.date) {
      this.alertSwitchDate = moment(jsonPurchase.alert_switch_date.date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    }
  }

  static init(): JsonPurchase {
    return {
      id: null,
      name: null,
      date_of_expiration: null,
      date_of_purchase: null,
      start_communication_period: null,
      end_communication_period: null,
      comments: null,
      reference_command: null,
      preferential_position: null,
      customer_budget: 0,
      commercial: null,
      sub_commercial: null,
      planning_id: null,
      type: null,
      advertiser: null,
      advertiser_signatory_name: null,
      advertiser_signatory_funct: null,
      product: null,
      campaign: null,
      mandatary: null,
      mandatary_signatory_name: null,
      mandatary_signatory_funct: null,
      sub_mandatary: null,
      sub_mandatary_signatory_name: null,
      sub_mandatary_signatory_funct: null,
      signatory_ftp_code: null,
      tv_budget: 0,
      tv_f4budget: 0,

      tv_classic_budget: 0,
      tv_thema_budget: 0,
      tv_region_budget: 0,
      tv_inter_budget: 0,
      tv_domtom_budget: 0,

      tv_budget_net: 0,
      tv_f4budget_net: 0,

      tv_classic_budget_net: 0,
      tv_thema_budget_net: 0,
      tv_region_budget_net: 0,
      tv_inter_budget_net: 0,
      tv_domtom_budget_net: 0,

      digital_budget: 0,
      digital_display_budget: 0,
      digital_replay_budget: 0,
      dig_vid_bill_budget: 0,
      dig_vid_pre_budget: 0,
      digital_social_budget: 0,
      social_fb_budget: 0,
      social_twit_budget: 0,
      social_insta_budget: 0,
      digital_other_budget: 0,
      digital_budget_net: 0,
      digital_display_budget_net: 0,
      digital_replay_budget_net: 0,
      dig_vid_bill_budget_net: 0,
      dig_vid_pre_budget_net: 0,
      digital_social_budget_net: 0,
      social_fb_budget_net: 0,
      social_twit_budget_net: 0,
      social_insta_budget_net: 0,
      digital_other_budget_net: 0,
      total_budget: 0,
      total_budget_net: 0,
      tv_budget_rate: 0,
      tv_f4budget_rate: 0,

      tv_classic_budget_rate: 0,
      tv_thema_budget_rate: 0,
      tv_region_budget_rate: 0,
      tv_inter_budget_rate: 0,
      tv_domtom_budget_rate: 0,

      digital_budget_rate: 0,
      digital_display_budget_rate: 0,
      digital_replay_budget_rate: 0,
      dig_vid_bill_budget_rate: 0,
      dig_vid_pre_budget_rate: 0,
      digital_social_budget_rate: 0,
      social_fb_budget_rate: 0,
      social_twit_budget_rate: 0,
      social_insta_budget_rate: 0,
      digital_other_budget_rate: 0,
      total_budget_rate: 0,
      secodip: null,
      mandate_certificate: null,
      temporary_advertiser: null,
      temporary_product: null,
      temporary_mandatary: null,
      temporary_sub_mandatary: null,
      grp: 0,
      cgrp: 0,
      estimated_grp: 0,
      estimated_cgrp: 0,
      average_duration_rate: 0,
      compensation_purchase: null,
      coverage: 0,
      repeat: 0,

      // Tv classic
      ca_ref: 0,
      ca_fact: 0,
      ini_fact: 0,
      ref_fact: 0,
      fact_fact: 0,
      net_fact: 0,

      // Tv region
      ca_ref_region: 0,
      ca_fact_region: 0,
      ini_fact_region: 0,
      ref_fact_region: 0,
      fact_fact_region: 0,
      net_fact_region: 0,

      // Tv domtom
      ca_ref_domtom: 0,
      ca_fact_domtom: 0,
      ini_fact_domtom: 0,
      ref_fact_domtom: 0,
      fact_fact_domtom: 0,
      net_fact_domtom: 0,

      // Tv thema
      ca_ref_thema: 0,
      ca_fact_thema: 0,
      ini_fact_thema: 0,
      ref_fact_thema: 0,
      fact_fact_thema: 0,
      net_fact_thema: 0,

      // Tv international
      ca_ref_inter: 0,
      ca_fact_inter: 0,
      ini_fact_inter: 0,
      ref_fact_inter: 0,
      fact_fact_inter: 0,
      net_fact_inter: 0,

      // Tv france 4
      ca_ref_f4: 0,
      ca_fact_f4: 0,
      ini_fact_f4: 0,
      ref_fact_f4: 0,
      fact_fact_f4: 0,
      net_fact_f4: 0,

      fsa_id: null,
      soreach_purchase: null,
    };
  }

  public getComunicationPeriodYear(): number {
    return this.startCommunicationPeriod ? moment(this.startCommunicationPeriod, 'YYYY-MM-DD').year() : null;
  }
}

export interface JsonPurchaseDotation {
  id: number;
  name: string;
}

export class PurchaseDotation extends Base {

  public id: number;
  public name: string;

  constructor(jsonPurchaseDotation: JsonPurchaseDotation) {
    super();

    this.id = jsonPurchaseDotation.id;
    this.name = jsonPurchaseDotation.name;
  }
}
