import { Base } from './base.resource';
import { Channel } from './channel.resource';

export interface JsonEmailRecipient {
    id?: string;
    email?: string;
}

export class EmailRecipient extends Base {

    public id: string;
    public email: string;

    constructor(jsonEmailRecipient: JsonEmailRecipient) {
        super();
        jsonEmailRecipient = this.convertToUnderscore(jsonEmailRecipient);

        this.id = jsonEmailRecipient.id;
        this.email = jsonEmailRecipient.email;
    }
}
