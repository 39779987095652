<section class="normal-screen" [ngClass]="{'full-screen': isFullScreen}">
  <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  <div fxLayout="row" fxLayoutGap="2px" class="margin-top full-width">
    <div fxLayout="column" class="name box-week" fxLayoutAlign="end center">
      <button mat-icon-button class="button-screen" *ngIf="!loading && !isFullScreen" (click)="fullScreen()">
        <mat-icon class="button-screen-icon">fullscreen</mat-icon>
      </button>
      <button mat-icon-button class="button-screen-exit" *ngIf="!loading && isFullScreen" (click)="removeFullScreen()">
        <mat-icon class="button-screen-icon">fullscreen_exit</mat-icon>
      </button>
      <span>Semaines</span>
      <span>Début de semaine</span>
    </div>
    <div fxLayout="column" class="full-width">
      <div fxLayout="row" class="full-width">
        <div *ngFor="let month of months; last as isLast" fxLayoutAlign="center center" class="box-week box-month border-all" [ngStyle]="{'width.%': month.size}" [ngClass]="{'doFlex': isLast}">
          <span class="font-size">{{ month.name }}</span>
        </div>
      </div>
      <mat-grid-list cols="53" rowHeight="15px" class="full-width-less" [gutterSize]="'2px'">
        <mat-grid-tile *ngFor="let week of weeks;first as isFirst; last as isLast;" [colspan]="1" [rowspan]="1" class="box-week" [ngClass]="{'border-left': isFirst, 'border-right': isLast || week.separated}">
          <span class="thin font-size">{{ week.number }}</span>
        </mat-grid-tile>
      </mat-grid-list>
      <mat-grid-list cols="53" rowHeight="15px" class="full-width-less" [gutterSize]="'2px'" [ngClass]="{'border-bottom': numberMonths.length}">
        <mat-grid-tile *ngFor="let numberMonth of numberMonths;first as isFirst; last as isLast;" [colspan]="1" [rowspan]="1" class="box-week" [ngClass]="{'border-left': isFirst, 'border-right': isLast || numberMonth.separated}">
          <span class="thin font-size">{{ numberMonth.number }}</span>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </div>

  <cdk-virtual-scroll-viewport *ngIf="!loading" itemSize="50" class="table" [ngClass]="{'full-table': isFullScreen}">
    <div *cdkVirtualFor="let offer of sourceSynthesis; templateCacheSize: 50" fxLayout="column">
      <div fxLayout="row" fxLayoutGap="2px">
        <div class="name"></div>
        <mat-grid-list [cols]="offer['deprogWeekStatus'].length" rowHeight="15px" class="full-width" [gutterSize]="'0px'">
          <mat-grid-tile *ngFor="let deprogStatus of offer['deprogWeekStatus']; let index = index" [colspan]="1" [rowspan]="1">
            <mat-icon
              *ngIf="offer['deprogWeekStatus'][index] > 0 || offer['exceptions'][index] > 0" class="icon-warning-synthesis"
              [ngClass]="offer['deprogWeekStatus'][index] > 0 && offer['exceptions'][index] == 0 ? 'warning-deprog' : offer['deprogWeekStatus'][index] == 0 && offer['exceptions'][index] > 0 ? 'warning-exception' : 'warning-both'"
            >
              warning
            </mat-icon>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
      <div fxLayout="row" fxLayoutGap="2px">
        <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center" class="name box-name">
          <div fxLayoutGap="2px" class="box-image" fxFlexOffset="10px" style="width:80px;">
            <img *ngFor="let channel of offer['channels']" class="logo" [src]="path + channel + '.png'">
          </div>
          <a [routerLink]="['/availabilities']" class="cursor decoration" target="_blank" queryParamsHandling="merge"
             [queryParams]="{offer_id: offer['id'], offer_name: offer['name'], view: 'offer', year: filters.year}">
            {{ offer['name'] }}
          </a>
        </div>
        <div fxLayout="column" fxFlex fxLayoutGap="1px">
          <div *ngFor="let max of offer['advertiser']; last as isLast" fxLayout="row" class="background" [ngClass]="{'soReach-background-top': max[0] && max[0].soreach_purchase, 'soReach-background-bot': max[0] && max[0].soreach_purchase && isLast}">
            <mat-grid-list [cols]="weeks.length" rowHeight="20px" class="full-width" [gutterSize]="'0px'">
              <mat-grid-tile *ngFor="let check of max; first as isFirst" [colspan]="check.duration || 0" [rowspan]="1" class="box" [ngClass]="{'disable': check?.status === 0, 'purchase': check?.status === 1, 'option': check?.status === 2, 'alert': check?.status === 5, 'reconduct': check?.status === 10}">
                <a *ngIf="check && check.advertiser" [routerLink]="['/purchases/edit/' + check.id]" class="text-name cursor" target="_blank" [matTooltip]="check.tooltip" matTooltipClass="styleTooltip">
                  <span class="text-name" *ngIf="!view">{{ check.advertiser }}</span>
                  <span class="text-name" *ngIf="view">{{ check.product_name }}</span>
                </a>
              </mat-grid-tile>
            </mat-grid-list>
          </div>
        </div>
      </div>
    </div>
  </cdk-virtual-scroll-viewport>
</section>
