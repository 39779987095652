import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { RegionFilterSynthesis } from '../../../../resource/synthesis-region';

@Component({
  selector: 'app-region-dispo',
  templateUrl: './region-dispo.component.html',
  styleUrls: ['./region-dispo.component.scss']
})
export class RegionDispoComponent implements OnInit {
  public filter: RegionFilterSynthesis;
  public programCtrl: FormControl = new FormControl();

  constructor() {
    this.filter = new RegionFilterSynthesis({
      year: new Date().getFullYear()
    });
  }

  ngOnInit() {}
}
