<p fxLayoutAlign="end center" class="position-infomation">
  <span *ngIf="broadcastsPurchasedCount <= 1" class="thin color-text">{{ broadcastsPurchasedCount }} diffusion </span>
  <span *ngIf="broadcastsPurchasedCount > 1" class="thin color-text">{{ broadcastsPurchasedCount }} diffusions</span>
  <span *ngIf="min && max" class="thin color-text">&nbsp;du {{ min.startTime | momentPipe:'DD/MM/YYYY'}} au {{ max.startTime| momentPipe:'DD/MM/YYYY'}}</span>
</p>

<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

<mat-card>
  <mat-card-content>
    <div class="table-scroll border-radius">
      <table mat-table [dataSource]="broadcastsPurchased" matSort matSortActive="startTime" matSortDirection="asc" matSortDisableClear>
        <ng-container matColumnDef="startTime">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
          <td mat-cell *matCellDef="let broadcast" class="center-td thin">
              &nbsp;&nbsp;{{ broadcast.startTime| momentPipe:'dd'}} {{ broadcast.startTime| momentPipe:'DD/MM/YYYY'}}
          </td>
        </ng-container>
        <ng-container matColumnDef="unprograme">
          <th mat-header-cell *matHeaderCellDef>Déprogrammation</th>
          <td class="center-td" mat-cell *matCellDef="let broadcast">
            <mat-icon class="warning" *ngIf="broadcast.broadcast.broadcastType === 1">warning</mat-icon>
          </td>
        </ng-container>
        <ng-container matColumnDef="price">
          <th mat-header-cell *matHeaderCellDef>Prix</th>
          <td class="center-td thin" mat-cell *matCellDef="let broadcast">
            {{ broadcast.price | roundTo:2 | number:'0.0-2':'fr' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="soreachBillboardPrice">
          <th mat-header-cell *matHeaderCellDef>Prix Net Billboard</th>
          <td class="center-td thin" mat-cell *matCellDef="let broadcast">
            {{ broadcast.soreachBillboardPrice | roundTo:2 | number:'0.0-2':'fr' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="statusLabel">
          <th mat-header-cell *matHeaderCellDef>Statut</th>
          <td class="center-td bold" mat-cell *matCellDef="let broadcast">
            {{ broadcast.statusLabel }}
          </td>
        </ng-container>
        <ng-container matColumnDef="broadcast.startTimeLabel">
          <th mat-header-cell *matHeaderCellDef>Début</th>
          <td class="center-td thin" mat-cell *matCellDef="let broadcast">
            {{ broadcast.broadcast.startTimeLabel | broadcastTimeLabel }}
          </td>
        </ng-container>
        <ng-container matColumnDef="broadcast.broadcast.endTimeLabel">
          <th mat-header-cell *matHeaderCellDef>Fin</th>
          <td class="center-td thin" mat-cell *matCellDef="let broadcast">
            {{ broadcast.broadcast.endTimeLabel | broadcastTimeLabel }}
          </td>
        </ng-container>
        <ng-container matColumnDef="remove">
          <th mat-header-cell *matHeaderCellDef fxLayoutGap="10px">
            <button [disabled]="(listDeleteBroadcasts.length === 0) || loading" mat-flat-button class="red button-flat" (click)="deleteBroadcasts()">Supprimer</button>
            <button mat-mini-fab *ngIf="selectedAllBox" (click)="selectAllBox()">
              <mat-icon>filter_none</mat-icon>
            </button>
            <button mat-mini-fab *ngIf="!selectedAllBox" (click)="selectAllBox()">
              <mat-icon>done_all</mat-icon>
            </button>
          </th>
          <td fxLayoutAlign="center center" mat-cell *matCellDef="let broadcast; let i = index">
            <mat-checkbox disableRipple="true" *ngIf="broadcast.statusLabel !== 'facturé'" (click)="checkDelete(broadcast, i)" [checked]="checkedDelete[i]"></mat-checkbox>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="matHeaderRowDefArray; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: matRowDefArray;"></tr>
      </table>
    </div>
  </mat-card-content>
</mat-card>
