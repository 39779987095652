import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Purchase } from '../../../../resource/purchase.resource';
import { Offer } from '../../../../resource/offer.resource';
import { PurchaseGrp } from '../../../../resource/purchase-grp.resource';
import { GrpFormService } from '@service/grp/grp-form.service';
import { OfferService } from '@service/offer/offer.service';
import { PurchaseProgramService } from '@service/purchase-program/purchase-program.service';
import { PurchaseService } from '@service/purchase/purchase.service';
import { PurchaseGrpService } from '@service/purchase-grp/purchase-grp.service';

@Component({
  selector: 'app-purchase-grp',
  templateUrl: './purchase-grp.component.html',
  styleUrls: ['./purchase-grp.component.scss']
})
export class PurchaseGrpComponent implements OnInit, OnChanges {

  @Input() purchase: Purchase;

  public offer: Offer;
  public purchaseGrp: PurchaseGrp;

  public estimatedPurchaseGrpAvailable: boolean;
  public estimatedOfferGrpAvailable: boolean;

  public grpForm: FormGroup;

  constructor(
    private grpFormService: GrpFormService,
    private offerService: OfferService,
    private purchaseProgramService: PurchaseProgramService,
    private purchaseService: PurchaseService,
    private purchaseGrpService: PurchaseGrpService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.initForm();
    this.grpForm.get('grp').valueChanges
    .subscribe(() => {
      this.updateRepetitionValue();
    });
    this.grpForm.get('coverage').valueChanges
    .subscribe(() => {
      this.updateRepetitionValue();
    });
  }

  ngOnChanges(changes: any): void {
    if (changes.purchase) {
      this.purchase = changes.purchase.currentValue;
    }
    if (this.purchase && this.purchase.id && (this.offer == null)) {
      this.initOffer();
    }
  }

  /**
   * Calculate automatically repetition value (grp/coverage)
   */
  public updateRepetitionValue(): void {
    let repetitionValue = null;

    const grp = this.grpForm.get('grp').value;
    const coverage = this.grpForm.get('coverage').value;

    const isGrpValid = (grp != null && grp !== '' && !Number.isNaN(grp));
    const isCoverageValid = (coverage != null && coverage !== '' && !Number.isNaN(coverage) && coverage !== 0);

    if (isGrpValid && isCoverageValid) {
      repetitionValue = (grp / coverage);
    }

    this.purchase.repeat = repetitionValue;
    this.grpForm.patchValue({repeat: repetitionValue});
  }

  /**
   * Update Grp form fields
   *
   * @param {any} event
   *
   * @memberOf PurchaseGrpComponent
   */
  public updateCgrp(event) {
    const controlName = event.target.attributes.formcontrolname.nodeValue; // determine if this is estimatedGrp or Grp field
    this.grpFormService.update(this.grpForm, controlName);
    this.purchase.estimatedCgrp =  this.purchaseService.purchase.estimatedCgrp;
    this.purchase.cgrp = this.purchaseService.purchase.cgrp;
  }

  public updatePurchase() {
    this.purchase.coverage = this.grpForm.get('coverage').value;
    this.purchase.repeat = this.grpForm.get('repeat').value;
  }

  private initOffer() {
    this.purchaseProgramService.getList({purchase_id: this.purchase.id, groups: 'purchaseProgramLoad'})
    .subscribe(purchasePrograms => {
      if ((purchasePrograms)
        && ((purchasePrograms.length) > 0)) {
        this.offer = purchasePrograms[0].offerProgram.offer;
      }
    });
  }

  private initForm(): void {
    this.grpForm = this.fb.group({
        grp: ['', [Validators.required]],
        cgrp: ['', [Validators.required]],
        estimatedGrp: ['', [Validators.required]],
        estimatedCgrp: ['', [Validators.required]],
        coverage: ['', [Validators.required]],
        repeat: [{value: '', disabled: true}, [Validators.required]],
    });
    this.populateForm();

    this.purchaseGrpService.purchaseGrpSource$.subscribe(
      purchaseGrp => {
        this.purchaseGrp = purchaseGrp;

        // Default values
        this.estimatedPurchaseGrpAvailable = false;
        this.estimatedOfferGrpAvailable = false;

        // Setting GRP flag for purchase
        if (this.purchaseGrp.estimatedPurchaseGrp > 0 &&
          this.purchaseGrp.estimatedPurchaseCoverage > 0 &&
          this.purchaseGrp.estimatedPurchaseRepeat > 0
        ) {
          this.estimatedPurchaseGrpAvailable = true;
        }

        // Setting GRP flag for offer
        if (this.purchaseGrp.estimatedPurchaseGrpForPurchase > 0 &&
          this.purchaseGrp.estimatedPurchaseCoverageForPurchase > 0 &&
          this.purchaseGrp.estimatedPurchaseRepeatForPurchase > 0
        ) {
          this.estimatedOfferGrpAvailable = true;
        }
      }
    );
    if (this.purchase.id) {
      this.purchaseGrpService.updatePurchaseGrp(this.purchase.id);
    }
  }

  private populateForm(): void {
    if (this.purchase) {
      this.grpForm.controls['grp'].setValue(this.purchase.grp);
      this.grpForm.controls['estimatedGrp'].setValue(this.purchase.estimatedGrp);
      this.grpForm.controls['coverage'].setValue(this.purchase.coverage);
      this.grpForm.controls['repeat'].setValue(this.purchase.repeat);
    }
  }
}
