<div class="lot-dotation-container" fxLayout="column">
  <div fxFlex="4" class="advertiser-form">
    <mat-progress-bar *ngIf="loadingInputAdvertiser || loadingProducts || loadingAdvertiserLotsDotation || isDeleting"
                      mode="indeterminate"></mat-progress-bar>
    <form [formGroup]="advertiserInformationGroup" class="full-width">
      <mat-card fxLayout="row" fxLayoutAlign="space-between center">
        <mat-form-field class="form-field-advertiser">
          <input matInput appRemoveAccents placeholder="Annonceur" [matAutocomplete]="advertiserAutocomplete"
                 formControlName="advertiser">
        </mat-form-field>
        <mat-autocomplete [displayWith]="autocompleteService.displayAutocomplete.bind(this)"
                          #advertiserAutocomplete="matAutocomplete" (optionSelected)="selectedOption($event)">
          <mat-option *ngFor="let advertiser of filteredAdvertiserItems | async" [value]="advertiser">
            {{ advertiser.display }} ({{ advertiser.value }})
          </mat-option>
        </mat-autocomplete>
        <mat-form-field class="form-field-year">
          <input matInput placeholder="Année" formControlName="year" type="number">
          <mat-error>Veuillez vérifier le format de saisie (AAAA)</mat-error>
        </mat-form-field>
        <button mat-flat-button (click)="getLotDotationCollection()" [disabled]="loadingAdvertiserLotsDotation"
                class="black button-flat">
          Rechercher
        </button>
        <button mat-flat-button (click)="openNewLotDotationFormDialog()" [disabled]="loadingProducts"
                class="red button-flat" fxFlexOffset="2">
          Créer un lot dotation
        </button>
      </mat-card>
    </form>
  </div>

  <!-- Grid list of advertiser's lots -->
  <div class="grid-lot-dotation-list">
    <table mat-table [dataSource]="dataSource" *ngIf="dataSource.length">
      <!-- Product Column -->
      <ng-container matColumnDef="product">
        <th mat-header-cell *matHeaderCellDef>Marque</th>
        <td mat-cell *matCellDef="let element">{{element.product}}</td>
      </ng-container>

      <!-- Parroption Column -->
      <ng-container matColumnDef="parroption">
        <th mat-header-cell *matHeaderCellDef>Contrat</th>
        <td mat-cell *matCellDef="let element">{{element.parroptionName}}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Nom</th>
        <td mat-cell *matCellDef="let element">{{element.name}}</td>
      </ng-container>
      <mat-divider></mat-divider>

      <!-- Description Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let element" matTooltip="{{element.description}}">{{element.description}}</td>
      </ng-container>
      <mat-divider></mat-divider>

      <!-- UnitPrice Column -->
      <ng-container matColumnDef="unitPrice">
        <th mat-header-cell *matHeaderCellDef>Prix unitaire<br>(HT €)</th>
        <td mat-cell *matCellDef="let element"> {{element.unitPrice}}</td>
      </ng-container>
      <mat-divider></mat-divider>

      <!-- Quantity Column -->
      <ng-container matColumnDef="quantity">
        <th mat-header-cell *matHeaderCellDef>Quantité au contrat</th>
        <td mat-cell *matCellDef="let element">{{element.quantity}}</td>
      </ng-container>
      <mat-divider></mat-divider>

      <!-- Total Column -->
      <ng-container matColumnDef="total">
        <th mat-header-cell *matHeaderCellDef>Total<br>(HT €)</th>
        <td mat-cell *matCellDef="let element">{{element.total | number:'0.2-2':'fr'}}</td>
      </ng-container>

      <!-- Total of won lots -->
      <ng-container matColumnDef="wonLots">
        <th mat-header-cell *matHeaderCellDef>Lots saisis</th>
        <td mat-cell *matCellDef="let element">{{element.totalWonLots}}</td>
      </ng-container>

      <!-- Total of invoiced lots -->
      <ng-container matColumnDef="invoicedLots">
        <th mat-header-cell *matHeaderCellDef>Lots facturés à date de clotûre</th>
        <td mat-cell *matCellDef="let element">{{element.totalInvoicedLots}}</td>
      </ng-container>

      <!-- ending balance -->
      <ng-container matColumnDef="endingBalance">
        <th mat-header-cell *matHeaderCellDef>Solde à date de clotûre</th>
        <td mat-cell *matCellDef="let element">{{element.endingBalance}}</td>
      </ng-container>

      <mat-divider></mat-divider>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <div fxLayout="row" fxLayoutGap="5px">
            <button mat-mini-fab [disabled]="isDeleting" (click)="openNewLotDotationFormDialog(element)"
                    matTooltip="Modifier">
              <mat-icon>create</mat-icon>
            </button>
            <button mat-mini-fab [disabled]="isDeleting" (click)="openNewLotDotationFormDialog(element, true)"
                    matTooltip="Dupliquer">
              <mat-icon>content_copy</mat-icon>
            </button>
            <button mat-mini-fab [disabled]="isDeleting" (click)="deleteLotDotation(element.id)"
                    matTooltip="Supprimer">
              <mat-icon>delete_outline</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
