import { CustomerService } from '@service/customer/customer.service';
import { SecodipService } from '@service/secodip/secodip.service';
import { CampaignService } from '@service/campaign/campaign.service';
import { AdvertiserService } from '@service/advertiser/advertiser.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Observable ,  of ,  Subject } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';

import { Purchase } from '../../../../resource/purchase.resource';
import { Employee } from '../../../../resource/employee.resource';
import { TargetService } from '@service/target/target.service';
import { EmployeeService } from '@service/employee/employee.service';
import { FilteredItem } from '../../../../resource/filteredItem.resource';

import { FiltersService } from '../../../availability/service/filters.service';
import { AutocompleteService } from '@service/utilities/autocomplete.service';
import { PurchaseService } from '@service/purchase/purchase.service';
import { ProductService } from '@service/product/product.service';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';

import { AddSignataireDialogComponent } from '../../../../shared/dialog/add-signataire-dialog/add-signataire-dialog.component';
import { AddFunctionDialogComponent } from '../../../../shared/dialog/add-function-dialog/add-function-dialog.component';
import { SignataireService } from '@service/signataire/signataire.service';

import { takeUntil, distinctUntilChanged, debounceTime, startWith, map, filter, catchError, tap } from 'rxjs/operators';

import * as moment from 'moment';

@Component({
  selector: 'app-purchase-stakeholder',
  templateUrl: './purchase-stakeholder.component.html',
  styleUrls: ['./purchase-stakeholder.component.scss'],
  providers: [ FiltersService ]
})
export class PurchaseStakeholderComponent implements OnInit, OnDestroy {
  @Input() purchase: Purchase;
  @Input() stakeholderGroup: FormGroup;

  public localization: Object;

  public mandatCertificateItems;

  private targetItems: FilteredItem[];
  private employeeCommercialItems: FilteredItem[];
  private employeeSubCommercialItems: FilteredItem[];
  private employeePlanningItems: FilteredItem[];
  private signatoryFtpItems: FilteredItem[];
  private advertiserItems: FilteredItem[];
  private productItems: FilteredItem[];
  private campaignItems: FilteredItem[];
  private secodipItems: FilteredItem[];
  private mandataryItems: FilteredItem[];
  private subMandataryItems: FilteredItem[];
  private compensationPurchaseItems: FilteredItem[];
  public campaignSelected: boolean = false;

  public filteredTargetItem: FilteredItem[];
  public filteredEmployeeCommercialItems: Observable<FilteredItem[]>;
  public filteredSubEmployeeCommercialItems: Observable<FilteredItem[]>;
  public filteredEmployeePlanningItems: Observable<FilteredItem[]>;
  public filteredSignatoryFtpItems: Observable<FilteredItem[]>;
  public filteredAdvertiserItems: Observable<FilteredItem[]>;
  public filteredProductItems: Observable<FilteredItem[]>;
  public filteredCampaignItems: Observable<FilteredItem[]>;
  public filteredSecodipItems: Observable<FilteredItem[]>;
  public filteredMandataryItems: Observable<FilteredItem[]>;
  public filteredSubMandataryItems: Observable<FilteredItem[]>;

  // Signataire
  public filteredAdvertiserSignatory: Observable<any[]>;
  public filteredMandatarySignatory: Observable<any[]>;
  public filteredSubMandatarySignatory: Observable<any[]>;

  public saveAdvertiserSignatory;
  public saveMandatarySignatory;
  public saveSubMandatarySignatory;
  public saveFonction;

  public filteredFonction: Observable<any[]>;
  public filteredAdvertiserFonction: Observable<any[]>;
  public filteredMandataryFonction: Observable<any[]>;
  public filteredSubMandataryFonction: Observable<any[]>;

  public loadingMainInformation: boolean = false;
  public loadingClient: boolean = false;
  public loading: boolean = false;

  public hintAdvertiser: boolean = false;
  public hintProduct: boolean = false;
  public hintMandatary: boolean = false;
  public hintSubMandatary: boolean = false;

  private componentDestroyed$: Subject<any> = new Subject();
  private sizeApi: number = 500; // Limite of page size from API

  constructor (
    private fb: FormBuilder,
    private purchaseService: PurchaseService,
    private advertiserService: AdvertiserService,
    private productService: ProductService,
    private targetService: TargetService,
    private campaignService: CampaignService,
    private employeeService: EmployeeService,
    private filterService: FiltersService,
    private secodipService: SecodipService,
    private customerService: CustomerService,
    public autocompleteService: AutocompleteService,
    private customToastrService: CustomToastrService,
    private signataireService: SignataireService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.initForm();
    this.initFonctionSignatory();
    this.switchHint(null, this.purchase);

    this.stakeholderGroup.valueChanges
      .pipe(
        debounceTime(100),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(data => {
        this.purchaseService.updateStakeholder(data);
        this.switchHint(data);
        this.checkConventionEnable(data);
      });

    // Clear campaign when change product
    this.stakeholderGroup.get('customer').get('product').valueChanges
      .pipe(
        debounceTime(250),
        filter((data) => !data.id),
        takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        this.stakeholderGroup.get('customer').get('campaign').setValue('');
      });

    this.mandatCertificateItems = Purchase.mandatCertificateItems;
    this.localization = this.filterService.getI18iForDatePicker();

    this.getDatas();
    this.setValueChanges();

    this.initChangesValues();
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.unsubscribe();
  }

  /**
   * Get all fonctions for signatory
   */
  initFonctionSignatory(): void {
    const customer = this.stakeholderGroup.get('customer').value;

    this.signataireService.getFonctionsListForFilter()
      .pipe(
        map((response) => response),
        tap(() => this.loading = false)
      ).subscribe((fonction) => {
        this.saveFonction = fonction;
        this.filteredAdvertiserFonction = of(fonction);
        this.filteredMandataryFonction = of(fonction);
        this.filteredSubMandataryFonction = of(fonction);
      },
      catchError((error) => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
        this.loading = false;
        return of([]);
      }));

    if (customer.advertiser && customer.advertiser.value && customer.advertiser.entity && customer.advertiser.entity.id) {
      this.filteredAdvertiserSignatory = this.signataireService.getSignatorysListForFilter(customer.advertiser.entity.id)
        .pipe(
          map((response) => response),
          tap((advertiser) => {
            this.saveAdvertiserSignatory = advertiser;
            this.loading = false;
          }),
          catchError((error) => {
            this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            this.loading = false;
            return of([]);
          })
        )
    }

    if (customer.mandatary && customer.mandatary.value && customer.mandatary.entity && customer.mandatary.entity.id) {
      this.filteredMandatarySignatory = this.signataireService.getSignatorysListForFilter(customer.mandatary.entity.id)
        .pipe(
          map((response) => response),
          tap((mandatary) => {
            this.saveMandatarySignatory = mandatary;
            this.loading = false;
          }),
          catchError((error) => {
            this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            this.loading = false;
            return of([]);
          })
        )
    }

    if (customer.subMandatary && customer.subMandatary.value && customer.subMandatary.entity && customer.subMandatary.entity.id) {
      this.filteredSubMandatarySignatory = this.signataireService.getSignatorysListForFilter(customer.subMandatary.entity.id)
        .pipe(
          map((response) => response),
          tap((subMandataire) => {
            this.saveSubMandatarySignatory = subMandataire;
            this.loading = false;
          }),
          catchError((error) => {
            this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            this.loading = false;
            return of([]);
          })
        )
    }
  }

  /**
   * Init value change FormControl
   * @memberOf PurchaseStakeholderComponent
   */
  initChangesValues(): void {
    this.stakeholderGroup.get('customer').get('advertiserSignatory').valueChanges
      .pipe(
        debounceTime(500),
        tap((item) => {
          if (item && item.entity && item.entity.signatory_function) {
                this.stakeholderGroup.get('customer').get('advertiserFonction')
                .setValue({value: item.entity.signatory_function, display: item.entity.signatory_function});
          } else if (!this.stakeholderGroup.get('customer').get('advertiserFonction').value &&
            item && item.entity && item.entity._embedded && item.entity._embedded.signatory_function &&
            item.entity._embedded.signatory_function.signatory_function) {
            this.stakeholderGroup.get('customer').get('advertiserFonction')
                .setValue({value: item.entity._embedded.signatory_function.signatory_function,
                display: item.entity._embedded.signatory_function.signatory_function});
          }
        }),
        filter((item) => typeof item === 'string'),
        takeUntil(this.componentDestroyed$)
      ).subscribe((name: string) => {
        const advertiser = this.stakeholderGroup.get('customer').get('advertiser').value;
        let filteredSignatory: Array<any>;
        let idAdvertiser: number;

        if (advertiser && advertiser.entity && advertiser.entity.id) {
          idAdvertiser = this.stakeholderGroup.get('customer').get('advertiser').value.entity.id;
        }

        if (name && name.length) {
          filteredSignatory = this.saveAdvertiserSignatory.filter((item) => item.display.toLowerCase().includes(name.toLowerCase()));
          this.filteredAdvertiserSignatory = of(filteredSignatory);
        } else {
          filteredSignatory = this.saveAdvertiserSignatory;
          this.filteredAdvertiserSignatory = of(filteredSignatory);
        }


        if (name && name.length && this.saveAdvertiserSignatory.length === this.sizeApi) {
          this.loading = true;
          this.signataireService.getSignatorysListForFilter(idAdvertiser, name)
            .pipe(
              takeUntil(this.componentDestroyed$)
            ).subscribe((items) => {
              items.forEach((item) => {
                if (filteredSignatory.findIndex((data) => data.entity.id === item.entity.id) === -1) {
                  filteredSignatory.push(item);
                }
              });
              this.filteredAdvertiserSignatory = of(filteredSignatory);
              this.loading = false;

            }, (error) => {
              this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            });
        }
      }, (error) => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.stakeholderGroup.get('customer').get('advertiserFonction').valueChanges
      .pipe(
        debounceTime(500),
        filter((item) => typeof item === 'string'),
        takeUntil(this.componentDestroyed$)
      ).subscribe((name: string) => {
        let filteredFunctions: Array<any>;

        if (name && name.length) {
          filteredFunctions = this.saveFonction.filter((item) => item.display.toLowerCase().includes(name.toLowerCase()));
          this.filteredAdvertiserFonction = of(filteredFunctions);
        } else {
          filteredFunctions = this.saveFonction;
          this.filteredAdvertiserFonction = of(filteredFunctions);
        }

        if (name && name.length && this.saveFonction.length === this.sizeApi) {
          this.loading = true;
          this.signataireService.getFonctionsListForFilter(name)
            .pipe(
              takeUntil(this.componentDestroyed$)
            ).subscribe((items) => {
              items.forEach((item) => {
                if (filteredFunctions.findIndex((data) => data.entity.id === item.entity.id) === -1) {
                  filteredFunctions.push(item);
                }
              });
              this.filteredAdvertiserFonction = of(filteredFunctions);
              this.loading = false;
            }, (error) => {
              this.loading = false;
              this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            })
        }
      }, (error) => {
        this.loading = false;
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.stakeholderGroup.get('customer').get('mandatarySignatory').valueChanges
      .pipe(
        debounceTime(500),
        tap((item) => {
          if (item && item.entity && item.entity.signatory_function) {
              this.stakeholderGroup.get('customer').get('mandataryFonction')
                .setValue({value: item.item.entity.signatory_function, display: item.item.entity.signatory_function});
          } else if (!this.stakeholderGroup.get('customer').get('mandataryFonction').value &&
            item && item.entity && item.entity._embedded && item.entity._embedded.signatory_function &&
            item.entity._embedded.signatory_function.signatory_function) {
            this.stakeholderGroup.get('customer').get('mandataryFonction')
              .setValue({value: item.entity._embedded.signatory_function.signatory_function,
                display: item.entity._embedded.signatory_function.signatory_function});
          }
        }),
        filter((item) => typeof item === 'string'),
        takeUntil(this.componentDestroyed$)
      ).subscribe((name: string) => {
        const mandatary = this.stakeholderGroup.get('customer').get('mandatary').value;
        let filteredSignatory: Array<any>;
        let idMandatary: number;

        if (mandatary && mandatary.entity && mandatary.entity.id) {
          idMandatary = this.stakeholderGroup.get('customer').get('mandatary').value.entity.id;
        }

        if (name && name.length) {
          filteredSignatory = this.saveMandatarySignatory.filter((item) => item.display.toLowerCase().includes(name.toLowerCase()));
          this.filteredMandatarySignatory = of(filteredSignatory);
        } else {
          filteredSignatory = this.saveMandatarySignatory;
          this.filteredMandatarySignatory = of(filteredSignatory);
        }


        if (name && name.length && this.saveMandatarySignatory.length === this.sizeApi) {
          this.loading = true;
          this.signataireService.getSignatorysListForFilter(idMandatary, name)
            .pipe(
              takeUntil(this.componentDestroyed$)
            ).subscribe((items) => {
              items.forEach((item) => {
                if (filteredSignatory.findIndex((data) => data.entity.id === item.entity.id) === -1) {
                  filteredSignatory.push(item);
                }
              });
              this.filteredMandatarySignatory = of(filteredSignatory);
              this.loading = false;
            }, (error) => {
              this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            });
        }
      }, (error) => {
        this.loading = false;
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.stakeholderGroup.get('customer').get('mandataryFonction').valueChanges
      .pipe(
        debounceTime(500),
        filter((item) => typeof item === 'string'),
        takeUntil(this.componentDestroyed$)
      ).subscribe((name: string) => {
        let filteredFunctions: Array<any>;

        if (name && name.length) {
          filteredFunctions = this.saveFonction.filter((item) => item.display.toLowerCase().includes(name.toLowerCase()));
          this.filteredMandataryFonction = of(filteredFunctions);
        } else {
          filteredFunctions = this.saveFonction;
          this.filteredMandataryFonction = of(filteredFunctions);
        }

        if (name && name.length && this.saveFonction.length === this.sizeApi) {
          this.loading = true;
          this.signataireService.getFonctionsListForFilter(name)
            .pipe(
              takeUntil(this.componentDestroyed$)
            ).subscribe((items) => {
              items.forEach((item) => {
                if (filteredFunctions.findIndex((data) => data.entity.id === item.entity.id) === -1) {
                  filteredFunctions.push(item);
                }
              });
              this.filteredMandataryFonction = of(filteredFunctions);
              this.loading = false;
            }, (error) => {
              this.loading = false;
              this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            })
        }
      }, (error) => {
        this.loading = false;
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.stakeholderGroup.get('customer').get('subMandatarySignatory').valueChanges
      .pipe(
        debounceTime(500),
        tap((item) => {
          if (item && item.entity && item.entity.signatory_function) {
              this.stakeholderGroup.get('customer').get('subMandataryFonction')
                .setValue({value: item.item.entity.signatory_function, display: item.item.entity.signatory_function});
          } else if (!this.stakeholderGroup.get('customer').get('subMandataryFonction').value &&
            item && item.entity && item.entity._embedded && item.entity._embedded.signatory_function &&
            item.entity._embedded.signatory_function.signatory_function) {
            this.stakeholderGroup.get('customer').get('subMandataryFonction')
              .setValue({value: item.entity._embedded.signatory_function.signatory_function,
                display: item.entity._embedded.signatory_function.signatory_function});
          }
        }),
        filter((item) => typeof item === 'string'),
        takeUntil(this.componentDestroyed$)
      ).subscribe((name: string) => {
        const subMandatary = this.stakeholderGroup.get('customer').get('subMandatary').value;
        let filteredSignatory: Array<any>;
        let idSubMandatary: number;

        if (subMandatary && subMandatary.entity && subMandatary.entity.id) {
          idSubMandatary = this.stakeholderGroup.get('customer').get('subMandatary').value.entity.id;
        }

        if (name && name.length) {
          filteredSignatory = this.saveSubMandatarySignatory.filter((item) => item.display.toLowerCase().includes(name.toLowerCase()));
          this.filteredSubMandatarySignatory = of(filteredSignatory);
        } else {
          filteredSignatory = this.saveSubMandatarySignatory;
          this.filteredSubMandatarySignatory = of(filteredSignatory);
        }


        if (name && name.length && this.saveSubMandatarySignatory.length === this.sizeApi) {
          this.loading = true;
          this.signataireService.getSignatorysListForFilter(idSubMandatary, name)
            .pipe(
              takeUntil(this.componentDestroyed$)
            ).subscribe((items) => {
            items.forEach((item) => {
              if (filteredSignatory.findIndex((data) => data.entity.id === item.entity.id) === -1) {
                filteredSignatory.push(item);
              }
            });
            this.filteredSubMandatarySignatory = of(filteredSignatory);
            this.loading = false;

          }, (error) => {
            this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
          });
        }
      }, (error) => {
        this.loading = false;
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.stakeholderGroup.get('customer').get('subMandataryFonction').valueChanges
      .pipe(
        debounceTime(500),
        filter((item) => typeof item === 'string'),
        takeUntil(this.componentDestroyed$)
      ).subscribe((name: string) => {
        let filteredFunctions: Array<any>;

        if (name && name.length) {
          filteredFunctions = this.saveFonction.filter((item) => item.display.toLowerCase().includes(name.toLowerCase()));
          this.filteredSubMandataryFonction = of(filteredFunctions);
        } else {
          filteredFunctions = this.saveFonction;
          this.filteredSubMandataryFonction = of(filteredFunctions);
        }

        if (name && name.length && this.saveFonction.length === this.sizeApi) {
          this.loading = true;
          this.signataireService.getFonctionsListForFilter(name)
            .pipe(
              takeUntil(this.componentDestroyed$)
            ).subscribe((items) => {
            items.forEach((item) => {
              if (filteredFunctions.findIndex((data) => data.entity.id === item.entity.id) === -1) {
                filteredFunctions.push(item);
              }
            });
            this.filteredSubMandataryFonction = of(filteredFunctions);
            this.loading = false;
          }, (error) => {
            this.loading = false;
            this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
          })
        }
    }, (error) => {
      this.loading = false;
      this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
    });
  }

  /**
   * Check and disable/enable if customer/mode is empty
   */
  disableInputSignataire(): void {
    const customer = this.stakeholderGroup.get('customer');

    if (customer.get('advertiser').valid && customer.get('advertiser').value.entity) {
      customer.get('advertiserSignatory').enable();
      customer.get('advertiserFonction').enable();
      customer.get('mandatary').enable();
      customer.get('mandatarySignatory').enable();
      customer.get('mandataryFonction').enable();
      customer.get('subMandatary').enable();
      customer.get('subMandatarySignatory').enable();
      customer.get('subMandataryFonction').enable();
    } else {
      // Advertiser
      this.purchase.advertiserSignatoryName = null;
      this.purchase.advertiserSignatoryFunct = null;
      customer.get('advertiserSignatory').setValue('');
      customer.get('advertiserSignatory').disable();
      customer.get('advertiserFonction').setValue('');
      customer.get('advertiserFonction').disable();
      // Mandatary
      this.purchase.mandatarySignatoryName = null;
      this.purchase.mandatarySignatoryFunct = null;
      this.purchase.mandatary = null;
      customer.get('mandatary').setValue('');
      customer.get('mandatary').disable();
      customer.get('mandatarySignatory').setValue('');
      customer.get('mandatarySignatory').disable();
      customer.get('mandataryFonction').setValue('');
      customer.get('mandataryFonction').disable();
      // Sub Mandatary
      this.purchase.subMandatarySignatoryName = null;
      this.purchase.subMandatarySignatoryFunct = null;
      this.purchase.subMandatary = null;
      customer.get('subMandatary').setValue('');
      customer.get('subMandatary').disable();
      customer.get('subMandatarySignatory').setValue('');
      customer.get('subMandatarySignatory').disable();
      customer.get('subMandataryFonction').setValue('');
      customer.get('subMandataryFonction').disable();
    }
  }

  onCampaignSelect(campaign: any) {
    let controlsMainInformation = this.stakeholderGroup.controls['mainInformation']['controls'];
    let controlsCustomer = this.stakeholderGroup.controls['customer']['controls'];

    if (campaign) {
      // flag selected campaign
      this.campaignSelected = true;
      this.loadingClient = true;

      if (campaign.entity.commercial) {
        controlsMainInformation['commercial'].setValue(campaign.entity.commercial ? new FilteredItem(campaign.entity.commercial) : '');
      }

      // if mandatary not found in campaign
      if (campaign.entity.commercial == null) {
        controlsCustomer['commercial'].setValue('');
      }

      if (campaign.entity.planning) {
        controlsMainInformation['planningUser'].setValue(campaign.entity.planning ? new FilteredItem(campaign.entity.planning) : '');
      }

      // if planning not found in campaign
      if (campaign.entity.planning == null) {
        controlsCustomer['planning'].setValue('');
      }

      if (campaign.entity.advertiser && !this.checkIsNewSignataire(campaign.entity.advertiser, 'advertiser')) {
        controlsCustomer['advertiser'].setValue(
          campaign.entity.advertiser ? new FilteredItem(campaign.entity.advertiser) : {display: this.purchase.temporaryAdvertiser}
        );
        this.getSignatorysAdvertiserListForFilter(campaign.entity.advertiser.id);
      }

      if (campaign.entity.mandatary && !this.checkIsNewSignataire(campaign.entity.mandatary, 'mandatary')) {
        controlsCustomer['mandatary'].setValue(
          campaign.entity.mandatary ?  new FilteredItem(campaign.entity.mandatary) : {display: this.purchase.temporaryMandatary}
        );
        this.getSignatorysMandataryListForFilter(campaign.entity.mandatary.id);
      }

      // if mandatary not found in campaign
      if (campaign.entity.mandatary == null) {
        controlsCustomer['mandatary'].setValue('');
        this.clearInputSignatory('mandatary');
      }

      if (campaign.entity.subMandatary && !this.checkIsNewSignataire(campaign.entity.subMandatary, 'subMandatary')) {
        controlsCustomer['subMandatary'].setValue(
          campaign.entity.subMandatary ?  new FilteredItem(campaign.entity.subMandatary) : {display: this.purchase.temporarySubMandatary}
        );
        this.getSignatorysSubMandataryListForFilter(campaign.entity.subMandatary.id);
      }

      // if submandatary not found in campaign
      if (campaign.entity.subMandatary == null) {
        controlsCustomer['subMandatary'].setValue('');
        this.clearInputSignatory('subMandatary');
      }

      // secteur aka secodip
      if (campaign.entity.secodip) {
        controlsCustomer['secodip'].setValue(campaign.entity.secodip ?  new FilteredItem(campaign.entity.secodip) : '');
      }

      // target (from campaign)
      if (campaign.entity.target) {
        controlsCustomer['target'].setValue(campaign.entity.target ?  new FilteredItem(campaign.entity.target) : '');
      }

      // if target not found in campaign
      if (campaign.entity.target == null) {
        controlsCustomer['target'].setValue('');
      }

      this.loadingClient = false;
    }
  }

  /**
   * Check if we need to reset signataire and fonction if it's not the same
   *
   * @param data
   * @param type
   */
  public checkIsNewSignataire(data: any, type: string): boolean {
    const response = this.stakeholderGroup.get('customer').get(type);

    return !!(response && response.value && response.value.entity && response.value.entity.id === data.id);
  }

  /**
   * Clear input signatory
   *
   * @param {string} type
   */
  public clearInputSignatory(type: string): void {
    this.stakeholderGroup.get('customer').get(`${type}Signatory`).setValue('');
    this.stakeholderGroup.get('customer').get(`${type}Fonction`).setValue('');
  }

  /**
   * returns selected campaign flag
   */
  public checkForSelectedCampaign(): boolean {
    return this.campaignSelected;
  }

  /**
   * Post select action on advertiser field
   *
   * @param {*} advertiser
   * @memberof PurchaseStakeholderComponent
   */
  onAdvertiserSelect(advertiser: any) {
    if (advertiser) {
      let controlsCustomer = this.stakeholderGroup.controls['customer']['controls'];

      // A quick and dirty way to not contatenate the id a second time
      advertiser.entity.name = advertiser.entity.name .replace(/ \([0-9]+\)$/, '');
      advertiser.entity.name = advertiser.entity.name + ' (' + advertiser.entity.id + ')';

      controlsCustomer['advertiser'].setValue(
        advertiser.entity ? new FilteredItem(advertiser.entity) : ''
      );

      if (advertiser && advertiser.value && advertiser.entity && advertiser.entity.id) {
        this.getSignatorysAdvertiserListForFilter(advertiser.entity.id);
      }
    }
  }

  /**
   * Get signatory list for filter
   *
   * @param advertiserId
   */
  getSignatorysAdvertiserListForFilter(advertiserId): void {
    this.signataireService.getSignatorysListForFilter(advertiserId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((advertiserResponse) => {
        this.saveAdvertiserSignatory = advertiserResponse;
        this.filteredAdvertiserSignatory = of(advertiserResponse);
        this.loading = false
      }, (error) => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
        this.loading = false;
        return of([]);
      });
  }

  /**
   * Post select action on product field
   *
   * @param {*} product
   * @memberof PurchaseStakeholderComponent
   */
  onProductSelect(product: any) {
    if (product) {
      let controlsCustomer = this.stakeholderGroup.controls['customer']['controls'];

      // Renaming inside the entity since we cant use strings in formControl.setValue()
      product.entity.advertiser.name = product.entity.advertiser.name .replace(/ \([0-9]+\)$/, '');
      product.entity.advertiser.name = product.entity.advertiser.name + ' (' + product.entity.advertiser.id + ')';
      product.entity.name = product.entity.name .replace(/ \([0-9]+\)$/, '');
      product.entity.name = product.entity.name + ' (' + product.entity.id + ')';

      if (this.purchase.advertiser) {
        if (this.purchase.advertiser.id !== product.entity.advertiser.id) {
          controlsCustomer['advertiser'].setValue(
            product.entity.advertiser ? new FilteredItem(product.entity.advertiser) : ''
          );
          controlsCustomer['product'].setValue(
            product.entity ? new FilteredItem(product.entity) : ''
          );
          controlsCustomer['campaign'].setValue('');
        } else {
          controlsCustomer['advertiser'].setValue(
            product.entity.advertiser ? new FilteredItem(product.entity.advertiser) : ''
          );
          controlsCustomer['product'].setValue(
            product.entity ? new FilteredItem(product.entity) : ''
          );
        }
      } else {
        controlsCustomer['advertiser'].setValue(
          product.entity.advertiser ? new FilteredItem(product.entity.advertiser) : ''
        );
        controlsCustomer['product'].setValue(
          product.entity ? new FilteredItem(product.entity) : ''
        );
      }
    }
  }

  /**
   * Post select action on mandatary field
   *
   * @param {*} mandatary
   * @memberof PurchaseStakeholderComponent
   */
  onMandatarySelect(mandatary: any) {
    if (mandatary) {
      let controlsCustomer = this.stakeholderGroup.controls['customer']['controls'];

      // A quick and dirty way to not contatenate the id a second time
      mandatary.entity.name = mandatary.entity.name .replace(/ \([0-9]+\)$/, '');
      mandatary.entity.name = mandatary.entity.name + ' (' + mandatary.entity.id + ')';

      controlsCustomer['mandatary'].setValue(
        mandatary.entity ? new FilteredItem(mandatary.entity) : ''
      );

      if (mandatary && mandatary.value && mandatary.entity && mandatary.entity.id) {
        this.getSignatorysMandataryListForFilter(mandatary.entity.id);
      }
    }
  }

  /**
   * Get signatory list for filter
   *
   * @param mandataryId
   */
  getSignatorysMandataryListForFilter(mandataryId): void {
    this.signataireService.getSignatorysListForFilter(mandataryId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((mandataire) => {
        this.saveMandatarySignatory = mandataire;
        this.filteredMandatarySignatory = of(mandataire);
        this.loading = false
      }, (error) => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
        this.loading = false;
        return of([]);
      });
  }

  /**
   * Post select action on mandatary certificate field
   *
   * @param {*} subMandatary
   * @memberof PurchaseStakeholderComponent
   */
  onSubMandatarySelect(subMandatary: any) {
    if (subMandatary) {
      let controlsCustomer = this.stakeholderGroup.controls['customer']['controls'];

      // A quick and dirty way to not contatenate the id a second time
      subMandatary.entity.name = subMandatary.entity.name .replace(/ \([0-9]+\)$/, '');
      subMandatary.entity.name = subMandatary.entity.name + ' (' + subMandatary.entity.id + ')';

      controlsCustomer['subMandatary'].setValue(
        subMandatary.entity ? new FilteredItem(subMandatary.entity) : ''
      );

      if (subMandatary && subMandatary.value && subMandatary.entity && subMandatary.entity.id) {
        this.getSignatorysSubMandataryListForFilter(subMandatary.entity.id);
      }
    }
  }

  /**
   * Get signatory list for filter
   *
   * @param subMandataryId
   */
  getSignatorysSubMandataryListForFilter(subMandataryId): void {
    this.signataireService.getSignatorysListForFilter(subMandataryId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((subMandataire) => {
        this.saveSubMandatarySignatory = subMandataire;
        this.filteredSubMandatarySignatory = of (subMandataire);
        this.loading = false
      }, (error) => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
        this.loading = false;
        return of([]);
      });
  }

  /**
   * Open dialog for add new signatory and set new signatory after close
   *
   * @param {string} section
   * @memberof PurchaseStakeholderComponent
   */
  openSignatoryDialog(section: string): void {
    let dialogRef;

    if (section === 'advertiserSignatory') {
      dialogRef = this.dialog.open(AddSignataireDialogComponent,
        { data: this.stakeholderGroup.get('customer').get('advertiser').value.entity.id });
    } else if (section === 'mandatarySignatory') {
      dialogRef = this.dialog.open(AddSignataireDialogComponent,
        { data: this.stakeholderGroup.get('customer').get('mandatary').value.entity.id });
    } else if (section === 'subMandatarySignatory') {
      dialogRef = this.dialog.open(AddSignataireDialogComponent,
        { data: this.stakeholderGroup.get('customer').get('subMandatary').value.entity.id });
    }

    dialogRef.afterClosed()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data: any) => {
        if (data && data.first_name && data.last_name) {
          data['display'] = `${data.first_name} ${data.last_name}`;
          this.stakeholderGroup.get('customer').get(section).setValue(new FilteredItem(
            {name: null, first_name: data.first_name, last_name: data.last_name, id: data['customer_id']}));
        } else {
          this.stakeholderGroup.get('customer').get(section).setValue(null);
        }
      }, error => {
        this.customToastrService.displayToastr('ERREUR', error.data.detail + 'Enregistrement échoué :');
      });
  }

  /**
   * Open dialog for add new function and set new function after close
   *
   * @param section
   * @memberof PurchaseStakeholderComponent
   */
  openFunctionDialog(section: string): void {
    const dialogRef = this.dialog.open(AddFunctionDialogComponent);

    dialogRef.afterClosed()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data: any) => {
        if (data && data.signatory_function) {
          data['display'] = data.signatory_function;
          this.stakeholderGroup.get('customer').get(section).setValue(new FilteredItem({name: data.display, id: 1}));
        } else {
          this.stakeholderGroup.get('customer').get(section).setValue(null);
        }
      }, error => {
        this.customToastrService.displayToastr('ERREUR', error.data.detail + 'Enregistrement échoué :');
      });
  }

  private initForm(): void {
    this.populateForm();
  }

  private getDatas() {
    // Target List
    this.targetService.getListForFilter()
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(targetItems => {
        this.targetItems = targetItems;
        this.purchaseService.savePurchaseForCompare('target');
      });

    // Signatory List
    this.signataireService.getSignatoryFtpListForFilter()
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(signatoryFtpItems => {
        this.signatoryFtpItems = signatoryFtpItems;
        this.filteredSignatoryFtpItems = of(this.signatoryFtpItems);
        if (this.stakeholderGroup.get('mainInformation').get('signatoryFtp').value) {
          const value = this.signatoryFtpItems
              .find((item) => item.value === this.stakeholderGroup.get('mainInformation').get('signatoryFtp').value);
          this.stakeholderGroup.get('mainInformation').get('signatoryFtp').setValue(value);
        }
        this.purchaseService.savePurchaseForCompare('signatory');
      });
  }

  private setValueChanges() {
    const controlsCustomer = this.stakeholderGroup.controls['customer']['controls'];
    const controlsMainInformation = this.stakeholderGroup.controls['mainInformation']['controls'];

    // Target
    this.filteredTargetItem = controlsCustomer['target'].valueChanges
      .pipe(
        startWith(null),
        debounceTime(100),
        takeUntil(this.componentDestroyed$),
        map((value: string) => value ? this.autocompleteService.filterItems(value, this.targetItems) : this.targetItems)
      );

    // Signataire FTP
    controlsMainInformation['signatoryFtp'].valueChanges
      .pipe(
        startWith(null),
        debounceTime(100),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((data) => {
        this.filteredSignatoryFtpItems =
          of(data ? this.autocompleteService.filterItems(data, this.signatoryFtpItems) : this.signatoryFtpItems);
      });

    // Commercial
    controlsMainInformation['commercial'].valueChanges
      .pipe(
        startWith(null),
        debounceTime(400),
        distinctUntilChanged(),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(value => {
        if (!value) { return; }
        this.loadingMainInformation = true;

        this.employeeService.getListForFilter({
            user : value,
            employee_type_id:  Employee.commercialType.join(';'),
            commercial_cell_id: Employee.commercialCell.join(';')
        })
        .subscribe(employeeItems => {
          if (employeeItems && employeeItems.length > 0) {
              this.employeeCommercialItems = employeeItems;
              this.filteredEmployeeCommercialItems = of(
                  this.autocompleteService.filterItems(value, this.employeeCommercialItems)
              );
          }

          this.loadingMainInformation = false;
        }, error => {
          this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
          this.loadingMainInformation = false;
          });

    });

    // Sub Commercial
    controlsMainInformation['subCommercial'].valueChanges
    .pipe(
      startWith(null),
      debounceTime(400),
      distinctUntilChanged(),
      takeUntil(this.componentDestroyed$)
    )
    .subscribe(value => {
      if (!value) { return; }
      this.loadingMainInformation = true;

      this.employeeService.getListForFilter({
          user : value,
          commercial_cell_id: Employee.commercialCell.join(';'),
          is_sub_commercial: true
      })
      .subscribe(employeeItems => {
        if (employeeItems && employeeItems.length > 0) {
            this.employeeSubCommercialItems = employeeItems;
            this.filteredSubEmployeeCommercialItems = of(
                this.autocompleteService.filterItems(value, this.employeeSubCommercialItems)
            );
        }

        this.loadingMainInformation = false;
      }, error => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
        this.loadingMainInformation = false;
        });
    });

    // Planning
    controlsMainInformation['planningUser'].valueChanges
    .pipe(
      startWith(null),
      debounceTime(400),
      distinctUntilChanged(),
      takeUntil(this.componentDestroyed$)
    )
    .subscribe(value => {
      if (!value) { return; }
      this.loadingMainInformation = true;

      this.employeeService.getListForFilter({
          user : value,
          employee_type_id: Employee.planningType.join(';')
      })
      .subscribe(employeeItems => {
        if (employeeItems && employeeItems.length > 0) {
            this.employeePlanningItems = employeeItems;
            this.filteredEmployeePlanningItems = of(
                this.autocompleteService.filterItems(value, this.employeePlanningItems)
            );
        }
        this.loadingMainInformation = false;
      }, error => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
        this.loadingMainInformation = false;
      });
    });

    // Advertiser
    controlsCustomer['advertiser'].valueChanges
      .pipe(
        startWith(null),
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(value => {
        this.disableInputSignataire();
        this.filteredAdvertiserItems = of([]);
        if (!value || typeof value !== 'string') {
          if (value === '') {
            this.filteredAdvertiserSignatory = of([]);
            this.purchase.advertiserSignatoryName = null;
            this.purchase.advertiserSignatoryFunct = null;
            controlsCustomer['advertiserSignatory'].setValue('');
            controlsCustomer['advertiserFonction'].setValue('');
          }
          return;
        }
        this.loadingClient = true;
        const filterAdvertiser =  Number(value) ? {id: value.toUpperCase()} : {name: value.toUpperCase()};

        this.advertiserService.getListForFilter(filterAdvertiser)
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe((advertiser) => {
            this.filteredAdvertiserItems = of(
              this.autocompleteService.filterItems(value, advertiser)
              );
            this.loadingClient = false;
          }, error => {
            this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            this.loadingClient = false;
            });
      });

    // Product
    controlsCustomer['product'].valueChanges
    .pipe(
      startWith(null),
      debounceTime(500),
      distinctUntilChanged(),
      takeUntil(this.componentDestroyed$)
    )
    .subscribe(value => {
      this.filteredProductItems = of([]);
      if (!value || typeof value !== 'string') {
        return;
      }
      this.loadingClient = true;
      let urlParams = Number(value) ? {id: value.toUpperCase()} : {name: value.toUpperCase()};

      // Optionnal advertiser id parameter
      if (this.purchase.advertiser != null) {
        urlParams['advertiser_id'] = this.purchase.advertiser.id
      }

      this.productService.getListForFilter(urlParams)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(productItems => {
          if (productItems && productItems.length > 0) {
              this.productItems = productItems;
              this.filteredProductItems = of(
                  this.autocompleteService.filterItems(value, this.productItems)
              );
          }

        this.loadingClient = false;
      }, error => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
        this.loadingClient = false;
        });

    });

    // Campaign
    controlsCustomer['campaign'].valueChanges
    .pipe(
      startWith(null),
      debounceTime(400),
      distinctUntilChanged(),
      takeUntil(this.componentDestroyed$)
    )
    .subscribe(value => {

      // remove all locks on campaign-related field if campaign field is empty
      if (controlsCustomer['campaign'].value === '') {
        this.campaignSelected = false;
      }

      if (!value) {
        return;
      }
      this.loadingClient = true;

      this.campaignService.getListForFilter({
        product_id: this.purchase.product.id,
        year: this.purchase.startCommunicationPeriod ? moment(
          this.purchase.startCommunicationPeriod, 'YYYY-MM-DD'
        ).year() :  moment().year()
      })
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(campaignItems => {
        if (campaignItems && campaignItems.length > 0) {
            this.campaignItems = campaignItems;
            this.filteredCampaignItems = of(
                this.autocompleteService.filterItems(value, this.campaignItems)
            );
        }

        this.loadingClient = false;
      }, error => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
        this.loadingClient = false;
        });
    });

    // secodip
    controlsCustomer['secodip'].valueChanges
    .pipe(
      startWith(null),
      debounceTime(400),
      distinctUntilChanged(),
      takeUntil(this.componentDestroyed$)
    )
    .subscribe(value => {
      if (!value) { return; }
      this.loadingClient = true;

      this.secodipService.getListForFilter({name : value})
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(secodipItems => {
        if (secodipItems && secodipItems.length > 0) {
            this.secodipItems = secodipItems;
            this.filteredSecodipItems = of(
                this.autocompleteService.filterItems(value, this.secodipItems)
            );
        }

        this.loadingClient = false;
      }, error => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
        this.loadingClient = false;
        });
    });

    // Mandatary
    controlsCustomer['mandatary'].valueChanges
    .pipe(
      startWith(null),
      debounceTime(400),
      distinctUntilChanged(),
      takeUntil(this.componentDestroyed$)
    )
    .subscribe(value => {
      this.filteredMandataryItems = of([]);
      if (!value || typeof value !== 'string') {
        if (value === '') {
          this.filteredMandatarySignatory = of([]);
          this.purchase.mandatarySignatoryName = null;
          this.purchase.mandatarySignatoryFunct = null;
          controlsCustomer['mandatarySignatory'].setValue('');
          controlsCustomer['mandataryFonction'].setValue('');
        }
        return;
      }
      this.loadingClient = true;
      const urlParams = Number(value) ? {id: value.toUpperCase()} : {name: value.toUpperCase()};

      this.customerService.getListForFilter(urlParams)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(mandataryItems => {
        if (mandataryItems && mandataryItems.length > 0) {
            this.mandataryItems = mandataryItems;
            this.filteredMandataryItems = of(
                this.autocompleteService.filterItems(value, this.mandataryItems)
            );
        }

        this.loadingClient = false;
      }, error => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
        this.loadingClient = false;
        });
    });

    // SubMandatary
    controlsCustomer['subMandatary'].valueChanges
    .pipe(
      startWith(null),
      debounceTime(400),
      distinctUntilChanged(),
      takeUntil(this.componentDestroyed$)
    )
    .subscribe(value => {
      this.filteredSubMandataryItems = of([]);
      if (!value || typeof value !== 'string') {
        if (value === '') {
          this.filteredSubMandatarySignatory = of([]);
          this.purchase.subMandatarySignatoryName = null;
          this.purchase.subMandatarySignatoryFunct = null;
          controlsCustomer['subMandatarySignatory'].setValue('');
          controlsCustomer['subMandataryFonction'].setValue('');
        }
        return;
      }
      this.loadingClient = true;
      const urlParams = Number(value) ? {id: value.toUpperCase()} : {name: value.toUpperCase()};

      this.customerService.getListForFilter(urlParams)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(subMandataryItems => {
        if (subMandataryItems && subMandataryItems.length > 0) {
            this.subMandataryItems = subMandataryItems;
            this.filteredSubMandataryItems = of(
                this.autocompleteService.filterItems(value, this.subMandataryItems)
            );
        }

        this.loadingClient = false;
      }, error => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
        this.loadingClient = false;
        });
    });
  }

  private populateForm(): void {
    const controlsMainInformation = this.stakeholderGroup.controls['mainInformation']['controls'];
    const controlsCustomer = this.stakeholderGroup.controls['customer']['controls'];

    // Main information
    controlsMainInformation['commercial'].setValue(this.purchase.commercial ? new FilteredItem(this.purchase.commercial) : '');
    controlsMainInformation['subCommercial'].setValue(this.purchase.subCommercial ? new FilteredItem(this.purchase.subCommercial) : '');
    controlsMainInformation['planningUser'].setValue(this.purchase.planning ? new FilteredItem(this.purchase.planning) : '');


    // Renaming inside the entity since we cant use strings in formControl.setValue()
    if (this.purchase.advertiser !== undefined) {
      this.purchase.advertiser.name = this.purchase.advertiser.name + ' (' + this.purchase.advertiser.id + ')';
    }
    if (this.purchase.product !== undefined) {
      this.purchase.product.name = this.purchase.product.name + ' (' + this.purchase.product.id + ')';
    }
    if (this.purchase.mandatary !== undefined) {
      this.purchase.mandatary.name = this.purchase.mandatary.name + ' (' + this.purchase.mandatary.id + ')';
    }
    if (this.purchase.subMandatary !== undefined) {
      this.purchase.subMandatary.name = this.purchase.subMandatary.name + ' (' + this.purchase.subMandatary.id + ')';
    }

    // Customer
    controlsCustomer['advertiser'].setValue(
      this.purchase.advertiser ? new FilteredItem(this.purchase.advertiser) : {display: this.purchase.temporaryAdvertiser}
    );
    controlsCustomer['product'].setValue(
      this.purchase.product ? new FilteredItem(this.purchase.product) : {display: this.purchase.temporaryProduct}
    );
    controlsCustomer['campaign'].setValue(this.purchase.campaign ? new FilteredItem(this.purchase.campaign) : '');
    controlsCustomer['secodip'].setValue(this.purchase.secodip ? new FilteredItem(this.purchase.secodip) : '');
    controlsCustomer['campaign'].setValue(this.purchase.campaign ? new FilteredItem(this.purchase.campaign)  : '');
    controlsCustomer['mandatary'].setValue(
      this.purchase.mandatary ?  new FilteredItem(this.purchase.mandatary) :  {display: this.purchase.temporaryMandatary}
    );
    controlsCustomer['subMandatary'].setValue(
      this.purchase.subMandatary ?  new FilteredItem(this.purchase.subMandatary) : {display: this.purchase.temporarySubMandatary}
    );

    controlsCustomer['mandateCertificate'].setValue(this.purchase.mandateCertificate ? this.purchase.mandateCertificate : null);
    controlsCustomer['target'].setValue(this.purchase.target ? new FilteredItem(this.purchase.target) : '');

    controlsCustomer['startCommunicationPeriod'].setValue(
      this.purchase.startCommunicationPeriod ?
      moment(this.purchase.startCommunicationPeriod, 'YYYY-MM-DD') :
      null
    );
    controlsCustomer['endCommunicationPeriod'].setValue(
      this.purchase.endCommunicationPeriod ?
      moment(this.purchase.endCommunicationPeriod, 'YYYY-MM-DD') :
      null
    );
    controlsCustomer['customerBudget'].setValue(this.purchase.customerBudget ? this.purchase.customerBudget : null);

    if (this.purchase.campaign) {
      this.campaignSelected = true;
    }
  }

  private switchHint(formData = null, purchase: Purchase = null): void {
    if (formData && purchase) { return; }

    let data = formData && formData['customer'] || purchase;

    if (formData) {
      this.hintAdvertiser = !(data['advertiser'] instanceof FilteredItem);
      this.hintProduct = !(data['product'] instanceof FilteredItem);
      this.hintMandatary = !(data['mandatary'] instanceof FilteredItem);
      this.hintSubMandatary = !(data['subMandatary'] instanceof FilteredItem);
    }

    if (purchase) {
      this.hintAdvertiser = !data.advertiser;
      this.hintProduct = !data.product;
      this.hintMandatary = !data.mandatary;
      this.hintSubMandatary = !data.subMandatary;
    }
  }

  /**
   * Check to enable the button Convention
   *
   * @param data
   * @return {void}
   */
  private checkConventionEnable(data: FormGroup): void {
    let customer = data['customer'];
    let mainInformation = data['mainInformation'];

    if (customer && customer['advertiser'] && customer['advertiser'].value
      && customer['advertiserSignatory'] && customer['advertiserSignatory'].value
      && customer['advertiserFonction'] && customer['advertiserFonction'].value) {
      if ((customer['subMandatary'] && customer['subMandatary'].value)
      || (customer['subMandatarySignatory'] && customer['subMandatarySignatory'].value)
      || (customer['subMandataryFonction'] && customer['subMandataryFonction'].value)) {
        if (!(customer['subMandatary'] && customer['subMandatary'].value)
        || !(customer['subMandatarySignatory'] && customer['subMandatarySignatory'].value)
        || !(customer['subMandataryFonction'] && customer['subMandataryFonction'].value)) {
          this.signataireService.sendStatusConvention(false);
          return;
        } else if (!(customer['mandatary'] && customer['mandatary'].value)) {
          this.signataireService.sendStatusConvention(false);
          return;
        }
      } else if ((customer['mandatary'] && customer['mandatary'].value)
        || (customer['mandatarySignatory'] && customer['mandatarySignatory'].value)
        || (customer['mandataryFonction'] && customer['mandataryFonction'].value)) {
        if (!(customer['mandatary'] && customer['mandatary'].value)
        || !(customer['mandatarySignatory'] && customer['mandatarySignatory'].value)
        || !(customer['mandataryFonction'] && customer['mandataryFonction'].value)) {
          this.signataireService.sendStatusConvention(false);
          return;
        }
      } else if (customer['mandateCertificate'] === '1') {
        this.signataireService.sendStatusConvention(false);
        return;
      }
    } else {
      if (customer['mandateCertificate'] === '1') {
        if (customer['subMandatary'] && customer['subMandatary'].value && customer['subMandatarySignatory']
        && customer['subMandatarySignatory'].value && customer['subMandataryFonction']
        && customer['subMandataryFonction'].value) {
          this.checkSignatoryFtp(mainInformation);
        } else if (customer['mandatary'] && customer['mandatary'].value && customer['mandatarySignatory']
          && customer['mandatarySignatory'].value && customer['mandataryFonction'] && customer['mandataryFonction'].value) {
          this.checkSignatoryFtp(mainInformation);
        } else {
          this.signataireService.sendStatusConvention(false);
          return;
        }
      } else {
        this.signataireService.sendStatusConvention(false);
        return;
      }
    }

    this.checkSignatoryFtp(mainInformation);
  }

  /**
   * check if signatory Ftp is selected
   *
   * @private
   * @param {*} mainInformation
   * @returns {void}
   * @memberof PurchaseStakeholderComponent
   */
  private checkSignatoryFtp(mainInformation): void {
    if (mainInformation && mainInformation['signatoryFtp'] && mainInformation['signatoryFtp'] !== null
    && mainInformation['signatoryFtp'].value) {
      this.signataireService.sendStatusConvention(true);
      return;
    } else {
      this.signataireService.sendStatusConvention(false);
      return;
    }
  }
}
