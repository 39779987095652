<div class="title-sub-table margin-top-25">
  Diffusion
</div>

<mat-progress-bar 
  mode="indeterminate" 
  color="accent"
  *ngIf="loading">
</mat-progress-bar>

<div [ngClass]="{'loading': loading}">
  <app-broadcast-add
    (addBroadcastEmitter)="refreshBrutEmitter($event)"
    [purchaseProgram]="purchaseProgram"
    [purchase]="purchase">
  </app-broadcast-add>

  <app-broadcast-list
    (deleteBroadcastEmitter)="refreshBrutEmitter($event)"
    [purchaseProgram]="purchaseProgram"
    [purchase]="purchase">
  </app-broadcast-list>
</div>
