<div #target>
    <mat-card class="form-card">
            <mat-card-title>
                <h2 class="no-margin-top">Emissions & dispositif</h2>
            </mat-card-title>
        
            <mat-card-content>
        
                <mat-progress-bar mode="indeterminate" color="accent" *ngIf="loading"></mat-progress-bar>
        
                <div fxLayout="row" fxLayoutGap="25px">
                    <!-- Program list -->
                    <div fxFlex="35">
                        <app-offer-program-list
                            [purchasePrograms]="purchaseProgramList"
                            [selectedPurchaseProgram]="purchaseProgramSelected"
                            (deletedPurchaseProgramEmitter)="deletePurchaseProgram($event)"
                            (selectedPurchaseProgramEmitter)="selectPurchaseProgram($event)">
                        </app-offer-program-list>
                    </div>
                    <div fxFlex="auto">
                        <app-offer-program-detail
                            *ngIf="purchaseProgramSelected" 
                            [purchaseProgram]="purchaseProgramSelected"
                            [purchase]="purchase"
                            (selectedPurchaseProgram)="onChangePurchaseProgramFromChild($event)"
                            (refreshEmitter)="refreshBrutEmitter($event)">
                        </app-offer-program-detail>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
</div>