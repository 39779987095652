
export interface JsonTrailerPeriodicity {
    id: number;
    name?: string;
}

export class TrailerPeriodicity {
    public id: number;
    public name: string;

    public static NA            = -1;
    public static EVENING       = 1;
    public static WEEK          = 3;
    public static PROGRAM       = 4;
    public static GLOBAL        = 5;
    public static DAY           = 9;

    // DEPRECIATED
    public static MONTH         = 0;
    public static TWO_WEEKS     = 2;
    public static S1            = 6;
    public static S2            = 7;
    public static S3            = 8;

    constructor(jsonTrailerPeriodicity: JsonTrailerPeriodicity) {
        this.id = jsonTrailerPeriodicity.id;
        this.name = jsonTrailerPeriodicity.name;
    }
}
