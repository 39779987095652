import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { RegionGridService } from '../../../service/region-grid.service';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';
import { CategoryService } from '@service/category/category.service';
import { AreaService } from '@service/area/area.service';

import {
  JsonRegionSynthesis,
  RegionFilterSynthesis,
  RegionSynthesis,
  RegionWeeksSynthesis
} from '../../../../../resource/synthesis-region';
import { Category } from '../../../../../resource/category.resource';
import { Area } from '../../../../../resource/area.resource';
import { UserParam } from '../../../../../resource/user/user.resource';

import { filter, takeUntil } from 'rxjs/operators';
import { forkJoin, merge, Subject } from 'rxjs';
import { ExportConducteurComponent } from '../../../../../shared/dialog/export-conducteur/export-conducteur.component';

@Component({
  selector: 'app-filter-region',
  templateUrl: './filter-region.component.html',
  styleUrls: ['./filter-region.component.scss']
})
export class FilterRegionComponent implements OnInit, OnDestroy {
  @Input() filters: RegionFilterSynthesis;

  public loading = false;
  public haveArea = false;
  public haveCategory = false;

  public categoryCtrl = new FormControl();
  public areaCtrl = new FormControl();
  public programCtrl = new FormControl();

  public allProgramCategories: Category[];
  public allAreas: Area[];

  private formatDataRegion;
  private rawDataRegion;
  readonly userParam: UserParam;

  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    public dialog: MatDialog,
    private regionGridService: RegionGridService,
    private categoryService: CategoryService,
    private areaService: AreaService,
    private customToastrService: CustomToastrService
  ) {
    // Get from local param user or create new params
    this.userParam = this.regionGridService.getUserParam();
  }

  ngOnInit() {
    this.loading = true;

    const programSource$ = this.regionGridService.get(this.filters);
    const areaSource$ = this.areaService.getList();
    const categorySource$ = this.categoryService.getList();

    this.initFilter();

    forkJoin([programSource$, areaSource$, categorySource$])
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((pacSource: [JsonRegionSynthesis[], Area[], Category[]] ) => {
        if (!pacSource[0].length) {
          this.customToastrService.displayToastr('WARNING', 'Aucun programme trouvé.');
        }

        this.rawDataRegion = pacSource[0];
        // Filter by only France 3
        this.allAreas = pacSource[1].filter((item: Area) => item && item.channel && item.channel.id && item.channel.id === '3' && item.id && item.id !== 'NAT');
        this.allProgramCategories = pacSource[2];
        // Default selected or user parameter for area
        this.areaCtrl.setValue(this.userParam.area ?  this.allAreas.filter((area: Area) =>
          this.userParam.area.findIndex((id: string) => area.id === id) >= 0) : this.allAreas, {emitEvent: false});
        // Default selected or user parameter for categories
        this.categoryCtrl.setValue(this.userParam.category ? this.allProgramCategories.filter((category: Category) =>
          this.userParam.category.findIndex((id: string) => category.id === id) >= 0) : this.allProgramCategories, {emitEvent: false});

        this.areaCtrl.value.length ? this.haveArea = true : this.haveArea = false;
        this.categoryCtrl.value.length ? this.haveCategory = true : this.haveCategory = false;

        this.formatGrid(this.rawDataRegion);
        this.loading = false;
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
        this.regionGridService.filterRegionSynthesis([]);
        this.loading = false;
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.unsubscribe();
  }

  /**
   * Start subscriber filter
   */
  initFilter(): void {
    merge(
      this.categoryCtrl.valueChanges,
      this.areaCtrl.valueChanges,
      this.programCtrl.valueChanges
    )
      .pipe(
        filter(() => this.formatDataRegion),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(() => {
        this.regionGridService.setUserParam({
          category: this.categoryCtrl.value.map((category) => category.id),
          area: this.areaCtrl.value.map((area) => area.id)
        });
        this.regionGridService.filterRegionSynthesis(this.filtersAll());
        this.areaCtrl.value.length ? this.haveArea = true : this.haveArea = false;
        this.categoryCtrl.value.length ? this.haveCategory = true : this.haveCategory = false;
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });
  }

  /**
   * Decrease Year and reload data
   */
  decreaseYear(): void {
    this.filters.year--;
    this.loadProgramRegion();
  }

  /**
   * Increase Year and reload data
   */
  increaseYear(): void {
    this.filters.year++;
    this.loadProgramRegion();
  }

  /**
   * Reload data by year
   */
  loadProgramRegion() {
    this.regionGridService.filterRegionSynthesis([]);
    this.programRegionGrid();
  }

  /**
   * Select all or unselect all programme
   */
  selectAllCategories(): void {
    if (this.categoryCtrl.value.length !== this.allProgramCategories.length) {
      this.categoryCtrl.setValue(this.allProgramCategories);
    } else {
      this.categoryCtrl.setValue([]);
    }
  }

  /**
   * Select all or unselect all area
   */
  selectAllArea(): void {
    if (this.areaCtrl.value.length !== this.allAreas.length) {
      this.areaCtrl.setValue(this.allAreas);
    } else {
      this.areaCtrl.setValue([]);
    }
  }

  /**
   * Open dialog Export Conducteur
   */
  openExportConducteur(): void {
    if (!this.areaCtrl.value.length) {
      this.customToastrService.displayToastr('WARNING', 'Veuillez saisir la région!');
    } else if (!this.categoryCtrl.value.length) {
      this.customToastrService.displayToastr('WARNING', 'Veuillez saisir l\'unité de programme!');
    }

    const dataFilterArea = {
      areas: this.areaCtrl.value,
      categories: this.categoryCtrl.value,
      program: this.programCtrl.value
    };

    this.dialog.open(ExportConducteurComponent, {
      data: dataFilterArea,
      autoFocus: false
    });
  }

  /**
   * Get program area
   */
  programRegionGrid(): void {
    this.loading = true;
    this.regionGridService
      .get(this.filters)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((dataRegion: JsonRegionSynthesis[]) => {
        this.loading = false;
        if (!dataRegion.length) {
          this.customToastrService.displayToastr('WARNING', 'Aucun programme trouvé.');
        }
        this.rawDataRegion = dataRegion;
        this.formatGrid(this.rawDataRegion);
      }, () => {
        this.loading = false;
        this.regionGridService.filterRegionSynthesis([]);
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });
  }

  /**
   * Filter the original dataRegion by all case
   *
   * return {RegionSynthesis[]}
   */
  private filtersAll(): RegionSynthesis[] {
    let copyDataRegion = [...this.formatDataRegion];

    copyDataRegion = this.filterArea(copyDataRegion);
    copyDataRegion = this.filterCategory(copyDataRegion);

    if (this.programCtrl.value && typeof this.programCtrl.value === 'string') {
      copyDataRegion = this.filterByProgramName(this.programCtrl.value, copyDataRegion)
    }

    return copyDataRegion;
  }

  /**
   * Filter by area
   *
   * @param copyDataRegion {RegionSynthesis[]}
   * @return {RegionSynthesis[]}
   */
  private filterArea(copyDataRegion: RegionSynthesis[]): RegionSynthesis[] {
    return copyDataRegion.filter((item: RegionSynthesis) => {
      return this.areaCtrl.value.findIndex((area: Area) => area.id === item.idRegion) > -1;
    });
  }

  /**
   * Filter by category
   *
   * @param copyDataRegion {RegionSynthesis[]}
   * @return {RegionSynthesis[]}
   */
  private filterCategory(copyDataRegion: RegionSynthesis[]): RegionSynthesis[] {
    return copyDataRegion.filter((item: RegionSynthesis) => {
      return this.categoryCtrl.value.findIndex((category: Category) => category.id === item.category) > -1;
    });
  }

  /**
  * Filter by program name
  *
  * @param name {string}
  * @param copyDataRegion {RegionSynthesis[]}
  */
  private filterByProgramName(name: string, copyDataRegion: RegionSynthesis[]) {
    const filterNameValue = name.toLowerCase();

    return copyDataRegion.filter((item: RegionSynthesis) => {
      return item.name.toLowerCase().includes(filterNameValue);
    });
  }

  /**
   * Format data for the grid
   *
   * @param dataRegion {JsonRegionSynthesis[]}
   * @return {void}
   */
  private formatGrid(dataRegion: JsonRegionSynthesis[]): void {
    let sourceSynthesis = [];

    dataRegion.forEach((programRegion: JsonRegionSynthesis) => {
      const updatedWeeks = this.reducerWeeks(this.updateWeeks(programRegion.weeks, programRegion.places));

      sourceSynthesis.push(new RegionSynthesis({
        name: programRegion.name,
        offerProgramId: programRegion.offerProgramId,
        region: programRegion.region,
        places: updatedWeeks.maxLines,
        category: programRegion.category,
        idRegion: programRegion.idRegion,
        weeks: updatedWeeks.weeks,
        offerProgram: {
          id: programRegion.offerProgramId,
          area: this.allAreas.find((area) => area.id === programRegion.idRegion),
          program: {
            id: programRegion.idProgram
          },
          offer: {
            id: +programRegion.idOffer,
            digVidBillBudget: programRegion.digitalBudgets.dig_vid_bill_budget,
            digVidPreBudget: programRegion.digitalBudgets.dig_vid_pre_budget,
            digitalDisplayBudget: programRegion.digitalBudgets.digital_display_budget,
            digitalOtherBudget: programRegion.digitalBudgets.digital_other_budget,
            digitalReplayBudget: programRegion.digitalBudgets.digital_replay_budget,
            digitalSocialBudget: programRegion.digitalBudgets.digital_social_budget,
            socialFbBudget: programRegion.digitalBudgets.social_fb_budget,
            socialInstaBudget: programRegion.digitalBudgets.social_insta_budget,
            socialTwitBudget: programRegion.digitalBudgets.social_twit_budget,
          }
        }
      }));
    });

    this.formatDataRegion = sourceSynthesis;
    this.sendDataAfterLoad();
  }

  /**
   * Get max line (place)
   *
   * @param week {[]}
   * @param maxLines {number}
   * @private
   */
  private static getMaxLines(week: [], maxLines: number): number {
    if ((week['purchases'].length >= maxLines)) {
      maxLines = week['purchases'].length;

      // If there is available broadcast we add one extra line
      if (week['broadcasts'].length > 0) {
        maxLines++;
      }
    }

    return maxLines;
  }

  /**
   * Transforme Weeks object to Weeks array
   *
   * @param weeks {any}
   * @param places {number}
   * @return {RegionWeeksSynthesis}
   */
  private updateWeeks(weeks: any, places: number = 0): RegionWeeksSynthesis {
    let weekArray = [];
    let maxLines: number = places;

    Object.keys(weeks).forEach((key: string, index: number, keys: string[]) => {
      // Update maxLines if have more
      maxLines = FilterRegionComponent.getMaxLines(weeks[key], maxLines);

      switch (key) {
        // 52e && 53e start all time in the year
        case `${(this.filters.year - 1)}-52` || `${(this.filters.year - 1)}-53`: {
          weekArray.length >= 1 ? weekArray.unshift(weeks[key]) : weekArray.push(weeks[key]);
          break;
        }
        default: {
          if ((index === (keys.length - 1) && weeks[`${(this.filters.year + 1)}-01`])) {
            weekArray.push(weeks[`${(this.filters.year + 1)}-01`]);
          } else if (key !== `${(this.filters.year + 1)}-01`) {
            weekArray.push(weeks[key]);
          }
          break;
        }
      }
    });

    return new RegionWeeksSynthesis(weekArray, maxLines);
  }

  /**
   * Reduce size of array (groupe by ID && WEEK)
   *
   * @param updatedWeeks
   */
  private reducerWeeks(updatedWeeks) {
    // Add duration 1 to all case who don't have
    updatedWeeks = this.feedEmptyWeek(updatedWeeks);
    // Align purchase to same row and the size of row
    return this.switchPurchasePlaceWeek(updatedWeeks);
  }

  /**
   * Add duration 1 for all empty week
   *
   * @param updatedWeeks
   */
  private feedEmptyWeek(updatedWeeks) {
    updatedWeeks.weeks.forEach((week, index: number) => {
      for (let i = 0; Number(updatedWeeks.maxLines) > i; i++) {
        if (updatedWeeks.weeks[index].purchases[i]) {
          updatedWeeks.weeks[index].purchases[i]['duration'] = 1;
          updatedWeeks.weeks[index].purchases[i]['disable'] = !!!week.broadcasts.length;
        } else {
          updatedWeeks.weeks[index].purchases.push({
            duration: 1,
            disable: !!!week.broadcasts.length
          });
        }
      }

      // Default check  for case available
      if (week && week.broadcasts && week.broadcasts.length) {
        week['checked'] = 0;
      }
    });

    return updatedWeeks;
  }

  /**
   * Send data all filter and data init
   */
  private sendDataAfterLoad(): void {
    this.regionGridService.filterRegionSynthesis(this.filtersAll());
  }

  /**
   * Switch place to align purchase
   *
   * @param updatedWeeks
   */
  private switchPurchasePlaceWeek(updatedWeeks) {
    // Will be the reference week
    let switchedPurchase = [];

    updatedWeeks.weeks.forEach((week, indexWeek: number) => {
      // we keep the first as a reference
      if (indexWeek === 0 || switchedPurchase.length === 0) {
        week.purchases.forEach((item, indexRow: number) => {
          if (week.purchases[indexRow] && week.purchases[indexRow].id) {
            week.purchases[indexRow]['indexStart'] = indexWeek;
          }
        });
        switchedPurchase.push(week);
      } else {
        let index: number;

        week.purchases.forEach((item, indexRow: number) => {
          index = switchedPurchase[switchedPurchase.length - 1].purchases.findIndex((purchaseWeek) =>
            purchaseWeek && item && purchaseWeek.id === item.id
          );
          if (item.id && index !== -1 && index !== indexRow) { // align to some row n and n-1 with same id
            [week.purchases[indexRow], week.purchases[index]] = [week.purchases[index], week.purchases[indexRow]];
            week.purchases[index].duration = 0; // set to 0 but up n-1
            week.purchases[index]['indexStart'] = switchedPurchase[indexWeek - 1].purchases[index]['indexStart']; // keep index of first week of purchase
            switchedPurchase[switchedPurchase[indexWeek - 1].purchases[index]['indexStart']].purchases[index].duration++; // up duration n-1
            if (switchedPurchase[indexWeek - 1].purchases[indexRow] && week.purchases[indexRow] &&
              switchedPurchase[indexWeek - 1].purchases[indexRow].id === week.purchases[indexRow].id &&
              !('indexStart' in week.purchases[indexRow]) && week.purchases[indexRow].id) { // we need to calculate the switched too
              week.purchases[indexRow].duration = 0; // set to 0 but up n-1 (switched)
              week.purchases[indexRow]['indexStart'] = switchedPurchase[indexWeek - 1].purchases[indexRow]['indexStart']; // set to 0 but up n-1 (switched)
              switchedPurchase[switchedPurchase[indexWeek - 1].purchases[indexRow]['indexStart']].purchases[indexRow].duration++; // up duration n-1 (switched)
            } else if (week.purchases[indexRow] && !('indexStart' in week.purchases[indexRow]) && week.purchases[indexRow].id) { // if switched have already index start
              week.purchases[indexRow]['indexStart'] = indexWeek;
            }
          } else if (item.id && index !== -1 && index === indexRow && !('indexStart' in week.purchases[indexRow])) { // same row n and n-1 with same id
            week.purchases[index].duration = 0;
            week.purchases[index]['indexStart'] = switchedPurchase[indexWeek - 1].purchases[index]['indexStart'];
            switchedPurchase[switchedPurchase[indexWeek - 1].purchases[index]['indexStart']].purchases[index].duration++;
          } else if (item.id && index === -1 && !('indexStart' in week.purchases[indexRow]) && week.purchases[indexRow].id) { // not same id but have purchase
            week.purchases[indexRow]['indexStart'] = indexWeek; // have no n -1 then can be the first week of purchase
          }
        });
        switchedPurchase.push(week);
      }
    });

    return updatedWeeks;
  }
}
