import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';

import { GridService } from '../service/grid.service';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';
import { AutocompleteService } from '@service/utilities/autocomplete.service';
import { AdvertiserService } from '@service/advertiser/advertiser.service';
import { EmployeeService } from '@service/employee/employee.service';
import { PurchaseService } from '@service/purchase/purchase.service';
import { PurchaseProgramService } from '@service/purchase-program/purchase-program.service';
import { BroadcastPurchasedService } from '@service/broadcast-purchased/broadcast-purchased.service';
import { PropalCartService } from '@service/propal-cart/propal-cart.service';

import { Channel } from '../../../resource/channel.resource';
import { FilteredItem } from '../../../resource/filteredItem.resource';
import { Employee } from '../../../resource/employee.resource';
import { PurchaseProgram } from '../../../resource/purchaseProgram.resource';
import { Purchase } from '../../../resource/purchase.resource';
import { PurchaseParams } from '../../../resource/purchaseType.resource';

import { Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, takeUntil } from 'rxjs/operators';

import * as moment from 'moment';

@Component({
  selector: 'app-soreach-card',
  templateUrl: './soreach-card.component.html',
  styleUrls: ['./soreach-card.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('none', style({
        transform: 'translateY(100%)'
      })),
      state('out', style({
        transform: 'translateY(85%)'
      })),
      state('in', style({
        transform: 'translateY(0)'
      })),
      transition('in => out', animate('800ms ease-in-out')),
      transition('out => in', animate('800ms ease-in-out')),
      transition('in => notCreated', animate('300ms ease-in-out')),
      transition('notCreated => out', animate('300ms ease-in-out'))
    ]),
  ]
})
export class SoreachCardComponent implements OnInit, OnDestroy {

  @Input('purchaseParams') purchaseParams: PurchaseParams;

  indexCount: number;
  isLoading: boolean = false;
  soReachForm: FormGroup;
  dataCard;
  nameTypeSoReach: string;
  typeSoReachId: number;
  isEmptyCard: boolean = true;
  cardState: string = 'out';
  path: string = Channel.PATH;
  days: Array<string> = ['lu', 'ma', 'me', 'je', 've', 'sa', 'di'];
  channels: Array<string> = ['2', '3', '4', '5', 'O'];

  public filteredAdvertiserItems: Observable<FilteredItem[]>;
  public filteredEmployeeCommercialItems: Observable<FilteredItem[]>;
  public loadingInputAdvertiser: boolean = false;
  public loadingInputCommercial: boolean = false;
  public createOrUpdateMessage: string = 'Créer une propale'
  public creatingPurchase: boolean = true;
  public updatingPurchase: boolean = false;

  private componentDestroyed$: Subject<void> = new Subject();
  private advertiserItems: FilteredItem[];
  private employeeCommercialItems: FilteredItem[];
  private soReachPurchasedBroadcasts = [];
  private purchase;

  constructor(private _fb: FormBuilder,
              private router: Router,
              private gridService: GridService,
              private propalCartService: PropalCartService,
              private customToastrService: CustomToastrService,
              private advertiserService: AdvertiserService,
              private employeeService: EmployeeService,
              public autocompleteService: AutocompleteService,
              private purchaseService: PurchaseService,
              private purchaseProgramService: PurchaseProgramService,
              private broadcastPurchasedService: BroadcastPurchasedService) {
    this.soReachForm = _fb.group({
      name: [null, Validators.required],
      commercial: [null, Validators.required],
      advertiser: [null, Validators.required],
      soReaches: _fb.group({}, Validators.required)
    });
  }

  ngOnInit() {
    this.isRedirectedFromPurchase();
    this.gridService.clearCardSoReach$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        this.cancel()
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.gridService.selectedSoReach$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((soreach: any) => {
        this.nameTypeSoReach = soreach.name;
        this.typeSoReachId = Number(soreach.id);
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.gridService.selectedSoReachPrograms$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.dataCard = data;
        this.checkCard(this.dataCard);
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.soReachForm.get('commercial').valueChanges
      .pipe(
        startWith(null),
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(value => {
        this.filteredEmployeeCommercialItems = of([]);
        if (!value) {
          return;
        }
        this.loadingInputCommercial = true;
        this.employeeService.getListForFilter({
          user : value,
          employee_type_id: Employee.commercialType.join(';'),
          commercial_cell_id: Employee.commercialCell.join(';')
        })
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(employeeItems => {
            if (employeeItems && employeeItems.length > 0) {
              this.employeeCommercialItems = employeeItems;
              this.filteredEmployeeCommercialItems = of(
                this.autocompleteService.filterItems(value, this.employeeCommercialItems)
              );
            }
            this.loadingInputCommercial = false;
          }, () => {
            this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            this.loadingInputCommercial = false;
          });
      });

    this.soReachForm.get('advertiser').valueChanges
      .pipe(
        startWith(null),
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(value => {
        this.filteredAdvertiserItems = of([]);
        if (!value || typeof value !== 'string') {
          return;
        }
        this.loadingInputAdvertiser = true;
        const filterAdvertiser =  Number(value) ? {id: value.toUpperCase()} : {name: value.toUpperCase()};

        this.advertiserService.getListForFilter(filterAdvertiser)
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(advertiserItems => {
            if (advertiserItems && advertiserItems.length > 0) {
              this.advertiserItems = advertiserItems;
              this.filteredAdvertiserItems = of(
                this.autocompleteService.filterItems(value, this.advertiserItems)
              );
            }
            this.loadingInputAdvertiser = false;
          }, () => {
            this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            this.loadingInputAdvertiser = false;
          });
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
        this.loadingInputAdvertiser = false;
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.unsubscribe();
  }

  /**
   * Check if bucket is empty
   *
   * @param data
   */
  checkCard(data) {
    this.isEmptyCard = true;
    this.channels.forEach((channel) => {
      this.days.forEach((day) => {
        if (this.dataCard && this.dataCard[channel] && this.dataCard[channel][day] && this.dataCard[channel][day].length) {
          this.isEmptyCard = false;
        }
      });
    });
    this.gridService.loadingEmptySoReach(this.isEmptyCard);
  }

  /**
   * Open or Close card soReach
   *
   * @return {void}
   */
  openClose(): void {
    if (this.cardState === 'out') {
      this.cardState = 'in';
    } else {
      this.cardState = 'out';
    }
  }

  /**
   * if came from purchase set purchase informations in propal card
   * @return {void}
   */
  isRedirectedFromPurchase(): void {
    if  (this.purchaseParams && this.purchaseParams.soReachPurchase ) {
      this.updatingPurchase = true;
      this.creatingPurchase = false;
      this.createOrUpdateMessage = 'Mettre à jour le dossier'

      this.soReachForm.controls['advertiser'].setValue(this.purchaseParams.advertiser);
      this.soReachForm.controls['commercial'].setValue(this.purchaseParams.commercial);
      this.soReachForm.controls['name'].setValue(this.purchaseParams.name);

      this.soReachForm.controls['advertiser'].disable();
      this.soReachForm.controls['commercial'].disable();
      this.soReachForm.controls['name'].disable();
    }
  }

  /**
   * Clear the soReach card
   *
   * @return {void}
   */
  cancel(): void {
    this.cardState = 'out';
    const soReachArray = this.soReachForm.get('soReaches') as FormArray;

    // For Angular >= 8
    // if (soReachArray.length > 0) {
    //   soReachArray.clear();
    // }
    // For <= Angular 7
    while (soReachArray.length > 0) {
      soReachArray.removeAt(0);
    }
    this.soReachForm.reset();
    this.channels.forEach((channel) => this.deleteAllProgramsSoReach(channel))
  }

  /**
   * Create the propale for soReach
   *
   * @return {void}
   */
  save(): void {
    if (this.soReachForm.valid && this.soReachForm.get('commercial').value && this.soReachForm.get('commercial').value.id &&
        this.soReachForm.get('advertiser').value && this.soReachForm.get('advertiser').value.id && this.typeSoReachId) {
      this.isLoading = true;
      if (this.creatingPurchase) {
        const purchaseSoReach = {
          id: null,
          advertiser: this.soReachForm.get('advertiser').value,
          commercial: this.soReachForm.get('commercial').value,
          name: this.soReachForm.get('name').value,
          startCommunicationPeriod: this.getMinDateSoReach(),
          endCommunicationPeriod: this.getMaxDateSoReach(),
          soreachPurchase: this.typeSoReachId,
          type: 6
        };

        this.purchaseService.createPurchase(purchaseSoReach, true)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe((purchase) => {
              this.purchase = purchase;
              const broadcastGroupedByOfferProgram = this.groupByOfferProgram();
              let count: number = broadcastGroupedByOfferProgram.length;

              broadcastGroupedByOfferProgram.forEach((broadcasts) => {
                if (broadcasts.length) {
                  this.purchaseProgramService.postPurchaseProgram(broadcasts[0], purchase)
                      .pipe(takeUntil(this.componentDestroyed$))
                      .subscribe((purchaseProgram) => {
                        this.buildSoReachPurchasedBroadcasts(purchaseProgram, broadcasts, purchase);

                        if (--count === 0) {
                          if (!this.soReachPurchasedBroadcasts && !this.soReachPurchasedBroadcasts.length) {
                            return false;
                          } else {
                            this.indexCount = this.soReachPurchasedBroadcasts.length - 1;
                          }
                          this.createSoReachBroadcastPurchasedAndSchedule(this.soReachPurchasedBroadcasts[this.indexCount]);
                        }
                      }, () => {
                        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
                      });
                }
              });
            }, () => {
              this.isLoading = false;
              this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            });
      } else if (this.updatingPurchase) {
        const purchaseDataToUpdate = {};
        // card max and min broadcasts dates
        const propalCardMinBroadcastsDate = this.getMinDateSoReach();
        const propalCardMaxBroadcastsDate = this.getMaxDateSoReach();

        if (propalCardMinBroadcastsDate < this.purchaseParams.startDate) {
          purchaseDataToUpdate['start_communication_period'] = propalCardMinBroadcastsDate;
        }

        if (propalCardMaxBroadcastsDate > this.purchaseParams.endDate) {
          purchaseDataToUpdate['end_communication_period'] = propalCardMaxBroadcastsDate;
        }

        this.purchaseService.updatePurchasePartial(this.purchaseParams.id, purchaseDataToUpdate)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe((purchase) => {
              this.purchase = purchase;
              const broadcastGroupedByOfferProgram = this.groupByOfferProgram();
              let count: number = broadcastGroupedByOfferProgram.length;

              broadcastGroupedByOfferProgram.forEach((broadcasts) => {
                if (broadcasts.length) {
                  this.purchaseProgramService.postPurchaseProgram(broadcasts[0], purchase)
                      .pipe(takeUntil(this.componentDestroyed$))
                      .subscribe((purchaseProgram) => {
                        this.buildSoReachPurchasedBroadcasts(purchaseProgram, broadcasts, purchase);

                        if (--count === 0) {
                          if (!this.soReachPurchasedBroadcasts && !this.soReachPurchasedBroadcasts.length) {
                            return false;
                          } else {
                            this.indexCount = this.soReachPurchasedBroadcasts.length - 1;
                          }
                          this.createSoReachBroadcastPurchasedAndSchedule(this.soReachPurchasedBroadcasts[this.indexCount]);
                        }
                      }, () => {
                        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
                      });
                }
              });
            }, () => {
              this.isLoading = false;
              this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            });
      }
    } else {
      if (!this.soReachForm.get('commercial').value || !this.soReachForm.get('commercial').value.id) {
        this.customToastrService.displayToastr('WARNING', 'Veuillez choisir un Commercial.');
      } else if (!this.soReachForm.get('advertiser').value || !this.soReachForm.get('advertiser').value.id) {
        this.customToastrService.displayToastr('WARNING', 'Veuillez choisir un Annonceur.');
      } else {
        this.customToastrService.displayToastr('WARNING', 'Veuillez bien renseigner les champs.');
      }
    }
  }

  /**
   * Delete program from the card
   *
   * @param channel {number}
   * @param day {string}
   * @param program {Object}
   * @param index {number}
   */
  deleteProgramSoReach(channel: number, day: string, program: Object, index: number): void {
    this.dataCard[channel][day].splice(index, 1);
    this.gridService.deleteProgramCard({
      card: this.dataCard,
      channel: channel,
      day: day,
      program: program
    });
    this.checkCard(this.dataCard);
  }

  /**
   * Delete all programs from the card
   *
   * @param channel {number}
   */
  deleteAllProgramsSoReach(channel: string): void {
    this.days.forEach((day: string) => {
      this.dataCard[channel][day] = [];
    });
    this.gridService.deleteChannelCard({
      card: this.dataCard,
      channel: channel
    });
    this.checkCard(this.dataCard);
  }

  /**
   * Post select action on advertiser field
   *
   * @param {*} advertiser
   */
  onAdvertiserSelect(advertiser: any) {
    if (advertiser) {
      let controlsCustomer = this.soReachForm.controls;

      // A quick and dirty way to not contatenate the id a second time
      advertiser.entity.name = advertiser.entity.name .replace(/ \([0-9]+\)$/, '');
      advertiser.entity.name = advertiser.entity.name + ' (' + advertiser.entity.id + ')';

      controlsCustomer['advertiser'].setValue(
        advertiser.entity ? new FilteredItem(advertiser.entity) : ''
      );
    }
  }

  /**
   * If receive several broadcastIds then extract
   * @param purchaseProgram
   * @param broadcasts
   * @param purchase
   * @return {void}
   */
  private buildSoReachPurchasedBroadcasts(purchaseProgram: PurchaseProgram, broadcasts, purchase: Purchase): void {
    let builtBroadcasts: Array<Object> = [];

    broadcasts.forEach((broadcast) => {
      let dicBroadcasts: Array<string> = [];

      if (broadcast.broadcastIds) {
        dicBroadcasts = broadcast.broadcastIds.trim().replace(/ /g, '').split(',');
      }

      dicBroadcasts.forEach((broadcastIds: string) => {
        const copyBroadcast = {...broadcast};
        copyBroadcast['broadcastIds'] = broadcastIds;
        builtBroadcasts.push(copyBroadcast);
      });
    });

    this.soReachPurchasedBroadcasts.push({
      offerProgram : purchaseProgram.offerProgram,
      purchase: purchase,
      purchaseProgram: purchaseProgram,
      broadcast: builtBroadcasts
    });
  }

  /**
   * Reset/Init broadcast purchase && create broadcast purchased
   *
   * @param data
   */
  private createSoReachBroadcastPurchasedAndSchedule(data): void {
    this.broadcastPurchasedService.reset();
    this.broadcastPurchasedService.init(data['offerProgram'], data['purchase'], data['purchaseProgram']);
    this.broadcastPurchasedService.createBroadcastPurchasedFromAvailibilty(data['broadcast'])
      .subscribe(() => {
        if (this.indexCount > 0) {
          this.createSoReachBroadcastPurchasedAndSchedule(this.soReachPurchasedBroadcasts[--this.indexCount]);
        } else {
          this.propalCartService.resetPropalCart();
          this.router.navigate(['/purchases/edit/' + this.purchase.id]);
        }
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });
  }

  /**
   * Group by program SoReach
   *
   * @return {Array<Array<Object>>}
   */
  private groupByOfferProgram(): Array<Array<Object>> {
    let allOfferPrograms = [];
    let dictionarySoReaches: Array<string> = []; // Reference of unique key (anti-duplication)
    let groupedByOfferProgram: Array<Array<Object>> = [];

    // Group by offer program
    this.channels.forEach((channel) => {
      this.days.forEach((day) => {
        this.dataCard[channel][day].forEach((item) => {
          if (!dictionarySoReaches.includes(item.offer_program)) {
            dictionarySoReaches.push(item.offer_program);
          }
          item.channel = channel;
          allOfferPrograms.push(item);
        });
      });
    });

    // Group by offer program ID
    dictionarySoReaches.forEach((offerProgramId) => {
      const filteredOfferPrograms = allOfferPrograms.filter((item) => offerProgramId === item.offer_program);

      if (filteredOfferPrograms && filteredOfferPrograms.length) {
        groupedByOfferProgram.push(filteredOfferPrograms);
      }
    });

    return groupedByOfferProgram;
  }

  /**
   * Check the smallest date
   *
   * @return {string}
   */
  private getMinDateSoReach(): string {
    let minDate: moment.Moment = null;

    this.channels.forEach((channel: string) => {
      this.days.forEach((day: string) => {
        this.dataCard[channel][day].forEach((item) => {
          if (!minDate) {
            minDate = moment(item.date, 'DD-MM-YYYY');
          } else if (moment(minDate, 'DD-MM-YYYY').isAfter(moment(item.date, 'DD-MM-YYYY'))) {
            minDate = moment(item.date, 'DD-MM-YYYY');
          }
        });
      });
    });

    return moment(minDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
  }

  /**
   * Check the biggest date
   *
   * @return {string}
   */
  private getMaxDateSoReach(): string {
    let maxDate: moment.Moment = null;

    this.channels.forEach((channel: string) => {
      this.days.forEach((day: string) => {
        this.dataCard[channel][day].forEach((item) => {
          if (!maxDate) {
            maxDate = moment(item.date, 'DD-MM-YYYY');
          } else if (moment(maxDate, 'DD-MM-YYYY').isBefore(moment(item.date, 'DD-MM-YYYY'))) {
            maxDate = moment(item.date, 'DD-MM-YYYY');
          }
        });
      });
    });

    return moment(maxDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
  }
}
