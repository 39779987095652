
import {map,  catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Channel } from '../../resource/channel.resource';
import { FilteredItem } from '../../resource/filteredItem.resource';
import { environment } from '../../../environments/environment';
import { UtilitiesHandler } from '../utilities-handlers/utilitiesHandlers';

import { Observable } from 'rxjs';

@Injectable()
export class ChannelService {
  private route: string = 'channel';

  constructor(
    private http: HttpClient,
    private utilitiesHandler: UtilitiesHandler
  ) { }

  /**
   * Get the specific Channel with ID
   *
   * @param {(string | number)} id
   * @returns {Observable<Channel>}
   * @memberof ChannelService
   */
  get(id: string | number): Observable<Channel> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}/${id}`;

    return this.http
      .get(api_base_url)
      .pipe(
        map((data: any) =>
          new Channel(data)
        ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get all channels
   *
   * @param {(Object)} [filters=null]
   * @returns {Observable<Channel[]>}
   * @memberof ChannelService
   */
  getList(filters: Object = null): Observable<Channel[]> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params})
      .pipe(
        map((data: any) =>
          data._embedded.channel
            .map((jsonChannel: any) =>
              new Channel(jsonChannel)
            )
        ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get the list of Channels for filter
   *
   * @param {(Object)} [filters=null]
   * @returns {Observable<FilteredItem[]>}
   * @memberof ChannelService
   */
  getListForFilter(filters: Object = null): Observable<FilteredItem[]> {
    const api_base_url = `${environment.api_base_url}/${this.route}`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params})
      .pipe(
        map((data: any) =>
          data._embedded.channel
            .map(jsonChannel =>
              new Channel(jsonChannel)
            )
            .map((channel: Channel) =>
              new FilteredItem(channel)
            )
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }
}
