<mat-progress-bar mode="indeterminate" *ngIf="loading" color="warn" class="padding-bottom-10"></mat-progress-bar>

<div *ngIf="purchase && (!isCreating || isEditing) && !loading">
  <div>
    <h1>Dossier : {{purchase.name}}</h1>
    <h4 class="subtitle" *ngIf="purchase.soreachPurchase">
      <span class="thin">Type SoReach:&nbsp;</span>
      <strong>{{ soReachType }}</strong>
    </h4>
  </div>

  <!-- General information -->
  <app-purchase-info [purchase]="purchase" [stakeholderGroup]="stakeholderGroup"></app-purchase-info>

  <!-- Parties prenante -->
  <app-purchase-stakeholder [purchase]="purchase" [stakeholderGroup]="stakeholderGroup"></app-purchase-stakeholder>

  <!-- Emissions et Dispositif -->
  <app-purchase-offer-program [purchase]="purchase" #purchaseOfferProgramComponent></app-purchase-offer-program>

  <!-- Budget global du dossier -->
  <app-purchase-global-budget [purchase]="purchase"></app-purchase-global-budget>

  <!-- GRP -->
  <app-purchase-grp [purchase]="purchase"></app-purchase-grp>
</div>

<!--Footer-->
<footer class="formfooter" fxLayoutAlign="center center">
  <mat-progress-bar mode="indeterminate" *ngIf="(saving || savingPurchase)" color="warn" class="bar"></mat-progress-bar>

  <div class="container-app">
    <div *ngIf="isCreating || isDeleting" class="layout horizontal">
      Diffusions en cours de traitement... Etape {{queueProgressCount}} / {{queueCreatingCount}}
    </div>
    <mat-progress-bar mode="buffer" value="{{queueProgressCount*100/queueCreatingCount}}"
                      color="warn"></mat-progress-bar>
  </div>
  <div *ngIf="!isDeleting && !isCreating " class="container-app full-width" fxLayout="row">
    <div fxLayout="row" fxLayoutGap="15px" fxFlexOffset="10px">
      <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center">
        <button mat-mini-fab class="mini-fab" fxLayout="row" fxLayoutAlign="center center"
                (click)="purchaseOfferProgramComponent.addNewPurchaseProgram()">
          <mat-icon class="white-text">add</mat-icon>
        </button>
        <span>Ajouter une emission</span>
      </div>
      <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center">
        <button mat-mini-fab class="mini-fab" fxLayout="row" fxLayoutAlign="center center"
                (click)="redirectToAvailability()" [disabled]="!backToAvailability">
          <mat-icon class="white-text">settings_backup_restore</mat-icon>
        </button>
        <span>Disponibilités</span>
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center" fxFlex class="margin-right-10">
      <button fxLayout="row" mat-flat-button (click)="generateProdSheet()" class="button-flat black">
        <span class="white-text">Feuille de Prod</span>
        <mat-icon class="white-text margin-left">get_app</mat-icon>
      </button>
      <button fxLayout="row" mat-flat-button (click)="downloadEcheancier()" class="button-flat black"
              [disabled]="(saving || savingPurchase) || !isValid">
        <span class="white-text">Echéancier</span>
        <mat-icon class="white-text margin-left">get_app</mat-icon>
      </button>
      <button fxLayout="row" mat-flat-button (click)="savePurchase(true)" class="button-flat black"
              [disabled]="!enableConvention || ((saving || savingPurchase) || !isValid)">
        <span class="white-text">Convention</span>
        <mat-icon class="white-text margin-left">get_app</mat-icon>
      </button>
      <button fxLayout="row" mat-flat-button class="button-flat black" (click)="sendProposalByMail()"
              [disabled]="(saving || savingPurchase) || !isValid">
        <span class="white-text">Envoyer la propale</span>
        <mat-icon class="white-text margin-left">mail</mat-icon>
      </button>
      <button fxLayout="row" mat-flat-button class="button-flat red" (click)="savePurchase()"
              [disabled]="(saving || savingPurchase) || !isValid">
        <span class="white-text">Enregistrer</span>
        <mat-icon class="white-text margin-left">save</mat-icon>
      </button>
    </div>
  </div>
</footer>
