<mat-card class="filter-card">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="column" fxLayoutGap="5px">
      <div fxLayout="row" fxLayoutGap="15px" class="less-margin-10">
        <div fxLayout="row" fxLayoutAlign="center center">
          <button mat-icon-button (click)="decreaseYear()">
            <mat-icon class="chevron">chevron_left</mat-icon>
          </button>
          <span class="switch-year hold-color">{{ filters.year }}</span>
          <button mat-icon-button (click)="increaseYear()">
            <mat-icon class="chevron">chevron_right</mat-icon>
          </button>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px">

          <mat-radio-group [formControl]="offerTypeCtrl">
            <mat-radio-button checked value="0">National</mat-radio-button>
            <mat-radio-button value="1">Régions</mat-radio-button>
            <mat-radio-button value="2">So Reach</mat-radio-button>
            <mat-radio-button value="3">Outre Mer</mat-radio-button>
          </mat-radio-group>

          <div fxLayout="column" class="cursor-pointer" (click)="orderSynthesis('asc')" fxLayoutAlign="center center">
            <mat-icon>arrow_drop_up</mat-icon>
            <span class="letterOrder">A</span>
          </div>
          <div fxLayout="column" class="cursor-pointer" (click)="orderSynthesis('desc')" fxLayoutAlign="center center">
            <mat-icon>arrow_drop_down</mat-icon>
            <span class="letterOrder">Z</span>
          </div>
          <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
            <span>Annonceur</span>
            <mat-slide-toggle (change)="changeView($event)"></mat-slide-toggle>
            <span>Produit</span>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutGap="15px" class="less-margin-10 margin-top-10">
        <!-- show on all modes except regionnal -->
        <mat-form-field *ngIf="offerTypeCtrl.value != 1" class="size-channels">
          <mat-chip-list #chipList>
            <mat-chip
              *ngFor="let channel of selectedChannels"
              [selectable]="selectable"
              [removable]="removable"
              (removed)="remove(channel)">
              <img preload fade class="tag" [src]="path + channel.logo">
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input
              placeholder="Chaîne"
              #channelInput
              [formControl]="channelCtrl"
              [matAutocomplete]="auto"
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="addOnBlur"
            />
          </mat-chip-list>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedChannel($event)">
            <mat-option *ngFor="let channel of filteredChannels | async" [value]="channel">
              <img preload fade class="tag" [src]="path + channel.logo">
              {{ channel.display }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <!-- show on regional mode only -->
        <mat-form-field *ngIf="offerTypeCtrl.value == 1" class="size-areas">
          <mat-chip-list #areaList>
            <mat-chip
              *ngFor="let area of selectedAreas"
              [selectable]="selectable"
              [removable]="removable"
              (removed)="removeArea(area)">
              {{ area.display }}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input
              placeholder="Région"
              #areaInput
              [formControl]="areaCtrl"
              [matAutocomplete]="autoArea"
              [matChipInputFor]="areaList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="addOnBlur"
            />
          </mat-chip-list>
          <mat-autocomplete #autoArea="matAutocomplete" (optionSelected)="selectedArea($event)">
            <mat-option *ngFor="let area of filteredAreas | async" [value]="area">
              {{ area.display }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <div fxLayout="row">
          <mat-form-field class="width-multi-select">
            <mat-select placeholder="Unité de programme" [formControl]="categoryCtrl" multiple>
              <div fxLayoutAlign="start center" class="height-all">
                <mat-checkbox class="margin-left-15" (change)="selectAllCategories()"
                              [checked]="categoryCtrl.value.length === allProgramCategories.length">
                  <span *ngIf="categoryCtrl.value.length !== allProgramCategories.length">Tout sélectionner</span>
                  <span *ngIf="categoryCtrl.value.length === allProgramCategories.length">Tout désélectionner</span>
                </mat-checkbox>
              </div>
              <mat-select-trigger>
                {{(categoryCtrl.value && categoryCtrl.value.length) ? categoryCtrl.value[0].name : ''}}
                <span *ngIf="categoryCtrl.value?.length > 1" class="text-additional-selection">
                (+{{categoryCtrl.value.length - 1}} {{categoryCtrl.value?.length === 2 ? 'autre' : 'autres'}})
              </span>
              </mat-select-trigger>
              <mat-option *ngFor="let category of allProgramCategories"
                          [value]="category">{{category.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <mat-form-field class="width-search">
          <input placeholder="Offre" type="text" matInput [formControl]="offerNameCtrl">
        </mat-form-field>
      </div>

      <mat-slide-toggle class="toggle-oe" (change)="switchExclusivesOffers($event)" color="primary">Uniquement Offres Exclusives</mat-slide-toggle>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px">
      <button mat-flat-button (click)="downloadSynthesis()" class="button-flat black"
              [disabled]="sourceSynthesis.length === 0">
        <span class="white-text">Télécharger</span>
        <mat-icon class="white-text margin-left">get_app</mat-icon>
      </button>

      <button mat-flat-button (click)="searchSynthesis()" class="find-button">Rechercher</button>
    </div>
  </div>
</mat-card>
<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
