import { Injectable } from '@angular/core';

import { Campaign } from '../../resource/campaign.resource';
import { FilteredItem } from '../../resource/filteredItem.resource';
import { Purchase } from '../../resource/purchase.resource';

import * as moment from 'moment';

@Injectable()
export class AutocompleteService {

  constructor() { }

  /**
   * Filter an item list from a value
   * @param value - The value to be compared.
   * @param items - An array of item to be compared with the value.
   * */
  public filterItems(value: string, items: Array<FilteredItem>): Array<FilteredItem> {
    if (typeof value === 'string' && items) {
      return items.filter(item =>
          item['display'].toLowerCase().indexOf(value.toLowerCase()) !== -1
              || item['value'].toString().toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
    }

    return items ? items : [];
  }

  /**
   * Display item value and display (ex : Paul Bahh (PBA))
   * @param item - item with an 'display' param and a 'value' param.
   * */
  public displayAutocompleteWithValue(item: FilteredItem): string | FilteredItem {
    return item ? (item.hasOwnProperty('display') && item.hasOwnProperty('value') ? `${item.display} (${item.value})` : item) : item;
  }

  /**
   * Display only the 'display' params of type FilteredItem
   * @param item - item with an 'display' param and a 'value' param.
   * */
  public displayAutocomplete(item: FilteredItem): string | FilteredItem {
    return item ? (item.hasOwnProperty('display') ? item.display : item) : item;
  }

  public displayCampaignFullName(item: FilteredItem): string | FilteredItem {
    let campaign: Campaign = item.entity;

    return campaign ? campaign.getFullName() : item;
  }

  public displayPurchaseWithYear(item: FilteredItem): string | FilteredItem {
    let purchase: Purchase = item.entity;

    return purchase ? `${moment(purchase.startCommunicationPeriod, 'YYYY-MM-DD').year()} ${purchase.name}` : item;
  }
}
