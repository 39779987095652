import { Base } from './base.resource';
import { Category } from './category.resource';

export interface JsonProgram {
    id: string;
    name?: string;
    category?: any;
    type?: any;
    status?: number;
}

export class Program extends Base {
    static typeItems = [
      { value: 1, display: 'Diffusion' },
      { value: 2, display: 'Production' },
    ];

    public id: string;
    public name: string;
    public category: Category;
    public type: number;
    public status: number;

    constructor(jsonProgram: JsonProgram) {
        super();
        jsonProgram = this.convertToUnderscore(jsonProgram);

        this.id = jsonProgram.id;
        this.name = jsonProgram.name;
        this.type = jsonProgram.type;

        if (jsonProgram.category) {
          this.category = new Category(jsonProgram.category);
        }

        if (jsonProgram.status) {
          this.status = jsonProgram.status;
        }
    }
}
