import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';

import { CustomToastrService } from '@service/toastr/custom-toastr.service';

import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { Moment } from 'moment';
import * as moment from 'moment';
import { MonitoringLotDotation, PeriodMonitoringLotDotation } from '../../../../../resource/lot-dotation.resource';

interface Period {
  startPeriod: Moment | null,
  endPeriod: Moment | null,
  diffusionHour: string | null,
  lotsWon: number | null
}

@Component({
  selector: 'app-add-monitoring-lot-period',
  templateUrl: './add-monitoring-lot-period.component.html',
  styleUrls: ['./add-monitoring-lot-period.component.scss']
})
export class AddMonitoringLotPeriodComponent implements OnInit, OnDestroy {
  @Input() public informationGroup;
  @Input() public monitoringLot: MonitoringLotDotation;

  private componentDestroyed$: Subject<any> = new Subject();

  constructor(
    private fb: FormBuilder,
    private customToastrService: CustomToastrService) {}

  ngOnInit() {
    this.informationGroup.addControl('periods', this.fb.array([]));

    if (this.monitoringLot && this.monitoringLot.periods) {
      this.monitoringLot.periods.forEach((period: PeriodMonitoringLotDotation) => this.addPeriod(period));
    }

    this.periods.valueChanges
      .pipe(
        debounceTime(500),
        filter(() => this.periods.valid),
        takeUntil(this.componentDestroyed$))
      .subscribe((periods: Period[]) => {
        this.checkFormatDate(periods);
        this.checkQuantityLot(periods);
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.unsubscribe();
  }

  get periods(): FormArray {
    return this.informationGroup.get('periods') as FormArray;
  }

  /**
   * Add period to the end
   */
  public addPeriod(period?: PeriodMonitoringLotDotation): void {
    this.periods.push(
      this.fb.group({
        id: [(period && period.id) ? period.id : null],
        startPeriod: [period && period.startPeriod ? period.startPeriod : null, [Validators.required]],
        endPeriod: [period && period.endPeriod ? period.endPeriod : null, [Validators.required]],
        diffusionHour: [period && period.diffusionHour ? period.diffusionHour : '00:00', [Validators.required]],
        lotsBroadcasted: [
          period && period.lotsBroadcasted ? period.lotsBroadcasted : null,
          [
            Validators.required,
            Validators.max(999999.9999)
          ]
        ],
        lotsWon: [
          period && period.lotsWon ? period.lotsWon : null,
          [
            Validators.required,
            Validators.max(period && period.lotsBroadcasted ? period.lotsBroadcasted : 0)
          ]
        ],
      })
    );
  }

  /**
   * Update validator of lotsWon for more protection/control (max)
   *
   * @param index {number}
   */
  public changeLotsBroadcastedQuantity(index: number): void {
    const broadcastsNumber = this.periods.get(index.toString()).get('lotsBroadcasted').value;

    this.periods.get(index.toString()).get('lotsWon').setValidators([Validators.required, Validators.max(broadcastsNumber)]);
    this.periods.get(index.toString()).get('lotsWon').updateValueAndValidity();
  }

  /**
   * Remove selected period
   *
   * @param index {number}
   */
  public removePeriod(index: number): void {
    this.periods.removeAt(index);
  }

  /**
   * Check month, year and overlap
   *
   * @param periods {Array}
   * @private
   */
  private checkFormatDate(periods: Period[]): void {
    periods = this.sortDate(periods);

    if (!this.checkMonthYear(periods)) {
      this.periods.setErrors({'isError': 'Les périodes doivent être sur le même mois de l\'année!'});
    } else if (!this.checkOverlap(periods)) {
      this.periods.setErrors({'isError': 'Il n\'est pas autorisé d\'avoir des périodes en chevauchement!'});
    } else {
      this.periods.setErrors(null);
    }
  }

  /**
   * Check if lots won and lot already won don't exceed the quantity recorded
   *
   * @param periods {Array}
   */
  private checkQuantityLot(periods: Period[]): void {
    let lotsWon = 0;

    periods.forEach(period => {
      lotsWon += period.lotsWon;
    });

    // Control when modify an existing monitoring lot
    if (this.monitoringLot) {
      if ((lotsWon - this.monitoringLot.totalLotsWon) > (this.monitoringLot.lot.quantity - this.monitoringLot.lot.totalWonLots)) {
        this.customToastrService.displayToastr('WARNING', 'Le nombre de lots gagnés est supérieur au stock de lots disponible');
      }
    } else {
      // Control when create an monitoring lot
      if ((lotsWon + this.informationGroup.get('lot').value.entity.totalWonLots) > this.informationGroup.get('lot').value.entity.quantity) {
        this.customToastrService.displayToastr('WARNING', 'Le nombre de lots gagnés est supérieur au stock de lots disponible');
      }
    }
  }

  /**
   * Order/sort by date of startPeriod
   *
   * @param periods {Array}
   * @return {Array}
   */
  private sortDate(periods: Period[]): Period[] {
    return periods.sort((date1: Period, date2: Period) => {
      if (moment(date1.startPeriod).isBefore(date2.startPeriod)) {
        return -1;
      } else if (moment(date1.startPeriod).isAfter(date2.startPeriod)) {
        return 1;
      }
    });
  }

  /**
   * Check if all the periods are in the same month of the same year
   *
   * @param periods {Array}
   * @return {boolean}
   */
  private checkMonthYear(periods: Period[]): boolean {
    let referenceDate: Moment;

    return periods.every((period: Period) => {
      if (!referenceDate) {
        referenceDate = moment(period.startPeriod);
      }
      if (!moment(period.startPeriod).isSame(referenceDate, 'year') || !moment(period.startPeriod).isSame(referenceDate, 'month')) {
        return false;
      } else if (!moment(period.endPeriod).isSame(referenceDate, 'year') || !moment(period.endPeriod).isSame(referenceDate, 'month')) {
        return false;
      } else {
        return true;
      }
    });
  }

  /**
   * Check if there's overlap between the periods
   *
   * @param periods {Array}
   * @return {boolean}
   */
  private checkOverlap(periods: Period[]): boolean {
    let referenceDate: Moment;

    return periods.every((period: Period) => {
      if (!referenceDate) {
        referenceDate = moment(period.endPeriod);
        return true;
      } else if (moment(period.startPeriod).isBefore(referenceDate)) {
        return false;
      } else {
        referenceDate = moment(period.endPeriod);
        return true;
      }
    });
  }
}
