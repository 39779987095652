import {Injectable} from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class FiltersService {

  /**
   * Init filters
   *
   * @param initYear? number
   * @param initOffer? Object
   */
  initFilters(initYear?: number, initOffer?: Object) {
    const initDate = initYear ? moment(initYear) : moment().startOf('year');
    let initOffers = [];

    if (initOffer) {
      initOffers.push(initOffer);
    }

    return {
      displayMode: 'year',
      year: initDate.year(),
      month: initDate.month(),
      broadcasted_year: initDate.year(),
      broadcasted_from: initDate.startOf('year').format('YYYY-MM-DD'),
      broadcasted_to: initDate.endOf('year').format('YYYY-MM-DD'),
      category_id: '',
      programs: [],
      channels: [],
      areas: [],
      offers: initOffers,
      offer_program_id: [],
      offer_program_budget_id: [],
      offer_from: initDate.startOf('year').format('YYYY-MM-DD'),
      offer_to: initDate.endOf('year').format('YYYY-MM-DD'),
      start_communication_period: initDate.startOf('year').format('YYYY-MM-DD'),
      end_communication_period: initDate.endOf('year').format('YYYY-MM-DD'),
      name: '',
      user: '',
      advertiser_or_customer: '',
      product_id: '',
      purchase_type_id: '',
      isExclusive: 0,
    };
  }

  getQueryParams(filters) {
    let queryParams: any = {};
    let programId: string = this.concatParamIds(filters.programs);
    let channelId: string = this.concatParamIds(filters.channels);
    let userId: string = this.concatParamIds(filters.user);
    let areaId: string = this.concatParamIds(filters.areas);
    let offerId: string = this.concatParamIds(filters.offers);
    let offerProgramId: string = this.concatParamIds(filters.offer_program_id);
    let offerProgramBudgetId: string = this.concatParamIds(filters.offer_program_budget_id);
    let advertiserOrCustomerId: string = this.concatParamIds(filters.advertiser_or_customer);
    let productId: string = this.concatParamIds(filters.product_id);
    let purchaseTypeId: string = this.concatParamIds(filters.purchase_type_id);

    if (filters.broadcasted_from && filters.broadcasted_to) {
      queryParams.broadcasted_from = filters.broadcasted_from;
      queryParams.broadcasted_to = filters.broadcasted_to;
    } else if (filters.broadcasted_year) {
      queryParams.broadcasted_year = filters.broadcasted_year;
    }

    if (filters.offer_from && filters.offer_to) {
      queryParams.offer_from = filters.offer_from;
      queryParams.offer_to = filters.offer_to;
    }

    if (filters.start_communication_period && filters.end_communication_period) {
      queryParams.start_communication_period = filters.start_communication_period;
      queryParams.end_communication_period = filters.end_communication_period;
    }

    if (programId) {
      queryParams.program_id = programId;
    }

    if (filters.category_id && filters.category_id.length === 1) {
      queryParams.category_id = filters.category_id[0].value;
    }

    if (channelId) {
      queryParams.channel_id = channelId;
    }

    if (filters.name) {
        queryParams.name = filters.name;
    }

    if (areaId) {
      queryParams.area_id = areaId;
    }

    if (offerId) {
      queryParams.offer_id = offerId;
    }

    if (offerProgramId) {
      queryParams.offer_program_id = offerProgramId;
    }

    if (offerProgramBudgetId) {
      queryParams.offer_program_budget_id = offerProgramBudgetId;
    }

    if (filters.user && userId !== null) {
      queryParams.user = userId;
    }

    if (filters.advertiser_or_customer && advertiserOrCustomerId !== null) {
      queryParams.advertiser_or_customer = advertiserOrCustomerId;
    }

    if (filters.product_id && productId !== null) {
      queryParams.product_id = productId;
    }

    if (filters.purchase_type_id && purchaseTypeId !== null) {
      queryParams.purchase_type_id = purchaseTypeId;
    }

    if (filters.isExclusive) {
      queryParams.is_exclusive = filters.isExclusive;
    }

    return queryParams;
  }

  concatParamIds(subFilters): string {
    if ((subFilters) && (subFilters.length > 0)) {
      let params = '';

      subFilters.forEach(function(filter) {
        params = params + filter.value + ';';
      });

      // remove last character ';'
      return params.substring(0, params.length - 1);
    }

    return null;
  }

  isFr3Selected(filters): boolean {
    let selectedChannels = filters.channels;
    let isFr3Selected = false;

    selectedChannels.forEach(function(channel) {
      if (channel.value === '3') {
         isFr3Selected = true;
      }
    });

    return isFr3Selected;
  }

  public getI18iForDatePicker() {
      return {
        monthNames: moment.months(),
        weekdays: moment.weekdays(),
        weekdaysShort: moment.weekdaysShort(),
        firstDayOfWeek: moment.localeData().firstDayOfWeek(),
        week: 'Semaine',
        calendar: 'Calendrier',

        clear: 'Réinitialiser',
        today: 'Aujourd\'hui',
        cancel: 'Annuler',
        formatDate: function(d) {
            return moment(d, 'YYYY-MM-DDTHH:mm:ss').format(moment.localeData().longDateFormat('L'));
        },
        parseDate: function(s) {
            return moment(s, moment.localeData().longDateFormat('L')).toDate();
        },
        formatTitle: function(monthName, fullYear) {
            return monthName + ' ' + fullYear;
        }
      };
  }
}
