
import {map,  catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { PurchaseGrp } from '../../resource/purchase-grp.resource';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';
import { UtilitiesHandler } from '@service/utilities-handlers/utilitiesHandlers';
import { environment } from '../../../environments/environment';

import { Subject ,  Observable } from 'rxjs';

@Injectable()
export class PurchaseGrpService {
  private route: string = 'purchase_grp';
  private purchaseGrpSource = new Subject<PurchaseGrp>();
  public  purchaseGrpSource$ = this.purchaseGrpSource.asObservable();

  constructor(
    private customToastrService: CustomToastrService,
    private http: HttpClient,
    private utilitiesHandler: UtilitiesHandler
  ) { }

  public updatePurchaseGrp(purchaseId): void {
    this.purchaseGrpSource$ = this.get(purchaseId);
    this.purchaseGrpSource$
      .subscribe(
        purchaseGrp => {
          this.purchaseGrpSource.next(purchaseGrp);
        }, (error) => {
          this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
        }
      );
  }

  /**
   * Get purchage GRP with ID
   *
   * @private
   * @param {(string | number)} id
   * @returns {Observable<PurchaseGrp>}
   * @memberof PurchaseGrpService
   */
  private get(id: string | number): Observable<PurchaseGrp> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}/${id}`;

    return this.http
      .get(api_base_url).pipe(
      map((data: any) =>
        new PurchaseGrp(data)
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }
}
