import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

import { UtilitiesHandler } from '@service/utilities-handlers/utilitiesHandlers';

import { Area } from '../../resource/area.resource';
import { FilteredItem } from '../../resource/filteredItem.resource';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class AreaService {

  constructor(
    private http: HttpClient,
    private utilitiesHandler: UtilitiesHandler
  ) {}

  /**
   * Get list of Area
   *
   * @param {Object} [filters=null]
   * @returns {Observable<Area[]>}
   * @memberof AreaService
   */
  getList(filters: Object = null): Observable<Array<Area>> {
    const api_base_url: string = `${environment.api_base_url}/area`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params})
      .pipe(
        map((data: any) =>
          data._embedded.area
            .map((jsonArea: any) =>
              new Area(jsonArea)
            )
        ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get list of Area - France 3
   *
   * @param {Object} [filters=null]
   * @returns {Observable<FilteredItem[]>}
   * @memberof AreaService
   */
  getListForFilter(filters: Object = null): Observable<FilteredItem[]> {
    const api_base_url: string = `${environment.api_base_url}/area`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params})
      .pipe(
        map((data: any) =>
          data._embedded.area
            .map((jsonArea: any) =>
              new Area(jsonArea)
            )
            .map((area: Area) =>
              new FilteredItem(area)
            )
        ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }
}
