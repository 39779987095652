import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
  @Input('type')  type: string;
  @Input('size')  size: string;
  @Input('alt')   text: string;

  color:        string;
  diameter:     string;
  strokeWidth:  string;

  constructor() {}

  ngOnInit() {
    this.selectType();
    this.selectSize();
  }

  // warning -> red && info -> blue && default -> blue
  selectType(): void {
    switch (this.type) {
      case 'warning': {
        this.color = 'warn';
        break;
      }
      case 'info': {
        this.color = 'primary';
        break;
      }
      default: {
        this.color = 'primary';
        break;
      }
    }
  }

  // small -> 25px && big -> 100px && default -> 100px
  selectSize(): void {
    switch (this.size) {
      case 'small': {
        this.diameter = '25';
        this.strokeWidth = '4';
        break;
      }
      case 'medium': {
        this.diameter = '50';
        this.strokeWidth = '5';
        break;
      }
      case 'big': {
        this.diameter = '100';
        this.strokeWidth = '10';
        break;
      }
      default: {
        this.diameter = '100';
        this.strokeWidth = '10';
        break;
      }
    }
  }
}
