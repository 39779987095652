import { Injectable } from '@angular/core';

@Injectable()
export class MathService {

  constructor() { }

  /**
   * Get round
   *
   * @param {*} value
   * @param {*} scale
   * @returns {number}
   * @memberof MathService
   */
  public getRound(value, scale): number {
    let amount = Math.pow(10, scale);

    return Math.round(value * amount) / amount;
  }

}
