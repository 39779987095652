import { Base } from './base.resource';


/**
 * Offer Period Indice interface
 * @export
 * @interface jsonOffer
 */
export interface JsonOfferPeriodIndice {
    id: number;
    start_date: string;
    end_date: string;
    indice: number;
    order_period: number;
}

/**
 * Offer Period Indice resource
 * @export
 * @class OfferPeriodIndice
 * @extends {Base}
 */
export class OfferPeriodIndice extends Base {

    static init(): JsonOfferPeriodIndice {
        return {
            id: null,
            start_date: 'du',
            end_date: 'au',
            indice: 0,
            order_period: 0
        };
    }

    public id: number;
    public startDate: string;
    public endDate: string;
    public indice: number;
    public orderPeriod: number;

    constructor(jsonOfferPeriodIndice) {
        super();
        jsonOfferPeriodIndice = this.convertToUnderscore(jsonOfferPeriodIndice);

        this.id = jsonOfferPeriodIndice.id;
        this.startDate = jsonOfferPeriodIndice.start_date;
        this.endDate = jsonOfferPeriodIndice.end_date;
        this.indice = jsonOfferPeriodIndice.indice;
        this.orderPeriod = jsonOfferPeriodIndice.order_period;
    }
}
