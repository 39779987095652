import { Base } from './base.resource';
import { Channel, JsonChannel } from './channel.resource';

export interface JsonArea {
    id: string;
    name?: string;
    channel_id?: JsonChannel;
    channel?: JsonChannel;
}

export class Area extends Base {

    public id: string;
    public name: string;
    public channel: Channel;

    constructor(jsonArea: JsonArea) {
        super();
        jsonArea = this.convertToUnderscore(jsonArea);

        this.id = jsonArea.id;
        this.name = jsonArea.name;

        if (jsonArea.channel_id)  {
            this.channel = new Channel(jsonArea.channel_id);
        // workaround fot the second overriding case
        } else if (jsonArea.channel) {
            this.channel = new Channel(jsonArea.channel);
        }
    }
}
