<mat-card class="propal container-app" [@slideInOut]="propalState" *ngIf="isDefaultCart || isF3AreaCart">
  <mat-card-title class="header-propal" (click)="togglePropal()" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="15px">
    <span>Créer une propale</span>
    <small>{{ propalCart?.offerPrograms.length || 0 }} {{ programLabel }} - {{ totalBroadcasts.length || f3AreaTotalBroadcasts }} {{ broadcastLabel }}</small>
    <mat-icon *ngIf="propalState == 'in'">arrow_drop_down</mat-icon>
    <mat-icon *ngIf="propalState == 'out'">arrow_drop_up</mat-icon>
  </mat-card-title>

  <div class="layout horizontal flex">
    <mat-card class="slider-propal" fxFlex="66">
      <ng-container *ngIf="isDefaultCart">
        <div class="layout horizontal flex">
          <div *ngFor="let offerProgramPropal of propalCart?.offerPrograms">
            <app-offer-program-propal
              [offerProgramPropal]="offerProgramPropal">
            </app-offer-program-propal>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isF3AreaCart">
        <div class="layout horizontal flex max-height card-area" fxLayout="row" fxLayoutGap="10px">
          <div *ngFor="let offerProgramPropal of propalCart?.offerPrograms">
            <app-area-program-propal
              [offerProgramPropal]="offerProgramPropal"
              (onDeleteProgram)="deleteOfferProgramFromF3AreaCart($event)">
            </app-area-program-propal>
          </div>
        </div>
      </ng-container>
    </mat-card>

    <div class="propal-actions" fxFlex="33">
      <!-- Propale -->
      <div class="propal-main-information">
          <app-propal-main-information
              [purchaseParams]='purchaseParams'
              (valid)="validMainInformation = $event"
              (purchase)="newPurchaseMainInformation = $event">
          </app-propal-main-information>
      </div>
      <div class="propal-buttons">
        <button mat-button (click)="cancel()" class="button-cancel">Annuler</button>

        <button mat-flat-button class="save button-flat red title-propal"
                          (click)="save()"
                          [disabled]="!validMainInformation || saving">
            {{ createOrUpdateMessage }}
        </button>
        <br>
        <br>
        <div class="loader">
            <mat-progress-bar *ngIf="saving" mode="indeterminate"></mat-progress-bar>
        </div>
      </div>
    </div>
  </div>
</mat-card>
