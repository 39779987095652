import { Title }  from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { LoginComponent } from '../../login/login.component';
import { AuthService } from '@service/auth.service';
import { User } from '../../resource/user/user.resource';
import { MatDialog } from '@angular/material';
import { SoReachDialogComponent } from '../dialog/so-reach-dialog/so-reach-dialog.component';

const DASHBOARD     = '';
const OFFER         = 'offers';
const AVAILABILITY  = 'availabilities';
const PURCHASE      = 'purchases';
const LOTS          = 'lots';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    providers: [ LoginComponent ]
})
export class MenuComponent implements OnInit {
    public entries: Array<any>;
    public selected: number;
    public name: string;
    public environmentName: string;
    private protectNumber: number = 0;

    constructor(
        public dialog: MatDialog,
        public loginComponent: LoginComponent,
        private titleService: Title,
        private router: Router,
        private authService: AuthService
    ) {}

    ngOnInit() {
        this.displayEnvironmentName();

        this.entries = [
            { name: 'Mon tableau de bord', routerLink: DASHBOARD },
            { name: 'Les offres', routerLink: 'offers', id: OFFER  },
            { name: 'Les disponibilités', routerLink: AVAILABILITY, id: AVAILABILITY },
            { name: 'Les dossiers', routerLink: PURCHASE },
            { name: 'Les lots', routerLink: LOTS },
        ];

        if (this.authService.getAccessToken()) {
          let trigramme = localStorage.getItem('trigramme');

          if (!User.checkUserTrg(trigramme)) {
            this.entries.splice(this.entries.findIndex((item) => item.name === 'Les offres'), 1);
          }
        }

        // set user name
        this.setUserName();

        // get active nav item
        this.router.events.subscribe((response: any) => {
            if (response.url) {
                let url = response.url.substring(1);
                let lastCharacter = url.search('/') !== -1 ?  url.search('/') :
                    ( url.search(/\?/) !== -1 ?  url.search(/\?/) : url.length);
                let activeRoute = url.substr(0, lastCharacter);
                    this.titleService.setTitle(response['title']);

                for (let index = 0; index < this.entries.length; index++) {
                    if (this.entries[index] && activeRoute === this.entries[index].routerLink) {
                        this.selected = index;
                        break;
                    }
                }
            }
        });
    }

  /**
   * Display actual environment except production
   */
  displayEnvironmentName(): void {
      const location = window.location.href;
      const urlDomainSpited = location.split('//').pop().split('.');

      if (urlDomainSpited.length) {
        if (urlDomainSpited[0] && urlDomainSpited[0].toLowerCase() === 'dev' && urlDomainSpited.length === 4) {
          this.environmentName = 'DEVELOPMENT';
        } else if (urlDomainSpited[0] && urlDomainSpited[0].toLowerCase() === 'recette-po' && urlDomainSpited.length === 4) {
          this.environmentName = 'RECETTE-PO';
        } else if (urlDomainSpited[0] && urlDomainSpited[0].toLowerCase() === 'recette' && urlDomainSpited.length === 4) {
          this.environmentName = 'RECETTE';
        } else if (urlDomainSpited[0] && urlDomainSpited[0].toLowerCase() === 'parrainage' && urlDomainSpited.length === 3) {
          this.environmentName = 'PRODUCTION';
        } else {
          this.environmentName = urlDomainSpited[0];
        }
      } else {
        this.environmentName = null;
      }
    }

    /**
     * Set user name
     */
    setUserName(): void {
        if (localStorage.getItem('email')) {
            const email = localStorage.getItem('email').substring(0, localStorage.getItem('email').indexOf('@'));
            const firstName = email.split('.')[0];
            const lastName = email.split('.')[1] ? email.split('.')[1] : null;

            if (firstName && lastName) {
              this.name = `${firstName.charAt(0).toUpperCase()}${firstName.slice(1)}  ${lastName.toUpperCase()}`;
            } else {
              this.name = `${firstName.charAt(0).toUpperCase()}${firstName.slice(1)}`;
            }
        } else if (this.protectNumber <= 5) {
          setTimeout( () => {
            this.protectNumber++;
            this.setUserName();
          }, 2500 );
        }
    }

    /**
     * Open dialog param SO REACH
     */
    openParamSoReach(): void {
      this.dialog.open(SoReachDialogComponent, {
        width: '90%',
        height: '95%',
        disableClose: true
      });
    }
}
