
import {filter, map,  catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Purchase } from '../../resource/purchase.resource';
import { Abatement } from '../../resource/abatement.resource';
import { PurchaseAbatement } from '../../resource/purchase-abatement.resource';
import { AbatementService } from '../abatement/abatement.service';
import { MathService } from '../utilities/math.service';
import { environment } from '../../../environments/environment';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';
import { UtilitiesHandler } from '@service/utilities-handlers/utilitiesHandlers';
import { Observable ,  Subject } from 'rxjs';

import * as moment from 'moment';

@Injectable()
export class PurchaseAbatementService {
  private route: string = 'purchase_abatement';

  // Dupliquer pour chaque régie
  private purchaseAbatementsLoaded: PurchaseAbatement[] = [];
  private purchaseAbatements: PurchaseAbatement[] = [];
  private purchaseAbatementsSavePoint: PurchaseAbatement[] = [];

  // Abatements for regions
  private purchaseAbatementsLoadedRegion: PurchaseAbatement[] = [];
  private purchaseAbatementsRegion: PurchaseAbatement[] = [];
  private purchaseAbatementsSavePointRegion: PurchaseAbatement[] = [];

  private purchaseAbatementsLoadedDomtom: PurchaseAbatement[] = [];
  private purchaseAbatementsDomtom: PurchaseAbatement[] = [];
  private purchaseAbatementsSavePointDomtom: PurchaseAbatement[] = [];

  private purchaseAbatementsLoadedThematique: PurchaseAbatement[] = [];
  private purchaseAbatementsThematique: PurchaseAbatement[] = [];
  private purchaseAbatementsSavePointThematique: PurchaseAbatement[] = [];

  private purchaseAbatementsLoadedInternational: PurchaseAbatement[] = [];
  private purchaseAbatementsInternational: PurchaseAbatement[] = [];
  private purchaseAbatementsSavePointInternational: PurchaseAbatement[] = [];

  private purchaseAbatementsLoadedF4: PurchaseAbatement[] = [];
  private purchaseAbatementsF4: PurchaseAbatement[] = [];
  private purchaseAbatementsSavePointF4: PurchaseAbatement[] = [];

  private removePurchaseAbatementSource = new Subject<string>();
  public removePurchaseAbatementSource$ = this.removePurchaseAbatementSource.asObservable();
  private abatementPreRequested = [];

  private headers: HttpHeaders;

  constructor(
    private abatementService: AbatementService,
    private mathService: MathService,
    private http: HttpClient,
    private customToastrService: CustomToastrService,
    private utilitiesHandler: UtilitiesHandler
  ) { }

  /**
   * Get purchase abatement with ID
   *
   * @param {(string | number)} id
   * @returns {Observable<PurchaseAbatement>}
   * @memberof PurchaseAbatementService
   */
  get(id: string | number): Observable<PurchaseAbatement> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}/${id}`;

    return this.http
      .get(api_base_url).pipe(
      map((data: any) =>
        new PurchaseAbatement(data)
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get list of purchase abatements
   *
   * @param {Object} [filters=null]
   * @returns {Observable<PurchaseAbatement[]>}
   * @memberof PurchaseAbatementService
   */
  public getList(filters: Object = null): Observable<PurchaseAbatement[]> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params}).pipe(
      map((data: any) =>
        data._embedded.purchase_abatement
          .map((jsonPurchaseAbatement: any) =>
            new PurchaseAbatement(jsonPurchaseAbatement)
          )
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Post purchase abatement
   *
   * @param {PurchaseAbatement} purchaseAbatement
   * @returns {Observable<Object>}
   * @memberof PurchaseAbatementService
   */
  public post(purchaseAbatement: PurchaseAbatement): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;

    return this.http
      .post(api_base_url, this.extract(purchaseAbatement))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Post purchase abatement collection
   *
   * @param {PurchaseAbatement[]} purchaseAbatements
   * @returns {Observable<Object>}
   * @memberof PurchaseAbatementService
   */
  public postCollection(purchaseAbatements: PurchaseAbatement[]): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;

    return this.http
      .post(api_base_url, this.extractCollection(purchaseAbatements))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Edit purchase abatement with ID
   *
   * @param {PurchaseAbatement} purchaseAbatement
   * @returns {Observable<Object>}
   * @memberof PurchaseAbatementService
   */
  public edit(purchaseAbatement: PurchaseAbatement): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}/${purchaseAbatement.id}`;

    return this.http
      .put(api_base_url, this.extract(purchaseAbatement))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Edit collection of purchase abatement
   *
   * @param {PurchaseAbatement[]} purchaseAbatements
   * @returns {Observable<Object>}
   * @memberof PurchaseAbatementService
   */
  public editCollection(purchaseAbatements: PurchaseAbatement[]): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;

    return this.http
      .put(api_base_url, this.extractCollection(purchaseAbatements, true))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Delete collection of purchase abatement
   *
   * @param {PurchaseAbatement[]} purchaseAbatements
   * @returns {Observable<Object>}
   * @memberof PurchaseAbatementService
   */
  public deleteCollection(purchaseAbatements: PurchaseAbatement[]): Observable<Object> {
    const api_base_url = `${environment.api_base_url}/${this.route}`;

    return this.http
      .request('delete', api_base_url, {
        headers: this.headers,
        body: {ids: purchaseAbatements.map(purchaseAbatement => purchaseAbatement.id)}
      })
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Delete purchase abatement
   *
   * @param {PurchaseAbatement} purchaseAbatement
   * @returns {Observable<Object>}
   * @memberof PurchaseAbatementService
   */
  public delete(purchaseAbatement: PurchaseAbatement): Observable<Object> {
    const api_base_url = `${environment.api_base_url}/${this.route}/${purchaseAbatement.id}`;

    return this.http
      .delete(api_base_url)
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Load purchase abatements
   *
   * @param {Purchase} purchase
   * @param {String} group
   * @returns {Observable<PurchaseAbatement[]>}
   * @memberof PurchaseAbatementService
   */
  public loadPurchaseAbatements(purchase: Purchase, group: String): Observable<PurchaseAbatement[]> {
    if (!(purchase && purchase.id)) {
      return Observable.create(observer => {
        observer.next([]);
      });
    }
    let filters = {
      purchase_id: purchase.id,
      channel_group: group
    };

    return Observable.create(observer => {
      this.getList(filters)
        .subscribe(purchaseAbatements => {
          if (group === 'F') {
            this.purchaseAbatementsLoaded = this.clone(purchaseAbatements);
            this.purchaseAbatements = this.clone(purchaseAbatements);
            this.purchaseAbatementsSavePoint = this.clone(purchaseAbatements);
          } else if (group === 'FREG') {
            this.purchaseAbatementsLoadedRegion = this.clone(purchaseAbatements);
            this.purchaseAbatementsRegion = this.clone(purchaseAbatements);
            this.purchaseAbatementsSavePointRegion = this.clone(purchaseAbatements);
          } else if (group === 'O') {
            this.purchaseAbatementsLoadedDomtom = this.clone(purchaseAbatements);
            this.purchaseAbatementsDomtom = this.clone(purchaseAbatements);
            this.purchaseAbatementsSavePointDomtom = this.clone(purchaseAbatements);
          } else if (group === 'W') {
            this.purchaseAbatementsLoadedThematique = this.clone(purchaseAbatements);
            this.purchaseAbatementsThematique = this.clone(purchaseAbatements);
            this.purchaseAbatementsSavePointThematique = this.clone(purchaseAbatements);
          } else if (group === 'I') {
            this.purchaseAbatementsLoadedInternational = this.clone(purchaseAbatements);
            this.purchaseAbatementsInternational = this.clone(purchaseAbatements);
            this.purchaseAbatementsSavePointInternational = this.clone(purchaseAbatements);
          } else if (group === 'F4') {
            this.purchaseAbatementsLoadedF4 = this.clone(purchaseAbatements);
            this.purchaseAbatementsF4 = this.clone(purchaseAbatements);
            this.purchaseAbatementsSavePointF4 = this.clone(purchaseAbatements);
          }

          observer.next(purchaseAbatements);
        });
    });
  }

  /**
   * Save
   *
   * @param {Purchase} purchase
   * @param {PurchaseAbatement[]} purchaseAbatements
   * @param {String} group
   * @returns {Observable<any>}
   * @memberof PurchaseAbatementService
   */
  public save(purchase: Purchase, purchaseAbatements: PurchaseAbatement[], group: String): Observable<any> {
    return Observable.create(observer => {
      let itemsToDelete = [];
      let itemsToEdit = [];
      let itemsToCreate = [];

      let seletectedPurchasedAbatementLoaded = this.getPurchaseAbatementsLoaded(group);

      seletectedPurchasedAbatementLoaded.forEach(item => {
        let keep = purchaseAbatements.filter(purchaseAbatement => purchaseAbatement.id === item.id);

        if (!keep.length) {
          itemsToDelete.push(item);
        }
      });

      purchaseAbatements.forEach(purchaseAbatement => {
        if (purchaseAbatement.id) {
          itemsToEdit.push(purchaseAbatement);
        } else {
          itemsToCreate.push(purchaseAbatement);
        }
      });

      if (!itemsToDelete.length) {
        observer.next();
      } else {
        this.deleteCollection(itemsToDelete)
          .subscribe(() => {
            observer.next();
          }, (error) => {
            this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
          });
      }

      if (!itemsToCreate.length) {
        observer.next();
      } else {
        this.postCollection(itemsToCreate)
          .subscribe(() => {
            observer.next();
          }, (error) => {
            this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
          });
      }

      if (!itemsToEdit.length) {
        observer.next();
      } else {
        this.editCollection(itemsToEdit)
          .subscribe(() => {
            observer.next();
          }, (error) => {
            this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
          });
      }
    });
  }

  /**
   * Create purchase Abatement
   *
   * @param {Abatement} abatement
   * @param {Purchase} purchase
   * @param {String} group
   * @returns {PurchaseAbatement}
   * @memberof PurchaseAbatementService
   */
  public createPurchaseAbatement(abatement: Abatement, purchase: Purchase, group: String): PurchaseAbatement {
    let purchaseAbatement = new PurchaseAbatement({});

    purchaseAbatement.abatement = abatement;
    purchaseAbatement.purchase = purchase;
    purchaseAbatement.rate = abatement.rate ? abatement.rate : null;
    purchaseAbatement.id = this.getPurchaseAbatementIdByAbatement(abatement, group);
    purchaseAbatement.channelGroup = group;

    return purchaseAbatement;
  }

  /**
   * Clone
   *
   * @param {PurchaseAbatement[]} purchaseAbatements
   * @returns {PurchaseAbatement[]}
   * @memberof PurchaseAbatementService
   */
  public clone(purchaseAbatements: PurchaseAbatement[]): PurchaseAbatement[] {
     return JSON.parse(JSON.stringify(purchaseAbatements));
  }

  /**
   * Remove purcase abatement (event)
   *
   * @param {string} code
   * @memberof PurchaseAbatementService
   */
  public removePurchaseAbatement(code: string): void {
    this.removePurchaseAbatementSource.next(code);
  }

  /**
   * Compute cascade
   *
   * @param {Purchase} purchase
   * @param {PurchaseAbatement[]} purchaseAbatements
   * @param {String} group
   * @memberof PurchaseAbatementService
   */
  public computeCascade(purchase: Purchase, purchaseAbatements: PurchaseAbatement[], group: String): void {
    // let caReference = purchase.tvBudget - purchase.iniFact;
    let caReference = this.getCaReferenceByGroup(purchase, group);

    let caFact = this.getCaReferenceByGroup(purchase, group);
    let rate = 0;
    let amount = 0;

    purchaseAbatements.forEach((purchaseAbatement, index) => {
      if (purchaseAbatement.abatement.category === Abatement.CATEGORY_5) {
        rate = purchaseAbatement.rate;
        amount = this.mathService.getRound(caReference * rate / 100, 2);
        purchaseAbatement.amount = amount;
        purchaseAbatement.base = caReference;
        purchaseAbatement.purchaseAbatementOrder = index;
        caReference = caReference + purchaseAbatement.amount;
      }
    });

    // Variabiliser selon la régie
    purchase.caRef = caReference;

    // Default F
    if (group === 'F') {
      caFact = purchase.caRef;
    } else if (group === 'FREG') {
      caFact = purchase.caRefRegion;
    } else if (group === 'O') {
      caFact = purchase.caRefDomtom;
    } else if (group === 'W') {
      caFact = purchase.caRefThema;
    } else if (group === 'I') {
      caFact = purchase.caRefInter;
    } else if (group === 'F4') {
      caFact = purchase.caRefF4;
    }

    purchaseAbatements.forEach((purchaseAbatement, index) => {
      if (purchaseAbatement.abatement.category === Abatement.CATEGORY_10) {
        rate = purchaseAbatement.rate;
        amount = this.mathService.getRound(caReference * rate / 100, 2);
        purchaseAbatement.amount = amount;
        purchaseAbatement.base = caFact;
        purchaseAbatement.purchaseAbatementOrder = index;
        caFact = caFact + purchaseAbatement.amount;
      }
    });

    if (group === 'F') {
      purchase.caFact = caFact;
    } else if (group === 'FREG') {
      purchase.caFactRegion = caFact;
    } else if (group === 'O') {
      purchase.caFactDomtom = caFact;
    } else if (group === 'W') {
      purchase.caFactThema = caFact;
    } else if (group === 'I') {
      purchase.caFactInter = caFact;
    } else if (group === 'F4') {
      purchase.caFactF4 = caFact;
    }
  }

  /**
   *Compute NCPP
   *
   * @param {Purchase} purchase
   * @param {String} group
   * @memberof PurchaseAbatementService
   */
  public computeNcpp(purchase: Purchase, group: String): void {

    let purchaseAbatements = this.getPurchaseAbatements(group);
    let purchaseAbatementNCPP = null;
    let maxIndexAbatement = 0;
    let indexAbatementNCPP = 0;

    let targetBudget = this.getTargetBudgetByGroup(purchase, group);
    this.computeCascade(purchase, purchaseAbatements, group);
    let caNetBeforeNCPP = this.getCaNetBeforeNCPPByGroup(purchase, group);

    purchaseAbatements.forEach((purchaseAbatement, index) => {
      if (purchaseAbatement.abatement.category === Abatement.CATEGORY_20) {
        maxIndexAbatement = index;

        if (purchaseAbatement.abatement.code !== 'NCPP') {
          caNetBeforeNCPP += purchaseAbatement.amount;
          indexAbatementNCPP = index;
        } else {
          purchaseAbatementNCPP = purchaseAbatement;
        }
      }
    });

    let amount = this.mathService.getRound(this.getBudgetMinusNetFactByGroup(purchase, group) - caNetBeforeNCPP, 2);
    let budgetMinusFact = this.getBudgetMinusNetFactByGroup(purchase, group);

    let rate = 0;
    if ((caNetBeforeNCPP === budgetMinusFact) || (caNetBeforeNCPP === 0)) {
      if (purchaseAbatementNCPP) {
        // remove
        purchaseAbatements.splice(indexAbatementNCPP, 1);
      }
    } else {
      if (caNetBeforeNCPP) {
        rate = this.mathService.getRound(amount / caNetBeforeNCPP * 100, 9);
      }

      if (purchaseAbatementNCPP) {
        if (amount !==  purchaseAbatementNCPP.amount) {
          // update
          purchaseAbatementNCPP.rate = rate;
          purchaseAbatementNCPP.amount = amount;
          purchaseAbatementNCPP.base = caNetBeforeNCPP;
          this.abatementPreRequested = this.abatementPreRequested.filter(item => item !== group);
        }
      } else {
        // add
        let filters = {
            start_time: moment(purchase.startCommunicationPeriod, 'YYYY-MM-DD').startOf('year').format('YYYY-MM-DD'),
            end_time: moment(purchase.startCommunicationPeriod, 'YYYY-MM-DD').endOf('year').format('YYYY-MM-DD'),
            code: 'NCPP'
        };

        let duplicateNcpp = purchaseAbatements.filter(purchaseAbatement => purchaseAbatement.abatement.code === 'NCPP');
        let duplicatePreRequestedAbatement = this.abatementPreRequested.filter(duplicate => duplicate === group);

        if (duplicateNcpp.length === 0 && duplicatePreRequestedAbatement.length === 0) {
          this.abatementService.getList(filters)
            .pipe(
              filter((item) => item.length > 0)
            )
            .subscribe(abatements => {
              let purchaseAbatement = this.createPurchaseAbatement(abatements[0], purchase, group);

              purchaseAbatement.base = caNetBeforeNCPP;
              purchaseAbatement.rate = rate;
              purchaseAbatement.amount = amount;
              purchaseAbatement.purchaseAbatementOrder = maxIndexAbatement + 1;
              purchaseAbatement.purchase = purchase;

              purchaseAbatements.push(purchaseAbatement);
              // Removing pre requested data
              this.abatementPreRequested = this.abatementPreRequested.filter(item => item !== group);
          }, (error) => {
            this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
          });

          this.abatementPreRequested = this.abatementPreRequested.filter(item => item !== group);
          this.abatementPreRequested.push(group);
        }
      }
    };
  }

  /**
   * Reset
   *
   * @memberof PurchaseAbatementService
   */
  public reset(): void {
    this.purchaseAbatements = [];
    this.purchaseAbatementsLoaded = [];
    this.purchaseAbatementsSavePoint = [];
    this.purchaseAbatementsRegion = [];
    this.purchaseAbatementsLoadedRegion = [];
    this.purchaseAbatementsSavePointRegion = [];
    this.purchaseAbatementsDomtom = [];
    this.purchaseAbatementsLoadedDomtom = [];
    this.purchaseAbatementsSavePointDomtom = [];
    this.purchaseAbatementsThematique = [];
    this.purchaseAbatementsLoadedThematique = [];
    this.purchaseAbatementsSavePointThematique = [];
    this.purchaseAbatementsInternational = [];
    this.purchaseAbatementsLoadedInternational = [];
    this.purchaseAbatementsSavePointInternational = [];
    this.purchaseAbatementsF4 = [];
    this.purchaseAbatementsLoadedF4 = [];
    this.purchaseAbatementsSavePointF4 = [];
  }

  public getAllPurchaseAbatements(): Array<any> {
    let allPurchaseAbatements = [];

    allPurchaseAbatements.push(this.purchaseAbatements);
    allPurchaseAbatements.push(this.purchaseAbatementsRegion);
    allPurchaseAbatements.push(this.purchaseAbatementsDomtom);
    allPurchaseAbatements.push(this.purchaseAbatementsThematique);
    allPurchaseAbatements.push(this.purchaseAbatementsInternational);
    allPurchaseAbatements.push(this.purchaseAbatementsF4);

    return allPurchaseAbatements;
  }

  /**
   * Return abatement collection by it's group
   * @param  group  Group string (F, FREG, O, W, I)
   * @return PurchaseAbatement[]
   */
  public getPurchaseAbatements(group: String): PurchaseAbatement[] {
    if (group === 'F') {
      return this.purchaseAbatements;
    } else if (group === 'FREG') {
      return this.purchaseAbatementsRegion;
    } else if (group === 'O') {
      return this.purchaseAbatementsDomtom;
    } else if (group === 'W') {
      return this.purchaseAbatementsThematique;
    } else if (group === 'I') {
      return this.purchaseAbatementsInternational;
    } else if (group === 'F4') {
      return this.purchaseAbatementsF4;
    }

    throw new Error('Invalid group');
  }

  /**
   * Set purchase abatement
   *
   * @param {PurchaseAbatement[]} purchaseAbatements
   * @param {Purchase} purchase
   * @memberof PurchaseAbatementService
   */
  public setPurchaseAbatements(purchaseAbatements: PurchaseAbatement[], purchase: Purchase): void {
    this.purchaseAbatements = [];
    purchaseAbatements.forEach(purchaseAbatement => {
      purchaseAbatement.purchase = purchase;
      this.purchaseAbatements.push(purchaseAbatement);
    });
  }

  /**
   * Return the selected abatements loaded for a group
   * @param group To select the proper collection (F, FREG, O, W, I, F4)
   * @return       A purchaseAbatement collection
   */
  public getPurchaseAbatementsLoaded(group: String): PurchaseAbatement[] { // Ajouter le paramètre de groupe
    let selectedAbatementsLoaded: PurchaseAbatement[] = [];

    if (group === 'F') {
      selectedAbatementsLoaded = this.purchaseAbatementsLoaded;
    } else if (group === 'FREG') {
      selectedAbatementsLoaded = this.purchaseAbatementsLoadedRegion;
    } else if (group === 'O') {
      selectedAbatementsLoaded = this.purchaseAbatementsLoadedDomtom;
    } else if (group === 'W') {
      selectedAbatementsLoaded = this.purchaseAbatementsLoadedThematique
    } else if (group === 'I') {
      selectedAbatementsLoaded = this.purchaseAbatementsLoadedInternational;
    } else if (group === 'F4') {
      selectedAbatementsLoaded = this.purchaseAbatementsLoadedF4;
    }

    return selectedAbatementsLoaded;
  }

  /**
   * Set the selected abatements loaded for a group
   * @param  purchaseAbatements The purchaseAbatement collection to save
   * @param group To set the proper collection (F, FREG, O, W, I, F4)
   * @return                    Self
   */
  public setPurchaseAbatementsLoaded(purchaseAbatements: PurchaseAbatement[], group: String): PurchaseAbatementService {
    if (group === 'F') {
      this.purchaseAbatementsLoaded = this.clone(purchaseAbatements);
    } else if (group === 'FREG') {
      this.purchaseAbatementsLoadedRegion = this.clone(purchaseAbatements);
    } else if (group === 'O') {
      this.purchaseAbatementsLoadedDomtom = this.clone(purchaseAbatements);
    } else if (group === 'W') {
      this.purchaseAbatementsLoadedThematique = this.clone(purchaseAbatements);
    } else if (group === 'I') {
      this.purchaseAbatementsLoadedInternational = this.clone(purchaseAbatements);
    } else if (group === 'F4') {
      this.purchaseAbatementsLoadedF4 = this.clone(purchaseAbatements);
    }

    return this;
  }

  /**
   * Get the selected abatements save point for a group
   * @param  group              To set the proper collection (F, FREG, O, W, I)
   * @return                    Self
   */
  public getPurchaseAbatementsSavePoint(group: String): PurchaseAbatement[] {
    let selectedAbatementsSavepoint: PurchaseAbatement[] = [];

    if (group === 'F') {
      selectedAbatementsSavepoint = this.purchaseAbatementsSavePoint
    } else if (group === 'FREG') {
      selectedAbatementsSavepoint = this.purchaseAbatementsSavePointRegion;
    } else if (group === 'O') {
      selectedAbatementsSavepoint = this.purchaseAbatementsSavePointDomtom;
    } else if (group === 'W') {
      selectedAbatementsSavepoint = this.purchaseAbatementsSavePointThematique
    } else if (group === 'I') {
      selectedAbatementsSavepoint = this.purchaseAbatementsSavePointInternational;
    } else if (group === 'F4') {
      selectedAbatementsSavepoint = this.purchaseAbatementsSavePointF4;
    }

    return selectedAbatementsSavepoint;
  }

  /**
   * Set purchase abatement save point
   *
   * @param {PurchaseAbatement[]} purchaseAbatements
   * @memberof PurchaseAbatementService
   */
  public setPurchaseAbatementsSavePoint(purchaseAbatements: PurchaseAbatement[]): void { // Ajouter le paramètre de groupe
    this.purchaseAbatementsSavePoint =  this.clone(purchaseAbatements);
  }

  /**
   * Restore previous state of a purchaseAbatement collection
   * @param  group To get the proper collection (F, FREG, O, W, I)
   * @return       Self
   */
  public restorePurchaseAbatementsToSavePoint(group: String): PurchaseAbatementService {
    if (group === 'F') {
      this.purchaseAbatements = this.clone(this.purchaseAbatementsSavePoint);
    } else if (group === 'FREG') {
      this.purchaseAbatementsRegion = this.clone(this.purchaseAbatementsSavePointRegion);
    } else if (group === 'O') {
      this.purchaseAbatementsDomtom = this.clone(this.purchaseAbatementsSavePointDomtom);
    } else if (group === 'W') {
      this.purchaseAbatementsThematique = this.clone(this.purchaseAbatementsSavePointThematique);
    } else if (group === 'I') {
      this.purchaseAbatementsInternational = this.clone(this.purchaseAbatementsSavePointInternational);
    } else if (group === 'F4') {
      this.purchaseAbatementsF4 = this.clone(this.purchaseAbatementsSavePointF4);
    }

    return this;
  }

  /**
   * Extract (build new object)
   *
   * @param {PurchaseAbatement} purchaseAbatement
   * @param {boolean} [withId=false]
   * @returns {Object}
   * @memberof PurchaseAbatementService
   */
  public extract(purchaseAbatement: PurchaseAbatement, withId: boolean = false): Object {
    return {
      id: withId ? purchaseAbatement.id : null,
      purchase: purchaseAbatement.purchase.id,
      abatement: purchaseAbatement.abatement.id,
      rate: purchaseAbatement.rate || 0,
      amount: purchaseAbatement.amount || 0,
      base: purchaseAbatement.base,
      purchase_abatement_order: purchaseAbatement.purchaseAbatementOrder,
      channel_group: purchaseAbatement.channelGroup
    }
  }

  /**
   * Extract collection
   *
   * @param {*} array
   * @param {boolean} [withId=false]
   * @returns {Array<any>}
   * @memberof PurchaseAbatementService
   */
  public extractCollection(array, withId: boolean = false): Array<any> {
    let result = [];
    array.forEach(item => {
      result.push(this.extract(item, withId));
    });

    return result;
  }

  /**
   * Get CA reference by group
   *
   * @protected
   * @param {Purchase} purchase
   * @param {String} group
   * @returns {number}
   * @memberof PurchaseAbatementService
   */
  protected getCaReferenceByGroup(purchase: Purchase, group: String): number {
    if (group === 'F') {
      return purchase.tvClassicBudget - purchase.iniFact;
    } else if (group === 'FREG') {
      return purchase.tvRegionBudget - purchase.iniFactRegion;
    } else if (group === 'O') {
      return purchase.tvDomtomBudget - purchase.iniFactDomtom;
    } else if (group === 'W') {
      return purchase.tvThemaBudget - purchase.iniFactThema;
    } else if (group === 'I') {
      return purchase.tvInterBudget - purchase.iniFactInter;
    } else if (group === 'F4') {
      return purchase.tvF4Budget - purchase.iniFactF4;
    }

    throw new Error('Invalid channel group');
  }

  /**
   * Get target budget by group
   *
   * @protected
   * @param {Purchase} purchase
   * @param {String} group
   * @returns {number}
   * @memberof PurchaseAbatementService
   */
  protected getTargetBudgetByGroup(purchase: Purchase, group: String): number {
    if (group === 'F') {
      return purchase.tvClassicBudgetNet - purchase.netFact;
    } else if (group === 'FREG') {
      return purchase.tvRegionBudgetNet - purchase.netFactRegion;
    } else if (group === 'O') {
      return purchase.tvDomtomBudgetNet - purchase.netFactDomtom;
    } else if (group === 'W') {
      return purchase.tvThemaBudgetNet - purchase.netFactThema;
    } else if (group === 'I') {
      return purchase.tvInterBudgetNet - purchase.netFactInter;
    } else if (group === 'F4') {
      return purchase.tvF4BudgetNet - purchase.netFactF4;
    }

    throw new Error('Invalid channel group');
  }

  /**
   * Get ca net before NCPP by group
   *
   * @protected
   * @param {Purchase} purchase
   * @param {String} group
   * @returns {number}
   * @memberof PurchaseAbatementService
   */
  protected getCaNetBeforeNCPPByGroup(purchase: Purchase, group: String): number {
    if (group === 'F') {
      return purchase.caFact;
    } else if (group === 'FREG') {
      return purchase.caFactRegion;
    } else if (group === 'O') {
      return purchase.caFactDomtom;
    } else if (group === 'W') {
      return purchase.caFactThema;
    } else if (group === 'I') {
      return purchase.caFactInter;
    } else if (group === 'F4') {
      return purchase.caFactF4;
    }

    throw new Error('Invalid channel group');
  }

  /**
   * Get budget minus net fac by group
   *
   * @protected
   * @param {Purchase} purchase
   * @param {String} group
   * @returns {number}
   * @memberof PurchaseAbatementService
   */
  protected getBudgetMinusNetFactByGroup(purchase: Purchase, group: String): number {
    if (group === 'F') {
      return purchase.tvClassicBudgetNet - purchase.netFact;
    } else if (group === 'FREG') {
      return purchase.tvRegionBudgetNet - purchase.netFactRegion;
    } else if (group === 'O') {
      return purchase.tvDomtomBudgetNet - purchase.netFactDomtom;
    } else if (group === 'W') {
      return purchase.tvThemaBudgetNet - purchase.netFactThema;
    } else if (group === 'I') {
      return purchase.tvInterBudgetNet - purchase.netFactInter;
    } else if (group === 'F4') {
      return purchase.tvF4BudgetNet - purchase.netFactF4;
    }

    throw new Error('Invalid channel group');
  }

  /**
   * Get purchase abatement id by abatement
   *
   * @private
   * @param {Abatement} abatement
   * @param {String} group
   * @returns {(string | number)}
   * @memberof PurchaseAbatementService
   */
  private getPurchaseAbatementIdByAbatement(abatement: Abatement, group: String): number {
    let purchaseAbatements = this.getPurchaseAbatements(group);
    let result = purchaseAbatements.filter(item => item.abatement.id === abatement.id);

    if (result.length) {
      return result[0].id;
    }
    return null;
  }
}
