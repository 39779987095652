import { DirectiveModule } from '../../directive/directive.module';
import { PipeModule } from '../../pipe/pipe.module';
import { SharedModule } from '../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AvailabilityComponent } from './availability/availability.component';
import { ProgramCardComponent } from './program-card/program-card.component';
import { GridComponent } from './grid/grid.component';
import { LegendComponent } from './legend/legend.component';
import { GridHeaderComponent} from './grid-header/grid-header.component';
import { ProgramFilterComponent} from './program-filter/program-filter.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OfferProgramGridComponent } from './offer-program-grid/offer-program-grid.component';
import { PropalCartComponent } from './propal-cart/propal-cart.component';
import { OfferProgramPropalComponent } from './propal-cart/offer-program-propal/offer-program-propal.component';
import { PropalMainInformationComponent } from './propal-cart/propal-main-information/propal-main-information.component';
import { RouterModule } from '@angular/router';

import {
  MatIconModule,
  MatToolbarModule,
  MatExpansionModule,
  MatCardModule,
  MatButtonModule,
  MatSlideToggleModule,
  MatProgressSpinnerModule,
  MatDialogModule,
  MatSelectModule,
  MatProgressBarModule,
  MatRadioModule,
  MatFormFieldModule,
  MatCheckboxModule,
  MatListModule,
  MatTooltipModule,
  MatAutocompleteModule,
  MatInputModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatTabsModule,
  MatChipsModule,
  MatDividerModule,
  MatButtonToggleModule,
  MatGridListModule
} from '@angular/material';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { GridOfferGroupComponent } from './grid-offer-group/grid-offer-group.component';
import { GridGroupComponent } from './grid-offer-group/grid-group/grid-group.component';
import { AudienceIndicatorsCartComponent } from './audience-indicators-cart/audience-indicators-cart.component';
import { GridInformationAvailableComponent } from './grid-information-available/grid-information-available.component';
import { GridWarningComponent } from './grid-warning/grid-warning.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SynthesisComponent } from './availability/synthesis/synthesis.component';
import { TableSynthesisComponent } from './availability/synthesis/table-synthesis/table-synthesis.component';
import { FiltersSynthesisComponent } from './availability/synthesis/filters-synthesis/filters-synthesis.component';
import { SoreachDispoComponent } from './availability/soreach-dispo/soreach-dispo.component';
import { SoreachDispoFiltersComponent } from './availability/soreach-dispo/soreach-dispo-filters/soreach-dispo-filters.component';
import { SoreachDispoGridComponent } from './availability/soreach-dispo/soreach-dispo-grid/soreach-dispo-grid.component';
import { SoreachCardComponent } from './soreach-card/soreach-card.component';
import { RegionDispoComponent } from './availability/region-dispo/region-dispo.component';
import { TableRegionComponent } from './availability/region-dispo/table-region/table-region.component';
import { FilterRegionComponent } from './availability/region-dispo/filter-region/filter-region.component';
import { AreaProgramPropalComponent } from './propal-cart/area-program-propal/area-program-propal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PipeModule,
    ReactiveFormsModule,
    DirectiveModule,
    RouterModule,
    BrowserAnimationsModule,
    // Material
    MatAutocompleteModule,
    MatIconModule,
    MatToolbarModule,
    MatExpansionModule,
    MatCardModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatSelectModule,
    MatProgressBarModule,
    MatRadioModule,
    MatFormFieldModule,
    MatDividerModule,
    MatCheckboxModule,
    MatListModule,
    MatTooltipModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTabsModule,
    MatGridListModule,
    SharedModule,
    MatChipsModule,
    FlexLayoutModule,
    MatButtonToggleModule,
    ScrollingModule
  ],
  declarations: [
    // Component
    ProgramCardComponent,
    GridComponent,
    GridHeaderComponent,
    ProgramFilterComponent,
    AvailabilityComponent,
    LegendComponent,
    OfferProgramGridComponent,
    PropalCartComponent,
    OfferProgramPropalComponent,
    PropalMainInformationComponent,
    GridOfferGroupComponent,
    GridGroupComponent,
    AudienceIndicatorsCartComponent,
    GridInformationAvailableComponent,
    GridWarningComponent,
    SynthesisComponent,
    TableSynthesisComponent,
    FiltersSynthesisComponent,
    SoreachDispoComponent,
    SoreachDispoFiltersComponent,
    SoreachDispoGridComponent,
    SoreachCardComponent,
    RegionDispoComponent,
    TableRegionComponent,
    FilterRegionComponent,
    AreaProgramPropalComponent
  ],
  providers: [
  ],
  exports: [
    ProgramCardComponent,
    GridComponent,
    GridHeaderComponent,
    ProgramFilterComponent,
    AvailabilityComponent,
    LegendComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AvailabilityModule { }
