<button class="close-dialog" (click)="closeSoreachDialog()">
  <mat-icon>close</mat-icon>
</button>
<div class="full" fxLayout="column" fxLayoutAlign="center center">
  <h1 class ="title">Paramétrage SO REACH</h1>
  <div class="full" fxFlex fxLayout="row" fxLayoutGap="15px">
    <div class="list-soreach" fxFlex fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="25px">
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
        <button (click)="decreaseYear(false)" mat-icon-button>
          <mat-icon class="chevron">chevron_left</mat-icon>
        </button>
        <span>{{ year }}</span>
        <button (click)="increaseYear(false)" mat-icon-button>
          <mat-icon class="chevron">chevron_right</mat-icon>
        </button>
      </div>
      <div fxFlex class="full-width overflow-list" fxLayout="column" fxLayoutAlign="start start">
        <div (click)="selectedSoReach(index)" *ngFor="let soReach of soReaches; index as index; last as last"
             [ngClass]="{'selected': selectedSoreachIndex === index, 'list-element': !last}"
             class="full-width padding-list">
          <span class="padding-span">{{ soReach.name }}</span>
        </div>
      </div>
      <div class="full-width footer-lister" fxLayout="row">
        <div fxFlex="50" fxLayoutAlign="center center">
          <app-spinner *ngIf="isLoading" [size]="'small'" [type]="'warning'"></app-spinner>
          <div *ngIf="!isLoading" (click)="addSoReach()" class="pointer-cursor" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="5px">
            <mat-icon>add_circle</mat-icon>
            <span>Ajouter</span>
          </div>
        </div>
        <div fxFlex="50" fxLayoutAlign="center center">
          <app-spinner *ngIf="isLoading" [size]="'small'" [type]="'warning'"></app-spinner>
          <div *ngIf="!isLoading" (click)="deleteSoReach()" class="pointer-cursor" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="5px">
            <mat-icon>remove_circle</mat-icon>
            <span>Supprimer</span>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="75" fxLayout="column" fxLayoutAlign="center center">
      <form *ngIf="(this.soReachForm?.value?.id && this.soReachForm?.value?.periodsBudgets?.length) || isCreating" [formGroup]="soReachForm" class="full-width-input"
            fxLayout="column" fxLayoutAlign="center center">
        <div fxFlex="90" fxLayout="column" class="full-width">
          <div fxLayout="row" fxLayoutAlign="start center" class="full-width-input" fxLayoutGap="5px">
            <span class="span-width thin" fxFlex="75px">Période :</span>
            <div fxFlex fxLayoutAlign="center center">
              <button (click)="decreaseYear(true)" mat-icon-button>
                <mat-icon class="chevron">chevron_left</mat-icon>
              </button>
              <span class="switch-year hold-color">{{ soReachForm.get('year').value }}</span>
              <button (click)="increaseYear(true)" mat-icon-button>
                <mat-icon class="chevron">chevron_right</mat-icon>
              </button>
            </div>
          </div>
          <div fxLayout="row" fxLayoutGap="25px" class="full-width-input" fxLayoutAlign="start center">
            <span class="span-width thin" fxFlex="75px">Type :</span>
            <mat-form-field class="full-width">
              <input #name formControlName="name" matInput maxlength="50" type="text">
              <mat-hint align="end">{{name.value.length}} / 50</mat-hint>
            </mat-form-field>
          </div>
          <div fxLayout="row" class="full-width" fxLayoutGap="15px">
            <div fxLayout="column" fxFlex="30" fxLayoutAlign="start" fxLayoutGap="10px" class="overflow-period-list">
              <div *ngIf="this.soReachForm?.value && this.soReachForm?.value?.periodsBudgets?.length" class="full-width">
                <div *ngFor="let period of soReachForm.get('periodsBudgets')['controls']; index as i"
                [ngClass]="{'selected': selectedPeriodIndex === i}" class="period-selected pointer-cursor" (click)="selectedPeriodIndex = i" fxLayoutGap="5px">
                  <div>
                    <span> du </span>
                    <span class="period-date">{{period.get('startDate').value | date:'dd/MM/yy'}}</span>
                    <span> au </span>
                    <span class="period-date">{{period.get('endDate').value | date:'dd/MM/yy'}}</span>
                    <button mat-icon-button (click)="removePeriod(i)">
                      <mat-icon class="close">close</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="full-width">
                <mat-icon (click)="addPeriod()" class="red-period cursor">add_circle_outline</mat-icon>
                <span (click)="addPeriod()" class="underline-text cursor">Ajouter une période</span>
              </div>
            </div>
            <mat-divider [vertical]="true"></mat-divider>
            <div *ngIf="this.soReachForm?.value?.id || isCreating" fxLayout="column" fxFlex="70" fxLayoutAlign="start center" class="full-width">
              <app-so-reach-period-budget #SoReachPeriodBudgetComponent [soReachForm]="soReachForm" [selectedPeriodIndex]="selectedPeriodIndex"></app-so-reach-period-budget>
            </div>
          </div>
        </div>

          <div fxLayout="row" fxLayoutAlign="start center" class="full-width">
              <button (click)="saveSoReach()" [disabled]="isDeleting || !soReachForm.valid || isSaving" fxLayoutAlign="center center" mat-flat-button class="red button-flat">
                <span *ngIf="!isSaving" class="white-text">
                  Sauvegarder
                </span>
                <app-spinner *ngIf="isSaving" [size]="'small'" [type]="'warning'"></app-spinner>
              </button>
          </div>
      </form>
    </div>
  </div>
</div>
