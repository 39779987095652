
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-purchase-info-dialog',
  templateUrl: './purchase-info-dialog.component.html',
  styleUrls: ['./purchase-info-dialog.component.scss']
})
export class PurchaseInfoDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PurchaseInfoDialogComponent>
  ) {}

  closeDialog() {
    this.dialogRef.close(false);
  }
}
