import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundTo'
})
export class RoundToPipe implements PipeTransform {

  transform(value: any, decimal: any): any {
    let dec = Math.pow(10,decimal);
    return Math.round(value * dec) / dec;
  }

}