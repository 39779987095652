import { Injectable } from '@angular/core';

@Injectable()
export class ArrayService {

  constructor() { }

  /**
   * Remove duplicate
   *
   * @param {Array<any>} array
   * @returns {Array<any>}
   * @memberof ArrayService
   */
  removeDuplicate(array: Array<any>): Array<any> {
    return Array.from(new Set(array));
  }

  /**
   * Array Object to Hash Map
   *
   * @param {Array<any>} arrayObject
   * @returns
   * @memberof ArrayService
   */
  arrayObjectToHashMap(arrayObject: Array<any>): Array<any> {
    let array: Array<any> = [];

    arrayObject.forEach(element => {
      array[element.id] = element.name;
    });

    return array;
  }

  /**
   * Array object to full hash map
   *
   * @param {Array<any>} arrayObject
   * @returns {Array<any>}
   * @memberof ArrayService
   */
  arrayObjectToFullHashMap(arrayObject: Array<any>): Array<any> {
      let array: Array<any> = [];

      arrayObject.forEach(element => {
        array[element.id] = element;
      });

      return array;
  }
}
