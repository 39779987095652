<span class="title-sub-table">
    Emission
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
</span>

<div *ngIf="!purchase.id" class="danger">
  <mat-icon>warning</mat-icon>
  Merci de renseigner et d'enregistrer la première partie de votre achat avant de continuer
</div>

<mat-progress-bar *ngIf="loadingProgram" mode="indeterminate"></mat-progress-bar>

<form novalidate *ngIf="!loadingProgram"
      [formGroup]="programGroup"
      fxLayout="column"
      class="form-program">
  <div>
    <!-- Chaine -->
    <mat-form-field class="form-control-medium">
      <input matInput placeholder="Chaine *"
             appRemoveAccents
             [matAutocomplete]="channelAutocomplete"
             formControlName="channel">
      <mat-hint class="hint-error" *ngIf="isValid">
        Champ obligatoire *
      </mat-hint>
    </mat-form-field>

    <mat-autocomplete #channelAutocomplete="matAutocomplete"
                      [displayWith]="autocompleteService.displayAutocomplete.bind(this)">
      <mat-option *ngFor="let channel of filteredChannelItem | async"
                  [value]="channel">
        {{ channel.display }}
      </mat-option>
    </mat-autocomplete>

    <!-- Area -->
    <mat-form-field class="form-control-medium">
      <input matInput placeholder="Région"
             appRemoveAccents
             [matAutocomplete]="areaAutocomplete"
             formControlName="area">
    </mat-form-field>

    <mat-autocomplete #areaAutocomplete="matAutocomplete"
                      [displayWith]="autocompleteService.displayAutocomplete.bind(this)">
      <mat-option *ngFor="let area of filteredAreaItem | async"
                  [value]="area">
        {{ area.display }}
      </mat-option>
    </mat-autocomplete>

  </div>

  <div fxLayout="row" fxLayoutGap="10px">
    <!-- Category -->
    <div fxFlex="48">
      <mat-form-field class="full-width">
        <input matInput placeholder="Unité de programme"
               appRemoveAccents
               [matAutocomplete]="categoryAutocomplete"
               formControlName="category">
      </mat-form-field>

      <mat-autocomplete #categoryAutocomplete="matAutocomplete"
                        [displayWith]="autocompleteService.displayAutocomplete.bind(this)">
        <mat-option *ngFor="let category of filteredCategoryItem | async"
                    [value]="category">
          {{ category.display }}
        </mat-option>
      </mat-autocomplete>
    </div>

    <!-- Program -->
    <div fxFlex="48">
      <mat-form-field class="full-width">
        <input matInput placeholder="Emission *"
               appRemoveAccents
               [matAutocomplete]="programAutocomplete"
               formControlName="program"
        >
        <mat-hint class="hint-error" *ngIf="isValid">
          Champ obligatoire *
        </mat-hint>
      </mat-form-field>

      <mat-autocomplete #programAutocomplete="matAutocomplete"
                        (optionSelected)="selectNewProgram()"
                        [displayWith]="autocompleteService.displayAutocomplete.bind(this)">
        <mat-option *ngFor="let program of filteredProgramItem | async"
                    [value]="program">
          {{ program.display }}
        </mat-option>
      </mat-autocomplete>
      <mat-hint class="warning"
                *ngIf="hintNotAlterable"
                align="start">
        Attention : cette partie de l'achat n'est pas modifiable car le dossier a un statut "acheté".
      </mat-hint>
    </div>
  </div>

  <div fxLayout="row" fxLayoutGap="10px">
    <!-- Offer -->
    <div style="width: 48%">
      <mat-form-field class="full-width custom-mat">
        <input matInput placeholder="Offre"
              appRemoveAccents
              [matAutocomplete]="offerAutocomplete"
              [disabled]="true"
              formControlName="offer">

        <mat-hint class="warning"
                  *ngIf="hintOptionable"
                  align="start">
          Attention : cette offre n'est pas optionnable
        </mat-hint>
      </mat-form-field>

      <mat-autocomplete #offerAutocomplete="matAutocomplete"
                        [displayWith]="autocompleteService.displayAutocomplete.bind(this)">
        <mat-option *ngFor="let offerProgram of filteredOfferItem | async"
                    [matTooltip]="getPeriodFromOfferProgram(offerProgram)"
                    matTooltipPosition="after"
                    matTooltipShowDelay="0"
                    matTooltipClass="select-tooltip"
                    [value]="offerProgram">
          {{ offerProgram.display }}
        </mat-option>
      </mat-autocomplete>
    </div>

    <!-- Evenements-->
    <div style="width: 48%">
      <mat-form-field class="full-width" [ngClass]="{'custom-mat': this.offerProgramEvt.length < 1}">
        <mat-chip-list #chipListEvent>
          <mat-chip
            *ngFor="let event of this.offerProgramEvt">
            {{event.libevt.toUpperCase()}}
            </mat-chip>
          <input
              appRemoveAccents
              placeholder="Evenements"
              formControlName="evt"
              [matChipInputFor]="chipListEvent">
        </mat-chip-list>
    </mat-form-field>
    </div>
  </div>
  <div class="full-width center" fxFlexAlign="center center">
    <button mat-flat-button
            (click)="savePurchaseProgram()"
            [disabled]="!(programGroup.valid && !savingPurchaseProgram && !isOptionableValid && !!purchase.id)"
            [ngClass]="{'disabled': !(programGroup.valid && !savingPurchaseProgram && !isOptionableValid && !!purchase.id)}"
            *ngIf="purchaseProgram  && purchaseProgram.id < 0"
            class="button-flat black">
      VALIDER
    </button>
  </div>

</form>
