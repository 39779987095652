
import {map,  catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { PurchaseType } from '../../resource/purchaseType.resource';
import { FilteredItem } from '../../resource/filteredItem.resource';
import { environment } from '../../../environments/environment';
import { UtilitiesHandler } from '@service/utilities-handlers/utilitiesHandlers';

import { Observable } from 'rxjs';

@Injectable()
export class PurchaseTypeService {
  private route: string = 'purchase_type';

  constructor(
    private http: HttpClient,
    private utilitiesHandler: UtilitiesHandler
  ) { }

  /**
   * Get purchase Type
   *
   * @param {(string | number)} id
   * @returns {Observable<PurchaseType>}
   * @memberof PurchaseTypeService
   */
  get(id: string | number): Observable<PurchaseType> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}/${id}`;

    return this.http
      .get(api_base_url).pipe(
      map((data: any) =>
        new PurchaseType(data)
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get list of purchase Type
   *
   * @returns {Observable<PurchaseType[]>}
   * @memberof PurchaseTypeService
   */
  getList(): Observable<PurchaseType[]> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;

    return this.http
      .get(api_base_url).pipe(
      map((data: any) =>
        data._embedded.purchase_type
          .map((jsonPurchaseType: any) =>
            new PurchaseType(jsonPurchaseType)
          )
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get purchases form types
   *
   * @param {*} purchaseTypes
   * @returns {PurchaseType[]}
   * @memberof PurchaseTypeService
   */
  public getPurchasesFormTypes(purchaseTypes): PurchaseType[] {
    let result = [];

    PurchaseType.PURCHASE_FORM_TYPES.forEach(id => {
      let type = purchaseTypes.filter(item => item.id === id)
      result.push(type.shift());
    })

    return result;
  }

  /**
   * Get filtered items
   *
   * @param {PurchaseType[]} purchaseTypes
   * @returns {FilteredItem[]}
   * @memberof PurchaseTypeService
   */
  public getFilteredItems(purchaseTypes: PurchaseType[]): FilteredItem[] {
    return purchaseTypes.map(purchaseType =>
      new FilteredItem({
        id: purchaseType.id,
        name: purchaseType.name,
        entity: purchaseType
      })
    );
  }

  /**
   * Get list of purchase type for filter
   *
   * @param {Object} [filters=null]
   * @returns {Observable<FilteredItem[]>}
   * @memberof PurchaseTypeService
   */
  getListForFilter(filters: Object = null): Observable<FilteredItem[]> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params}).pipe(
      map((data: any) =>
        data._embedded.purchase_type
          .map((jsonPurchaseType: any) =>
            new PurchaseType(jsonPurchaseType)
          )
          .map((purchaseType: PurchaseType) =>
            new FilteredItem({
                id: purchaseType.id,
                name: purchaseType.name,
                entity: purchaseType
            })
          )
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }
}
