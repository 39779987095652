import { map, takeUntil } from 'rxjs/operators';
import { PurchaseBudgetService } from '../../../../service/purchase-budget/purchase-budget.service';
import { PurchaseService } from '../../../../service/purchase/purchase.service';
import { Purchase } from '../../../../resource/purchase.resource';
import { Component, Input, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';

import { PurchaseProgram } from '../../../../resource/purchaseProgram.resource';
import { ApiError } from '../../../../resource/api.error.resource';

import { CustomToastrService } from '../../../../service/toastr/custom-toastr.service';
import { PurchaseProgramService } from '../../../../service/purchase-program/purchase-program.service';

@Component({
  selector: 'app-purchase-offer-program',
  templateUrl: './purchase-offer-program.component.html',
  styleUrls: ['./purchase-offer-program.component.scss'],
})
export class PurchaseOfferProgramComponent implements OnInit, OnDestroy {
  @ViewChild('target') myTarget: ElementRef;
  @Input() purchase: Purchase;

  public purchasePrograms$: Observable<PurchaseProgram[]>;
  public purchaseProgramList: PurchaseProgram[];
  public purchaseProgramSelected: PurchaseProgram;
  public loading: boolean = false;

  private idNewProgram: number = -1;
  private purchaseProgramsList: any;
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private purchaseProgramService: PurchaseProgramService,
    private purchaseService: PurchaseService,
    private purchaseBudgetService: PurchaseBudgetService,
    private customToastrService: CustomToastrService
  ) { }

  ngOnInit() {
    if (this.purchase.id) {
      this.loadPurchasedPrograms();
    } else {
      this.addNewPurchaseProgram();
    }

    this.computeDigitalBudgetFromPurchasePrograms();
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.unsubscribe();
  }

  refreshBrutEmitter($event: boolean): void {
    this.getBrutList();
  }

  public onChangePurchaseProgramFromChild($event: PurchaseProgram) {
    this.purchaseProgramList = this.purchaseProgramList.filter((item) => {
      return item.id >= 0;
    });

    this.purchaseProgramList.push($event);
    this.purchasePrograms$.pipe(map(purchasePrograms =>
      purchasePrograms.map(
        purchaseProgram => {
            if (purchaseProgram['id'] === this.purchaseProgramSelected.id) {
                this.purchaseProgramSelected = $event;
                return  $event;
            }

            return purchaseProgram;
        })
    ))
    .subscribe(purchasePrograms => {
        this.purchasePrograms$ = of(purchasePrograms);
    });
  }

  public selectPurchaseProgram($event: PurchaseProgram): void {
    this.purchaseProgramSelected = $event;
  }

  public deletePurchaseProgram($event: boolean): void {
    if (this.purchase.id) {
      this.loadPurchasedPrograms();
    } else {
      this.addNewPurchaseProgram();
    }
  }

  /**
   * Scroll to EMISSIONS ET DISPOSITIF
   */
  public scrollInto() {
    this.myTarget.nativeElement.scrollIntoView({block: 'start', behavior: 'smooth'});
  }

  public addNewPurchaseProgram() {
    if (this.purchaseProgramList && !this.purchaseProgramList.find((item) => item.id < 0)) {
      this.scrollInto();
      let newPurchaseProgram = new PurchaseProgram({
        id: --this.idNewProgram,
        offer_program: null,
        purchase: this.purchase || null,
        program: null,
        area: null,
        channel: null,
        broadcast_count: 0,
        schedules: []
    });

    this.purchaseProgramList.push(newPurchaseProgram);
    if (!this.purchasePrograms$) {
      this.purchasePrograms$ = of([newPurchaseProgram]);
    } else {
      this.purchasePrograms$ = this.purchasePrograms$.pipe(map(data => [...data, newPurchaseProgram]));
    }
    this.selectPurchaseProgram(newPurchaseProgram);
    }
  }

  private getBrutList(): void {
    this.purchaseProgramService
        .getPurchaseProgramSum(this.purchase.id)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((data) => {
          let findId;
          this.purchaseProgramList.forEach((item) => {
            if (item && item.offerProgram && item.offerProgram.id) {
              findId = data.find((result) => result.OFFER_PROGRAM_ID === item.offerProgram.id.toString())
              if (findId) {
                return item['brutPrice'] = Number(findId.BRUT_PRICE).toFixed(2);
              } else {
                return;
              }
            }
          });
          this.purchaseService.savePurchaseForCompare('brutList');
        }, (error) => {
          console.error('error: ', error);
          this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
        });
  }

  private loadPurchasedPrograms(): void {
    this.loading = true;
    this.purchasePrograms$ = this.purchaseProgramService
      .getList({purchase_id: this.purchase.id, groups: 'purchaseProgramLoad'});

    this.purchasePrograms$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((result: PurchaseProgram[]) => {
        this.loading = false;
        this.purchaseProgramSelected = result[0];
        if (result && result.length === 0) {
          this.addNewPurchaseProgram();
        } else {
          this.purchaseService.checkIfPurchaseIsOptionableByPurchaseProgramsCollection(result);
        }

        // save purchase program list
        this.purchaseProgramList = result;
        this.getBrutList();
      });
  }

  private computeDigitalBudgetFromPurchasePrograms(): void {
    this.purchaseService.purchaseProgramAdded$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(purchaseProgramAdded => {
        this.purchaseProgramService.getList({purchase_id: this.purchase.id, groups: 'purchaseProgramComputeDigital'})
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(result => {
            let digitalBudgets = {
              digitalDisplayBudget : 0,
              digitalReplayBudget : 0,
              digVidBillBudget : 0,
              digVidPreBudget : 0,
              digitalSocialBudget : 0,
              socialFbBudget : 0,
              socialTwitBudget : 0,
              socialInstaBudget: 0,
              digitalOtherBudget : 0
            };
            this.purchaseProgramsList = result;

            let offerIdAdded = purchaseProgramAdded['offerProgram']['offer']['id'];
            let offerCount = 0;

            for (let i = 0 ; i < this.purchaseProgramsList.length ; i++) {
              if (offerIdAdded === this.purchaseProgramsList[i]['offerProgram']['offer']['id']) {
                offerCount++;
              }
            }

            if (offerCount === 1) {
              digitalBudgets.digitalDisplayBudget = purchaseProgramAdded['offerProgram']['offer'].digitalDisplayBudget;
              digitalBudgets.digitalReplayBudget = purchaseProgramAdded['offerProgram']['offer'].digitalReplayBudget;
              digitalBudgets.digVidBillBudget = purchaseProgramAdded['offerProgram']['offer'].digVidBillBudget;
              digitalBudgets.digVidPreBudget = purchaseProgramAdded['offerProgram']['offer'].digVidPreBudget;
              digitalBudgets.digitalSocialBudget = purchaseProgramAdded['offerProgram']['offer'].digitalSocialBudget;
              digitalBudgets.socialFbBudget = purchaseProgramAdded['offerProgram']['offer'].socialFbBudget;
              digitalBudgets.socialTwitBudget = purchaseProgramAdded['offerProgram']['offer'].socialTwitBudget;
              digitalBudgets.socialInstaBudget = purchaseProgramAdded['offerProgram']['offer'].socialInstaBudget;
              digitalBudgets.digitalOtherBudget = purchaseProgramAdded['offerProgram']['offer'].digitalOtherBudget;
              this.purchaseService.addDigitalBudgets(digitalBudgets);
              this.purchaseBudgetService.publishPurchaseBudgetLoadFromResource(true);
              this.purchaseService.editPurchase(this.purchase)
                .pipe(takeUntil(this.componentDestroyed$))
                .subscribe((purchase: Purchase) => {
                    this.purchase = purchase;
                    this.customToastrService.displayToastr('SUCCESS', 'Dossier édité avec succès');
                }, error => {
                  if (error && error.detail) {
                    let apiError = new ApiError(error.detail);
                    this.customToastrService.displayToastr('ERROR', apiError.message + '\nEnregistrement échoué');
                  } else {
                    this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue');
                  }
                });
            }
          });
      });
  }
}
