import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-grid-header',
  templateUrl: './grid-header.component.html',
  styleUrls: ['./grid-header.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  providers: []
})
export class GridHeaderComponent implements OnInit {

  @Input() header;
  @Input() headerMonth;
  cellWidth: number = 32;

  ngOnInit() {
  }
}
