import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-grid-information-available',
  templateUrl: './grid-information-available.component.html',
  styleUrls: ['./grid-information-available.component.scss']
})
export class GridInformationAvailableComponent implements OnInit {
  @Output() closeToltip: EventEmitter<void> = new EventEmitter<void>();
  @Input() informationOffer;

  constructor() {}

  ngOnInit() { }

  closeInformation(): void {
    this.closeToltip.emit();
  }

  pathImage(image: string): string {
    return `assets/images/logos/channels/${image}`;
  }
}
