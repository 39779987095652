import { Base } from './base.resource';

export interface JsonEmployee {
    id: string;
    name: string;
}

export class Employee extends Base {
    static commercialCell = [
        'O1', 'O2', 'O3', 'O51', 'O52', 'O53', 'O7', 'O81', 'O', 'O82', 'O8', 'O5',
        '6', '61', '62', '63', '64', '65', '66', '67', '68',
        '5',
        'S', 'S1', 'S2',
        'C', 'C1', 'C11', 'C12', 'C13', 'C14', 'C2', 'C21', 'C22', 'C31',
        'I1',
        'M', 'M1',
        'PL_2'
    ];

    static commercialType = [12, 19, 40, 41, 44, 47];

    static planningType = [
        4,  // Région/planification
        9,  // Région/planification
        10, // Resp. Planning
        17, // Assistance planning
        37, // Planning Parrainage
        40, // FTP International
        41, // FTP Outremer
        43, // FTPRégions-FTPO
        45, // Marketing FTPO
        47, // FTP Pacifique
        51 // Mkt FTPI
    ];

    public id: string;
    public name: string;

    constructor(jsonEmployee: JsonEmployee) {
        super();
        jsonEmployee = this.convertToUnderscore(jsonEmployee);

        this.id = jsonEmployee.id;
        this.name = jsonEmployee.name;
    }
}
