import { Channel } from './channel.resource';

export interface JsonFilteredItem {
    id: any;
    name: any;
    image?: any;
    code_ut?: string;

    first_name?: string;
    last_name?: string

    signatory_function?: string;

    entity?: any;
    disabled?: boolean;
    toolTipMsg?: string;
}

export class FilteredItem {
    public id: any;
    public value: any;
    public display: any;
    public logo: Channel;

    public entity: any;
    public disabled: boolean = false;
    public toolTipMsg: string = '';

    constructor(jsonFilteredItem: JsonFilteredItem) {
        if (jsonFilteredItem) {
            this.value = jsonFilteredItem.id || jsonFilteredItem.code_ut;
            this.display = jsonFilteredItem.name ||
              (jsonFilteredItem.first_name && jsonFilteredItem.last_name ? `${jsonFilteredItem.first_name} ${jsonFilteredItem.last_name}` :
              (jsonFilteredItem.signatory_function ? jsonFilteredItem.signatory_function : null));

            if (jsonFilteredItem.id) {
              this.id = jsonFilteredItem.id;
            }

            if (jsonFilteredItem.image) {
              this.logo = jsonFilteredItem.image;
            }

            this.entity = jsonFilteredItem;
        }
    }

    public getImagePath(): string | null {
        return this.logo ? Channel.PATH + this.logo : null;
    }
}
