
import {map,  catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { OfferProgramBudget } from '../../resource/offerProgramBudget.resource';
import { environment } from '../../../environments/environment';
import { AbstractService } from '../abstract.service';
import { UtilitiesHandler } from '@service/utilities-handlers/utilitiesHandlers';

import { Observable } from 'rxjs';


@Injectable()
export class OfferProgramBudgetService extends AbstractService {
  private route: string = 'offer_program_budget';

  constructor(
    private http: HttpClient,
    private utilitiesHandler: UtilitiesHandler
  ) {
    super();
  }

  /**
   * Get offer program budget with ID // TODO MORE TEST
   *
   * @param {(string | number)} id
   * @returns {Observable<OfferProgramBudget>}
   * @memberof OfferProgramBudgetService
   */
  get(id: string | number): Observable<OfferProgramBudget> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}/${id}`;

    return this.http
      .get(api_base_url)
      .pipe(map((data: any) =>
        new OfferProgramBudget(data)
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get the list of offer program budget // TODO MORE TEST
   *
   * @param {*} filters
   * @returns {Observable<OfferProgramBudget[]>}
   * @memberof OfferProgramBudgetService
   */
  getByFilter(filters):  Observable<OfferProgramBudget[]> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params})
      .pipe(map((data: any) =>
        data._embedded.offer_program_budget
          .map((jsonOfferProgramBudget: any) =>
            new OfferProgramBudget(jsonOfferProgramBudget)
          )
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Post offer program budget
   *
   * @param {OfferProgramBudget} offerProgramBudget
   * @returns {Observable<Object>}
   * @memberof OfferProgramBudgetService
   */
  public post(offerProgramBudget: OfferProgramBudget): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;

    return this.http
      .post(api_base_url, offerProgramBudget)
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Post with offer program // TODO UNUSED MORE TEST
   *
   * @param {*} offerProgram
   * @returns {Observable<Object>}
   * @memberof OfferProgramBudgetService
   */
  public postWithOfferProgram(offerProgram): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;

    return this.http
      .post(api_base_url, this.extract(offerProgram))
      .pipe(map((data: any) =>
        new OfferProgramBudget(data)
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Put a edit offer program budget
   *
   * @param {OfferProgramBudget} offerProgramBudget
   * @returns {Observable<Object>}
   * @memberof OfferProgramBudgetService
   */
  edit(offerProgramBudget: OfferProgramBudget): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}/${offerProgramBudget.id}`;
    const dataOfferProgramBudget = JSON.parse(JSON.stringify(offerProgramBudget));

    // offerProgramBudget don't need status for API (was surcharge)
    if (offerProgramBudget && offerProgramBudget.offerProgram && offerProgramBudget.offerProgram.status >= 0) {
      delete dataOfferProgramBudget.offerProgram.status;
    }

    return this.http
      .put(api_base_url, this.convertToUnderscore(dataOfferProgramBudget))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Save offer program budget
   *
   * @param {OfferProgramBudget} offerProgramBudget
   * @returns {Observable<Object>}
   * @memberof OfferProgramBudgetService
   */
  public saveOfferProgramBudget(offerProgramBudget: OfferProgramBudget): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;

    return this.http
      .post(api_base_url, this.convertToUnderscore(offerProgramBudget))
      .pipe(map((data: any) =>
        new OfferProgramBudget(data)
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Build new Object
   *
   * @param {*} offerProgram
   * @returns {Object}
   * @memberof OfferProgramBudgetService
   */
  public extract(offerProgram): Object {
    return {
        price: 0,
        currency: 'EUR',
        offer_program: {
            id: offerProgram.id
        }
    }
  }
}
