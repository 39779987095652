import { Injectable } from '@angular/core';

import { SchedulePackageService } from '../schedule-package/schedule-package.service';
import { ScheduleTimeService } from '../schedule-time/schedule-time.service';
import { Purchase } from '../../resource/purchase.resource';
import { SchedulePeriodService } from '../schedule-period/schedule-period.service';
import { ScheduleService } from './schedule.service';
import { Schedule } from '../../resource/schedule/schedule.resource';
import { PurchaseProgram } from '../../resource/purchaseProgram.resource';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';

import { Observable } from 'rxjs';

@Injectable()
export class SchedulePurchasedService {

  constructor(
    private scheduleService: ScheduleService,
    private schedulePeriodService: SchedulePeriodService,
    private scheduleTimeService: ScheduleTimeService,
    private schedulePackageService: SchedulePackageService,
    private customToastrService: CustomToastrService
  ) { }

  /**
   * Clone schedule
   *
   * @param {Schedule} schedule
   * @param {Purchase} purchase
   * @param {PurchaseProgram} purchaseProgram
   * @returns {Observable<any>}
   * @memberof SchedulePurchasedService
   */
  public cloneSchedule(schedule: Schedule, purchase: Purchase, purchaseProgram: PurchaseProgram): Observable<any> {

    return Observable.create(observer => {
        let totalActions = 3;
        let actionCount = 0;
        let clonedSchedule = this.getClonedSchedule(schedule, purchase, purchaseProgram);

        this.scheduleService.post(clonedSchedule).subscribe(result => {
            let clonedScheduleId = result['id'];
            clonedSchedule.id = clonedScheduleId;

            this.schedulePeriodService.postClonedSchedulePeriod(clonedSchedule)
              .subscribe(() => {
                if (this.checkActionCount(totalActions, ++actionCount)) {
                  observer.next(clonedScheduleId);
                }
              }, () => {
                this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
              });

            this.scheduleTimeService.postClonedScheduleTime(clonedSchedule)
              .subscribe(() => {
                if (this.checkActionCount(totalActions, ++actionCount)) {
                  observer.next(clonedScheduleId);
                }
              }, () => {
                this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
              });

            this.schedulePackageService.postClonedSchedulePackages(clonedSchedule, purchase.soreachPurchase)
              .subscribe(() => {
                if (this.checkActionCount(totalActions, ++actionCount)) {
                  observer.next(clonedScheduleId);
                }
              }, () => {
                this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
              });
        }, () => {
          this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
        });
      });
    }

    /**
     * Get clone schedule
     *
     * @param {Schedule} schedule
     * @param {Purchase} purchase
     * @param {PurchaseProgram} purchaseProgram
     * @returns {Schedule}
     * @memberof SchedulePurchasedService
     */
    public getClonedSchedule(schedule: Schedule, purchase: Purchase, purchaseProgram: PurchaseProgram): Schedule {
      return new Schedule({
          comments: schedule.comments,
          days: schedule.days,
          except_start_period: schedule.exceptStartPeriod,
          except_end_period: schedule.exceptEndPeriod,
          except_start_time: schedule.exceptStartTime,
          except_end_time: schedule.exceptEndTime,
          is_legacy: schedule.isLegacy,
          broadcast_count: schedule.broadcastCount,
          offer_program: purchaseProgram.offerProgram,
          parent: schedule,
          purchase: purchase,
          periods: schedule.periods,
          times: schedule.times,
          schedule_packages: schedule.schedulePackages,
          purchase_program: purchaseProgram
      });
    }

    /**
     * Check action count
     *
     * @private
     * @param {*} totalActions
     * @param {*} actionCount
     * @returns {boolean}
     * @memberof SchedulePurchasedService
     */
    private checkActionCount(totalActions, actionCount): boolean {
      return totalActions === actionCount;
    }
}
