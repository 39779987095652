import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

import * as moment from 'moment';
import { Schedule } from '../../../resource/schedule/schedule.resource';
import { ScheduleException } from 'src/app/resource/schedule/schedule-exception.resource';
import { Broadcast } from 'src/app/resource/broadcast.resource';

@Component({
  selector: 'app-grid-warning',
  templateUrl: './grid-warning.component.html',
  styleUrls: ['./grid-warning.component.scss']
})
export class GridWarningComponent implements OnInit, OnChanges {
  @Input() broadcastsTimes;
  @Input() mode: boolean;
  @Input() schedules: Schedule[];

  public broadcastWarning: Array<any>;
  public broadcastWarningIconClass: Array<any>;

  constructor() {}

  ngOnInit() {
    if (this.broadcastsTimes && this.broadcastsTimes.length > 0) {
      let warningIconClass;

      this.broadcastWarning = new Array(this.broadcastsTimes.length);
      this.broadcastWarningIconClass = new Array(this.broadcastsTimes.length);

      this.broadcastsTimes.forEach((items, index) => {
        let oldValue: string;

        // reset flags
        let hasDeprog = false;
        let hasException = false;

        if (items.length > 0) {
          this.broadcastWarning[index] = '';
          let dicException: Array<number> = [];

          items.forEach((item) => {
            if (item instanceof ScheduleException && !dicException.includes(item.id)) {
              let label = '';
              let startDate: moment.Moment = null;
              let endDate = null;

              if (item.label) {
                label = `(${item.label})`;
              }

              items.forEach((data) => {
                if (data.id === item.id && (!startDate || moment(data.exceptStartPeriod).isBefore(moment(startDate)))) {
                  startDate = moment(data.exceptStartPeriod);
                }

                if (data.id === item.id && (!endDate || moment(data.exceptEndPeriod).isAfter(moment(endDate)))) {
                  endDate = moment(data.exceptEndPeriod);
                }
              });

              this.broadcastWarning[index] += `Exception, ${label} du ${startDate.format('L')} au ${endDate.format('L')}: ${item.exceptStartTime} / ${item.exceptEndTime} \n`;
              dicException.push(item.id);
              hasException = true;
            } else if (item instanceof Broadcast) {
              let currentValue = `\n${item.channelName} / ${item.programeName}:\n`;
              let startDate = moment(item.startTime).format('L');

              if (oldValue !== currentValue && this.mode) {
                this.broadcastWarning[index] += currentValue;
                oldValue = currentValue;
              }
              this.broadcastWarning[index] +=
                `Déprog. du ${startDate} : ${this.transform(item.startTimeLabel)} / ${this.transform(item.endTimeLabel)} \n`;

              hasDeprog = true;
            }
          });

          // found only deprogs
          if (hasDeprog && !hasException) {
            warningIconClass = 'warning-deprog';
          }

          // found only exception
          if (!hasDeprog && hasException) {
            warningIconClass = 'warning-exception';
          }

          // found both deprog and exceptions
          if (hasDeprog && hasException) {
            warningIconClass = 'warning-both';
          }

          hasDeprog = false;
          hasException = false;

          // save class
          this.broadcastWarningIconClass[index] = warningIconClass;
        } else {
          this.broadcastWarning[index] = null;
          this.broadcastWarningIconClass[index] = null;
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  /**
   * Transforme string number to hour
   *
   * @param {*} value
   * @returns {string}
   * @memberof GridWarningComponent
   */
  transform(value): string {
    let hour = value.substr(0, 2);
    let minute = value.substr(2, 2);

    return hour + ':' + minute;
  }
}
