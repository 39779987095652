import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { PurchaseComponent } from './purchase/purchase.component';
import { PurchaseListComponent } from './purchase-list/purchase-list.component';
import { PurchaseDetailComponent } from './purchase-detail/purchase-detail.component';

const routes = [
  {
    path: 'purchases',
    component: PurchaseComponent,
    children: [
      {path: 'list', component: PurchaseListComponent},
      {path: 'create', component: PurchaseDetailComponent},
      {path: ':action/:id', component: PurchaseDetailComponent},
      {path: '', redirectTo: 'list', pathMatch: 'full'}
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class PurchaseRoutingModule { }

