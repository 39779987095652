import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { LotsComponent } from './lots/lots.component';

const routes = [
  {
    path: 'lots',
    component: LotsComponent,
    data: { title: 'Gestion des lots' }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class LotsRoutingModule { }
