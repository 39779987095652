import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Schedule } from '../../resource/schedule/schedule.resource';
import { SchedulePeriod } from '../../resource/schedule/schedule-period.resource';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';
import { environment } from '../../../environments/environment';
import { UtilitiesHandler } from '@service/utilities-handlers/utilitiesHandlers';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import * as moment from 'moment';

@Injectable()
export class SchedulePeriodService {
  private route: string = 'schedule_period';

 constructor(
   private customToastrService: CustomToastrService,
   private http: HttpClient,
   private utilitiesHandler: UtilitiesHandler
   ) {}

  /**
   * Post schedule period
   *
   * @param {SchedulePeriod} period
   * @returns {Observable<Object>}
   * @memberof SchedulePeriodService
   */
  public post(period: SchedulePeriod): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;

    return this.http
      .post(api_base_url, this.extract(period))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Post collection of schedule perdiod
   *
   * @param {SchedulePeriod[]} periods
   * @returns {Observable<Object>}
   * @memberof SchedulePeriodService
   */
  public postCollection(periods: SchedulePeriod[]): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;

    return this.http
      .post(api_base_url, this.extractCollection(periods))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Edit collection of schedule period
   *
   * @param {SchedulePeriod[]} periods
   * @returns {Observable<Object>}
   * @memberof SchedulePeriodService
   */
  public editCollection(periods: SchedulePeriod[]): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;

    return this.http
      .put(api_base_url, this.extractCollection(periods))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Delete collection of schedule perdiod
   *
   * @param {SchedulePeriod[]} periods
   * @returns {Observable<Object>}
   * @memberof SchedulePeriodService
   */
  public deleteCollection(periods: SchedulePeriod[]): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;

    return this.http
      .request('delete', api_base_url,
        {
          body: {ids: periods.map(period => period.id)}
        }
      )
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Remove schedule period
   *
   * @param {SchedulePeriod} period
   * @returns {Observable<Object>}
   * @memberof SchedulePeriodService
   */
  public remove(period: SchedulePeriod): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}/${period.id}`;

    return this.http
      .delete(api_base_url)
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Extract collection
   *
   * @param {*} array
   * @returns {Array<any>}
   * @memberof SchedulePeriodService
   */
  public extractCollection(array): Array<any> {
    let result = [];
    array.forEach(item => {
      result.push(this.extract(item));
    });

    return result;
  }

  /**
   * Post cloned schedule period
   *
   * @param {Schedule} clonedSchedule
   * @returns {Observable<any>}
   * @memberof SchedulePeriodService
   */
  public postClonedSchedulePeriod(clonedSchedule: Schedule): Observable<any> {
    return Observable.create(observer => {
      if (!clonedSchedule.periods.length) {
        observer.next();
      } else {
        let count = 0;

        clonedSchedule.periods.forEach((period: SchedulePeriod ) => {
          period.parent = Object.assign({}, period);
          period.schedule = clonedSchedule;
          period.id = null;
          this.post(period)
          .subscribe(() => {
            if (this.checkActionCount(clonedSchedule.periods.length, ++count)) {
              observer.next();
            }
          }, () => {
            this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
          })
        })
      }
    })
  }

  /**
   * manage periods
   *
   * @param {*} schedule
   * @param {*} [oldSchedule=null]
   * @returns {Observable<any>}
   * @memberof SchedulePeriodService
   */
  public managePeriods(schedule, oldSchedule = null): Observable<any> {
    return Observable.create(observer => {

      schedule.periods.forEach(period => {
        period.schedule = schedule;
      })

      let totalActions = 0
      let actionCount = 0;
      let periodsToDelete = [];
      let periodsToAdd = schedule.periods.filter((period) => period.id === null);
      let periodsToEdit = schedule.periods.filter((period) => period.id !== null);
      let oldPeriods = oldSchedule ? oldSchedule.periods : [];

      oldPeriods.forEach(oldPeriod => {
        let idFound = schedule.periods.filter((period) => period.id === oldPeriod.id)
        if (idFound.length === 0) {
          periodsToDelete.push(oldPeriod);
        }
      });

      if (periodsToAdd.length) {
          totalActions++;
          this.postCollection(periodsToAdd)
            .subscribe(() => {
              if (this.checkActionCount(totalActions, ++actionCount)) {
                observer.next();
              }
            }, (error) => {
              this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            });
      }

      if (periodsToEdit.length) {
          totalActions++;
          this.editCollection(periodsToEdit)
            .subscribe(() => {
              if (this.checkActionCount(totalActions, ++actionCount)) {
                observer.next();
              }
            }, (error) => {
              this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            });
      }

      if (periodsToDelete.length) {
        periodsToDelete.forEach(period => {
          totalActions++;
          this.remove(period)
            .subscribe(() => {
              if (this.checkActionCount(totalActions, ++actionCount)) {
                observer.next();
              }
            }, (error) => {
              this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            });
        });
      }
    });

  }

  /**
   * Check action count
   *
   * @private
   * @param {*} totalActions
   * @param {*} actionCount
   * @returns {boolean}
   * @memberof SchedulePeriodService
   */
  private checkActionCount(totalActions, actionCount): boolean {
    return totalActions === actionCount;
  }

  /**
   * Extract
   *
   * @private
   * @param {SchedulePeriod} schedulePeriod
   * @returns {Object}
   * @memberof SchedulePeriodService
   */
  private extract(schedulePeriod: SchedulePeriod): Object {
    return {
      id: schedulePeriod.id,
      schedule: schedulePeriod.schedule.id,
      start_time: schedulePeriod.startTime ? moment(schedulePeriod.startTime, 'YYYY-MM-DD').format('YYYY-MM-DD') : null,
      end_time: schedulePeriod.endTime ?  moment(schedulePeriod.endTime, 'YYYY-MM-DD').format('YYYY-MM-DD') : null,
      parent: schedulePeriod.parent && schedulePeriod.parent.id ? schedulePeriod.parent.id : null
    }
  }
}
