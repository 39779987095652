import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';

import { PurchaseTypeService } from '@service/purchase-type/purchase-type.service';
import { PurchaseService } from '@service/purchase/purchase.service';
import { FiltersService } from '../../../availability/service/filters.service';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';

import { FilteredItem } from '../../../../resource/filteredItem.resource';
import { Purchase } from '../../../../resource/purchase.resource';
import { PurchaseType } from '../../../../resource/purchaseType.resource';

import { CustomValidators } from '../../../../validator/custom-validators';
import { PurchaseInfoDialogComponent } from './dialog/purchase-info-dialog.component';

import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as moment from 'moment';

@Component({
  selector: 'app-purchase-info',
  templateUrl: './purchase-info.component.html',
  styleUrls: ['./purchase-info.component.scss'],
  providers: [ FiltersService ]
})
export class PurchaseInfoComponent implements OnInit {
  @Input() purchase: Purchase;
  @Input() stakeholderGroup: FormGroup;

  currentDate = new Date();
  mainInformationGroup: FormGroup;
  purchaseTypes: FilteredItem[];
  alertSwitchDate;

  selectedType: FilteredItem;

  public purchaseDateOfExpiration = null;
  public computedDateOfExpiration = null;
  public localization;
  public showDateOfExpiration: boolean = false;

  public initialPurchaseStatus: number;


  private componentDestroyed: Subject<any> = new Subject();

  constructor(
    public dialog: MatDialog,
    private purchaseService: PurchaseService,
    private purchaseTypeService: PurchaseTypeService,
    private fb: FormBuilder,
    private filterService: FiltersService,
    private customToastrService: CustomToastrService
  ) { }

  ngOnInit() {
    this.localization = this.filterService.getI18iForDatePicker();

    this.purchaseService.getExpirationDate()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(response => {
        this.computedDateOfExpiration = moment(response[0], 'YYYY-MM-DD');
      }, (error) => {
        this.customToastrService.displayToastr('ERROR', 'Erreur lors du calcul de la date d\'expiration.');
      });

    this.getPurchaseType().subscribe(() => {
      this.initForm();

      this.mainInformationGroup.valueChanges
        .pipe(takeUntil(this.componentDestroyed))
        .subscribe((data) => {
          this.showDateOfExpiration = data.type === PurchaseType.OPTION || data.type === PurchaseType.ALERT
            || data.type === PurchaseType.RECONDUCT_PRIORITY;
          this.purchaseService.updateMainInformation(data);

          this.updateDisplayType(data.type);
        });

      this.purchaseService.isOptionableSource$
        .pipe(takeUntil(this.componentDestroyed))
        .subscribe(isOptionable => {
          this.purchaseTypes.map(purchaseType => {
            if (purchaseType.value === PurchaseType.OPTION) {
                  purchaseType.disabled = !isOptionable;
                  if (!isOptionable) {
                      purchaseType.toolTipMsg = 'Une ou plusieurs offres présentent sur cet achat ne sont pas optionnable.';
                  }
              }
          });
        }, (error) => {
          this.customToastrService.displayToastr('ERROR', 'Erreur lors du passage du dossier en mode optionné.');
        });

      // saving initial purchase status
      this.initialPurchaseStatus = this.mainInformationGroup.controls['type'].value;
    });
  }

  /**
   * Manage select the status
   *
   * @param {*} purchaseType
   *
   * @memberOf PurchaseInfoComponent
   */
  public onStateSelect(purchaseType: any) {
    let newTypeId: number = +this.mainInformationGroup.controls['type'].value || 0;
    let oldTypeId: number = +this.initialPurchaseStatus || 0;

    if (newTypeId !== oldTypeId) {
        let purchaseData = {
          newTypeName: purchaseType.name,
          oldTypeId: this.initialPurchaseStatus
        };
        this.openDialog(purchaseData);
    }
  }

  public getFromNowDate(date): string {
    return moment(date, 'YYYY-MM-DD').fromNow(true);
  }

  /**
   * Open Status dialog and manage result
   *
   * @private
   * @param {*} purchaseData
   *
   * @memberOf PurchaseInfoComponent
   */
  private openDialog(purchaseData: any) {
    const dialogRef = this.dialog.open(PurchaseInfoDialogComponent, {
      height: '250px',
      width: '500px',
      disableClose: true,
      data: purchaseData
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(result => {
        if (result === false && result !== undefined) {
          // reverting selection if state change is cancelled
          this.mainInformationGroup.controls['type'].setValue(this.initialPurchaseStatus);
        } else {
          // saving new selected state as initialState
          this.initialPurchaseStatus = this.mainInformationGroup.controls['type'].value;
        }

        if (purchaseData.newTypeName === 'Optionné' || purchaseData.newTypeName === 'Alerte') {
          this.mainInformationGroup.controls['dateOfExpiration'].setValue(
            moment(this.computedDateOfExpiration, 'YYYY-MM-DD')
          );
        }

        if (purchaseData.newTypeName === 'Priorité de reconduction') {
          this.mainInformationGroup.controls['dateOfExpiration'].setValue(
              moment(moment().year() + '-09-27', 'YYYY-MM-DD')
          );
        }
    }, (error) => {
        this.customToastrService.displayToastr('ERROR', 'Erreur lors du changement de statut du dossier.');
    });
  }

  private getPurchaseType() {
    return new Observable((observer) => {
      this.purchaseTypeService.getList()
        .pipe(takeUntil(this.componentDestroyed))
        .subscribe(
        purchaseTypes => {
            this.purchaseTypes = this.purchaseTypeService.getFilteredItems(
                this.purchaseTypeService.getPurchasesFormTypes(purchaseTypes)
            );

            this.purchaseService.setPurchaseTypes(this.purchaseTypes);
            this.purchaseService.savePurchaseForCompare('info');
            observer.next();
        });
    });
  }

  /**
   * Init Form (Info générales)
   *
   * @return {void}
   */
  private initForm(): void {
    this.mainInformationGroup = this.fb.group({
      name: [null, [Validators.required]],
      type: [null, [Validators.required, CustomValidators.isFilteredItem]],
      comments: null,
      referenceCommand: null,
      preferentialPosition: [null, [Validators.min(0), Validators.max(9)]],
      dateOfExpiration: null
    });

    this.populateForm();
  }

  private populateForm(): void {
    const controls = this.mainInformationGroup.controls;

    if (this.purchase.type) {
        this.showDateOfExpiration = (this.purchase.type.id === PurchaseType.OPTION || this.purchase.type.id === PurchaseType.ALERT
          || this.purchase.type.id === PurchaseType.RECONDUCT_PRIORITY);
    }

    let type = this.purchase.type || this.purchaseTypes[0];

    this.purchaseDateOfExpiration = this.purchase.dateOfExpiration;

    this.alertSwitchDate = this.purchase.alertSwitchDate;

    if (this.purchaseDateOfExpiration) {
      controls['dateOfExpiration'].setValue(moment(this.purchaseDateOfExpiration, 'YYYY-MM-DD'));
    }

    controls['name'].setValue(this.purchase.name);
    controls['type'].setValue(type.id);
    controls['comments'].setValue(this.purchase.comments);
    controls['referenceCommand'].setValue(this.purchase.referenceCommand);
    controls['preferentialPosition'].setValue(this.purchase.preferentialPosition);

    this.updateDisplayType(type.id);
  }

  /**
   * Update display type name
   * @param typeId
   */
  private updateDisplayType(typeId: string): void {
    if (!this.selectedType || (this.selectedType && this.selectedType.id && (this.selectedType.id !== typeId))) {
      this.selectedType = this.purchaseTypes.find((purchaseType: FilteredItem) => purchaseType.id === typeId);
    }
  }
}
