export class Base {

  public toUnderscore(string) {
    return string.replace(/([A-Z])/g, function($1) {return '_' + $1.toLowerCase(); });
  };

  public convertToUnderscore(json) {
      let result = <any>{};
      Object.keys(json).forEach(key => {

          if (key === '_embedded') {
              Object.keys(json[key]).forEach(subKey => {
                  let underscoreKey = this.toUnderscore(subKey);
                  result[underscoreKey] = json[key][subKey];
              });
          } else {
            let underscoreKey = this.toUnderscore(key);
            result[underscoreKey] = json[key];
          }
      });

      return result;
  }
}
