<section fxLayout="column" fxFlex>
  <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  <div fxLayout="row" fxFlex class="box-filter">
    <div fxLayout="row" fxLayoutAlign="center center">
      <button mat-icon-button (click)="decreaseYear()">
        <mat-icon class="chevron">chevron_left</mat-icon>
      </button>
      <span class="switch-year hold-color">{{ filters.year }}</span>
      <button mat-icon-button (click)="increaseYear()">
        <mat-icon class="chevron">chevron_right</mat-icon>
      </button>
    </div>
    <ng-container *ngIf="!loading && allAreas && allProgramCategories">
      <div fxLayout="row" fxLayoutGap="10px">
        <!--Région-->
        <mat-form-field class="width-multi-select">
          <mat-select placeholder="Région" [formControl]="areaCtrl" multiple>
            <div fxLayoutAlign="start center" class="height-all">
              <mat-checkbox (change)="selectAllArea()" [checked]="areaCtrl.value.length === allAreas.length"
                            class="margin-left-15">
                <span *ngIf="areaCtrl.value.length !== allAreas.length">Tout sélectionner</span>
                <span *ngIf="areaCtrl.value.length === allAreas.length">Tout désélectionner</span>
              </mat-checkbox>
            </div>
            <mat-select-trigger>
              {{(areaCtrl.value && areaCtrl.value.length) ? areaCtrl.value[0].name : ''}}
              <span *ngIf="areaCtrl.value?.length > 1" class="text-additional-selection">
                (+{{areaCtrl.value.length - 1}} {{areaCtrl.value?.length === 2 ? 'autre' : 'autres'}})
              </span>
            </mat-select-trigger>
            <mat-option *ngFor="let area of allAreas" [value]="area">{{area.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <!--Unité de programme-->
        <mat-form-field class="width-multi-select">
          <mat-select placeholder="Unité de programme" [formControl]="categoryCtrl" multiple>
            <div fxLayoutAlign="start center" class="height-all">
              <mat-checkbox (change)="selectAllCategories()"
                            [checked]="categoryCtrl.value.length === allProgramCategories.length"
                            class="margin-left-15">
                <span *ngIf="categoryCtrl.value.length !== allProgramCategories.length">Tout sélectionner</span>
                <span *ngIf="categoryCtrl.value.length === allProgramCategories.length">Tout désélectionner</span>
              </mat-checkbox>
            </div>
            <mat-select-trigger>
              {{(categoryCtrl.value && categoryCtrl.value.length) ? categoryCtrl.value[0].name : ''}}
              <span *ngIf="categoryCtrl.value?.length > 1" class="text-additional-selection">
                (+{{categoryCtrl.value.length - 1}} {{categoryCtrl.value?.length === 2 ? 'autre' : 'autres'}})
              </span>
            </mat-select-trigger>
            <mat-option *ngFor="let category of allProgramCategories" [value]="category">{{category.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="width-search">
          <input placeholder="Emission" type="text" matInput [formControl]="programCtrl">
        </mat-form-field>
      </div>
    </ng-container>
    <div fxLayout="row" fxLayoutAlign="center center">
      <button mat-flat-button class="find-button-big position-button-export" (click)="openExportConducteur()"
              [disabled]="loading || !haveArea || !haveCategory">Export Conducteur
      </button>
      <button mat-flat-button class="find-button position-button-find" (click)="loadProgramRegion()"
              [disabled]="loading">Rechercher
      </button>
    </div>
  </div>
</section>
