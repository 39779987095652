import { DirectiveModule } from '../../directive/directive.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PurchaseListComponent } from './purchase-list/purchase-list.component';
import { PurchaseRoutingModule } from './purchase-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { PurchaseComponent } from './purchase/purchase.component';
import { PurchaseListDetailComponent } from './purchase-list/purchase-list-detail/purchase-list-detail.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PurchaseDetailComponent } from './purchase-detail/purchase-detail.component';
import { PurchaseInfoComponent } from './purchase-detail/purchase-info/purchase-info.component';
import { PurchaseInfoDialogComponent } from './purchase-detail/purchase-info/dialog/purchase-info-dialog.component';
import { PurchaseStakeholderComponent } from './purchase-detail/purchase-stakeholder/purchase-stakeholder.component';
import { PurchaseListFilterComponent } from './purchase-list/purchase-list-filter/purchase-list-filter.component';
import { PurchaseOfferProgramComponent } from './purchase-detail/purchase-offer-program/purchase-offer-program.component';
import { OfferProgramListComponent } from './purchase-detail/purchase-offer-program/offer-program-list/offer-program-list.component';
import { OfferProgramDetailComponent } from './purchase-detail/purchase-offer-program/offer-program-detail/offer-program-detail.component';
import { ProgramComponent } from './purchase-detail/purchase-offer-program/offer-program-detail/program/program.component';
import { BroadcastComponent } from './purchase-detail/purchase-offer-program/offer-program-detail/broadcast/broadcast.component';
import { AdvertisingPackageComponent }
from './purchase-detail/purchase-offer-program/offer-program-detail/advertising-package/advertising-package.component';
import { AdvertisingPackageDetailsComponent }
// tslint:disable-next-line:max-line-length
from './purchase-detail/purchase-offer-program/offer-program-detail/advertising-package/advertising-package-details/advertising-package-details.component';
import { PurchaseGlobalBudgetComponent } from './purchase-detail/purchase-global-budget/purchase-global-budget.component';

import { BroadcastAddComponent }
from './purchase-detail/purchase-offer-program/offer-program-detail/broadcast/broadcast-add/broadcast-add.component';
import { BroadcastListComponent }
from './purchase-detail/purchase-offer-program/offer-program-detail/broadcast/broadcast-list/broadcast-list.component';
import { PurchaseGrpComponent } from './purchase-detail/purchase-grp/purchase-grp.component';
import { AbatementComponent } from './dialog/abatement/abatement.component';
import { CascadeUnitComponent } from './dialog/abatement/cascade-unit/cascade-unit.component';
import { FlexLayoutModule } from '@angular/flex-layout';

import { LocalStrPipe } from '../../pipe/local-str.pipe';

import {
  MatTableModule,
  MatIconModule,
  MatToolbarModule,
  MatExpansionModule,
  MatCardModule,
  MatButtonModule,
  MatSlideToggleModule,
  MatProgressSpinnerModule,
  MatDialogModule,
  MatSelectModule,
  MatProgressBarModule,
  MatRadioModule,
  MatFormFieldModule,
  MatCheckboxModule,
  MatListModule,
  MatTooltipModule,
  MatAutocompleteModule,
  MatInputModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSortModule,
  MatChipsModule, MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter
} from '@angular/material';

import { PipeModule } from '../../pipe/pipe.module';
import {FRENCH_DATE_FORMATS} from '../../app.module';
import {MomentDateAdapter} from '@angular/material-moment-adapter';

@NgModule({
  imports: [
    CommonModule,
    PurchaseRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    DirectiveModule,
    // Material
    MatAutocompleteModule,
    MatIconModule,
    MatToolbarModule,
    MatExpansionModule,
    MatCardModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatSelectModule,
    MatProgressBarModule,
    MatRadioModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatListModule,
    MatTooltipModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatSortModule,
    SharedModule,
    MatChipsModule,
    FlexLayoutModule,
    PipeModule
  ],
  declarations: [
    PurchaseComponent,
    PurchaseListComponent,
    PurchaseListDetailComponent,
    PurchaseDetailComponent,
    PurchaseInfoComponent,
    PurchaseInfoDialogComponent,
    PurchaseStakeholderComponent,
    PurchaseListFilterComponent,
    PurchaseOfferProgramComponent,
    OfferProgramListComponent,
    OfferProgramDetailComponent,
    ProgramComponent,
    AdvertisingPackageComponent,
    AdvertisingPackageDetailsComponent,
    BroadcastComponent,
    PurchaseGlobalBudgetComponent,
    BroadcastAddComponent,
    BroadcastListComponent,
    PurchaseGrpComponent,
    AbatementComponent,
    CascadeUnitComponent,
    LocalStrPipe,
  ],
  providers: [
    AbatementComponent,
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: MAT_DATE_FORMATS, useValue: FRENCH_DATE_FORMATS, deps: [MAT_DATE_LOCALE] },
    { provide: DateAdapter, useClass: MomentDateAdapter }
  ],
  entryComponents : [
    AbatementComponent,
    PurchaseInfoDialogComponent
  ],
  exports: [PurchaseListFilterComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PurchaseModule { }
