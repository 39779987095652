import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AbstractService } from '../abstract.service';
import { UtilitiesHandler } from '@service/utilities-handlers/utilitiesHandlers';
import { environment } from '../../../environments/environment';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class DuplicateOfferService extends AbstractService {
  private route: string = 'duplicate_offer';

  constructor(
    private http: HttpClient,
    private utilitiesHandler: UtilitiesHandler
  ) {
    super();
   }

  /**
   * Duplicate Offer
   *
   * @param {number} offerId
   * @param {number} newYear
   * @returns {Observable<Object>}
   * @memberof DuplicateOfferService
   */
  public duplicate(offerId: number, newYear: number): Observable<Object> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;
    const data: Object = {
      id: offerId,
      year: newYear
    };

    return this.http
      .post(api_base_url, data)
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

}
