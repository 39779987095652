import { Abatement } from '../../../../../resource/abatement.resource';
import { PurchaseAbatementService } from '@service/purchase-abatement/purchase-abatement.service';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';

@Component({
  selector: 'app-cascade-unit',
  templateUrl: './cascade-unit.component.html',
  styleUrls: ['./cascade-unit.component.scss'],
})
export class CascadeUnitComponent implements OnInit {
  @Input() public form: FormGroup;
  @Input() public unitName: string;
  @Input() public groupName: string;
  @Input() public total: number;
  @Input() public totalAlreadyFact: number;

  public items: any;

  constructor(private purchaseAbatementService: PurchaseAbatementService) { }

  ngOnInit() {
    this.items = this.form.controls[this.groupName];
  }

  public removeItem(i: number) {
    const control = <FormArray>this.form.controls[this.groupName];
    let abatement: Abatement = control.controls[i].get('abatement').value;

    this.purchaseAbatementService.removePurchaseAbatement(abatement.code);
    control.removeAt(i);
  }

}
