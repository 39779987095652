<mat-progress-bar mode="indeterminate" *ngIf="isSaving" class="position-progress-bar"></mat-progress-bar>
<h2 mat-dialog-title>Export des conducteurs :</h2>
<div mat-dialog-content class="full-width content-export-conducteur">
  <form [formGroup]="exportConducteurForm" fxLayout="column" fxLayoutAlign="center center">
    <div fxLayout="row" fxLayoutGap="25px">
      <mat-form-field class="form-field">
        <input matInput
               [matDatepicker]="startDate"
               placeholder="Du"
               formControlName="periodStartDate"
               [max]="endDateForm">
        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
        <mat-datepicker #startDate></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="form-field">
        <input matInput
               [matDatepicker]="endDate"
               placeholder="Au"
               formControlName="periodEndDate"
               [min]="startDateForm">
        <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
        <mat-datepicker #endDate></mat-datepicker>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" class="full-width">
      <div fxLayoutAlign="center center" fxFlex>
        <mat-checkbox formControlName="option">Options</mat-checkbox>
      </div>
      <div fxLayoutAlign="center center" fxFlex>
        <mat-checkbox formControlName="alert">Alertes</mat-checkbox>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" class="actions-export-conducteur" fxLayoutGap="10px">
  <button mat-button class="button-flat-export black" (click)="close()">
    <span class="white-text">Annuler</span>
  </button>
  <button mat-flat-button class="button-flat-export red" [disabled]="!exportConducteurForm.valid" (click)="generateConducteur()">
    <span class="white-text">Valider</span>
  </button>
</div>
