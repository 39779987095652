import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { SignataireService } from '@service/signataire/signataire.service';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-add-function-dialog',
  templateUrl: './add-function-dialog.component.html',
  styleUrls: ['./add-function-dialog.component.scss']
})
export class AddFunctionDialogComponent implements OnInit {
  public fonction: FormGroup;
  public loading: boolean = false;
  private componentDestroyed$: Subject<any> = new Subject();

  constructor(
    private signataireService: SignataireService,
    private customToastrService: CustomToastrService,
    public dialogRef: MatDialogRef<AddFunctionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.fonction = new FormGroup({
      name: new FormControl(null, [Validators.maxLength(100), Validators.required])
    });
  }

  ngOnInit() {
  }

  /**
   * Close dialog signataire without save
   *
   * @memberOf AddFunctionDialogComponent
   */
  close(name = null): void {
    this.dialogRef.close(name);
  }

  /**
   * Save new signataire
   *
   * @memberOf AddFunctionDialogComponent
   */
  save(): void {
    if (this.fonction.valid) {
      this.loading = true;

      this.signataireService.postFonction(this.fonction)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((response) => {
          this.loading = false;
          this.customToastrService.displayToastr('SUCCESS', 'Fonction créée');
          this.close(response);
        }, error => {
          this.customToastrService.displayToastr('ERREUR', error.data.detail + 'Enregistrement échoué :');
          this.loading = false;
          this.close();
        });
    }
  }
}
