import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { GridService } from '../../../service/grid.service';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';

import { Channel } from '../../../../../resource/channel.resource';
import { environment } from '../../../../../../environments/environment';

import { Subject}  from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as moment from 'moment';

@Component({
  selector: 'app-soreach-dispo-grid',
  templateUrl: './soreach-dispo-grid.component.html',
  styleUrls: ['./soreach-dispo-grid.component.scss']
})
export class SoreachDispoGridComponent implements OnInit, OnDestroy {
  @Input() filters: any;

  public loading = false;
  public baseURl: string;
  public soReachProgramsGrid = [];
  public logoChannelsPath: string = Channel.PATH;
  public weeksDays = [{'long': 'Lundi', 'short': 'lu', 'iso': 1}, {'long': 'Mardi', 'short': 'ma', 'iso': 2},
    {'long': 'Mercredi', 'short': 'me', 'iso': 3}, {'long': 'Jeudi', 'short': 'je', 'iso': 4},
    {'long': 'Vendredi', 'short': 've', 'iso': 5}, {'long': 'Samedi', 'short': 'sa', 'iso': 6},
    {'long': 'Dimanche', 'short': 'di', 'iso': 7}];

  public NatChannels = [{'long': 'France 2', 'short': '2'}, {'long': 'France 3', 'short': '3'}, {'long': 'France 4', 'short': '4'},
    {'long': 'France 5', 'short': '5'}, {'long': 'France O', 'short': 'o'}];

  private componentDestroyed$: Subject<void> = new Subject();
  private isoWeekNumber: number;
  private isoYear: number;
  private selectedSoReachPrograms: Object;

  constructor(
    private customToastrService: CustomToastrService,
    private gridService: GridService
  ) {
    this.selectedSoReachPrograms = {
      2: this.buildWeek(),
      3: this.buildWeek(),
      4: this.buildWeek(),
      5: this.buildWeek(),
      O: this.buildWeek()
    };
    this.baseURl = environment.api_base_url;
  }

  ngOnInit() {
    this.gridService.soReachOfferProgramsGrid$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((soReachOfferProgramsGrid: any) => {
        this.soReachProgramsGrid = [];
        for (const item of Object.values(soReachOfferProgramsGrid)) {
          this.soReachProgramsGrid.push(item);
        }

        this.separateCoPart();
        this.mergeSelected();
        this.feedPurchases();
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.gridService.soReachWeek$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((isoWeekNumber: number) => {
        this.isoWeekNumber = isoWeekNumber;
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.gridService.soReachYear$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((isoYear: number) => {
        this.isoYear = isoYear;
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.gridService.channelCard$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data: any) => {
        this.selectedSoReachPrograms = data.card;
        this.soReachProgramsGrid.forEach((program) => {
            this.weeksDays.forEach((weekDay) => {
              if (program.grid[data.channel][weekDay.short].status === 5) {
                program.grid[data.channel][weekDay.short].status = 1;
              }
            });
        })
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.gridService.programCard$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data: any) => {
        const index = this.soReachProgramsGrid.findIndex((item) => item.offer_program === data.program.offer_program);
        this.selectedSoReachPrograms = data.card;
        if (index >= 0 && this.soReachProgramsGrid[index].grid[data.channel][data.day].status === 5) {
          this.soReachProgramsGrid[index].grid[data.channel][data.day].status = 1;
        }
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.unsubscribe();
  }

  /**
   * Send object of week
   *
   * @return {Object}
   */
  buildWeek(): Object {
    return {
      lu: [],
      ma: [],
      me: [],
      je: [],
      ve: [],
      sa: [],
      di: [],
    }
  }

  /**
   * Separate coPart/Purchase
   */
  separateCoPart(): void {
    this.soReachProgramsGrid.forEach((soReachProgramGrid: any, indexProgramGrid: number) => {
      Object.keys(soReachProgramGrid.grid).forEach((channel: string) => {
        Object.keys(soReachProgramGrid.grid[channel]).forEach((day: string) => {
          const purchases = soReachProgramGrid.grid[channel][day].purchases.filter((purchase: any) => purchase['SOREACH_PURCHASE'] && purchase['PURCHASE'] && purchase['PRODUCT']);
          const coPart = soReachProgramGrid.grid[channel][day].purchases.filter((purchase: any) => !purchase['SOREACH_PURCHASE'] && purchase['PURCHASE'] && purchase['PRODUCT']);

          soReachProgramGrid.grid[channel][day] = {...soReachProgramGrid.grid[channel][day], purchases: purchases, coPart: coPart};
        });
      });
    });
  }

  /**
   * Change statue by click if user have selected
   *
   * @return {void}
   */
  mergeSelected(): void {
    this.NatChannels.forEach((channel) => {
      this.weeksDays.forEach((weekDay) => {
        this.selectedSoReachPrograms[channel.short.toUpperCase()][weekDay.short].forEach((selected) => {
          if (selected.weekNumber === this.isoWeekNumber) {
            const index = this.soReachProgramsGrid.findIndex((program) => program.offer_program === selected.offer_program);
            if (index >= 0) {
              this.soReachProgramsGrid[index].grid[channel.short.toUpperCase()][weekDay.short].status = 5;
            }
          }
        })
      });
    });
  }

  /**
   * Add case soReach
   */
  feedPurchases(): void {
    this.soReachProgramsGrid.forEach((soReachProgramGrid: any, indexProgramGrid: number) => {
      Object.keys(soReachProgramGrid.grid).forEach((channel: string) => {
        Object.keys(soReachProgramGrid.grid[channel]).forEach((day: string) => {
          const availability = +soReachProgramGrid.availability;
          const purchases = soReachProgramGrid.grid[channel][day].purchases.length;

          if (soReachProgramGrid.grid[channel][day].status === 1 || soReachProgramGrid.grid[channel][day].status === 2) {
            for (let p = purchases; availability > p; p++) {
              this.soReachProgramsGrid[indexProgramGrid].grid[channel][day].purchases.push({});
            }
          }
        });
      });
    });
  }

  /**
   * Select/Unselect soReach
   * @param indexSoReachProgram
   * @param channel
   * @param weekDay
   * @param indexPurchase
   */
  selectSoReachProgram(indexSoReachProgram: number, channel: string, weekDay: string, indexPurchase: number): void {
    let purchase = this.soReachProgramsGrid[indexSoReachProgram].grid[channel][weekDay].purchases[indexPurchase];
    channel = channel.toUpperCase();
    const day = this.weeksDays.find((item) => item.short === weekDay).iso;
    const indexSelected = this.selectedSoReachPrograms[channel][weekDay].findIndex((item) => this.soReachProgramsGrid[indexSoReachProgram].offer_program === item.offer_program && item.weekNumber === this.isoWeekNumber);
    const indexProgram = this.soReachProgramsGrid.findIndex((item) => item.offer_program === this.soReachProgramsGrid[indexSoReachProgram].offer_program);

    if (purchase.selected) {
      delete purchase.selected;
    } else {
      purchase['selected'] = true;
    }

    if (indexSelected === -1) {
      let dayDate = moment(this.soReachProgramsGrid[indexProgram].grid[channel][weekDay].start_time);
      if (!dayDate.isValid()) {
        dayDate = moment().isoWeekYear(this.isoYear).isoWeek(this.isoWeekNumber).isoWeekday(day);
      }

      this.selectedSoReachPrograms[channel][weekDay].push({name: this.soReachProgramsGrid[indexSoReachProgram].name, program: this.soReachProgramsGrid[indexSoReachProgram].program, offer_program: this.soReachProgramsGrid[indexSoReachProgram].offer_program,
        date: dayDate.format('L'), weekNumber: this.isoWeekNumber, broadcastIds: this.soReachProgramsGrid[indexSoReachProgram].grid[channel][weekDay].broadcasts});
      this.soReachProgramsGrid[indexProgram].grid[channel][weekDay].status = 5;
    } else {
      this.selectedSoReachPrograms[channel][weekDay].splice(indexSelected, 1);
      this.soReachProgramsGrid[indexProgram].grid[channel][weekDay].status = 1;
    }
    this.gridService.loadingSelectedSoReachPrograms(this.selectedSoReachPrograms);
  }

  /**
   * Build tooltip for soreach purchase
   *
   * @param purchase {Array<string>}
   * @return {string}
   */
  buildTooltip(purchase: Array<string>): string {
    let tooltip = [];

    if (purchase['ADVERTISER']) {
      tooltip.push('Annonceur: ' + purchase['PRODUCT']);
    }

    if (purchase['PRODUCT']) {
      tooltip.push('Produit: ' + purchase['PRODUCT']);
    }

    if (purchase['SECODIP']) {
      tooltip.push('Secodip: ' + purchase['SECODIP']);
    }

    if (purchase['COMMERCIAL']) {
      tooltip.push('Commercial: ' + purchase['COMMERCIAL']);
    }

    if ((purchase['TYPE_PURCHASE'] === 2 || purchase['TYPE_PURCHASE'] === 10) && purchase['EXPIRATION']) {
      tooltip.push('Expiration: ' + purchase['EXPIRATION']);
    }

    return tooltip.join('\n');
  }
}
