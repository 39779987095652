import { Base } from './base.resource';
import { Offer } from './offer.resource';
import { OfferProgram } from './offerProgram.resource';
import { EmailRecipient } from './email-recipient.resource';

export interface JsonOfferPush {
    id ?: number;
    parent: Offer;
    offer_push_programs?: OfferProgram[];
    email_recipients?: EmailRecipient[];
}

export class OfferPush extends Offer {

    public id: number;
    public offerPushPrograms: OfferProgram[];
    public emailRecipients: EmailRecipient[];

    constructor(jsonOfferPush: JsonOfferPush) {
        // jsonOfferPush = super.convertToUnderscore(jsonOfferPush);
        super(jsonOfferPush.parent);

        this.id = jsonOfferPush.id;

        if (jsonOfferPush.offer_push_programs) {
            this.offerPushPrograms = this.setOfferPushPrograms(jsonOfferPush.offer_push_programs);
            this.offerPrograms = this.offerPushPrograms;
        }

        if (jsonOfferPush.email_recipients) {
            this.emailRecipients = this.setEmailRecipient(jsonOfferPush.email_recipients);
        }
    }

    private setOfferPushPrograms(offerPushPrograms: OfferProgram[]): OfferProgram[] {
        return offerPushPrograms.map(offerPushProgram => new OfferProgram(offerPushProgram));
    }

    private setEmailRecipient(emailRecipients: EmailRecipient[]): EmailRecipient[] {
        return emailRecipients.map(emailRecipient => new EmailRecipient(emailRecipient));
    }
}
