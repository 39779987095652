import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { takeUntil } from 'rxjs/operators';
import { Subject, of, Observable } from 'rxjs';

import { PropalCartService } from '@service/propal-cart/propal-cart.service';
import { GridHeaderService } from '../service/grid.header.service';
import { DateService } from '../service/date.service';
import { FiltersService } from '../service/filters.service';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';
import { GridService } from '../service/grid.service';
import { OfferProgramService } from '@service/offer-program/offer-program.service';
import { TargetService } from '@service/target/target.service';

import { PropalCart } from '../../../resource/availability/propal-cart.resource';
import { OfferProgram } from '../../../resource/offerProgram.resource';
import { Channel } from '../../../resource/channel.resource';
import { Broadcast } from '../../../resource/broadcast.resource';

import { GridComponent } from '../grid/grid.component';
import { Title } from '@angular/platform-browser';

import * as moment from 'moment';
import { PurchaseService } from '@service/purchase/purchase.service';
import { PurchaseParams } from '../../../resource/purchaseType.resource';

@Component({
  selector: 'app-availability',
  templateUrl: 'availability.component.html',
  styleUrls: ['availability.component.scss'],
  providers: [ FiltersService, DateService, GridHeaderService, GridService ],
})

export class AvailabilityComponent implements OnInit, OnDestroy {

  public filters: any;
  public loading: boolean = false;
  public legendToogleStatus: boolean = false;
  public diplayModeIndex: number = 2;
  public DateFrom: FormControl;
  public DateTo: FormControl;
  public target: FormControl;
  public mode: FormControl;

  public purchaseParams: PurchaseParams;
  public targetItems: any;

  public groupeBroadcast: Object = {};

  // panier
  public propalCart: PropalCart;
  // new properties
  public offerPrograms: OfferProgram[] = [];
  public queryParams: any = {};

  public indexOpened: Array<boolean> = [];
  public selectedPeriod: string = '1';
  public filterTargetOptions: Observable<string[]>;
  public availabilityGridViews: Array<string> = ['program', 'offer', 'synthesis', 'soreach'];
  public defaultGridView = 'program';
  // Variables used to display an offer in availability view offer redirected from availability synthesis view
  viewParam: string = 'program';
  yearParam: number = null;
  offerParam: Object = null;

  path: string = Channel.PATH;

  private componentDestroyed$: Subject<any> = new Subject();

  constructor(
    private filtersService: FiltersService,
    private offerProgramService: OfferProgramService,
    private propalCartService: PropalCartService,
    private customToastrService: CustomToastrService,
    private targetService: TargetService,
    private titleService: Title,
    private dateService: DateService,
    private purchaseService: PurchaseService,
    private route: ActivatedRoute) {
      this.filtersService = filtersService;
      this.dateService = dateService;
  }

  ngOnInit() {
    this.route.queryParams
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(params => {
      this.viewParam = (params['view'] && this.availabilityGridViews.includes(params['view'])) ? params['view'] : this.defaultGridView;
      this.yearParam = (params['year'] && Date.parse(params['year'])) ? params['year'] : null;
      this.offerParam = (params['offer_id'] && Number.isInteger(parseInt(params['offer_id'], 10)) && params['offer_name']) ?
          {value: params['offer_id'], display: params['offer_name']} : null;
    }, (error) => {
      this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
    });

    this.titleService.setTitle(`Disponibilités - recherche`);
    this.getTargetItem();
    this.filters = this.filtersService.initFilters(this.yearParam, this.offerParam);
    this.DateFrom = new FormControl(moment(this.filters.broadcasted_from, 'YYYY-MM-DD'));
    this.DateTo = new FormControl(moment(this.filters.broadcasted_to, 'YYYY-MM-DD'));
    this.target = new FormControl('');
    this.mode = new FormControl(this.viewParam);

    // get purchase params for update purchase
    this.purchaseParams = this.purchaseService.getPurchaseParams();

    // propal cart
    this.propalCart = this.propalCartService.initPropalCart();
    this.propalCartService.propalCartSource$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(propalCart => {
        this.propalCart = propalCart;
      }, (error) => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.target.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        // Init total selected grp data with default values after change event on GRP Cible field
        GridComponent.initTotalSelectedGrpData();
        this.filterTargetOptions = of(this.filterTarget(data));
      });

    // Call search function when redirecting from availability synthetic view to availability offer view
    if (this.filters.offers.length > 0 && this.viewParam === 'offer') {
      this.search();
    }
  }

  search() {
    this.loading = true;
    this.groupeBroadcast = {};
    this.queryParams = this.filtersService.getQueryParams(this.filters);
    this.propalCartService.clickOnOffer(null); // RESET VALUE CLICK
    this.getOfferPrograms();
  }

  increaseYear() {
    this.filters.broadcasted_from = moment(this.filters.broadcasted_from, 'YYYY-MM-DDTHH:mm:ss')
      .startOf('year')
      .add(1, 'year')
      .format('YYYY-MM-DD');

    this.filters.broadcasted_to = moment(this.filters.broadcasted_to, 'YYYY-MM-DDTHH:mm:ss')
      .endOf('year')
      .add(1, 'year')
      .format('YYYY-MM-DD');

    this.filters.month = moment(this.filters.broadcasted_from).month();
    this.filters.year = moment(this.filters.broadcasted_from, 'YYYY-MM-DDTHH:mm:ss').year();
    this.DateFrom = new FormControl(moment(this.filters.broadcasted_from, 'YYYY-MM-DD'));
    this.DateTo = new FormControl(moment(this.filters.broadcasted_to, 'YYYY-MM-DD'));
    if (this.mode.value !== 'synthesis') {
      this.search();
    }
  }

  decreaseYear() {
    this.filters.broadcasted_from = moment(this.filters.broadcasted_from, 'YYYY-MM-DDTHH:mm:ss')
      .startOf('year')
      .subtract(1, 'year')
      .format('YYYY-MM-DD');

    this.filters.broadcasted_to = moment(this.filters.broadcasted_to, 'YYYY-MM-DDTHH:mm:ss')
      .endOf('year')
      .subtract(1, 'year')
      .format('YYYY-MM-DD');

    this.filters.month = moment(this.filters.broadcasted_from).month();
    this.filters.year = moment(this.filters.broadcasted_from, 'YYYY-MM-DDTHH:mm:ss').year();
    this.DateFrom = new FormControl(moment(this.filters.broadcasted_from, 'YYYY-MM-DD'));
    this.DateTo = new FormControl(moment(this.filters.broadcasted_to, 'YYYY-MM-DD'));
    if (this.mode.value !== 'synthesis') {
      this.search();
    }
  }

  increaseMonth(): void {
    let from = moment(this.filters.broadcasted_from, 'YYYY-MM-DDTHH:mm:ss').startOf('month').add(1, 'month');
    this.filters.broadcasted_from = from.format('YYYY-MM-DD');
    this.filters.broadcasted_to = from.endOf('month').format('YYYY-MM-DD');
    this.filters.month = from.month();
    this.filters.year = from.year();
    this.DateFrom = new FormControl(moment(this.filters.broadcasted_from, 'YYYY-MM-DD'));
    this.DateTo = new FormControl(moment(this.filters.broadcasted_to, 'YYYY-MM-DD'));
    this.search();
  }

  decreaseMonth(): void {
    let from = moment(this.filters.broadcasted_from, 'YYYY-MM-DDTHH:mm:ss').startOf('month').subtract(1, 'month');
    this.filters.broadcasted_from = from.format('YYYY-MM-DD');
    this.filters.broadcasted_to = from.endOf('month').format('YYYY-MM-DD');
    this.filters.month = from.month();
    this.filters.year = from.year();
    this.DateFrom = new FormControl(moment(this.filters.broadcasted_from, 'YYYY-MM-DD'));
    this.DateTo = new FormControl(moment(this.filters.broadcasted_to, 'YYYY-MM-DD'));
    this.search();
  }

  updateDisplayMode(tabChangeEvent: any): void {
    this.propalCartService.clickOnOffer(null);
    switch (tabChangeEvent.value) {
      case '1':
        this.filters.displayMode = 'year';
        this.filters.broadcasted_from = moment(this.filters.broadcasted_from, 'YYYY-MM-DDTHH:mm:ss')
          .format('YYYY-MM-DD');

        this.filters.broadcasted_to = moment(this.filters.broadcasted_to, 'YYYY-MM-DDTHH:mm:ss')
          .format('YYYY-MM-DD');

        this.diplayModeIndex = 2;
        break;
      case '0':
        this.filters.displayMode = 'month';
        this.filters.broadcasted_from = moment(this.filters.broadcasted_from, 'YYYY-MM-DDTHH:mm:ss')
          .format('YYYY-MM-DD');

        this.filters.broadcasted_to = moment(this.filters.broadcasted_to, 'YYYY-MM-DDTHH:mm:ss')
          .format('YYYY-MM-DD');

        this.diplayModeIndex = 1;
        break;
      default:
        break;
    }

    this.filters.month = moment(this.filters.broadcasted_from, 'YYYY-MM-DDTHH:mm:ss').month();
    this.filters.year = moment(this.filters.broadcasted_from, 'YYYY-MM-DDTHH:mm:ss').year();

    this.search();
  }

  /**
   * Emit event for close all CardInformation
   *
   * @returns {void}
   * @memberof AvailabilityComponent
   */
  leaveGridInformation(): void {
    this.propalCartService.mouseLeaveGrid();
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.unsubscribe();
  }

  changeGroupeToogle() {
    if (this.offerPrograms.length) {
      this.search();
    }
    this.offerPrograms = [];
  }

  buildGroupeOffer(offerPrograms) {
    let offerGroupe = [];
    let buildItem;
    let statusOffer;

    offerPrograms.forEach((item) => {
      statusOffer = offerGroupe.findIndex((data) => data.id === item.offer.id);

      if (statusOffer === -1) {
        buildItem = item.offer;
        buildItem['program'] = [];
        buildItem['images'] = [];
        buildItem.program.push(item);
        buildItem.images.push(item.area.channel.image);
        offerGroupe.push(buildItem);
      } else {
        if (offerGroupe[statusOffer].images.findIndex((data) => data === item.area.channel.image) === -1) {
          offerGroupe[statusOffer].images.push(item.area.channel.image);
        }
        offerGroupe[statusOffer].program.push(item);
      }
    });
    this.offerPrograms = offerGroupe;
    this.indexOpened = new Array(this.offerPrograms.length);
  }

  /**
   * Send all Tmp broadcast for shared
   * @param data Broadcast
   * @param offer
   * @return void
   */
  pushBroadcastAll(data: Broadcast[], offer: any): void {
    let id: number = offer.id;
    let offerId: number = offer.offer.id;
    let programId: number = offer.program.id;

    if (!this.groupeBroadcast[offerId]) {
      this.groupeBroadcast[offerId] = {};
    }
    this.groupeBroadcast[offerId][`${programId}${id}`] = data;
    this.offerProgramService.allBroadcast.next(this.groupeBroadcast);
  }

  /**
   * Boolean for open panel
   * @param $event void
   * @param index number
   * @returns void
   */
  openOfferPanel($event: void, index: number): void {
    this.indexOpened[index] = !this.indexOpened[index];
  }

  formatTarget(item: void): any {
    return (value) => this.displayTarget(value);
  }

  displayTarget(value: string): string {
    if (this.targetItems) {
      let item = this.targetItems.find((data) => data.value === value);
      if (item) {
        return item.display;
      }
    }
    return value;
  }

  /**
   * Get Offer Program and redirect
   * @returns void
   */
  private getOfferPrograms(): void {
    this.loading = true;
    this.offerProgramService
      .getList(this.queryParams, 'offer-program-availibility', this.mode.value === 'offer')
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((offerPrograms: OfferProgram[]) => {
        this.loading = false;
        if (this.mode.value === 'offer') {
          this.buildGroupeOffer(offerPrograms);
        } else {
          this.offerPrograms = offerPrograms;
        }
      }, (error) => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });
  }

  private getTargetItem(): void {
    this.targetService
      .getListForFilter()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(result => {
        this.targetItems = result;
        this.filterTargetOptions = of(this.targetItems);
    })
  }

  private filterTarget(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.targetItems.filter(option => option.display.toLowerCase().includes(filterValue));
  }
}
