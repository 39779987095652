<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<mat-card class="soreachavailability-filter">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutGap="10px" fxFlex="50%">
      <mat-form-field fxFlex="30%" class="form-field">
        <input matInput #soReachDateInput [matDatepicker]="soreachDate" placeholder="Date" [formControl]="soReachDateCtrl">
        <mat-datepicker-toggle matSuffix [for]="soreachDate"></mat-datepicker-toggle>
        <mat-datepicker #soreachDate></mat-datepicker>
      </mat-form-field>
      <mat-form-field fxFlex="30" class="form-field">
        <input matInput #soReachTypeInput placeholder="Type SoReach" [matAutocomplete]="soreachType" [formControl]="soReachTypeCtrl">
        <mat-autocomplete #soreachType="matAutocomplete" [displayWith]="displayChannelName">
          <mat-option *ngFor="let soReach of filteredSoReachTypes | async" [value]="soReach">
            <span>{{ soReach.name }}</span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <button mat-flat-button (click)="getSoReachOfferProgramsGrid()" class="find-button">Rechercher</button>
  </div>
</mat-card>
