<div>
  <mat-toolbar class="fixed-nav-bar">
    <mat-toolbar-row class="toolbar-height">
      <div class="container-app">
        <img class="logo-adspace-offline" src="assets/images/logos/adspace-logo.png">
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <button mat-button class="pink mat-raised" (click)="googleLoginPage()">Connexion avec France Télévision Publicité</button>
</div>
