<mat-progress-bar mode="indeterminate" *ngIf="loadingInputAdvertiser || loadingInputCommercial"></mat-progress-bar>
<form novalidate [formGroup]="mainInformationGroup">
    <!-- Purchase name -->
    <div>
        <mat-form-field class="form-control-full">
            <input
                matInput
                formControlName="name"
                appRemoveAccents
                placeholder="Entrez un nom"
                required
                maxlength="50">
        </mat-form-field>
    </div>

    <!-- Commercial -->
    <div>
        <mat-form-field class="form-control-full">
            <input
                matInput
                placeholder="Commercial"
                required
                appRemoveAccents
                [matAutocomplete]="commercialAutocomplete"
                formControlName="commercial">
        </mat-form-field>

        <mat-autocomplete #commercialAutocomplete="matAutocomplete" [displayWith]="autocompleteService.displayAutocompleteWithValue.bind(this)">
            <mat-option *ngFor="let employee of filteredEmployeeCommercialItems | async"  [value]="employee">
                {{ employee.display }} ({{ employee.value }})
            </mat-option>
        </mat-autocomplete>
    </div>

    <!-- Annonceur -->
    <div>
        <mat-form-field class="form-control-full">
            <input
                matInput
                placeholder="Annonceur"
                required
                appRemoveAccents
                [matAutocomplete]="advertiserAutocomplete"
                formControlName="advertiser">
        </mat-form-field>

        <mat-autocomplete #advertiserAutocomplete="matAutocomplete" [displayWith]="autocompleteService.displayAutocomplete.bind(this)">
            <mat-option *ngFor="let advertiser of filteredAdvertiserItems | async"  [value]="advertiser" (onSelectionChange)="onAdvertiserSelect(advertiser)">
                {{ advertiser.display }} ({{ advertiser.value }})
            </mat-option>
        </mat-autocomplete>
    </div>
</form>
