import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { SynthesisFilter } from '../../../../resource/synthesis.resource';

@Component({
  selector: 'app-synthesis',
  templateUrl: './synthesis.component.html',
  styleUrls: ['./synthesis.component.scss']
})
export class SynthesisComponent implements OnInit {
  @Output() decreaseY: EventEmitter<void> = new EventEmitter();
  @Output() increaseY: EventEmitter<void> = new EventEmitter();
  @Input() filters;

  synthesisFilter: SynthesisFilter;
  sourceSynthesis = [];

  constructor() { }

  ngOnInit() {
    this.initSynthesisFilters();
  }

  decreaseYear(): void {
    this.decreaseY.emit();
  }

  increaseYear(): void {
    this.increaseY.emit();
  }

  getValueSourceSynthesis(synthesisData): void {
    this.sourceSynthesis = synthesisData;
  }

  initSynthesisFilters(): void {
    this.synthesisFilter = new SynthesisFilter();
  }
}
