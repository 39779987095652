import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { UtilitiesHandler } from '@service/utilities-handlers/utilitiesHandlers';

import { Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class AuthService {
  public access_token: string;

  private eventLogged = new Subject<boolean>();
  public eventLogged$ = this.eventLogged.asObservable();

  constructor(
    private http: HttpClient,
    private utilitiesHandler: UtilitiesHandler
  ) {}

  /**
   * Get token
   *
   * @param {*} code
   * @returns
   * @memberof AuthService
   */
  getToken(code) {
    let isGotToken = false;
    let api_base_url = environment.api_base_url + '/oauth/token';
    let redirect_uri = window.location.href.split('?')[0];
    let client_id = environment.openid_client_id;

    let credentials = '?grant_type=authorization_code'
      + '&client_id=' + client_id
      + '&redirect_uri=' + redirect_uri
      + '&code=' + code;

    return this.http.post(api_base_url + credentials, [] )
      .pipe(
        map((response: any) => {
          if (response && response['access_token']) {
            isGotToken = true;
            localStorage.setItem('access_token', response['access_token']);
          }

          return isGotToken;
        })
      )
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      );
  }

  /**
   * Is logged in
   *
   * @returns {boolean}
   * @memberof AuthService
   */
  isLoggedIn(): boolean {
    return this.getAccessToken() !== null;
  }

  /**
   * Get acces token
   *
   * @returns {string}
   * @memberof AuthService
   */
  getAccessToken(): string {
    return localStorage.getItem('access_token');
  }

  /**
   * Logout (remove from storage)
   *
   * @returns {void}
   * @memberof AuthService
   */
  logout(): void {
    localStorage.removeItem('access_token');
    localStorage.removeItem('parameter');
  }

  /**
   * Send status event logged
   *
   * @param status {boolean}
   * @return {void}
   */
   sendLoggedStatus(status: boolean): void {
    this.eventLogged.next(status);
  }
}
