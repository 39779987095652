<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
<h2 mat-dialog-title class="h2-title">Création d'émission pour la dotation:</h2>
<mat-dialog-content class="full-width purchase-dotation">
  <form [formGroup]="newMonitoringLotProgramForm" class="full-width" fxLayout="column" fxLayoutAlign="center center">
    <!--name-->
    <div fxLayout="row" fxLayoutGap="25px" class="input-line" fxLayoutAlign="start center">
      <span class="span-width bold" fxFlex="30">Nom :</span>
      <mat-form-field class="full-width">
        <input formControlName="name" placeholder="Nom émission" matInput appRemoveAccents maxlength="50" type="text">
        <mat-error>Veuillez renseigner le nom de l'émission !</mat-error>
      </mat-form-field>
    </div>
    <!--channel-->
    <div fxLayout="row" fxLayoutGap="25px" class="input-line" fxLayoutAlign="start center">
      <span class="span-width bold" fxFlex="30">Chaine :</span>
      <mat-form-field class="full-width">
        <input matInput maxlength="30" placeholder="Chaine"
               appRemoveAccents
               [matAutocomplete]="channelAutocomplete"
               formControlName="channel">
        <mat-error>Veuillez sélectionner une chaine !</mat-error>
      </mat-form-field>
      <mat-autocomplete #channelAutocomplete="matAutocomplete" [displayWith]="autocompleteService.displayAutocomplete.bind(this)">
        <mat-option *ngFor="let channel of filteredChannelItems | async" [value]="channel">
          <img preload fade class="logo" [src]="path + channel.logo">
          {{ channel.display }}
        </mat-option>
      </mat-autocomplete>
    </div>
    <!--area-->
    <div fxLayout="row" fxLayoutGap="25px" class="input-line" fxLayoutAlign="start center">
      <span class="span-width bold" fxFlex="30">Région :</span>
      <mat-form-field class="full-width">
        <input matInput maxlength="30" placeholder="Région"
               appRemoveAccents
               [matAutocomplete]="areaAutocomplete"
               formControlName="area">
        <mat-error>Veuillez sélectionner une région !</mat-error>
      </mat-form-field>
      <mat-autocomplete #areaAutocomplete="matAutocomplete" [displayWith]="autocompleteService.displayAutocompleteWithValue.bind(this)">
        <mat-option *ngFor="let area of filteredAreaItems | async" [value]="area">
          {{ area.display }}
        </mat-option>
      </mat-autocomplete>
    </div>
    <!--Category-->
    <div fxLayout="row" fxLayoutGap="25px" class="input-line" fxLayoutAlign="start center">
      <span class="span-width bold" fxFlex="30">Catégorie :</span>
      <mat-form-field class="full-width">
        <input matInput maxlength="30" placeholder="Catégorie"
               appRemoveAccents
               [matAutocomplete]="categoryAutocomplete"
               formControlName="category">
        <mat-error>Veuillez sélectionner une catégorie de programme !</mat-error>
      </mat-form-field>
      <mat-autocomplete #categoryAutocomplete="matAutocomplete" [displayWith]="autocompleteService.displayAutocompleteWithValue.bind(this)">
        <mat-option *ngFor="let category of filteredCategoryItems | async" [value]="category">
          {{ category.display }}
        </mat-option>
      </mat-autocomplete>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="full-width bloc-actions" fxLayout="row" fxLayoutAlign="end stretch" fxLayoutGap="30px">
  <button mat-flat-button mat-dialog-close class="button-flat black white-text">Annuler</button>
  <button mat-flat-button class="button-flat white-text" (click)="save()" [disabled]="loading || !newMonitoringLotProgramForm.valid">
    Valider
  </button>
</mat-dialog-actions>
