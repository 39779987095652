<a [routerLink]="['/purchases', 'edit', purchase.id]" class="remove-underline">
  <mat-card class="purchase-card margin-bot">
    <mat-progress-bar *ngIf="loadingChannel" mode="indeterminate"></mat-progress-bar>
    <mat-card-content fxLayout="column">
      <div fxLayout="column">
        <div fxLayout="row"  fxLayoutGap="5px" class="margin-bot">
          <div *ngFor="let logo of logosChannel">
            <img preload fade [src]="path + logo" class="flex-1">
          </div>
        </div>
        <mat-card-title class="bold">{{purchase.name}}</mat-card-title>
        <mat-card-subtitle *ngIf="purchase.advertiser">{{purchase.advertiser.name}}</mat-card-subtitle>
        <mat-card-subtitle *ngIf="purchase.temporaryAdvertiser">{{purchase.temporaryAdvertiser}}</mat-card-subtitle>
        <mat-card-subtitle *ngIf="purchase.startCommunicationPeriod && purchase.endCommunicationPeriod">du {{ purchase.startCommunicationPeriod | momentPipe:'DD/MM/YYYY' }} au {{ purchase.endCommunicationPeriod | momentPipe:'DD/MM/YYYY' }}</mat-card-subtitle>
        <mat-card-subtitle *ngIf="purchase.fsaId"><strong>Numéro FSA : {{ purchase.fsaId }}</strong></mat-card-subtitle>
      </div>
      <mat-form-field disabled class="width-input">
        <input matInput placeholder="Etat du dossier" class="hold-value bold" [value]="displayStatus(purchaseTypeValue.value)">
      </mat-form-field>
    </mat-card-content>
  </mat-card>
</a>
