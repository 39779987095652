import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class PurchaseBudgetService {

  private globalBudgetLoadingSource = new Subject<boolean>();
  public globalBudgetLoadingSource$ = this.globalBudgetLoadingSource.asObservable();

  private schedulePackageLoadingSource = new Subject<boolean>();
  public schedulePackageLoadingSource$ = this.schedulePackageLoadingSource.asObservable();

  private purchaseBudgetLoadFromResource = new Subject<boolean>();
  public purchaseBudgetLoadFromResource$ = this.purchaseBudgetLoadFromResource.asObservable();

  constructor() { }

  /**
   * Update global budget loading (event)
   *
   * @param {*} value
   * @memberof PurchaseBudgetService
   */
  public updateGlobalBudgetLoading(value): void {
    this.globalBudgetLoadingSource.next(value);
  }

  /**
   * Update schedule package loading (event)
   *
   * @param {*} value
   * @memberof PurchaseBudgetService
   */
  public updateSchedulePackageLoading(value): void {
    this.schedulePackageLoadingSource.next(value);
  }

  /**
   * Publish purchase budget load from resource (event)
   *
   * @param {*} value
   * @memberof PurchaseBudgetService
   */
  public publishPurchaseBudgetLoadFromResource(value): void {
     this.purchaseBudgetLoadFromResource.next(value);
  }
}
