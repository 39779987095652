<h2 fxLayoutAlign="center center">
  Ajouter une fonction inexistante
</h2>
<form fxLayout="column" fxLayoutAlign="center center" [formGroup]="fonction">
  <mat-form-field class="full-width">
    <input type="text" matInput placeholder="Fonction" formControlName="name" required>
  </mat-form-field>
  <div class="full-width" fxLayout="row" fxLayoutAlign="space-around center">
    <button mat-flat-button class="button-flat black" (click)="close()">
      <span class="white-text">Annuler</span>
    </button>
    <button mat-flat-button class="button-flat red" type="submit" (click)="save()" [disabled]="!fonction.valid">
      <span class="white-text">Enregistrer</span>
    </button>
  </div>
</form>
