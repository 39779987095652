import { OfferPush } from "./offer-push.resource";
import { Base } from "./base.resource";
import { OfferProgramBudget } from "./offerProgramBudget.resource";
import { Offer } from "./offer.resource";
import { Program } from "./program.resource";
import { Area } from "./area.resource";
import { Schedule } from "./schedule/schedule.resource";

export interface JsonOfferProgram {
  id: string;
  offer?: any;
  program?: any;
  area?: any;
  theorical_presence?: any;
  offer_program_budgets?: Array<any>;
  schedules?: Schedule[];
  offer_push?: any;
  so_reachs?: any;
  status?: number;
}

export class OfferProgram extends Base {
  public id: string;
  public offer: Offer;
  public program: Program;
  public area: Area;
  public theoricalPresence: number;
  public offerProgramBudgets: OfferProgramBudget[];
  public schedules: Schedule[];
  public offerPush: OfferPush;
  public soReach;
  public status: number;

  public schedulesCount;
  public isUnknown: boolean;

  constructor(jsonOfferProgram: JsonOfferProgram) {
    super();
    jsonOfferProgram = this.convertToUnderscore(jsonOfferProgram);

    this.id = jsonOfferProgram.id;
    this.status = jsonOfferProgram.status;

    if (jsonOfferProgram.offer) {
      this.offer = new Offer(jsonOfferProgram.offer);
    }

    if (jsonOfferProgram.program) {
      this.program = new Program(jsonOfferProgram.program);
    }

    if (jsonOfferProgram.area) {
      this.area = new Area(jsonOfferProgram.area);
    }

    if (jsonOfferProgram.offer_push) {
      this.offerPush = new OfferPush(jsonOfferProgram.offer_push);
    }

    if (jsonOfferProgram.so_reachs) {
      this.soReach = jsonOfferProgram.so_reachs;
    }

    if (jsonOfferProgram.status) {
      this.status = jsonOfferProgram.status;
    }

    if (jsonOfferProgram.offer_program_budgets) {
      this.setOfferProgramBudgets(jsonOfferProgram);
    }

    if (jsonOfferProgram.schedules) {
      this.setSchedules(jsonOfferProgram);
      this.schedulesCount = jsonOfferProgram.schedules.length || 0;
    }

    this.theoricalPresence = jsonOfferProgram.theorical_presence;
  }

  private setOfferProgramBudgets(jsonOfferProgram: JsonOfferProgram) {
    let result = [];
    jsonOfferProgram.offer_program_budgets.forEach((offerProgramBudget) => {
      result.push(new OfferProgramBudget(offerProgramBudget));
    });

    this.offerProgramBudgets = result;
  }

  private setSchedules(jsonOfferProgram: JsonOfferProgram) {
    let result = [];
    jsonOfferProgram.schedules.forEach((schedule) => {
      result.push(new Schedule(schedule));
    });

    this.schedules = result;
  }
}

export interface JsonEvent {
  libevt: string;
  codevt: number;
  children?: [];
}

export class Event {
  libevt: string;
  codevt: number;
  children: [];

  constructor(jsonEvent: JsonEvent) {
    this.libevt = jsonEvent.libevt;
    this.codevt = jsonEvent.codevt;

    if (jsonEvent.children) {
      this.children = jsonEvent.children;
    }
  }
}
