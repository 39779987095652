import { PropalCart } from '../../resource/availability/propal-cart.resource';
import { PropalCartService } from './propal-cart.service';
import { Injectable } from '@angular/core';
import { Subject ,  Observable } from 'rxjs';

import { OfferProgram } from '../../resource/offerProgram.resource';
import { Purchase } from '../../resource/purchase.resource';
import { PurchaseService } from '../purchase/purchase.service';
import { PurchaseProgramService } from '../purchase-program/purchase-program.service';
import { PurchaseProgram } from '../../resource/purchaseProgram.resource';
import { BroadcastPurchasedService } from '../broadcast-purchased/broadcast-purchased.service';
import { Router } from '@angular/router';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';
import { PurchaseParams } from '../../resource/purchaseType.resource';
import { OfferProgramPropalCart } from '../../resource/availability/offer-program-propal-cart.resource';

const CREATE_PURCHASE = {step: 1, msg: 'Création de l\'achat'};
const ADD_PURCHASE_PROGRAM = {step: 2, msg: 'Ajout des émissions'};
const ADD_SCHEDULE = {step: 3, msg: 'Ajout des programmations'};
const ADD_BROADCAST_PURCHASED = {step: 0, msg: 'Une erreur est survenue'};
const ERROR = {step: 0, msg: 'Une erreur est survenue'};

@Injectable()
export class PropalCartGenerateService {

  public static CREATE_PURCHASE = CREATE_PURCHASE;
  public static ADD_PURCHASE_PROGRAM = ADD_PURCHASE_PROGRAM;
  public static ADD_SCHEDULE = ADD_SCHEDULE;
  public static ADD_BROADCAST_PURCHASED = ADD_BROADCAST_PURCHASED;

  private purchase: Purchase;
  private propalCart: PropalCart;

  private stepSource = new Subject<Object>();
  private step$ = this.stepSource.asObservable();

  private broadcastPurchasedObservable = [];

  constructor(
    private purchaseService: PurchaseService,
    private purchaseProgramService: PurchaseProgramService,
    private broadcastPurchasedService: BroadcastPurchasedService,
    private propalCartService: PropalCartService,
    public customToastrService: CustomToastrService,
    private router: Router,
  ) { }

  public updatePurchaseFromAvailability(propalCart: PropalCart, purchaseParams: PurchaseParams) {
    let purchaseDataToUpdate = {};
    this.broadcastPurchasedObservable = [];
    this.propalCart = propalCart;

    // propal card commnunication periods
    const propalCardMinBroadcastsDate = this.propalCart.getMinBroadcastDate();
    const propalCardMaxBroadcastsDate = this.propalCart.getMaxBroadcastDate();

    if (propalCardMinBroadcastsDate < purchaseParams.startDate) {
      purchaseDataToUpdate['start_communication_period'] = propalCardMinBroadcastsDate;
    }

    if (propalCardMaxBroadcastsDate > purchaseParams.endDate) {
      purchaseDataToUpdate['end_communication_period'] = propalCardMaxBroadcastsDate;
    }

    this.purchaseService.updatePurchasePartial(purchaseParams.id, purchaseDataToUpdate)
      .subscribe(purchase => {
        this.purchase = purchase;
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue lors de la mise à jour de l\'achat.');
      }, () => {
        this.createPurchasePrograms()
          .subscribe(() => {
            this.propalCartService.resetPropalCart();
            this.router.navigate(['/purchases/edit/' + this.purchase.id]);
          }, () => {
            this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
          });

      })
  }

  /**
   * Init
   *
   * @param {PropalCart} propalCart
   * @param {Purchase} purchase
   * @memberof PropalCartGenerateService
   */
  public init(propalCart: PropalCart, purchase: Purchase): void {
    this.broadcastPurchasedObservable = [];
    this.propalCart = propalCart;
    this.purchase = purchase;
    this.generate();
  }

  /**
   * Generate
   *
   * @private
   * @returns {Observable<Purchase>}
   * @memberof PropalCartGenerateService
   */
  private generate(): void {
    let offerList = this.propalCart.offerPrograms.map(c => c.offerProgram.offer);

    let uniqueOfferList = ((unique = {}) => offerList.filter(offer => {
      if (unique[offer.id]) {
        return false;
      }
      unique[offer.id] = true;
      return true;
    }))();

    let digitalBudgets = {
      digitalDisplayBudget : 0,
      digitalReplayBudget : 0,
      digVidBillBudget : 0,
      digVidPreBudget : 0,
      digitalSocialBudget : 0,
      socialFbBudget : 0,
      socialTwitBudget : 0,
      socialInstaBudget : 0,
      digitalOtherBudget : 0
    };

    uniqueOfferList.forEach(offer => {
      digitalBudgets.digitalDisplayBudget += offer.digitalDisplayBudget;
      digitalBudgets.digitalReplayBudget += offer.digitalReplayBudget;
      digitalBudgets.digVidBillBudget += offer.digVidBillBudget;
      digitalBudgets.digVidPreBudget += offer.digVidPreBudget;
      digitalBudgets.digitalSocialBudget += offer.digitalSocialBudget;
      digitalBudgets.socialFbBudget += offer.socialFbBudget;
      digitalBudgets.socialTwitBudget += offer.socialTwitBudget;
      digitalBudgets.socialInstaBudget += offer.socialInstaBudget;
      digitalBudgets.digitalOtherBudget += offer.digitalOtherBudget;
    });

    Object.assign(this.purchase, digitalBudgets, {
      digitalBudget:  digitalBudgets.digitalDisplayBudget +
        digitalBudgets.digitalReplayBudget +
        digitalBudgets.digitalSocialBudget +
        digitalBudgets.digitalOtherBudget
    });

    this.purchase.totalBudget = this.purchase.digitalBudget + this.purchase.tvBudget;
    this.purchase.digitalDisplayBudgetNet = digitalBudgets.digitalDisplayBudget;
    this.purchase.digitalReplayBudgetNet = digitalBudgets.digitalReplayBudget;
    this.purchase.digVidBillBudgetNet = digitalBudgets.digVidBillBudget;
    this.purchase.digVidPreBudgetNet = digitalBudgets.digVidPreBudget;
    this.purchase.digitalSocialBudgetNet = digitalBudgets.digitalSocialBudget;
    this.purchase.socialFbBudgetNet = digitalBudgets.socialFbBudget;
    this.purchase.socialTwitBudgetNet = digitalBudgets.socialTwitBudget;
    this.purchase.socialInstaBudgetNet = digitalBudgets.socialInstaBudget;
    this.purchase.digitalOtherBudgetNet = digitalBudgets.digitalOtherBudget;
    this.purchase.digitalBudgetNet = this.purchase.digitalBudget;

    this.purchase.digitalReplayBudgetNet = this.purchase.digVidBillBudgetNet + this.purchase.digVidPreBudgetNet;
    this.purchase.digitalSocialBudgetNet = this.purchase.socialFbBudgetNet + this.purchase.socialTwitBudgetNet
      + this.purchase.socialInstaBudgetNet;
    this.purchase.totalBudgetNet = this.purchase.digitalBudgetNet + this.purchase.tvBudgetNet;

    this.purchase.caRef = this.purchase.totalBudgetNet;
    this.purchase.caFact = this.purchase.totalBudgetNet;

    // commnunication periods
    this.purchase.startCommunicationPeriod = this.propalCart.getMinBroadcastDate();
    this.purchase.endCommunicationPeriod = this.propalCart.getMaxBroadcastDate();

    this.purchaseService.createPurchase(this.purchase)
      .subscribe(purchase => {
        this.purchase = purchase;
        this.createPurchasePrograms()
          .subscribe(() => {
            this.propalCartService.resetPropalCart();
            this.router.navigate(['/purchases/edit/' + this.purchase.id]);
          }, () => {
            this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
          });
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      })
  }

  /**
   * Create broadcast purchase and schedule
   *
   * @private
   * @returns {Observable<any>}
   * @memberof PropalCartGenerateService
   */
  private createBroadcastPurchaseAndSchedule(): Observable<any> {
    return Observable.create(createObserver => {
      if (!this.broadcastPurchasedObservable && !this.broadcastPurchasedObservable.length) {
        return false;
      }

      let count = this.broadcastPurchasedObservable.length - 1;
      let source;
      let self = this;

      let observable = function(data) {
        source = Observable.create(observer => {
          self.broadcastPurchasedService.reset();
          self.broadcastPurchasedService.init(data['offerProgram'], data['purchase'], data['purchaseProgram']);
          self.broadcastPurchasedService.createBroadcastPurchasedFromAvailibilty(data['broadcast'])
            .subscribe(() => {
              observer.next();
            }, (error) => {
              self.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            });
        });

        source.subscribe(() => {
          if (--count > -1) {
            observable(self.broadcastPurchasedObservable[count]);
          } else {
            createObserver.next(true);
          }
        }, (error) => {
          self.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
        });
      };
      observable(this.broadcastPurchasedObservable[count]);
    })
  }

  /**
   * Create purchase programs
   *
   * @private
   * @returns {Observable<PurchaseProgram[]>}
   * @memberof PropalCartGenerateService
   */
  private createPurchasePrograms(): Observable<PurchaseProgram[]> {
    let count: number = this.propalCart.offerPrograms.length;

    return Observable.create(observer => {
      this.propalCart.offerPrograms.forEach((offerProgramPropalCart: OfferProgramPropalCart) => {
        this.purchaseProgramService.postPurchaseProgram(this.setPurchaseProgram(offerProgramPropalCart.offerProgram))
          .subscribe(purchaseProgram => {
            this.broadcastPurchasedObservable.push({
              offerProgram : offerProgramPropalCart.offerProgram,
              purchase: this.purchase,
              purchaseProgram: purchaseProgram,
              broadcast: offerProgramPropalCart.broadcasts
            });

            if (--count === 0) {
              this.createBroadcastPurchaseAndSchedule().subscribe(() => observer.next(true));
            }
          }, (error) => {
            this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
          })
      });
    });
  }

  /**
   * Set purchase program
   *
   * @private
   * @param {OfferProgram} offerProgram
   * @returns {PurchaseProgram}
   * @memberof PropalCartGenerateService
   */
  private setPurchaseProgram(offerProgram: OfferProgram): PurchaseProgram {
    return new PurchaseProgram({
      id: null,
      offer_program: offerProgram,
      program: offerProgram.program,
      area: offerProgram.area,
      channel: offerProgram.area.channel,
      purchase: this.purchase,
      broadcast_count: 0
    });
  }

  /**
   * Update step (event)
   *
   * @private
   * @param {*} step
   * @memberof PropalCartGenerateService
   */
  private updateStep(step) {
    this.stepSource.next(step);
  }
}
