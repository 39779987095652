
import {delay, map,  catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { CustomToastrService } from '../../service/toastr/custom-toastr.service';
import { QueueLog } from '../../resource/queue-log.resource';
import { UtilitiesHandler } from '@service/utilities-handlers/utilitiesHandlers';
import { environment } from '../../../environments/environment';

import { Subject ,  Observable } from 'rxjs';

@Injectable()
export class QueueLogService {
  private route: string = 'queue_log';

  private queueLogSource = new Subject <QueueLog[]>();
  public queueLogSource$ = this.queueLogSource.asObservable();

  // purchase Queue
  public purchaseFrom: number;
  public t: number = 0;

  constructor(
    private customToastrService: CustomToastrService,
    private http: HttpClient,
    private utilitiesHandler: UtilitiesHandler
  ) { }

  /**
   * Get the queueLog
   *
   * @param {(string | number)} id
   * @returns {Observable<QueueLog>}
   * @memberof QueueLogService
   */
  get(id: string | number): Observable<QueueLog> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}/${id}`;

    return this.http
      .get(api_base_url).pipe(
      map((data: any) =>
        new QueueLog(data)
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get the list of queue log
   *
   * @returns {Observable<QueueLog[]>}
   * @memberof QueueLogService
   */
  getList(): Observable<QueueLog[]> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;

    return this.http
      .get(api_base_url).pipe(
      map((data: any) =>
        data._embedded.queue_log
          .map((jsonPurchaseType: any) =>
            new QueueLog(jsonPurchaseType)
          )
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get list of queue log for filter
   *
   * @param {Object} [filters=null]
   * @returns {Observable<QueueLog[]>}
   * @memberof QueueLogService
   */
  public getListForFilter(filters: Object = null): Observable<QueueLog[]> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params}).pipe(
      map((data: any) =>
        data._embedded.queue_log
          .map((jsonQueueLog: any) =>
            new QueueLog(jsonQueueLog)
          )
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get creating entity
   *
   * @param {string} entityName
   * @param {number} purchaseId
   * @returns {Observable<QueueLog[]>}
   * @memberof QueueLogService
   */
  public getCreatingEntity(entityName: string, purchaseId: number): Observable<QueueLog[]> {
    if (entityName === QueueLog.QUEUE_NAME_BROADCAST_PURCHASED) {
    let filter = {
      entity_id : purchaseId,
      status : '1;2'
    };
      return this.getListForFilter(filter);
    } else {
      return null;
    }
  }

  /**
   * Check of queue progress on timer
   *
   * @param {string} entityName
   * @param {number} entityId
   * @param {number} from
   * @param {boolean} isNew
   * @memberof QueueLogService
   */
  public checkQueueProgressOnTimer(entityName: string, entityId: number, from: number, isNew: boolean): void {
    this.purchaseFrom = from;
    this.getCreatingEntity(entityName, entityId).pipe(
      delay(3000))
      .subscribe(queueLog => {
        if (queueLog.length === 0 && isNew) {
          if (this.t === 0) {
            this.customToastrService.displayToastr('WARNING', 'Aucune diffusion trouvé');
          }
          this.t = 0;
        } else if (queueLog.length > 0) {
          this.t++;
        }
        this.queueLogSource.next(queueLog);
        if (queueLog.length !== 0) {
          if (queueLog[0].okCount !== queueLog[0].totalCount) {
            this.checkQueueProgressOnTimer(entityName, entityId, from, true);
          }
        }
      }, (error) => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });
  }

}
