import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { RegionGridService } from '../../../service/region-grid.service';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';
import { PropalCartService } from '@service/propal-cart/propal-cart.service';

import { RegionFilterSynthesis, RegionSynthesis, RegionWeeksSynthesis } from '../../../../../resource/synthesis-region';

import { HeaderGridExtendsComponent } from '../../../../../shared/class/header-grid-extends/header-grid-extends.component';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as moment from 'moment';

@Component({
  selector: 'app-table-region',
  templateUrl: './table-region.component.html',
  styleUrls: ['./table-region.component.scss']
})
export class TableRegionComponent extends HeaderGridExtendsComponent implements OnInit, OnDestroy {
  @Input() filters: RegionFilterSynthesis;

  public Arr = Array;
  public months = [];
  public weeks = [];
  public numberMonths = [];
  public sourceSynthesis: RegionSynthesis[] = [];

  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private regionGridService: RegionGridService,
    private customToastrService: CustomToastrService,
    private propalCartService: PropalCartService
  ) {
    super();
  }

  ngOnInit() {
    this.regionGridService.regionSynthesis$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((dataRegion: RegionSynthesis[]) => {
        this.updateHeaderYear();
        this.sourceSynthesis = dataRegion;
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    this.propalCartService.deleteProgramCartSource$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((id: number) => {
        this.uncheckOfferProgramFromF3AreaGrid(id);
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.unsubscribe();
  }

  /**
   * Emit to add in cart
   *
   * @param offerProgram {any}
   * @param weekNumber {number}
   * @param indexRow {number}
   */
  addToCart(offerProgram: any, weekNumber: number, indexRow: number): void {
    if (offerProgram && offerProgram.weeks && offerProgram.weeks[weekNumber] && offerProgram.weeks[weekNumber].broadcasts &&
      offerProgram.weeks[weekNumber].broadcasts.length) {
      // Find the offer program
      const offerProgramIndex = this.sourceSynthesis.findIndex((regionSynthesis: RegionSynthesis) => {
        return regionSynthesis.offerProgramId === offerProgram.offerProgramId;
      });

      if (offerProgramIndex >= 0) {
        // Switch between unable/disable
        this.sourceSynthesis[offerProgramIndex]['weeks'][weekNumber]['checked'] ?
          this.sourceSynthesis[offerProgramIndex]['weeks'][weekNumber]['checked'] = 0 :
          this.sourceSynthesis[offerProgramIndex]['weeks'][weekNumber]['checked'] = 1;
        offerProgram['broadcasts'] = [...offerProgram.weeks[weekNumber].broadcasts];
        offerProgram['checked'] = this.sourceSynthesis[offerProgramIndex]['weeks'][weekNumber]['checked'];
        offerProgram['index'] = weekNumber;

        this.propalCartService.emitF3AreaPropalCart(offerProgram);
      }
    }
  }

  /**
   * Build tooltip
   *
   * @param purchase {Array<string>}
   * @return string
   */
  buildTooltip(purchase: Array<string>): string {
    let tooltip = '';

    if (purchase['advertiserName'] && purchase['advertiserName'] !== '' && purchase['advertiserName'] !== null) {
      tooltip += `Annonceur: ${purchase['advertiserName']}\n`
    }

    if (purchase['productName'] && purchase['productName'] !== '' && purchase['productName'] !== null) {
      tooltip += `Produit: ${purchase['productName']}\n`
    }

    if (purchase['secodipName'] && purchase['secodipName'] !== '' && purchase['secodipName'] !== null) {
      tooltip += `Secteur: ${purchase['secodipName']}\n`
    }

    if (purchase['employeeName'] && purchase['employeeName'] !== '' && purchase['employeeName'] !== null) {
      tooltip += `Commercial: ${purchase['employeeName']}\n`
    }

    return tooltip;
  }

  /**
   * Uncheck selected offer program from F3 area availability grid
   *
   * @param id {number}
   */
  private uncheckOfferProgramFromF3AreaGrid(id: number): void {
    const indexProgram = this.sourceSynthesis.findIndex((regionSynthesis: RegionSynthesis) => {
      return regionSynthesis.offerProgramId === id;
    });

    if (indexProgram >= 0) {
      // Uncheck all offer program selected weeks in F3 Area availability grid
      this.sourceSynthesis[indexProgram]['weeks'].forEach((week: RegionWeeksSynthesis) => {
        if (week && week.checked) {
          week.checked = 0;
        }
      });
    }
  }
}
