<div class="legend container-app" elevation="5" [@slideInOut]="legendState">
  <div class="card-content">

    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div>Légende :</div>
      <button mat-icon-button (click)="toggleLegend()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <ul fxLayout="row">
      <li *ngFor="let item of legendeItem" [ngStyle]="{'width': liWidth+'%'}" fxLayout="row">
        <div class="item {{item.class}}" fxLayoutAlign="center center">
          <div *ngIf="item.text" class="thin">{{item.text}}</div>
        </div>
        <div>
          <span class="thin">{{item.name}}</span>
        </div>
      </li>
    </ul>
  </div>
</div>
