import { Purchase } from '../../../../../resource/purchase.resource';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PurchaseProgram } from '../../../../../resource/purchaseProgram.resource';

@Component({
  selector: 'app-offer-program-detail',
  templateUrl: './offer-program-detail.component.html',
  styleUrls: ['./offer-program-detail.component.scss']
})
export class OfferProgramDetailComponent implements OnInit {

  @Input() purchaseProgram: PurchaseProgram;
  @Input() purchase: Purchase;

  @Output() selectedPurchaseProgram = new EventEmitter<PurchaseProgram>();
  @Output() refreshEmitter = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  public onChangePurchaseProgram($event: PurchaseProgram): void {
    this.purchaseProgram = $event;
    this.selectedPurchaseProgram.emit($event);
  }

  refreshBrutEmitter($event: boolean): void {
    this.refreshEmitter.emit($event);
  }

}
