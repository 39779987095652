import { Component, Input, OnInit } from '@angular/core';
import { PurchaseParams } from '../../../../resource/purchaseType.resource';

@Component({
  selector: 'app-soreach-dispo',
  templateUrl: './soreach-dispo.component.html',
  styleUrls: ['./soreach-dispo.component.scss']
})
export class SoreachDispoComponent implements OnInit {
  @Input() filters;
  @Input('purchaseParams') purchaseParams: PurchaseParams;
  constructor() {}

  ngOnInit() {}
}
