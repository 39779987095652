<div class="full-height">
    <mat-toolbar class="fixed-nav-bar">
			<mat-toolbar-row fxLayoutAlign="center center" class="toolbar-height">
				<div fxLayout="row" fxLayoutGap="80px" class="container-app">
					<img class="logo-adspace" src="assets/images/logos/adspace-logo.png">
					<app-menu></app-menu>
				</div>
			</mat-toolbar-row>
    </mat-toolbar>
    <div class="container-app private">
			<router-outlet></router-outlet>
    </div>
</div>
