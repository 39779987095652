import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

import { ScheduleTime } from '../resource/schedule/schedule-time.resource';
import { Schedule } from '../resource/schedule/schedule.resource';
import { FilteredItem } from '../resource/filteredItem.resource';

import { Observable, of } from 'rxjs';

import * as moment from 'moment';

export class CustomValidators {

  // schedule days from
  static multipleCheckboxRequireOne(formArray: FormArray) {
    let valid = false;

    for (let x = 0; x < formArray.length; ++x) {
      if (formArray.at(x).value) {
        valid = true;
        break;
      }
    }
    return valid ? null : {
      multipleCheckboxRequireOne: true
    };
  }


  /**
   * Validate that endTime is superior or egal than startTime
   *
   * @static
   * @param {FormControl} control
   * @returns
   *
   * @memberOf CustomValidators
   */
  static exceptEndTimeValidator(control: FormControl) {
    let isValid  = true;

    if (control.parent) {
      isValid = control.value >= control.parent.controls['exceptStartTime'].value ? true : false;
    }

    return isValid ? null : {
      'exceptEndTimeValidator': true
    };
  }


  /**
   * Validate the time format
   *
   * @static
   * @param {FormControl} control
   * @returns
   *
   * @memberOf CustomValidators
   */
  static timeLabelValidator(control: FormControl) {

    let value = control.value.replace(/\D/g, '');
    let isValid = value.length === 4 ? true : false;

    return isValid ? null : {
      'timeLabelValidator': true
    };
  }


  /**
   * Validate that endTime is superior or egal than startTime
   *
   * @static
   * @param {FormControl} control
   * @returns
   *
   * @memberOf CustomValidators
   */
  static endTimeValidator(control: FormControl) {
    let isValid  = true;

    if (control.parent && control.parent.controls['startTime'].value) {
      let value = control.value.replace(/\D/g, '');
      let startValue = control.parent.controls['startTime'].value.replace(/\D/g, '');

      isValid = value >= startValue ? true : false;
    }

    return isValid ? null : {
      'endTimeValidator': true
    };
  }

  /**
   * Validate that endTime is superior or egal than startTime
   *
   * @static
   * @param {FormControl} control
   * @returns
   *
   * @memberOf CustomValidators
   */
  static endPeriodValidator(control: FormControl) {
    let isValid  = true;

    if (control.parent && control.parent.controls['startTime'].value) {
      isValid = true;
    }

    return isValid ? null : {
      'endPeriodValidator': true
    };
  }

  static endCommunicationPeriodValidator(control: FormControl) {
    let isValid  = true;

    if (control.parent && control.parent.controls['startCommunicationPeriod'].value) {
      let value = control.value.replace(/\D/g, '');
      let startValue = control.parent.controls['startCommunicationPeriod'].value.replace(/\D/g, '');

      isValid = value >= startValue ? true : false;
    }

    return isValid ? null : {
      'endCommunicationPeriodValidator': true
    };
  }

  static startCommunicationPeriodValidator(control: FormControl) {
    let isValid  = true;

    if (control.parent && control.parent.controls['endCommunicationPeriod'].value) {
      let value = control.value.replace(/\D/g, '');
      let endValue = control.parent.controls['endCommunicationPeriod'].value.replace(/\D/g, '');

      isValid = value <= endValue ? true : false;
    }

    return isValid ? null : {
      'startCommunicationPeriodValidator': true
    };
  }

  /**
   * Validate the quantity of purchased schedule packages
   *
   * @static
   * @param {FormControl} control
   * @returns
   *
   * @memberOf CustomValidators
   */
  static offerProgramSchedulePackageDuration(control: FormControl) {
    let isValid  = true;

    if (control.parent) {
      if (control.parent.controls['quantity'].value && !control.value) {
        isValid = false;
      }
    }

    return isValid ? null : {
      offerProgramSchedulePackageDuration: true
    };
  }

  static isFilteredItem(control: FormControl) {
    let isValid = false;

    if (control.value && control.value instanceof FilteredItem) {
        isValid = true;
    }

    return isValid ? null : {
      'isFilteredItem': true
    };
  }

  static temporyFieldRequired(control: FormControl) {
    let isValid  = true;

    if (control.value) {
      if (typeof control.value === 'object' && !control.value.display) {
        isValid = false;
      }
    } else {
      isValid = false;
    }

    return isValid ? null : {
      'temporyFieldRequired': true
    };
  }

  static startTimeValidator(control: FormControl) {
    let isValid  = true;

    if (control.parent && control.parent.controls['endTime'].value) {
      let value = control.value.replace(/\D/g, '');
      let endValue = control.parent.controls['endTime'].value.replace(/\D/g, '');

      isValid = value <= endValue ? true : false;
    }

    return isValid ? null : {
      'startTimeValidator': true
    };
  }

  /**
   * Validate that broadcast new date is not in the past and included in days list of schedule
   *
   * @static
   * @param {Schedule} schedule
   *
   * @memberOf CustomValidators
   */
  static broadcastNewDateValidator(schedule: Schedule): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      let isValid  = false;
      const today = moment();
      const days: number[] = schedule.days.map((day) => day === 0 ? 7 : day); // Replace 0 by 7 (Sunday isoWeek)

      // Check that new broadcast date is not in the past and included in schedule list days
      if (days && days.length && control.value && days.includes(control.value.isoWeekday())) {
        if (control.value >= today) {
          isValid = true;
        }
      }

      return isValid ? null : {'broadcastNewDate': {value: control.value}};
    };
  }

  /**
   * Validate that broadcast startTime and endTime are included in schedule times list
   *
   * @static
   * @param {Schedule} schedule
   * @memberOf CustomValidators
   */
  static inScheduleTimesValidator(schedule: Schedule) {
    return (newTimeFormGroup: FormGroup): {[key: string]: any} | null => {
      let isValid  = false;
      const scheduleTimes: Array<ScheduleTime> = schedule.times;
      const controlStartTime = (newTimeFormGroup && newTimeFormGroup.controls['startTime']) ?
          newTimeFormGroup.controls['startTime'].value : null;
      const controlEndTime = (newTimeFormGroup && newTimeFormGroup.controls['endTime']) ?
          newTimeFormGroup.controls['endTime'].value : null;

      if (scheduleTimes) {
        if (controlStartTime && controlEndTime) {
          isValid = scheduleTimes.some(time => {
            return (controlStartTime === time.startTime && controlEndTime === time.endTime);
          });
        } else if (controlStartTime) {
          isValid = scheduleTimes.some(time => {
            return (controlStartTime === time.startTime);
          });
        } else if (controlEndTime) {
          isValid = scheduleTimes.some(time => {
            return (controlEndTime === time.endTime);
          });
        }
      }

      return isValid ? null : {'inScheduleTimes': {value: newTimeFormGroup.value}};
    };
  }

  /**
   * Validate if it's a filtered item
   *
   * @static
   * @return {Observable<ValidationErrors | null> | boolean}
   */
  static inFilteredItemValidator(control: AbstractControl): Observable<ValidationErrors | null> | boolean {
    if (control.value instanceof FilteredItem) {
      return true;
    }

    return of(null);
  }

  /**
   * Validate if it's a valid date
   * @param dayNumber 0 = Monday => 6 = Sunday
   */
  static dayValidator(dayNumber: number): any {
    return (control: AbstractControl): ValidationErrors | null => {
      const weekDay = moment(control.value).weekday();

      return weekDay !== dayNumber ? {invalidDay: weekDay} : null;
    }
  }
}
