import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class CustomToastrService {

  constructor(private toastr: ToastrService) {}

  /**
   * Display toastr notification
   *
   * @param type string => 'ERROR' | 'WARNING' | 'SUCCESS'
   * @param message string
   */
  displayToastr(type: 'ERROR' | 'WARNING' | 'SUCCESS' | string, message: string): void {
    switch (type) {
      case 'ERROR':
        this.toastr.error(message, null, {timeOut: 30000, closeButton: true});
        break;
      case 'WARNING':
        this.toastr.warning(message, null, {timeOut: 5000, closeButton: false});
        break;
      case 'SUCCESS':
        this.toastr.success(message, null, {timeOut: 5000, closeButton: false});
        break;
      default :
        this.toastr.success(message, null, {timeOut: 5000, closeButton: false});
        break;
    }
  }
}
