import * as moment from 'moment';

export class HeaderGridExtendsComponent {
  protected weeks;
  protected months;
  protected numberMonths;
  protected filters;

  constructor() { }

  /**
   * Update Header
   * Create month week and week number
   *
   * @return {void}
   */
  protected updateHeaderYear(): void {
    let size: number = 53; // Default
    let nameOfMonths: string[] = moment.months();
    this.weeks = [];
    this.months = [];
    this.numberMonths = [];
    let separateMonths = [];
    // Create reference for group month
    for (let i = 1; i < size; i++) {
      if (separateMonths && separateMonths.length && moment(this.filters.year.toString()).add(i - 1, 'weeks')
        .startOf('week').format('MM') !== moment(this.filters.year.toString())
        .add(i, 'weeks').startOf('week').format('MM')) {
        separateMonths.push(true);
      } else {
        // Switch case if the week is between 2 week
        if (separateMonths[separateMonths.length - 1] === true &&
          +moment(this.filters.year.toString()).add(i - 1, 'weeks').startOf('week').format('DD') >= 5 &&
          +moment(this.filters.year.toString()).add(i - 1, 'weeks').startOf('week').format('DD') <= 7) {
          separateMonths[separateMonths.length - 2] = true;
          separateMonths[separateMonths.length - 1] = false;
        }
        separateMonths.push(false);
      }
    }
    // Create case for number of week
    for (let i = 0; i < size; i++) {
      this.weeks[i] = {
        number: moment(moment(this.filters.year.toString()).add(i, 'weeks'), 'DD-MM-YYYY').week(),
        separated: separateMonths[i]
      };
      this.numberMonths.push({
        number: moment(this.filters.year.toString()).add(i, 'weeks').startOf('week').format('DD'),
        separated: separateMonths[i]
      });
    }
    // Create case for months
    for (let index = 1; index <= 12; index++) {
      this.months.push({
        size: ((100 / 370) * moment(`${this.filters.year}-${index}`).daysInMonth()),
        name: nameOfMonths[index - 1]
      });
    }
  }
}
