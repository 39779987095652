import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'currency'
})
export class CurrencyPipe implements PipeTransform {
  transform(value: number | string = 0, args?: any): any {
    value = value ? value : 0;
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' €';
  }

}
