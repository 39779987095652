import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'momentPipe',
  pure: false,
})
export class MomentPipe implements PipeTransform {

    transform(date, format) {
      return moment(date, 'YYYY-MM-DDTHH:mm:ss').format(format);
    }
}
