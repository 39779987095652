import { Injectable } from '@angular/core';

@Injectable()

export class UtilitiesService {

  constructor() { }

  /**
   * Get id from link
   *
   * @param {string} link
   * @returns {string}
   * @memberof UtilitiesService
   */
  public getIdFromLink(link: string): string {
    let splitArray: Array<any> = link.split('/');

    return splitArray[splitArray.length - 1];
  }

  /**
   * To underscore
   *
   * @param {*} string
   * @returns {string}
   * @memberof UtilitiesService
   */
  public toUnderscore(string): string {
    return string.replace(/([A-Z])/g, function($1) {return '_' + $1.toLowerCase(); });
  };

  /**
   * Convert to underscore
   *
   * @param {*} object
   * @returns {Object}
   * @memberof UtilitiesService
   */
  public convertToUnderscore(object): Object {
    let result = <any>{};

    Object.keys(object).forEach(key => {
        let underscoreKey = this.toUnderscore(key);
        result[underscoreKey] = object[key];
    });

    return result;
  }

  /**
   * Convert collection
   *
   * @param {*} array
   * @returns {Array<any>}
   * @memberof UtilitiesService
   */
  public convertCollection(array): Array<any> {
    let result = [];
    array.forEach(item => {
      result.push(this.convertToUnderscore(item));
    });

    return result;
  }
}
