import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { URLSearchParams } from '@angular/http';
import { Angulartics2 } from 'angulartics2';

import { AppComponent } from '../app.component';

import { environment } from '../../environments/environment';

import { User } from '../resource/user/user.resource';

import { AuthService } from '../service/auth.service';
import { UserService  } from '../service/user/user.service';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loading: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    public  appComponent: AppComponent,
    public  angulartics: Angulartics2,
    private customToastrService: CustomToastrService
  ) {}

  googleLoginPage() {
    window.location.href = environment.openid_authorize_endpoint
                         + '?response_type=code'
                         + '&scope=' + environment.openid_scope
                         + '&client_id=' + environment.openid_client_id
                         + '&redirect_uri=' + window.location.href;
  }

  getAuthCode() {
    let params = new URLSearchParams(window.location.search);
    let code = params.get('?code');

    if (code) {
      this.loading = true;
      this.authService.getToken(code)
        .subscribe((isGotToken: boolean) => {
          this.loading = false;

          if (isGotToken) {
            this.router.navigate(['/']);
            this.appComponent.checkIsLoggedIn();
            this.userService.getUser().subscribe(user => {
              this.userService.setUserConnected(user);
              if (!this.isUserGroupAuthorized(user)) {
                  this.logout();
              } else {
                    // track connection with or without Active Directory groups
                    if (user.groups && typeof user.groups === 'string') {
                      // without developers for staging and production
                      if (!user.groups.split(',').includes('dev') && environment.api_base_url.indexOf('dev') <= 0 ) {
                          this.angulartics.eventTrack.next({
                            action: 'Connection',
                            properties: { category: 'Login', label: 'Connected. Connection of ' + user.groups }
                          });
                      } else {
                          this.angulartics.eventTrack.next({
                            action: 'Connection',
                            properties: { category: 'Login', label: 'Connected. Connection with dev groups' }
                          });
                      }
                    } else {
                        this.angulartics.eventTrack.next({
                          action: 'Connection',
                          properties: { category: 'Login', label: 'Connected. Connection without groups' }
                        });
                    }

                    this.customToastrService.displayToastr('SUCCESS', 'Vous êtes à présent connecté.\nBienvenue ' + user.name);
              }
            }, () => {
              this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            });
          } else {
            this.angulartics.eventTrack.next({
              action: 'ConnectionError',
              properties: { category: 'Login', label: 'Impossible to connect' }
            });
          }
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });
    }
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/']);
    this.appComponent.checkIsLoggedIn();
    this.customToastrService.displayToastr('WARNING', 'Vous êtes déconnecté de votre session.\nDeconnexion');
    this.angulartics.eventTrack.next({
      action: 'Disconnection',
      properties: { category: 'Logout', label: 'Disconnected' }
    });

    return false;
  }

  ngOnInit() {
    if (!this.authService.isLoggedIn()) {
      this.getAuthCode();
    }
  }

  /**
   * Check if user is linked to authorized group
   * @param user
   * @private
   */
  private isUserGroupAuthorized(user: User): boolean {
    let isUserGroupAuthorized = false;

    const allowedGroups = [
      'dev',
      'marketing',
      'planning',
      'commerciaux-dotation',
      'commerciaux-outremer',
      'commerciaux-parrainage',
      'ADV'
    ];

    if (user.groups) {
      let userGroups = user.groups.split(',');

      isUserGroupAuthorized = allowedGroups.some(group => {
        return userGroups.includes(group);
      });
    }

    return isUserGroupAuthorized;
  }
}
