<app-spinner *ngIf="loading" class="spinner-form" type="info" size="small" alt="Chargement de la grille ..."></app-spinner>

<div class="grid" [ngClass]="{'grid-offer': mode, 'margin-bottom-60': lastProgram}" #gridElement *ngIf="!loading">
    <app-grid-header [header]="header" [headerMonth]="headerMonth"></app-grid-header>
    <app-grid-warning
        [broadcastsTimes]="broadcastsTimes"
        [mode]="mode"
        [schedules]="schedules">
    </app-grid-warning>

    <table>
        <tr *ngFor="let row of gridData[0]; let i = index;">
            <td *ngFor="let column of gridData; let columnIndex = index; first as isFirst; last as isLast"
                class="cell {{column[i]?.type}}"
                [ngClass]="{'hover': column[i].information && (columnIndex === index), 'size-case': !mode, 'size-case-offer': mode}"
                [id]="offerProgram.program.id + columnIndex"
                (click)="clickOnCell(column[i], columnIndex)">
                <span *ngIf="row.soReach && isFirst" class="soReach-span">{{ row.soReach }}</span>
                <!--DON'T REMOVE NEXT STEP TODO-->
                <!--<img *ngIf="row.soReach && isFirst" src="assets/images/logos/SOREACHPLUS.svg" class="soReach-icon" [matTooltip]="row.soReach" matTooltipClass="styleTooltip">-->
                <mat-icon *ngIf="column[i].isCompletelyChecked"[ngClass]="{'checked-offer': column[i].isConcurrence}" class="checked">done_all</mat-icon>
                <mat-icon *ngIf="column[i].isPartiallyChecked" class="checked">done</mat-icon>

                <div *ngIf="column[i].isConcurrence" class="module-annonceur">A</div>

                <span
                    *ngIf="column[i].information && !column[i].isSoReach"
                    class="advertiser case-information"
                    (mouseenter)="enter(column[i].information, columnIndex)"
                    [ngStyle]="{'width': (column[i].duration * cellWidth) + 'px', 'max-width': (column[i].duration * cellWidth) + 'px'}">
                </span>

                <span
                    *ngIf="!column[i].information && !column[i].isSoReach"
                    class="advertiser case-information"
                    (mouseenter)="leave()"
                    [ngStyle]="{'width': (column[i].duration * cellWidth) + 'px', 'max-width': (column[i].duration * cellWidth) + 'px'}">
                </span>

                <a
                    *ngIf="column[i].duration && !column[i].isSoReach"
                    [routerLink]="['/purchases', 'edit', column[i].info.purchase.id]"
                    class="advertiser pointer"
                    target="_blank"
                    style="height: 40px"
                    [ngStyle]="{'width': (column[i].duration * cellWidth) + 'px', 'max-width': (column[i].duration * cellWidth) + 'px'}"
                    [matTooltip]="column[i].tooltip.toString()"
                    matTooltipClass="styleTooltip">

                    <a class="purchaselink" style="text-decoration: none;">
                        <span *ngIf="column[i].hasGameModule == 1" class="module-game">MJ</span>

                        <span *ngIf="column[i].info.purchase._embedded.product" class="white">
                            {{ column[i].info.purchase._embedded.product.name | uppercase }}
                        </span>

                        <span *ngIf="column[i].info.purchase.temporary_product" class="white">
                            {{ column[i].info.purchase.temporary_product | uppercase }} <br>
                        </span>

                        <span *ngIf="column[i].info.purchase._embedded.secodip" class="white">
                            {{ column[i].info.purchase._embedded.secodip.name | lowercase | capitalize }}
                        </span>
                    </a>
                </a>
                <a *ngIf="column[i].duration && column[i].isSoReach && isFirst"
                   [ngStyle]="{'width': (column[i].duration * cellWidth) + 'px', 'max-width': (column[i].duration * cellWidth) + 'px'}"
                   [matTooltip]="column[i].programName"
                   matTooltipClass="styleTooltip"
                   class="advertiser">
                  <span class="black">{{ column[i].programName | uppercase }}</span>
                </a>
            </td>
        </tr>
    </table>
    <table *ngIf="newGrpOffer && newGrpOffer.length" class="no-border">
        <tr class="no-border inline-flex" fxLayout="row">
            <td class="sub-headerGrp no-border" fxLayout="column" fxLayoutAlign="center center" *ngFor="let cell of newGrpOffer">
                <mat-icon class="size-grp hold-color">track_changes</mat-icon>
                <span class="hold-color">{{cell.GRPA}}</span>
            </td>
        </tr>
    </table>
</div>

<app-grid-information-available
    *ngIf="mode && informationDisplay"
    style="box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);"
    (closeToltip)="leave()"
    [informationOffer]="informationDisplay">
</app-grid-information-available>
