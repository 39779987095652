<div>
  <mat-progress-bar mode="indeterminate" color="accent" *ngIf="loading">
  </mat-progress-bar>
  <mat-card class="form-card">
    <mat-card-title>
      <h2>Proposition globale</h2>
      <!-- Dossier Compensation -->
      <div [formGroup]="compensationFormGroup"  fxLayout="column">
        <span class="text-size thin">Si cette proposition est réalisée dans le cadre d'une compensation, veuillez renseigner le nom du dossier à compenser :</span>
        <mat-form-field class="input-widht">
          <input appRemoveAccents matInput placeholder="Dossier" (click)="checkIfCompensation()" [matAutocomplete]="compensationPurchaseAutocomplete"
            formControlName="compensationPurchase">
        </mat-form-field>
        <mat-autocomplete #compensationPurchaseAutocomplete="matAutocomplete" [displayWith]="autocompleteService.displayPurchaseWithYear.bind(this)">
          <mat-option *ngFor="let compensationPurchase of filteredCompensationPurchaseItems | async" [value]="compensationPurchase">
            {{ compensationPurchase.entity.getComunicationPeriodYear() }} {{ compensationPurchase.display }}
          </mat-option>
        </mat-autocomplete>
      </div>
    </mat-card-title>
    <mat-card-content [formGroup]="purchaseGlobalBudgetGroup">
      <div [ngClass]="{'loading': loading}">
        <table>
          <thead>
            <tr class="global-budget-header">
              <th class="title-table thin">Budget de base parrainage</th>
              <th class="title-table thin">Abattements</th>
              <th class="title-table thin">Budget offre</th>
              <th class="title-table thin">Négociation</th>
              <th class="title-table thin">Budget négociés</th>
            </tr>
          </thead>
          <tbody>
            <!-- Total budget -->
            <tr formGroupName="tv" class="table-color">
              <td class="bold padding-left-25"> BUDGET TV</td>
              <td></td>
              <td formGroupName="tvBudget">
                <input
                  matInput
                  class="hold-strong-color bold"
                  formControlName="tvBudget"
                  ngDefaultControl
                  min="0"
                  readonly="true"
                  appThousandsNumber>
                <span class="hold-strong-color bold">&nbsp;€</span>
                <span class="hold-strong-color bold">&nbsp;{{budgetType}}</span>
              </td>
              <td formGroupName="tvBudget">
                <input
                  matInput
                  formControlName="tvBudgetRate"
                  ngDefaultControl
                  type="number"
                  min="-100">
                <span>&nbsp;%</span>
              </td>
              <td formGroupName="tvBudget">
                  <input
                    matInput
                    formControlName="tvBudgetNet"
                    ngDefaultControl
                    min="0"
                    appThousandsNumber>
                  <span>&nbsp;€</span>
                  <span>&nbsp;NET</span>
              </td>
            </tr>
            <!-- FTP -->
            <tr formGroupName="tv">
              <td class="table-detail thin">FTP National</td>
              <td fxLayoutGap="10px">
                <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center">
                  <mat-icon (click)="openAbatementDialog('F')" class="pointer">remove_red_eye</mat-icon>
                  <span (click)="openAbatementDialog('F')" class="pointer underline" *ngFor="let purchasedAbatement of purchasedAbatClassic | async">
                    {{ purchasedAbatement.abatement.code }} ({{ purchasedAbatement.rate }}%)
                  </span>
                </div>
              </td>
              <td formGroupName="tvClassic">
                  <input
                    matInput
                    class="hold-color forminput"
                    formControlName="tvClassicBudget"
                    ngDefaultControl
                    min="0"
                    readonly="true"
                    appThousandsNumber>
                  <span class="hold-color">&nbsp;€</span>
                  <span class="hold-color">&nbsp;{{budgetType | lowercase}}</span>
              </td>
              <td formGroupName="tvClassic">
                  <input
                    matInput
                    class="forminput thin"
                    formControlName="tvClassicBudgetRate"
                    ngDefaultControlclass="forminput"
                    type="number"
                    min="-100">
                  <span class="thin">&nbsp;%</span>
              </td>
              <td formGroupName="tvClassic">
                  <input
                    matInput
                    formControlName="tvClassicBudgetNet"
                    ngDefaultControl
                    class="forminput thin"
                    appThousandsNumber
                    min="0">
                  <span class="thin">&nbsp;€</span>
                  <span class="thin">&nbsp;net</span>
              </td>
            </tr>
            <!-- FTP France 4 -->
            <tr *ngIf="this.purchaseYear >= '2023'" formGroupName="tv">
              <td class="table-detail thin">FTP France 4</td>
              <td fxLayoutGap="10px">
                <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center">
                  <mat-icon (click)="openAbatementDialog('F4')" class="pointer">remove_red_eye</mat-icon>
                  <span (click)="openAbatementDialog('F4')" class="pointer underline" *ngFor="let purchasedAbatement of purchasedAbatF4 | async">
                    {{ purchasedAbatement.abatement.code }} ({{ purchasedAbatement.rate }}%)
                  </span>
                </div>
              </td>
              <td formGroupName="tvF4">
                <input
                  matInput
                  class="hold-color forminput"
                  formControlName="tvF4Budget"
                  ngDefaultControl
                  min="0"
                  appThousandsNumber
                  readonly="true">
                <span class="hold-color">&nbsp;€</span>
                <span class="hold-color">&nbsp;{{budgetType | lowercase}}</span>
              </td>
              <td formGroupName="tvF4">
                <input
                  matInput
                  formControlName="tvF4BudgetRate"
                  ngDefaultControl
                  class="forminput thin"
                  type="number"
                  min="-100">
                <span class="thin">&nbsp;%</span>
              </td>
              <td formGroupName="tvF4">
                <input
                  matInput
                  formControlName="tvF4BudgetNet"
                  ngDefaultControl
                  class="forminput thin"
                  appThousandsNumber
                  min="0">
                <span class="thin">&nbsp;€</span>
                <span class="thin">&nbsp;net</span>
              </td>
            </tr>
            <!-- FTP regionnal -->
            <tr formGroupName="tv">
              <td class="table-detail thin">FTP régions</td>
              <td fxLayoutGap="10px">
                  <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center">
                    <mat-icon (click)="openAbatementDialog('FREG')" class="pointer">remove_red_eye</mat-icon>
                    <span (click)="openAbatementDialog('FREG')" class="pointer underline" *ngFor="let purchasedAbatement of purchasedAbatRegion | async">
                      {{ purchasedAbatement.abatement.code }} ({{ purchasedAbatement.rate }}%)
                    </span>
                  </div>
              </td>
              <td formGroupName="tvRegion">
                  <input
                    matInput
                    class="hold-color forminput"
                    formControlName="tvRegionBudget"
                    ngDefaultControl
                    min="0"
                    appThousandsNumber
                    readonly="true">
                    <span class="hold-color">&nbsp;€</span>
                    <span class="hold-color">&nbsp;{{budgetType | lowercase}}</span>
              </td>
              <td formGroupName="tvRegion">
                  <input
                    matInput
                    formControlName="tvRegionBudgetRate"
                    ngDefaultControl
                    class="forminput thin"
                    type="number"
                    min="-100">
                    <span class="thin">&nbsp;%</span>
              </td>
              <td formGroupName="tvRegion">
                  <input
                    matInput
                    formControlName="tvRegionBudgetNet"
                    ngDefaultControl
                    class="forminput thin"
                    appThousandsNumber
                    min="0">
                    <span class="thin">&nbsp;€</span>
                    <span class="thin">&nbsp;net</span>
              </td>
            </tr>
            <!-- FTP dom-tom -->
            <tr formGroupName="tv">
              <td class="table-detail thin">FTP Outre-Mer</td>
              <td fxLayoutGap="10px">
                <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center">
                  <mat-icon (click)="openAbatementDialog('O')" class="pointer">remove_red_eye</mat-icon>
                  <span (click)="openAbatementDialog('O')" class="pointer underline" *ngFor="let purchasedAbatement of purchasedAbatDomTom | async">
                    {{ purchasedAbatement.abatement.code }} ({{ purchasedAbatement.rate }}%)
                  </span>
                </div>
              </td>
              <td formGroupName="tvDomtom">
                  <input
                    matInput
                    class="hold-color forminput"
                    formControlName="tvDomtomBudget"
                    ngDefaultControl
                    min="0"
                    appThousandsNumber
                    readonly="true">
                    <span class="hold-color">&nbsp;€</span>
                    <span class="hold-color">&nbsp;{{budgetType | lowercase}}</span>
              </td>
              <td formGroupName="tvDomtom">
                  <input
                    matInput
                    formControlName="tvDomtomBudgetRate"
                    ngDefaultControl
                    class="forminput thin"
                    type="number"
                    min="-100">
                    <span class="thin">&nbsp;%</span>
              </td>
              <td formGroupName="tvDomtom">
                  <input
                    matInput
                    formControlName="tvDomtomBudgetNet"
                    ngDefaultControl
                    class="forminput thin"
                    appThousandsNumber
                    min="0">
                    <span class="thin">&nbsp;€</span>
                    <span class="thin">&nbsp;net</span>
              </td>
            </tr>
            <!-- FTP Thematic -->
            <tr formGroupName="tv">
              <td class="table-detail thin">FTP thématique</td>
              <td fxLayoutGap="10px">
                <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center">
                  <mat-icon (click)="openAbatementDialog('W')" class="pointer">remove_red_eye</mat-icon>
                  <span (click)="openAbatementDialog('W')" class="pointer underline" *ngFor="let purchasedAbatement of purchasedAbatThema | async">
                    {{ purchasedAbatement.abatement.code }} ({{ purchasedAbatement.rate }}%)
                  </span>
                </div>
              </td>
              <td formGroupName="tvThema">
                  <input
                    matInput
                    class="hold-color forminput"
                    formControlName="tvThemaBudget"
                    ngDefaultControl
                    min="0"
                    appThousandsNumber
                    readonly="true">
                    <span class="hold-color">&nbsp;€</span>
                    <span class="hold-color">&nbsp;{{budgetType | lowercase}}</span>
              </td>
              <td formGroupName="tvThema">
                  <input
                    matInput
                    formControlName="tvThemaBudgetRate"
                    ngDefaultControl
                    class="forminput thin"
                    type="number"
                    min="-100">
                    <span class="thin">&nbsp;%</span>
              </td>
              <td formGroupName="tvThema">
                  <input
                    matInput
                    formControlName="tvThemaBudgetNet"
                    ngDefaultControl
                    class="forminput thin"
                    appThousandsNumber
                    min="0">
                    <span class="thin">&nbsp;€</span>
                    <span class="thin">&nbsp;net</span>
              </td>
            </tr>
            <!-- FTP International -->
            <tr formGroupName="tv">
              <td class="table-detail thin">FTP international</td>
              <td fxLayoutGap="10px">
                <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center">
                  <mat-icon (click)="openAbatementDialog('I')" class="pointer">remove_red_eye</mat-icon>
                  <span (click)="openAbatementDialog('I')" class="pointer underline" *ngFor="let purchasedAbatement of purchasedAbatInter | async">
                    {{ purchasedAbatement.abatement.code }} ({{ purchasedAbatement.rate }}%)
                  </span>
                </div>
              </td>
              <td formGroupName="tvInter">
                  <input
                    matInput
                    class="hold-color forminput"
                    formControlName="tvInterBudget"
                    ngDefaultControl
                    min="0"
                    appThousandsNumber
                    readonly="true">
                    <span class="hold-color">&nbsp;€</span>
                    <span class="hold-color">&nbsp;{{budgetType | lowercase}}</span>
              </td>
              <td formGroupName="tvInter">
                  <input
                    matInput
                    formControlName="tvInterBudgetRate"
                    ngDefaultControl
                    class="forminput thin"
                    type="number"
                    min="-100">
                    <span class="thin">&nbsp;%</span>
              </td>
              <td formGroupName="tvInter">
                  <input
                    matInput
                    formControlName="tvInterBudgetNet"
                    ngDefaultControl
                    class="forminput thin"
                    appThousandsNumber
                    min="0">
                    <span class="thin">&nbsp;€</span>
                    <span class="thin">&nbsp;net</span>
              </td>
            </tr>
            <tr formGroupName="digital" class="table-color">
              <td class="bold padding-left-25">BUDGET DIGITAL</td>
              <td></td>
              <td formGroupName="digitalBudget">
                  <input
                    matInput
                    formControlName="digitalBudget"
                    ngDefaultControl
                    class="forminput hold-strong-color bold"
                    min="0"
                    appThousandsNumber
                    readonly="true">
                    <span class="hold-strong-color">&nbsp;€</span>
                    <span class="hold-strong-color">&nbsp;{{budgetType}}</span>
              </td>
              <td formGroupName="digitalBudget">
                  <input
                    matInput
                    formControlName="digitalBudgetRate"
                    ngDefaultControl
                    class="forminput"
                    type="number"
                    min="-100">
                    <span>&nbsp;%</span>
              </td>
              <td formGroupName="digitalBudget">
                  <input
                    matInput
                    formControlName="digitalBudgetNet"
                    ngDefaultControl
                    class="forminput"
                    appThousandsNumber
                    min="0">
                    <span>&nbsp;€</span>
                    <span>&nbsp;NET</span>
              </td>
            </tr>
            <tr formGroupName="digital" class="border-top-bot">
              <td class="table-detail thin">Display</td>
                <td></td>
                <td formGroupName="digitalDisplay">
                      <input
                        matInput
                        formControlName="digitalDisplayBudget"
                        ngDefaultControl
                        class="forminput thin"
                        min="0"
                        appThousandsNumber
                        (focus)="setFocus('digitalDisplayBudget')">
                        <span class="thin">&nbsp;€</span>
                        <span class="thin">&nbsp;{{budgetType | lowercase}}</span>
                </td>
                <td formGroupName="digitalDisplay">
                    <input
                      matInput
                      formControlName="digitalDisplayBudgetRate"
                      ngDefaultControl
                      class="forminput thin"
                      type="number"
                      min="-100">
                      <span class="thin">&nbsp;%</span>
                </td>
                <td formGroupName="digitalDisplay">
                    <input
                      matInput
                      formControlName="digitalDisplayBudgetNet"
                      ngDefaultControl
                      class="forminput thin"
                      min="0"
                      appThousandsNumber
                      (focus)="setFocus('digitalDisplayBudgetNet')">
                      <span class="thin">&nbsp;€</span>
                      <span class="thin">&nbsp;net</span>
                </td>
            </tr>
            <tr formGroupName="digital" class="border-top-bot">
              <td (click)="expandSubCategories('video')" class="table-detail thin">Vidéo
              <mat-icon class="arrow" *ngIf="!isVideoExpanded">keyboard_arrow_down</mat-icon>
              <mat-icon class="arrow" *ngIf="isVideoExpanded">keyboard_arrow_up</mat-icon>
              </td>
                <td></td>
                <td formGroupName="digitalReplay">
                    <input
                      matInput
                      formControlName="digitalReplayBudget"
                      ngDefaultControl
                      class="forminput thin hold-strong-color bold"
                      min="0"
                      readonly= true
                      appThousandsNumber
                      (focus)="setFocus('digitalReplayBudget')">
                      <span class="thin hold-strong-color bold">&nbsp;€</span>
                      <span class="thin hold-strong-color bold">&nbsp;{{budgetType | lowercase}}</span>
                </td>
                <td formGroupName="digitalReplay">
                  <input
                    matInput
                    formControlName="digitalReplayBudgetRate"
                    ngDefaultControl
                    class="forminput thin hold-strong-color bold"
                    type="number"
                    min="-100">
                    <span class="thin hold-strong-color bold">&nbsp;%</span>
              </td>
              <td formGroupName="digitalReplay">
                  <input
                    matInput
                    formControlName="digitalReplayBudgetNet"
                    ngDefaultControl
                    class="forminput thin hold-strong-color bold"
                    min="0"
                    appThousandsNumber
                    (focus)="setFocus('digitalReplayBudgetNet')">
                    <span class="thin hold-strong-color bold">&nbsp;€</span>
                    <span class="thin hold-strong-color bold">&nbsp;net</span>
              </td>
              </tr>
              <tr [hidden]="isVideoHidden" formGroupName="digital" class="border-top-bot">
                  <td class="table-detail thin category">Billboard</td>
                  <td></td>
                  <td formGroupName="digitalVideoBill">
                      <input
                        matInput
                        formControlName="digitalVideoBillBudget"
                        ngDefaultControl
                        class="forminput thin"
                        min="0"
                        appThousandsNumber
                        (focus)="setFocus('digitalVideoBillBudget')">
                        <span class="thin">&nbsp;€</span>
                        <span class="thin">&nbsp;{{budgetType | lowercase}}</span>
                  </td>
                  <td formGroupName="digitalVideoBill">
                    <input
                      matInput
                      formControlName="digitalVideoBillBudgetRate"
                      ngDefaultControl
                      class="forminput thin"
                      type="number"
                      min="-100">
                      <span class="thin">&nbsp;%</span>
                </td>
                <td formGroupName="digitalVideoBill">
                    <input
                      matInput
                      formControlName="digitalVideoBillBudgetNet"
                      ngDefaultControl
                      class="forminput thin"
                      min="0"
                      appThousandsNumber
                      (focus)="setFocus('digitalVideoBillBudgetNet')">
                      <span class="thin">&nbsp;€</span>
                      <span class="thin">&nbsp;net</span>
                </td>
              </tr>
                <tr [hidden]="isVideoHidden" formGroupName="digital" class="border-top-bot">
                  <td class="table-detail thin category">Préroll</td>
                    <td></td>
                    <td formGroupName="digitalVideoPre">
                        <input
                          matInput
                          formControlName="digitalVideoPreBudget"
                          ngDefaultControl
                          class="forminput thin"
                          min="0"
                          appThousandsNumber
                          (focus)="setFocus('digitalVideoPreBudget')">
                          <span class="thin">&nbsp;€</span>
                          <span class="thin">&nbsp;{{budgetType | lowercase}}</span>
                    </td>
                    <td formGroupName="digitalVideoPre">
                      <input
                        matInput
                        formControlName="digitalVideoPreBudgetRate"
                        ngDefaultControl
                        class="forminput thin"
                        type="number"
                        min="-100">
                        <span class="thin">&nbsp;%</span>
                  </td>
                  <td formGroupName="digitalVideoPre">
                      <input
                        matInput
                        formControlName="digitalVideoPreBudgetNet"
                        ngDefaultControl
                        class="forminput thin"
                        min="0"
                        appThousandsNumber
                        (focus)="setFocus('digitalVideoPreBudgetNet')">
                        <span class="thin">&nbsp;€</span>
                        <span class="thin">&nbsp;net</span>
                  </td>
            </tr>
            <tr formGroupName="digital" class="border-top-bot">
              <td (click)="expandSubCategories('social')" class="table-detail thin">Social
              <mat-icon class="arrow" *ngIf="!isSocialExpanded">keyboard_arrow_down</mat-icon>
              <mat-icon class="arrow" *ngIf="isSocialExpanded">keyboard_arrow_up</mat-icon></td>
                <td></td>
                <td formGroupName="digitalSocial">
                    <input
                      matInput
                      formControlName="digitalSocialBudget"
                      ngDefaultControl
                      class="forminput thin hold-strong-color bold"
                      min="0"
                      readonly= true
                      appThousandsNumber
                      (focus)="setFocus('digitalSocialBudget')">
                      <span class="thin hold-strong-color bold">&nbsp;€</span>
                      <span class="thin hold-strong-color bold">&nbsp;{{budgetType | lowercase}}</span>
                </td>
                <td formGroupName="digitalSocial">
                  <input
                    matInput
                    formControlName="digitalSocialBudgetRate"
                    ngDefaultControl
                    class="forminput thin hold-strong-color bold"
                    type="number"
                    min="-100">
                    <span class="thin hold-strong-color bold">&nbsp;%</span>
              </td>
              <td formGroupName="digitalSocial">
                  <input
                    matInput
                    formControlName="digitalSocialBudgetNet"
                    ngDefaultControl
                    class="forminput thin hold-strong-color bold"
                    min="0"
                    appThousandsNumber
                    (focus)="setFocus('digitalSocialBudgetNet')">
                    <span class="thin hold-strong-color bold">&nbsp;€</span>
                    <span class="thin hold-strong-color bold">&nbsp;net</span>
              </td>
            </tr>
            <tr [hidden]="isSocialHidden" formGroupName="digital" class="border-top-bot">
                <td class="table-detail thin category">Facebook</td>
                <td></td>
                <td formGroupName="digitalSocialFb">
                    <input
                      matInput
                      formControlName="digitalSocialFbBudget"
                      ngDefaultControl
                      class="forminput thin"
                      min="0"
                      appThousandsNumber
                      (focus)="setFocus('digitalSocialFbBudget')">
                      <span class="thin">&nbsp;€</span>
                      <span class="thin">&nbsp;{{budgetType | lowercase}}</span>
                </td>
                <td formGroupName="digitalSocialFb">
                  <input
                    matInput
                    formControlName="digitalSocialFbBudgetRate"
                    ngDefaultControl
                    class="forminput thin"
                    type="number"
                    min="-100">
                    <span class="thin">&nbsp;%</span>
              </td>
              <td formGroupName="digitalSocialFb">
                  <input
                    matInput
                    formControlName="digitalSocialFbBudgetNet"
                    ngDefaultControl
                    class="forminput thin"
                    min="0"
                    appThousandsNumber
                    (focus)="setFocus('digitalSocialFbBudgetNet')">
                    <span class="thin">&nbsp;€</span>
                    <span class="thin">&nbsp;net</span>
              </td>
            </tr>
            <tr [hidden]="isSocialHidden" formGroupName="digital" class="border-top-bot">
                <td class="table-detail thin category">Twitter</td>
                <td></td>
                <td formGroupName="digitalSocialTwit">
                    <input
                      matInput
                      formControlName="digitalSocialTwitBudget"
                      ngDefaultControl
                      class="forminput thin"
                      min="0"
                      appThousandsNumber
                      (focus)="setFocus('digitalSocialTwitBudget')">
                      <span class="thin">&nbsp;€</span>
                      <span class="thin">&nbsp;{{budgetType | lowercase}}</span>
                </td>
                <td formGroupName="digitalSocialTwit">
                  <input
                    matInput
                    formControlName="digitalSocialTwitBudgetRate"
                    ngDefaultControl
                    class="forminput thin"
                    type="number"
                    min="-100">
                    <span class="thin">&nbsp;%</span>
              </td>
              <td formGroupName="digitalSocialTwit">
                  <input
                    matInput
                    formControlName="digitalSocialTwitBudgetNet"
                    ngDefaultControl
                    class="forminput thin"
                    min="0"
                    appThousandsNumber
                    (focus)="setFocus('digitalSocialTwitBudgetNet')">
                    <span class="thin">&nbsp;€</span>
                    <span class="thin">&nbsp;net</span>
              </td>
            </tr>
            <tr [hidden]="isSocialHidden" formGroupName="digital" class="border-top-bot">
                <td class="table-detail thin category">Instagram</td>
                <td></td>
                <td formGroupName="digitalSocialInsta">
                    <input
                      matInput
                      formControlName="digitalSocialInstaBudget"
                      ngDefaultControl
                      class="forminput thin"
                      min="0"
                      appThousandsNumber
                      (focus)="setFocus('digitalSocialInstaBudget')">
                      <span class="thin">&nbsp;€</span>
                      <span class="thin">&nbsp;{{budgetType | lowercase}}</span>
                </td>
                <td formGroupName="digitalSocialInsta">
                  <input
                    matInput
                    formControlName="digitalSocialInstaBudgetRate"
                    ngDefaultControl
                    class="forminput thin"
                    type="number"
                    min="-100">
                    <span class="thin">&nbsp;%</span>
              </td>
              <td formGroupName="digitalSocialInsta">
                  <input
                    matInput
                    formControlName="digitalSocialInstaBudgetNet"
                    ngDefaultControl
                    class="forminput thin"
                    min="0"
                    appThousandsNumber
                    (focus)="setFocus('digitalSocialInstaBudgetNet')">
                    <span class="thin">&nbsp;€</span>
                    <span class="thin">&nbsp;net</span>
              </td>
            </tr>
            <tr formGroupName="digital">
              <td class="table-detail thin"> Autres</td>
              <td></td>
              <td formGroupName="digitalOther">
                  <input
                    matInput
                    formControlName="digitalOtherBudget"
                    ngDefaultControl
                    class="forminput thin"
                    min="0"
                    appThousandsNumber
                    (focus)="setFocus('digitalOtherBudget')">
                    <span class="thin">&nbsp;€</span>
                    <span class="thin">&nbsp;{{budgetType | lowercase}}</span>
              </td>
              <td formGroupName="digitalOther">
                  <input
                    matInput
                    formControlName="digitalOtherBudgetRate"
                    ngDefaultControl
                    class="forminput thin"
                    type="number"
                    min="-100">
                    <span class="thin">&nbsp;%</span>
              </td>
              <td formGroupName="digitalOther">
                  <input
                    matInput
                    formControlName="digitalOtherBudgetNet"
                    ngDefaultControl
                    class="forminput thin"
                    min="0"
                    appThousandsNumber
                    (focus)="setFocus('digitalOtherBudgetNet')">
                    <span class="thin">&nbsp;€</span>
                    <span class="thin">&nbsp;net</span>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr formGroupName="total" class="table-color">
              <td class="bold padding-left-25">CA NET AVANT REMISE</td>
              <td></td>
              <td>
                  <input
                    matInput
                    formControlName="totalBudget"
                    ngDefaultControl
                    class="forminput hold-strong-color bold"
                    min="0"
                    appThousandsNumber
                    readonly="true">
                    <span class="hold-strong-color">&nbsp;€</span>
                    <span class="hold-strong-color">&nbsp;{{budgetType}}</span>
              </td>
              <td>
                  <input
                    matInput
                    formControlName="totalBudgetRate"
                    ngDefaultControl
                    class="forminput"
                    type="number"
                    min="-100">
                    <span>&nbsp;%</span>
              </td>
              <td>
                  <input
                    matInput
                    formControlName="totalBudgetNet"
                    ngDefaultControl
                    class="forminput"
                    appThousandsNumber
                    min="0">
                    <span>&nbsp;€</span>
                    <span>&nbsp;NET</span>
              </td>
            </tr>
            <tr class="table-color">
              <td class="bold padding-left-25">RAPPEL DU BUDGET INDICATIF</td>
              <td></td>
              <td class="hold-strong-color bold" [ngClass]="{'customer-budget-canet':  budgetType === 'NET', 'customer-budget-caini' : budgetType !== 'NET'}">
                {{purchase.customerBudget || 0 | number:'0.0-2':'fr' | currency}}
              </td>
              <td></td>
              <td></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </mat-card-content>
  </mat-card>
</div>
