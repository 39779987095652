import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './private/dashboard/dashboard.component';
import { AvailabilityComponent } from './private/availability/availability/availability.component';

export const routes: Routes = [
  { path: '', component: DashboardComponent },
  { path: 'availabilities', component: AvailabilityComponent },
  { path: 'offers', loadChildren: './private/offer/offer.module#OfferModule'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
