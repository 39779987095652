import { animate, transition, state, trigger, style } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';

import { PropalCartService } from '@service/propal-cart/propal-cart.service';
import { PropalCartGenerateService } from '@service/propal-cart/propal-cart-generate.service';
import { PurchaseService } from '@service/purchase/purchase.service';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';

import { Broadcast } from '../../../resource/broadcast.resource';
import { PropalCart } from '../../../resource/availability/propal-cart.resource';
import { PurchaseParams } from '../../../resource/purchaseType.resource';

import { Purchase } from '../../../resource/purchase.resource';

import { environment } from '../../../../environments/environment';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-propal-cart',
  templateUrl: './propal-cart.component.html',
  styleUrls: ['./propal-cart.component.scss'],

  animations: [
    trigger('slideInOut', [

      state(PropalCart.STATE_NOT_CREATED, style({
        transform: 'translateY(100%)'
      })),

      state(PropalCart.STATE_OUT, style({
        transform: 'translateY(90%)'
      })),

      state(PropalCart.STATE_IN, style({
        transform: 'translateY(0)'
      })),

      transition('in => out', animate('800ms ease-in-out')),
      transition('out => in', animate('800ms ease-in-out')),
      transition('in => notCreated', animate('300ms ease-in-out')),
      transition('notCreated => out', animate('300ms ease-in-out'))
    ]),
  ]
})

export class PropalCartComponent implements OnInit, OnDestroy {

  @Input() availabilityMode: FormControl;
  @Input() propalToogleStatus;
  @Input() propalCart: PropalCart;
  @Input('purchaseParams') purchaseParams: PurchaseParams;
  @Output() propalToogleStatusEmitter = new EventEmitter();

  public propalState: string;
  public totalBroadcasts: Broadcast[] = [];
  public f3AreaTotalBroadcasts = 0; // total of broadcasts in the cart
  public programLabel: string;
  public broadcastLabel: string;

  public validMainInformation: boolean = false;
  public newPurchaseMainInformation: Purchase;

  public saving: boolean = false;
  public propalCartLoading: boolean;

  public createOrUpdateMessage: string = 'Créer une propale';

  public isF3AreaCart = false; // mode region
  public isDefaultCart = false; // mode offer/program

  public creatingPurchase: boolean = true;
  public updatingPurchase: boolean = false;


  // if production
  public isProduction: boolean = false;
  private previousDispoMode: string;

  private componentDestroyed$: Subject<any> = new Subject();


  constructor(
    private purchaseService: PurchaseService,
    private propalCartService: PropalCartService,
    private customToastrService: CustomToastrService,
    private propalCartGenerateService: PropalCartGenerateService
  ) {
    if (!this.availabilityMode && this.propalCartService.propalCart.offerPrograms.length) {
      this.purchaseParams = this.purchaseService.getPurchaseParams();
      this.resetLabels();
      this.propalCart = this.propalCartService.propalCart;
      this.isDefaultCart = true;
      this.propalCartLoading = false;
      this.updateLabels();
    }
  }

  ngOnInit() {
    this.initState();
    this.updateLabels();

    // update source for labels
    this.propalCartService.propalCartSource$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(propalCart => {
        if (propalCart) {
          this.isDefaultCart = true;
          this.propalCart = propalCart;
          this.propalCartLoading = false;
          this.updateLabels();
        }
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    // update F3 area cart source
    this.propalCartService.propalCartAreaSource$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(offerProgramToCart => {
        this.feedF3AreaPropalCart(offerProgramToCart);
        this.isF3AreaCart = true;
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    // update toggle state
    this.propalCartService.tooglePropalSource$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.togglePropal(data);
      }, error => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });

    // clean the cart when we change toggle
    this.availabilityMode.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((selectedDispoMode: string) => {
        if (((this.previousDispoMode === 'program' || this.previousDispoMode === 'offer' || !this.previousDispoMode) && selectedDispoMode === 'region') ||
          ((this.previousDispoMode === 'region' || !this.previousDispoMode) && (selectedDispoMode === 'program' || selectedDispoMode === 'offer')) ||
          (selectedDispoMode !== 'program' && selectedDispoMode !== 'offer' && selectedDispoMode !== 'region')) {
          this.propalCart = null;
          this.purchaseParams = this.purchaseService.getPurchaseParams();
          this.isF3AreaCart = false;
          this.isDefaultCart = false;
          this.resetLabels();

          if ((selectedDispoMode === 'program' || selectedDispoMode === 'offer') && this.propalCartService.propalCart.offerPrograms.length) {
            this.propalCart = this.propalCartService.propalCart;
            this.isDefaultCart = true;
            this.propalCartLoading = false;
            this.updateLabels();
          }
        }
        this.previousDispoMode = selectedDispoMode;
      });

    this.isProduction = environment.production;
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.unsubscribe();
  }

  public togglePropal(data: string = null) {
    if (!data) {
      this.propalState = this.propalState === PropalCart.STATE_IN ? PropalCart.STATE_OUT : PropalCart.STATE_IN;
    } else {
      this.propalState = data;
    }

    this.propalToogleStatus = !this.propalToogleStatus;
    this.propalToogleStatusEmitter.emit(this.propalToogleStatus);
  }

  public cancel(): void {
    this.propalCartService.resetPropalCart();
    this.saving = false;
    this.customToastrService.displayToastr('SUCCESS', 'Panier supprimé avec succès');
  }

  public save(): void {
    if (this.creatingPurchase && this.validMainInformation && this.newPurchaseMainInformation && (this.isDefaultCart || this.isF3AreaCart)) {
      this.saving = true;
      this.propalCartGenerateService.init(this.propalCart, this.newPurchaseMainInformation);
    } else if (this.updatingPurchase && this.validMainInformation && this.purchaseParams && this.isDefaultCart) {
      this.saving = true;
      this.propalCartGenerateService.updatePurchaseFromAvailability(this.propalCart, this.purchaseParams);
    }
  }

  /**
   * Delete program
   *
   * @param id {number}
   */
  public deleteOfferProgramFromF3AreaCart(id: number): void {
    const indexToDelete = this.propalCart.offerPrograms.findIndex((offerProgram) => {
      return offerProgram['offerProgramId'] === id;
    });

    if (indexToDelete >= 0) {
      this.f3AreaTotalBroadcasts = this.f3AreaTotalBroadcasts - this.propalCart.offerPrograms[indexToDelete].broadcasts.length;
      this.propalCart.offerPrograms.splice(indexToDelete, 1);
      // Remove propal cart if have 0 offerProgram
      if (this.propalCart['offerPrograms'].length === 0) {
        this.propalCart = null;
        this.propalState = PropalCart.STATE_NOT_CREATED;
      }
    }
  }

  /**
   * Create propal cart area and feed it
   *
   * @param propalCart
   */
  private feedF3AreaPropalCart(propalCart): void {
    // Create/Init propal cart
    if (!this.propalCart) {
      this.propalCart = new PropalCart();
      this.propalState = PropalCart.STATE_OUT;
    }

    // Init propal cart statement
    if (!this.propalCart['offerPrograms'].length) {
      this.propalCart['offerPrograms'] = [];
    }

    // Find offer/program
    const index = this.propalCart['offerPrograms'].findIndex((offerProgram) => {
      return offerProgram['offerProgramId'] === propalCart['offerProgramId'];
    });

    if (propalCart.checked && index >= 0) { // Add to the cart
      this.f3AreaTotalBroadcasts = this.f3AreaTotalBroadcasts + propalCart.broadcasts.length;
      this.propalCart['offerPrograms'][index]['broadcasts'] = [...this.propalCart['offerPrograms'][index]['broadcasts'], ...propalCart['broadcasts']]
    } else if (!propalCart.checked && index >= 0) { // Remove from the cart
      const broadcasts = [...propalCart['broadcasts']];

      // Remove each broadcast
      broadcasts.forEach((broadcast: Broadcast) => {
        this.propalCart['offerPrograms'][index]['broadcasts'] = this.propalCart['offerPrograms'][index]['broadcasts'].filter((broadcastPropal: Broadcast) => {
          return broadcastPropal.id !== broadcast.id;
        });
      });

      // Remove offer/program if have 0 broadcast
      if (this.propalCart['offerPrograms'][index]['broadcasts'].length === 0) {
        this.propalCart['offerPrograms'].splice(index, 1);
        // Remove propal cart if have 0 offerProgram
        if (this.propalCart['offerPrograms'].length === 0) {
          this.propalCart = null;
          this.propalState = PropalCart.STATE_NOT_CREATED;
        }
      }

      this.f3AreaTotalBroadcasts = this.f3AreaTotalBroadcasts - propalCart['broadcasts'].length;
    } else { // No offer/program founded push to init
      this.propalCart['offerPrograms'].push({...propalCart});
      this.f3AreaTotalBroadcasts = propalCart.broadcasts.length;
    }
  }

  /**
   * Init state of propal cart
   */
  private initState(): void {
    const initState = ((this.propalCart && this.propalCart.offerPrograms.length))
      ? PropalCart.STATE_OUT
      : PropalCart.STATE_NOT_CREATED;
    this.togglePropal(initState);

    if (this.purchaseParams) {
      this.createOrUpdateMessage = 'Mettre à jour le dossier';
      this.updatingPurchase = true;
      this.creatingPurchase = false;
    }
  }

  /**
   * Update labels
   */
  private updateLabels(): void {
    if (!this.propalCart) {
      return null;
    }

    this.totalBroadcasts = this.propalCart.getAllBroadcasts();
    this.programLabel = this.propalCart.offerPrograms.length > 1
      ? 'émissions'
      : 'émission';

    this.broadcastLabel = this.totalBroadcasts.length > 1
      ? 'diffusions'
      : 'diffusion';
  }

  /**
   * Reset labels
   */
  private resetLabels(): void {
    if (!this.propalCart) {
      this.totalBroadcasts = [];
      this.programLabel = 'émission';
      this.broadcastLabel = 'diffusion';
      this.propalState = PropalCart.STATE_OUT;
    }
  }
}
