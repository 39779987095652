<mat-list>
    <mat-list-item 
      *ngFor="let purchaseProgram of purchasePrograms; index as i"
      [ngClass]="{ 'active': isSelected(purchaseProgram) }"
      (click)="onSelect(purchaseProgram)">
  
      <img mat-list-avatar 
        *ngIf="purchaseProgram && purchaseProgram.area"
        [src]="purchaseProgram.area.channel.getFullPath()" 
        [alt]="purchaseProgram.area.channel.name"
        class="list-logo">
  
      <h4 matLine *ngIf="purchaseProgram.program"> {{ purchaseProgram.program.name}} </h4>
      <h4 matLine *ngIf="!purchaseProgram.program"> Nouveau programme </h4>

      <p matLine>
        <span class="list-subtitle" *ngIf="purchaseProgram.program"> 
            {{ purchaseProgram.program.category.name }} 
        </span>
      </p>

      <h4 matLine *ngIf="purchaseProgram.brutPrice" class="margin-top"> {{ purchaseProgram.brutPrice | number:'0.0-2':'fr' }}€ </h4>
      <h4 matLine *ngIf="!purchaseProgram.brutPrice" class="margin-top"> 0 € </h4>

      <div>
        <div>
          <!-- <mat-icon *ngIf="index != i" (click)="deletePurchaseProgram(purchaseProgram, i)" class="delete-offer-program">delete</mat-icon> -->
          <button mat-mini-fab *ngIf="index != i" (click)="deletePurchaseProgram(purchaseProgram, i)" class="delete-offer-program">
              <mat-icon>delete_outline</mat-icon>
          </button>
        </div>
        <div fxLayoutAlign="center center">
          <mat-icon *ngIf="listWarning && listWarning[purchaseProgram.id]" class="warning-color">warning</mat-icon>
        </div>
      </div>
      <app-spinner *ngIf="index === i" class="spinner-form delete-offer-program" type="warning" size="small"></app-spinner>
    </mat-list-item>
  </mat-list>