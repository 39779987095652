import { TimeLabelDirective } from './time-label/time-label.directive';
import { DefaultImageDirective } from './default-image/default-image.directive';
import { LowerCaseDirective } from './lower-case/lower-case.directive';
import { NgModule } from '@angular/core';
import { ThousandsSeparatorsDirective } from './thousands-separators.directive';
import { RemoveAccentsDirective } from './remove-accents/remove-accents.directive';
import { ThousandsNumberDirective } from './thousands-number/thousands-number.directive';
import { CutDecimalDirective } from './cut-decimal/cut-decimal.directive';
import { MoveBroadcastIsAuthorizedDirective } from './move-broadcast-is-authorized/move-broadcast-is-authorized.directive';


@NgModule({
    imports: [],
    declarations: [
      DefaultImageDirective,
      TimeLabelDirective,
      LowerCaseDirective,
      ThousandsSeparatorsDirective,
      RemoveAccentsDirective,
      ThousandsNumberDirective,
      CutDecimalDirective,
      MoveBroadcastIsAuthorizedDirective
    ],
    exports: [
      DefaultImageDirective,
      TimeLabelDirective,
      LowerCaseDirective,
      ThousandsSeparatorsDirective,
      RemoveAccentsDirective,
      ThousandsNumberDirective,
      CutDecimalDirective,
      MoveBroadcastIsAuthorizedDirective
    ]
})
export class DirectiveModule { }
