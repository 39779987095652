import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { PurchaseAbatementService } from '@service/purchase-abatement/purchase-abatement.service';
import { UtilitiesHandler } from '@service/utilities-handlers/utilitiesHandlers';
import { AbstractService } from '../abstract.service';

import { PurchaseProgram } from '../../resource/purchaseProgram.resource';
import { PurchaseParams } from '../../resource/purchaseType.resource';
import { PurchaseBudgetService } from '../purchase-budget/purchase-budget.service';
import { JsonPurchase, Purchase, JsonPurchaseDotation, PurchaseDotation } from '../../resource/purchase.resource';
import { Offer } from '../../resource/offer.resource';
import { FilteredItem } from '../../resource/filteredItem.resource';
import { environment } from '../../../environments/environment';

import { Observable, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import * as fileServer from 'file-saver';
import * as moment from 'moment';

import 'moment/locale/fr';

@Injectable()
export class PurchaseService extends AbstractService {
  private route: string = 'purchase';
  private filter: Object;
  public purchase: Purchase;
  private purchaseTypes: FilteredItem[];

  private purchaseSource = new Subject<Purchase>();
  public purchaseSource$ = this.purchaseSource.asObservable();

  private isOptionableSource = new Subject<boolean>();
  public isOptionableSource$ = this.isOptionableSource.asObservable();

  private isValidSource = new Subject<boolean>();
  public isValidSource$ = this.isValidSource.asObservable();

  private loadingSource = new Subject<boolean>();
  public loadingSource$ = this.loadingSource.asObservable();

  private purchaseProgramAdded = new Subject<PurchaseProgram>();
  public purchaseProgramAdded$ = this.purchaseProgramAdded.asObservable();

  private savePurchase = new Subject<string>();
  public savePurchase$ = this.savePurchase.asObservable();

  private offer: Offer = null;

  private token: string = '';
  private headers: HttpHeaders;

  private paramPurchase: PurchaseParams = null;

  constructor(
    private purchaseBudgetService: PurchaseBudgetService,
    private purchaseAbatementService: PurchaseAbatementService,
    private http: HttpClient,
    private utilitiesHandler: UtilitiesHandler
  ) {
    super();
    this.purchaseSource.next(this.purchase);

    this.updateOptionable(true);
    this.token = localStorage.getItem('access_token');
    this.headers = new HttpHeaders({
        Authorization: `Bearer ${this.token}`
    });
    this.headers.append('Accept', 'application/x-www-form-urlencoded');
    this.headers.append('Content-Type', 'application/x-www-form-urlencoded');
  }

  /**
   * Get purchase by ID
   *
   * @param {(string | number)} id
   * @returns {Observable<Purchase>}
   * @memberof PurchaseService
   */
  get(id: string | number): Observable<Purchase> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}/${id}`;

    return this.http
      .get(api_base_url)
      .pipe(
        map((data: any) => this.setMandatary(new Purchase(data), data)))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get list of purchase with ID
   *
   * @param {Array<string>} idList
   * @returns {Observable<any>}
   * @memberof PurchaseService
   */
  getListByPurchaseId(idList: Array<string>): Observable<any> {
    this.filter = {
      purchase_id: idList.join(';')
    };

    const api_base_url: string = `${environment.api_base_url}/${this.route}`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(this.filter);

    return this.http
      .get(api_base_url, {params})
      .pipe(
        map((data: any) =>
          data._embedded.purchase
        ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Download echeancier purchase pdf
   *
   * @param  {number|string} purchaseId
   * @param  {string} advertiserName
   * @returns {Observable<any>}
   * @memberof PurchaseService
   */
  downloadEcheancierPdf(purchaseId, advertiserName): Observable<any> {
    const api_base_url = `${environment.api_base_url}/generate_purchase_echeancier?purchase_id=${purchaseId}`;
    const fileName = advertiserName === '' ? 'EchConv.pdf' : `EchConv ${advertiserName}.pdf`
    this.headers.set('Accept', 'application/pdf');

    return this.http.get(api_base_url, { headers: this.headers, responseType: 'blob'})
      .pipe(
        map((response: any) => {
          const blob = new Blob([response], { type: 'application/pdf'});
          fileServer.saveAs(blob, fileName);
        })
      );
  }

  /**
   * Download convention purchase word
   *
   * @param  {number|string} purchaseId
   * @param  {string} advertiserName
   * @returns {Observable<any>}
   * @memberof PurchaseService
   */
  downloadConventionWord(purchaseId, advertiserName): Observable<any> {
    const api_base_url = `${environment.api_base_url}/generate_purchase_convention?purchase_id=${purchaseId}`;
    const fileName = advertiserName === '' ? 'convention.docx' : `convention_${advertiserName}.docx`
    this.headers.set('Accept', 'application/msword, */*');

    return this.http.get(api_base_url, { headers: this.headers, responseType: 'blob'})
      .pipe(
        map((response: any) => {
          const blob = new Blob([response], { type: 'application/msword, */*'});
          fileServer.saveAs(blob, fileName);
        })
      )
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      );
  }

  /**
   * Generate prod sheet
   *
   * @param purchaseId {number}
   */
   public exportXlsxProdSheet(purchaseId: number): Observable<any> {
    const apiUrl: string = `${environment.api_base_url}/export_prod_sheet?purchase_id=${purchaseId}`;
    const xlsxType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(purchaseId);

    return this.http.get(apiUrl, {params: params, responseType: 'blob'})
        .pipe(
            map((response: any) => {
              const blob = new Blob([response], { type: xlsxType});
              fileServer.saveAs(blob, 'prodSheet.xlsx');
            })
        )
        .pipe(
            catchError(this.utilitiesHandler.handleErrorApi)
        );
  }

  /**
   * Get list of deprogramation
   *
   * @param idList {Array<number>}
   * @return {Observable<any>}
   */
  getListWarning(idList: Array<number>): Observable<any> {
    idList = idList.filter((id: number) => id >= 0); // remove id of new program
    const api_base_url = `${environment.api_base_url}/purchase_check_deprogramation?purchase_program_ids=${idList.join(';')}`;

    return this.http
      .get(api_base_url)
      .pipe(map((data) =>
        data
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get purchase with game list by purchase ID
   *
   * @param {Array<string>} idList
   * @returns {Observable<any>}
   * @memberof PurchaseService
   */
  getPurchaseWithGameListByPurchaseId(idList: Array<string>): Observable<any> {
    this.filter = {
      purchase_id: idList.join(';'),
      has_game: 1
    };

    const api_base_url: string = `${environment.api_base_url}/${this.route}`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(this.filter);

    return this.http
      .get(api_base_url, {params}).pipe(
      map((data: any) =>
        data._embedded.purchase
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get list of purchase
   *
   * @param {*} [filters=null]
   * @returns {Observable<Purchase[]>}
   * @memberof PurchaseService
   */
  getList(filters: any = null): Observable<Purchase[]> {
    filters.groups = 'purchaseList';

    const api_base_url: string = `${environment.api_base_url}/${this.route}`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params}).pipe(
      map((data: any) =>
        data._embedded.purchase
          .map((jsonPurchase: any) =>
            new Purchase(jsonPurchase)
          )
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get list of purchases
   *
   * @param {*} [filters=null]
   * @param {string} [groups='purchaseList']
   * @returns {Observable<FilteredItem[]>}
   * @memberof PurchaseService
   */
  public getListForFilter(filters: any = null, groups = 'purchaseList'): Observable<FilteredItem[]> {
    if (groups) {
      filters.groups = groups;
    }

    const api_base_url: string = `${environment.api_base_url}/${this.route}`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params}).pipe(
      map((data: any) =>
        data._embedded.purchase
          .map((jsonPurchase: any) =>
            new Purchase(jsonPurchase)
          )
          .map((purchase: Purchase) =>
            new FilteredItem(purchase)
          )
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get list of purchase dotation
   * @param {*} [filters=null]
   * @returns {Observable<FilteredItem[]>}
   */
  public getListDotationPurchaseForFilter(filters: any = null): Observable<FilteredItem[]> {
    const api_base_url: string = `${environment.api_base_url}/dotation_purchase`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params})
      .pipe(
        map((data: any) => {
           return data
          .map((jsonPurchaseDotation: JsonPurchaseDotation) =>
            new PurchaseDotation(jsonPurchaseDotation)
          )
          .map((purchaseDotation: PurchaseDotation) =>
            new FilteredItem(purchaseDotation)
          )
        })
      )
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get list of purchase dotation
   * @param {*} [filters=null]
   * @returns {Observable<FilteredItem[]>}
   */
   public getListDotationPurchase(filters: any = null): Observable<FilteredItem[]> {
    const api_base_url: string = `${environment.api_base_url}/list_dotation_purchase`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params})
      .pipe(
        map((data: any) => {
           return data
        })
      )
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get list for autocomplete advertiser
   *
   * @param {Object} [filters=null]
   * @returns {Observable<Array<any>>}
   * @memberof PurchaseService
   */
  getListForAutoCompleteAdvertiser(filters: Object = null): Observable<Array<any>> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params}).pipe(
      map((data: any) =>
        data._embedded.purchase
          .map((jsonPurchase: any) =>
            ({
              display: jsonPurchase.temporary_advertiser,
              value: jsonPurchase.temporary_advertiser
            })
          )
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get list for autocomplete mandatary
   *
   * @param {Object} [filters=null]
   * @returns {Observable<Array<any>>}
   * @memberof PurchaseService
   */
  getListForAutoCompleteMandatary(filters: Object = null): Observable<Array<any>> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params}).pipe(
      map((data: any) =>
        data._embedded.purchase
          .map((jsonPurchase: any) =>
            ({
              display: jsonPurchase.temporary_mandatary,
              value: jsonPurchase.temporary_mandatary
            })
          )
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get list for autocomplete sub mandatary
   *
   * @param {Object} [filters=null]
   * @returns {Observable<Array<any>>}
   * @memberof PurchaseService
   */
  getListForAutoCompleteSubMandatary(filters: Object = null): Observable<Array<any>> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params}).pipe(
      map((data: any) =>
        data._embedded.purchase
          .map((jsonPurchase: any) =>
            ({
              display: jsonPurchase.temporary_sub_mandatary,
              value: jsonPurchase.temporary_sub_mandatary
            })
          )
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Create purchase
   *
   * @param purchase {Purchase}
   * @param isSoReach {boolean}
   * @returns {Observable<Purchase>}
   * @memberof PurchaseService
   */
  public createPurchase(purchase, isSoReach?: boolean): Observable<Purchase> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;

    return this.http
      .post(api_base_url, this.convertToUnderscore(isSoReach ? purchase : this.extract(purchase)))
      .pipe(
         map((jsonPurchase: JsonPurchase) =>
          new Purchase(jsonPurchase)
        )
      )
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Edit purchase
   *
   * @param {Purchase} purchase
   * @returns {Observable<Purchase>}
   * @memberof PurchaseService
   */
  public editPurchase(purchase: Purchase): Observable<Purchase> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}/${purchase.id}`;

    return this.http
      .put(api_base_url, this.convertToUnderscore(this.extract(purchase)))
      .pipe(
        map((data: any) =>
          new Purchase(data)
        ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  public updatePurchasePartial(id: number, data: Object = {}): Observable<Purchase> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}/${id}`;

    if (Object.keys(data).length === 0) {
      return this.get(id);
    }

    return this.http
      .patch(api_base_url, data)
      .pipe(
        map((response: any) =>
          new Purchase(response)
        ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get expiration date
   *
   * @returns {Observable<any>}
   * @memberof PurchaseService
   */
  public getExpirationDate(): Observable<any> {
    const api_base_url: string = `${environment.api_base_url}/expiration_date`;

    return this.http
      .get(api_base_url)
      .pipe(catchError(this.utilitiesHandler.handleErrorApi))
  }

  /**
   * Get update purchase
   *
   * @param {boolean} [budget]
   * @memberof PurchaseService
   */
  public getUpdatedPurchase(budget?: boolean): void {
    this.get(this.purchase.id).subscribe(purchase => {
      if (purchase.startCommunicationPeriod) {
         let purchaseYear = {
            purchaseYear: moment(purchase.startCommunicationPeriod, 'YYYY-MM-DD').year()
          };
          Object.assign(purchase, purchaseYear);
      }
      if (budget) {
        this.mergeBudget(purchase);
      } else {
        this.purchase = purchase;
      }
      this.purchaseSource.next(purchase);
      this.purchaseBudgetService.updateGlobalBudgetLoading(false);
    });
  }

  /**
   * Merge budget
   *
   * @param {*} purchase
   * @memberof PurchaseService
   */
  public mergeBudget(purchase): void {
    let title = Object.keys(purchase).filter((item) => item.includes('tv') || item.includes('digital') || item.includes('total'));

    title.forEach((item) => {
      this.purchase[item] = purchase[item];
    })
  }

  /**
   * Set purchase
   *
   * @param {Purchase} purchase
   * @memberof PurchaseService
   */
  public setPurchase(purchase: Purchase): void {
    this.purchase = purchase;
    this.onDataChange();
  }

  /**
   * Set purchase types
   *
   * @param {FilteredItem[]} purchaseTypes
   * @memberof PurchaseService
   */
  public setPurchaseTypes(purchaseTypes: FilteredItem[]): void {
    this.purchaseTypes = purchaseTypes;
  }

  /**
   * Get purchase type
   *
   * @param {string} id
   * @returns {FilteredItem}
   * @memberof PurchaseService
   */
  public getPurchaseType(id: string): FilteredItem {
    return this.purchaseTypes.find((purchaseType: FilteredItem) => purchaseType['value'] === id);
  }

  /**
   * Update loading
   *
   * @param {*} value
   * @memberof PurchaseService
   */
  public updateLoading(value): void {
    this.loadingSource.next(value);
  }

  /**
   * Update main information
   *
   * @param {*} data
   * @memberof PurchaseService
   */
  public updateMainInformation(data): void {
    this.purchase.name = data['name'];
    this.purchase.type = data['type'] ? this.getPurchaseType(String(data['type']))['entity'] : null ;
    this.purchase.comments = data['comments'];
    this.purchase.referenceCommand = data['referenceCommand'];
    this.purchase.preferentialPosition = data['preferentialPosition'];
    this.purchase.dateOfExpiration = data['dateOfExpiration'] !== 'Invalid date' ? data['dateOfExpiration'] : null;

    this.onDataChange();
  }

  /**
   * Update stake holder
   *
   * @param {*} data
   * @memberof PurchaseService
   */
  public updateStakeholder(data): void {
    // stakeholder ftp
    this.purchase.commercial = data['mainInformation']['commercial'] ? data['mainInformation']['commercial']['entity'] : null;
    this.purchase.subCommercial = data['mainInformation']['subCommercial'] ? data['mainInformation']['subCommercial']['entity'] : null;
    this.purchase.planning = data['mainInformation']['planningUser'] ? data['mainInformation']['planningUser']['entity'] : null;
    this.purchase.signatoryFtpCode = data['mainInformation']['signatoryFtp'] ? data['mainInformation']['signatoryFtp']['entity'] : null;

    // stakeholder client
    this.purchase.campaign = data['customer']['campaign'] ? data['customer']['campaign']['entity'] : null;
    this.purchase.secodip = data['customer']['secodip'] ? data['customer']['secodip']['entity'] : null;
    this.purchase.target = data['customer']['target'] ? data['customer']['target']['entity'] : null;
    this.purchase.mandateCertificate = data['customer']['mandateCertificate'];
    this.purchase.customerBudget = data['customer']['customerBudget'];
    this.purchase.startCommunicationPeriod = data['customer']['startCommunicationPeriod'] !== 'Invalid date' ?
      data['customer']['startCommunicationPeriod'] : null;
    this.purchase.endCommunicationPeriod = data['customer']['endCommunicationPeriod'] !== 'Invalid date' ?
      data['customer']['endCommunicationPeriod'] : null;

    if (data['customer']['advertiser']) {
      this.checkTemporaryField('advertiser', 'temporaryAdvertiser', 'customer', data);
      this.purchase.advertiserSignatoryName = data['customer']['advertiserSignatory'] ? data['customer']['advertiserSignatory'] : null;
      this.purchase.advertiserSignatoryFunct = data['customer']['advertiserFonction'] ? data['customer']['advertiserFonction'] : null;
    } else {
      this.purchase.advertiser = null;
      this.purchase.temporaryAdvertiser = null;
    }

    if (data['customer']['product']) {
      this.checkTemporaryField('product', 'temporaryProduct', 'customer', data);
    } else {
      this.purchase.product = null;
      this.purchase.temporaryProduct = null;
    }

    if (data['customer']['mandatary']) {
      this.checkTemporaryField('mandatary', 'temporaryMandatary', 'customer', data);
      this.purchase.mandatarySignatoryName = data['customer']['mandatarySignatory'] ? data['customer']['mandatarySignatory'] : null;
      this.purchase.mandatarySignatoryFunct = data['customer']['mandataryFonction'] ? data['customer']['mandataryFonction'] : null;
    } else {
      this.purchase.mandatary = null;
      this.purchase.temporaryMandatary = null;
    }

    if (data['customer']['subMandatary']) {
      this.checkTemporaryField('subMandatary', 'temporarySubMandatary', 'customer', data);
      this.purchase.subMandatarySignatoryName = data['customer']['subMandatarySignatory'] ? data['customer']['subMandatarySignatory'] : null;
      this.purchase.subMandatarySignatoryFunct = data['customer']['subMandataryFonction'] ? data['customer']['subMandataryFonction'] : null;
    } else {
      this.purchase.subMandatary = null;
      this.purchase.temporarySubMandatary = null;
    }

    this.onDataChange();
  }

  /**
   * Update digital budget from offer
   *
   * @param {Offer} offer
   * @memberof PurchaseService
   */
  public updateDigitalBudgetFromOffer(offer: Offer): void {
    if (!this.offer) {
      this.purchase.digitalBudget = offer.digitalBudget;
      this.purchase.digitalDisplayBudget = offer.digitalDisplayBudget;
      this.purchase.digitalReplayBudget = offer.digitalReplayBudget;
      this.purchase.digVidBillBudget = offer.digVidBillBudget;
      this.purchase.digVidPreBudget = offer.digVidPreBudget;
      this.purchase.digitalSocialBudget = offer.digitalSocialBudget;
      this.purchase.socialFbBudget = offer.socialFbBudget;
      this.purchase.socialTwitBudget = offer.socialTwitBudget;
      this.purchase.socialInstaBudget = offer.socialInstaBudget;
      this.purchase.digitalOtherBudget = offer.digitalOtherBudget;

      this.offer = offer;

      this.onDataChange();
    }
  }

  /**
   * Update compensation
   *
   * @param {*} data
   * @memberof PurchaseService
   */
  public updateCompensation(data): void {
    this.purchase.compensationPurchase = data['compensationPurchase'] ? data['compensationPurchase']['entity'] : null;
    this.onDataChange();
  }

  /**
   * Reset offer
   *
   * @memberof PurchaseService
   */
  public resetOffer(): void {
    this.offer = null;
  }

  /**
   * On data change (event)
   *
   * @memberof PurchaseService
   */
  public onDataChange(): void {
    this.validatePurchase();
    this.purchaseSource.next(this.purchase);
  }

  /**
   * Extract
   *
   * @param {Purchase} purchase
   * @returns {Object}
   * @memberof PurchaseService
   */
  public extract(purchase: Purchase): Object {
      return {
        // Main info
        id: purchase.id,
        name: purchase.name,
        comments: purchase.comments,
        type : purchase.type ? purchase.type.id : 6,
        date_of_expiration : purchase.dateOfExpiration ?
            moment(purchase.dateOfExpiration, 'YYYY-MM-DD').format('YYYY-MM-DD') : null,
        referenceCommand: purchase.referenceCommand,
        preferentialPosition: purchase.preferentialPosition,

        // FTV
        commercial: purchase.commercial ? purchase.commercial.id : null,
        sub_commercial: purchase.subCommercial ? purchase.subCommercial.id : null,
        planning_id: purchase.planning ? purchase.planning.id : null,

        // Client
        product: purchase.product ? purchase.product.id : null,
        campaign: purchase.campaign ? purchase.campaign.id : null,
        secodip: purchase.secodip ? purchase.secodip.id : null,
        advertiser: purchase.advertiser ? purchase.advertiser.id : null,
        advertiser_signatory_name: purchase.advertiserSignatoryName ? (purchase.advertiserSignatoryName.display ?
          purchase.advertiserSignatoryName.display : purchase.advertiserSignatoryName) : null,
        advertiser_signatory_funct: purchase.advertiserSignatoryFunct ? (purchase.advertiserSignatoryFunct.display ?
          purchase.advertiserSignatoryFunct.display : purchase.advertiserSignatoryFunct) : null,
        mandatary: purchase.mandatary ? purchase.mandatary.id : null,
        mandatary_signatory_name: purchase.mandatarySignatoryName ? (purchase.mandatarySignatoryName.display ?
          purchase.mandatarySignatoryName.display : purchase.mandatarySignatoryName) : null,
        mandatary_signatory_funct: purchase.mandatarySignatoryFunct ? (purchase.mandatarySignatoryFunct.display ?
          purchase.mandatarySignatoryFunct.display : purchase.mandatarySignatoryFunct) : null,
        sub_mandatary: purchase.subMandatary ? purchase.subMandatary.id : null,
        sub_mandatary_signatory_name: purchase.subMandatarySignatoryName ? (purchase.subMandatarySignatoryName.display ?
          purchase.subMandatarySignatoryName.display : purchase.subMandatarySignatoryName) : null,
        sub_mandatary_signatory_funct: purchase.subMandatarySignatoryFunct ? (purchase.subMandatarySignatoryFunct.display ?
          purchase.subMandatarySignatoryFunct.display : purchase.subMandatarySignatoryFunct) : null,
        signatory_ftp_code: purchase.signatoryFtpCode ? (purchase.signatoryFtpCode.code_ut
          ? purchase.signatoryFtpCode.code_ut : purchase.signatoryFtpCode) : null,
        target: purchase.target ? purchase.target.id : null,
        temporaryAdvertiser : purchase.temporaryAdvertiser ? purchase.temporaryAdvertiser : null,
        temporaryProduct : purchase.temporaryProduct ? purchase.temporaryProduct : null,
        temporaryMandatary : purchase.temporaryMandatary ? purchase.temporaryMandatary : null,
        temporarySubMandatary : purchase.temporarySubMandatary ? purchase.temporarySubMandatary : null,
        mandate_certificate: purchase.mandateCertificate,
        customer_budget: purchase.customerBudget,
        start_communication_period: purchase.startCommunicationPeriod ?
          moment(purchase.startCommunicationPeriod, 'YYYY-MM-DD').format('YYYY-MM-DD') : null,
        end_communication_period: purchase.endCommunicationPeriod ?
          moment(purchase.endCommunicationPeriod, 'YYYY-MM-DD').format('YYYY-MM-DD') : null,
        compensation_purchase: purchase.compensationPurchase ? purchase.compensationPurchase.id : null,

        // Abatement
        ca_ref: purchase.caRef,
        ca_fact: purchase.caFact,

        ca_ref_region: purchase.caRefRegion,
        ca_fact_region: purchase.caFactRegion,

        ca_ref_domtom: purchase.caRefDomtom,
        ca_fact_domtom: purchase.caFactDomtom,

        ca_ref_thema: purchase.caRefThema,
        ca_fact_thema: purchase.caFactThema,

        ca_ref_inter: purchase.caRefInter,
        ca_fact_inter: purchase.caFactInter,

        ca_ref_f4: purchase.caRefF4,
        ca_fact_f4: purchase.caFactF4,

        // Budget TV
        tv_budget: purchase.tvBudget ||  0,
        tv_budget_rate: purchase.tvBudgetRate ||  0,
        tv_budget_net: purchase.tvBudgetNet ||  0,

        tv_classic_budget: purchase.tvClassicBudget ||  0,
        tv_classic_budget_rate: purchase.tvClassicBudgetRate ||  0,
        tv_classic_budget_net: purchase.tvClassicBudgetNet ||  0,

        tv_region_budget: purchase.tvRegionBudget ||  0,
        tv_region_budget_rate: purchase.tvRegionBudgetRate ||  0,
        tv_region_budget_net: purchase.tvRegionBudgetNet ||  0,

        tv_domtom_budget: purchase.tvDomtomBudget ||  0,
        tv_domtom_budget_rate: purchase.tvDomtomBudgetRate ||  0,
        tv_domtom_budget_net: purchase.tvDomtomBudgetNet ||  0,

        tv_thema_budget: purchase.tvThemaBudget ||  0,
        tv_thema_budget_rate: purchase.tvThemaBudgetRate ||  0,
        tv_thema_budget_net: purchase.tvThemaBudgetNet ||  0,

        tv_inter_budget: purchase.tvInterBudget ||  0,
        tv_inter_budget_rate: purchase.tvInterBudgetRate ||  0,
        tv_inter_budget_net: purchase.tvInterBudgetNet ||  0,

        tv_f4budget: purchase.tvF4Budget ||  0,
        tv_f4budget_rate: purchase.tvF4BudgetRate ||  0,
        tv_f4budget_net: purchase.tvF4BudgetNet ||  0,

        // Budget digital
        digital_budget: purchase.digitalBudget || 0,
        digital_budget_rate: purchase.digitalBudgetRate || 0,
        digital_budget_net: purchase.digitalBudgetNet || 0,

        digital_display_budget: purchase.digitalDisplayBudget || 0,
        digital_display_budget_rate: purchase.digitalDisplayBudgetRate || 0,
        digital_display_budget_net: purchase.digitalDisplayBudgetNet || 0,

        digital_replay_budget: purchase.digitalReplayBudget || 0,
        digital_replay_budget_rate: purchase.digitalReplayBudgetRate || 0,
        digital_replay_budget_net: purchase.digitalReplayBudgetNet || 0,

        dig_vid_bill_budget: purchase.digVidBillBudget || 0,
        dig_vid_bill_budget_rate: purchase.digVidBillBudgetRate || 0,
        dig_vid_bill_budget_net: purchase.digVidBillBudgetNet || 0,

        dig_vid_pre_budget: purchase.digVidPreBudget || 0,
        dig_vid_pre_budget_rate: purchase.digVidPreBudgetRate || 0,
        dig_vid_pre_budget_net: purchase.digVidPreBudgetNet || 0,

        digital_social_budget: purchase.digitalSocialBudget || 0,
        digital_social_budget_rate: purchase.digitalSocialBudgetRate || 0,
        digital_social_budget_net: purchase.digitalSocialBudgetNet || 0,

        social_fb_budget: purchase.socialFbBudget || 0,
        social_fb_budget_rate: purchase.socialFbBudgetRate || 0,
        social_fb_budget_net: purchase.socialFbBudgetNet || 0,

        social_twit_budget: purchase.socialTwitBudget || 0,
        social_twit_budget_rate: purchase.socialTwitBudgetRate || 0,
        social_twit_budget_net: purchase.socialTwitBudgetNet || 0,

        social_insta_budget: purchase.socialInstaBudget || 0,
        social_insta_budget_rate: purchase.socialInstaBudgetRate || 0,
        social_insta_budget_net: purchase.socialInstaBudgetNet || 0,

        digital_other_budget: purchase.digitalOtherBudget || 0,
        digital_other_budget_rate: purchase.digitalOtherBudgetRate || 0,
        digital_other_budget_net: purchase.digitalOtherBudgetNet || 0,

        // Total
        total_budget: purchase.totalBudget || 0,
        total_budget_rate: purchase.totalBudgetRate || 0,
        total_budget_net: purchase.totalBudgetNet || 0,

        // Grp
        grp: purchase.grp || 0,
        cgrp: purchase.cgrp || 0,
        estimatedGrp: purchase.estimatedGrp || 0,
        estimatedCgrp: purchase.estimatedCgrp || 0,
        average_duration_Rate: purchase.averageDurationRate || 0,
        coverage: purchase.coverage || 0,
        repeat: purchase.repeat || 0,
        soreach_purchase: purchase.soreachPurchase || null,
      };
    }

  /**
   * Update global budget
   *
   * @param {*} data
   * @memberof PurchaseService
   */
  public updateGlobalBudget(data): void {
    // tv
    this.purchase.tvBudget = parseFloat(data['tv']['tvBudget']['tvBudget']) || 0;
    this.purchase.tvBudgetRate = parseFloat(data['tv']['tvBudget']['tvBudgetRate']) || 0;
    this.purchase.tvBudgetNet = parseFloat(data['tv']['tvBudget']['tvBudgetNet']) || 0;

    this.purchase.tvClassicBudget = parseFloat(data['tv']['tvClassic']['tvClassicBudget']) || 0;
    this.purchase.tvClassicBudgetRate = parseFloat(data['tv']['tvClassic']['tvClassicBudgetRate']) || 0;
    this.purchase.tvClassicBudgetNet = parseFloat(data['tv']['tvClassic']['tvClassicBudgetNet']) || 0;

    this.purchase.tvRegionBudget = parseFloat(data['tv']['tvRegion']['tvRegionBudget']) || 0;
    this.purchase.tvRegionBudgetRate = parseFloat(data['tv']['tvRegion']['tvRegionBudgetRate']) || 0;
    this.purchase.tvRegionBudgetNet = parseFloat(data['tv']['tvRegion']['tvRegionBudgetNet']) || 0;

    this.purchase.tvDomtomBudget = parseFloat(data['tv']['tvDomtom']['tvDomtomBudget']) || 0;
    this.purchase.tvDomtomBudgetRate = parseFloat(data['tv']['tvDomtom']['tvDomtomBudgetRate']) || 0;
    this.purchase.tvDomtomBudgetNet = parseFloat(data['tv']['tvDomtom']['tvDomtomBudgetNet']) || 0;

    this.purchase.tvThemaBudget = parseFloat(data['tv']['tvThema']['tvThemaBudget']) || 0;
    this.purchase.tvThemaBudgetRate = parseFloat(data['tv']['tvThema']['tvThemaBudgetRate']) || 0;
    this.purchase.tvThemaBudgetNet = parseFloat(data['tv']['tvThema']['tvThemaBudgetNet']) || 0;

    this.purchase.tvInterBudget = parseFloat(data['tv']['tvInter']['tvInterBudget']) || 0;
    this.purchase.tvInterBudgetRate = parseFloat(data['tv']['tvInter']['tvInterBudgetRate']) || 0;
    this.purchase.tvInterBudgetNet = parseFloat(data['tv']['tvInter']['tvInterBudgetNet']) || 0;

    this.purchase.tvF4Budget = parseFloat(data['tv']['tvF4']['tvF4Budget']) || 0;
    this.purchase.tvF4BudgetRate = parseFloat(data['tv']['tvF4']['tvF4BudgetRate']) || 0;
    this.purchase.tvF4BudgetNet = parseFloat(data['tv']['tvF4']['tvF4BudgetNet']) || 0;

    // digital
    this.purchase.digitalBudget = parseFloat(data['digital']['digitalBudget']['digitalBudget']) || 0;
    this.purchase.digitalBudgetRate = parseFloat(data['digital']['digitalBudget']['digitalBudgetRate']) || 0;
    this.purchase.digitalBudgetNet = parseFloat(data['digital']['digitalBudget']['digitalBudgetNet']) || 0;

    this.purchase.digitalReplayBudget = parseFloat(data['digital']['digitalReplay']['digitalReplayBudget']) || 0;
    this.purchase.digitalReplayBudgetRate = parseFloat(data['digital']['digitalReplay']['digitalReplayBudgetRate']) || 0;
    this.purchase.digitalReplayBudgetNet = parseFloat(data['digital']['digitalReplay']['digitalReplayBudgetNet']) || 0;

    this.purchase.digVidBillBudget = parseFloat(data['digital']['digitalVideoBill']['digitalVideoBillBudget']) || 0;
    this.purchase.digVidBillBudgetRate = parseFloat(data['digital']['digitalVideoBill']['digitalVideoBillBudgetRate']) || 0;
    this.purchase.digVidBillBudgetNet = parseFloat(data['digital']['digitalVideoBill']['digitalVideoBillBudgetNet']) || 0;

    this.purchase.digVidPreBudget = parseFloat(data['digital']['digitalVideoPre']['digitalVideoPreBudget']) || 0;
    this.purchase.digVidPreBudgetRate = parseFloat(data['digital']['digitalVideoPre']['digitalVideoPreBudgetRate']) || 0;
    this.purchase.digVidPreBudgetNet = parseFloat(data['digital']['digitalVideoPre']['digitalVideoPreBudgetNet']) || 0;

    this.purchase.digitalDisplayBudget = parseFloat(data['digital']['digitalDisplay']['digitalDisplayBudget']) || 0;
    this.purchase.digitalDisplayBudgetRate = parseFloat(data['digital']['digitalDisplay']['digitalDisplayBudgetRate']) || 0;
    this.purchase.digitalDisplayBudgetNet = parseFloat(data['digital']['digitalDisplay']['digitalDisplayBudgetNet']) || 0;

    this.purchase.digitalSocialBudget = parseFloat(data['digital']['digitalSocial']['digitalSocialBudget']) || 0;
    this.purchase.digitalSocialBudgetRate = parseFloat(data['digital']['digitalSocial']['digitalSocialBudgetRate']) || 0;
    this.purchase.digitalSocialBudgetNet = parseFloat(data['digital']['digitalSocial']['digitalSocialBudgetNet']) || 0;

    this.purchase.socialFbBudget = parseFloat(data['digital']['digitalSocialFb']['digitalSocialFbBudget']) || 0;
    this.purchase.socialFbBudgetRate = parseFloat(data['digital']['digitalSocialFb']['digitalSocialFbBudgetRate']) || 0;
    this.purchase.socialFbBudgetNet = parseFloat(data['digital']['digitalSocialFb']['digitalSocialFbBudgetNet']) || 0;

    this.purchase.socialTwitBudget = parseFloat(data['digital']['digitalSocialTwit']['digitalSocialTwitBudget']) || 0;
    this.purchase.socialTwitBudgetRate = parseFloat(data['digital']['digitalSocialTwit']['digitalSocialTwitBudgetRate']) || 0;
    this.purchase.socialTwitBudgetNet = parseFloat(data['digital']['digitalSocialTwit']['digitalSocialTwitBudgetNet']) || 0;

    this.purchase.socialInstaBudget = parseFloat(data['digital']['digitalSocialInsta']['digitalSocialInstaBudget']) || 0;
    this.purchase.socialInstaBudgetRate = parseFloat(data['digital']['digitalSocialInsta']['digitalSocialInstaBudgetRate']) || 0;
    this.purchase.socialInstaBudgetNet = parseFloat(data['digital']['digitalSocialInsta']['digitalSocialInstaBudgetNet']) || 0;

    this.purchase.digitalOtherBudget = parseFloat(data['digital']['digitalOther']['digitalOtherBudget']) || 0;
    this.purchase.digitalOtherBudgetRate = parseFloat(data['digital']['digitalOther']['digitalOtherBudgetRate']) || 0;
    this.purchase.digitalOtherBudgetNet = parseFloat(data['digital']['digitalOther']['digitalOtherBudgetNet']) || 0;

    // Total
    this.purchase.totalBudget = parseFloat(data['total']['totalBudget']) || 0;
    this.purchase.totalBudgetRate = parseFloat(data['total']['totalBudgetRate']) || 0;
    this.purchase.totalBudgetNet = parseFloat(data['total']['totalBudgetNet']) || 0;

    // Compute NCPP for each agency
    this.purchaseAbatementService.computeNcpp(this.purchase, 'F');
    this.purchaseAbatementService.computeNcpp(this.purchase, 'FREG');
    this.purchaseAbatementService.computeNcpp(this.purchase, 'O');
    this.purchaseAbatementService.computeNcpp(this.purchase, 'W');
    this.purchaseAbatementService.computeNcpp(this.purchase, 'I');
    this.purchaseAbatementService.computeNcpp(this.purchase, 'F4');
    this.onDataChange();
  }

  /**
   * Add digital budgets
   *
   * @param {*} digitalBudgets
   * @memberof PurchaseService
   */
  public addDigitalBudgets(digitalBudgets: any): void {
    this.purchase.digitalDisplayBudget += digitalBudgets.digitalDisplayBudget;

    this.purchase.digVidBillBudget += digitalBudgets.digVidBillBudget;
    this.purchase.digVidPreBudget += digitalBudgets.digVidPreBudget;
    this.purchase.digitalReplayBudget = this.purchase.digVidBillBudget + this.purchase.digVidPreBudget;

    this.purchase.socialFbBudget += digitalBudgets.socialFbBudget;
    this.purchase.socialTwitBudget += digitalBudgets.socialTwitBudget;
    this.purchase.socialInstaBudget += digitalBudgets.socialInstaBudget;
    this.purchase.digitalSocialBudget =  this.purchase.socialFbBudget + this.purchase.socialTwitBudget +
        this.purchase.socialInstaBudget;

    this.purchase.digitalOtherBudget += digitalBudgets.digitalOtherBudget;

    this.purchase.digitalBudget = this.purchase.digitalOtherBudget + this.purchase.digitalReplayBudget +
        this.purchase.digitalSocialBudget + this.purchase.digitalDisplayBudget;
    this.purchase.totalBudget = this.purchase.digitalBudget + this.purchase.tvBudget;

    this.computeBudgets();
    this.onDataChange();
  }

  /** TODO to analyze
   * Compute budgets
   *
   * @memberof PurchaseService
   */
  public computeBudgets(): void {
    this.purchase.digitalDisplayBudgetNet = this.purchase.digitalDisplayBudget * (1 + (this.purchase.digitalDisplayBudgetRate / 100));

    this.purchase.digVidBillBudgetNet = this.purchase.digVidBillBudget * (1 + (this.purchase.digVidBillBudgetRate / 100));
    this.purchase.digVidPreBudgetNet = this.purchase.digVidPreBudget * (1 + (this.purchase.digVidPreBudgetRate / 100));
    this.purchase.digitalReplayBudgetNet = this.purchase.digVidBillBudgetNet + this.purchase.digVidPreBudgetNet;

    this.purchase.socialFbBudgetNet = this.purchase.socialFbBudget * (1 + (this.purchase.socialFbBudgetRate / 100));
    this.purchase.socialTwitBudgetNet = this.purchase.socialTwitBudget * (1 + (this.purchase.socialTwitBudgetRate / 100));
    this.purchase.socialInstaBudgetNet = this.purchase.socialInstaBudget * (1 + (this.purchase.socialInstaBudgetRate / 100));
    this.purchase.digitalSocialBudgetNet = this.purchase.socialFbBudgetNet + this.purchase.socialTwitBudgetNet
      + this.purchase.socialInstaBudgetNet;

    this.purchase.digitalOtherBudgetNet = this.purchase.digitalOtherBudget * (1 + (this.purchase.digitalOtherBudgetRate / 100));

    this.purchase.digitalBudgetNet = this.purchase.digitalDisplayBudgetNet + this.purchase.digitalReplayBudgetNet
      + this.purchase.digitalSocialBudgetNet + this.purchase.digitalOtherBudgetNet;
    this.purchase.totalBudgetNet = this.purchase.digitalBudgetNet + this.purchase.tvBudgetNet;

    // Rates
    this.purchase.digitalReplayBudgetRate = !this.purchase.digitalReplayBudget ? 0 :
      ((this.purchase.digitalReplayBudgetNet / this.purchase.digitalReplayBudget) - 1) * 100 ;
    this.purchase.digitalSocialBudgetRate = !this.purchase.digitalSocialBudget ? 0 :
      ((this.purchase.digitalSocialBudgetNet / this.purchase.digitalSocialBudget) - 1) * 100;
    this.purchase.digitalBudgetRate = !this.purchase.digitalBudget ? 0 :
      ((this.purchase.digitalBudgetNet / this.purchase.digitalBudget) - 1) * 100;
    this.purchase.totalBudgetRate = this.purchase.totalBudget ? ((this.purchase.totalBudgetNet / this.purchase.totalBudget) - 1) * 100 : 0;
  }

  /**
   * Publish purchase program added (event)
   *
   * @param {*} value
   * @memberof PurchaseService
   */
  public publishPurchaseProgramAdded(value): void {
    this.purchaseProgramAdded.next(value);
  }

  /**
   * Save purchase for compare (convention)
   *
   * @return {void}
   * @memberof PurchaseService
   */
  public savePurchaseForCompare(type: string): void {
    this.savePurchase.next(type);
  }

  /**
   * Update optionable (event)
   *
   * @param {boolean} value
   * @memberof PurchaseService
   */
  public updateOptionable(value: boolean): void {
    if (typeof value === 'boolean') {
      this.isOptionableSource.next(value);
    }
  }

  /**
   * Check if purchase is optionable by purchase programs collection
   *
   * @param {PurchaseProgram[]} purchasePrograms
   * @memberof PurchaseService
   */
  public checkIfPurchaseIsOptionableByPurchaseProgramsCollection(purchasePrograms: PurchaseProgram[]): void {
    if (!purchasePrograms && !purchasePrograms.length) {
      return;
    };

    let optionnable: boolean = true;

    purchasePrograms.forEach(purchaseProgram => {
        if (!this.checkIfPurchaseIsOptionableByPurchaseProgram(purchaseProgram)) {
            optionnable = false;
        }
    });

    if (!optionnable) {
        this.updateOptionable(false);
    } else {
        this.updateOptionable(true);
    }
  }

  /**
   * Check if purchase is optionable by purchase program
   *
   * @param {PurchaseProgram} purchaseProgram
   * @returns {boolean}
   * @memberof PurchaseService
   */
  public checkIfPurchaseIsOptionableByPurchaseProgram(purchaseProgram: PurchaseProgram): boolean {
    if (!purchaseProgram) {
      return;
    }

    if (!purchaseProgram.offerProgram.offer.isOptionable()) {
        this.updateOptionable(false);
        return false;
    }

    this.updateOptionable(true);
    return true;
  }

  public getPurchaseParams(): PurchaseParams {
    return this.paramPurchase;
  }

  public setPurchaseParams(paramPurchase: PurchaseParams): void {
    this.paramPurchase = paramPurchase;
  }

  /**
   * Valide purchase
   *
   * @private
   * @memberof PurchaseService
   */
  private validatePurchase(): void {
    let isValid = true;

    if (!this.purchase.name) {
      isValid = false;
    }

    if (!this.purchase.commercial) {
      isValid = false;
    }

    if (!this.purchase.advertiser && !this.purchase.temporaryAdvertiser) {
      isValid = false;
    }

    if (!this.purchase.startCommunicationPeriod || !this.purchase.endCommunicationPeriod) {
      isValid = false;
    }

    if (this.purchase.startCommunicationPeriod && this.purchase.endCommunicationPeriod) {
      if (this.purchase.startCommunicationPeriod > this.purchase.endCommunicationPeriod) {
        isValid = false;
      }
    }

    this.isValidSource.next(isValid);
  }

  /**
   * Check temporary field
   *
   * @private
   * @param {string} field
   * @param {string} temporaryField
   * @param {string} group
   * @param {*} data
   * @memberof PurchaseService
   */
  private checkTemporaryField(field: string, temporaryField: string, group: string, data: any): void {
    if (!data[group][field]) {
      return;
    }

    let value = data[group][field];

    if (value instanceof FilteredItem) {
        this.purchase[field] = value['entity'];
        this.purchase[temporaryField] = null;
    } else {
        this.purchase[temporaryField] = value.display ? value.display : value;
        this.purchase[field] = null;
    }

    if (this.purchase[temporaryField] && this.purchase[temporaryField].display === null) {
        this.purchase[temporaryField] = null;
    }
  }

  /**
   * Set Mandatary name and funct
   *
   * @param {Purchase} purchase
   * @param data
   * @return Purchase
   */
  private setMandatary(purchase: Purchase, data: any): Purchase {
    if (data.advertiser_signatory_name) {
      purchase.advertiserSignatoryName = data.advertiser_signatory_name;
    }

    if (data.advertiser_signatory_funct) {
      purchase.advertiserSignatoryFunct = data.advertiser_signatory_funct;
    }

    if (data.mandatary_signatory_name) {
      purchase.mandatarySignatoryName = data.mandatary_signatory_name;
    }

    if (data.mandatary_signatory_funct) {
      purchase.mandatarySignatoryFunct = data.mandatary_signatory_funct;
    }

    if (data.sub_mandatary_signatory_name) {
      purchase.subMandatarySignatoryName = data.sub_mandatary_signatory_name;
    }

    if (data.sub_mandatary_signatory_funct) {
      purchase.subMandatarySignatoryFunct = data.sub_mandatary_signatory_funct;
    }

    return purchase;
  }
}
