import { Injectable } from '@angular/core';

import { OfferProgramPropalCart } from '../../../resource/availability/offer-program-propal-cart.resource';
import { Cell, PurchaseTooltip } from '../../../resource/availability/cell.resource';
import { Broadcast } from '../../../resource/broadcast.resource';
import { OfferProgram } from '../../../resource/offerProgram.resource';
import { ScheduleException } from '../../../resource/schedule/schedule-exception.resource';
import { Schedule } from '../../../resource/schedule/schedule.resource';
import { Channel } from 'src/app/resource/channel.resource';

import { Subject } from 'rxjs';

import * as moment from 'moment';

@Injectable()
export class GridService {
  private maxOfLines: number = 0;
  private linesGroup: string;

  private soReachOfferProgramsGrid = new Subject<Object>();
  public soReachOfferProgramsGrid$ = this.soReachOfferProgramsGrid.asObservable();

  private soReachWeek = new Subject<Number>();
  public soReachWeek$ = this.soReachWeek.asObservable();

  private selectedSoReachPrograms = new Subject<any>();
  public selectedSoReachPrograms$ = this.selectedSoReachPrograms.asObservable();

  private soReachYear = new Subject<Number>();
  public soReachYear$ = this.soReachYear.asObservable();

  private channelCard = new Subject<any>();
  public channelCard$ = this.channelCard.asObservable();

  private programCard = new Subject<any>();
  public programCard$ = this.programCard.asObservable();

  private selectedSoReach = new Subject<string>();
  public selectedSoReach$ = this.selectedSoReach.asObservable();

  private isEmptyCardSoReach = new Subject<boolean>();
  public isEmptyCardSoReach$ = this.isEmptyCardSoReach.asObservable();

  private clearCardSoReach = new Subject<void>();
  public clearCardSoReach$ = this.clearCardSoReach.asObservable();

  constructor() {}

  /**
   * Return an array with all weeks of the selected year with broadcast data when available
   *
   * @param {Broadcast[]} broadcasts
   * @param {number} totalNumberOfWeeks
   * @param {string} startDate
   *
   * @returns {(Array<Broadcast> | null)}
   * @memberof GridService
   */
  public getBroadcastsByWeeks(broadcasts: Broadcast[], totalNumberOfWeeks: number, startDate: string): Array<Broadcast> | null {

    // array of broadcasts grouped by week
    let result = [];

    // array of a successive serie of weeks' numbers, starting by the reference of startDate week number
    // length of array is totalNumberOfWeeks
    let numberWeeksArray = [];

    // index of week position in result array
    let i = 0;

    for (let j = 0; j < totalNumberOfWeeks; j++) {
      result[j] = [];
      numberWeeksArray[j] = moment(startDate).add(j, 'week').isoWeek();
    }

    broadcasts.forEach(function(broadcast) {
      // while current broadcast week number is different than numberWeeksArray[i] we jump to the next numberWeeksArray element (i++)
      while (moment(broadcast.startTime, 'YYYY-MM-DD').isoWeek() !== numberWeeksArray[i] && i < totalNumberOfWeeks) {
        i += 1;
      }

      // push the current broadcast inside the group of broadcasts belonging to week in position i
      if (result[i]) {
        result[i].push(broadcast);
      }
    });

    return result;
  }

  /**
   * Getting schedule exceptions by weeks
   *
   * @param schedules the schedules
   * @param weekCount the number of weeks in the selected year
   * @param startDate the starting date
   */
  public getExceptionByWeeksFromSchedules(schedules: Schedule[], weekCount: number, startDate: string): Array<ScheduleException> {
    // output
    let sortedScheduleExceptions: Array<ScheduleException> = [];
    let exceptionsByWeeks = [];
    let numberWeeksArray = [];

    // buildind scaffolding array
    for (let j = 0; j < weekCount; j++) {
      exceptionsByWeeks[j] = [];
      numberWeeksArray[j] = moment(startDate).add(j, 'week').isoWeek();
    }
    // extract schedule exceptions
    sortedScheduleExceptions = this.extractScheduleExceptionsFromSchedules(schedules, moment(startDate));

    let i = 0;

    // add unsorted schedules to weeks
    sortedScheduleExceptions.forEach((exception) => {
      while (moment(exception.exceptStartPeriod, 'YYYY-MM-DD').isoWeek() !== numberWeeksArray[i] && i < weekCount) {
        i += 1;
      }

      // push to the position
      if (exceptionsByWeeks[i]) {
        exceptionsByWeeks[i].push(exception);
      }
    });

    return exceptionsByWeeks;
  }

  /**
   * Return an array with all days of the selected month/week with broadcast data on availables days
   *
   * @param {Broadcast[]} broadcasts
   * @param {number} year
   * @param {number} month
   * @returns {(Array<Broadcast> | null)}
   * @memberof GridService
   */
  public getBroadcastsByDays(broadcasts: Broadcast[], year: number, month: number, startDay: number): Array<Broadcast> | null {
    let totalNumberOfDays = moment().set('year', year).set('month', month).daysInMonth();
    let result = [];

    for (let i = 0; i < totalNumberOfDays; i++) {
      // pre-fill current day with empty array
      result[i] = [];

      broadcasts.forEach(function(broadcast) {
        let push = false;
        let startDayNumber = moment(broadcast.startTime, 'YYYY-MM-DD').date();
        let endDayNumber = moment(broadcast.endTime, 'YYYY-MM-DD').date();
        let startMonthNumber = moment(broadcast.startTime, 'YYYY-MM-DD').month();

        // if end of the broadcast is on next day
        if (startDayNumber === i + startDay && endDayNumber - startDayNumber > 0 && startMonthNumber === month) {
          push = true;
        }

        // if broadcast day is day number
        if (startDayNumber >= i + startDay && endDayNumber <= i + startDay && startMonthNumber === month) {
          push = true;
        }

        // if broadcast data available, fill the selected day
        if (push === true) {
          result[i].push(broadcast);
        }
      });
    }
    return result;
  }

  /**
   * Sort schedule exceptions by days
   *
   * @param schedules {Schedule[]} schedules from program
   * @param year {number} selected year
   * @param month {number} selected month
   * @param startDay {number} starting day
   * @param totalDayAvailable {number} total of day/month available for calcul
   */
  public getExceptionByDaysFromSchedules(schedules: Schedule[], year: number, month: number, startDay: number, totalDayAvailable: number): Array<ScheduleException> {
    let startingDate = moment(year + '' + String(month + 1).padStart(2, '0') + '' + String(startDay).padStart(2, '0'), 'YYYYMMDD');
    let result = [];

    let scheduleExceptionColletion = this.extractScheduleExceptionsFromSchedules(schedules, startingDate);

    for (let i = 0; i < totalDayAvailable; i++) {
      // pre-fill current day with empty array
      result[i] = [];

      scheduleExceptionColletion.forEach(function(scheduleException: ScheduleException) {
        let push = false;
        let startDayNumber = moment(scheduleException.exceptStartPeriod, 'YYYY-MM-DD').date();
        let endDayNumber = moment(scheduleException.exceptEndPeriod, 'YYYY-MM-DD').date();
        let startMonthNumber = moment(scheduleException.exceptStartPeriod, 'YYYY-MM-DD').month();

        // if end of the broadcast is on next day
        if (startDayNumber === i + startDay && endDayNumber - startDayNumber > 0 && startMonthNumber === month) {
          push = true;
        }

        // if broadcast day is day number
        if (startDayNumber >= i + startDay && endDayNumber <= i + startDay && startMonthNumber === month) {
          push = true;
        }

        // if broadcast data available, fill the selected day
        if (push === true) {
          result[i].push(scheduleException);
        }
      });
    }

    return result;
  }

  // get purchase by time an by type of purchase
  // solded - option - alerte - etc...

  /**
   * Return an array of purchases matching the current purchase type for the week period
   *
   * @param {any} broadcastsByTimeParts a collection of broadcasts
   * @param {any} type achat, option or alerte, exemple { id: PURCHASE, label: 'achat' },
   * @param {OfferProgram} offerProgram
   * @returns {(Broadcast[] | null)}
   * @memberof GridService
   */
  public getPurchasesByTimeParts(broadcastsByTimeParts: any[], type: any, offerProgram: OfferProgram): Broadcast[] | null {
    let purchasesByTimeParts = [];
    let self = this;

    broadcastsByTimeParts.forEach((broadcasts, timePartNumber) => {
      purchasesByTimeParts[timePartNumber] = [];
      let purchasesArray = [];

      broadcasts.forEach(function(broadcast) {
        let broadcastsPurchased = broadcast.broadcastPurchased;
        let purchases = self.getPurchasesRelatedToCurrentOfferProgram(
          broadcastsPurchased,
          offerProgram
        );

        purchases.forEach(function(purchase) {
          if (type.id === purchase._embedded.type.id) {
            purchasesArray[purchase.id] = purchase;
          }
        });
      });

      // transform purchasesArray to clean array
      if (purchasesArray.length > 0) {
        purchasesArray.forEach(function(purchase) {
          purchasesByTimeParts[timePartNumber].push(purchase);
        });
      }
    });

    return purchasesByTimeParts;
  }

  /**
   * If broadcastPurchased offerProgram match current offer program
   *
   * @param {any[]} broadcastsPurchased
   * @param {OfferProgram} offerProgram
   * @returns {Array<any>} A collection of purchase or an empty array
   * @memberof GridService
   */
  public getPurchasesRelatedToCurrentOfferProgram(broadcastsPurchased: any[], offerProgram: OfferProgram): Array<any> {
    let purchases = [];

    broadcastsPurchased.forEach(broadcastPurchased => {
      if (this.isPurchaseOfCurrentOfferProgram(broadcastPurchased._embedded.offer_program, offerProgram)) {
        purchases.push(broadcastPurchased._embedded.purchase);
      }
    });

    return purchases;
  }

  /**
   * Check if BroadcastPurchase's OfferProgram id, match currentOfferProgram id
   *
   * @param {any} broadcastPurchaseOfferProgram {id: 141, theorical_presence: 72, broadcast_count: 17, offer_push: null, _links: {…}}
   * @param {OfferProgram} currentOfferProgram
   * @returns {boolean}
   * @memberof GridService
   */
  public isPurchaseOfCurrentOfferProgram(broadcastPurchaseOfferProgram: any, currentOfferProgram: OfferProgram): boolean {
    if (broadcastPurchaseOfferProgram.id === currentOfferProgram.id) {
      return true;
    }

    return false;
  }

  /**
   * Order purchase on grid by start date
   *
   * @param {any[]} broadcastByTimePart available broadcasts for each week
   * @param {any[]} purchasesByTimePart purchases by week
   * @param {number} totalCells number of weeks
   * @param {any[]} gameModuleList
   * @returns {Array<any>}
   * @memberof GridService
   */
  public orderPurchases(broadcastByTimePart: any[], purchasesByTimePart: any[], totalCells: number, gameModuleList: any[]): Array<any> {
    let result = {};

    // init array result
    for (let i = 0; i < totalCells; i++) {
      let purchases = purchasesByTimePart[i];
      purchases.forEach(purchase => {
        result[purchase.id] = {
          purchase: purchase,
          times: [],
          startTime: null,
          hasGameModule: gameModuleList[purchase.id] ? 1 : 0
        };
      });
    }

    // match purchase start time with week number on grid
    for (let i = 0; i < totalCells; i++) {
      let purchases = purchasesByTimePart[i];
      purchases.forEach(function(purchase) {
        if (result[purchase.id].startTime == null) {
          result[purchase.id].startTime = i;
        }
        result[purchase.id].times.push(i);
      });
    }

    // result to array;
    let purchasesArray = [];
    Object.keys(result).map(function(objectKey, index) {
      let purchase = result[objectKey];
      purchasesArray.push(purchase);
    });

    // Split existing purchases in multiple blocks if week sequence has holes
    let finalResult = [];
    purchasesArray.forEach((purchase, index) => {
      let splitArray = [];
      let splitCount = 0;

      for (let i = 0; i < purchase.times.length; i++) {
        if (i === 0) {
          splitArray[splitCount] = [];
        }

        if (i !== 0 && purchase.times[i - 1] !== purchase.times[i] - 1) {
          // split
          splitCount++;
          splitArray[splitCount] = [];
        }

        splitArray[splitCount].push(purchase.times[i]);
      }

      // if purchase need to be splited
      if (splitArray.length > 1) {
        splitArray.forEach(function(splitedTimes) {
          // clone purchase
          let splitedPurchase = Object.assign({}, purchase);
          // update times and startTime of splited purchase
          splitedPurchase.times = splitedTimes;
          splitedPurchase.startTime = splitedTimes[0];
          finalResult.push(splitedPurchase);
        });
      } else {
        finalResult.push(purchase);
      }
    });

    // sort by startTime
    // maybe need to sort by gameModule
    finalResult.sort(function(a, b) {
      return a.startTime - b.startTime;
    });

    return finalResult;
  }

  /**
   * Return an object containing arrays with data for each week
   *
   * @param {any[]} purchases
   * @param {number} totalCells
   * @param {any} type
   * @returns {any}
   * @memberof GridService
   */
  public insertPurchasesInGrid(purchases: any[], totalCells: number, type: any): any {
    let grid = {};

    // init grid
    for (let i = 0; i < totalCells; i++) {
      grid[i] = [];
    }
    purchases.forEach(function(purchaseObject) {
      let purchase = purchaseObject.purchase;
      let times = purchaseObject.times;
      let duration = times.length;
      let first = times[0];
      let last = times[times.length - 1];
      let line = 0;

      for (let i = 0; i < totalCells; i++) {
        if (i >= first && i <= last) {
          if (times.indexOf(i) === -1) {
            continue;
          }

          while (grid[i][line]) {
            line++;
          }

          grid[i][line] = {
            purchase: purchase,
            end: i === last,
            duration: i === first ? duration : null,
            type: type,
            hasGameModule: purchaseObject.hasGameModule
          };
        }
      }
    });

    return grid;
  }

  /**
   * Create available or non available cell objects, then return them as array to build the grid
   *
   * @param {any[]} broadcastByTimePart
   * @param {Object} purchasesByTimePart
   * @param {number} totalCells
   * @param {number} maxLines
   * @param {number} maxNumberOfAdvertiser
   * @param {any[]} purchasesSoldedByTime
   * @param {any} offerProgramPropalCart
   * @param {string} channelGroup
   * @param {string} channelImage
   * @param {string} programName
   * @param offerProgramStatus
   * @returns {Array<any>}
   * @memberof GridService
   */
  public constructCells(
    broadcastByTimePart: any[],
    purchasesByTimePart: Object,
    totalCells: number,
    maxLines: number,
    maxNumberOfAdvertiser: number,
    purchasesSoldedByTime: any[],
    offerProgramPropalCart: any,
    channelGroup: string,
    channelImage: string,
    programName: string,
    offerProgramStatus: number
  ): Array<any> {

    let result = [];

    for (let i = 0; i < totalCells; i++) {
      let column = [];
      let purchases = purchasesByTimePart[i];
      let broadcasts = broadcastByTimePart[i];

      // Remove all soReaches purchase except soreach with channel thema
      purchases = purchases.filter(
        (purchase) => purchase && purchase.purchase &&
        (!purchase.purchase.soreach_purchase || channelGroup === Channel.THEMA_CHANNEL_GROUP));
      for (let j = 0; j < maxLines; j++) {
        // if no broadcast available, create a non bookable cell
        if (!broadcasts.length) {
          let cell = this.createUnvailableCell(channelGroup, channelImage, programName, null, !!!offerProgramStatus);
          column.push(cell);
          continue;
        }

        // if broadcast available, create a bookable cell
        if (!purchases.length) {
          if (!offerProgramStatus) {
            // program status is cancelled, draw unavailable cell
            let cell = this.createUnvailableCell(channelGroup, channelImage, programName, null, !!!offerProgramStatus);
            column.push(cell);
          } else {
            let cell =
              purchasesSoldedByTime[i].filter(purchase =>
                !purchase.soreach_purchase
                  || channelGroup === Channel.THEMA_CHANNEL_GROUP).length >= maxNumberOfAdvertiser
                ? this.createUnvailableCell(channelGroup, channelImage, programName, null, !!!offerProgramStatus)
                : this.createAvailableCell(channelGroup, channelImage, programName, !!!offerProgramStatus);
            cell.broadcasts = broadcasts;
            this.checkPropalBroadcasts(cell, broadcasts, offerProgramPropalCart);
            column.push(cell);
          }
          continue;
        }

        if (purchases.length) {
          if (purchases[j] && purchases[j].purchase
            && (!purchases[j].purchase.soreach_purchase || channelGroup === Channel.THEMA_CHANNEL_GROUP)) {
            let purchase = purchases[j];
            column.push(this.createPurchasedCell(purchase, channelGroup, channelImage, programName, !!!offerProgramStatus));
          } else {
            // if purchase is detected with broadcast available, create a bookable cell
            if (!offerProgramStatus) {
              // program status is cancelled, draw unavailable cell
              let cell = this.createUnvailableCell(channelGroup, channelImage, programName, null, !!!offerProgramStatus);
              column.push(cell);
            } else {
              let cell =
                purchasesSoldedByTime[i].filter(purchase => !purchase.soreach_purchase
                  || channelGroup === Channel.THEMA_CHANNEL_GROUP).length >= maxNumberOfAdvertiser
                  ? this.createUnvailableCell(channelGroup, channelImage, programName, null, !!!offerProgramStatus)
                  : this.createAvailableCell(channelGroup, channelImage, programName, !!!offerProgramStatus);
              cell.broadcasts = broadcasts;
              this.checkPropalBroadcasts(
                cell,
                broadcasts,
                offerProgramPropalCart
              );
              column.push(cell);
            }
          }
        }
      }
      (result.length && result.length > 1) ? result.push(this.alignSamePurchases(column, result[result.length - 1])) : result.push(column);
    }
    return result;
  }

  /**
   * Align same purchases of successive columns of program availability grid
   *
   * @param column
   * @param previousColumn
   * @param protection - Guard (garde fou)
   */
  public alignSamePurchases(column: Cell[], previousColumn: Cell[], protection: number = 25): Cell[] {
    // Status if have reorder
    let isChange = false;

    column.forEach((cell: Cell, index: number) => {
      if (cell && cell.info && cell.info['purchase'] && cell.info['purchase'].id) {
        // Get previousIndex to compare
        const previousIndex = previousColumn.findIndex((previousCell: Cell) =>
          previousCell && previousCell.info && previousCell.info['purchase'] && previousCell.info['purchase'].id &&
          (previousCell.info['purchase'].id === cell.info['purchase'].id)
        );

        // Re order to the good line
        if (previousIndex >= 0 && previousIndex !== index) {
          isChange = true;
          [column[previousIndex], column[index]] = [column[index], column[previousIndex]];
        }
      }
    });

    protection--;

    // If have reorder, do again a control with the same function
    return (isChange && protection >= 0) ? this.alignSamePurchases(column, previousColumn, protection) : column;
  }

  /**
   * Check if purchase is soreach
   *
   * @param purchases
   * @param id
   */
  public verificationSoReachPurchase(purchases: Object[], id: number): Object {
    return purchases.find((purchase) => purchase && purchase['purchase'] &&
      purchase['purchase'].soreach_purchase && purchase['purchase'].soreach_purchase === id);
  }

  /**
   * Construct soreach purchase cell
   *
   * @param broadcastByTimePart
   * @param purchases
   * @param purchaseListYear
   * @param channelGroup {string}
   * @param channelImage {string}
   * @param soReach
   * @param programName {string}
   * @param type {number}
   */
  public constructCellsSoReaches(broadcastByTimePart: any[],
                                 purchases,
                                 purchaseListYear: Object,
                                 channelGroup: string,
                                 channelImage: string,
                                 soReach,
                                 programName: string,
                                 type: number): Array<Cell> {
    let result = [];
    const dicPurchases = Object.keys(purchases);

    if (dicPurchases.some((purchaseKey) => purchases[purchaseKey].some((purchase) => purchase.soreach_purchase)) || type === 0) {
      dicPurchases.forEach((purchase, index) => {
        let cell;
        const soReachPurchase: Object = this.verificationSoReachPurchase(purchases[index], soReach.id);

        if (!broadcastByTimePart[index].length) {
          cell = this.createUnvailableCell(channelGroup, channelImage, soReach.name, true);
        } else if (soReachPurchase) {
          cell = this.createPurchasedCell(soReachPurchase, channelGroup, channelImage, programName);
        } else {
          cell = this.createUnvailableCell(channelGroup, channelImage, soReach.name, true);
        }
        cell['soReach'] = soReach.name;
        result.push(cell);
      });

      return result;
    } else {
      return null;
    }
  }

  /**
   * Set maximum of Lines needed by default per program
   * to match the slots count ot the offer (eg. 2)
   *
   * @param {number} maximumOfLines
   */
  public setMaxOfLinesByDefault(maximumOfLines: number): void {
    this.maxOfLines = maximumOfLines;
  }

  /**
   * Set current type of lines
   *
   * @param {string} linesGroupType (1 for 'achat', 2 for 'option', 5 for 'alerte', 10 for 'priorité de reconduction')
   */
  public setCurrentLinesGroup(linesGroupType: string) {
    this.linesGroup = linesGroupType;
  }

  /**
   * Get max lines of grid group
   */
  public getMaxLinesOfGridGroup(advertisersByTime): number {
    let maxLines = 0;

    if (this.linesGroup === '1') {
      maxLines = this.maxOfLines;
    }

    Object.keys(advertisersByTime).map(function(objectKey, index) {
      let lines = advertisersByTime[objectKey].filter(item => !item.purchase.soreach_purchase).length;

      if (maxLines < lines) {
        maxLines = lines;
      }
    });

    return maxLines;
  }

  /**
   * Merge cell collection with cell collection of other purchase types
   * merge cell collection of achat with option and alerte
   *
   * @param {any[]} mergedArray aleready merged data
   * @param {any[]} programsTime array of broaadcast and purchase for the selected type
   * @returns
   * @memberof GridService
   */
  public mergeAllTypeOfPurchase(mergedArray: any[], programsTime: any[]) {
    for (let columnIndex in programsTime) {
      if (programsTime) {
        if (!mergedArray[columnIndex]) {
          mergedArray[columnIndex] = [];
        }

        for (let rowIndex in programsTime[columnIndex]) {
          if (programsTime[columnIndex]) {
            mergedArray[columnIndex].push(programsTime[columnIndex][rowIndex]);
          }
        }
      }
    }

    return mergedArray;
  }

  /**
   * Inject programsSoReach into mergedArray
   *
   * @param mergedArray
   * @param programsSoReach
   *
   * @return {Array<Cell[]>}
   */
  public mergeSoReachOfPurchase(mergedArray: Array<Array<Cell>>, programsSoReach: Array<Cell>): Array<Array<Cell>> {
    mergedArray.forEach((listCell: Array<Cell>, index: number) => {
      mergedArray[index].push(programsSoReach[index]);
    });
    return mergedArray;
  }

  /**
   * Event for order asc/desc search Synthesis
   */
  public loadingSoReachOfferProgramsGrid(soReachOfferProgramsGrid?: Object): void {
    this.soReachOfferProgramsGrid.next(soReachOfferProgramsGrid);
  }

  /**
   * Event to send week Number
   */
  public loadingWeekNumber(week: number): void {
    this.soReachWeek.next(week);
  }

  /**
   * Event to send selected SoReach Program to cart
   */
  public loadingSelectedSoReachPrograms(selectedSoReach): void {
    this.selectedSoReachPrograms.next(selectedSoReach);
  }

  /**
   *  Event to send selected Year
   *
   * @param year
   */
  public loadingSelectedYear(year: number): void {
    this.soReachYear.next(year);
  }

  /**
   * Event to delete channel card
   *
   * @return {void}
   */
  public deleteChannelCard(card): void {
    this.channelCard.next(card);
  }

  /**
   * Event to delete channel card
   *
   * @return {void}
   */
  public deleteProgramCard(card): void {
    this.programCard.next(card);
  }

  /**
   * Event to send selected soreach to propal cart component
   *
   * @return {void}
   */
  public sendSelectedSoreachToPropalCart(soreach): void {
    this.selectedSoReach.next(soreach);
  }

  /**
   * Event to check card
   *
   * @return {void}
   */
  public loadingEmptySoReach(isEmpty: boolean): void {
    this.isEmptyCardSoReach.next(isEmpty);
  }

  /**
   * Event to clear card
   *
   * @return {void}
   */
  public clearSoReach(): void {
    this.clearCardSoReach.next();
  }

/**
   * Return a collection of schedule exception from schedule, also create items from ranges
   *
   * @param schedules the schedules
   */
  protected extractScheduleExceptionsFromSchedules(schedules: Array<Schedule>, startDate: moment.Moment): Array<ScheduleException> {
    let scheduleExceptionColletion = new Array<ScheduleException>();

    if (!schedules) {
      return [];
    }

    schedules.forEach((schedule: Schedule, index: Number) => {
      if (schedule.scheduleExceptions.length > 0) {
        schedule.scheduleExceptions.forEach((exception: ScheduleException) => {

          let exceptionStartDate = moment(exception.exceptStartPeriod);
          let exceptionEndDate = moment(exception.exceptEndPeriod)
          let daysFromStartToEnd = exceptionEndDate.diff(exceptionStartDate, 'days');

          // scheduleException contains a date range
          if (daysFromStartToEnd > 0) {
            let cursor = 0;

            // starting date from range
            if (exceptionStartDate >= startDate) {
              scheduleExceptionColletion.push(this.cloneScheduleExceptionWithDate(exception, exceptionStartDate));
            }

            // in-between dates from range
            while (cursor < (daysFromStartToEnd - 1)) {
              let tomorrowDate = exceptionStartDate.add(1, 'days');
              let scheduleDay = schedule.binaryDaysToStringDays(schedule.days);

              if (tomorrowDate >= startDate && scheduleDay.includes(tomorrowDate.format('dd'))) {
                scheduleExceptionColletion.push(this.cloneScheduleExceptionWithDate(exception, tomorrowDate));
              }
              cursor++;
            }

            // last date from range
            if (exceptionEndDate >= startDate) {
              scheduleExceptionColletion.push(this.cloneScheduleExceptionWithDate(exception, exceptionEndDate));
            }
          } else {
            // scheduleException contains a single date
            if (exceptionStartDate >= startDate) {
              scheduleExceptionColletion.push(this.cloneScheduleExceptionWithDate(exception, exceptionStartDate));
            }
          }
        });
      }
    });

    // sort scheduleExceptions by start date
    let sortedScheduleExceptions = scheduleExceptionColletion.sort(function(a: ScheduleException, b: ScheduleException) {
      let start: number = Number.parseInt(moment(a.exceptStartPeriod).format('x'), 0);
      let end: number = Number.parseInt(moment(b.exceptStartPeriod).format('x'), 0);

      return start - end;
    });

    return sortedScheduleExceptions;
  }

  /**
   * Clone a given schedule, then set the given date
   *
   * @param refException exception to clone
   * @param date date to set
   */
  protected cloneScheduleExceptionWithDate(refException: ScheduleException, date: moment.Moment): ScheduleException {
    const dateFormat: string = 'Y-MM-D, h:mm:ss.x';

    let clonedException = new ScheduleException({
      'id': refException.id,
      'label': refException.label,
      'except_start_time': refException.exceptStartTime,
      'except_end_time': refException.exceptEndTime,
      'except_start_period': date.format(dateFormat),
      'except_end_period': date.format(dateFormat),
      'schedule': refException.schedule
    });

    return clonedException;
  }

  /**
   * Check if cell broadcasts match broadcasts form propalcart, then mark the cell as partialy or fully
   * checked if matching data found
   *
   * @private
   * @param {Cell} cell
   * @param {Broadcast[]} broadcasts
   * @param {OfferProgramPropalCart} offerProgramPropalCart
   * @returns {boolean}
   * @memberof GridService
   */
  private checkPropalBroadcasts(cell: Cell, broadcasts: Broadcast[], offerProgramPropalCart: OfferProgramPropalCart): boolean {
    let result = false;

    if (!offerProgramPropalCart) {
      return false;
    }

    let count = 0;
    broadcasts.forEach(broadcast => {
      let propalBroadcasts = offerProgramPropalCart.broadcasts.filter(
        broadcastFromCart => broadcastFromCart.id === broadcast.id
      );
      if (propalBroadcasts.length) {
        result = true;
        count++;
      }
    });

    if (count === broadcasts.length) {
      cell.isCompletelyChecked = true;
    } else if (result) {
      cell.isPartiallyChecked = true;
    }

    return true;
  }

  /**
   * return an instance object of type cell contain the type unavailable
   *
   * @private
   * @returns {Cell}
   * @memberof GridService
   */
  private createUnvailableCell(channelGroup?: string, channelImage?: string, programName?: string, isSoReach?: boolean, isDisable?: boolean): Cell {
    let cell: Cell = new Cell(Cell.UNAVAILABLE);

    if (channelGroup) {
      cell.channelGroup = channelGroup;
    }
    if (channelImage) {
      cell.channelImage = channelImage;
    }
    if (programName) {
      cell.programName = programName;
    }
    if (isSoReach) {
      cell.isSoReach = !!isSoReach;
    }
    cell.isDisable = isDisable;
    return cell;
  }

  /**
   * return an instance object of type celle contain the type available
   *
   * @private
   * @returns {Cell}
   * @memberof GridService
   */
  private createAvailableCell(channelGroup?: string, channelImage?: string, programName?: string, isDisable?: boolean): Cell {
    let cell: Cell = new Cell(Cell.AVAILABLE);

    if (channelGroup) {
      cell.channelGroup = channelGroup;
    }
    if (channelImage) {
      cell.channelImage = channelImage;
    }
    if (programName) {
      cell.programName = programName;
    }
    cell.isDisable = isDisable;
    return cell;
  }

  /**
   * return a cell contain purchase data to display the purchase and the tooltip in the grid
   *
   * @private
   * @param {any} purchase
   * @param {string} channelGroup
   * @param {string} channelImage
   * @param {string} programName
   * @param {boolean} isDisable
   * @returns {Cell}
   * @memberof GridService
   */
  private createPurchasedCell(purchase: any, channelGroup?: string, channelImage?: string, programName?: string, isDisable?: boolean): Cell {
    let type = purchase.end === true ? purchase.type.label + 'End' : purchase.type.label;
    let cell = new Cell(type);

    cell.duration = purchase.duration || null;
    cell.hasGameModule = purchase.hasGameModule;
    cell.info = purchase;

    if (channelGroup) {
      cell.channelGroup = channelGroup;
    }
    if (channelImage) {
      cell.channelImage = channelImage;
    }
    if (programName) {
      cell.programName = programName;
    }

    cell.tooltip = new PurchaseTooltip();

    if (purchase.purchase._embedded && purchase.purchase._embedded.advertiser) {
      cell.tooltip.advertiserName = purchase.purchase._embedded.advertiser.name;
    } else {
      cell.tooltip.advertiserName = purchase.purchase.temporary_advertiser;
    }

    if (purchase.purchase._embedded && purchase.purchase._embedded.product) {
      cell.tooltip.productName = purchase.purchase._embedded.product.name;
    } else {
      cell.tooltip.productName = purchase.purchase.temporary_product;
    }

    if (purchase.purchase._embedded && purchase.purchase._embedded.secodip) {
      cell.tooltip.secodip = purchase.purchase._embedded.secodip.name;
    } else {
      cell.tooltip.secodip = '';
    }

    if (purchase.purchase._embedded && purchase.purchase._embedded.commercial) {
      cell.tooltip.commercialName = purchase.purchase._embedded.commercial.name;
    } else {
      cell.tooltip.commercialName = '';
    }

    if (purchase.purchase._embedded && purchase.type.label === 'option' && purchase.purchase.date_of_expiration) {
      cell.tooltip.expidationDate = purchase.purchase.date_of_expiration;
    } else {
      cell.tooltip.expidationDate = null;
    }

    if (purchase.purchase && purchase.purchase.alert_switch_date && purchase.purchase.alert_switch_date.date &&
        purchase.type && purchase.type.id && purchase.type.id === '5') {
      cell.tooltip.alertSwitchDate = moment(purchase.purchase.alert_switch_date.date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    } else {
      cell.tooltip.alertSwitchDate = null;
    }

    cell.isDisable = isDisable;
    return cell;
  }
}
