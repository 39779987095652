import { Schedule } from './schedule.resource';
import { Base } from '../base.resource';

export interface JsonSchedulePeriod {
    id: number;
    start_time: string;
    end_time: string;
    schedule?: any;
    parent?: any;
}

export class SchedulePeriod extends Base {
    public id: number;
    public startTime: string;
    public endTime: string;
    public schedule: Schedule;
    public parent: SchedulePeriod;

    constructor(jsonSchedulePeriod: JsonSchedulePeriod, ) {
        super();
        jsonSchedulePeriod = this.convertToUnderscore(jsonSchedulePeriod);

        this.id = jsonSchedulePeriod.id;

        if (jsonSchedulePeriod.start_time) {
            this.startTime = jsonSchedulePeriod.start_time['date'] || jsonSchedulePeriod.start_time;
        }

        if (jsonSchedulePeriod.end_time) {
            this.endTime = jsonSchedulePeriod.end_time['date'] || jsonSchedulePeriod.end_time;
        }

        if (jsonSchedulePeriod.schedule) {
            this.schedule = new Schedule(jsonSchedulePeriod.schedule);
        }

        if (jsonSchedulePeriod.parent) {
            this.parent = new SchedulePeriod(jsonSchedulePeriod.parent);
        }
    }
}
