
import {map,  catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { UtilitiesHandler } from '@service/utilities-handlers/utilitiesHandlers';

import { Observable, Subject } from 'rxjs';

@Injectable()
export class GrpService {
  private route: string = 'offer_grp';

  private totalSelectedGrpSource = new Subject<any>();
  public totalSelectedGrpSource$ = this.totalSelectedGrpSource.asObservable();

  constructor(
    private http: HttpClient,
    private utilitiesHandler: UtilitiesHandler
  ) { }

  /**
   * Send updated total selected grp data to display
   *
   * @param {any} data updated total selected grp data object
   */
  displayTotalSelectedGrp(data): void {
    this.totalSelectedGrpSource.next(data);
  }

  /**
   * Get couverture and repetition for the offer
   *
   * @param {number} id
   * @param {number} year
   * @param {number} duration
   * @param {number} starWeek
   * @returns {Observable<any>}
   * @memberof GrpService
   */
  getCouvRepetOffer(id: number, year: number, starWeek: number, duration: number): Observable<any> {
    let api_base_url = `${environment.api_base_url}/${this.route}?offer_id=${id}&duration=${duration}&year=${year}&dtd=${starWeek}`;

    return this.http
      .get(api_base_url).pipe(
      map((data: any) =>
          data._embedded
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get grp with offer
   *
   * @param {number} id
   * @param {number} year
   * @param {number} [duration=1]
   * @returns {Observable<any>}
   * @memberof GrpService
   */
  getGrpOffer(id: number, year: number, duration: number = 1): Observable<any> {
    let api_base_url = `${environment.api_base_url}/${this.route}?offer_id=${id}&duration=${duration}&year=${year}`;

    return this.http
      .get(api_base_url).pipe(
      map((data: any) =>
          data._embedded
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get rounded Cgrp
   *
   * @param {number} grp
   * @param {number} tvClassicBudgetNet
   * @param {number} averageDurationRate
   * @returns {number}
   * @memberOf GrpService
   */
  public getCost(grp: number, tvClassicBudgetNet: number, averageDurationRate: number): number {
    let cost = 0;
    if (grp && averageDurationRate) {
      cost = this.round(tvClassicBudgetNet / grp / averageDurationRate);
    }
    return cost;
  }

  /**
   * Round value to two decimal places
   *
   * @private
   * @param {number} value
   * @returns {number}
   * @memberOf GrpService
   */
  private round(value: number): number {
    return Math.round( value * 1000 ) / 1000;
  }
}
