<section class="normal-screen">
  <div fxLayout="row" fxLayoutGap="5px" class="margin-top full-width">
    <div fxLayout="column" class="name box-week" fxLayoutAlign="center center">
      <span class="font-size">Emission</span>
    </div>
    <div fxLayout="column" class="name-area box-week" fxLayoutAlign="center center">
      <span class="font-size">Région</span>
    </div>
    <div fxLayout="column" class="full-width">
      <div fxLayout="row" class="full-width">
        <div *ngFor="let month of months; last as isLast" fxLayoutAlign="center center" class="box-week box-month border-all" [ngStyle]="{'width.%': month.size}" [ngClass]="{'doFlex': isLast}">
          <span class="font-size">{{ month.name }}</span>
        </div>
      </div>
      <mat-grid-list cols="53" rowHeight="15px" class="full-width-less" [gutterSize]="'2px'">
        <mat-grid-tile *ngFor="let week of weeks;first as isFirst; last as isLast;" [colspan]="1" [rowspan]="1" class="box-week" [ngClass]="{'border-left': isFirst, 'border-right': isLast || week.separated}">
          <span class="thin font-size">{{ week.number }}</span>
        </mat-grid-tile>
      </mat-grid-list>
      <mat-grid-list cols="53" rowHeight="15px" class="full-width-less" [gutterSize]="'2px'" [ngClass]="{'border-bottom': numberMonths.length}">
        <mat-grid-tile *ngFor="let numberMonth of numberMonths;first as isFirst; last as isLast;" [colspan]="1" [rowspan]="1" class="box-week" [ngClass]="{'border-left': isFirst, 'border-right': isLast || numberMonth.separated}">
          <span class="thin font-size">{{ numberMonth.number }}</span>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </div>

  <cdk-virtual-scroll-viewport itemSize="25" class="table">
    <div *cdkVirtualFor="let offerProgram of sourceSynthesis; last as last; templateCacheSize: 25" fxLayout="column" class="margin-bot">
      <div fxLayout="row" fxLayoutGap="5px" class="full-width" [ngClass]="{'margin-bottom-55': last}">
        <div class="name box-week font-size" fxLayoutAlign="center center">{{offerProgram.name}}</div>
        <div class="name-area box-week font-size" fxLayoutAlign="center center">{{offerProgram.region}}</div>
        <div fxLayout="column" class="full-width">
          <ng-container *ngFor="let place of Arr(offerProgram.places); index as indexRow">
            <mat-grid-list [cols]="offerProgram.weeks.length" rowHeight="20px" class="full-width background margin-bottom-1" [gutterSize]="'0px'">
              <ng-container *ngFor="let week of offerProgram.weeks; index as indexWeek">
                <ng-container *ngIf="week['purchases'][indexRow].duration">
                  <mat-grid-tile [colspan]="week['purchases'][indexRow].duration" [rowspan]="1" class="box">
                    <ng-container *ngIf="week['purchases'][indexRow].id">
                      <a [routerLink]="['/purchases', 'edit', week['purchases'][indexRow]['id']]" target="_blank">
                        <span [matTooltip]="buildTooltip(week['purchases'][indexRow])" matTooltipClass="styleTooltip"
                          [ngClass]="{
                          'purchase': week['purchases'][indexRow].status === 1,
                          'option': week['purchases'][indexRow].status === 2,
                          'alerte': week['purchases'][indexRow].status === 5,
                          'reconduction': week['purchases'][indexRow].status === 10}">
                            {{ week['purchases'][indexRow].advertiserName }}
                        </span>
                      </a>
                    </ng-container>
                    <ng-container *ngIf="!week['purchases'][indexRow].id && week['purchases'][indexRow]['disable']">
                      <span class="unavailable"></span>
                    </ng-container>
                    <ng-container *ngIf="week['checked'] && week['checked'] === 1">
                      <mat-icon fxLayoutAlign="center center" class="checked" (click)="addToCart(offerProgram, indexWeek, indexRow)">done_all</mat-icon>
                    </ng-container>
                    <ng-container *ngIf="week['checked'] === 0 && !week['purchases'][indexRow].id">
                      <span class="available" (click)="addToCart(offerProgram, indexWeek, indexRow)"></span>
                    </ng-container>
                  </mat-grid-tile>
                </ng-container>
              </ng-container>
            </mat-grid-list>
          </ng-container>
        </div>
      </div>
    </div>
  </cdk-virtual-scroll-viewport>
</section>

