import { Base } from './base.resource';

export interface JsonCustomer {
    id: number;
    name: string;
}

export class Customer extends Base {
    public id: number;
    public name: string;

    constructor(jsonCustomer: JsonCustomer) {
      super();
      jsonCustomer = this.convertToUnderscore(jsonCustomer);

      this.id = jsonCustomer.id;
      this.name = jsonCustomer.name;
    }
}
