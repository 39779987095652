import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';

import { AddMonitoringLotComponent } from '../dialog/add-monitoring-row/add-monitoring-lot.component';
import { LotDotationService } from '@service/lot-dotation/lot-dotation.service';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';
import { AutocompleteService } from '@service/utilities/autocomplete.service';
import { AdvertiserService } from '@service/advertiser/advertiser.service';
import { ProgramService } from '@service/program/program.service';

import { MonitoringLotDotation } from '../../../resource/lot-dotation.resource';
import { FilteredItem } from '../../../resource/filteredItem.resource';

import { Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, startWith, takeUntil } from 'rxjs/operators';

import * as moment from 'moment';

@Component({
  selector: 'app-lots-monitoring',
  templateUrl: './lots-monitoring.component.html',
  styleUrls: ['./lots-monitoring.component.scss']
})
export class LotsMonitoringComponent implements OnInit, OnDestroy {
  loading = false;
  displayedColumns = ['contract', 'advertiserName', 'product', 'description', 'bilanNumber', 'broadcastsNumber',
    'totalLotsWon', 'unitPrice', 'totalPrice', 'channel', 'periods', 'invoice', 'actions'];
  dataSource: MonitoringLotDotation[] = [];

  informationGroup: FormGroup;

  filteredProgramItems: Observable<FilteredItem[]>;
  filteredAdvertiserItems: Observable<FilteredItem[]>;
  filteredBilanNumberItems: Observable<FilteredItem[]>;
  filteredContractNumberItems: Observable<FilteredItem[]>;

  componentDestroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public dialog: MatDialog,
    public customToastrService: CustomToastrService,
    public autocompleteService: AutocompleteService,
    private lotDotationService: LotDotationService,
    private advertiserService: AdvertiserService,
    private programService: ProgramService,
    private fb: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.initForm();
    this.setValueChange();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.unsubscribe();
  }

  /**
   * Open dialog new monitoring
   *
   * monitoringLot {MonitoringLotDotation}
   */
  openNewMonitoringRowDialog(monitoringLot: MonitoringLotDotation = null): void {
    const dialogRef = this.dialog.open(AddMonitoringLotComponent, {
      width: '50%',
      height: '75%',
      autoFocus: false,
      data: monitoringLot
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        if (data) {
          this.loadMonitoringLotList();
          this.customToastrService.displayToastr('SUCCESS', 'Le suivi a été édité avec succès!');
        }
      }, () => {
        this.loading = false;
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      });
  }

  /**
   * Load Monitoring Lot list data
   */
  loadMonitoringLotList(): void {
    const filtersMonitoring = {}
    this.loading = true;

    filtersMonitoring['groups'] = 'monitoring_lot';

    if (this.informationGroup.get('period') && this.informationGroup.get('period').value) {
      filtersMonitoring['period'] = moment(this.informationGroup.get('period').value).format('YYYY-MM-DD');
    }
    if (this.informationGroup.get('program') && this.informationGroup.get('program').value
    && this.informationGroup.get('program').value.id) {
      filtersMonitoring['program'] = this.informationGroup.get('program').value.id;
    }
    if (this.informationGroup.get('advertiser') && this.informationGroup.get('advertiser').value
    && this.informationGroup.get('advertiser').value.id) {
      filtersMonitoring['advertiser'] = this.informationGroup.get('advertiser').value.id;
    }
    if (this.informationGroup.get('contractNumber') && this.informationGroup.get('contractNumber').value) {
      filtersMonitoring['contract_number'] = this.informationGroup.get('contractNumber').value.value;
    }
    if (this.informationGroup.get('bilanNumber') && this.informationGroup.get('bilanNumber').value) {
      filtersMonitoring['bilan_number'] = this.informationGroup.get('bilanNumber').value.value;
    }

    this.lotDotationService.getMonitoringLotList(filtersMonitoring)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((monitoringLots: MonitoringLotDotation[]) => {
        this.dataSource = monitoringLots;
        this.loading = false;
      }, () => {
        this.loading = false;
        this.customToastrService.displayToastr('ERROR', 'Erreur lors de la récupération des suivis');
      });
  }

  /**
   * Delete monitoring lot
   *
   * @param id {number}
   */
  deleteMonitoringLot(id: number): void {
    this.loading = true;

    this.lotDotationService.deleteMonitoringLot(id)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        this.loadMonitoringLotList();
        this.customToastrService.displayToastr('SUCCESS', 'Le suivi a été supprimé avec succès!');
      }, () => {
        this.loading = false;
        this.customToastrService.displayToastr('ERROR', 'Erreur lors de la suppression du suivi!');
      });
  }

  /**
   * Init form
   *
   * @private
   */
  private initForm(): void {
    this.informationGroup = this.fb.group({
      period: [null],
      program: [null, [Validators.maxLength(150)]],
      advertiser: [null, [Validators.maxLength(100)]],
      contractNumber: [null, [Validators.minLength(3), Validators.maxLength(15)]],
      bilanNumber: [null, [Validators.minLength(3), Validators.maxLength(15)]],
    });
  }

  /**
   * Init value change
   *
   * @private
   */
  private setValueChange(): void {
    // bilan number
    this.informationGroup.get('bilanNumber').valueChanges
      .pipe(
        startWith(null),
        debounceTime(500),
        filter((data) => data && (typeof data === 'string')),
        distinctUntilChanged(),
        takeUntil(this.componentDestroyed$))
      .subscribe((value) => {
        this.filteredBilanNumberItems = of([]);
        this.loading = true;

        this.lotDotationService.filterByBilanNumber(value)
          .pipe(
            distinctUntilChanged(),
            takeUntil(this.componentDestroyed$))
          .subscribe((bilanItems: FilteredItem[]) => {
            if (bilanItems && bilanItems.length > 0) {
              this.filteredBilanNumberItems = of(this.autocompleteService.filterItems(value, bilanItems));
            }
            this.loading = false;
          }, () => {
            this.loading = false;
            this.customToastrService.displayToastr('ERROR', 'Erreur lors de la récupération des numéros de bilan');
          })
      }, () => {
        this.loading = false;
        this.customToastrService.displayToastr('ERROR', 'Erreur lors de la récupération des numéros de bilans');
      });

    // contract number
    this.informationGroup.get('contractNumber').valueChanges
      .pipe(
        startWith(null),
        debounceTime(500),
        filter((data) => data && (typeof data === 'string')),
        distinctUntilChanged(),
        takeUntil(this.componentDestroyed$))
      .subscribe((value) => {
        this.loading = true;

        this.lotDotationService.getContractNumber(value)
          .pipe(
            distinctUntilChanged(),
            takeUntil(this.componentDestroyed$))
          .subscribe((contractsItems: FilteredItem[]) => {
            if (contractsItems && contractsItems.length > 0) {
              this.filteredContractNumberItems = of(this.autocompleteService.filterItems(value, contractsItems));
            }
            this.loading = false;
          }, () => {
            this.loading = false;
            this.customToastrService.displayToastr('ERROR', 'Erreur lors de la récupération des numéros de contrats');
          })
      }, () => {
        this.loading = false;
        this.customToastrService.displayToastr('ERROR', 'Erreur lors de la récupération des numéros de contrats');
      });

    // program
    this.informationGroup.get('program').valueChanges
      .pipe(
        startWith(null),
        debounceTime(500),
        filter((data) => data && (typeof data === 'string')),
        distinctUntilChanged(),
        takeUntil(this.componentDestroyed$))
      .subscribe((value) => {
        this.filteredProgramItems = of([]);
        this.loading = true;

        const filterProgram = {with_monitoring_lot: true, name: value.toUpperCase()};

        this.programService.getListForFilter(filterProgram)
          .pipe(
            distinctUntilChanged(),
            takeUntil(this.componentDestroyed$))
          .subscribe((programItems: FilteredItem[]) => {
            if (programItems && programItems.length > 0) {
              this.filteredProgramItems = of(this.autocompleteService.filterItems(value, programItems));
            }
            this.loading = false;
          }, () => {
            this.loading = false;
            this.customToastrService.displayToastr('ERROR', 'Erreur lors de la récupération des émissions');
          });
      }, () => {
        this.loading = false;
        this.customToastrService.displayToastr('ERROR', 'Erreur lors de la récupération des émissions');
      });

    // advertiser
    this.informationGroup.get('advertiser').valueChanges
      .pipe(
        startWith(null),
        debounceTime(500),
        filter((data) => data && (typeof data === 'string')),
        distinctUntilChanged(),
        takeUntil(this.componentDestroyed$))
      .subscribe((value) => {
        this.filteredAdvertiserItems = of([]);
        this.loading = true;

        const filterAdvertiser =
        Number(value) ? {id: value.toUpperCase(), with_monitoring_lot: true} : {name: value.toUpperCase(), with_monitoring_lot: true};

        this.advertiserService.getListForFilter(filterAdvertiser)
          .pipe(
            distinctUntilChanged(),
            takeUntil(this.componentDestroyed$))
          .subscribe((advertiserItems: FilteredItem[]) => {
            if (advertiserItems && advertiserItems.length > 0) {
              this.filteredAdvertiserItems = of(this.autocompleteService.filterItems(value, advertiserItems));
            }
            this.loading = false;
          }, () => {
            this.loading = false;
            this.customToastrService.displayToastr('ERROR', 'Erreur lors de la récupération des annonceurs');
          });
      }, () => {
        this.loading = false;
        this.customToastrService.displayToastr('ERROR', 'Erreur lors de la récupération des annonceurs');
      });
  }
}
