
import {map,  catchError } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Campaign } from '../../resource/campaign.resource';
import { FilteredItem } from '../../resource/filteredItem.resource';
import { environment } from '../../../environments/environment';
import { UtilitiesHandler } from '@service/utilities-handlers/utilitiesHandlers';

import { Observable } from 'rxjs';

@Injectable()
export class CampaignService {
  private route: string = 'campaign';

  constructor(
    private http: HttpClient,
    private utilitiesHandler: UtilitiesHandler
  ) { }

  /**
   * Get campagne with ID // TODO NOT TESTED 404 NOT FOUND
   *
   * @param {(string | number)} id
   * @returns {Observable<Campaign>}
   * @memberof CampaignService
   */
  get(id: string | number): Observable<Campaign> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}/${id}`;

    return this.http
      .get(api_base_url).pipe(
      map((data: any) =>
        new Campaign(data)
      ))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }

  /**
   * Get list of Campagne for filter
   *
   * @param {Object} [filters=null]
   * @returns {Observable<FilteredItem[]>}
   * @memberof CampaignService
   */
  getListForFilter(filters: Object = null): Observable<FilteredItem[]> {
    const api_base_url: string = `${environment.api_base_url}/${this.route}`;
    const params: HttpParams = this.utilitiesHandler.buildParamsApi(filters);

    return this.http
      .get(api_base_url, {params}).pipe(
      map((data: any) => {
        return data._embedded.campaign
          .map((json: any) =>
            new Campaign(json)
          )
          .map((campaign: Campaign) =>
            new FilteredItem(campaign)
          )
      }))
      .pipe(
        catchError(this.utilitiesHandler.handleErrorApi)
      )
  }
}
