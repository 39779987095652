<h1 mat-dialog-title> {{ data.title }} </h1>
<div fxLayout="row" fxLayoutAlign="center center"> {{ data.subTitle }} </div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center">
  <button mat-button (click)="cancel()" class="flat-button black">
    <span class="text">NON</span>
  </button>
  <button mat-button (click)="validate()" class="flat-button red">
    <span class="text">OUI</span>
  </button>
</div>
