<mat-card class="form-card">
    <mat-card-title>
        <h2 class="no-margin-top">Info générales</h2>
    </mat-card-title>
    <mat-card-content class="purchase-list-content">

        <mat-progress-bar mode="indeterminate" color="accent" *ngIf="!purchaseTypes">
        </mat-progress-bar>

        <div *ngIf="purchaseTypes">
            <form novalidate [formGroup]="mainInformationGroup" fxLayout="row" fxLayoutGap="5%">
                <div fxLayout="column" fxFlex="40">
                    <div fxLayout="row" fxLayoutGap="10%">
                        <!-- Purchase name -->
                        <mat-form-field fxFlex="45">
                            <input appRemoveAccents matInput formControlName="name" ngDefaultControl placeholder="Nom du dossier" required maxlength="50">
                            <mat-error *ngIf="!mainInformationGroup.controls.name.valid">Ce champ est obligatoire</mat-error>
                        </mat-form-field>
                        <!-- State -->
                        <mat-form-field fxFlex="45">
                            <mat-select formControlName="type" ngDefaultControl placeholder="Etat du dossier" (selectionChange)="onStateSelect(this.purchase.type)">
                              <mat-select-trigger>
                                <div fxLayout="row" fxLayoutGap="5px">
                                  <ng-container *ngIf="selectedType && selectedType.display">
                                    <span>{{ selectedType.display }}</span>
                                  </ng-container>
                                  <ng-container *ngIf="selectedType && selectedType.id && selectedType.id === '5' && alertSwitchDate">
                                    <span>{{ '(posée le ' + this.alertSwitchDate + ')' }}</span>
                                  </ng-container>
                                </div>
                              </mat-select-trigger>
                              <mat-option *ngFor="let purchaseType of purchaseTypes" [disabled]="purchaseType.disabled" [matTooltip]="purchaseType.toolTipMsg"
                                  matTooltipPosition="after" matTooltipShowDelay="0" [value]="purchaseType.value">
                                      {{ purchaseType.display }}
                              </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutGap="10%">
                        <!-- Du -->
                        <mat-form-field fxFlex="45">
                            <input matInput
                                [matDatepicker]="startDate"
                                [max]="stakeholderGroup['controls'].customer['controls'].endCommunicationPeriod.value"
                                placeholder="Du"
                                [formControl]="stakeholderGroup['controls'].customer['controls'].startCommunicationPeriod"
                                >
                            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                            <mat-datepicker #startDate></mat-datepicker>
                        </mat-form-field>
                        <!-- Au -->
                        <mat-form-field fxFlex="45">
                            <input matInput
                                [matDatepicker]="endDate"
                                [min]="stakeholderGroup['controls'].customer['controls'].startCommunicationPeriod.value"
                                placeholder="Au"
                                [formControl]="stakeholderGroup['controls'].customer['controls'].endCommunicationPeriod"
                                >
                            <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                            <mat-datepicker #endDate></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutGap="10%">
                        <!-- BUDGET -->
                        <mat-form-field fxFlex="45">
                            <input matInput
                                type="number"
                                [formControl]="stakeholderGroup['controls'].customer['controls'].customerBudget"
                                restrictInput="float"
                                placeholder="Budget initial">
                            <span matSuffix>
                                €
                            </span>
                        </mat-form-field>
                        <!-- EXPIRATION -->
                        <mat-form-field *ngIf="showDateOfExpiration" fxFlex="45">
                            <input matInput
                                [matDatepicker]="expirationDate"
                                [min]="currentDate"
                                placeholder="Choisir une date"
                                label="Date d'expiration"
                                formControlName="dateOfExpiration"
                                ngDefaultControl
                                (click)="expirationDate.open()">
                            <mat-datepicker-toggle matSuffix [for]="expirationDate"></mat-datepicker-toggle>
                            <mat-datepicker #expirationDate></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div *ngIf="showDateOfExpiration && mainInformationGroup.get('dateOfExpiration').value">
                        Expiration dans {{ getFromNowDate(mainInformationGroup.get('dateOfExpiration').value) }}.
                    </div>
                </div>
                <!-- Comment -->
                <div fxLayout="column" fxFlex="55">
                    <mat-form-field fxFlex="80" class="text-area">
                        <textarea fxFlex="90" matInput formControlName="comments" maxlength="2000" rows="5" placeholder="Commentaires" class="border-textarea thin"></textarea>
                        <mat-hint align="end" *ngIf="mainInformationGroup.get('comments').value">
                            {{mainInformationGroup.get('comments').value.length}} / 2000
                        </mat-hint>
                    </mat-form-field>
                    <div fxLayout="row" fxLayoutAlign="space-around center">
                        <span *ngIf="purchase.fsaId">
                          <span class="thin">Numéro FSA :&nbsp;</span>
                          <strong>{{ purchase.fsaId }}</strong>
                        </span>
                        <mat-form-field class="position-pref">
                            <input matInput type="number" max="9" min="0" formControlName="preferentialPosition" placeholder="Position préférentielle">
                            <mat-error *ngIf="!mainInformationGroup.controls.preferentialPosition.valid">Vous devez saisir un chiffre entre 1 et 9</mat-error>
                        </mat-form-field>
                        <mat-form-field class="command">
                          <input matInput maxlength="50" formControlName="referenceCommand" placeholder="Référence bon commande (Optionnel)">
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </div>
    </mat-card-content>
</mat-card>
