<div fxLayout="row">
	<nav mat-tab-nav-bar>
		<div fxLayoutGap="15px">
      <span class="environment-name" *ngIf="environmentName !== 'PRODUCTION'">{{ environmentName }}</span>
			<a mat-tab-link
				*ngFor="let entry of entries; let i = index;"
				[routerLink]="entry.routerLink"
				[active]="i == selected">
				{{ entry.name }}
			</a>
		</div>
	</nav>
	<button mat-button [matMenuTriggerFor]="menu" class="list-name">
		<span>{{ name }}</span>
		<mat-icon aria-label="menu">arrow_drop_down</mat-icon>
	</button>
	<mat-menu #menu="matMenu">
    <button mat-menu-item (click)="openParamSoReach()">
      <div fxLayoutGap="15px">
        <span>Paramétrage SO REACH</span>
      </div>
    </button>
		<button mat-menu-item (click)="loginComponent.logout()">
			<div fxLayoutGap="15px">
				<span>Se déconnecter</span>
			</div>
		</button>
	</mat-menu>
</div>
